import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagenotfound',
  template: `
    <div style="margin:10em;
    text-align:center;
    color:tomato;">
        <h1>404 Page Not Found !</h1>
    </div>
  `,
  styles: [
  ]
})
export class PagenotfoundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
