<div class="position-relative">
  <nav class="navbar navbar-white bg-white">
      <button type="button" class="btn" (click)="toggle('')">
        <i class="fas fa-bars"></i>
      </button>
      <img src="../../assets/img/download.png" alt="" class="px-2">
          <h4 class="px-2">Paramafashion</h4>
        <button class="btn btn-success my-2 my-sm-0 ml-auto rounded-0" type="button">Logout</button>
    </nav>
    <div [class.sidenavbackside]="isCollapsed">
        <div class="backgroundBlack" *ngIf="isCollapsed" ></div>
        <div #collapse *ngIf="isCollapsed" class="sideNav">
          <ul class="list-group bg-white">
              <li class="list-group-item" *ngFor="let item of details">
                  <a [routerLink]="item.link" class="sideNav_link" routerLinkActive="active-link" (click)="toggle(item.name)">{{item.name}}</a>
                  <ul class="list-group" *ngIf="item.childs">
                    <li class="list-group-item" *ngFor="let value of item.childs;index as i">
                      <a [routerLink]="value.link" class="sideNav_link" (click)="toggle(value.name)"
                      routerLinkActive="active-link">{{value.name}}</a>
                    </li>
                  </ul>
              </li>
            </ul>
        </div>

    </div>
  <router-outlet></router-outlet>
</div>
