import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import {Offer} from './offer'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { error } from '@angular/compiler/src/util';
@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.css']
})
export class OffersComponent implements OnInit {
  
  displayMonths = 2;
  navigation = 'select';
  showWeekNumbers = false;
  outsideDays = 'visible';
  offerList = []

  catagories:any = []
  brands: any;

 

  onCat(item){
    this.offerInfo.apply_on = 'catagory'
    this.offerInfo.apply_to_id = item.cat_id
    this.offerInfo.apply_to_name = item.cat_name
    // if (item.status) {
    //   let result
    //   this.offerInfo.apply_to.map((res,index) => {
    //     result = res;
    //     if (result.apply_to_id === item.cat_id) {
    //       this.offerInfo.apply_to.splice(index,1)
    //     }
    //   }
    //   )
    // } else {
    //   this.offerInfo.apply_to.push({
    //     "apply_to": 'catagory', 
    //     "apply_to_name": item.cat_name,
    //     "apply_to_id" : item.cat_id
    //   })
    // }
  }

  onBrand(item){
    this.offerInfo.apply_on = 'brand'
    this.offerInfo.apply_to_id = item._id
    this.offerInfo.apply_to_name = item.brand_name
    // if (item.status) {
    //   let result
    //   this.offerInfo.apply_to.map((res,index) => {
    //     result = res;
    //     if (result.apply_to_id === item._id) {
    //       this.offerInfo.apply_to.splice(index,1)
    //     }
    //   }
    //   )
    // } else {
    //   this.offerInfo.apply_to.push({
    //     "apply_to": 'brand', 
    //     "apply_to_name": item.brand_name,
    //     "apply_to_id" : item._id
    //   })
    // }
  }

  catValue(cart_value){
    console.log(cart_value)
  }

  
  // offerInfo = new Offer('','','','',0,false,'%off','','','','','',[],'','','',0);
  offerInfo = new Offer('','','','',0,false,'%off','','','unlimited','unlimited','',[],'','','',0);


  flat = false;
  delete(item){
    this.api.post('delete_cart_offer',{"_id": item._id}).subscribe(
      data=> {
        console.log(data)
        this.api.get(`cart_offer_list`).subscribe(
          data =>{
            this.offerList = Object.values(data)[2];
          }, 
          error => console.log('Error!', error)
        )
      },
      error => console.log(error)
    )
  }

  
  updateCoupon(value){
    this.offerInfo = value;
    this.offerList.splice(this.offerList.indexOf(value),1);
  }

  onSelect(event){
    if (event === '%off') {
      this.flat = false
    } else {
      this.flat = true;
    }
  }
  
  open(content) {
    this.modalService.open(content, {backdropClass: 'light-blue-backdrop'});
  }

  getCatagory(country){
    this.api.post(`get_footer_cat_subcat`, { "category_country_id": country.split('-')[0], "category_country_name": country.split('-')[1]}).subscribe(
      data => {
        this.catagories = Object.values(data)[2]
        this.catagories.map((data) => {
          data.status = false
              if (data.child_cat && data.child_cat.length > 0) {
                data.child_cat.map((subcat) => {
                    subcat.status = false
                    if (subcat.child_cat && subcat.child_cat.length > 0 ) {
                      subcat.child_cat.map((ultimateSerch) => {
                        ultimateSerch.status = false
                      })
                    }
                })
              }
        })
      },
      error => console.log('Error!', error)
    )
  }

  
    // bannerMenu;
  
    // getData(){
    //   this.api.bannerSource$.subscribe(
    //     message => {
    //       // console.log(message)
    //       this.bannerMenu = message;
    //     }
    //   )
    // }
  
    onSubmit(form:NgForm){
      let country = this.offerInfo.country.split('-')
        let body = {
          country_id: country[0],
          cart_offer_country_name: country[1],
          cart_offer_description: this.offerInfo.description,
          cart_offer_type: form.value.type,
          cart_offer_value: form.value.cuponOfferValue,
          cart_offer_tag: this.offerInfo.couponOfferTag,
          cart_offer_code: this.offerInfo.couponOfferCode,
          cart_offer_uses_time: this.offerInfo.unlimited? 'unlimited' : this.offerInfo.OfferUseTime,
          cart_offer_valid_from: this.offerInfo.ValidFrom = null ? 'unlimited': this.offerInfo.ValidFrom,
          cart_offer_valid_upto: this.offerInfo.ValidUpto = null ? 'unlimited': this.offerInfo.ValidUpto,
          cart_offer_terms_and_conditions: this.offerInfo.Terms_Conditions,
          // offer_apply_to: this.offerInfo.apply_to,
          // apply_to_name: this.offerInfo.apply_to_name,
          // apply_to: this.offerInfo.apply_on,
          // apply_to_id: this.offerInfo.apply_to_id,
          cart_value: this.offerInfo.cart_value
        }
        console.log(body)
        this.api.post(`add_cart_offer`,body).subscribe(
        data=> {
          console.log('!success',data);
          this.offerInfo.apply_to = [];
          this.offerInfo.apply_to_name = ''
          this.offerInfo.apply_on = ''
          this.offerInfo.apply_to_id = ''
          this.api.get(`cart_offer_list`).subscribe(
            data =>{
              this.offerList = Object.values(data)[2];
              console.log(this.offerList)
            }, 
            error => console.log('Error!', error)
          )
          form.reset()
        },
        error=> console.log(error)
      )
      // if(form.value.unlimited=true){
      //   console.log('unlimited')
      // }
      // if(this.offerInfo.unlimited){
      //   this.offerInfo.OfferUseTime = 'unlimited'
      // }
        console.log(form.value);
    }

    Showsubcat(val) {
      val.click = !val.click
    }
  
  
    constructor(private api:ApiService,private modalService: NgbModal) { 
    }
    countries;
    ngOnInit(): void {
      this.api.get(`selected_country_list`).subscribe(
        data =>{
          this.countries = Object.values(data)[2];
          this.api.countryShare(this.countries);
          console.log(this.countries)
        }, 
        error => console.log('Error!', error)
      )
      this.api.get(`cart_offer_list`).subscribe(
        data =>{
          this.offerList = Object.values(data)[2];
          console.log(this.offerList)
        }, 
        error => console.log('Error!', error)
      )
      this.api.get(`brand_list`).subscribe(
        data => {
          this.brands = Object.values(data)[2];
          this.brands.map((res)=> res.status = false)
        },
        error => console.log('Error!', error)
      )
    }
    //   this.api.countrySource$.subscribe(
    //     message => {
    //       this.countries = message;
    //     }
    //   )
}

