import { BannerSetting } from './banner-setting';
import { ApiService } from './../../../api.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {CroppedEvent} from 'ngx-photo-editor';


@Component({
  selector: 'app-banner-setting',
  templateUrl: './banner-setting.component.html',
  styleUrls: ['./banner-setting.component.css']
})
export class BannerSettingComponent implements OnInit {

  offerList = [
    {cuponOfferValue:'5',couponOfferTag: 'About Shop',description:'1234', country:'India', couponOfferCode: 'new30',
   ValidFrom: new Date(), ValidUpto: new Date(), OfferUseTime:0},
    {cuponOfferValue:'5', couponOfferTag: 'About Shop',description:'1234', country:'India', couponOfferCode: 'new30',
   ValidFrom: new Date(), ValidUpto: new Date(), OfferUseTime:0},
    {cuponOfferValue:'5', couponOfferTag: 'About Shop',description:'1234', country:'India', couponOfferCode: 'new30',
   ValidFrom: new Date(), ValidUpto: new Date(), OfferUseTime:0},
    {cuponOfferValue:'5', couponOfferTag: 'About Shop',description:'1234', country:'India', couponOfferCode: 'new30',
   ValidFrom: new Date(), ValidUpto: new Date(), OfferUseTime:0},
    {cuponOfferValue:'15', couponOfferTag: 'About Shop',description:'1234', country:'India', couponOfferCode: 'new30',
   ValidFrom: new Date(), ValidUpto: new Date(), OfferUseTime:0},
  ]


  catagories = []

  Showcat(val){
    this.catagories[val].click = !this.catagories[val].click;
  }
  Showsubcat(val,val2){
    this.catagories[val].subcatagory[val2].click = !this.catagories[val].subcatagory[val2].click;
  }
  customOptions = {
    loop: true,
    autoplay: true,
    center: true,
    dots: false,
    autoHeight: true,
    autoWidth: true,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      }
    }
  }

  imageChangedEvent: any;
  base64: any;
  
  fileChangeEvent(event: any) {
    this.imageChangedEvent = event;
  }

  imageCropped(event: CroppedEvent) {
    this.base64 = event.base64;
  }

  bannerPage = new BannerSetting([],'','',false,1000,1,'rectangle','applied on','')

  images = [
    {img: "../../../assets/img/home/hero-slider/01.jpg", myStyles: {"background-color": "rgb(58, 175, 210)"}},
    {img: "../../../assets/img/home/hero-slider/02.jpg", myStyles: {"background-color": "#f5b1b0"}},
    {img: "../../../assets/img/home/hero-slider/03.jpg", myStyles: {"background-color": "#eba170"}}
  ]

  imgType = true;

  bannerMenu;

  // getData(){
  //   this.api.bannerSource$.subscribe(
  //     message => {
  //       // console.log(message)
  //       this.bannerMenu = message;
  //     }
  //   )
  // }
  bannerpositionRight = true;
  bannerPositionChecked(value){
    let item = value.toString();
      for (let i = 0; i < this.bannerMenu.length; i++) {
        if (this.bannerMenu[i].main_banner_position_id === item) {
          this.bannerpositionRight = false;
        }else{
          this.bannerpositionRight = true
        }
      }
  }
  selectedFile:File;

  onSubmit(form:NgForm){
      let body = {
        "carosel_image": this.bannerPage.bannerImages,
        "country_id": this.bannerPage.country.split('-')[0],
        "banner_country_name": this.bannerPage.country.split('-')[1],
        "carosel_name": this.bannerPage.name,
        "slidesToShow":this.bannerPage.showImageNumber,
        "animation":this.bannerPage.animation,
    }
      this.api.post(`post_carosel_banner`,body).subscribe(
      data=> {
        console.log('!success',data);
        this.bannerPage.bannerImages = []
        this.api.get(`get_carosel_banner`).subscribe(
          data =>{
            this.bannerMenu = Object.values(data)[2];
            console.log('Success!',data);
          },
          error => console.log('Error!', error)
        )
      },
      error=> console.log(error)
    )
      form.reset();
    // } 
  }
  // upload(event){
    // if (event.value.includes(".png") || event.value.includes(".jpg")) {
    //   this.imgType = true;
    // } else {
    //   this.imgType = false;
    // }
    // this.selectedFile = <File>event.target.files[0];
    // console.log(this.selectedFile)
  // }

  onUpload(event) {
    const file: File = event.target.files;
    console.log(file)
    var filesAmount = event.target.files.length;
    if (filesAmount <= 20 && this.bannerPage.bannerImages.length < 20) {
      for (let i = 0; i < filesAmount; i++) {
        const formData = new FormData();
        formData.append('carosel_image', file[i])
        this.api.post(`add_carosel_image`, formData).subscribe(
          data => {
            let imagelink = Object.values(data)[2];
            this.bannerPage.bannerImages.push({ 'image': imagelink })
          },
          error => console.log(error)
        )
      }
    }
  }

  view(item){
    this.api.put(`update_carosel_banner`,{"_id": item._id, "status": (item.status)? false : true}).subscribe(
      data=> {
        console.log('!success',data);
        this.api.get(`brand_list`).subscribe(
          data =>{
            console.log(data)
            this.api.get(`get_carosel_banner`).subscribe(
              data =>{
                this.bannerMenu = Object.values(data)[2];
                console.log('Success!',data);
              },
              error => console.log('Error!', error)
            )
          }, 
          error => console.log('Error!', error)
        )
        },
        error=> console.log(error)
    )
  }


  deleteImg(img, list) {
    let array = list
    let public_id = img.image.split("/")[8].split(".")[0];
    this.api.post(`remove_image`, {"image_id":`caroselimage/${public_id}`}).subscribe(
      data => {
        list.map((res) => {
          if (res.image === img.image) {
            array.splice(list.indexOf(res), 1)
          }
        })
      },
      error => console.log(error)
    )
  }

  deletebanner(item){
    this.api.post(`delete_carosel_banner`,{"_id":item._id}).subscribe(
      data => {
        if (data.status === 'success') {
          item.carosel_image.map(res => {
            let public_id = res.image.split("/")[8].split(".")[0];
            console.log(public_id)
            this.api.post(`remove_image`, {"image_id":`caroselimage/${public_id}`}).subscribe(
              data => {
                console.log(data)
              },
              error => console.log(error)
            )
          })
          this.api.get(`get_carosel_banner`).subscribe(
            response =>{
              this.bannerMenu = Object.values(response)[2];
              console.log('Success!',response);
            },
            error => console.log('Error!', error)
          )
        }
      },
      error => console.log(error)
    )
  }

  constructor(private api:ApiService,private modalService: NgbModal) { 
  }
  countries;

  open(content,size) {
    this.modalService.open(content, { size: size,scrollable: true  });
  }
  ngOnInit(): void {
    this.api.get(`get_carosel_banner`).subscribe(
      data =>{
        this.bannerMenu = Object.values(data)[2];
        console.log('Success!',data);
        // this.api.BannerShare(this.bannerMenu);
      },
      error => console.log('Error!', error)
    )
    this.api.get(`selected_country_list`).subscribe(
      data =>{
        this.countries = Object.values(data)[2]
        console.log(this.countries)
      }, 
      error => console.log('Error!', error)
    )
  }
  //   this.api.countrySource$.subscribe(
  //     message => {
  //       this.countries = message;
  //     }
  //   )

}


