import { coerceNumberProperty } from '@angular/cdk/coercion';
import { Component, OnInit, OnChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/api.service';
import { ProductAdd } from '../product-add/product-add';
import { ManageProduct } from './manage-product';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'app-manage-product',
  templateUrl: './manage-product.component.html',
  styleUrls: ['./manage-product.component.css']
})
export class ManageProductComponent implements OnInit {

  products: any = [];

  brand

  fetureList = [];

  catagories = []

  productListByCatagoey: any = []

  catagoryList: any = [];

  countries;

  selectedProducts = [];

  catagory = 'all catagory';

  catagory_update = []
  unselect: boolean;
  allselect: boolean;
  fetureCatList: any;
  feturePerCat: any = []
  feturePerCatForProduct: any = [];
  manageProduct = new ManageProduct('country', [], [], false, [], 'Are You surely Want to update this product', 'All', 'Filter By Catagory', 'Filter By Tax')
  pagecount: string;
  pageLink: string;
  sort: string;
  gst_list: any = [{ store_hsn_code: "Filter by Tax", status: true, _id: ' ' }]
  addMore: string;
  loader: boolean = true
  type_of_productList: unknown[];
  vendorList: any;

  sku_weight_mesurement: any = {
    store_decimal_places: "",
    store_decimal_token: "",
    store_generate_auto: false,
    store_length_mesurements: "",
    store_sku_manual_start_from: "",
    store_sku_prefix: "",
    store_thousand_token: "",
    store_weight_mesurements: "",
  }

  Showsubcat(val) {
    val.click = !val.click
  }


  onFileUpload(event, images) {
    const file: File = event.target.files;
    console.log(file)
    var filesAmount = event.target.files.length;
    if (filesAmount <= 20 && images.length < 20) {
      for (let i = 0; i < filesAmount; i++) {
        const formData = new FormData();
        formData.append('product_image', file[i])
        this.api.post(`add_product_image`, formData).subscribe(
          data => {
            let imagelink = Object.values(data)[2];
            images.push({ 'image_link': imagelink })
            // this.singleProductForm.coverImg = this.imgLinks[0].image_link
            // console.log(this.singleProductForm.coverImg)
          },
          error => console.log(error)
        )
      }
    }
    console.log(images)
  }

  deleteImg(value, product) {
    let array = product.image
    let public_id
    if (value) {
      public_id = value.split("/")[8].split(".")[0];
    }
    this.api.post(`remove_image`, { "image_id": `products/${public_id}` }).subscribe(
      data => {
        product.image.map((res) => {
          if (res.image_link === value) {
            array.splice(product.image.indexOf(res), 1)
          }
        })
        if (!product.cover_image) {
          product.cover_image = array[0].image_link
        }
        // console.log(array)
      },
      error => console.log(error)
    )
  }

  all() {
    if (this.manageProduct.allSelect === true) {
      this.products.map((res) => res.status = true)
    } else {
      this.products.map((res) => res.status = false)
    }
  }

  selectOne() {
    if (this.products.every((res) => res.status === true)) {
      this.manageProduct.allSelect = true
    } else {
      this.manageProduct.allSelect = false
    }
  }


  onCat(item, parent, superParent) {
    if (item.status) {
      parent.status = false
      superParent.status = false
      let result
      this.manageProduct.cat_id.map((res, index) => {
        result = res;
        if (result === item.cat_id) {
          this.manageProduct.cat_id.splice(index, 1)
        }
      }
      )
      if (item.child_cat && item.child_cat.length > 0) {
        item.child_cat.map((val) => {
          val.status = false
          this.manageProduct.cat_id.map((res, index) => {
            result = res;
            if (res === val.cat_id) {
              this.manageProduct.cat_id.splice(index, 1)
            }
          }
          )
          if (val.child_cat && val.child_cat.length > 0) {
            val.child_cat.map(val2 => {
              val2.status = false
              this.manageProduct.cat_id.map((res, index) => {
                result = res;
                if (result === val2.cat_id) {
                  this.manageProduct.cat_id.splice(index, 1)
                }
              }
              )
            })
          }
        })
      }
      this.manageProduct.cat_name.map((res, index) => {
        result = res;
        if (result === item.cat_name) {
          this.manageProduct.cat_name.splice(index, 1)
        }
        if (result === parent.cat_name) {
          this.manageProduct.cat_name.splice(index, 1)
        }
        if (result === superParent.cat_name) {
          this.manageProduct.cat_name.splice(index, 1)
        }
        if (this.manageProduct.cat_name.length > 0) {
          this.catagory = this.manageProduct.cat_name.join()
        } else {
          this.catagory = 'All Catagory'
        }
      }
      )
      this.feturePerCat.map((res, index) => {
        if (res.featured_product_category_category_id === item.cat_id) {
          this.feturePerCat.splice(index, 1)
        }
      }
      )
    } else {
      console.log(item.child_cat)
      this.manageProduct.cat_id.push(item.cat_id)
      if (item.child_cat && item.child_cat.length > 0) {
        item.child_cat.map((val) => {
          val.status = true
          this.manageProduct.cat_id.push(val.cat_id)
          if (val.child_cat && val.child_cat.length > 0) {
            val.child_cat.map(val2 => {
              val2.status = true
              this.manageProduct.cat_id.push(val2.cat_id)
            })
          }
        })
      }
      if (parent.child_cat && parent.child_cat.length > 0) {
        parent.child_cat.every((val) => {
          val.status = true
          if (val.status === true) {
            this.manageProduct.cat_name.push(item.cat_name)
          }
        })
      } else if (superParent.child_cat && superParent.child_cat.length > 0) {
        superParent.child_cat.every((val) => {
          val.status = true
          if (val.status === true) {
            this.manageProduct.cat_name.push(item.cat_name)
          }
        })
      } else {
        this.manageProduct.cat_name.push(item.cat_name)
      }
      this.catagory = this.manageProduct.cat_name.join()
    }
    if (this.manageProduct.cat_id.length > 0) {
      this.api.post(`product_by_cat_id`, { "cat_id": this.manageProduct.cat_id }).subscribe(
        data => {
          this.products = Object.values(data)[2]
          this.products.map((res) => {
            res.status = false
            if (res.product_upload_type === "bulk_product_upload") {
              res.bg_color = "rgb(207, 248, 212)"
            } else if (res.product_upload_type === "set_product_upload" && res.set_single_product.single_product_type && !res.set_single_product.set_product_type) {
              res.bg_color = "rgb(255, 217, 223)"
            } else if (res.product_upload_type === "set_product_upload" && res.set_single_product.single_product_type && res.set_single_product.set_product_type) {
              res.bg_color = "rgb(232, 202, 240)"
            } else if (res.product_upload_type === "single product upload") {
              res.bg_color = "rgb(255, 217, 223)"
            } else if (res.product_upload_type === "set_product_upload" && res.combo_product) {
              res.bg_color = "rgb(244, 248, 207)"
            }
          })
        },
        error => console.log('Error!', error)
      )
      this.api.post(`populate_featured_product_category_by_category`, { "featured_product_category_id": this.manageProduct.cat_id }).subscribe(
        data => {
          this.feturePerCat.push(data.result.result)
          this.feturePerCat.map((res) => res.status = false)
          // if (data.result.result.featured_product_category_product_id.length > 1) {
          //   data.result.result.status = true
          // } else {
          //   data.result.result.status = false
          // }
        },
        error => console.log('Error!', error)
      )
    } else {
      this.api.get(`product_list`).subscribe(
        data => {
          this.products = Object.values(data)[2];
          this.products.map((res) => {
            res.status = false
            if (res.product_upload_type === "bulk_product_upload") {
              res.bg_color = "rgb(207, 248, 212)"
            } else if (res.product_upload_type === "set_product_upload" && res.set_single_product.single_product_type && !res.set_single_product.set_product_type) {
              res.bg_color = "rgb(255, 217, 223)"
            } else if (res.product_upload_type === "set_product_upload" && res.set_single_product.single_product_type && res.set_single_product.set_product_type) {
              res.bg_color = "rgb(232, 202, 240)"
            } else if (res.product_upload_type === "single product upload") {
              res.bg_color = "rgb(255, 217, 223)"
            } else if (res.product_upload_type === "set_product_upload" && res.combo_product) {
              res.bg_color = "rgb(244, 248, 207)"
            }
          })
        },
        error => console.log('Error!', error)
      )
      this.feturePerCat = []
    }
  }

  getCatagory(country) {
    this.api.post(`get_footer_cat_subcat`, { "category_country_id": country.split('-')[0], "category_country_name": country.split('-')[1] }).subscribe(
      data => {
        this.productListByCatagoey = Object.values(data)[2]
        console.log(this.productListByCatagoey)
        this.productListByCatagoey.map((data) => {
          data.status = false
          if (data.child_cat && data.child_cat.length > 0) {
            data.child_cat.map((subcat) => {
              subcat.status = false
              if (subcat.child_cat && subcat.child_cat.length > 0) {
                subcat.child_cat.map((ultimateSerch) => {
                  ultimateSerch.status = false
                })
              }
            })
          }
        })
      },
      error => console.log('Error!', error)
    )
  }


  openScrollableContent(longContent) {
    this.modalService.open(longContent, { scrollable: true });
  }

  showFetures(product) {
    this.fetureList.map((result) => {
      result.featured_product_product_id.map((res) => {
        if (res === product._id) {
          result.status = true
        }
      })
    })
    this.fetureCatList.map((result) => {
      result.featured_product_category_product_id.map((res) => {
        if (res === product._id) {
          result.status = true
        }
      })
    })
  }

  hideFetures() {
    this.fetureList.map((res) => res.status = false)
    this.fetureCatList.map((res) => res.status = false)
  }

  openXl(content, product) {
    this.modalService.open(content, { scrollable: true, size: 'xl', windowClass: "dark-modal" });
    this.fetureList.map((result) => {
      result.featured_product_product_id.map((res, index) => {
        if (res === product._id) {
          result.status = true
        }
      })
    })
    this.api.get(`product_type_list`).subscribe(
      data => {
        this.type_of_productList = Object.values(Object.values(data)[2])
        let val
        product.varientsforSingleProductCustomforMake = []
        this.type_of_productList.map((result) => {
          val = result
          product.attributeList = val.type_of_product_attribute
          if (product.type_of_product === val.type_of_product_sub) {
            val.type_of_product_custom_varient.map((res) => {
              const array = res.custom_varient_value.map(des => ({
                "varient_name": des.varient_name, "variable_description": [
                  {
                    "value": '',
                    "quantity": '',
                    "discount": product.price_discount_from_buyer,
                    "price": product.price_from_buyer,
                    "sku": product.sku,
                    "barcode": '',
                    "mrp": product.mrp
                  },
                ]
              }))
              product.varientsforSingleProductCustomforMake.push(
                {
                  name: res.custom_varient_product_type, image: '../../../../assets/icon/settings.png', disable: false,
                  everything: [{
                    details: [{ name: res.custom_varient_product_type, list: res.custom_varient_value }],
                    sameShow: true
                  }],
                  custom_varient_img1: res.custom_varient_img1,
                  custom_varient_img2: res.custom_varient_img2
                }
              )
            }
            )
          }
        })
        let array = []
        if (product.custom_varient.length > 0) {
          product.varientsforSingleProductCustomforMake.map((res) => {
            product.custom_varient.map((varient) => {
              if (varient.variable_name === res.name) {
                array.push(varient)
                console.log(res.everything[0])
                for (let index = 1; index < varient.variable_description.length; index++) {
                  res.everything.push(res.everything[0])
                }
              } else {
                array.push({
                  "variable_name": res.name,
                  "custom_varient_img1": res.custom_varient_img1,
                  "custom_varient_img2": res.custom_varient_img2,
                  "variable_description": [
                    {
                      "value": '',
                      "quantity": '',
                      "discount": product.price_discount_from_buyer,
                      "price": product.price_from_buyer,
                      "sku": product.sku,
                      "barcode": '',
                      "mrp": product.mrp
                    },
                  ]
                })
              }
            })
          }) 
        } else {
          array.push({
            // "variable_name": '',
            // "custom_varient_img1": '',
            // "custom_varient_img2": '',
            "variable_description": [
              {
                "value": '',
                "quantity": '',
                "discount": product.price_discount_from_buyer,
                "price": product.price_from_buyer,
                "sku": product.sku,
                "barcode": '',
                "mrp": product.mrp
              },
            ]
          })
        }
        product.custom = array
        // product.custom_varient.map((res,index)=> product.custom[index] = res)
        console.log(product.varientsforSingleProductCustomforMake)
        console.log(product.custom)
      },
      error => console.log('Error!', error)
    )
    this.api.post(`populate_featured_product_category_by_category`, { "featured_product_category_id": product.product_main_category_id }).subscribe(
      data => {
        if (data.status === "success") {
          data.result.map(res => this.feturePerCatForProduct.push(res))
          console.log(data)
          console.log(this.feturePerCatForProduct)
          this.feturePerCatForProduct.map((result) => {
            if (result.featured_product_category_product_id.find((res) => res._id === product._id)) {
              result.status = true
            } else {
              result.status = false
            }
          })
        }
      },
      error => console.log('Error!', error)
    )
    this.api.post(`populate_featured_product_category_by_category`, { "featured_product_category_id": product.product_sub_main_category_id }).subscribe(
      data => {
        if (data.status === "success") {
          data.result.map(res => this.feturePerCatForProduct.push(res))
          console.log(data)
          console.log(this.feturePerCatForProduct)
          this.feturePerCatForProduct.map((result) => {
            if (result.featured_product_category_product_id.find((res) => res._id === product._id)) {
              result.status = true
            } else {
              result.status = false
            }
          })
        }
      },
      error => console.log('Error!', error)
    )
    this.api.post(`populate_featured_product_category_by_category`, { "featured_product_category_id": product.category_id }).subscribe(
      data => {
        if (data.status === "success") {
          data.result.map(res => this.feturePerCatForProduct.push(res))
          console.log(data)
          console.log(this.feturePerCatForProduct)
          this.feturePerCatForProduct.map((result) => {
            if (result.featured_product_category_product_id.find((res) => res._id === product._id)) {
              result.status = true
            } else {
              result.status = false
            }
          })
        }
      },
      error => console.log('Error!', error)
    )
    this.fetureCatList.map((result) => {
      result.featured_product_category_product_id.map((res, index) => {
        if (res === product._id) {
          result.status = true
        }
      })
    })
  }

  // openXl(content, list) {
  // this.modalService.open(content, { scrollable: true, size: 'xl', windowClass: "dark-modal" });
  // if (list !== '') {
  // if (list.product_category.length > 0) {
  //     list.product_category.map((result) => {
  //       this.catagoryList.map((data) => {
  //         data.catagories.map((value) => {
  //           if (value.cat_id === result.category_id) {
  //             data.catagory = result.category_id
  //           } else {
  //             if (value.child_cat && value.child_cat.length > 0) {
  //               value.child_cat.map((subcat) => {
  //                 if (subcat.cat_id === result.category_id) {
  //                   data.catagory = result.category_id
  //                 } else {
  //                   if (subcat.child_cat && subcat.child_cat.length > 0 ) {
  //                     subcat.child_cat.map((ultimateSerch) => {
  //                       if (ultimateSerch.cat_id === result.category_id) {
  //                         data.catagory = result.category_id
  //                       }
  //                     })
  //                   }
  //                 }
  //               })
  //             }
  //           }
  //         })
  //       })
  //     })
  // }
  // this.fetureList.map((result) => {
  //   result.featured_product_product_id.map((res, index) => {
  //     if (res === list._id) {
  //       result.status = true
  //     }
  //   })
  // })

  // let product_category = []
  // list.product_category.map((res)=> product_category.push(res.category_id))

  // this.api.post(`populate_featured_product_category_by_category`,{"featured_product_category_id":product_category}).subscribe(
  //   data => {
  //     if (data.status === "success") {
  //       this.feturePerCatForProduct.push(data.result.result)
  //       data.result.result.status = false
  //       data.result.result.featured_product_category_product_id.find((res) => {
  //         if (res._id === list._id) {
  //           data.result.result.status = true
  //         }
  //       })
  //     }
  //   },
  //   error => console.log('Error!', error)
  // )


  //     this.fetureCatList.map((result) => {
  //       result.featured_product_category_product_id.map((res, index) => {
  //         if (res === list._id) {
  //           result.status = true
  //         }
  //       })
  //     })
  //   }
  // }

  closeModel() {
    this.modalService.dismissAll();
    this.catagoryList.map((data) => data.catagory = '')
    this.api.get(`featured_product_list`).subscribe(
      data => {
        let fetureList = Object.values(data)[2]
        fetureList.map((res) => res.status = false)
        this.fetureList = fetureList
      },
      error => console.log('Error!', error)
    )
    this.api.get(`featured_product_category_list`).subscribe(
      data => {
        let fetureList = Object.values(data)[2].result;
        fetureList.map((res) => res.status = false)
        this.fetureCatList = fetureList
      },
      error => console.log('Error!', error)
    )
    // this.fetureList.map((res) => res.status = false)
    // this.fetureCatList.map((res) => res.status = false)
    this.feturePerCatForProduct = []
  }

  onSelect(value) {
    let array = []
    if (value.checked) {
      this.products.map((res) => array.push(res._id))
    } else {
      array = []
    }
    this.selectedProducts = array
    console.log(this.selectedProducts)
  }

  selectSingle(e, product, parent) {
    if (e.target.checked) {
      this.selectedProducts.push(product._id)
    } else {
      this.selectedProducts.map((res, index) => {
        if (res === product._id) {
          this.selectedProducts.splice(index, 1)
        }
        this.allselect = false
      })
    }
  }

  catagoryEdit(main, submain, sub, product, smallcontent) {
    if (sub) {
      product.product_main_category = main.category_name,
        product.product_main_category_id = main._id,
        product.product_sub_main_category = submain.category_name,
        product.product_sub_main_category_id = submain._id,
        product.product_sub_category = sub.category_name,
        product.category_id = sub._id
    } else if (submain) {
      product.product_main_category = main.category_name,
        product.product_main_category_id = main._id,
        product.product_sub_main_category = submain.category_name,
        product.product_sub_main_category_id = submain._id,
        product.product_sub_category = '',
        product.category_id = null
    } else {
      product.product_sub_main_category = '',
        product.product_sub_main_category_id = null,
        product.product_sub_category = '',
        product.category_id = null
    }
    this.api.put(`update_all_product`, product).subscribe(
      data => {
        console.log(data)
        if (data.status === 'success') {
          // this.modalService.open(smallcontent)
          this.manageProduct.updateMessage = 'Product SuccessFully Updated'
        }
      },
      error => console.log(error)
    )
  }

  productEdit(product) {
    this.api.put(`update_all_product`, product).subscribe(
      data => {
        console.log(data)
        if (data.status === 'success') {
          this.manageProduct.updateMessage = 'Product SuccessFully Updated'
          window.location.reload()
        }
      },
      error => console.log(error)
    )
  }

  catagoryselect(list) {
    console.log(list)
  }

  addFeture(product, feture, e) {
    if (feture.status) {
      this.api.post(`push_product_id_to_featured_product`, { "featured_product_id": feture._id, "product_id": product._id }).subscribe(
        data => {
          console.log(data)
          if (this.fetureList.find((res) => res.status)) {
            product.featured_product = true
          } else if (this.fetureCatList.find((res) => res.status)) {
            product.featured_product = true
          } else {
            product.featured_product = false
          }
          this.api.put(`update_all_product`, product).subscribe(
            data => {
              console.log(data)
            },
            error => console.log(error)
          )
        },
        error => console.log('Error!', error)
      )
    } else {
      this.api.post(`pull_product_id_from_featured_product`, { "featured_product_id": feture._id, "product_id": product._id }).subscribe(
        data => {
          console.log(data)
          if (this.fetureList.find((res) => res.status)) {
            product.featured_product = true
          } else if (this.fetureCatList.find((res) => res.status)) {
            product.featured_product = true
          } else {
            product.featured_product = false
          }
          this.api.put(`update_all_product`, product).subscribe(
            data => {
              console.log(data)
            },
            error => console.log(error)
          )
        },
        error => console.log('Error!', error)
      )
    }
  }

  addCatFeture(product, feture, e) {
    if (feture.status) {
      this.api.post(`push_product_id_to_featured_product_category`, { "_id": feture._id, "product_id": product._id }).subscribe(
        data => {
          console.log(data)
          if (this.fetureList.find((res) => res.status)) {
            product.featured_product = true
          } else if (this.fetureCatList.find((res) => res.status)) {
            product.featured_product = true
          } else {
            product.featured_product = false
          }
          this.api.put(`update_all_product`, product).subscribe(
            data => {
              console.log(data)
            },
            error => console.log(error)
          )
        },
        error => console.log('Error!', error)
      )
    } else {
      this.api.post(`pull_product_id_from_featured_product_category`, { "_id": feture._id, "product_id": product._id }).subscribe(
        data => {
          console.log(data)
          if (this.fetureList.find((res) => res.status)) {
            product.featured_product = true
          } else if (this.fetureCatList.find((res) => res.status)) {
            product.featured_product = true
          } else {
            product.featured_product = false
          }
          this.api.put(`update_all_product`, product).subscribe(
            data => {
              console.log(data)
            },
            error => console.log(error)
          )
        },
        error => console.log('Error!', error)
      )
    }
  }

  selectFeture(feture) {
    this.products.map((res) => {
      if (res.status) {
        this.api.post(`push_product_id_to_featured_product`, { "featured_product_id": feture._id, "product_id": res._id }).subscribe(
          data => {
            console.log(data)
            if (!res.featured_product) {
              res.featured_product = true
              this.api.put(`update_all_product`, res).subscribe(
                data => {
                  console.log(data)
                },
                error => console.log(error)
              )
            }
            // if (this.fetureList.find((res) => res.status)) {
            //   res.featured_product = true
            // } else if (this.fetureCatList.find((res) => res.status)) {
            //   res.featured_product = true
            // } else {
            //   res.featured_product = false
            // }
          },
          error => console.log('Error!', error)
        )
      }
    })
  }

  selectFetureForCatagory(feture) {
    this.products.map((res) => {
      if (res.status) {
        console.log({ "featured_product_id": feture._id, "product_id": res._id })
        this.api.post(`push_product_id_to_featured_product_category`, { "_id": feture._id, "product_id": res._id }).subscribe(
          data => {
            console.log(data)
            // if (this.fetureList.find((res) => res.status)) {
            //   res.featured_product = true
            // } else if (this.fetureCatList.find((res) => res.status)) {
            //   res.featured_product = true
            // } else {
            //   res.featured_product = false
            // }
            if (!res.featured_product) {
              res.featured_product = true
              this.api.put(`update_all_product`, res).subscribe(
                data => {
                  console.log(data)
                },
                error => console.log(error)
              )
            }
          },
          error => console.log('Error!', error)
        )
      }
    })
  }

  openAddFeture(content) {
    this.modalService.open(content, { scrollable: true, size: 'xl', windowClass: "dark-modal" })
  }

  delete(value) {
    let body = {
      "product_id": value._id
    }
    this.api.post(`delete_product_by_id`, body).subscribe(
      data => {
        console.log('!success', data);
        if (value.cover_image) {
          let public_id = value.cover_image.split("/")[8].split(".")[0];
          this.api.post(`remove_image`, { "image_id": `products/${public_id}` }).subscribe(
            data => {
              console.log(data)
            },
            error => console.log(error)
          )
        }
        if (value.image.length > 0) {
          value.image.map((res) => {
            let res_id = res.image_link.split("/")[8].split(".")[0];
            this.api.post(`remove_image`, { "image_id": `products/${res_id}` }).subscribe(
              data => {
                console.log(data)
                window.location.reload()
              },
              error => console.log(error)
            )
          })
        }
      },
      error => console.log(error)
    )
    console.log(body)
  }

  searchCat(value, endpoint) {
    this.manageProduct.serchByCatagory = value
    this.manageProduct.searchByGst = 'Filter By Tax'
    if (this.manageProduct.search !== ' ') {
      this.pageLink = endpoint
    } else {
      this.pageLink = 'product_list'
    }
    this.pagecount = '1'
    this.router.navigateByUrl(`/product-manage/${this.pageLink}/${this.manageProduct.search}/${this.pagecount}`)
    console.log(this.pageLink)
  }

  searchProduct(value, endpoint) {
    this.manageProduct.serchByCatagory = 'Filter By Catagory'
    this.manageProduct.searchByGst = value
    if (this.manageProduct.search !== ' ') {
      this.pageLink = endpoint
    } else {
      this.pageLink = 'product_list'
    }
    this.pagecount = '1'
    this.router.navigateByUrl(`/product-manage/${this.pageLink}/${this.manageProduct.search}/${this.pagecount}`)
    console.log(this.pageLink)
  }

  price(obj) {
    if (0 > obj.price) {
      obj.price = ''
      obj.mrp = ''
    } else {
      if (obj.discount) {
        obj.discount = parseFloat(obj.discount)
        let new_dis = obj.discount / 100
        obj.mrp = (obj.price / (1 - new_dis)).toFixed(2)
      } else {
        obj.mrp = obj.price ? parseFloat(obj.price) : ''
        if (isNaN(obj.mrp)) {
          obj.price = ''
          obj.mrp = ''
        }
      }
      if (obj.custom) {
        obj.custom.map((res) => res.variable_description.map((res) => res.price = obj.price))
      }
      if (obj.price && obj.productCostPrice) {
        obj.margin = (parseFloat(obj.price) - parseFloat(obj.productCostPrice)).toFixed(2)
        obj.margin > 0 ? obj.profit = Math.round((obj.margin / parseFloat(obj.price)) * 100) : obj.profit = 0
      }
    }
  }

  discount(obj) {
    if (obj.discount > 0) {
      if (obj.discount === Math.floor(obj.discount)) {
        obj.discount = parseInt(obj.discount)
      } else {
        obj.discount = Math.floor(parseFloat(obj.discount))
      }
      if (obj.price) {
        obj.price = parseFloat(obj.price);
        let new_dis = obj.discount / 100
        obj.mrp = (obj.price / (1 - new_dis)).toFixed(2)
        if (obj.custom) {
          obj.custom.map((res) => res.variable_description.map((res) => res.mrp = obj.mrp))
          obj.custom.map((res) => res.variable_description.map((res) => res.price = obj.price))
        }
      }
      if (obj.mrp) {
        obj.mrp = parseFloat(obj.mrp);
        obj.discount = parseFloat(obj.discount)
        let discount_amount = (obj.discount * obj.mrp) / 100;
        obj.price = (obj.mrp - discount_amount).toFixed(2)
      }
      if (obj.custom) {
        obj.custom.map((res) => res.variable_description.map((res) => res.discount = obj.discount))
      }
    } else {
      obj.discount = parseFloat(obj.discount)
      if (isNaN(obj.discount)) {
        obj.discount = ''
      }
      if (obj.mrp > 0) {
        obj.price = obj.mrp
      } else if (obj.price > 0) {
        obj.mrp = obj.price
      } else {
        obj.price = obj.price
        obj.mrp = obj.mrp
      }
    }
  }

  totalprice(obj) {
    if (0 > obj.mrp) {
      obj.mrp = ''
    } else {
      if (obj.discount) {
        obj.discount = parseFloat(obj.discount)
        obj.mrp = parseFloat(obj.mrp);
        let discount_amount = (obj.discount * obj.mrp) / 100;
        obj.price = (obj.mrp - discount_amount).toFixed(2);
      } else {
        obj.price = obj.mrp ? parseFloat(obj.mrp) : ''
        if (isNaN(obj.mrp)) {
          obj.price = ''
          obj.mrp = ''
        }
      }
      if (obj.custom) {
        obj.custom.map((res) => res.variable_description.map((res) => res.mrp = obj.mrp))
      }
    }
  }

  Pofit(obj) {
    if (obj.price && obj.productCostPrice) {
      obj.margin = (parseInt(obj.price) - parseInt(obj.productCostPrice)).toFixed(2)
      obj.margin > 0 ? obj.profit = Math.round((obj.margin / parseInt(obj.price)) * 100) : obj.margin = 0
    } else {
      obj.margin = 0;
      obj.profit = 0;
    }
  }

  quantityInStock(product) {
    if (product.quantity_in_stock) {
      product.quantity_in_stock = Math.round(parseInt(product.quantity_in_stock));
    }
    product.custom.map((res) => res.variable_description.map((res) => res.quantity = product.quantity_in_stock))
  }

  barcodeNo(product) {
    if (product.barcode_number) {
      product.barcode_number = product.barcode_number;
    }
    product.custom.map((res) => res.variable_description.map((res) => res.barcode = product.barcode_number))
  }

  sku(obj) {
    if (obj.sku) {
      obj.sku = obj.sku;
      if (this.sku_weight_mesurement.store_sku_manual_start_from) {
        if (!obj.sku.startsWith(this.sku_weight_mesurement.store_sku_manual_start_from)) {
          obj.sku_valid.status = 'fail'
          obj.sku_valid.message = `Sku is not Starts form ${this.sku_weight_mesurement.store_sku_manual_start_from}`
        } else {
          this.api.post(`check_sku`, { "sku": obj.sku }).subscribe(
            data => {
              obj.sku_valid = data
              if (obj.sku_valid.status === 'success') {
                setTimeout(() => {
                  obj.sku_valid.status = ''
                }, 1000);
              } else {
                obj.sku_valid.status = 'fail'
              }
            },
            error => console.log(error)
          )
        }
      } else {
        this.api.post(`check_sku`, { "sku": obj.sku }).subscribe(
          data => {
            obj.sku_valid = data
            if (obj.sku_valid.status === 'success') {
              setTimeout(() => {
                obj.sku_valid.status = ''
              }, 1000);
            }
          },
          error => console.log(error)
        )
      }
    }
    // obj.custom.map((res) => res.variable_description.map((res) => res.sku = obj.Math.random().toString(36).substr(2, 10)))
    obj.custom.map((res) => res.variable_description.map((res) => res.sku = obj.sku))
  }

  onType(v, obj) {
    obj.type_of_product = v.type_of_product_sub
    obj.type_of_product_addons = v.type_of_product_addons
    obj.attributeList = v.type_of_product_attribute
    let arr = []
    v.type_of_product_custom_varient.map((res) =>
      arr.push(
        {
          name: res.custom_varient_product_type, image: '../../../../assets/icon/settings.png', disable: false,
          everything: [{
            details: [{ name: res.custom_varient_product_type, list: res.custom_varient_value }],
            sameShow: true, differentShow: false,
          }],
          custom_varient_img1: res.custom_varient_img1,
          custom_varient_img2: res.custom_varient_img2
        }
      )
    )
    obj.varientsforSingleProductCustomforMake = arr
    let Array = []
    obj.varientsforSingleProductCustomforMake.map((res) => {
      Array.push({
        "variable_name": res.name,
        "custom_varient_img1": res.custom_varient_img1,
        "custom_varient_img2": res.custom_varient_img2,
        "variable_description": [
          {
            "value": '',
            "quantity": '',
            "discount": obj.discount,
            "price": obj.price,
            "sku": this.sku_weight_mesurement.store_generate_auto ? obj.sku : Math.random().toString(36).toUpperCase().substr(2, 10),
            "barcode": '',
            "mrp": obj.mrp
          },
        ]
      })
    })
    obj.custom = Array
  }

  productAttribute(attrparent, attr, product, e) {
    let hasparent = product.product_attribute.some((res) => res.attribute_name === attrparent.type_of_product_attribute_name)
    if (hasparent) {
      product.product_attribute.map((res) => {
        if (res.attribute_name === attrparent.type_of_product_attribute_name) {
          res.attribute_description = res.attribute_description.concat(' & ', attr.type_of_product_value)
        }
      })
    } else {
      product.product_attribute.unshift({ "attribute_name": attrparent.type_of_product_attribute_name, "attribute_description": attr.type_of_product_value, deleteButton: true })
    }
    if (e.target.checked) {
      if (attrparent.type_of_product_attribute_name === 'material') {
        if (product.wash_care) {
          if (product.wash_care.includes(attr.wash_care.toLowerCase()) === false) {
            product.wash_care = product.wash_care.concat(', ', attr.wash_care)
          }
        } else {
          product.wash_care = attr.wash_care
        }
        console.log(product.wash_care)
      }
    } else {
      product.product_attribute.map((res, i) => {
        if (res.attribute_name === attrparent.type_of_product_attribute_name) {
          let arr = res.attribute_description.split(' & ')
          let array = []
          arr.map((r) => {
            if (r !== attr.type_of_product_value) {
              array.push(r)
            }
          })
          res.attribute_description = array.join(' & ')
        }
      })
    }
  }

  selectCat(main, submain, sub, dropDown, obj) {
    if (sub) {
      obj.product_main_category = main.category_name,
        obj.product_main_category_id = main._id,
        obj.product_sub_main_category = submain.category_name,
        obj.product_sub_main_category_id = submain._id,
        obj.product_sub_category = sub.category_name,
        obj.category_id = sub._id
    } else if (submain) {
      obj.product_main_category = main.category_name,
        obj.product_main_category_id = main._id,
        obj.product_sub_main_category = submain.category_name,
        obj.product_sub_main_category_id = submain._id,
        obj.product_sub_category = '',
        obj.category_id = null
    } else {
      obj.product_sub_main_category = '',
        obj.product_sub_main_category_id = null,
        obj.product_sub_category = '',
        obj.category_id = null
    }
    this.api.get(`product_type_list/${main._id}`).subscribe(
      data => {
        let status = Object.values(data)[0]
        if (status === 'success') {
          Object.values(Object.values(data)[2]).map((res) => this.type_of_productList.push(res))
        } else {
          this.api.get(`product_type_list/${submain._id}`).subscribe(
            data => {
              let status = Object.values(data)[0]
              if (status === 'success') {
                Object.values(Object.values(data)[2]).map((res) => this.type_of_productList.push(res))
                console.log(this.type_of_productList)
              } else {
                this.api.get(`product_type_list/${main._id}`).subscribe(
                  data => {
                    let status = Object.values(data)[0]
                    if (status === 'success') {
                      Object.values(Object.values(data)[2]).map((res) => this.type_of_productList.push(res))
                      console.log(this.type_of_productList)
                    }
                  },
                  error => console.log('Error!', error)
                )
              }
            },
            error => console.log('Error!', error)
          )
        }
      },
      error => console.log('Error!', error)
    )
    dropDown.close()
  }

  deleteVar(list, item) {
    let array = list
    array.splice(list.indexOf(item), 1);
  }

  addVar(list, item) {
    list.push(item)
  }

  addVarient(a, vardes, form) {
    if (a.everything.length <= (a.everything[0].details[0].list.length - 2)) {
      console.log(false)
      a.disable = false
    } else {
      console.log(true)
      a.disable = true
    }
    a.everything.push(a.everything[0]);
    vardes.push({
      "value": "",
      "quantity": form.quantity_in_stock,
      "discount": form.price_discount_from_buyer,
      "price": form.price_from_buyer,
      "sku": this.sku_weight_mesurement.store_generate_auto ? form.sku : Math.random().toString(36).toUpperCase().substr(2, 10),
      "barcode": form.barcode,
      "mrp": form.mrp
    })
  };

  deleteVarients(list, index, custom_array) {
    let array = list.everything
    if (array.length > 1) {
      array.splice(index, 1);
      list.disable = false
      let custom = custom_array
      custom.splice(index, 1);
    }
  }

  optionDisable(val, arr) {
    arr.map((result) => {
      val.map((res) => {
        if (result.varient_name === res.variable_description) {
          result.status = false
        }
      })
    })
  }

  open(content, product) {
    this.modalService.open(content, { centered: true })
    let message = []
    if (product.product_var.length > 1) {
      product.product_var.map((res) => {
        if (res.variable_name && res.variable_description) {
          // product_var.push(res)
        } else {
          message.push(`${message.length + 1}. Product Variable Description is Empty`)
        }
      })
    }
    if (product.product_bulk_price.length > 1) {
      product.product_bulk_price.map((res) => {
        if (res.min_quantity && res.max_quantity) {
        } else {
          message.push(`${message.length + 1}. Product Bulk Price Is Empty`)
        }
      }
      )
    }
    if (product.product_attribute.length > 1) {
      product.product_attribute.map((res) => {
        if (res.attribute_name && res.attribute_description) {
        } else {
          message.push(`${message.length + 1}. Product Attribute List Is Empty`)
        }
      })
    }
    message.length > 0 ? this.manageProduct.updateMessage = message.join(" ") : 'Are You surely Want to update this product'
  }

  _handelEdit(product) {
    this.loader = true
    product.gst ? product.gst_id = product.gst : ''
    product.brand ? product.brand_id = product.brand : ''
    product.product_cost_price = parseFloat(product.product_cost_price)
    product.gift_wrap = 'true' ? product.gift_wrap = true : false
    let product_var = []
    if (product.product_var.length > 1) {
      product.product_var.map((res) => {
        if (res.variable_name && res.variable_description) {
          product_var.push(res)
        }
      })
    }
    product.product_var = product_var
    let product_bulk_price = []
    if (product.product_bulk_price.length > 1) {
      product.product_bulk_price.map((res) => {
        if (res.min_quantity && res.max_quantity) {
          product_bulk_price.push(res)
        }
      }
      )
    }
    product.product_bulk_price = product_bulk_price
    let product_attribute = []
    if (product.product_attribute.length > 1) {
      product.product_attribute.map((res) => {
        if (res.attribute_name && res.attribute_description) {
          product_attribute.push(res)
        }
      })
    }
    product.product_attribute = product_attribute
    let array = []
    product.custom_varient = []
    product.custom.map((res) => {
      res.variable_description.map((result, i) => {
        if (!result.value) {
          res.variable_description.splice(i, 1)
        } else {
          array.push(parseFloat(result.price))
        }
      })
    })
    product.custom.map((res) => {
      if (res.variable_description.length > 0) {
        product.custom_varient.push(res)
      }
    })
    if (product.custom.length > 0) {
      product.custom.map((res) => {
        res.variable_description.find((result) => {
          if (parseFloat(result.price) === Math.min(...array)) {
            console.log(result)
            product.price_from_buyer = parseFloat(result.price),
              product.price_discount_from_buyer = parseFloat(result.discount),
              product.mrp = parseFloat(result.mrp)
            product.barcode_number = result.barcode
            product.sku = result.sku
          }
        })
      })
    }
    this.api.put(`update_all_product`, product).subscribe(
      data => {
        console.log(data)
        if (data.status === 'success') {
          this.manageProduct.updateMessage = 'Product SuccessFully Updated'
          window.location.reload()
        }
      },
      error => console.log(error)
    )
  }


  constructor(private modalService: NgbModal, private api: ApiService, public router: Router, private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.pageLink = params.get('param1')
      this.manageProduct.search = params.get('param2')
      this.pagecount = params.get('param3')
      this.addMore = JSON.stringify(parseInt(this.pagecount) + 1)
      if (this.manageProduct.search === ' ') {
        this.pageLink = `product_list`
        this.feturePerCat = []
        this.api.get(`${this.pageLink}?page=${this.pagecount}&limit=10`).subscribe(
          data => {
            if (Object.values(data)[0] === 'success') {
              this.products = Object.values(data)[2]
              this.loader = false
              this.products.map((res) => {
                res.gst_id ? res.gst = res.gst_id._id : res.gst = ''
                res.brand_id ? res.brand = res.brand_id._id : res.brand = ''
                res.brand_id ? res.brand_name = res.brand_id.brand_name : res.brand_name = ''
                res.gift_wrap = res.gift_wrap.toString()
                res.margin = Math.round(res.price_from_buyer - res.product_cost_price);
                res.profit = Math.round((res.margin / res.price_from_buyer) * 100);
                res.product_var.length === 0 ? res.product_var = [{ variable_name: '', variable_description: '' }] : res.product_var = res.product_var
                res.product_bulk_price.length === 0 ? res.product_bulk_price = [{ min_quantity: '', max_quantity: '', price: '' }] : res.product_bulk_price = res.product_bulk_price
                res.status = false
                res.sku_valid = { status: '', message: '' }
                if (res.product_upload_type === "bulk_product_upload") {
                  res.bg_color = "rgb(207, 248, 212)"
                } else if (res.product_upload_type === "set_product_upload" && res.set_single_product.single_product_type && !res.set_single_product.set_product_type) {
                  res.bg_color = "rgb(255, 217, 223)"
                } else if (res.product_upload_type === "set_product_upload" && !res.set_single_product.single_product_type && res.set_single_product.set_product_type) {
                  res.bg_color = "rgb(219, 249, 250)"
                } else if (res.product_upload_type === "set_product_upload" && res.set_single_product.single_product_type && res.set_single_product.set_product_type) {
                  res.bg_color = "rgb(232, 202, 240)"
                } else if (res.product_upload_type === "single product upload") {
                  res.bg_color = "rgb(255, 217, 223)"
                } else if (res.product_upload_type === "set_product_upload" && res.combo_product) {
                  res.bg_color = "rgb(244, 248, 207)"
                }
              })
            }
            console.log(data)
          },
          error => console.log('Error!', error)
        )
      } else {
        this.pageLink = params.get('param1')
        switch (this.pageLink) {
          case `product_by_cat_id`:
            this.api.get(`${this.pageLink}/${this.manageProduct.search}?page=${this.pagecount}&limit=10`).subscribe(
              data => {
                if (Object.values(data)[0] === 'success') {
                  this.products = Object.values(data)[2]
                  this.loader = false
                  this.products.map((res) => {
                    res.gst_id ? res.gst = res.gst_id._id : res.gst = ''
                    res.brand_id ? res.brand = res.brand_id._id : res.brand = ''
                    res.brand_id ? res.brand_name = res.brand_id.brand_name : res.brand_name = ''
                    res.gift_wrap = res.gift_wrap.toString()
                    res.margin = Math.round(res.price_from_buyer - res.product_cost_price);
                    res.profit = Math.round((res.margin / res.price_from_buyer) * 100);
                    res.product_var.length === 0 ? res.product_var = [{ variable_name: '', variable_description: '' }] : res.product_var = res.product_var
                    res.product_bulk_price.length === 0 ? res.product_bulk_price = [{ min_quantity: '', max_quantity: '', price: '' }] : res.product_bulk_price = res.product_bulk_price
                    res.status = false
                    res.sku_valid = { status: '', message: '' }
                    if (res.product_upload_type === "bulk_product_upload") {
                      res.bg_color = "rgb(207, 248, 212)"
                    } else if (res.product_upload_type === "set_product_upload" && res.set_single_product.single_product_type && !res.set_single_product.set_product_type) {
                      res.bg_color = "rgb(255, 217, 223)"
                    } else if (res.product_upload_type === "set_product_upload" && !res.set_single_product.single_product_type && res.set_single_product.set_product_type) {
                      res.bg_color = "rgb(219, 249, 250)"
                    } else if (res.product_upload_type === "set_product_upload" && res.set_single_product.single_product_type && res.set_single_product.set_product_type) {
                      res.bg_color = "rgb(232, 202, 240)"
                    } else if (res.product_upload_type === "single product upload") {
                      res.bg_color = "rgb(255, 217, 223)"
                    } else if (res.product_upload_type === "set_product_upload" && res.combo_product) {
                      res.bg_color = "rgb(244, 248, 207)"
                    }
                  })
                }
                console.log(data)
              },
              error => console.log('Error!', error)
            )
            this.api.post(`populate_featured_product_category_by_category`, { featured_product_category_id: this.manageProduct.search }).subscribe(
              data => {
                console.log(data)
                this.feturePerCat = data.result
                this.feturePerCat.map((res) => res.status = false)
              },
              error => console.log(error)
            )
            break;
          case `populate_featured_product_by_fetured_product_id`:
            this.feturePerCat = []
            this.api.post(this.pageLink, { featured_product_id: this.manageProduct.search }).subscribe(
              data => {
                console.log(data)
                if (data.status === 'success') {
                  this.products = data.response[0].featured_product_product_id
                  this.loader = false
                  this.products.map((res) => {
                    res.gst_id ? res.gst = res.gst_id._id : res.gst = ''
                    res.brand_id ? res.brand = res.brand_id._id : res.brand = ''
                    res.brand_id ? res.brand_name = res.brand_id.brand_name : res.brand_name = ''
                    res.gift_wrap = res.gift_wrap.toString()
                    res.margin = Math.round(res.price_from_buyer - res.product_cost_price);
                    res.profit = Math.round((res.margin / res.price_from_buyer) * 100);
                    res.product_var.length === 0 ? res.product_var = [{ variable_name: '', variable_description: '' }] : res.product_var = res.product_var
                    res.product_bulk_price.length === 0 ? res.product_bulk_price = [{ min_quantity: '', max_quantity: '', price: '' }] : res.product_bulk_price = res.product_bulk_price
                    res.status = false
                    res.sku_valid = { status: '', message: '' }
                    if (res.product_upload_type === "bulk_product_upload") {
                      res.bg_color = "rgb(207, 248, 212)"
                    } else if (res.product_upload_type === "set_product_upload" && res.set_single_product.single_product_type && !res.set_single_product.set_product_type) {
                      res.bg_color = "rgb(255, 217, 223)"
                    } else if (res.product_upload_type === "set_product_upload" && !res.set_single_product.single_product_type && res.set_single_product.set_product_type) {
                      res.bg_color = "rgb(219, 249, 250)"
                    } else if (res.product_upload_type === "set_product_upload" && res.set_single_product.single_product_type && res.set_single_product.set_product_type) {
                      res.bg_color = "rgb(232, 202, 240)"
                    } else if (res.product_upload_type === "single product upload") {
                      res.bg_color = "rgb(255, 217, 223)"
                    } else if (res.product_upload_type === "set_product_upload" && res.combo_product) {
                      res.bg_color = "rgb(244, 248, 207)"
                    }
                  })
                }
              },
              error => console.log(error)
            )
            break;
          case `populate_featured_product_category_by_id`:
            this.api.post(this.pageLink, { _id: this.manageProduct.search }).subscribe(
              data => {
                console.log(data.result[0].featured_product_category_product_id)
                if (data.status === 'success') {
                  this.products = data.result[0].featured_product_category_product_id
                  this.loader = false
                  this.products.map((res) => {
                    res.gst_id ? res.gst = res.gst_id._id : res.gst = ''
                    res.brand_id ? res.brand = res.brand_id._id : res.brand = ''
                    res.brand_id ? res.brand_name = res.brand_id.brand_name : res.brand_name = ''
                    res.gift_wrap = res.gift_wrap.toString()
                    res.margin = Math.round(res.price_from_buyer - res.product_cost_price);
                    res.profit = Math.round((res.margin / res.price_from_buyer) * 100);
                    res.product_var.length === 0 ? res.product_var = [{ variable_name: '', variable_description: '' }] : res.product_var = res.product_var
                    res.product_bulk_price.length === 0 ? res.product_bulk_price = [{ min_quantity: '', max_quantity: '', price: '' }] : res.product_bulk_price = res.product_bulk_price
                    res.status = false
                    res.sku_valid = { status: '', message: '' }
                    if (res.product_upload_type === "bulk_product_upload") {
                      res.bg_color = "rgb(207, 248, 212)"
                    } else if (res.product_upload_type === "set_product_upload" && res.set_single_product.single_product_type && !res.set_single_product.set_product_type) {
                      res.bg_color = "rgb(255, 217, 223)"
                    } else if (res.product_upload_type === "set_product_upload" && !res.set_single_product.single_product_type && res.set_single_product.set_product_type) {
                      res.bg_color = "rgb(219, 249, 250)"
                    } else if (res.product_upload_type === "set_product_upload" && res.set_single_product.single_product_type && res.set_single_product.set_product_type) {
                      res.bg_color = "rgb(232, 202, 240)"
                    } else if (res.product_upload_type === "single product upload") {
                      res.bg_color = "rgb(255, 217, 223)"
                    } else if (res.product_upload_type === "set_product_upload" && res.combo_product) {
                      res.bg_color = "rgb(244, 248, 207)"
                    }
                  })
                }
              },
              error => console.log(error)
            )
            break;
          default:
            break;
        }
      }
    }
    )
    this.api.get(`brand_list`).subscribe(
      data => {
        this.brand = Object.values(data)[2];
        console.log(this.brand)
      },
      error => console.log('Error!', error)
    )
    this.api.get(`featured_product_list`).subscribe(
      data => {
        let fetureList = Object.values(data)[2]
        fetureList.map((res) => res.status = false)
        this.fetureList = fetureList
        // console.log(this.fetureList)
      },
      error => console.log('Error!', error)
    )
    this.api.get(`featured_product_category_list`).subscribe(
      data => {
        let fetureList = Object.values(data)[2].result;
        fetureList.map((res) => res.status = false)
        this.fetureCatList = fetureList
        console.log(data)
      },
      error => console.log('Error!', error)
    )
    this.api.get(`selected_country_list`).subscribe(
      data => {
        this.countries = Object.values(data)[2]
        console.log(this.countries)
        // let catagories;
        // this.countries.map((res) => {
        //   this.api.post(`get_footer_cat_subcat`, { "category_country_id": res._id, "category_country_name": res.selected_country_name }).subscribe(
        //     data => {
        //       this.catagories = Object.values(data)[2]
        //       if (this.catagories.length > 0) {
        //         this.catagoryList.push({ "category_country_name": res.selected_country_name, "catagory": "", "catagories": this.catagories })
        //       }
        //     },
        //     error => console.log('Error!', error)
        //   )
        // },
        //   console.log(this.catagoryList)
        // )
      },
      error => console.log('Error!', error)
    );
    this.api.get(`populate_all_category`).subscribe(
      data => {
        this.catagories = Object.values(data)[2]
        console.log(this.catagories)
        if (this.pageLink.includes('cat_id')) {
          this.productListByCatagoey = this.catagories
          this.productListByCatagoey.map((res) => {
            if (this.manageProduct.search === res.cat_id) {
              this.manageProduct.serchByCatagory = res.cat_name
            } else {
              if (res.child_cat && res.child_cat.length > 0) {
                res.child_cat.map((res1) => {
                  if (this.manageProduct.search === res1.cat_id) {
                    this.manageProduct.serchByCatagory = res1.cat_name
                  } else {
                    if (res1.child_cat && res1.child_cat.length > 0) {
                      res1.child_cat.map((res2) => {
                        if (this.manageProduct.search === res2.cat_id) {
                          this.manageProduct.serchByCatagory = res2.cat_name
                        }
                      })
                    }
                  }
                })
              }
            }
          })
        }
      },
      error => console.log('Error!', error)
    )
    this.api.get(`store_gst_list`).subscribe(
      data => {
        this.gst_list = Object.values(data)[2]
        this.gst_list.unshift({
          country_code: '', country_id: "", country_name: "", status: false,
          store_cgst_rate: 0,
          store_description_of_goods: 'null', _id: ''
        })
        console.log(this.gst_list)
        if (this.pageLink.includes('gst_id')) {
          this.gst_list.map((res) => {
            if (this.manageProduct.search === res._id) {
              this.manageProduct.searchByGst = res.store_hsn_code
            }
          })
        }
      },
      error => console.log('Error!', error)
    )
    this.api.get('store_sku_and_weight_list').subscribe(
      data => {
        this.sku_weight_mesurement = Object.values(data)[2][0]
        console.log(this.sku_weight_mesurement)
      },
      error => console.log('Error!', error)
    )
    this.api.get(`source_vendor_list`).subscribe(
      data => {
        this.vendorList = Object.values(data)[2];
        console.log(this.vendorList)
      },
      error => console.log('Error!', error)
    )
    this.api.get(`product_type_list/0`).subscribe(
      data => {
        let status = Object.values(data)[0]
        if (status === 'success') {
          this.type_of_productList = Object.values(data)[2]
        }
      },
      error => console.log(error)
    )
  }
}
