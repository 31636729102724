<div class="loaderBackground" *ngIf="loader">
    <div class="loaderparent"></div>
    <div class="loader">
        <div class="spinner-grow text-success mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success  mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success  mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>

<div class="container-fluid">
    <ngb-alert *ngIf="alert" class="message" [type]="type" (closed)="alert = false">{{message }}</ngb-alert>
    <ngb-tabset type="pills" class="heading">
        <ngb-tab>
            <ng-template ngbTabTitle><b>Single Product upload</b></ng-template>
            <ng-template ngbTabContent>
                <div>
                    <!-- image upload list -->
                    <div class="imageUpload border border-success my-2 px-2 d-flex flex-row">
                        <div class="text-center bg-light px-3 dropProducts">
                            <p class="m-2">1/20</p>
                            <p class="m-2">Drops Products Image Here</p>
                            <p class="m-2">(.PNG or .JPG)</p>
                            <label
                                style="padding: 10px; background: rgb(41, 170, 41); display:inline-flex;color: #fff;">Upload
                                Images
                                <input type="file" style="display: none;" accept="image/x-png,image/jpeg"
                                    (change)="onFileUpload($event, imageContent)"></label>
                        </div>

                        <div class="d-flex flex-row images">
                            <div class="text-center " *ngFor="let img of imgLinks">
                                <div class="p-2 border d-inline-flex">
                                    <img [src]="img.image_link" alt="">
                                    <i class="fas fa-times-circle text-success product-clean"
                                        (click)="deleteImg(img.image_link,imgLinks,singleProductForm)"></i>
                                </div>
                                <div>
                                    <div class="form-check form-check-inline">
                                        <input type="radio" name="coverimage" [value]="img.image_link"
                                            class="form-check-input mx-1" [(ngModel)]="singleProductForm.coverImg">
                                        <label for="cover" class="form-check-label mx-1">Cover</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <ng-template #imageContent let-modal>
                        <div class="modal-body">
                            <app-image-cropper [src]="uploadImage" (greetEvent)="getcropImage($event)"></app-image-cropper>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-light" (click)="closeModel()">Close</button>
                            <button type="button" [disabled]="loading" class="btn btn-success" (click)="uploadToSever()">
                                <div *ngIf="loading" class="spinner-border text-secondary" role="status"></div>
                                <span *ngIf="!loading">Save</span>
                            </button>
                        </div>
                    </ng-template>

                    <!-- product information -->
                    <form #singleProdyctUploadForm="ngForm" novalidate>
                        <div class="d-flex">
                            <div>
                                <label class="custom mb-0">This is a Physical Product
                                    <input type="checkbox" name="physicalProduct"
                                        [(ngModel)]="singleProductForm.productType">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="mx-2" *ngIf="singleProductForm.productType">
                                <div class="d-lg-inline-flex">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="physicalProductType" #new
                                            [(ngModel)]="singleProductForm.physical_product_condition" value="new">
                                        <label class="form-check-label" for="inlineRadio1">New</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="physicalProductType"
                                            value="refurbished"
                                            [(ngModel)]="singleProductForm.physical_product_condition" #refurbished>
                                        <label class="form-check-label" for="inlineRadio2">refurbished</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="physicalProductType"
                                            value="used" [(ngModel)]="singleProductForm.physical_product_condition"
                                            #used>
                                        <label class="form-check-label" for="inlineRadio2">used</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="my-4 row">
                            <!-- catagory -->
                            <div class="col-lg-2 col-6">
                                <div class="form-group productInfo">
                                    <label class="title" style="z-index: 2;">Catagory</label>
                                    <div ngbDropdown class="d-inline-block w-100" #CatDrop="ngbDropdown">
                                        <button style="overflow-x: scroll; overflow-y: hidden;"
                                            class="btn py-2 px-4 btn-block d-flex justify-content-between form-control"
                                            type="button" ngbDropdownToggle>
                                            <div>
                                                <span *ngIf="singleProductForm.product_main_category">
                                                    {{singleProductForm.product_main_category}}</span>
                                                <span *ngIf="singleProductForm.product_sub_main_category"><i
                                                        class="fas fa-angle-double-right m-1"></i>
                                                    {{singleProductForm.product_sub_main_category}}</span>
                                                <span *ngIf="singleProductForm.product_sub_category"><i
                                                        class="fas fa-angle-double-right m-1"></i>{{singleProductForm.product_sub_category}}</span>
                                            </div>
                                        </button>
                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                            style="min-height: auto; max-height: 400px; overflow-y: scroll; width: 400px;">
                                            <div class="bg-success p-2 text-center ">
                                                <a routerLink="/create-new-catagory" class="text-white"
                                                    routerLinkActive="active-link" target="_blank"> Create new
                                                    catagory</a>
                                            </div>
                                            <div class="input-group p-2">
                                                <div class="input-group-prepend" style="width: 15%;">
                                                    <span
                                                        class="input-group-text border-black rounded-0 bg-transparent border-right-0">
                                                        <i class="fas fa-search"></i></span>
                                                </div>
                                                <input type="text" class="search" ngModel name="searchCatagory"
                                                    #searchCatagory>
                                            </div>
                                            <ul class="list-group list-group-flush">
                                                <li class="list-group-item border-0"
                                                    *ngFor="let item of catagories; index as i">
                                                    <div class="d-flex">
                                                        <label class="custom mb-0">{{item.category_name}}
                                                            <input type="radio" [value]="item._id"
                                                                (click)="onCat(item,'','',CatDrop,singleProductForm)"
                                                                class="form-check-input m-2"
                                                                name="product_main_category_id"
                                                                [(ngModel)]="singleProductForm.product_main_category_id">
                                                            <span class="checkmark"></span>
                                                        </label>
                                                        <button class="btn p-0 ml-4" type="button"
                                                            *ngIf="item.main_sub_category.length > 0"
                                                            (click)="Showsubcat(item)">
                                                            <i
                                                                [class]="!item.click? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                        </button>
                                                    </div>
                                                    <ul class="list-group list-group-flush ml-4" *ngIf="item.click">
                                                        <li class="list-group-item border-0"
                                                            *ngFor="let val of item.main_sub_category; index as j">
                                                            <div class="d-flex">
                                                                <label class="custom mb-0">{{val.category_name}}
                                                                    <input type="radio" [value]="val._id"
                                                                        (click)="onCat(item,val,'',CatDrop,singleProductForm)"
                                                                        [(ngModel)]="singleProductForm.product_sub_main_category_id"
                                                                        class="form-check-input m-2"
                                                                        name="product_sub_main_category_id">
                                                                    <span class="checkmark"></span>
                                                                </label>
                                                                <button class="btn p-0 ml-4" type="button"
                                                                    *ngIf="val.sub_category.length > 0"
                                                                    (click)="Showsubcat(val)">
                                                                    <i
                                                                        [class]="!val.click? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                </button>
                                                            </div>
                                                            <ul class="list-group list-group-flush ml-4"
                                                                *ngIf="val.click">
                                                                <li class="list-group-item border-0"
                                                                    *ngFor="let v of val.sub_category; index as m">
                                                                    <div class="d-flex">
                                                                        <label class="custom mb-0">{{v.category_name}}
                                                                            <input type="radio" [value]="v._id"
                                                                                (click)="onCat(item,val,v,CatDrop,singleProductForm)"
                                                                                class="form-check-input m-2"
                                                                                [(ngModel)]="singleProductForm.catagory_id"
                                                                                name="catagory_id">
                                                                            <span class="checkmark"></span>
                                                                        </label>
                                                                        <button class="btn p-0 ml-4" type="button"
                                                                            *ngIf="v.sub_category">
                                                                            <i
                                                                                [class]="v.sub_category? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                        </button>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- type of product -->
                            <div class="col-lg-2 col-6">
                                <div class="form-group productInfo">
                                    <label class="title" style="z-index: 2;">Type of product</label>
                                    <div ngbDropdown class="d-inline-block w-100">
                                        <button
                                            class="btn py-2 px-4 btn-block d-flex justify-content-between form-control"
                                            type="button" ngbDropdownToggle>
                                            <span>{{singleProductForm.typeOfProduct}}</span>
                                        </button>
                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                            style="min-height: auto; max-height: 400px; overflow-y: scroll;">
                                            <div class="bg-success p-2 text-center ">
                                                <a routerLink="/brands" class="text-white"
                                                    routerLinkActive="active-link">
                                                    Create new Brand</a>
                                            </div>
                                            <div class="input-group p-2">
                                                <div class="input-group-prepend" style="width: 12%;">
                                                    <span
                                                        class="input-group-text border-black rounded-0 bg-transparent border-right-0">
                                                        <i class="fas fa-search"></i></span>
                                                </div>
                                                <input type="text" class="search" ngModel name="searchCatagory"
                                                    #searchCatagory>
                                            </div>
                                            <div class="text-center" *ngIf="singleProductForm.loader">
                                                <div class="spinner-border text-success" role="status">
                                                    <span class="visually-hidden"></span>
                                                </div>
                                            </div>
                                            <ul class="list-group list-group-flush">
                                                <li class="list-group-item border-0"
                                                    *ngFor="let item of type_of_productList; index as i">
                                                    <div class="d-flex">
                                                        <label class="custom mb-0">{{item.type_of_product_sub}}
                                                            <input type="radio" [value]="item.type_of_product_sub"
                                                                (click)="onType(item,singleProductForm)"
                                                                [(ngModel)]="singleProductForm.typeOfProduct"
                                                                class="form-check-input m-2" name="productType">
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Brand -->
                            <div class="col-lg-2 col-6">
                                <div class="form-group productInfo">
                                    <label class="title" style="z-index: 2;">Brand</label>
                                    <div ngbDropdown class="d-inline-block w-100" #brandDrop="ngbDropdown">
                                        <button
                                            class="btn py-2 px-4 btn-block d-flex justify-content-between form-control"
                                            type="button" ngbDropdownToggle>
                                            <span>{{singleProductForm.brandName}}</span>
                                        </button>
                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                            style="min-height: auto; max-height: 400px; overflow-y: scroll;"
                                            style="min-height: auto; max-height: 400px; overflow-y: scroll;">
                                            <div class="bg-success p-2 text-center ">
                                                <a routerLink="/brands" class="text-white"
                                                    routerLinkActive="active-link" target="_blank"> Create new Brand</a>
                                            </div>
                                            <div class="input-group" *ngIf="brands.length > 0">
                                                <div class="input-group p-2">
                                                    <div class="input-group-prepend" style="width: 15%;">
                                                        <span
                                                            class="input-group-text border-black rounded-0 bg-transparent border-right-0">
                                                            <i class="fas fa-search"></i></span>
                                                    </div>
                                                    <input type="text" class="search" ngModel name="searchCatagory"
                                                        #searchCatagory>
                                                </div>
                                            </div>
                                            <div class="">
                                                <ul class="list-group list-group-flush">
                                                    <li class="list-group-item border-0" *ngFor="let brand of brands">
                                                        <div class="d-flex">
                                                            <label class="custom mb-0">{{brand.brand_name}}
                                                                <input type="radio" [value]="brand._id"
                                                                    (click)="onBrand(brand,brandDrop,singleProductForm)"
                                                                    class="form-check-input m-2" name="brand"
                                                                    [(ngModel)]="singleProductForm.brandId">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- gst -->
                            <div class="col-lg-2">
                                <label class="title">HsnCode</label>
                                <select class="form-control" name="gst" min="0" [(ngModel)]="singleProductForm.gst_id">
                                    <option [value]="gst._id" *ngFor="let gst of gstList">
                                        {{gst.store_hsn_code}}({{gst.store_description_of_goods}})
                                    </option>
                                </select>
                            </div>

                            <!-- product name  -->
                            <div class="col-lg-4">
                                <div class="form-group productInfo">
                                    <label class="title">*Product Name</label>
                                    <input type="text" class="form-control" [(ngModel)]="singleProductForm.productName"
                                        name="productNameSingle" #productNameSingle>
                                </div>
                            </div>

                            <div class="col-12"
                                [class.d-none]="singleProductForm.varientsforSingleProductCustomforMake.length > 0 && singleProductForm.varientsforSingleProductCustomforMake[0].everything[0].differentShow">
                                <div class="row">
                                    <!-- price you Want form buyer -->
                                    <div class="col">
                                        <div class="form-group productInfo">
                                            <label class="title">*Price</label>
                                            <input type="text" class="form-control"
                                                [class.danger-box-shadow]="0 > singleProductForm.price"
                                                (keyup)="price(singleProductForm,$event)"
                                                [(ngModel)]="singleProductForm.price" name="PriceUWantFromBuyerSingle">
                                        </div>
                                    </div>
                                    <!-- discount shown to buyer  -->
                                    <div class="col">
                                        <div class="form-group productInfo">
                                            <label class="title">*Discount</label>
                                            <div>
                                                <input type="text" class="form-control"
                                                    name="DiscountToShownToBuyerSingle" maxlength="2" pattern="[0-9]*"
                                                    [(ngModel)]="singleProductForm.discount"
                                                    #DiscountToShownToBuyerSingle
                                                    (ngModelChange)="discount(singleProductForm)">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- MRP price -->
                                    <div class="col">
                                        <div class="form-group productInfo">
                                            <label class="title">*MRP</label>
                                            <div>
                                                <input type="text" class="form-control" name="mrp"
                                                    [(ngModel)]="singleProductForm.mrp"
                                                    (keyup)="totalprice(singleProductForm)">
                                            </div>
                                        </div>
                                    </div>

                                    <!-- product cost price  -->
                                    <div class="col">
                                        <div class="form-group productInfo">
                                            <label class="title">Product Cost Price</label>
                                            <div>
                                                <input type="text" class="form-control" name="ProductCostPriceSingle"
                                                    #ProductCostPriceSingle
                                                    [(ngModel)]="singleProductForm.productCostPrice"
                                                    (keyup)="Pofit(singleProductForm)">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- margin  -->
                                    <div class="col">
                                        <div class="form-group productInfo">
                                            <label class="title">Margin</label>
                                            <div class="form-control">
                                                {{singleProductForm.margin}}
                                            </div>
                                        </div>
                                    </div>
                                    <!-- profit  -->
                                    <div class="col">
                                        <div class="form-group productInfo">
                                            <label class="title">Profit</label>
                                            <div class="form-control">
                                                {{singleProductForm.profit}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- price shown to buyer  -->
                            <div class="col-lg-6">
                                <div class="form-group productInfo border border-dark d-flex justify-content-between p-1"
                                    style="height: 45px;">
                                    <p class="font-weight-bold my-auto text-size">Price Shown To Buyer</p>
                                    <p class="m-auto text-size previousOne" *ngIf="singleProductForm.mrp">
                                        Rs.{{singleProductForm.mrp}}</p>
                                    <p class="m-auto text-size" *ngIf="singleProductForm.price">
                                        Rs.{{singleProductForm.price}}<span class="text-danger"
                                            *ngIf="singleProductForm.discount">
                                            ({{singleProductForm.discount}}% off)</span></p>
                                    <button class="btn btn-sm btn-success text-size mx-2">Rs.99(10%)</button>
                                    <button class="btn btn-success text-size mx-2">Rs.109(10%)</button>
                                    <button class="btn btn-success text-size mx-2">Rs.110(10%)</button>
                                </div>
                            </div>

                            <!-- quantity in stock  -->
                            <div class="col-lg-2 col-6">
                                <div class="form-group productInfo">
                                    <label class="title">*Quantity in Stock</label>
                                    <div class="form-control d-flex justify-content-between">
                                        <div class="w-50 py-2">
                                            <input type="number" class="w-100 border-0 p-0"
                                                *ngIf="!unlimitedSingle.checked" min="0"
                                                [(ngModel)]="singleProductForm.quantity" name="QuantityInStockSingle"
                                                #QuantityInStockSingle (keyup)="quantityInStock(singleProductForm)">
                                        </div>
                                        <div class="w-50 py-2">
                                            <input type="checkbox" class="mx-2"
                                                [(ngModel)]="singleProductForm.quantity_in_stock_unlimited"
                                                name="unlimitedSingle" #unlimitedSingle>
                                            <label>Unlimited</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- avaliable shop upto  -->
                            <div class="col-lg-2 col-6">
                                <div class="productInfo">
                                    <label class="title">Avaliable to shop up to</label>
                                    <input class="form-control w-100" type="date" [min]="today | date:'yy-MM-dd'"
                                        name="AvaliableToShopUpToSingle"
                                        [(ngModel)]="singleProductForm.AvaliableToShopUpTo" #AvaliableToShopUpToSingle>
                                </div>
                            </div>

                            <!-- pre Order -->
                            <div class="col-lg-2 col-6">
                                <div class="form-group productInfo">
                                    <label class="title">Pre Order</label>
                                    <div class="form-control">
                                        <div class="form-check ">
                                            <label class="mr-4 my-1">Pre Order</label>
                                            <input type="checkbox" name="PreOrderSingle" #PreOrderSingle
                                                [(ngModel)]="singleProductForm.PreOrder">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- description  -->
                            <div class="col-lg-6">
                                <div class="form-group ">
                                    <label class="title">Description(Optional)</label>
                                    <textarea class="form-control" rows="4" [(ngModel)]="singleProductForm.Description"
                                        name="DescriptionSingle" #DescriptionSingle></textarea>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="row">

                                    <!-- expected launch date -->
                                    <div class="col-lg-4 col-6">
                                        <div class="productInfo">
                                            <label class="title">Expected Launch Date</label>
                                            <input class="form-control w-100" type="date"
                                                [min]="today | date:'yy-MM-dd'" [disabled]="!singleProductForm.PreOrder"
                                                [(ngModel)]="singleProductForm.expectedLaunchDate"
                                                name="ExpectedLaunchDateSingle" #ExpectedLaunchDateSingle>
                                        </div>
                                    </div>

                                    <!-- Sku -->
                                    <div class="col-lg-4" *ngIf="!sku_weight_mesurement.store_generate_auto">
                                        <div class="form-group productInfo">
                                            <label class="title">*SKU</label>
                                            <div class="input-group" *ngIf="sku_weight_mesurement.store_sku_prefix">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text rounded-0">
                                                        {{sku_weight_mesurement.store_sku_prefix}}
                                                    </div>
                                                </div>
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="singleProductForm.sku" name="SKUSingle"
                                                    #SKUSingle="ngModel" (keyup)="sku(singleProductForm)">
                                            </div>
                                            <input type="text" *ngIf="!sku_weight_mesurement.store_sku_prefix"
                                                class="form-control" [(ngModel)]="singleProductForm.sku"
                                                name="SKUSingle" #SKUSingle="ngModel"
                                                (keyup)="sku(singleProductForm,[])">
                                        </div>
                                        <div *ngIf="singleProductForm.sku_valid.status" class="p-2 btn" style="position: absolute;
                                            z-index: 250;
                                            width: 100%;
                                            bottom: -10px;
                                            left: 0;"
                                            [class.btn-success]="singleProductForm.sku_valid.status === 'success'"
                                            [class.btn-danger]="singleProductForm.sku_valid.status === 'fail'">
                                            <small>{{singleProductForm.sku_valid.message}}</small>
                                        </div>
                                    </div>

                                    <!-- barcode no -->
                                    <div class="col-lg-4 col-2">
                                        <div class="form-group productInfo">
                                            <label class="title">Barcode No</label>
                                            <div>
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="singleProductForm.barcode" name="BarcodeNoSingle"
                                                    #BarcodeNoSingle (keyup)="barcodeNo(singleProductForm)">
                                            </div>
                                        </div>
                                    </div>

                                    <!-- source Vendor -->
                                    <div class="col-lg-4 col-6">
                                        <div class="form-group productInfo">
                                            <label class="title" style="z-index: 2;">Source Vendor</label>
                                            <div ngbDropdown class="d-inline-block w-100">
                                                <button
                                                    class="btn py-2 px-4 btn-block d-flex justify-content-between form-control"
                                                    type="button" ngbDropdownToggle>
                                                    <span>{{singleProductForm.SourceVendor}}</span>
                                                </button>
                                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                                    style="min-height: auto; max-height: 400px; overflow-y: scroll;">
                                                    <div class="bg-success p-2 text-center ">
                                                        <a routerLink="/add-vendor" class="text-white"
                                                            routerLinkActive="active-link">
                                                            Add Vendor</a>
                                                    </div>
                                                    <div class="input-group p-2">
                                                        <div class="input-group-prepend" style="width: 12%;">
                                                            <span
                                                                class="input-group-text border-black rounded-0 bg-transparent border-right-0">
                                                                <i class="fas fa-search"></i></span>
                                                        </div>
                                                        <input type="text" class="search" ngModel name="searchCatagory"
                                                            #searchCatagory>
                                                    </div>
                                                    <div class="">
                                                        <ul class="list-group list-group-flush">
                                                            <li class="list-group-item border-0"
                                                                *ngFor="let item of vendorList; index as i">
                                                                <div class="d-flex">
                                                                    <label class="custom mb-0">{{item.primary_contact}}
                                                                        <input type="radio"
                                                                            [value]="item.primary_contact"
                                                                            (click)="SourceVendor(item,singleProductForm)"
                                                                            [(ngModel)]="singleProductForm.SourceVendor"
                                                                            class="form-check-input m-2"
                                                                            name="SourceVendor">
                                                                        <span class="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- refference Sku -->
                                    <div class="col-lg-4">
                                        <div class="form-group productInfo">
                                            <label class="title">Refference SKU</label>
                                            <input type="text" class="form-control"
                                                [(ngModel)]="singleProductForm.RefferenceSKU" name="RefferenceSKUSingle"
                                                #RefferenceSKUSingle>
                                        </div>
                                    </div>

                                    <!-- country origin  -->
                                    <div class="col-lg-4">
                                        <div class="form-group productInfo">
                                            <label class="title">*Country origin name</label>
                                            <input type="text" class="form-control"
                                                [(ngModel)]="singleProductForm.country_origin_name"
                                                name="country_origin" #countryOrigin>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- add video URL -->
                            <div class="col-lg-4">
                                <div class="form-group productInfo">
                                    <label class="title">Add Video URL</label>
                                    <input type="text" class="form-control" name="AddVideoURLSingle" #AddVideoURLSingle
                                        [(ngModel)]="singleProductForm.AddVideoURL">
                                </div>
                            </div>

                            <div class="col-2">
                                <div class="productInfo">
                                    <button class="btn form-control d-flex justify-content-between"
                                        (click)="open(content)">
                                        <span>Specifications</span>
                                        <span class="my-1 ml-4" style="font-size: 24px;"><i
                                                class="fas fa-expand"></i></span>
                                    </button>
                                    <ng-template #content let-modal>
                                        <div class="modal-header">
                                            <h4 class="modal-title" id="modal-basic-title">Specification List</h4>
                                            <button type="button" class="close" aria-label="Close"
                                                (click)="modal.dismiss('Cross click')">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <div class="d-flex">
                                                <ul ngbNav #nav="ngbNav" class="nav-pills" orientation="vertical">
                                                    <li ngbNavItem
                                                        *ngFor="let attribute of singleProductForm.attributeList; index as i">
                                                        <a ngbNavLink>{{attribute.type_of_product_attribute_name}}</a>
                                                        <ng-template ngbNavContent>
                                                            <div class="">
                                                                <ul class="list-group list-group-flush">
                                                                    <li class="list-group-item border-0"
                                                                        *ngFor="let attr of attribute.type_of_product_attribute_values; index as a">
                                                                        <div class="d-flex">
                                                                            <label
                                                                                class="custom mb-0">{{attr.type_of_product_value}}
                                                                                <input type="checkbox"
                                                                                    (click)="productAttribute(attribute,attr,singleProductForm,$event)"
                                                                                    ngModel class="form-check-input m-2"
                                                                                    name="attribute{{a}}">
                                                                                <span class="checkmark"></span>
                                                                            </label>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </ng-template>
                                                    </li>
                                                </ul>
                                                <div [ngbNavOutlet]="nav" class="ml-4"></div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>

                            <!-- gift wrap -->
                            <div class="col-4">
                                <div class="row">
                                    <div class="col-8">
                                        <div class="productInfo">
                                            <div class="form-control d-flex justify-content-between">
                                                <div class="my-auto ">Gift Wrap</div>
                                                <div class="form-check form-check-inline mr-0">
                                                    <label class="form-check-label mx-1">Yes </label>
                                                    <input class="form-check-input mx-1" type="radio"
                                                        name="GiftWrapOptions" value=true
                                                        [(ngModel)]="singleProductForm.giftWrap">
                                                </div>
                                                <div class="form-check form-check-inline mr-0">
                                                    <label class="form-check-label mx-1">No</label>
                                                    <input class="form-check-input mx-1" type="radio"
                                                        name="GiftWrapOptions" value=false
                                                        [(ngModel)]="singleProductForm.giftWrap">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-4">
                                        <div class="form-group productInfo">
                                            <label class="title mx-0">Wrapping Price</label>
                                            <input type="text" class="form-control" name="gift_wrap_price"
                                                [readonly]="!singleProductForm.giftWrap || singleProductForm.giftWrap ==='false'"
                                                #gift_wrap_price [(ngModel)]="singleProductForm.gift_wrap_price">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- weight -->
                            <div class="col-lg-2 col-3">
                                <div class="form-group productInfo ">
                                    <label class="title">Product Weight</label>
                                    <div class="input-group">
                                        <input type="number" class="form-control" [(ngModel)]="singleProductForm.weight"
                                            name="weightSingle" #weightSingle min="0">
                                        <div class="input-group-append">
                                            <div class="input-group-text rounded-0">
                                                {{unit}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="0 > singleProductForm.weight" class="p-2 btn btn-danger" style="position: absolute;
                                            z-index: 250;
                                            width: 100%;
                                            bottom: -10px;
                                            left: 0;">
                                    <small>Weight Can't be Negetive</small>
                                </div>
                            </div>

                            <!-- free shipping -->
                            <!-- <div class="col-lg-2 col-6">
                                <div class="form-group productInfo">
                                    <label class="title">Free Shipping</label>
                                    <div class="form-control">
                                        <div class="form-check ">
                                            <label class="mr-4 my-1">Free Shipping</label>
                                            <input type="checkbox" name="freeShipping" #freeShipping
                                                [(ngModel)]="singleProductForm.freeShipping">
                                        </div>
                                    </div>
                                </div>
                            </div> -->


                            <div class="col-6" *ngIf="singleProductForm.productAttribute.length > 0">
                                <div class="productInfo row"
                                    *ngFor="let item of singleProductForm.productAttribute;index as i">
                                    <!-- product attribute -->
                                    <div class="col-lg-5 col-6 form-group">
                                        <label class="title" style="z-index: 2;">Product Attribute</label>
                                        <div class="form-control" style="height: 45px;">
                                            <span>{{item.attribute_name}}</span>
                                        </div>
                                    </div>

                                    <!-- product attributeValue -->
                                    <div class="col-lg-6 col-6 form-group">
                                        <label class="title" style="z-index: 2;">Attribute Value</label>
                                        <div class="form-control" style="height: 45px;">
                                            <span>{{item.attribute_description}}</span>
                                        </div>
                                    </div>

                                    <div class="col-1 form-group">
                                        <button class="btn bg-white w-100 p-0 addDelete" *ngIf="item.deleteButton"
                                            type="button" (click)="deleteVar(singleProductForm.productAttribute,item)">
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                        <!-- <button class="btn bg-white w-100 p-0 addDelete" *ngIf="!item.deleteButton"
                                                type="button" (click)="addAttribute(singleProductForm)">
                                                <i class="fas fa-plus-circle"></i>
                                            </button> -->
                                    </div>
                                </div>
                            </div>

                            <!-- variable list -->
                            <div class="col-6">
                                <div class="productInfo d-flex"
                                    *ngFor="let item of singleProductForm.variableList;index as i">
                                    <div class="w-35 pr-3 form-group">
                                        <label class="title">Variable Name</label>
                                        <input type="text" class="form-control" name="VariableNameSingle{{i}}"
                                            [(ngModel)]="item.name">
                                    </div>
                                    <div class="w-69 px-3 form-group">
                                        <label class="title">Variable Description</label>
                                        <input type="text" class="form-control" name="VariableDesSingle{{i}}"
                                            [(ngModel)]="item.des">
                                    </div>
                                    <div class="w-6 form-group">
                                        <button class="btn bg-white w-100 p-0 addDelete" *ngIf="item.deleteButton"
                                            type="button" (click)="deleteVar(singleProductForm.variableList,item)">
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                        <button class="btn bg-white w-100 p-0 addDelete" *ngIf="!item.deleteButton"
                                            type="button" (click)="addMore(item,singleProductForm.variableList)">
                                            <i class="fas fa-plus-circle"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!-- bulk price -->
                            <div class="col-6">
                                <div class="row productInfo">
                                    <div class="col-2 text-center p-2">Bulk Price</div>
                                    <div class="col-10">
                                        <div class="row"
                                            *ngFor="let item of singleProductForm.bulkPricelist;index as i">
                                            <div class="col-3 form-group">
                                                <label class="title mx-2">Min Quality</label>
                                                <input type="text" class="form-control" [(ngModel)]="item.minQuality"
                                                    name="BulkPriceMinQualitySingle{{i}}"
                                                    #BulkPriceMinQualitySingle{{i}}>
                                            </div>
                                            <div class="col-3 form-group">
                                                <label class="title mx-2">Max Quality</label>
                                                <input type="text" class="form-control" [(ngModel)]="item.maxQuality"
                                                    name="BulkPriceMaxQualitySingle{{i}}"
                                                    #BulkPriceMaxQualitySingle{{i}}>
                                            </div>
                                            <div class="col-3 form-group">
                                                <label class="title mx-2">Price</label>
                                                <input type="text" class="form-control" ngModel [(ngModel)]="item.price"
                                                    name="BulkPriceSingle{{i}}" #BulkPriceSingle{{i}}>
                                            </div>
                                            <div class="col-1 form-group">
                                                <button type="button" class="btn bg-white w-100 p-0 addDelete"
                                                    *ngIf="item.deleteButton"
                                                    (click)="deleteVar(singleProductForm.bulkPricelist,i)">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                                <button type="button" class="btn bg-white w-100 p-0 addDelete"
                                                    *ngIf="!item.deleteButton"
                                                    (click)="addMore2(item,singleProductForm.bulkPricelist)">
                                                    <i class="fas fa-plus-circle"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- add varients -->
                            <div class="col-12">
                                <ngb-tabset type="pills" class="add-varients">
                                    <ngb-tab title="Add Varients (optional)" [disabled]="true">
                                    </ngb-tab>
                                    <ngb-tab
                                        *ngFor="let varient of singleProductForm.varientsforSingleProductCustomforMake;index as i">
                                        <ng-template ngbTabTitle>
                                            <div class="border text-center d-flex flex-column"
                                                style="width:100px;height: 60px;">
                                                <img [src]="varient.image" class="mx-auto" class="mx-auto my-1"
                                                    height="30px" width="30px" alt="">
                                                <small class="text-center">{{varient.name}}</small>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbTabContent>
                                            <div *ngFor="let a of varient.everything; index as m">
                                                <div class="row" *ngIf="a.sameShow">
                                                    <div class="col-lg-9 col-12 form-group my-3">
                                                        <div class="title float-left" style="top: 0px; left: -15px;">
                                                            {{m+1}}
                                                        </div>
                                                        <div class="productInfo border border-dark"
                                                            style="height: 70px;">
                                                            <div class="form-group d-flex w-75 title">
                                                                <div class="flex-fill px-2"
                                                                    *ngFor="let v of a.details;index as r">
                                                                    <label class="title text-capitalize">{{v.name}}
                                                                        name</label>
                                                                    <select class="form-control" name={{v.name}}{{m}}
                                                                        [(ngModel)]="singleProductForm.custom[i].variable_description[m].value"
                                                                        (change)="optionDisable(singleProductForm.custom[i].variable_description,v.list)">
                                                                        <option *ngFor="let lt of v.list"
                                                                            [value]=[lt.varient_name]
                                                                            [disabled]="!lt.status">
                                                                            {{lt.varient_name}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="title float-right" style="top:0px; right: -25px;"
                                                            *ngIf="varient.everything.length > 1">
                                                            <button
                                                                class="btn btn-outline-success p-1 rounded-0 addDelete"
                                                                (click)="deleteVarients(varient,m,singleProductForm.custom[i].variable_description)">
                                                                <i class="fas fa-times-circle"></i>
                                                            </button>
                                                        </div>

                                                    </div>
                                                    <div class="col-lg-3 col-12 form-group my-auto">
                                                        <button
                                                            class="btn productInfo bg-dark text-white p-2 my-lg-4 rounded-0"
                                                            (click)="sameShowing(m,varient.everything)">
                                                            Use Different price & quality
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="row" *ngIf="a.differentShow">
                                                    <div class="col-lg-9 col-12 form-group my-3">
                                                        <div class="title float-left" style="top: 0px; left: -15px;">
                                                            {{m+1}}
                                                        </div>
                                                        <div class=" productInfo  d-flex  flex-column border border-dark"
                                                            style="min-height: 100px;">
                                                            <div class="form-group d-flex w-75 title">
                                                                <div class="flex-fill px-2"
                                                                    *ngFor="let v of a.details;index as r">
                                                                    <label class="title text-capitalize">{{v.name}}
                                                                        name</label>
                                                                    <select class="form-control" name={{v.name}}{{m}}
                                                                        [(ngModel)]="singleProductForm.custom[i].variable_description[m].value">
                                                                        <option *ngFor="let lt of v.list"
                                                                            [value]=[lt.varient_name]>
                                                                            {{lt.varient_name}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="row mt-5 px-4">

                                                                <!-- price -->
                                                                <div class="col-4 ">
                                                                    <div class="form-group productInfo">
                                                                        <label class="title">Price You
                                                                            Want Form Buyer</label>
                                                                        <div class="d-flex justify-content-between">
                                                                            <input type="text" class="form-control "
                                                                                [(ngModel)]="singleProductForm.custom[i].variable_description[m].price"
                                                                                name="priceWantFromBuyercustom{{m}}"
                                                                                (keyup)="price(singleProductForm.custom[i].variable_description[m])">
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <!-- discount -->
                                                                <div class="col-4 ">
                                                                    <div class="form-group productInfo">
                                                                        <label for="inputEmail4"
                                                                            class="title mx-4">Discount
                                                                            Percentage to show
                                                                            Buyer</label>
                                                                        <div class="d-flex justify-content-between">
                                                                            <input type="text" class="form-control"
                                                                                name="discountFromBuyercustom{{m}}"
                                                                                [(ngModel)]="singleProductForm.custom[i].variable_description[m].discount"
                                                                                (keyup)="discount(singleProductForm.custom[i].variable_description[m])">
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!-- mrp rupees -->
                                                                <div class="col-4 ">
                                                                    <div class="form-group productInfo"><label
                                                                            for="inputEmail4" class="title">MRP</label>
                                                                        <div
                                                                            class="form-control d-flex justify-content-between">
                                                                            <div class="w-50 py-2">
                                                                                <input type="text"
                                                                                    class="w-100 border-0"
                                                                                    name="mrp{{m}}"
                                                                                    (keyup)="totalprice(singleProductForm.custom[i].variable_description[m])"
                                                                                    [(ngModel)]="singleProductForm.custom[i].variable_description[m].mrp">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!-- quantity in stock -->
                                                                <div class="col-4 ">
                                                                    <div class="form-group productInfo"><label
                                                                            for="inputEmail4" class="title">Quantity
                                                                            in Stock</label>
                                                                        <div
                                                                            class="form-control d-flex justify-content-between">
                                                                            <div class="w-50 py-2">
                                                                                <input type="text"
                                                                                    class="w-100 border-0"
                                                                                    name="quantitycustom{{m}}"
                                                                                    [(ngModel)]="singleProductForm.custom[i].variable_description[m].quantity">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!-- barcode no -->
                                                                <div class="col-lg-4 col-6">
                                                                    <div class="form-group productInfo">
                                                                        <label class="title">Barcode No</label>
                                                                        <div>
                                                                            <input type="text" class="form-control"
                                                                                name="BarcodeNoSinglecustom{{m}}"
                                                                                [(ngModel)]="singleProductForm.custom[i].variable_description[m].barcode"
                                                                                #BarcodeNoSingle>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- Sku -->
                                                                <div class="col-lg-4">
                                                                    <div class="form-group productInfo">
                                                                        <label class="title">SKU</label>
                                                                        <input type="text" class="form-control"
                                                                            [(ngModel)]="singleProductForm.custom[i].variable_description[m].sku"
                                                                            name="SKUSinglecustom{{m}}" #SKUSingle{{m}}>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12">
                                                                    <div class="form-group productInfo border border-dark d-flex justify-content-between p-1"
                                                                        style="height: 45px;">
                                                                        <p class="font-weight-bold m-auto text-size">
                                                                            Price
                                                                            Shown To Buyer</p>
                                                                        <p class="m-auto text-size previousOne">Rs.
                                                                            {{singleProductForm.custom[i].variable_description[m].price}}
                                                                        </p>
                                                                        <p class="m-auto text-size"
                                                                            *ngIf="singleProductForm.custom[i].variable_description[m].discount">
                                                                            Rs.
                                                                            {{singleProductForm.custom[i].variable_description[m].mrp}}
                                                                            <span
                                                                                class="text-danger">({{singleProductForm.custom[i].variable_description[m].discount}}%
                                                                                off)</span>
                                                                        </p>
                                                                        <button
                                                                            class="btn btn-sm btn-success text-size mx-2">Rs.99(10%)</button>
                                                                        <button
                                                                            class="btn btn-success text-size mx-2">Rs.109(10%)</button>
                                                                        <button
                                                                            class="btn btn-success text-size mx-2">Rs.110(10%)</button>
                                                                        <button
                                                                            class="btn btn-primary text-size rounded-0 mx-2">GST
                                                                            Applied</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="title float-right"
                                                                *ngIf="varient.everything.length > 1"
                                                                style="top:0px; right: -25px;">
                                                                <button
                                                                    class="btn btn-outline-success p-1 rounded-0 addDelete"
                                                                    (click)="deleteVarients(varient,m,singleProductForm.custom[i].variable_description)">
                                                                    <i class="fas fa-times-circle"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-12 form-group m-auto">
                                                        <button
                                                            class="btn rounded-0 productInfo bg-dark text-white p-2 my-lg-5"
                                                            (click)="differentShowing(m,varient.everything)">
                                                            Use Same price & quality
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <p class="my-auto mx-3">Add More(Optional)</p>
                                                <button class="btn p-0 border text-center d-flex flex-column"
                                                    style="width:100px;height: 60px;" [disabled]="varient.disable"
                                                    (click)="addVarient(varient,singleProductForm.custom[i].variable_description,singleProductForm)">
                                                    <img [src]="varient.image" class="mx-auto" height="40px" alt="">
                                                    <small class="m-auto">{{varient.name}}</small>
                                                </button>
                                            </div>
                                        </ng-template>
                                    </ngb-tab>
                                </ngb-tabset>
                            </div>
                            <div class="col-12">
                                <span>If you want to upload different color products go through set products
                                    upload</span>
                            </div>
                            <div class="col-lg-3 col-12 my-3 text-center">
                                <button class="btn btn-danger rounded-0" type="button"
                                    (click)="multipleProductsave('single product upload',[singleProductForm])">
                                    Save Product</button>
                            </div>
                        </div>
                    </form>
                </div>

            </ng-template>
        </ngb-tab>
        <ngb-tab>
            <ng-template ngbTabTitle><b>bulk Product upload</b></ng-template>
            <ng-template ngbTabContent>
                <div>
                    <!-- image upload list -->
                    <div class="imageUpload border border-success my-2 px-2 d-flex flex-row">
                        <div class="text-center bg-light px-3 dropProducts">
                            <p class="m-2">1/20</p>
                            <p class="m-2">Drops Products Image Here</p>
                            <p class="m-2">(.PNG or .JPG)</p>
                            <label
                                style="padding: 10px; background: rgb(41, 170, 41); display:inline-flex;color: #fff;">Upload
                                Images
                                <input type="file" style="display: none;" multiple accept="image/x-png,image/jpeg"
                                    (change)="onFileUpload($event, bulkimageContent)"></label>
                        </div>

                        <div class="d-flex flex-row images">
                            <div class="text-center " *ngFor="let img of bulkImageForms;index as i">
                                <div class="p-2 border d-inline-flex">
                                    <img [src]="img.coverImg" alt="">
                                    <i class="fas fa-times-circle text-success product-clean"
                                        (click)="deleteProduct(img,bulkImageForms)"></i>
                                </div>
                                <div>
                                    <div class="form-check form-check-inline">
                                        <input type="radio" name="coverimage{{i}}" [value]="img.coverImg"
                                            class="form-check-input mx-1" [(ngModel)]="img.coverImg">
                                        <label for="cover" class="form-check-label mx-1">Cover</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <ng-template #bulkimageContent let-modal>
                        <div class="modal-body">
                            <app-image-cropper [src]="uploadImage" (greetEvent)="getcropImage($event)"></app-image-cropper>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-light" (click)="closeModel()">Close</button>
                            <button type="button" [disabled]="loading" class="btn btn-success" (click)="onUpload(bulkImageForms)">
                                <div *ngIf="loading" class="spinner-border text-secondary" role="status"></div>
                                <span *ngIf="!loading">Save</span>
                            </button>
                        </div>
                    </ng-template>
                    <div class="my-3">
                        <div class="row">
                            <div class="col-3">Select one of the items to produce</div>
                            <div class="col-9">
                                <div class="row">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="price" #samepriceAndDes
                                            [(ngModel)]="priceAndDes" value="samepriceAndDes">
                                        <label class="form-check-label" for="inlineRadio1">Same Price and
                                            Description</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="price"
                                            [(ngModel)]="priceAndDes" value="differentPriceAndDes"
                                            #differentPriceAndDes>
                                        <label class="form-check-label" for="inlineRadio2">Dfferent Price and
                                            Description</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        *ngFor="let form of bulkImageForms| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as index">
                        <!-- product information -->
                        <form #bulkProductUploadForm="ngForm" novalidate *ngIf="samepriceAndDes.checked">
                            <div class="d-flex">
                                <div>
                                    <label class="custom mb-0">This is a Physical Product
                                        <input type="checkbox" name="physicalProduct" [(ngModel)]="form.productType">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="mx-2" *ngIf="form.productType">
                                    <div class="d-lg-inline-flex">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="physicalProductType" #new
                                                [(ngModel)]="form.physical_product_condition" value="new">
                                            <label class="form-check-label" for="inlineRadio1">New</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="physicalProductType"
                                                value="refurbished" [(ngModel)]="form.physical_product_condition"
                                                #refurbished>
                                            <label class="form-check-label" for="inlineRadio2">refurbished</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="physicalProductType"
                                                value="used" [(ngModel)]="form.physical_product_condition" #used>
                                            <label class="form-check-label" for="inlineRadio2">used</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="my-4 row">
                                <!-- catagory -->
                                <div class="col-lg-2 col-6">
                                    <div class="form-group productInfo">
                                        <label class="title" style="z-index: 2;">Catagory</label>
                                        <div ngbDropdown class="d-inline-block w-100" #CatDrop="ngbDropdown">
                                            <button style="overflow-x: scroll; overflow-y: hidden;"
                                                class="btn py-2 px-4 btn-block d-flex justify-content-between form-control"
                                                type="button" ngbDropdownToggle>
                                                <div>
                                                    <span *ngIf="form.product_main_category">
                                                        {{form.product_main_category}}</span>
                                                    <span *ngIf="form.product_sub_main_category"><i
                                                            class="fas fa-angle-double-right m-1"></i>
                                                        {{form.product_sub_main_category}}</span>
                                                    <span *ngIf="form.product_sub_category"><i
                                                            class="fas fa-angle-double-right m-1"></i>{{form.product_sub_category}}</span>
                                                </div>
                                            </button>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                                style="min-height: auto; max-height: 400px; overflow-y: scroll; width: 400px;">
                                                <div class="bg-success p-2 text-center ">
                                                    <a routerLink="/create-new-catagory" class="text-white"
                                                        routerLinkActive="active-link" target="_blank"> Create new
                                                        catagory</a>
                                                </div>
                                                <div class="input-group p-2">
                                                    <div class="input-group-prepend" style="width: 15%;">
                                                        <span
                                                            class="input-group-text border-black rounded-0 bg-transparent border-right-0">
                                                            <i class="fas fa-search"></i></span>
                                                    </div>
                                                    <input type="text" class="search" ngModel name="searchCatagory"
                                                        #searchCatagory>
                                                </div>
                                                <ul class="list-group list-group-flush">
                                                    <li class="list-group-item border-0"
                                                        *ngFor="let item of catagories; index as i">
                                                        <div class="d-flex">
                                                            <label class="custom mb-0">{{item.category_name}}
                                                                <input type="radio" [value]="item._id"
                                                                    (click)="onCat(item,'','',CatDrop,form)"
                                                                    class="form-check-input m-2"
                                                                    name="product_main_category_id"
                                                                    [(ngModel)]="form.product_main_category_id">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                            <button class="btn p-0 ml-4" type="button"
                                                                *ngIf="item.main_sub_category.length > 0"
                                                                (click)="Showsubcat(item)">
                                                                <i
                                                                    [class]="!item.click? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                            </button>
                                                        </div>
                                                        <ul class="list-group list-group-flush ml-4" *ngIf="item.click">
                                                            <li class="list-group-item border-0"
                                                                *ngFor="let val of item.main_sub_category; index as j">
                                                                <div class="d-flex">
                                                                    <label class="custom mb-0">{{val.category_name}}
                                                                        <input type="radio" [value]="val._id"
                                                                            (click)="onCat(item,val,'',CatDrop,form)"
                                                                            [(ngModel)]="form.product_sub_main_category_id"
                                                                            class="form-check-input m-2"
                                                                            name="product_sub_main_category_id">
                                                                        <span class="checkmark"></span>
                                                                    </label>
                                                                    <button class="btn p-0 ml-4" type="button"
                                                                        *ngIf="val.sub_category.length > 0"
                                                                        (click)="Showsubcat(val)">
                                                                        <i
                                                                            [class]="!val.click? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                    </button>
                                                                </div>
                                                                <ul class="list-group list-group-flush ml-4"
                                                                    *ngIf="val.click">
                                                                    <li class="list-group-item border-0"
                                                                        *ngFor="let v of val.sub_category; index as m">
                                                                        <div class="d-flex">
                                                                            <label
                                                                                class="custom mb-0">{{v.category_name}}
                                                                                <input type="radio" [value]="v._id"
                                                                                    (click)="onCat(item,val,v,CatDrop,form)"
                                                                                    class="form-check-input m-2"
                                                                                    [(ngModel)]="form.catagory_id"
                                                                                    name="catagory_id">
                                                                                <span class="checkmark"></span>
                                                                            </label>
                                                                            <button class="btn p-0 ml-4" type="button"
                                                                                *ngIf="v.sub_category">
                                                                                <i
                                                                                    [class]="v.sub_category? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                            </button>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- type of product -->
                                <div class="col-lg-2 col-6">
                                    <div class="form-group productInfo">
                                        <label class="title" style="z-index: 2;">Type of product</label>
                                        <div ngbDropdown class="d-inline-block w-100">
                                            <button
                                                class="btn py-2 px-4 btn-block d-flex justify-content-between form-control"
                                                type="button" ngbDropdownToggle>
                                                <span>{{form.typeOfProduct}}</span>
                                            </button>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                                style="min-height: auto; max-height: 400px; overflow-y: scroll;">
                                                <div class="bg-success p-2 text-center ">
                                                    <a routerLink="/brands" class="text-white"
                                                        routerLinkActive="active-link">
                                                        Create new Brand</a>
                                                </div>
                                                <div class="input-group p-2">
                                                    <div class="input-group-prepend" style="width: 12%;">
                                                        <span
                                                            class="input-group-text border-black rounded-0 bg-transparent border-right-0">
                                                            <i class="fas fa-search"></i></span>
                                                    </div>
                                                    <input type="text" class="search" ngModel name="searchCatagory"
                                                        #searchCatagory>
                                                </div>
                                                <div class="">
                                                    <ul class="list-group list-group-flush">
                                                        <li class="list-group-item border-0"
                                                            *ngFor="let item of type_of_productList; index as i">
                                                            <div class="d-flex">
                                                                <label class="custom mb-0">{{item.type_of_product_sub}}
                                                                    <input type="radio"
                                                                        [value]="item.type_of_product_sub"
                                                                        (click)="onType(item,form)"
                                                                        [(ngModel)]="form.typeOfProduct"
                                                                        class="form-check-input m-2" name="productType">
                                                                    <span class="checkmark"></span>
                                                                </label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Brand -->
                                <div class="col-lg-2 col-6">
                                    <div class="form-group productInfo">
                                        <label class="title" style="z-index: 2;">Brand</label>
                                        <div ngbDropdown class="d-inline-block w-100" #brandDrop="ngbDropdown">
                                            <button
                                                class="btn py-2 px-4 btn-block d-flex justify-content-between form-control"
                                                type="button" ngbDropdownToggle>
                                                <span>{{form.brandName}}</span>
                                            </button>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                                style="min-height: auto; max-height: 400px; overflow-y: scroll;">
                                                <div class="bg-success p-2 text-center ">
                                                    <a routerLink="/brands" class="text-white"
                                                        routerLinkActive="active-link" target="_blank"> Create new
                                                        Brand</a>
                                                </div>
                                                <div class="input-group" *ngIf="brands.length > 0">
                                                    <div class="input-group p-2">
                                                        <div class="input-group-prepend" style="width: 15%;">
                                                            <span
                                                                class="input-group-text border-black rounded-0 bg-transparent border-right-0">
                                                                <i class="fas fa-search"></i></span>
                                                        </div>
                                                        <input type="text" class="search" ngModel name="searchCatagory"
                                                            #searchCatagory>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <ul class="list-group list-group-flush">
                                                        <li class="list-group-item border-0"
                                                            *ngFor="let brand of brands">
                                                            <div class="d-flex">
                                                                <label class="custom mb-0">{{brand.brand_name}}
                                                                    <input type="radio" [value]="brand._id"
                                                                        (click)="onBrand(brand,brandDrop,form)"
                                                                        [(ngModel)]="form.brandId"
                                                                        class="form-check-input m-2" name="brand">
                                                                    <span class="checkmark"></span>
                                                                </label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- gst Percentage -->
                                <div class="col-lg-2">
                                    <label class="title">HSN Code</label>
                                    <select class="form-control" name="gst" [(ngModel)]="form.gst_id">
                                        <option [value]="gst._id" *ngFor="let gst of gstList">
                                            {{gst.store_hsn_code}}({{gst.store_description_of_goods}})
                                        </option>
                                    </select>
                                </div>

                                <!-- product name  -->
                                <div class="col-lg-4">
                                    <div class="form-group productInfo">
                                        <label class="title">*Product Name</label>
                                        <input type="text" class="form-control" [(ngModel)]="form.productName"
                                            name="productNameSingle" #productNameSingle>
                                    </div>
                                </div>

                                <div class="col-12"
                                    [class.d-none]="form.varientsforSingleProductCustomforMake.length > 0 && form.varientsforSingleProductCustomforMake[0].everything[0].differentShow">
                                    <div class="row">
                                        <!-- price you Want form buyer -->
                                        <div class="col-lg-2 col-6">
                                            <div class="form-group productInfo">
                                                <label class="title">*Price</label>
                                                <div class="">
                                                    <input type="text" class="form-control" (keyup)="price(form)"
                                                        [(ngModel)]="form.price" name="PriceUWantFromBuyerSingle"
                                                        #PriceUWantFromBuyerSingle>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- discount shown to buyer  -->
                                        <div class="col-lg-2 col-6">
                                            <div class="form-group productInfo">
                                                <label class="title">*Discount</label>
                                                <div>
                                                    <input type="text" class="form-control"
                                                        name="DiscountToShownToBuyerSingle" [(ngModel)]="form.discount"
                                                        #DiscountToShownToBuyerSingle (keyup)="discount(form)">
                                                </div>
                                            </div>
                                        </div>
                                        <!-- MRP price -->
                                        <div class="col-lg-2 col-6">
                                            <div class="form-group productInfo">
                                                <label class="title">*MRP</label>
                                                <div>
                                                    <input type="text" class="form-control" name="totalPrice"
                                                        [(ngModel)]="form.mrp" #totalPrice (keyup)="totalprice(form)">
                                                </div>
                                            </div>
                                        </div>

                                        <!-- product cost price  -->
                                        <div class="col-lg-2 col-6">
                                            <div class="form-group productInfo">
                                                <label class="title">Product Cost Price</label>
                                                <div>
                                                    <input type="text" class="form-control"
                                                        name="ProductCostPriceSingle" #ProductCostPriceSingle
                                                        [(ngModel)]="form.productCostPrice" (keyup)="Pofit(form)">
                                                </div>
                                            </div>
                                        </div>
                                        <!-- margin  -->
                                        <div class="col-lg-2 col-6">
                                            <div class="form-group productInfo">
                                                <label class="title">Margin</label>
                                                <div class="form-control">
                                                    {{form.margin}}
                                                </div>
                                            </div>
                                        </div>
                                        <!-- profit  -->
                                        <div class="col-lg-2 col-6">
                                            <div class="form-group productInfo">
                                                <label class="title">Profit</label>
                                                <div class="form-control">
                                                    {{form.profit}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- price shown to buyer  -->
                                <div class="col-lg-6">
                                    <div class="form-group productInfo border border-dark d-flex justify-content-between p-1"
                                        style="height: 45px;">
                                        <p class="font-weight-bold my-auto text-size">Price Shown To Buyer</p>
                                        <p class="m-auto text-size previousOne" *ngIf="form.mrp">
                                            Rs.{{form.mrp}}</p>
                                        <p class="m-auto text-size" *ngIf="form.price">
                                            Rs.{{form.price}}<span class="text-danger" *ngIf="form.discount">
                                                ({{form.discount}}% off)</span></p>
                                        <button class="btn btn-sm btn-success text-size mx-2">Rs.99(10%)</button>
                                        <button class="btn btn-success text-size mx-2">Rs.109(10%)</button>
                                        <button class="btn btn-success text-size mx-2">Rs.110(10%)</button>
                                    </div>
                                </div>

                                <!-- quantity in stock  -->
                                <div class="col-lg-2 col-6">
                                    <div class="form-group productInfo">
                                        <label class="title">*Quantity in Stock</label>
                                        <div class="form-control d-flex justify-content-between">
                                            <div class="w-50 py-2">
                                                <input type="number" class="w-100 border-0"
                                                    *ngIf="!unlimitedSingle.checked" [(ngModel)]="form.quantity"
                                                    name="QuantityInStockSingle" #QuantityInStockSingle
                                                    (keyup)="quantityInStock(form)">
                                            </div>
                                            <div class="w-50 py-2">
                                                <input type="checkbox" class="mx-2"
                                                    [(ngModel)]="form.quantity_in_stock_unlimited"
                                                    name="unlimitedSingle" #unlimitedSingle>
                                                <label>Unlimited</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- avaliable shop upto  -->
                                <div class="col-lg-2 col-6">
                                    <div class="productInfo">
                                        <label class="title">Avaliable to shop up to</label>
                                        <input class="form-control w-100" type="date" [min]="today | date:'yy-MM-dd'"
                                            name="AvaliableToShopUpToSingle" [(ngModel)]="form.AvaliableToShopUpTo"
                                            #AvaliableToShopUpToSingle>
                                    </div>
                                </div>


                                <!-- pre Order -->
                                <div class="col-lg-2 col-6">
                                    <div class="form-group productInfo">
                                        <label class="title">Pre Order</label>
                                        <div class="form-control">
                                            <div class="form-check ">
                                                <label class="mr-4 my-1">Pre Order</label>
                                                <input type="checkbox" ngModel name="PreOrderSingle" #PreOrderSingle
                                                    [(ngModel)]="form.PreOrder">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- description  -->
                                <div class="col-lg-6">
                                    <div class="form-group ">
                                        <label class="title">Description(Optional)</label>
                                        <textarea class="form-control" rows="4" [(ngModel)]="form.Description"
                                            name="DescriptionSingle" #DescriptionSingle></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="row">

                                        <!-- expected launch date -->
                                        <div class="col-lg-4 col-6">
                                            <div class="productInfo">
                                                <label class="title">Expected Launch Date</label>
                                                <input class="form-control w-100" type="date"
                                                    [min]="today | date:'yy-MM-dd'" [disabled]="!form.PreOrder"
                                                    [(ngModel)]="form.expectedLaunchDate"
                                                    name="ExpectedLaunchDateSingle" #ExpectedLaunchDateSingle>
                                            </div>
                                        </div>

                                        <!-- source Vendor -->
                                        <div class="col-lg-4 col-6">
                                            <div class="form-group productInfo">
                                                <label class="title" style="z-index: 2;">Source Vendor</label>
                                                <div ngbDropdown class="d-inline-block w-100">
                                                    <button
                                                        class="btn py-2 px-4 btn-block d-flex justify-content-between form-control"
                                                        type="button" ngbDropdownToggle>
                                                        <span>{{form.SourceVendor}}</span>
                                                    </button>
                                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                                        style="min-height: auto; max-height: 400px; overflow-y: scroll;">
                                                        <div class="bg-success p-2 text-center ">
                                                            <a routerLink="/add-vendor" class="text-white"
                                                                routerLinkActive="active-link">
                                                                Add Vendor</a>
                                                        </div>
                                                        <div class="input-group p-2">
                                                            <div class="input-group-prepend" style="width: 12%;">
                                                                <span
                                                                    class="input-group-text border-black rounded-0 bg-transparent border-right-0">
                                                                    <i class="fas fa-search"></i></span>
                                                            </div>
                                                            <input type="text" class="search" ngModel
                                                                name="searchCatagory" #searchCatagory>
                                                        </div>
                                                        <div class="">
                                                            <ul class="list-group list-group-flush">
                                                                <li class="list-group-item border-0"
                                                                    *ngFor="let item of vendorList; index as i">
                                                                    <div class="d-flex">
                                                                        <label
                                                                            class="custom mb-0">{{item.primary_contact}}
                                                                            <input type="radio"
                                                                                [value]="item.primary_contact"
                                                                                (click)="SourceVendor(item,form)"
                                                                                [(ngModel)]="form.SourceVendor"
                                                                                class="form-check-input m-2"
                                                                                name="SourceVendor">
                                                                            <span class="checkmark"></span>
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- barcode no -->
                                        <div class="col-lg-4 col-2">
                                            <div class="form-group productInfo">
                                                <label class="title">Barcode No</label>
                                                <div>
                                                    <input type="text" class="form-control" [(ngModel)]="form.barcode"
                                                        name="BarcodeNoSingle" #BarcodeNoSingle
                                                        (keyup)="barcodeNo(form)">
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Sku -->
                                        <!-- <div class="col-lg-4">
                                            <div class="form-group productInfo">
                                                <label class="title">*SKU</label>
                                                <input type="text" class="form-control" [(ngModel)]="form.sku"
                                                    name="SKUbulk" #SKUbulk (keyup)="sku(form)"
                                                    [class.is-invalid]="SKUbulk.invalid && SKUbulk.touched"
                                                    [class.border-danger]="SKUbulk.invalid && SKUbulk.touched">
                                            </div>
                                            <div *ngIf="form.sku_valid.status" class="p-2 btn" style="position: absolute;
                                            z-index: 250;
                                            width: 100%;
                                            bottom: -10px;
                                            left: 0;" [class.btn-success]="form.sku_valid.status === 'success'"
                                                [class.btn-danger]="form.sku_valid.status === 'fail'">
                                                <small>{{form.sku_valid.message}}</small>
                                            </div>
                                        </div> -->

                                        <!-- Sku -->
                                        <div class="col-lg-4" *ngIf="!sku_weight_mesurement.store_generate_auto">
                                            <div class="form-group productInfo">
                                                <label class="title">*SKU</label>
                                                <div class="input-group" *ngIf="sku_weight_mesurement.store_sku_prefix">
                                                    <div class="input-group-prepend">
                                                        <div class="input-group-text rounded-0">
                                                            {{sku_weight_mesurement.store_sku_prefix}}
                                                        </div>
                                                    </div>
                                                    <input type="text" class="form-control" [(ngModel)]="form.sku"
                                                        name="SKUSingle" #SKUSingle="ngModel"
                                                        (keyup)="sku(form,bulkImageForms)">
                                                </div>
                                                <input type="text" *ngIf="!sku_weight_mesurement.store_sku_prefix"
                                                    class="form-control" [(ngModel)]="form.sku" name="SKUSingle"
                                                    #SKUSingle="ngModel" (keyup)="sku(form,bulkImageForms)">
                                            </div>
                                            <div *ngIf="form.sku_valid.status" class="p-2 btn" style="position: absolute;
                                            z-index: 250;
                                            width: 100%;
                                            bottom: -10px;
                                            left: 0;" [class.btn-success]="form.sku_valid.status === 'success'"
                                                [class.btn-danger]="form.sku_valid.status === 'fail'">
                                                <small>{{form.sku_valid.message}}</small>
                                            </div>
                                        </div>

                                        <!-- country origin  -->
                                        <div class="col-lg-4">
                                            <div class="form-group productInfo">
                                                <label class="title">*Country origin name</label>
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="form.country_origin_name" name="country_origin"
                                                    #countryOrigin>
                                            </div>
                                        </div>

                                        <!-- refference Sku -->
                                        <div class="col-lg-4">
                                            <div class="form-group productInfo">
                                                <label class="title">Refference SKU</label>
                                                <input type="text" class="form-control" [(ngModel)]="form.RefferenceSKU"
                                                    name="RefferenceSKUSingle" #RefferenceSKUSingle>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- add video URL -->
                                <div class="col-lg-4">
                                    <div class="form-group productInfo">
                                        <label class="title">Add Video URL</label>
                                        <input type="text" class="form-control" name="AddVideoURLSingle"
                                            #AddVideoURLSingle [(ngModel)]="form.AddVideoURL">
                                    </div>
                                </div>

                                <!-- Specifications -->

                                <div class="col-2">
                                    <div class="productInfo">
                                        <button class="btn form-control d-flex justify-content-between"
                                            (click)="open(content)">
                                            <span>Specifications</span>
                                            <span class="my-1 ml-4" style="font-size: 24px;"><i
                                                    class="fas fa-expand"></i></span>
                                        </button>
                                        <ng-template #content let-modal>
                                            <div class="modal-header">
                                                <h4 class="modal-title" id="modal-basic-title">Specification List</h4>
                                                <button type="button" class="close" aria-label="Close"
                                                    (click)="modal.dismiss('Cross click')">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="d-flex">
                                                    <ul ngbNav #nav="ngbNav" class="nav-pills" orientation="vertical">
                                                        <li ngbNavItem
                                                            *ngFor="let attribute of form.attributeList; index as i">
                                                            <a
                                                                ngbNavLink>{{attribute.type_of_product_attribute_name}}</a>
                                                            <ng-template ngbNavContent>
                                                                <div class="">
                                                                    <ul class="list-group list-group-flush">
                                                                        <li class="list-group-item border-0"
                                                                            *ngFor="let attr of attribute.type_of_product_attribute_values; index as a">
                                                                            <div class="d-flex">
                                                                                <label
                                                                                    class="custom mb-0">{{attr.type_of_product_value}}
                                                                                    <input type="checkbox"
                                                                                        (click)="productAttribute(attribute,attr,form,$event)"
                                                                                        ngModel
                                                                                        class="form-check-input m-2"
                                                                                        name="attribute{{a}}">
                                                                                    <span class="checkmark"></span>
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </ng-template>
                                                        </li>
                                                    </ul>
                                                    <div [ngbNavOutlet]="nav" class="ml-4"></div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>

                                <!-- gift wrap -->
                                <div class="col-4">
                                    <div class="row">
                                        <div class="col-8">
                                            <div class="productInfo">
                                                <div class="form-control d-flex justify-content-between">
                                                    <div class="my-auto ">Gift Wrap</div>
                                                    <div class="form-check form-check-inline">
                                                        <label class="form-check-label mx-1">Yes </label>
                                                        <input class="form-check-input mx-1" type="radio"
                                                            name="GiftWrapOptions" value=true
                                                            [(ngModel)]="form.giftWrap">
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <label class="form-check-label mx-1">No</label>
                                                        <input class="form-check-input mx-1" type="radio"
                                                            name="GiftWrapOptions" value=false
                                                            [(ngModel)]="form.giftWrap">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-4">
                                            <div class="form-group productInfo">
                                                <label class="title mx-0">Wrapping Price</label>
                                                <input type="text" class="form-control" name="gift_wrap_price"
                                                    [readonly]="!form.giftWrap || form.giftWrap ==='false'"
                                                    [(ngModel)]="form.gift_wrap_price">
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <!-- weight -->
                                <div class="col-lg-2 col-3">
                                    <div class="form-group productInfo">
                                        <label class="title">Product Weight</label>
                                        <div class="input-group">
                                            <input type="number" class="form-control"
                                                [(ngModel)]="singleProductForm.weight" name="weightSingle"
                                                #weightSingle>
                                            <div class="input-group-append">
                                                <div class="input-group-text rounded-0">
                                                    {{unit}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- free shipping -->
                                <!-- <div class="col-lg-2 col-6">
                                    <div class="form-group productInfo">
                                        <label class="title">Free Shipping</label>
                                        <div class="form-control">
                                            <div class="form-check ">
                                                <label class="mr-4 my-1">Free Shipping</label>
                                                <input type="checkbox" name="freeShipping" #freeShipping
                                                    [(ngModel)]="form.freeShipping">
                                            </div>
                                        </div>
                                    </div>
                                </div> -->


                                <div class="col-6" *ngIf="form.productAttribute.length > 0">
                                    <div class="productInfo row" *ngFor="let item of form.productAttribute;index as i">
                                        <!-- product attribute -->
                                        <div class="col-lg-5 col-6 form-group">
                                            <label class="title" style="z-index: 2;">Product Attribute </label>
                                            <div class="form-control" style="height: 45px;">
                                                {{item.attribute_name}}
                                            </div>
                                        </div>

                                        <!-- product attributeValue -->
                                        <div class="col-lg-6 col-6 form-group">
                                            <label class="title" style="z-index: 2;">Attribute Value</label>
                                            <div class="form-control" style="height: 45px;">
                                                {{item.attribute_description}}
                                            </div>
                                        </div>

                                        <div class="col-1 form-group">
                                            <!-- <button class="btn bg-white w-100 p-0 addDelete" *ngIf="item.deleteButton"
                                                type="button" (click)="deleteVar(form.productAttribute,item)">
                                                <i class="fas fa-trash-alt"></i>
                                            </button> -->
                                            <button class="btn bg-white w-100 p-0 addDelete" *ngIf="!item.deleteButton"
                                                type="button" (click)="addAttribute(form)">
                                                <i class="fas fa-plus-circle"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <!-- variable list -->
                                <div class="col-6">
                                    <div class="productInfo d-flex" *ngFor="let item of form.variableList;index as i">
                                        <div class="w-35 pr-3 form-group">
                                            <label class="title">Variable Name</label>
                                            <input type="text" class="form-control" name="VariableNameSingle{{i}}"
                                                [(ngModel)]="item.name">
                                        </div>
                                        <div class="w-69 px-3 form-group">
                                            <label class="title">Variable Description</label>
                                            <input type="text" class="form-control" name="VariableDesSingle{{i}}"
                                                [(ngModel)]="item.des">
                                        </div>
                                        <div class="w-6 form-group">
                                            <button class="btn bg-white w-100 p-0 addDelete" *ngIf="item.deleteButton"
                                                type="button" (click)="deleteVar(form.variableList,item)">
                                                <i class="fas fa-trash-alt"></i>
                                            </button>
                                            <button class="btn bg-white w-100 p-0 addDelete" *ngIf="!item.deleteButton"
                                                type="button" (click)="addMore(item,form.variableList)">
                                                <i class="fas fa-plus-circle"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <!-- bulk price -->
                                <div class="col-6">
                                    <div class="row productInfo">
                                        <div class="col-2 text-center p-2">Bulk Price</div>
                                        <div class="col-10">
                                            <div class="row" *ngFor="let item of form.bulkPricelist;index as i">
                                                <div class="col-3 form-group">
                                                    <label class="title mx-2">Min Quality</label>
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="item.minQuality"
                                                        name="BulkPriceMinQualitySingle{{i}}"
                                                        #BulkPriceMinQualitySingle{{i}}>
                                                </div>
                                                <div class="col-3 form-group">
                                                    <label class="title mx-2">Max Quality</label>
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="item.maxQuality"
                                                        name="BulkPriceMaxQualitySingle{{i}}"
                                                        #BulkPriceMaxQualitySingle{{i}}>
                                                </div>
                                                <div class="col-3 form-group">
                                                    <label class="title mx-2">Price</label>
                                                    <input type="text" class="form-control" ngModel
                                                        [(ngModel)]="item.price" name="BulkPriceSingle{{i}}"
                                                        #BulkPriceSingle{{i}}>
                                                </div>
                                                <div class="col-1 form-group">
                                                    <button type="button" class="btn bg-white w-100 p-0 addDelete"
                                                        *ngIf="item.deleteButton"
                                                        (click)="deleteVar(form.bulkPricelist,i)">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                    <button type="button" class="btn bg-white w-100 p-0 addDelete"
                                                        *ngIf="!item.deleteButton"
                                                        (click)="addMore2(item,form.bulkPricelist)">
                                                        <i class="fas fa-plus-circle"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- add varients -->
                                <div class="col-12">
                                    <ngb-tabset type="pills" class="add-varients">
                                        <ngb-tab title="Add Varients (optional)" [disabled]="true">
                                        </ngb-tab>
                                        <ngb-tab
                                            *ngFor="let varient of form.varientsforSingleProductCustomforMake;index as i">
                                            <ng-template ngbTabTitle>
                                                <div class="border text-center d-flex flex-column"
                                                    style="width:100px;height: 60px;">
                                                    <img [src]="varient.image" class="mx-auto" class="mx-auto my-1"
                                                        height="30px" width="30px" alt="">
                                                    <small class="text-center">{{varient.name}}</small>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbTabContent>
                                                <div *ngFor="let a of varient.everything; index as m">
                                                    <div class="row" *ngIf="a.sameShow">
                                                        <div class="col-lg-9 col-12 form-group my-3">
                                                            <div class="title float-left"
                                                                style="top: 0px; left: -15px;">
                                                                {{m+1}}
                                                            </div>
                                                            <div class="productInfo border border-dark"
                                                                style="height: 70px;">
                                                                <div class="form-group d-flex w-75 title">
                                                                    <div class="flex-fill px-2"
                                                                        *ngFor="let v of a.details;index as r">
                                                                        <label class="title text-capitalize">{{v.name}}
                                                                            name</label>
                                                                        <select class="form-control"
                                                                            name={{v.name}}{{m}}
                                                                            [(ngModel)]="form.custom[i].variable_description[m].value"
                                                                            (change)="optionDisable(form.custom[i].variable_description,v.list)">
                                                                            <option *ngFor="let lt of v.list"
                                                                                [value]=[lt.varient_name]
                                                                                [disabled]="!lt.status">
                                                                                {{lt.varient_name}}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="title float-right"
                                                                style="top:0px; right: -25px;"
                                                                *ngIf="varient.everything.length > 1">
                                                                <button
                                                                    class="btn btn-outline-success p-1 rounded-0 addDelete"
                                                                    (click)="deleteVarients(varient,m,form.custom[i].variable_description)">
                                                                    <i class="fas fa-times-circle"></i>
                                                                </button>
                                                            </div>

                                                        </div>
                                                        <div class="col-lg-3 col-12 form-group my-auto">
                                                            <button
                                                                class="btn productInfo bg-dark text-white p-2 my-lg-4 rounded-0"
                                                                (click)="sameShowing(m,varient.everything)">
                                                                Use Different price & quality
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="row" *ngIf="a.differentShow">
                                                        <div class="col-lg-9 col-12 form-group my-3">
                                                            <div class="title float-left"
                                                                style="top: 0px; left: -15px;">
                                                                {{m+1}}
                                                            </div>
                                                            <div class=" productInfo  d-flex  flex-column border border-dark"
                                                                style="min-height: 100px;">
                                                                <div class="form-group d-flex w-75 title">
                                                                    <div class="flex-fill px-2"
                                                                        *ngFor="let v of a.details;index as r">
                                                                        <label class="title text-capitalize">{{v.name}}
                                                                            name</label>
                                                                        <select class="form-control"
                                                                            name={{v.name}}{{m}}
                                                                            [(ngModel)]="form.custom[i].variable_description[m].value">
                                                                            <option *ngFor="let lt of v.list"
                                                                                [value]=[lt.varient_name]>
                                                                                {{lt.varient_name}}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="row mt-5 px-4">

                                                                    <!-- price -->
                                                                    <div class="col-4 ">
                                                                        <div class="form-group productInfo">
                                                                            <label class="title">Price You
                                                                                Want Form Buyer</label>
                                                                            <div class="d-flex justify-content-between">
                                                                                <input type="text" class="form-control "
                                                                                    [(ngModel)]="form.custom[i].variable_description[m].price"
                                                                                    name="priceWantFromBuyercustom{{m}}"
                                                                                    (keyup)="price(form.custom[i].variable_description[m])">
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <!-- discount -->
                                                                    <div class="col-4 ">
                                                                        <div class="form-group productInfo">
                                                                            <label for="inputEmail4"
                                                                                class="title mx-4">Discount
                                                                                Percentage to show
                                                                                Buyer</label>
                                                                            <div class="d-flex justify-content-between">
                                                                                <input type="text" class="form-control"
                                                                                    name="discountFromBuyercustom{{m}}"
                                                                                    [(ngModel)]="form.custom[i].variable_description[m].discount"
                                                                                    (keyup)="discount(form.custom[i].variable_description[m])">
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <!-- mrp rupees -->
                                                                    <div class="col-4 ">
                                                                        <div class="form-group productInfo"><label
                                                                                for="inputEmail4"
                                                                                class="title">MRP</label>
                                                                            <div
                                                                                class="form-control d-flex justify-content-between">
                                                                                <div class="w-50 py-2">
                                                                                    <input type="text"
                                                                                        class="w-100 border-0"
                                                                                        name="mrp{{m}}"
                                                                                        [(ngModel)]="form.custom[i].variable_description[m].mrp"
                                                                                        (keyup)="mrp(form.custom[i].variable_description[m])">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <!-- quantity in stock -->
                                                                    <div class="col-4 ">
                                                                        <div class="form-group productInfo"><label
                                                                                for="inputEmail4" class="title">Quantity
                                                                                in Stock</label>
                                                                            <div
                                                                                class="form-control d-flex justify-content-between">
                                                                                <div class="w-50 py-2">
                                                                                    <input type="text"
                                                                                        class="w-100 border-0"
                                                                                        name="quantitycustom{{m}}"
                                                                                        [(ngModel)]="form.custom[i].variable_description[m].quantity">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <!-- barcode no -->
                                                                    <div class="col-lg-4 col-6">
                                                                        <div class="form-group productInfo">
                                                                            <label class="title">Barcode No</label>
                                                                            <div>
                                                                                <input type="text" class="form-control"
                                                                                    name="BarcodeNoSinglecustom{{m}}"
                                                                                    [(ngModel)]="form.custom[i].variable_description[m].barcode"
                                                                                    #BarcodeNoSingle>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- Sku -->
                                                                    <div class="col-lg-4">
                                                                        <div class="form-group productInfo">
                                                                            <label class="title">SKU</label>
                                                                            <input type="text" class="form-control"
                                                                                [(ngModel)]="form.custom[i].variable_description[m].sku"
                                                                                name="SKUSinglecustom{{m}}"
                                                                                #SKUSingle{{m}}>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class="form-group productInfo border border-dark d-flex justify-content-between p-1"
                                                                            style="height: 45px;">
                                                                            <p
                                                                                class="font-weight-bold m-auto text-size">
                                                                                Price
                                                                                Shown To Buyer</p>
                                                                            <p class="m-auto text-size previousOne">Rs.
                                                                                {{form.custom[i].variable_description[m].price}}
                                                                            </p>
                                                                            <p class="m-auto text-size"
                                                                                *ngIf="form.custom[i].variable_description[m].discount">
                                                                                Rs.
                                                                                {{form.custom[i].variable_description[m].mrp}}
                                                                                <span
                                                                                    class="text-danger">({{form.custom[i].variable_description[m].discount}}%
                                                                                    off)</span>
                                                                            </p>
                                                                            <button
                                                                                class="btn btn-sm btn-success text-size mx-2">Rs.99(10%)</button>
                                                                            <button
                                                                                class="btn btn-success text-size mx-2">Rs.109(10%)</button>
                                                                            <button
                                                                                class="btn btn-success text-size mx-2">Rs.110(10%)</button>
                                                                            <button
                                                                                class="btn btn-primary text-size rounded-0 mx-2">GST
                                                                                Applied</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="title float-right"
                                                                    style="top:0px; right: -25px;"
                                                                    *ngIf="varient.everything.length > 1">
                                                                    <button
                                                                        class="btn btn-outline-success p-1 rounded-0 addDelete"
                                                                        (click)="deleteVarients(varient,m,form.custom[i].variable_description)">
                                                                        <i class="fas fa-times-circle"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-3 col-12 form-group m-auto">
                                                            <button
                                                                class="btn rounded-0 productInfo bg-dark text-white p-2 my-lg-5"
                                                                (click)="differentShowing(m,varient.everything)">
                                                                Use Same price & quality
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-flex">
                                                    <p class="my-auto mx-3">Add Varients (optional)</p>
                                                    <button class="btn p-0 border text-center d-flex flex-column"
                                                        style="width:100px;height: 60px;" [disabled]="varient.disable"
                                                        (click)="addVarient(varient,form.custom[i].variable_description,form)">
                                                        <img [src]="varient.image" class="mx-auto" height="40px" alt="">
                                                        <small class="m-auto">{{varient.name}}</small>
                                                    </button>
                                                </div>
                                            </ng-template>
                                        </ngb-tab>
                                    </ngb-tabset>
                                </div>

                            </div>
                        </form>

                        <form #bulkProductUploaddiffForm="ngForm" novalidate *ngIf="differentPriceAndDes.checked">
                            <div class="d-flex">
                                <div>
                                    <label class="custom mb-0">This is a Physical Product
                                        <input type="checkbox" name="physicalProduct" [(ngModel)]="form.productType">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="mx-2" *ngIf="form.productType">
                                    <div class="d-lg-inline-flex">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="physicalProductType" #new
                                                [(ngModel)]="form.physical_product_condition" value="new">
                                            <label class="form-check-label" for="inlineRadio1">New</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="physicalProductType"
                                                value="refurbished" [(ngModel)]="form.physical_product_condition"
                                                #refurbished>
                                            <label class="form-check-label" for="inlineRadio2">refurbished</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="physicalProductType"
                                                value="used" [(ngModel)]="form.physical_product_condition" #used>
                                            <label class="form-check-label" for="inlineRadio2">used</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="my-4 row">

                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-lg-2">
                                            <div class="text-center border position-relative  border-black"
                                                style="height: 90%;">
                                                <img [src]="form.coverImg" height="180px" alt="" width="100%">
                                                <div class="bg-secondary text-white producti">
                                                    Product {{page}}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-10">
                                            <div class="row">

                                                <!-- catagory -->
                                                <div class="col-3">
                                                    <div class="form-group productInfo">
                                                        <label class="title" style="z-index: 2;">Catagory</label>
                                                        <div ngbDropdown class="d-inline-block w-100"
                                                            #CatDrop="ngbDropdown">
                                                            <button style="overflow-x: scroll; overflow-y: hidden;"
                                                                class="btn py-2 px-4 btn-block d-flex justify-content-between form-control"
                                                                type="button" ngbDropdownToggle>
                                                                <div>
                                                                    <span *ngIf="form.product_main_category">
                                                                        {{form.product_main_category}}</span>
                                                                    <span *ngIf="form.product_sub_main_category"><i
                                                                            class="fas fa-angle-double-right m-1"></i>
                                                                        {{form.product_sub_main_category}}</span>
                                                                    <span *ngIf="form.product_sub_category"><i
                                                                            class="fas fa-angle-double-right m-1"></i>{{form.product_sub_category}}</span>
                                                                </div>
                                                            </button>
                                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                                                style="min-height: auto; max-height: 400px; overflow-y: scroll; width: 400px;">
                                                                <div class="bg-success p-2 text-center ">
                                                                    <a routerLink="/create-new-catagory"
                                                                        class="text-white"
                                                                        routerLinkActive="active-link" target="_blank">
                                                                        Create new
                                                                        catagory</a>
                                                                </div>
                                                                <div class="input-group p-2">
                                                                    <div class="input-group-prepend"
                                                                        style="width: 15%;">
                                                                        <span
                                                                            class="input-group-text border-black rounded-0 bg-transparent border-right-0">
                                                                            <i class="fas fa-search"></i></span>
                                                                    </div>
                                                                    <input type="text" class="search" ngModel
                                                                        name="searchCatagory" #searchCatagory>
                                                                </div>
                                                                <ul class="list-group list-group-flush">
                                                                    <li class="list-group-item border-0"
                                                                        *ngFor="let item of catagories; index as i">
                                                                        <div class="d-flex">
                                                                            <label
                                                                                class="custom mb-0">{{item.category_name}}
                                                                                <input type="radio" [value]="item._id"
                                                                                    (click)="onCat(item,'','',CatDrop,form)"
                                                                                    class="form-check-input m-2"
                                                                                    name="product_main_category_id"
                                                                                    [(ngModel)]="form.product_main_category_id">
                                                                                <span class="checkmark"></span>
                                                                            </label>
                                                                            <button class="btn p-0 ml-4" type="button"
                                                                                *ngIf="item.main_sub_category.length > 0"
                                                                                (click)="Showsubcat(item)">
                                                                                <i
                                                                                    [class]="!item.click? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                            </button>
                                                                        </div>
                                                                        <ul class="list-group list-group-flush ml-4"
                                                                            *ngIf="item.click">
                                                                            <li class="list-group-item border-0"
                                                                                *ngFor="let val of item.main_sub_category; index as j">
                                                                                <div class="d-flex">
                                                                                    <label
                                                                                        class="custom mb-0">{{val.category_name}}
                                                                                        <input type="radio"
                                                                                            [value]="val._id"
                                                                                            (click)="onCat(item,val,'',CatDrop,form)"
                                                                                            [(ngModel)]="form.product_sub_main_category_id"
                                                                                            class="form-check-input m-2"
                                                                                            name="product_sub_main_category_id">
                                                                                        <span class="checkmark"></span>
                                                                                    </label>
                                                                                    <button class="btn p-0 ml-4"
                                                                                        type="button"
                                                                                        *ngIf="val.sub_category.length > 0"
                                                                                        (click)="Showsubcat(val)">
                                                                                        <i
                                                                                            [class]="!val.click? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                                    </button>
                                                                                </div>
                                                                                <ul class="list-group list-group-flush ml-4"
                                                                                    *ngIf="val.click">
                                                                                    <li class="list-group-item border-0"
                                                                                        *ngFor="let v of val.sub_category; index as m">
                                                                                        <div class="d-flex">
                                                                                            <label
                                                                                                class="custom mb-0">{{v.category_name}}
                                                                                                <input type="radio"
                                                                                                    [value]="v._id"
                                                                                                    (click)="onCat(item,val,v,CatDrop,form)"
                                                                                                    class="form-check-input m-2"
                                                                                                    [(ngModel)]="form.catagory_id"
                                                                                                    name="catagory_id">
                                                                                                <span
                                                                                                    class="checkmark"></span>
                                                                                            </label>
                                                                                            <button class="btn p-0 ml-4"
                                                                                                type="button"
                                                                                                *ngIf="v.sub_category">
                                                                                                <i
                                                                                                    [class]="v.sub_category? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                                            </button>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- Brand -->
                                                <div class="col">
                                                    <div class="form-group productInfo" style="height: 45px;">
                                                        <label class="title" style="z-index: 2;">Brand</label>
                                                        <div ngbDropdown class="d-inline-block w-100"
                                                            #brandDrop="ngbDropdown">
                                                            <button
                                                                class="btn py-2 px-4 btn-block d-flex justify-content-between form-control"
                                                                type="button" ngbDropdownToggle>
                                                                <span>{{form.brandName}}</span>
                                                            </button>
                                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                                                style="min-height: auto; max-height: 400px; overflow-y: scroll;">
                                                                <div class="bg-success p-2 text-center ">
                                                                    <a routerLink="/brands" class="text-white"
                                                                        routerLinkActive="active-link" target="_blank">
                                                                        Create new Brand</a>
                                                                </div>
                                                                <div class="input-group" *ngIf="brands.length > 0">
                                                                    <div class="input-group p-2">
                                                                        <div class="input-group-prepend"
                                                                            style="width: 15%;">
                                                                            <span
                                                                                class="input-group-text border-black rounded-0 bg-transparent border-right-0">
                                                                                <i class="fas fa-search"></i></span>
                                                                        </div>
                                                                        <input type="text" class="search" ngModel
                                                                            name="searchCatagory" #searchCatagory>
                                                                    </div>
                                                                </div>
                                                                <div class="">
                                                                    <ul class="list-group list-group-flush">
                                                                        <li class="list-group-item border-0"
                                                                            *ngFor="let brand of brands">
                                                                            <div class="d-flex">
                                                                                <label
                                                                                    class="custom mb-0">{{brand.brand_name}}
                                                                                    <input type="radio"
                                                                                        [value]="brand._id"
                                                                                        (click)="onBrand(brand,brandDrop,form)"
                                                                                        [(ngModel)]="form.brandId"
                                                                                        class="form-check-input m-2"
                                                                                        name="brand">
                                                                                    <span class="checkmark"></span>
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- type of product -->
                                                <div class="col">
                                                    <div class="form-group productInfo" style="height: 45px;">
                                                        <label class="title" style="z-index: 2;">Type of
                                                            product</label>
                                                        <div ngbDropdown class="d-inline-block w-100">
                                                            <button
                                                                class="btn py-2 px-4 btn-block d-flex justify-content-between form-control"
                                                                type="button" ngbDropdownToggle>
                                                                <span>{{form.typeOfProduct}}</span>
                                                            </button>
                                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                                                style="min-height: auto; max-height: 400px; overflow-y: scroll;">
                                                                <div class="bg-success p-2 text-center ">
                                                                    <a routerLink="/brands" class="text-white"
                                                                        routerLinkActive="active-link">
                                                                        Create new Brand</a>
                                                                </div>
                                                                <div class="input-group p-2">
                                                                    <div class="input-group-prepend"
                                                                        style="width: 12%;">
                                                                        <span
                                                                            class="input-group-text border-black rounded-0 bg-transparent border-right-0">
                                                                            <i class="fas fa-search"></i></span>
                                                                    </div>
                                                                    <input type="text" class="search" ngModel
                                                                        name="searchCatagory" #searchCatagory>
                                                                </div>
                                                                <div class="">
                                                                    <ul class="list-group list-group-flush">
                                                                        <li class="list-group-item border-0"
                                                                            *ngFor="let item of type_of_productList; index as i">
                                                                            <div class="d-flex">
                                                                                <label
                                                                                    class="custom mb-0">{{item.type_of_product_sub}}
                                                                                    <input type="radio"
                                                                                        [value]="item.type_of_product_sub"
                                                                                        (click)="onType(item,form)"
                                                                                        [(ngModel)]="form.typeOfProduct"
                                                                                        class="form-check-input m-2"
                                                                                        name="productType">
                                                                                    <span class="checkmark"></span>
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- product name  -->
                                                <div class="col-lg-5">
                                                    <div class="form-group productInfo">
                                                        <label class="title">*Product Name</label>
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="form.productName" name="productNameSingle"
                                                            #productNameSingle>
                                                    </div>
                                                </div>

                                                <div class="col-12"
                                                    [class.d-none]="form.varientsforSingleProductCustomforMake.length > 0 && form.varientsforSingleProductCustomforMake[0].everything[0].differentShow">
                                                    <div class="row">
                                                        <!-- price you Want form buyer -->
                                                        <div class="col-lg-2 col-6">
                                                            <div class="form-group productInfo">
                                                                <label class="title">*Price</label>
                                                                <div class="">
                                                                    <input type="text" class="form-control"
                                                                        (keyup)="price(form)" [(ngModel)]="form.price"
                                                                        name="PriceUWantFromBuyerSingle"
                                                                        #PriceUWantFromBuyerSingle>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- discount shown to buyer  -->
                                                        <div class="col-lg-2 col-6">
                                                            <div class="form-group productInfo">
                                                                <label class="title">*Discount</label>
                                                                <div>
                                                                    <input type="text" class="form-control"
                                                                        name="DiscountToShownToBuyerSingle"
                                                                        [(ngModel)]="form.discount"
                                                                        #DiscountToShownToBuyerSingle
                                                                        (keyup)="discount(form)">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- MRP price -->
                                                        <div class="col-lg-2 col-6">
                                                            <div class="form-group productInfo">
                                                                <label class="title">*MRP</label>
                                                                <div>
                                                                    <input type="text" class="form-control"
                                                                        name="totalPrice" [(ngModel)]="form.mrp"
                                                                        #totalPrice (keyup)="totalprice(form)">
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- product cost price  -->
                                                        <div class="col-lg-2 col-6">
                                                            <div class="form-group productInfo">
                                                                <label class="title">Product Cost Price</label>
                                                                <div>
                                                                    <input type="text" class="form-control"
                                                                        name="ProductCostPriceSingle"
                                                                        #ProductCostPriceSingle
                                                                        [(ngModel)]="form.productCostPrice"
                                                                        (keyup)="Pofit(form)">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- margin  -->
                                                        <div class="col-lg-2 col-6">
                                                            <div class="form-group productInfo">
                                                                <label class="title">Margin</label>
                                                                <div class="form-control">
                                                                    {{form.margin}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- profit  -->
                                                        <div class="col-lg-2 col-6">
                                                            <div class="form-group productInfo">
                                                                <label class="title">Profit</label>
                                                                <div class="form-control">
                                                                    {{form.profit}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- price shown to buyer  -->
                                                <div class="col-lg-7">
                                                    <div class="form-group productInfo border border-dark d-flex justify-content-between p-1"
                                                        style="height: 45px;">
                                                        <p class="font-weight-bold my-auto text-size">Price Shown To
                                                            Buyer</p>
                                                        <p class="m-auto text-size previousOne" *ngIf="form.mrp">
                                                            Rs.{{form.mrp}}</p>
                                                        <p class="m-auto text-size" *ngIf="form.price">
                                                            Rs.{{form.price}}<span class="text-danger"
                                                                *ngIf="form.discount">
                                                                ({{form.discount}}% off)</span></p>
                                                        <button
                                                            class="btn btn-sm btn-success text-size mx-2">Rs.99(10%)</button>
                                                        <button
                                                            class="btn btn-success text-size mx-2">Rs.109(10%)</button>
                                                        <button
                                                            class="btn btn-success text-size mx-2">Rs.110(10%)</button>
                                                    </div>
                                                </div>

                                                <!-- quantity in stock  -->
                                                <div class="col-lg">
                                                    <div class="form-group productInfo">
                                                        <label class="title">*Quantity in Stock</label>
                                                        <div class="form-control d-flex justify-content-between">
                                                            <div class="w-50 py-2">
                                                                <input type="number" class="w-100 border-0"
                                                                    *ngIf="!unlimitedSingle.checked"
                                                                    [(ngModel)]="form.quantity"
                                                                    name="QuantityInStockSingle" #QuantityInStockSingle
                                                                    (keyup)="quantityInStock(form)">
                                                            </div>
                                                            <div class="w-50 py-2">
                                                                <input type="checkbox" class="mx-2"
                                                                    [(ngModel)]="form.quantity_in_stock_unlimited"
                                                                    name="unlimitedSingle" #unlimitedSingle>
                                                                <label>Unlimited</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <!-- avaliable shop upto  -->
                                                <div class="col-lg">
                                                    <div class="productInfo">
                                                        <label class="title">Avaliable to shop up to</label>
                                                        <input class="form-control w-100" type="date"
                                                            [min]="today | date:'yy-MM-dd'"
                                                            name="AvaliableToShopUpToSingle"
                                                            [(ngModel)]="form.AvaliableToShopUpTo"
                                                            #AvaliableToShopUpToSingle>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- description  -->
                                <div class="col-lg-5">
                                    <div class="form-group ">
                                        <label class="title">Description(Optional)</label>
                                        <textarea class="form-control" rows="4" [(ngModel)]="form.Description"
                                            name="DescriptionSingle" #DescriptionSingle></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-7">
                                    <div class="row">

                                        <!-- gst Percentage -->
                                        <div class="col-3">
                                            <label class="title">HSN Code</label>
                                            <select class="form-control" name="gst" [(ngModel)]="form.gst_id">
                                                <option [value]="gst._id" *ngFor="let gst of gstList">
                                                    {{gst.store_hsn_code}}({{gst.store_description_of_goods}})
                                                </option>
                                            </select>
                                        </div>
                                        <!-- pre Order -->
                                        <div class="col-lg-auto col-6">
                                            <div class="form-group productInfo">
                                                <label class="title">Pre Order</label>
                                                <div class="form-control">
                                                    <div class="form-check ">
                                                        <label class="mr-4 my-1">Pre Order</label>
                                                        <input type="checkbox" ngModel name="PreOrderSingle"
                                                            #PreOrderSingle [(ngModel)]="form.PreOrder">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- expected launch date -->
                                        <div class="col-lg-auto col-6">
                                            <div class="productInfo">
                                                <label class="title">Expected Launch Date</label>
                                                <input class="form-control w-100" type="date"
                                                    [min]="today | date:'yy-MM-dd'" [disabled]="!form.PreOrder"
                                                    [(ngModel)]="form.expectedLaunchDate"
                                                    name="ExpectedLaunchDateSingle" #ExpectedLaunchDateSingle>
                                            </div>
                                        </div>

                                        <!-- barcode no -->
                                        <div class="col-lg-3">
                                            <div class="form-group productInfo">
                                                <label class="title">Barcode No</label>
                                                <div>
                                                    <input type="text" class="form-control" [(ngModel)]="form.barcode"
                                                        name="BarcodeNoSingle" #BarcodeNoSingle
                                                        (keyup)="barcodeNo(form)">
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Sku -->
                                        <div class="col-lg-3" *ngIf="!sku_weight_mesurement.store_generate_auto">
                                            <div class="form-group productInfo">
                                                <label class="title">*SKU</label>
                                                <div class="input-group" *ngIf="sku_weight_mesurement.store_sku_prefix">
                                                    <div class="input-group-prepend">
                                                        <div class="input-group-text rounded-0">
                                                            {{sku_weight_mesurement.store_sku_prefix}}
                                                        </div>
                                                    </div>
                                                    <input type="text" class="form-control" [(ngModel)]="form.sku"
                                                        name="SKUSingle" #SKUSingle="ngModel"
                                                        (keyup)="sku(form,bulkImageForms)">
                                                </div>
                                                <input type="text" *ngIf="!sku_weight_mesurement.store_sku_prefix"
                                                    class="form-control" [(ngModel)]="form.sku" name="SKUSingle"
                                                    #SKUSingle="ngModel" (keyup)="sku(form,bulkImageForms)">
                                            </div>
                                            <div *ngIf="form.sku_valid.status" class="p-2 btn" style="position: absolute;
                                            z-index: 250;
                                            width: 100%;
                                            bottom: -10px;
                                            left: 0;" [class.btn-success]="form.sku_valid.status === 'success'"
                                                [class.btn-danger]="form.sku_valid.status === 'fail'">
                                                <small>{{form.sku_valid.message}}</small>
                                            </div>
                                        </div>
                                        <!-- source Vendor -->
                                        <div class="col-lg-3 col-6">
                                            <div class="form-group productInfo" style="height: 45px;">
                                                <label class="title" style="z-index: 2;">Source Vendor</label>
                                                <div ngbDropdown class="d-inline-block w-100">
                                                    <button
                                                        class="btn py-2 px-4 btn-block d-flex justify-content-between form-control"
                                                        type="button" ngbDropdownToggle>
                                                        <span>{{form.SourceVendor}}</span>
                                                    </button>
                                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                                        style="min-height: auto; max-height: 400px; overflow-y: scroll;">
                                                        <div class="bg-success p-2 text-center ">
                                                            <a routerLink="/add-vendor" class="text-white"
                                                                routerLinkActive="active-link">
                                                                Add Vendor</a>
                                                        </div>
                                                        <div class="input-group p-2">
                                                            <div class="input-group-prepend" style="width: 12%;">
                                                                <span
                                                                    class="input-group-text border-black rounded-0 bg-transparent border-right-0">
                                                                    <i class="fas fa-search"></i></span>
                                                            </div>
                                                            <input type="text" class="search" ngModel
                                                                name="searchCatagory" #searchCatagory>
                                                        </div>
                                                        <div class="">
                                                            <ul class="list-group list-group-flush">
                                                                <li class="list-group-item border-0"
                                                                    *ngFor="let item of vendorList; index as i">
                                                                    <div class="d-flex">
                                                                        <label
                                                                            class="custom mb-0">{{item.primary_contact}}
                                                                            <input type="radio"
                                                                                [value]="item.primary_contact"
                                                                                (click)="SourceVendor(item,form)"
                                                                                class="form-check-input m-2"
                                                                                [(ngModel)]="form.SourceVendor"
                                                                                name="SourceVendor">
                                                                            <span class="checkmark"></span>
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- refference Sku -->
                                        <div class="col-lg-3">
                                            <div class="form-group productInfo">
                                                <label class="title">Refference SKU</label>
                                                <input type="text" class="form-control" [(ngModel)]="form.RefferenceSKU"
                                                    name="RefferenceSKUSingle" #RefferenceSKUSingle>
                                            </div>
                                        </div>

                                        <!-- Country origin name -->
                                        <div class="col-lg-3">
                                            <div class="form-group productInfo">
                                                <label class="title">*Country origin name</label>
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="form.country_origin_name" name="country_origin"
                                                    #countryOrigin>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- add video URL -->
                                <div class="col-lg-4">
                                    <div class="form-group productInfo">
                                        <label class="title">Add Video URL</label>
                                        <input type="text" class="form-control" name="AddVideoURLSingle"
                                            #AddVideoURLSingle [(ngModel)]="form.AddVideoURL">
                                    </div>
                                </div>

                                <!-- Specifications -->

                                <div class="col-2">
                                    <div class="productInfo">
                                        <button class="btn form-control d-flex justify-content-between"
                                            (click)="open(content)">
                                            <span>Specifications</span>
                                            <span class="my-1 ml-4" style="font-size: 24px;"><i
                                                    class="fas fa-expand"></i></span>
                                        </button>
                                        <ng-template #content let-modal>
                                            <div class="modal-header">
                                                <h4 class="modal-title" id="modal-basic-title">Specification List</h4>
                                                <button type="button" class="close" aria-label="Close"
                                                    (click)="modal.dismiss('Cross click')">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="d-flex">
                                                    <ul ngbNav #nav="ngbNav" class="nav-pills" orientation="vertical">
                                                        <li ngbNavItem
                                                            *ngFor="let attribute of form.attributeList; index as i">
                                                            <a
                                                                ngbNavLink>{{attribute.type_of_product_attribute_name}}</a>
                                                            <ng-template ngbNavContent>
                                                                <div class="">
                                                                    <ul class="list-group list-group-flush">
                                                                        <li class="list-group-item border-0"
                                                                            *ngFor="let attr of attribute.type_of_product_attribute_values; index as a">
                                                                            <div class="d-flex">
                                                                                <label
                                                                                    class="custom mb-0">{{attr.type_of_product_value}}
                                                                                    <input type="checkbox"
                                                                                        (click)="productAttribute(attribute,attr,form,$event)"
                                                                                        ngModel
                                                                                        class="form-check-input m-2"
                                                                                        name="attribute{{a}}">
                                                                                    <span class="checkmark"></span>
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </ng-template>
                                                        </li>
                                                    </ul>
                                                    <div [ngbNavOutlet]="nav" class="ml-4"></div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>

                                <!-- gift wrap -->
                                <div class="col-4">
                                    <div class="row">
                                        <div class="col-8">
                                            <div class="productInfo">
                                                <div class="form-control d-flex justify-content-between">
                                                    <div class="my-auto ">Gift Wrap</div>
                                                    <div class="form-check form-check-inline">
                                                        <label class="form-check-label mx-1">Yes </label>
                                                        <input class="form-check-input mx-1" type="radio"
                                                            name="GiftWrapOptions" value=true
                                                            [(ngModel)]="form.giftWrap">
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <label class="form-check-label mx-1">No</label>
                                                        <input class="form-check-input mx-1" type="radio"
                                                            name="GiftWrapOptions" value=false
                                                            [(ngModel)]="form.giftWrap">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-4">
                                            <div class="form-group productInfo">
                                                <label class="title mx-0">Wrapping Price</label>
                                                <input type="text" class="form-control" name="gift_wrap_price"
                                                    [readonly]="!form.giftWrap || form.giftWrap ==='false'"
                                                    [(ngModel)]="form.gift_wrap_price">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- weight -->
                                <div class="col-lg-2 col-2">
                                    <div class="form-group productInfo ">
                                        <label class="title">Product Weight</label>
                                        <div class="input-group">
                                            <input type="number" class="form-control"
                                                [(ngModel)]="singleProductForm.weight" name="weightSingle"
                                                #weightSingle>
                                            <div class="input-group-append">
                                                <div class="input-group-text rounded-0">
                                                    {{unit}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <!-- free shipping -->
                                <!-- <div class="col-lg-2 col-6">
                                    <div class="form-group productInfo">
                                        <label class="title">Free Shipping</label>
                                        <div class="form-control">
                                            <div class="form-check ">
                                                <label class="mr-4 my-1">Free Shipping</label>
                                                <input type="checkbox" name="freeShipping" #freeShipping
                                                    [(ngModel)]="form.freeShipping">
                                            </div>
                                        </div>
                                    </div>
                                </div> -->


                                <div class="col-6" *ngIf="form.productAttribute.length > 0">
                                    <div class="productInfo row" *ngFor="let item of form.productAttribute;index as i">
                                        <!-- product attribute -->
                                        <div class="col-lg-5 col-6 form-group">
                                            <label class="title" style="z-index: 2;">Product Attribute </label>
                                            <div class="form-control" style="height: 45px;">
                                                {{item.attribute_name}}
                                            </div>
                                        </div>

                                        <!-- product attributeValue -->
                                        <div class="col-lg-6 col-6 form-group">
                                            <label class="title" style="z-index: 2;">Attribute Value</label>
                                            <div class="form-control" style="height: 45px;">
                                                {{item.attribute_description}}
                                            </div>
                                        </div>

                                        <div class="col-1 form-group">
                                            <!-- <button class="btn bg-white w-100 p-0 addDelete" *ngIf="item.deleteButton"
                                                type="button" (click)="deleteVar(form.productAttribute,item)">
                                                <i class="fas fa-trash-alt"></i>
                                            </button> -->
                                            <button class="btn bg-white w-100 p-0 addDelete" *ngIf="!item.deleteButton"
                                                type="button" (click)="addAttribute(form)">
                                                <i class="fas fa-plus-circle"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <!-- variable list -->
                                <div class="col-6">
                                    <div class="productInfo d-flex" *ngFor="let item of form.variableList;index as i">
                                        <div class="w-35 pr-3 form-group">
                                            <label class="title">Variable Name</label>
                                            <input type="text" class="form-control" name="VariableNameSingle{{i}}"
                                                [(ngModel)]="item.name">
                                        </div>
                                        <div class="w-69 px-3 form-group">
                                            <label class="title">Variable Description</label>
                                            <input type="text" class="form-control" name="VariableDesSingle{{i}}"
                                                [(ngModel)]="item.des">
                                        </div>
                                        <div class="w-6 form-group">
                                            <button class="btn bg-white w-100 p-0 addDelete" *ngIf="item.deleteButton"
                                                type="button" (click)="deleteVar(form.variableList,item)">
                                                <i class="fas fa-trash-alt"></i>
                                            </button>
                                            <button class="btn bg-white w-100 p-0 addDelete" *ngIf="!item.deleteButton"
                                                type="button" (click)="addMore(item,form.variableList)">
                                                <i class="fas fa-plus-circle"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <!-- bulk price -->
                                <div class="col-6">
                                    <div class="row productInfo">
                                        <div class="col-2 text-center p-2">Bulk Price</div>
                                        <div class="col-10">
                                            <div class="row" *ngFor="let item of form.bulkPricelist;index as i">
                                                <div class="col-3 form-group">
                                                    <label class="title mx-2">Min Quality</label>
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="item.minQuality"
                                                        name="BulkPriceMinQualitySingle{{i}}"
                                                        #BulkPriceMinQualitySingle{{i}}>
                                                </div>
                                                <div class="col-3 form-group">
                                                    <label class="title mx-2">Max Quality</label>
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="item.maxQuality"
                                                        name="BulkPriceMaxQualitySingle{{i}}"
                                                        #BulkPriceMaxQualitySingle{{i}}>
                                                </div>
                                                <div class="col-3 form-group">
                                                    <label class="title mx-2">Price</label>
                                                    <input type="text" class="form-control" ngModel
                                                        [(ngModel)]="item.price" name="BulkPriceSingle{{i}}"
                                                        #BulkPriceSingle{{i}}>
                                                </div>
                                                <div class="col-1 form-group">
                                                    <button type="button" class="btn bg-white w-100 p-0 addDelete"
                                                        *ngIf="item.deleteButton"
                                                        (click)="deleteVar(form.bulkPricelist,i)">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                    <button type="button" class="btn bg-white w-100 p-0 addDelete"
                                                        *ngIf="!item.deleteButton"
                                                        (click)="addMore2(item,form.bulkPricelist)">
                                                        <i class="fas fa-plus-circle"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- add varients -->
                                <div class="col-12">
                                    <ngb-tabset type="pills" class="add-varients">
                                        <ngb-tab title="Add Varients (optional)" [disabled]="true">
                                        </ngb-tab>
                                        <ngb-tab
                                            *ngFor="let varient of form.varientsforSingleProductCustomforMake;index as i">
                                            <ng-template ngbTabTitle>
                                                <div class="border text-center d-flex flex-column"
                                                    style="width:100px;height: 60px;">
                                                    <img [src]="varient.image" class="mx-auto" class="mx-auto my-1"
                                                        height="30px" width="30px" alt="">
                                                    <small class="text-center">{{varient.name}}</small>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbTabContent>
                                                <div *ngFor="let a of varient.everything; index as m">
                                                    <div class="row" *ngIf="a.sameShow">
                                                        <div class="col-lg-9 col-12 form-group my-3">
                                                            <div class="title float-left"
                                                                style="top: 0px; left: -15px;">
                                                                {{m+1}}
                                                            </div>
                                                            <div class="productInfo border border-dark"
                                                                style="height: 70px;">
                                                                <div class="form-group d-flex w-75 title">
                                                                    <div class="flex-fill px-2"
                                                                        *ngFor="let v of a.details;index as r">
                                                                        <label class="title text-capitalize">{{v.name}}
                                                                            name</label>
                                                                        <select class="form-control"
                                                                            name={{v.name}}{{m}}
                                                                            [(ngModel)]="form.custom[i].variable_description[m].value"
                                                                            (change)="optionDisable(form.custom[i].variable_description,v.list)">
                                                                            <option *ngFor="let lt of v.list"
                                                                                [value]=[lt.varient_name]
                                                                                [disabled]="!lt.status">
                                                                                {{lt.varient_name}}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="title float-right"
                                                                style="top:0px; right: -25px;"
                                                                *ngIf="varient.everything.length > 1">
                                                                <button
                                                                    class="btn btn-outline-success p-1 rounded-0 addDelete"
                                                                    (click)="deleteVarients(varient,m,form.custom[i].variable_description)">
                                                                    <i class="fas fa-times-circle"></i>
                                                                </button>
                                                            </div>

                                                        </div>
                                                        <div class="col-lg-3 col-12 form-group my-auto">
                                                            <button
                                                                class="btn productInfo bg-dark text-white p-2 my-lg-4 rounded-0"
                                                                (click)="sameShowing(m,varient.everything)">
                                                                Use Different price & quality
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="row" *ngIf="a.differentShow">
                                                        <div class="col-lg-9 col-12 form-group my-3">
                                                            <div class="title float-left"
                                                                style="top: 0px; left: -15px;">
                                                                {{m+1}}
                                                            </div>
                                                            <div class=" productInfo  d-flex  flex-column border border-dark"
                                                                style="min-height: 100px;">
                                                                <div class="form-group d-flex w-75 title">
                                                                    <div class="flex-fill px-2"
                                                                        *ngFor="let v of a.details;index as r">
                                                                        <label class="title text-capitalize">{{v.name}}
                                                                            name</label>
                                                                        <select class="form-control"
                                                                            name={{v.name}}{{m}}
                                                                            [(ngModel)]="form.custom[i].variable_description[m].value">
                                                                            <option *ngFor="let lt of v.list"
                                                                                [value]=[lt.varient_name]>
                                                                                {{lt.varient_name}}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="row mt-5 px-4">

                                                                    <!-- price -->
                                                                    <div class="col-4 ">
                                                                        <div class="form-group productInfo">
                                                                            <label class="title">Price You
                                                                                Want Form Buyer</label>
                                                                            <div class="d-flex justify-content-between">
                                                                                <input type="text" class="form-control "
                                                                                    [(ngModel)]="form.custom[i].variable_description[m].price"
                                                                                    name="priceWantFromBuyercustom{{m}}"
                                                                                    (keyup)="price(form.custom[i].variable_description[m])">
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <!-- discount -->
                                                                    <div class="col-4 ">
                                                                        <div class="form-group productInfo">
                                                                            <label for="inputEmail4"
                                                                                class="title mx-4">Discount
                                                                                Percentage to show
                                                                                Buyer</label>
                                                                            <div class="d-flex justify-content-between">
                                                                                <input type="text" class="form-control"
                                                                                    name="discountFromBuyercustom{{m}}"
                                                                                    [(ngModel)]="form.custom[i].variable_description[m].discount"
                                                                                    (keyup)="discount(form.custom[i].variable_description[m])">
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <!-- mrp rupees -->
                                                                    <div class="col-4 ">
                                                                        <div class="form-group productInfo"><label
                                                                                for="inputEmail4"
                                                                                class="title">MRP</label>
                                                                            <div
                                                                                class="form-control d-flex justify-content-between">
                                                                                <div class="w-50 py-2">
                                                                                    <input type="text"
                                                                                        class="w-100 border-0"
                                                                                        name="mrp{{m}}"
                                                                                        [(ngModel)]="form.custom[i].variable_description[m].mrp"
                                                                                        (keyup)="mrp(form.custom[i].variable_description[m])">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <!-- quantity in stock -->
                                                                    <div class="col-4 ">
                                                                        <div class="form-group productInfo"><label
                                                                                for="inputEmail4" class="title">Quantity
                                                                                in Stock</label>
                                                                            <div
                                                                                class="form-control d-flex justify-content-between">
                                                                                <div class="w-50 py-2">
                                                                                    <input type="text"
                                                                                        class="w-100 border-0"
                                                                                        name="quantitycustom{{m}}"
                                                                                        [(ngModel)]="form.custom[i].variable_description[m].quantity">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <!-- barcode no -->
                                                                    <div class="col-lg-4 col-6">
                                                                        <div class="form-group productInfo">
                                                                            <label class="title">Barcode No</label>
                                                                            <div>
                                                                                <input type="text" class="form-control"
                                                                                    name="BarcodeNoSinglecustom{{m}}"
                                                                                    [(ngModel)]="form.custom[i].variable_description[m].barcode"
                                                                                    #BarcodeNoSingle>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- Sku -->
                                                                    <div class="col-lg-4">
                                                                        <div class="form-group productInfo">
                                                                            <label class="title">SKU</label>
                                                                            <input type="text" class="form-control"
                                                                                [(ngModel)]="form.custom[i].variable_description[m].sku"
                                                                                name="SKUSinglecustom{{m}}"
                                                                                #SKUSingle{{m}}>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class="form-group productInfo border border-dark d-flex justify-content-between p-1"
                                                                            style="height: 45px;">
                                                                            <p
                                                                                class="font-weight-bold m-auto text-size">
                                                                                Price
                                                                                Shown To Buyer</p>
                                                                            <p class="m-auto text-size previousOne">Rs.
                                                                                {{form.custom[i].variable_description[m].price}}
                                                                            </p>
                                                                            <p class="m-auto text-size"
                                                                                *ngIf="form.custom[i].variable_description[m].discount">
                                                                                Rs.
                                                                                {{form.custom[i].variable_description[m].mrp}}
                                                                                <span
                                                                                    class="text-danger">({{form.custom[i].variable_description[m].discount}}%
                                                                                    off)</span>
                                                                            </p>
                                                                            <button
                                                                                class="btn btn-sm btn-success text-size mx-2">Rs.99(10%)</button>
                                                                            <button
                                                                                class="btn btn-success text-size mx-2">Rs.109(10%)</button>
                                                                            <button
                                                                                class="btn btn-success text-size mx-2">Rs.110(10%)</button>
                                                                            <button
                                                                                class="btn btn-primary text-size rounded-0 mx-2">GST
                                                                                Applied</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="title float-right"
                                                                    style="top:0px; right: -25px;"
                                                                    *ngIf="varient.everything.length > 1">
                                                                    <button
                                                                        class="btn btn-outline-success p-1 rounded-0 addDelete"
                                                                        (click)="deleteVarients(varient,m,form.custom[i].variable_description)">
                                                                        <i class="fas fa-times-circle"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-3 col-12 form-group m-auto">
                                                            <button
                                                                class="btn rounded-0 productInfo bg-dark text-white p-2 my-lg-5"
                                                                (click)="differentShowing(m,varient.everything)">
                                                                Use Same price & quality
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-flex">
                                                    <p class="my-auto mx-3">Add Varients (optional)</p>
                                                    <button class="btn p-0 border text-center d-flex flex-column"
                                                        style="width:100px;height: 60px;" [disabled]="varient.disable"
                                                        (click)="addVarient(varient,form.custom[i].variable_description,form)">
                                                        <img [src]="varient.image" class="mx-auto" height="40px" alt="">
                                                        <small class="m-auto">{{varient.name}}</small>
                                                    </button>
                                                </div>
                                            </ng-template>
                                        </ngb-tab>
                                    </ngb-tabset>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6 text-left">
                        <ngb-pagination *ngIf="bulkImageForms.length > 1" [collectionSize]="bulkImageForms.length"
                            [(page)]="page" [pageSize]="pageSize" [boundaryLinks]="true"></ngb-pagination>
                    </div>
                    <div class="col-6 text-right">
                        <button class="btn btn-danger rounded-0" type="button"
                            *ngIf="page === 1 && bulkImageForms.length > 1" (click)="copy(bulkImageForms)">
                            Copy to All</button>
                        <button class="btn btn-danger rounded-0" type="button"
                            *ngIf="page === bulkImageForms.length && samepriceAndDes.checked"
                            (click)="multipleProductsave('bulk_product_upload',bulkImageForms)">
                            Save Product</button>
                        <button class="btn btn-danger rounded-0" type="button"
                            *ngIf="page === bulkImageForms.length && differentPriceAndDes.checked"
                            (click)="multipleProductsave('bulk_product_upload',bulkImageForms)">
                            Save Product</button>
                    </div>
                </div>
            </ng-template>
        </ngb-tab>
        <ngb-tab>
            <ng-template ngbTabTitle><b>Set or Combo Upload</b></ng-template>
            <ng-template ngbTabContent>
                <div>
                    <!-- image upload list -->
                    <div class="imageUpload border border-success my-2 px-2 d-flex flex-row">
                        <div class="text-center bg-light px-3 dropProducts">
                            <p class="m-2">1/20</p>
                            <p class="m-2">Drops Products Image Here</p>
                            <p class="m-2">(.PNG or .JPG)</p>
                            <label
                                style="padding: 10px; background: rgb(41, 170, 41); display:inline-flex;color: #fff;">Upload
                                Images
                                <input type="file" style="display: none;" accept="image/x-png,image/jpeg"
                                    (change)="onFileUpload($event, setimageContent)"></label>
                        </div>

                        <div class="d-flex flex-row images">
                            <div class="text-center " *ngFor="let img of setImageLinks;index as i">
                                <div class="p-2 border d-inline-flex">
                                    <img [src]="img.coverImg" alt="">
                                    <i class="fas fa-times-circle text-success product-clean"
                                        (click)="deleteProduct(img,setImageLinks)"></i>
                                </div>
                                <div>
                                    <div class="form-check form-check-inline">
                                        <input type="radio" name="coverimage{{i}}" [value]="img.coverImg"
                                            class="form-check-input mx-1" [(ngModel)]="img.coverImg">
                                        <label for="cover" class="form-check-label mx-1">Cover</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <ng-template #setimageContent let-modal>
                        <div class="modal-body">
                            <app-image-cropper [src]="uploadImage" (greetEvent)="getcropImage($event)"></app-image-cropper>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-light" (click)="closeModel()">Close</button>
                            <button type="button" [disabled]="loading" class="btn btn-success" (click)="onUpload(setImageLinks)">
                                <div *ngIf="loading" class="spinner-border text-secondary" role="status"></div>
                                <span *ngIf="!loading">Save</span>
                            </button>
                        </div>
                    </ng-template>
                    <div class="my-3">
                        <div class="row">
                            <div class="col-3 my-2">Select one of the items to produce</div>
                            <div class="col-9">
                                <div class="row">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" checked name="setComboAvalable"
                                            #combo [(ngModel)]="setComboAvalable" value="combo">
                                        <label class="form-check-label">Combo of Color product</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="setComboAvalable" #set
                                            [(ngModel)]="setComboAvalable" value="set">
                                        <label class="form-check-label" for="inlineRadio2">Set Products</label>
                                    </div>
                                    <div class="form-group mb-2" *ngIf="set.checked">
                                        <label class="title-total-pcs">Total Pcs</label>
                                        <div class="form-control px-4">{{setImageLinks.length}}</div>
                                    </div>
                                </div>
                                <div class="row my-1" *ngIf="set.checked">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" #singleAvaliable
                                            name="singleAvaliable" [(ngModel)]="setSingle.single_product_type">
                                        <label class="form-check-label">Singles Avaliable</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" #setAvaliable
                                            [(ngModel)]="setSingle.set_product_type" name="setAvaliable">
                                        <label class="form-check-label">Set Avaliable</label>
                                    </div>
                                    <div *ngIf="setAvaliable.checked">
                                        <span class="form-check form-check-inline">Select Size for Sets</span>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="select_size"
                                                [(ngModel)]="select_size" value="true" checked>
                                            <label class="form-check-label" for="inlineRadio1">Same Size Select</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="select_size"
                                                [(ngModel)]="select_size" value="false">
                                            <label class="form-check-label" for="inlineRadio2">Dfferent Size
                                                Select</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="price2" #samepriceAndDes2
                                            [(ngModel)]="priceAndDes2" value="samepriceAndDes2" checked>
                                        <label class="form-check-label" for="inlineRadio1">Same Price and
                                            Description</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="price2"
                                            [(ngModel)]="priceAndDes2" value="differentPriceAndDes2"
                                            #differentPriceAndDes2>
                                        <label class="form-check-label" for="inlineRadio2">Dfferent Price and
                                            Description</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        *ngFor="let form of setImageLinks| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as index">
                        <!-- product information -->
                        <form #setProductUploadForm="ngForm" novalidate *ngIf="samepriceAndDes2.checked">
                            <div class="d-flex">
                                <div>
                                    <label class="custom mb-0">This is a Physical Product
                                        <input type="checkbox" name="physicalProduct" [(ngModel)]="form.productType">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="mx-2" *ngIf="form.productType">
                                    <div class="d-lg-inline-flex">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="physicalProductType" #new
                                                [(ngModel)]="form.physical_product_condition" value="new">
                                            <label class="form-check-label" for="inlineRadio1">New</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="physicalProductType"
                                                value="refurbished" [(ngModel)]="form.physical_product_condition"
                                                #refurbished>
                                            <label class="form-check-label" for="inlineRadio2">refurbished</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="physicalProductType"
                                                value="used" [(ngModel)]="form.physical_product_condition" #used>
                                            <label class="form-check-label" for="inlineRadio2">used</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="my-4 row">
                                <!-- catagory -->
                                <div class="col-lg-2 col-6">
                                    <div class="form-group productInfo">
                                        <label class="title" style="z-index: 2;">Catagory</label>
                                        <div ngbDropdown class="d-inline-block w-100" #CatDrop="ngbDropdown">
                                            <button style="overflow-x: scroll; overflow-y: hidden;"
                                                class="btn py-2 px-4 btn-block d-flex justify-content-between form-control"
                                                type="button" ngbDropdownToggle>
                                                <div>
                                                    <span *ngIf="form.product_main_category">
                                                        {{form.product_main_category}}</span>
                                                    <span *ngIf="form.product_sub_main_category"><i
                                                            class="fas fa-angle-double-right m-1"></i>
                                                        {{form.product_sub_main_category}}</span>
                                                    <span *ngIf="form.product_sub_category"><i
                                                            class="fas fa-angle-double-right m-1"></i>{{form.product_sub_category}}</span>
                                                </div>
                                            </button>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                                style="min-height: auto; max-height: 400px; overflow-y: scroll; width: 400px;">
                                                <div class="bg-success p-2 text-center ">
                                                    <a routerLink="/create-new-catagory" class="text-white"
                                                        routerLinkActive="active-link" target="_blank"> Create new
                                                        catagory</a>
                                                </div>
                                                <div class="input-group p-2">
                                                    <div class="input-group-prepend" style="width: 15%;">
                                                        <span
                                                            class="input-group-text border-black rounded-0 bg-transparent border-right-0">
                                                            <i class="fas fa-search"></i></span>
                                                    </div>
                                                    <input type="text" class="search" ngModel name="searchCatagory"
                                                        #searchCatagory>
                                                </div>
                                                <ul class="list-group list-group-flush">
                                                    <li class="list-group-item border-0"
                                                        *ngFor="let item of catagories; index as i">
                                                        <div class="d-flex">
                                                            <label class="custom mb-0">{{item.category_name}}
                                                                <input type="radio" [value]="item._id"
                                                                    (click)="onCat(item,'','',CatDrop,form)"
                                                                    class="form-check-input m-2"
                                                                    name="product_main_category_id"
                                                                    [(ngModel)]="form.product_main_category_id">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                            <button class="btn p-0 ml-4" type="button"
                                                                *ngIf="item.main_sub_category.length > 0"
                                                                (click)="Showsubcat(item)">
                                                                <i
                                                                    [class]="!item.click? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                            </button>
                                                        </div>
                                                        <ul class="list-group list-group-flush ml-4" *ngIf="item.click">
                                                            <li class="list-group-item border-0"
                                                                *ngFor="let val of item.main_sub_category; index as j">
                                                                <div class="d-flex">
                                                                    <label class="custom mb-0">{{val.category_name}}
                                                                        <input type="radio" [value]="val._id"
                                                                            (click)="onCat(item,val,'',CatDrop,form)"
                                                                            [(ngModel)]="form.product_sub_main_category_id"
                                                                            class="form-check-input m-2"
                                                                            name="product_sub_main_category_id">
                                                                        <span class="checkmark"></span>
                                                                    </label>
                                                                    <button class="btn p-0 ml-4" type="button"
                                                                        *ngIf="val.sub_category.length > 0"
                                                                        (click)="Showsubcat(val)">
                                                                        <i
                                                                            [class]="!val.click? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                    </button>
                                                                </div>
                                                                <ul class="list-group list-group-flush ml-4"
                                                                    *ngIf="val.click">
                                                                    <li class="list-group-item border-0"
                                                                        *ngFor="let v of val.sub_category; index as m">
                                                                        <div class="d-flex">
                                                                            <label
                                                                                class="custom mb-0">{{v.category_name}}
                                                                                <input type="radio" [value]="v._id"
                                                                                    (click)="onCat(item,val,v,CatDrop,form)"
                                                                                    class="form-check-input m-2"
                                                                                    [(ngModel)]="form.catagory_id"
                                                                                    name="catagory_id">
                                                                                <span class="checkmark"></span>
                                                                            </label>
                                                                            <button class="btn p-0 ml-4" type="button"
                                                                                *ngIf="v.sub_category">
                                                                                <i
                                                                                    [class]="v.sub_category? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                            </button>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- type of product -->
                                <div class="col-lg-2 col-6">
                                    <div class="form-group productInfo">
                                        <label class="title" style="z-index: 2;">Type of product</label>
                                        <div ngbDropdown class="d-inline-block w-100">
                                            <button
                                                class="btn py-2 px-4 btn-block d-flex justify-content-between form-control"
                                                type="button" ngbDropdownToggle>
                                                <span>{{form.typeOfProduct}}</span>
                                            </button>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                                style="min-height: auto; max-height: 400px; overflow-y: scroll;">
                                                <div class="bg-success p-2 text-center ">
                                                    <a routerLink="/brands" class="text-white"
                                                        routerLinkActive="active-link">
                                                        Create new Brand</a>
                                                </div>
                                                <div class="input-group p-2">
                                                    <div class="input-group-prepend" style="width: 12%;">
                                                        <span
                                                            class="input-group-text border-black rounded-0 bg-transparent border-right-0">
                                                            <i class="fas fa-search"></i></span>
                                                    </div>
                                                    <input type="text" class="search" ngModel name="searchCatagory"
                                                        #searchCatagory>
                                                </div>
                                                <div class="">
                                                    <ul class="list-group list-group-flush">
                                                        <li class="list-group-item border-0"
                                                            *ngFor="let item of type_of_productList; index as i">
                                                            <div class="d-flex">
                                                                <label class="custom mb-0">{{item.type_of_product_sub}}
                                                                    <input type="radio"
                                                                        [value]="item.type_of_product_sub"
                                                                        (click)="onType(item,form)"
                                                                        [(ngModel)]="form.typeOfProduct"
                                                                        class="form-check-input m-2" name="productType">
                                                                    <span class="checkmark"></span>
                                                                </label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Brand -->
                                <div class="col-lg-2 col-6">
                                    <div class="form-group productInfo">
                                        <label class="title" style="z-index: 2;">Brand</label>
                                        <div ngbDropdown class="d-inline-block w-100" #brandDrop="ngbDropdown">
                                            <button
                                                class="btn py-2 px-4 btn-block d-flex justify-content-between form-control"
                                                type="button" ngbDropdownToggle>
                                                <span>{{form.brandName}}</span>
                                            </button>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                                style="min-height: auto; max-height: 400px; overflow-y: scroll;">
                                                <div class="bg-success p-2 text-center ">
                                                    <a routerLink="/brands" class="text-white"
                                                        routerLinkActive="active-link" target="_blank"> Create new
                                                        Brand</a>
                                                </div>
                                                <div class="input-group" *ngIf="brands.length > 0">
                                                    <div class="input-group p-2">
                                                        <div class="input-group-prepend" style="width: 15%;">
                                                            <span
                                                                class="input-group-text border-black rounded-0 bg-transparent border-right-0">
                                                                <i class="fas fa-search"></i></span>
                                                        </div>
                                                        <input type="text" class="search" ngModel name="searchCatagory"
                                                            #searchCatagory>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <ul class="list-group list-group-flush">
                                                        <li class="list-group-item border-0"
                                                            *ngFor="let brand of brands">
                                                            <div class="d-flex">
                                                                <label class="custom mb-0">{{brand.brand_name}}
                                                                    <input type="radio" [value]="brand._id"
                                                                        (click)="onBrand(brand,brandDrop,form)"
                                                                        [(ngModel)]="form.brandId"
                                                                        class="form-check-input m-2" name="brand">
                                                                    <span class="checkmark"></span>
                                                                </label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- product name  -->
                                <div class="col-lg-4">
                                    <div class="form-group productInfo">
                                        <label class="title">*Product Name</label>
                                        <input type="text" class="form-control" [(ngModel)]="form.productName"
                                            name="productNameSingle" #productNameSingle>
                                    </div>
                                </div>

                                <!-- gst Percentage -->

                                <div class="input-group form-group col-lg-2">
                                    <label class="title">HsnCode</label>
                                    <select class="form-control" name="gst" min="0" [(ngModel)]="form.gst_id">
                                        <option [value]="gst._id" *ngFor="let gst of gstList">
                                            {{gst.store_hsn_code}}({{gst.store_description_of_goods}})</option>
                                    </select>
                                </div>

                                <div class="col-12"
                                    [class.d-none]="form.varientsforSingleProductCustomforMake.length > 0 && form.varientsforSingleProductCustomforMake[0].everything[0].differentShow">
                                    <div class="row">
                                        <!-- price you Want form buyer -->
                                        <div class="col-lg-2 col-6">
                                            <div class="form-group productInfo">
                                                <label class="title">*Price</label>
                                                <div class="">
                                                    <input type="text" class="form-control" (keyup)="price(form)"
                                                        [(ngModel)]="form.price" name="PriceUWantFromBuyerSingle"
                                                        #PriceUWantFromBuyerSingle>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- discount shown to buyer  -->
                                        <div class="col-lg-2 col-6">
                                            <div class="form-group productInfo">
                                                <label class="title">*Discount</label>
                                                <div>
                                                    <input type="text" class="form-control"
                                                        name="DiscountToShownToBuyerSingle" [(ngModel)]="form.discount"
                                                        #DiscountToShownToBuyerSingle (keyup)="discount(form)">
                                                </div>
                                            </div>
                                        </div>
                                        <!-- MRP price -->
                                        <div class="col-lg-2 col-6">
                                            <div class="form-group productInfo">
                                                <label class="title">*MRP</label>
                                                <div>
                                                    <input type="text" class="form-control" name="totalPrice"
                                                        [(ngModel)]="form.mrp" #totalPrice (keyup)="totalprice(form)">
                                                </div>
                                            </div>
                                        </div>

                                        <!-- product cost price  -->
                                        <div class="col-lg-2 col-6">
                                            <div class="form-group productInfo">
                                                <label class="title">Product Cost Price</label>
                                                <div>
                                                    <input type="text" class="form-control"
                                                        name="ProductCostPriceSingle" #ProductCostPriceSingle
                                                        [(ngModel)]="form.productCostPrice" (keyup)="Pofit(form)">
                                                </div>
                                            </div>
                                        </div>
                                        <!-- margin  -->
                                        <div class="col-lg-2 col-6">
                                            <div class="form-group productInfo">
                                                <label class="title">Margin</label>
                                                <div class="form-control">
                                                    {{form.margin}}
                                                </div>
                                            </div>
                                        </div>
                                        <!-- profit  -->
                                        <div class="col-lg-2 col-6">
                                            <div class="form-group productInfo">
                                                <label class="title">Profit</label>
                                                <div class="form-control">
                                                    {{form.profit}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- price shown to buyer  -->
                                <div class="col-lg-6">
                                    <div class="form-group productInfo border border-dark d-flex justify-content-between p-1"
                                        style="height: 45px;">
                                        <p class="font-weight-bold my-auto text-size">Price Shown To Buyer</p>
                                        <p class="m-auto text-size previousOne" *ngIf="form.mrp">
                                            Rs.{{form.mrp}}</p>
                                        <p class="m-auto text-size" *ngIf="form.price">
                                            Rs.{{form.price}}<span class="text-danger" *ngIf="form.discount">
                                                ({{form.discount}}% off)</span></p>
                                        <button class="btn btn-sm btn-success text-size mx-2">Rs.99(10%)</button>
                                        <button class="btn btn-success text-size mx-2">Rs.109(10%)</button>
                                        <button class="btn btn-success text-size mx-2">Rs.110(10%)</button>
                                    </div>
                                </div>

                                <!-- quantity in stock  -->
                                <div class="col-lg-2 col-6">
                                    <div class="form-group productInfo">
                                        <label class="title">*Quantity in Stock</label>
                                        <div class="form-control d-flex justify-content-between">
                                            <div class="w-50 py-2">
                                                <input type="number" class="w-100 border-0"
                                                    *ngIf="!unlimitedSingle.checked" [(ngModel)]="form.quantity"
                                                    name="QuantityInStockSingle" #QuantityInStockSingle
                                                    (keyup)="quantityInStock(form)">
                                            </div>
                                            <div class="w-50 py-2">
                                                <input type="checkbox" class="mx-2"
                                                    [(ngModel)]="form.quantity_in_stock_unlimited"
                                                    name="unlimitedSingle" #unlimitedSingle>
                                                <label>Unlimited</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- avaliable shop upto  -->
                                <div class="col-lg-2 col-6">
                                    <div class="productInfo">
                                        <label class="title">Avaliable to shop up to</label>
                                        <input class="form-control w-100" type="date" [min]="today | date:'yy-MM-dd'"
                                            name="AvaliableToShopUpToSingle" [(ngModel)]="form.AvaliableToShopUpTo"
                                            #AvaliableToShopUpToSingle>
                                    </div>
                                </div>

                                <!-- pre Order -->
                                <div class="col-lg-2 col-6">
                                    <div class="form-group productInfo">
                                        <label class="title">Pre Order</label>
                                        <div class="form-control">
                                            <div class="form-check ">
                                                <label class="mr-4 my-1">Pre Order</label>
                                                <input type="checkbox" ngModel name="PreOrderSingle" #PreOrderSingle
                                                    [(ngModel)]="form.PreOrder">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- description  -->
                                <div class="col-lg-6">
                                    <div class="form-group ">
                                        <label class="title">Description(Optional)</label>
                                        <textarea class="form-control" rows="4" [(ngModel)]="form.Description"
                                            name="DescriptionSingle" #DescriptionSingle></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="row">

                                        <!-- expected launch date -->
                                        <div class="col-lg-4 col-6">
                                            <div class="productInfo">
                                                <label class="title">Expected Launch Date</label>
                                                <input class="form-control w-100" type="date"
                                                    [disabled]="!form.PreOrder" [min]="today | date:'yy-MM-dd'"
                                                    [(ngModel)]="form.expectedLaunchDate"
                                                    name="ExpectedLaunchDateSingle" #ExpectedLaunchDateSingle>
                                            </div>
                                        </div>

                                        <!-- barcode no -->
                                        <div class="col-lg-4 col-2">
                                            <div class="form-group productInfo">
                                                <label class="title">Barcode No</label>
                                                <div>
                                                    <input type="text" class="form-control" [(ngModel)]="form.barcode"
                                                        name="BarcodeNoSingle" #BarcodeNoSingle
                                                        (keyup)="barcodeNo(form)">
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Sku -->
                                        <div class="col-lg-4" *ngIf="!sku_weight_mesurement.store_generate_auto">
                                            <div class="form-group productInfo">
                                                <label class="title">*SKU</label>
                                                <div class="input-group" *ngIf="sku_weight_mesurement.store_sku_prefix">
                                                    <div class="input-group-prepend">
                                                        <div class="input-group-text rounded-0">
                                                            {{sku_weight_mesurement.store_sku_prefix}}
                                                        </div>
                                                    </div>
                                                    <input type="text" class="form-control" [(ngModel)]="form.sku"
                                                        name="SKUSingle" #SKUSingle="ngModel"
                                                        (keyup)="sku(form,setImageLinks)">
                                                </div>
                                                <input type="text" *ngIf="!sku_weight_mesurement.store_sku_prefix"
                                                    class="form-control" [(ngModel)]="form.sku" name="SKUSingle"
                                                    #SKUSingle="ngModel" (keyup)="sku(form,setImageLinks)">
                                            </div>
                                            <div *ngIf="form.sku_valid.status" class="p-2 btn" style="position: absolute;
                                            z-index: 250;
                                            width: 100%;
                                            bottom: -10px;
                                            left: 0;" [class.btn-success]="form.sku_valid.status === 'success'"
                                                [class.btn-danger]="form.sku_valid.status === 'fail'">
                                                <small>{{form.sku_valid.message}}</small>
                                            </div>
                                        </div>
                                        <!-- source Vendor -->
                                        <div class="col-lg-4 col-6">
                                            <div class="form-group productInfo">
                                                <label class="title" style="z-index: 2;">Source Vendor</label>
                                                <div ngbDropdown class="d-inline-block w-100">
                                                    <button
                                                        class="btn py-2 px-4 btn-block d-flex justify-content-between form-control"
                                                        type="button" ngbDropdownToggle>
                                                        <span>{{form.SourceVendor}}</span>
                                                    </button>
                                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                                        style="min-height: auto; max-height: 400px; overflow-y: scroll;">
                                                        <div class="bg-success p-2 text-center ">
                                                            <a routerLink="/add-vendor" class="text-white"
                                                                routerLinkActive="active-link">
                                                                Add Vendor</a>
                                                        </div>
                                                        <div class="input-group p-2">
                                                            <div class="input-group-prepend" style="width: 12%;">
                                                                <span
                                                                    class="input-group-text border-black rounded-0 bg-transparent border-right-0">
                                                                    <i class="fas fa-search"></i></span>
                                                            </div>
                                                            <input type="text" class="search" ngModel
                                                                name="searchCatagory" #searchCatagory>
                                                        </div>
                                                        <div class="">
                                                            <ul class="list-group list-group-flush">
                                                                <li class="list-group-item border-0"
                                                                    *ngFor="let item of vendorList; index as i">
                                                                    <div class="d-flex">
                                                                        <label
                                                                            class="custom mb-0">{{item.primary_contact}}
                                                                            <input type="radio"
                                                                                [value]="item.primary_contact"
                                                                                (click)="SourceVendor(item,form)"
                                                                                [(ngModel)]="form.SourceVendor"
                                                                                class="form-check-input m-2"
                                                                                name="SourceVendor">
                                                                            <span class="checkmark"></span>
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- refference Sku -->
                                        <div class="col-lg-4">
                                            <div class="form-group productInfo">
                                                <label class="title">Refference SKU</label>
                                                <input type="text" class="form-control" [(ngModel)]="form.RefferenceSKU"
                                                    name="RefferenceSKUSingle" #RefferenceSKUSingle>
                                            </div>
                                        </div>

                                        <!-- country origin  -->
                                        <div class="col-lg-4">
                                            <div class="form-group productInfo">
                                                <label class="title">*Country origin name</label>
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="form.country_origin_name" name="country_origin"
                                                    #countryOrigin>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <!-- add video URL -->
                                <div class="col-lg-4">
                                    <div class="form-group productInfo">
                                        <label class="title">Add Video URL</label>
                                        <input type="text" class="form-control" name="AddVideoURLSingle"
                                            #AddVideoURLSingle [(ngModel)]="form.AddVideoURL">
                                    </div>
                                </div>

                                <!-- Specifications -->

                                <div class="col-2">
                                    <div class="productInfo">
                                        <button class="btn form-control d-flex justify-content-between"
                                            (click)="open(content)">
                                            <span>Specifications</span>
                                            <span class="my-1 ml-4" style="font-size: 24px;"><i
                                                    class="fas fa-expand"></i></span>
                                        </button>
                                        <ng-template #content let-modal>
                                            <div class="modal-header">
                                                <h4 class="modal-title" id="modal-basic-title">Specification List</h4>
                                                <button type="button" class="close" aria-label="Close"
                                                    (click)="modal.dismiss('Cross click')">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="d-flex">
                                                    <ul ngbNav #nav="ngbNav" class="nav-pills" orientation="vertical">
                                                        <li ngbNavItem
                                                            *ngFor="let attribute of form.attributeList; index as i">
                                                            <a
                                                                ngbNavLink>{{attribute.type_of_product_attribute_name}}</a>
                                                            <ng-template ngbNavContent>
                                                                <div class="">
                                                                    <ul class="list-group list-group-flush">
                                                                        <li class="list-group-item border-0"
                                                                            *ngFor="let attr of attribute.type_of_product_attribute_values; index as a">
                                                                            <div class="d-flex">
                                                                                <label
                                                                                    class="custom mb-0">{{attr.type_of_product_value}}
                                                                                    <input type="checkbox"
                                                                                        (click)="productAttribute(attribute,attr,form,$event)"
                                                                                        ngModel
                                                                                        class="form-check-input m-2"
                                                                                        name="attribute{{a}}">
                                                                                    <span class="checkmark"></span>
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </ng-template>
                                                        </li>
                                                    </ul>
                                                    <div [ngbNavOutlet]="nav" class="ml-4"></div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>

                                <!-- gift wrap -->
                                <div class="col-4">
                                    <div class="row">
                                        <div class="col-8">
                                            <div class="productInfo">
                                                <div class="form-control d-flex justify-content-between">
                                                    <div class="my-auto ">Gift Wrap</div>
                                                    <div class="form-check form-check-inline">
                                                        <label class="form-check-label mx-1">Yes </label>
                                                        <input class="form-check-input mx-1" type="radio"
                                                            name="GiftWrapOptions" value=true
                                                            [(ngModel)]="form.giftWrap">
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <label class="form-check-label mx-1">No</label>
                                                        <input class="form-check-input mx-1" type="radio"
                                                            name="GiftWrapOptions" value=false
                                                            [(ngModel)]="form.giftWrap">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-4">
                                            <div class="form-group productInfo">
                                                <label class="title mx-0">Wrapping Price</label>
                                                <input type="text" class="form-control" name="gift_wrap_price"
                                                    [readonly]="!form.giftWrap || form.giftWrap ==='false'"
                                                    [(ngModel)]="form.gift_wrap_price">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- weight -->
                                <div class="col-lg-2">
                                    <div class="form-group productInfo ">
                                        <label class="title">Product Weight</label>
                                        <div class="input-group">
                                            <input type="number" class="form-control"
                                                [(ngModel)]="singleProductForm.weight" name="weightSingle"
                                                #weightSingle>
                                            <div class="input-group-append">
                                                <div class="input-group-text rounded-0">
                                                    {{unit}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- free shipping -->
                                <!-- <div class="col-lg-2 col-6">
                                    <div class="form-group productInfo">
                                        <label class="title">Free Shipping</label>
                                        <div class="form-control">
                                            <div class="form-check ">
                                                <label class="mr-4 my-1">Free Shipping</label>
                                                <input type="checkbox" name="freeShipping" #freeShipping
                                                    [(ngModel)]="form.freeShipping">
                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                                <div class="col-6" *ngIf="form.productAttribute.length > 0">
                                    <div class="productInfo row" *ngFor="let item of form.productAttribute;index as i">
                                        <!-- product attribute -->
                                        <div class="col-lg-5 col-6 form-group">
                                            <label class="title" style="z-index: 2;">Product Attribute </label>
                                            <div class="form-control" style="height: 45px;">
                                                {{item.attribute_name}}
                                            </div>
                                        </div>

                                        <!-- product attributeValue -->
                                        <div class="col-lg-6 col-6 form-group">
                                            <label class="title" style="z-index: 2;">Attribute Value</label>
                                            <div class="form-control" style="height: 45px;">
                                                {{item.attribute_description}}
                                            </div>
                                        </div>

                                        <div class="col-1 form-group">
                                            <!-- <button class="btn bg-white w-100 p-0 addDelete" *ngIf="item.deleteButton"
                                                type="button" (click)="deleteVar(form.productAttribute,item)">
                                                <i class="fas fa-trash-alt"></i>
                                            </button> -->
                                            <button class="btn bg-white w-100 p-0 addDelete" *ngIf="!item.deleteButton"
                                                type="button" (click)="addAttribute(form)">
                                                <i class="fas fa-plus-circle"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <!-- variable list -->
                                <div class="col-6">
                                    <div class="productInfo d-flex" *ngFor="let item of form.variableList;index as i">
                                        <div class="w-35 pr-3 form-group">
                                            <label class="title">Variable Name</label>
                                            <input type="text" class="form-control" name="VariableNameSingle{{i}}"
                                                [(ngModel)]="item.name">
                                        </div>
                                        <div class="w-69 px-3 form-group">
                                            <label class="title">Variable Description</label>
                                            <input type="text" class="form-control" name="VariableDesSingle{{i}}"
                                                [(ngModel)]="item.des">
                                        </div>
                                        <div class="w-6 form-group">
                                            <button class="btn bg-white w-100 p-0 addDelete" *ngIf="item.deleteButton"
                                                type="button" (click)="deleteVar(form.variableList,item)">
                                                <i class="fas fa-trash-alt"></i>
                                            </button>
                                            <button class="btn bg-white w-100 p-0 addDelete" *ngIf="!item.deleteButton"
                                                type="button" (click)="addMore(item,form.variableList)">
                                                <i class="fas fa-plus-circle"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <!-- bulk price -->
                                <div class="col-6">
                                    <div class="row productInfo">
                                        <div class="col-2 text-center p-2">Bulk Price</div>
                                        <div class="col-10">
                                            <div class="row" *ngFor="let item of form.bulkPricelist;index as i">
                                                <div class="col-3 form-group">
                                                    <label class="title mx-2">Min Quality</label>
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="item.minQuality"
                                                        name="BulkPriceMinQualitySingle{{i}}"
                                                        #BulkPriceMinQualitySingle{{i}}>
                                                </div>
                                                <div class="col-3 form-group">
                                                    <label class="title mx-2">Max Quality</label>
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="item.maxQuality"
                                                        name="BulkPriceMaxQualitySingle{{i}}"
                                                        #BulkPriceMaxQualitySingle{{i}}>
                                                </div>
                                                <div class="col-3 form-group">
                                                    <label class="title mx-2">Price</label>
                                                    <input type="text" class="form-control" ngModel
                                                        [(ngModel)]="item.price" name="BulkPriceSingle{{i}}"
                                                        #BulkPriceSingle{{i}}>
                                                </div>
                                                <div class="col-1 form-group">
                                                    <button type="button" class="btn bg-white w-100 p-0 addDelete"
                                                        *ngIf="item.deleteButton"
                                                        (click)="deleteVar(form.bulkPricelist,i)">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                    <button type="button" class="btn bg-white w-100 p-0 addDelete"
                                                        *ngIf="!item.deleteButton"
                                                        (click)="addMore2(item,form.bulkPricelist)">
                                                        <i class="fas fa-plus-circle"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- add varients -->
                                <div class="col-12">
                                    <ngb-tabset type="pills" class="add-varients">
                                        <ngb-tab title="Add Varients (optional)" [disabled]="true">
                                        </ngb-tab>
                                        <ngb-tab
                                            *ngFor="let varient of form.varientsforSingleProductCustomforMake;index as i">
                                            <ng-template ngbTabTitle>
                                                <div class="border text-center d-flex flex-column"
                                                    style="width:100px;height: 60px;">
                                                    <img [src]="varient.image" class="mx-auto" class="mx-auto my-1"
                                                        height="30px" width="30px" alt="">
                                                    <small class="text-center">{{varient.name}}</small>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbTabContent>
                                                <div *ngFor="let a of varient.everything; index as m">
                                                    <div class="row" *ngIf="a.sameShow">
                                                        <div class="col-lg-9 col-12 form-group my-3">
                                                            <div class="title float-left"
                                                                style="top: 0px; left: -15px;">
                                                                {{m+1}}
                                                            </div>
                                                            <div class="productInfo border border-dark"
                                                                style="height: 70px;">
                                                                <div class="form-group d-flex w-75 title">
                                                                    <div class="flex-fill px-2"
                                                                        *ngFor="let v of a.details;index as r">
                                                                        <label class="title text-capitalize">{{v.name}}
                                                                            name</label>
                                                                        <select class="form-control"
                                                                            name={{v.name}}{{m}}
                                                                            [(ngModel)]="form.custom[i].variable_description[m].value"
                                                                            (change)="optionDisable(form.custom[i].variable_description,v.list)">
                                                                            <option *ngFor="let lt of v.list"
                                                                                [value]=[lt.varient_name]
                                                                                [disabled]="!lt.status">
                                                                                {{lt.varient_name}}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="title float-right"
                                                                style="top:0px; right: -25px;"
                                                                *ngIf="varient.everything.length > 1">
                                                                <button
                                                                    class="btn btn-outline-success p-1 rounded-0 addDelete"
                                                                    (click)="deleteVarients(varient,m,form.custom[i].variable_description)">
                                                                    <i class="fas fa-times-circle"></i>
                                                                </button>
                                                            </div>

                                                        </div>
                                                        <div class="col-lg-3 col-12 form-group my-auto">
                                                            <button
                                                                class="btn productInfo bg-dark text-white p-2 my-lg-4 rounded-0"
                                                                (click)="sameShowing(m,varient.everything)">
                                                                Use Different price & quality
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="row" *ngIf="a.differentShow">
                                                        <div class="col-lg-9 col-12 form-group my-3">
                                                            <div class="title float-left"
                                                                style="top: 0px; left: -15px;">
                                                                {{m+1}}
                                                            </div>
                                                            <div class=" productInfo  d-flex  flex-column border border-dark"
                                                                style="min-height: 100px;">
                                                                <div class="form-group d-flex w-75 title">
                                                                    <div class="flex-fill px-2"
                                                                        *ngFor="let v of a.details;index as r">
                                                                        <label class="title text-capitalize">{{v.name}}
                                                                            name</label>
                                                                        <select class="form-control"
                                                                            name={{v.name}}{{m}}
                                                                            [(ngModel)]="form.custom[i].variable_description[m].value">
                                                                            <option *ngFor="let lt of v.list"
                                                                                [value]=[lt.varient_name]>
                                                                                {{lt.varient_name}}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="row mt-5 px-4">

                                                                    <!-- price -->
                                                                    <div class="col-4 ">
                                                                        <div class="form-group productInfo">
                                                                            <label class="title">Price You
                                                                                Want Form Buyer</label>
                                                                            <div class="d-flex justify-content-between">
                                                                                <input type="text" class="form-control "
                                                                                    [(ngModel)]="form.custom[i].variable_description[m].price"
                                                                                    name="priceWantFromBuyercustom{{m}}"
                                                                                    (keyup)="price(form.custom[i].variable_description[m])">
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <!-- discount -->
                                                                    <div class="col-4 ">
                                                                        <div class="form-group productInfo">
                                                                            <label for="inputEmail4"
                                                                                class="title mx-4">Discount
                                                                                Percentage to show
                                                                                Buyer</label>
                                                                            <div class="d-flex justify-content-between">
                                                                                <input type="text" class="form-control"
                                                                                    name="discountFromBuyercustom{{m}}"
                                                                                    [(ngModel)]="form.custom[i].variable_description[m].discount"
                                                                                    (keyup)="discount(form.custom[i].variable_description[m])">
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <!-- mrp rupees -->
                                                                    <div class="col-4 ">
                                                                        <div class="form-group productInfo"><label
                                                                                for="inputEmail4"
                                                                                class="title">MRP</label>
                                                                            <div
                                                                                class="form-control d-flex justify-content-between">
                                                                                <div class="w-50 py-2">
                                                                                    <input type="text"
                                                                                        class="w-100 border-0"
                                                                                        name="mrp{{m}}"
                                                                                        [(ngModel)]="form.custom[i].variable_description[m].mrp"
                                                                                        (keyup)="mrp(form.custom[i].variable_description[m])">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <!-- quantity in stock -->
                                                                    <div class="col-4 ">
                                                                        <div class="form-group productInfo"><label
                                                                                for="inputEmail4" class="title">Quantity
                                                                                in Stock</label>
                                                                            <div
                                                                                class="form-control d-flex justify-content-between">
                                                                                <div class="w-50 py-2">
                                                                                    <input type="text"
                                                                                        class="w-100 border-0"
                                                                                        name="quantitycustom{{m}}"
                                                                                        [(ngModel)]="form.custom[i].variable_description[m].quantity">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <!-- barcode no -->
                                                                    <div class="col-lg-4 col-6">
                                                                        <div class="form-group productInfo">
                                                                            <label class="title">Barcode No</label>
                                                                            <div>
                                                                                <input type="text" class="form-control"
                                                                                    name="BarcodeNoSinglecustom{{m}}"
                                                                                    [(ngModel)]="form.custom[i].variable_description[m].barcode"
                                                                                    #BarcodeNoSingle>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- Sku -->
                                                                    <div class="col-lg-4">
                                                                        <div class="form-group productInfo">
                                                                            <label class="title">SKU</label>
                                                                            <input type="text" class="form-control"
                                                                                [(ngModel)]="form.custom[i].variable_description[m].sku"
                                                                                name="SKUSinglecustom{{m}}"
                                                                                #SKUSingle{{m}}>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class="form-group productInfo border border-dark d-flex justify-content-between p-1"
                                                                            style="height: 45px;">
                                                                            <p
                                                                                class="font-weight-bold m-auto text-size">
                                                                                Price
                                                                                Shown To Buyer</p>
                                                                            <p class="m-auto text-size previousOne">Rs.
                                                                                {{form.custom[i].variable_description[m].price}}
                                                                            </p>
                                                                            <p class="m-auto text-size"
                                                                                *ngIf="form.custom[i].variable_description[m].discount">
                                                                                Rs.
                                                                                {{form.custom[i].variable_description[m].mrp}}
                                                                                <span
                                                                                    class="text-danger">({{form.custom[i].variable_description[m].discount}}%
                                                                                    off)</span>
                                                                            </p>
                                                                            <button
                                                                                class="btn btn-sm btn-success text-size mx-2">Rs.99(10%)</button>
                                                                            <button
                                                                                class="btn btn-success text-size mx-2">Rs.109(10%)</button>
                                                                            <button
                                                                                class="btn btn-success text-size mx-2">Rs.110(10%)</button>
                                                                            <button
                                                                                class="btn btn-primary text-size rounded-0 mx-2">GST
                                                                                Applied</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="title float-right"
                                                                    style="top:0px; right: -25px;"
                                                                    *ngIf="varient.everything.length > 1">
                                                                    <button
                                                                        class="btn btn-outline-success p-1 rounded-0 addDelete"
                                                                        (click)="deleteVarients(varient,m,form.custom[i].variable_description)">
                                                                        <i class="fas fa-times-circle"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-3 col-12 form-group m-auto">
                                                            <button
                                                                class="btn rounded-0 productInfo bg-dark text-white p-2 my-lg-5"
                                                                (click)="differentShowing(m,varient.everything)">
                                                                Use Same price & quality
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-flex">
                                                    <p class="my-auto mx-3">Add Varients (optional)</p>
                                                    <button class="btn p-0 border text-center d-flex flex-column"
                                                        style="width:100px;height: 60px;" [disabled]="varient.disable"
                                                        (click)="addVarient(varient,form.custom[i].variable_description,form)">
                                                        <img [src]="varient.image" class="mx-auto" height="40px" alt="">
                                                        <small class="m-auto">{{varient.name}}</small>
                                                    </button>
                                                </div>
                                            </ng-template>
                                        </ngb-tab>
                                    </ngb-tabset>
                                </div>

                            </div>
                        </form>

                        <form #setProductUploaddiffForm="ngForm" novalidate *ngIf="differentPriceAndDes2.checked">
                            <div class="d-flex">
                                <div>
                                    <label class="custom mb-0">This is a Physical Product
                                        <input type="checkbox" name="physicalProduct" [(ngModel)]="form.productType">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="mx-2" *ngIf="form.productType">
                                    <div class="d-lg-inline-flex">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="physicalProductType" #new
                                                [(ngModel)]="form.physical_product_condition" value="new">
                                            <label class="form-check-label" for="inlineRadio1">New</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="physicalProductType"
                                                value="refurbished" [(ngModel)]="form.physical_product_condition"
                                                #refurbished>
                                            <label class="form-check-label" for="inlineRadio2">refurbished</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="physicalProductType"
                                                value="used" [(ngModel)]="form.physical_product_condition" #used>
                                            <label class="form-check-label" for="inlineRadio2">used</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="my-4 row">

                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-lg-2">
                                            <div class="text-center border position-relative  border-black"
                                                style="height: 90%;">
                                                <img [src]="form.coverImg" height="180px" alt="" width="100%">
                                                <div class="bg-secondary text-white producti">
                                                    Product {{page}}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-10">
                                            <div class="row">

                                                <!-- catagory -->
                                                <div class="col-3">
                                                    <div class="form-group productInfo">
                                                        <label class="title" style="z-index: 2;">Catagory</label>
                                                        <div ngbDropdown class="d-inline-block w-100"
                                                            #CatDrop="ngbDropdown">
                                                            <button style="overflow-x: scroll; overflow-y: hidden;"
                                                                class="btn py-2 px-4 btn-block d-flex justify-content-between form-control"
                                                                type="button" ngbDropdownToggle>
                                                                <div>
                                                                    <span *ngIf="form.product_main_category">
                                                                        {{form.product_main_category}}</span>
                                                                    <span *ngIf="form.product_sub_main_category"><i
                                                                            class="fas fa-angle-double-right m-1"></i>
                                                                        {{form.product_sub_main_category}}</span>
                                                                    <span *ngIf="form.product_sub_category"><i
                                                                            class="fas fa-angle-double-right m-1"></i>{{form.product_sub_category}}</span>
                                                                </div>
                                                            </button>
                                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                                                style="min-height: auto; max-height: 400px; overflow-y: scroll; width: 400px;">
                                                                <div class="bg-success p-2 text-center ">
                                                                    <a routerLink="/create-new-catagory"
                                                                        class="text-white"
                                                                        routerLinkActive="active-link" target="_blank">
                                                                        Create new
                                                                        catagory</a>
                                                                </div>
                                                                <div class="input-group p-2">
                                                                    <div class="input-group-prepend"
                                                                        style="width: 15%;">
                                                                        <span
                                                                            class="input-group-text border-black rounded-0 bg-transparent border-right-0">
                                                                            <i class="fas fa-search"></i></span>
                                                                    </div>
                                                                    <input type="text" class="search" ngModel
                                                                        name="searchCatagory" #searchCatagory>
                                                                </div>
                                                                <ul class="list-group list-group-flush">
                                                                    <li class="list-group-item border-0"
                                                                        *ngFor="let item of catagories; index as i">
                                                                        <div class="d-flex">
                                                                            <label
                                                                                class="custom mb-0">{{item.category_name}}
                                                                                <input type="radio" [value]="item._id"
                                                                                    (click)="onCat(item,'','',CatDrop,form)"
                                                                                    class="form-check-input m-2"
                                                                                    name="product_main_category_id"
                                                                                    [(ngModel)]="form.product_main_category_id">
                                                                                <span class="checkmark"></span>
                                                                            </label>
                                                                            <button class="btn p-0 ml-4" type="button"
                                                                                *ngIf="item.main_sub_category.length > 0"
                                                                                (click)="Showsubcat(item)">
                                                                                <i
                                                                                    [class]="!item.click? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                            </button>
                                                                        </div>
                                                                        <ul class="list-group list-group-flush ml-4"
                                                                            *ngIf="item.click">
                                                                            <li class="list-group-item border-0"
                                                                                *ngFor="let val of item.main_sub_category; index as j">
                                                                                <div class="d-flex">
                                                                                    <label
                                                                                        class="custom mb-0">{{val.category_name}}
                                                                                        <input type="radio"
                                                                                            [value]="val._id"
                                                                                            (click)="onCat(item,val,'',CatDrop,form)"
                                                                                            [(ngModel)]="form.product_sub_main_category_id"
                                                                                            class="form-check-input m-2"
                                                                                            name="product_sub_main_category_id">
                                                                                        <span class="checkmark"></span>
                                                                                    </label>
                                                                                    <button class="btn p-0 ml-4"
                                                                                        type="button"
                                                                                        *ngIf="val.sub_category.length > 0"
                                                                                        (click)="Showsubcat(val)">
                                                                                        <i
                                                                                            [class]="!val.click? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                                    </button>
                                                                                </div>
                                                                                <ul class="list-group list-group-flush ml-4"
                                                                                    *ngIf="val.click">
                                                                                    <li class="list-group-item border-0"
                                                                                        *ngFor="let v of val.sub_category; index as m">
                                                                                        <div class="d-flex">
                                                                                            <label
                                                                                                class="custom mb-0">{{v.category_name}}
                                                                                                <input type="radio"
                                                                                                    [value]="v._id"
                                                                                                    (click)="onCat(item,val,v,CatDrop,form)"
                                                                                                    class="form-check-input m-2"
                                                                                                    [(ngModel)]="form.catagory_id"
                                                                                                    name="catagory_id">
                                                                                                <span
                                                                                                    class="checkmark"></span>
                                                                                            </label>
                                                                                            <button class="btn p-0 ml-4"
                                                                                                type="button"
                                                                                                *ngIf="v.sub_category">
                                                                                                <i
                                                                                                    [class]="v.sub_category? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                                            </button>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- Brand -->
                                                <div class="col">
                                                    <div class="form-group productInfo">
                                                        <label class="title" style="z-index: 2;">Brand</label>
                                                        <div ngbDropdown class="d-inline-block w-100"
                                                            #brandDrop="ngbDropdown">
                                                            <button
                                                                class="btn py-2 px-4 btn-block d-flex justify-content-between form-control"
                                                                type="button" ngbDropdownToggle>
                                                                <span>{{form.brandName}}</span>
                                                            </button>
                                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                                                style="min-height: auto; max-height: 400px; overflow-y: scroll;">
                                                                <div class="bg-success p-2 text-center ">
                                                                    <a routerLink="/brands" class="text-white"
                                                                        routerLinkActive="active-link" target="_blank">
                                                                        Create new Brand</a>
                                                                </div>
                                                                <div class="input-group" *ngIf="brands.length > 0">
                                                                    <div class="input-group p-2">
                                                                        <div class="input-group-prepend"
                                                                            style="width: 15%;">
                                                                            <span
                                                                                class="input-group-text border-black rounded-0 bg-transparent border-right-0">
                                                                                <i class="fas fa-search"></i></span>
                                                                        </div>
                                                                        <input type="text" class="search" ngModel
                                                                            name="searchCatagory" #searchCatagory>
                                                                    </div>
                                                                </div>
                                                                <div class="">
                                                                    <ul class="list-group list-group-flush">
                                                                        <li class="list-group-item border-0"
                                                                            *ngFor="let brand of brands">
                                                                            <div class="d-flex">
                                                                                <label
                                                                                    class="custom mb-0">{{brand.brand_name}}
                                                                                    <input type="radio"
                                                                                        [value]="brand._id"
                                                                                        (click)="onBrand(brand,brandDrop,form)"
                                                                                        [(ngModel)]="form.brandId"
                                                                                        class="form-check-input m-2"
                                                                                        name="brand">
                                                                                    <span class="checkmark"></span>
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- type of product -->
                                                <div class="col">
                                                    <div class="form-group productInfo">
                                                        <label class="title" style="z-index: 2;">Type of
                                                            product</label>
                                                        <div ngbDropdown class="d-inline-block w-100">
                                                            <button
                                                                class="btn py-2 px-4 btn-block d-flex justify-content-between form-control"
                                                                type="button" ngbDropdownToggle>
                                                                <span>{{form.typeOfProduct}}</span>
                                                            </button>
                                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                                                style="min-height: auto; max-height: 400px; overflow-y: scroll;">
                                                                <div class="bg-success p-2 text-center ">
                                                                    <a routerLink="/brands" class="text-white"
                                                                        routerLinkActive="active-link">
                                                                        Create new Brand</a>
                                                                </div>
                                                                <div class="input-group p-2">
                                                                    <div class="input-group-prepend"
                                                                        style="width: 12%;">
                                                                        <span
                                                                            class="input-group-text border-black rounded-0 bg-transparent border-right-0">
                                                                            <i class="fas fa-search"></i></span>
                                                                    </div>
                                                                    <input type="text" class="search" ngModel
                                                                        name="searchCatagory" #searchCatagory>
                                                                </div>
                                                                <div class="">
                                                                    <ul class="list-group list-group-flush">
                                                                        <li class="list-group-item border-0"
                                                                            *ngFor="let item of type_of_productList; index as i">
                                                                            <div class="d-flex">
                                                                                <label
                                                                                    class="custom mb-0">{{item.type_of_product_sub}}
                                                                                    <input type="radio"
                                                                                        [value]="item.type_of_product_sub"
                                                                                        (click)="onType(item,form)"
                                                                                        [(ngModel)]="form.typeOfProduct"
                                                                                        class="form-check-input m-2"
                                                                                        name="productType">
                                                                                    <span class="checkmark"></span>
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- product name  -->
                                                <div class="col-lg-5">
                                                    <div class="form-group productInfo">
                                                        <label class="title">*Product Name</label>
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="form.productName" name="productNameSingle"
                                                            #productNameSingle>
                                                    </div>
                                                </div>

                                                <div class="col-12"
                                                    [class.d-none]="form.varientsforSingleProductCustomforMake.length > 0 && form.varientsforSingleProductCustomforMake[0].everything[0].differentShow">
                                                    <div class="row">
                                                        <!-- price you Want form buyer -->
                                                        <div class="col-lg-2 col-6">
                                                            <div class="form-group productInfo">
                                                                <label class="title">*Price</label>
                                                                <div class="">
                                                                    <input type="text" class="form-control"
                                                                        (keyup)="price(form)" [(ngModel)]="form.price"
                                                                        name="PriceUWantFromBuyerSingle"
                                                                        #PriceUWantFromBuyerSingle>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- discount shown to buyer  -->
                                                        <div class="col-lg-2 col-6">
                                                            <div class="form-group productInfo">
                                                                <label class="title">*Discount</label>
                                                                <div>
                                                                    <input type="text" class="form-control"
                                                                        name="DiscountToShownToBuyerSingle"
                                                                        [(ngModel)]="form.discount"
                                                                        #DiscountToShownToBuyerSingle
                                                                        (keyup)="discount(form)">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- MRP price -->
                                                        <div class="col-lg-2 col-6">
                                                            <div class="form-group productInfo">
                                                                <label class="title">*MRP</label>
                                                                <div>
                                                                    <input type="text" class="form-control"
                                                                        name="totalPrice" [(ngModel)]="form.mrp"
                                                                        #totalPrice (keyup)="totalprice(form)">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- product cost price  -->
                                                        <div class="col-lg-2 col-6">
                                                            <div class="form-group productInfo">
                                                                <label class="title">Product Cost Price</label>
                                                                <div>
                                                                    <input type="text" class="form-control"
                                                                        name="ProductCostPriceSingle"
                                                                        #ProductCostPriceSingle
                                                                        [(ngModel)]="form.productCostPrice"
                                                                        (keyup)="Pofit(form)">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- margin  -->
                                                        <div class="col-lg-2 col-6">
                                                            <div class="form-group productInfo">
                                                                <label class="title">Margin</label>
                                                                <div class="form-control">
                                                                    {{form.margin}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- profit  -->
                                                        <div class="col-lg-2 col-6">
                                                            <div class="form-group productInfo">
                                                                <label class="title">Profit</label>
                                                                <div class="form-control">
                                                                    {{form.profit}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- price shown to buyer  -->
                                                <div class="col-lg-7">
                                                    <div class="form-group productInfo border border-dark d-flex justify-content-between p-1"
                                                        style="height: 45px;">
                                                        <p class="font-weight-bold my-auto text-size">Price Shown To
                                                            Buyer</p>
                                                        <p class="m-auto text-size previousOne" *ngIf="form.mrp">
                                                            Rs.{{form.mrp}}</p>
                                                        <p class="m-auto text-size" *ngIf="form.price">
                                                            Rs.{{form.price}}<span class="text-danger"
                                                                *ngIf="form.discount">
                                                                ({{form.discount}}% off)</span></p>
                                                        <button
                                                            class="btn btn-sm btn-success text-size mx-2">Rs.99(10%)</button>
                                                        <button
                                                            class="btn btn-success text-size mx-2">Rs.109(10%)</button>
                                                        <button
                                                            class="btn btn-success text-size mx-2">Rs.110(10%)</button>
                                                    </div>
                                                </div>

                                                <!-- quantity in stock  -->
                                                <div class="col-lg col-6">
                                                    <div class="form-group productInfo">
                                                        <label class="title">*Quantity in Stock</label>
                                                        <div class="form-control d-flex justify-content-between">
                                                            <div class="w-50 py-2">
                                                                <input type="number" class="w-100 border-0"
                                                                    *ngIf="!unlimitedSingle.checked"
                                                                    [(ngModel)]="form.quantity"
                                                                    name="QuantityInStockSingle" #QuantityInStockSingle
                                                                    (keyup)="quantityInStock(form)">
                                                            </div>
                                                            <div class="w-50 py-2">
                                                                <input type="checkbox" class="mx-2"
                                                                    [(ngModel)]="form.quantity_in_stock_unlimited"
                                                                    name="unlimitedSingle" #unlimitedSingle>
                                                                <label>Unlimited</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <!-- avaliable shop upto  -->
                                                <div class="col-lg col-6">
                                                    <div class="productInfo">
                                                        <label class="title">Avaliable to shop up to</label>
                                                        <input class="form-control w-100" type="date"
                                                            [min]="today | date:'yy-MM-dd'"
                                                            name="AvaliableToShopUpToSingle"
                                                            [(ngModel)]="form.AvaliableToShopUpTo"
                                                            #AvaliableToShopUpToSingle>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- description  -->
                                <div class="col-lg-5">
                                    <div class="form-group ">
                                        <label class="title">Description(Optional)</label>
                                        <textarea class="form-control" rows="4" [(ngModel)]="form.Description"
                                            name="DescriptionSingle" #DescriptionSingle></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-7">
                                    <div class="row">

                                        <!-- gst Percentage -->
                                        <div class="col-3">
                                            <label class="title">HSN Code</label>
                                            <select class="form-control" name="gst" [(ngModel)]="form.gst_id">
                                                <option [value]="gst._id" *ngFor="let gst of gstList">
                                                    {{gst.store_hsn_code}}({{gst.store_description_of_goods}})
                                                </option>
                                            </select>
                                        </div>
                                        <!-- pre Order -->
                                        <div class="col-lg-auto col-6">
                                            <div class="form-group productInfo">
                                                <label class="title">Pre Order</label>
                                                <div class="form-control">
                                                    <div class="form-check ">
                                                        <label class="mr-4 my-1">Pre Order</label>
                                                        <input type="checkbox" ngModel name="PreOrderSingle"
                                                            #PreOrderSingle [(ngModel)]="form.PreOrder">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- expected launch date -->
                                        <div class="col-lg-auto col-6">
                                            <div class="productInfo">
                                                <label class="title">Expected Launch Date</label>
                                                <input class="form-control w-100" type="date"
                                                    [min]="today | date:'yy-MM-dd'" [disabled]="!form.PreOrder"
                                                    [(ngModel)]="form.expectedLaunchDate"
                                                    name="ExpectedLaunchDateSingle" #ExpectedLaunchDateSingle>
                                            </div>
                                        </div>

                                        <!-- barcode no -->
                                        <div class="col-lg-3">
                                            <div class="form-group productInfo">
                                                <label class="title">Barcode No</label>
                                                <div>
                                                    <input type="text" class="form-control" [(ngModel)]="form.barcode"
                                                        name="BarcodeNoSingle" #BarcodeNoSingle
                                                        (keyup)="barcodeNo(form)">
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Sku -->
                                        <div class="col-lg-3" *ngIf="!sku_weight_mesurement.store_generate_auto">
                                            <div class="form-group productInfo">
                                                <label class="title">*SKU</label>
                                                <div class="input-group" *ngIf="sku_weight_mesurement.store_sku_prefix">
                                                    <div class="input-group-prepend">
                                                        <div class="input-group-text rounded-0">
                                                            {{sku_weight_mesurement.store_sku_prefix}}
                                                        </div>
                                                    </div>
                                                    <input type="text" class="form-control" [(ngModel)]="form.sku"
                                                        name="SKUSingle" #SKUSingle="ngModel"
                                                        (keyup)="sku(form,setImageLinks)">
                                                </div>
                                                <input type="text" *ngIf="!sku_weight_mesurement.store_sku_prefix"
                                                    class="form-control" [(ngModel)]="form.sku" name="SKUSingle"
                                                    #SKUSingle="ngModel" (keyup)="sku(form,setImageLinks)">
                                            </div>
                                            <div *ngIf="form.sku_valid.status" class="p-2 btn" style="position: absolute;
                                            z-index: 250;
                                            width: 100%;
                                            bottom: -10px;
                                            left: 0;" [class.btn-success]="form.sku_valid.status === 'success'"
                                                [class.btn-danger]="form.sku_valid.status === 'fail'">
                                                <small>{{form.sku_valid.message}}</small>
                                            </div>
                                        </div>
                                        <!-- source Vendor -->
                                        <div class="col-lg-3 col-6">
                                            <div class="form-group productInfo">
                                                <label class="title" style="z-index: 2;">Source Vendor</label>
                                                <div ngbDropdown class="d-inline-block w-100">
                                                    <button
                                                        class="btn py-2 px-4 btn-block d-flex justify-content-between form-control"
                                                        type="button" ngbDropdownToggle>
                                                        <span>{{form.SourceVendor}}</span>
                                                    </button>
                                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                                        style="min-height: auto; max-height: 400px; overflow-y: scroll;">
                                                        <div class="bg-success p-2 text-center ">
                                                            <a routerLink="/add-vendor" class="text-white"
                                                                routerLinkActive="active-link">
                                                                Add Vendor</a>
                                                        </div>
                                                        <div class="input-group p-2">
                                                            <div class="input-group-prepend" style="width: 12%;">
                                                                <span
                                                                    class="input-group-text border-black rounded-0 bg-transparent border-right-0">
                                                                    <i class="fas fa-search"></i></span>
                                                            </div>
                                                            <input type="text" class="search" ngModel
                                                                name="searchCatagory" #searchCatagory>
                                                        </div>
                                                        <div class="">
                                                            <ul class="list-group list-group-flush">
                                                                <li class="list-group-item border-0"
                                                                    *ngFor="let item of vendorList; index as i">
                                                                    <div class="d-flex">
                                                                        <label
                                                                            class="custom mb-0">{{item.primary_contact}}
                                                                            <input type="radio"
                                                                                [value]="item.primary_contact"
                                                                                (click)="SourceVendor(item,form)"
                                                                                [(ngModel)]="form.SourceVendor"
                                                                                class="form-check-input m-2"
                                                                                name="SourceVendor">
                                                                            <span class="checkmark"></span>
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- refference Sku -->
                                        <div class="col-lg-3">
                                            <div class="form-group productInfo">
                                                <label class="title">Refference SKU</label>
                                                <input type="text" class="form-control" [(ngModel)]="form.RefferenceSKU"
                                                    name="RefferenceSKUSingle" #RefferenceSKUSingle>
                                            </div>
                                        </div>

                                        <!-- Country origin name -->
                                        <div class="col-lg-3">
                                            <div class="form-group productInfo">
                                                <label class="title">*Country origin name</label>
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="form.country_origin_name" name="country_origin"
                                                    #countryOrigin>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- add video URL -->
                                <div class="col-lg-4">
                                    <div class="form-group productInfo">
                                        <label class="title">Add Video URL</label>
                                        <input type="text" class="form-control" name="AddVideoURLSingle"
                                            #AddVideoURLSingle [(ngModel)]="form.AddVideoURL">
                                    </div>
                                </div>

                                <!-- Specifications -->

                                <div class="col-2">
                                    <div class="productInfo">
                                        <button class="btn form-control d-flex justify-content-between"
                                            (click)="open(content)">
                                            <span>Specifications</span>
                                            <span class="my-1 ml-4" style="font-size: 24px;"><i
                                                    class="fas fa-expand"></i></span>
                                        </button>
                                        <ng-template #content let-modal>
                                            <div class="modal-header">
                                                <h4 class="modal-title" id="modal-basic-title">Specification List</h4>
                                                <button type="button" class="close" aria-label="Close"
                                                    (click)="modal.dismiss('Cross click')">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="d-flex">
                                                    <ul ngbNav #nav="ngbNav" class="nav-pills" orientation="vertical">
                                                        <li ngbNavItem
                                                            *ngFor="let attribute of form.attributeList; index as i">
                                                            <a
                                                                ngbNavLink>{{attribute.type_of_product_attribute_name}}</a>
                                                            <ng-template ngbNavContent>
                                                                <div class="">
                                                                    <ul class="list-group list-group-flush">
                                                                        <li class="list-group-item border-0"
                                                                            *ngFor="let attr of attribute.type_of_product_attribute_values; index as a">
                                                                            <div class="d-flex">
                                                                                <label
                                                                                    class="custom mb-0">{{attr.type_of_product_value}}
                                                                                    <input type="checkbox"
                                                                                        (click)="productAttribute(attribute,attr,form,$event)"
                                                                                        ngModel
                                                                                        class="form-check-input m-2"
                                                                                        name="attribute{{a}}">
                                                                                    <span class="checkmark"></span>
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </ng-template>
                                                        </li>
                                                    </ul>
                                                    <div [ngbNavOutlet]="nav" class="ml-4"></div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>

                                <!-- gift wrap -->
                                <div class="col-4">
                                    <div class="row">
                                        <div class="col-8">
                                            <div class="productInfo">
                                                <div class="form-control d-flex justify-content-between">
                                                    <div class="my-auto ">Gift Wrap</div>
                                                    <div class="form-check form-check-inline">
                                                        <label class="form-check-label mx-1">Yes </label>
                                                        <input class="form-check-input mx-1" type="radio"
                                                            name="GiftWrapOptions" value=true
                                                            [(ngModel)]="form.giftWrap">
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <label class="form-check-label mx-1">No</label>
                                                        <input class="form-check-input mx-1" type="radio"
                                                            name="GiftWrapOptions" value=false
                                                            [(ngModel)]="form.giftWrap">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-4">
                                            <div class="form-group productInfo">
                                                <label class="title mx-0">Wrapping Price</label>
                                                <input type="text" class="form-control" name="gift_wrap_price"
                                                    [readonly]="!form.giftWrap || form.giftWrap ==='false'"
                                                    [(ngModel)]="form.gift_wrap_price">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- weight -->
                                <div class="col-lg-2">
                                    <div class="form-group productInfo ">
                                        <label class="title">Product Weight</label>
                                        <div class="input-group">
                                            <input type="number" class="form-control"
                                                [(ngModel)]="singleProductForm.weight" name="weightSingle"
                                                #weightSingle>
                                            <div class="input-group-append">
                                                <div class="input-group-text rounded-0">
                                                    {{unit}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <!-- free shipping -->
                                <!-- <div class="col-lg-2 col-6">
                                    <div class="form-group productInfo">
                                        <label class="title">Free Shipping</label>
                                        <div class="form-control">
                                            <div class="form-check ">
                                                <label class="mr-4 my-1">Free Shipping</label>
                                                <input type="checkbox" name="freeShipping" #freeShipping
                                                    [(ngModel)]="form.freeShipping">
                                            </div>
                                        </div>
                                    </div>
                                </div> -->


                                <div class="col-6" *ngIf="form.productAttribute.length > 0">
                                    <div class="productInfo row" *ngFor="let item of form.productAttribute;index as i">
                                        <!-- product attribute -->
                                        <div class="col-lg-5 col-6 form-group">
                                            <label class="title" style="z-index: 2;">Product Attribute </label>
                                            <div class="form-control" style="height: 45px;">
                                                {{item.attribute_name}}
                                            </div>
                                        </div>

                                        <!-- product attributeValue -->
                                        <div class="col-lg-6 col-6 form-group">
                                            <label class="title" style="z-index: 2;">Attribute Value</label>
                                            <div class="form-control" style="height: 45px;">
                                                {{item.attribute_description}}
                                            </div>
                                        </div>

                                        <div class="col-1 form-group">
                                            <!-- <button class="btn bg-white w-100 p-0 addDelete" *ngIf="item.deleteButton"
                                                type="button" (click)="deleteVar(form.productAttribute,item)">
                                                <i class="fas fa-trash-alt"></i>
                                            </button> -->
                                            <button class="btn bg-white w-100 p-0 addDelete" *ngIf="!item.deleteButton"
                                                type="button" (click)="addAttribute(form)">
                                                <i class="fas fa-plus-circle"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <!-- variable list -->
                                <div class="col-6">
                                    <div class="productInfo d-flex" *ngFor="let item of form.variableList;index as i">
                                        <div class="w-35 pr-3 form-group">
                                            <label class="title">Variable Name</label>
                                            <input type="text" class="form-control" name="VariableNameSingle{{i}}"
                                                [(ngModel)]="item.name">
                                        </div>
                                        <div class="w-69 px-3 form-group">
                                            <label class="title">Variable Description</label>
                                            <input type="text" class="form-control" name="VariableDesSingle{{i}}"
                                                [(ngModel)]="item.des">
                                        </div>
                                        <div class="w-6 form-group">
                                            <button class="btn bg-white w-100 p-0 addDelete" *ngIf="item.deleteButton"
                                                type="button" (click)="deleteVar(form.variableList,item)">
                                                <i class="fas fa-trash-alt"></i>
                                            </button>
                                            <button class="btn bg-white w-100 p-0 addDelete" *ngIf="!item.deleteButton"
                                                type="button" (click)="addMore(item,form.variableList)">
                                                <i class="fas fa-plus-circle"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <!-- bulk price -->
                                <div class="col-6">
                                    <div class="row productInfo">
                                        <div class="col-2 text-center p-2">Bulk Price</div>
                                        <div class="col-10">
                                            <div class="row" *ngFor="let item of form.bulkPricelist;index as i">
                                                <div class="col-3 form-group">
                                                    <label class="title mx-2">Min Quality</label>
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="item.minQuality"
                                                        name="BulkPriceMinQualitySingle{{i}}"
                                                        #BulkPriceMinQualitySingle{{i}}>
                                                </div>
                                                <div class="col-3 form-group">
                                                    <label class="title mx-2">Max Quality</label>
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="item.maxQuality"
                                                        name="BulkPriceMaxQualitySingle{{i}}"
                                                        #BulkPriceMaxQualitySingle{{i}}>
                                                </div>
                                                <div class="col-3 form-group">
                                                    <label class="title mx-2">Price</label>
                                                    <input type="text" class="form-control" ngModel
                                                        [(ngModel)]="item.price" name="BulkPriceSingle{{i}}"
                                                        #BulkPriceSingle{{i}}>
                                                </div>
                                                <div class="col-1 form-group">
                                                    <button type="button" class="btn bg-white w-100 p-0 addDelete"
                                                        *ngIf="item.deleteButton"
                                                        (click)="deleteVar(form.bulkPricelist,i)">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                    <button type="button" class="btn bg-white w-100 p-0 addDelete"
                                                        *ngIf="!item.deleteButton"
                                                        (click)="addMore2(item,form.bulkPricelist)">
                                                        <i class="fas fa-plus-circle"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- add varients -->
                                <div class="col-12">
                                    <ngb-tabset type="pills" class="add-varients">
                                        <ngb-tab title="Add Varients (optional)" [disabled]="true">
                                        </ngb-tab>
                                        <ngb-tab
                                            *ngFor="let varient of form.varientsforSingleProductCustomforMake;index as i">
                                            <ng-template ngbTabTitle>
                                                <div class="border text-center d-flex flex-column"
                                                    style="width:100px;height: 60px;">
                                                    <img [src]="varient.image" class="mx-auto" class="mx-auto my-1"
                                                        height="30px" width="30px" alt="">
                                                    <small class="text-center">{{varient.name}}</small>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbTabContent>
                                                <div *ngFor="let a of varient.everything; index as m">
                                                    <div class="row" *ngIf="a.sameShow">
                                                        <div class="col-lg-9 col-12 form-group my-3">
                                                            <div class="title float-left"
                                                                style="top: 0px; left: -15px;">
                                                                {{m+1}}
                                                            </div>
                                                            <div class="productInfo border border-dark"
                                                                style="height: 70px;">
                                                                <div class="form-group d-flex w-75 title">
                                                                    <div class="flex-fill px-2"
                                                                        *ngFor="let v of a.details;index as r">
                                                                        <label class="title text-capitalize">{{v.name}}
                                                                            name</label>
                                                                        <select class="form-control"
                                                                            name={{v.name}}{{m}}
                                                                            [(ngModel)]="form.custom[i].variable_description[m].value"
                                                                            (change)="optionDisable(form.custom[i].variable_description,v.list)">
                                                                            <option *ngFor="let lt of v.list"
                                                                                [value]=[lt.varient_name]
                                                                                [disabled]="!lt.status">
                                                                                {{lt.varient_name}}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="title float-right"
                                                                style="top:0px; right: -25px;"
                                                                *ngIf="varient.everything.length > 1">
                                                                <button
                                                                    class="btn btn-outline-success p-1 rounded-0 addDelete"
                                                                    (click)="deleteVarients(varient,m,form.custom[i].variable_description)">
                                                                    <i class="fas fa-times-circle"></i>
                                                                </button>
                                                            </div>

                                                        </div>
                                                        <div class="col-lg-3 col-12 form-group my-auto">
                                                            <button
                                                                class="btn productInfo bg-dark text-white p-2 my-lg-4 rounded-0"
                                                                (click)="sameShowing(m,varient.everything)">
                                                                Use Different price & quality
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="row" *ngIf="a.differentShow">
                                                        <div class="col-lg-9 col-12 form-group my-3">
                                                            <div class="title float-left"
                                                                style="top: 0px; left: -15px;">
                                                                {{m+1}}
                                                            </div>
                                                            <div class=" productInfo  d-flex  flex-column border border-dark"
                                                                style="min-height: 100px;">
                                                                <div class="form-group d-flex w-75 title">
                                                                    <div class="flex-fill px-2"
                                                                        *ngFor="let v of a.details;index as r">
                                                                        <label class="title text-capitalize">{{v.name}}
                                                                            name</label>
                                                                        <select class="form-control"
                                                                            name={{v.name}}{{m}}
                                                                            [(ngModel)]="form.custom[i].variable_description[m].value">
                                                                            <option *ngFor="let lt of v.list"
                                                                                [value]=[lt.varient_name]>
                                                                                {{lt.varient_name}}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="row mt-5 px-4">

                                                                    <!-- price -->
                                                                    <div class="col-4 ">
                                                                        <div class="form-group productInfo">
                                                                            <label class="title">Price You
                                                                                Want Form Buyer</label>
                                                                            <div class="d-flex justify-content-between">
                                                                                <input type="text" class="form-control "
                                                                                    [(ngModel)]="form.custom[i].variable_description[m].price"
                                                                                    name="priceWantFromBuyercustom{{m}}"
                                                                                    (keyup)="price(form.custom[i].variable_description[m])">
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <!-- discount -->
                                                                    <div class="col-4 ">
                                                                        <div class="form-group productInfo">
                                                                            <label for="inputEmail4"
                                                                                class="title mx-4">Discount
                                                                                Percentage to show
                                                                                Buyer</label>
                                                                            <div class="d-flex justify-content-between">
                                                                                <input type="text" class="form-control"
                                                                                    name="discountFromBuyercustom{{m}}"
                                                                                    [(ngModel)]="form.custom[i].variable_description[m].discount"
                                                                                    (keyup)="discount(form.custom[i].variable_description[m])">
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <!-- mrp rupees -->
                                                                    <div class="col-4 ">
                                                                        <div class="form-group productInfo"><label
                                                                                for="inputEmail4"
                                                                                class="title">MRP</label>
                                                                            <div
                                                                                class="form-control d-flex justify-content-between">
                                                                                <div class="w-50 py-2">
                                                                                    <input type="text"
                                                                                        class="w-100 border-0"
                                                                                        name="mrp{{m}}"
                                                                                        [(ngModel)]="form.custom[i].variable_description[m].mrp"
                                                                                        (keyup)="mrp(form.custom[i].variable_description[m])">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <!-- quantity in stock -->
                                                                    <div class="col-4 ">
                                                                        <div class="form-group productInfo"><label
                                                                                for="inputEmail4" class="title">Quantity
                                                                                in Stock</label>
                                                                            <div
                                                                                class="form-control d-flex justify-content-between">
                                                                                <div class="w-50 py-2">
                                                                                    <input type="text"
                                                                                        class="w-100 border-0"
                                                                                        name="quantitycustom{{m}}"
                                                                                        [(ngModel)]="form.custom[i].variable_description[m].quantity">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <!-- barcode no -->
                                                                    <div class="col-lg-4 col-6">
                                                                        <div class="form-group productInfo">
                                                                            <label class="title">Barcode No</label>
                                                                            <div>
                                                                                <input type="text" class="form-control"
                                                                                    name="BarcodeNoSinglecustom{{m}}"
                                                                                    [(ngModel)]="form.custom[i].variable_description[m].barcode"
                                                                                    #BarcodeNoSingle>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- Sku -->
                                                                    <div class="col-lg-4">
                                                                        <div class="form-group productInfo">
                                                                            <label class="title">SKU</label>
                                                                            <input type="text" class="form-control"
                                                                                [(ngModel)]="form.custom[i].variable_description[m].sku"
                                                                                name="SKUSinglecustom{{m}}"
                                                                                #SKUSingle{{m}}>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class="form-group productInfo border border-dark d-flex justify-content-between p-1"
                                                                            style="height: 45px;">
                                                                            <p
                                                                                class="font-weight-bold m-auto text-size">
                                                                                Price
                                                                                Shown To Buyer</p>
                                                                            <p class="m-auto text-size previousOne">Rs.
                                                                                {{form.custom[i].variable_description[m].price}}
                                                                            </p>
                                                                            <p class="m-auto text-size"
                                                                                *ngIf="form.custom[i].variable_description[m].discount">
                                                                                Rs.
                                                                                {{form.custom[i].variable_description[m].mrp}}
                                                                                <span
                                                                                    class="text-danger">({{form.custom[i].variable_description[m].discount}}%
                                                                                    off)</span>
                                                                            </p>
                                                                            <button
                                                                                class="btn btn-sm btn-success text-size mx-2">Rs.99(10%)</button>
                                                                            <button
                                                                                class="btn btn-success text-size mx-2">Rs.109(10%)</button>
                                                                            <button
                                                                                class="btn btn-success text-size mx-2">Rs.110(10%)</button>
                                                                            <button
                                                                                class="btn btn-primary text-size rounded-0 mx-2">GST
                                                                                Applied</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="title float-right"
                                                                    style="top:0px; right: -25px;"
                                                                    *ngIf="varient.everything.length > 1">
                                                                    <button
                                                                        class="btn btn-outline-success p-1 rounded-0 addDelete"
                                                                        (click)="deleteVarients(varient,m,form.custom[i].variable_description)">
                                                                        <i class="fas fa-times-circle"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-3 col-12 form-group m-auto">
                                                            <button
                                                                class="btn rounded-0 productInfo bg-dark text-white p-2 my-lg-5"
                                                                (click)="differentShowing(m,varient.everything)">
                                                                Use Same price & quality
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-flex">
                                                    <p class="my-auto mx-3">Add Varients (optional)</p>
                                                    <button class="btn p-0 border text-center d-flex flex-column"
                                                        style="width:100px;height: 60px;" [disabled]="varient.disable"
                                                        (click)="addVarient(varient,form.custom[i].variable_description,form)">
                                                        <img [src]="varient.image" class="mx-auto" height="40px" alt="">
                                                        <small class="m-auto">{{varient.name}}</small>
                                                    </button>
                                                </div>
                                            </ng-template>
                                        </ngb-tab>
                                    </ngb-tabset>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>

                <div class="row">
                    <div class="col-6 text-left">
                        <ngb-pagination *ngIf="setImageLinks.length > 1" [collectionSize]="setImageLinks.length"
                            [(page)]="page" [pageSize]="pageSize" [boundaryLinks]="true"></ngb-pagination>
                    </div>
                    <div class="col-6 text-right">
                        <button class="btn btn-danger rounded-0" type="button"
                            *ngIf="page === 1 && setImageLinks.length > 1" (click)="copy(setImageLinks)">
                            Copy to All</button>
                        <button class="btn btn-danger rounded-0" type="button"
                            *ngIf="page === setImageLinks.length && samepriceAndDes2.checked"
                            (click)="multipleProductsave('set_product_upload',setImageLinks)">
                            Save Product</button>
                        <button class="btn btn-danger rounded-0" type="button"
                            *ngIf="page === setImageLinks.length && differentPriceAndDes2.checked"
                            (click)="multipleProductsave('set_product_upload',setImageLinks)">
                            Save Product</button>
                    </div>
                </div>
            </ng-template>
        </ngb-tab>
    </ngb-tabset>
</div>