export class BannerSetting {
    constructor(
        public bannerImages:Array<Object>,
        public country: string,
        public name: string,
        public animation: boolean,
        public animationtime: number,
        public showImageNumber: number,
        public bannerStyle: string,
        public applyPage: string,
        public link: string
    ){}
}
