import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-child3',
  templateUrl: './child3.component.html',
  styleUrls: ['./child3.component.css']
})
export class Child3Component implements OnInit {

  imgType = true;

  fetureMenu= [
    {img:'', featuredName: 'Diabetes', featuredTag:'30% Off on women saree', link:'https://youtube.com', country:'India'},
    {img:'', featuredName: 'Joint Care',  featuredTag:'30% Off on women saree', link:'https://youtube.com', country:'India'},
    {img:'', featuredName: 'Male Power Booster',  featuredTag:'30% Off on women saree', link:'https://youtube.com',  country:'India'}
  ]

  onSubmit(form:NgForm,img){
    if (img.value.includes(".png") || img.value.includes(".jpg")) {
      console.log(form.value);
      this.fetureMenu.push(form.value);
      form.reset();
    } 
    console.log(img.value)
  }

  upload(event){
    if (event.value.includes(".png") || event.value.includes(".jpg")) {
      this.imgType = true;
    } else {
      this.imgType = false;
    }
  }


  constructor() { }

  ngOnInit(): void {
  }

}
