import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-delivary-garruenty',
  templateUrl: './delivary-garruenty.component.html',
  styleUrls: ['./delivary-garruenty.component.css']
})
export class DelivaryGarruentyComponent implements OnInit {

  delivary_type = 'Shipping Method Wise'

  shipping_delivery_day = '5'

  delivery_terms_conditions = ''

  countryZone = []

  stateZone: any[]

  countries: any[];
  shippingList = [];

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '200px',
    minHeight: '200px',
    maxHeight: '200px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'no',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

  response: any = { status: '', message: '', type: '' }

  selectedCountries: any = [];

  _handleZone(country, array, zone) {
    console.log(country)
    zone.push(country)
    array.map((res, index) => {
      if (res.shipping_name === country.shipping_name) {
        array.splice(index, 1)
      }
    })
  }

  edit(ship) {
    this.shippingList.map((res, index) => {
      if (res.shipping_name === ship.shipping_name) {
        this.countryZone.push(res)
        this.shippingList.splice(index)
      }
    })
    this.delivery_terms_conditions = ship.delivery_terms_conditions
    this.shipping_delivery_day = ship.shipping_delivery_day
  }

  update() {
    this.countryZone.map((res) => {
      res.shipping_delivery_day = parseInt(this.shipping_delivery_day)
      res.delivery_terms_conditions = this.delivery_terms_conditions
      this.api.put(`update_shipping`, res).subscribe(
        data => {
          console.log(data)
          this.response = data
          if (this.response.status === 'success') {
            this.response.message = 'Shipping Delivary Day Added successfully'
            window.location.reload()
          }
        },
        error => console.log(error)
      )
    })
  }

  delete(ship) {
    this.api.put(`update_shipping`, { "_id": ship._id, "shipping_delivery_day": 0, "delivery_terms_conditions": '' }).subscribe(
      data => {
        console.log(data)
        this.response = data
        if (this.response.status === 'success') {
          this.response.message = 'Shipping Delivary Day Added successfully'
          window.location.reload()
        }
      },
      error => console.log(error)
    )
  }

  close(country,index) {
    this.countryZone.splice(index,1)
    this.selectedCountries.push(country)
  }

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.api.get('shipping_list').subscribe(
      data => {
        let country = Object.values(data);
        country[2].map((result) => {
          if (result.shipping_delivery_day) {
            this.shippingList.push(result)
          } else {
            this.selectedCountries.push(result)
          }
        })
        console.log(this.selectedCountries)
      },
      error => console.log('Error!', error)
    )
  }

}
