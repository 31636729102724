<div class="loaderBackground" *ngIf="loader">
    <div class="loaderparent"></div>
    <div class="loader">
        <div class="spinner-grow text-success mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success  mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success  mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>
    <div class="container-fluid">
        <h1>View Customers</h1>
        <ul ngbNav #nav="ngbNav" class="nav-tabs customer-component">
            <li ngbNavItem>
                <a ngbNavLink>All Customers</a>
                <ng-template ngbNavContent>
                    <div class="py-3">
                        <table class="table border">
                            <thead>
                                <tr>
                                    <th scope="col-1"><input type="checkbox"></th>
                                    <th scope="col-3">Name</th>
                                    <th scope="col-1">Email</th>
                                    <th scope="col-1">Phone(optional)</th>
                                    <th scope="col-1" *ngIf="customerGroup">Group</th>
                                    <th scope="col-1">Store Credit</th>
                                    <th scope="col-1">Joined</th>
                                    <th scope="col-1">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let customer of customers">
                                    <th scope="col-1">
                                        <input type="checkbox" [name]="customer.fullname" [(ngModel)]="customer.status">
                                    </th>
                                    <td>{{customer.fullname}}</td>
                                    <td>{{customer.email}}</td>
                                    <td>{{customer.phone}}</td>
                                    <td *ngIf="customerGroup">{{customerGroup}}</td>
                                    <td>
                                        <div class="row">
                                            <div class="input-group col-8">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon1"></span>
                                                </div>
                                                <input type="text" class="form-control" name="store_credit"
                                                    [(ngModel)]="customer.store_credit">
                                            </div>
                                            <button class="btn btn-success">Save</button>
                                        </div>
                                    </td>
                                    <td>{{customer.createdAt | date: 'short'}}</td>
                                    <td>
                                        <div ngbDropdown class="d-inline-block">
                                            <button class="btn p-0" id="dropdownBasic1" ngbDropdownToggle (click)="customer_orders(customer)">
                                                <i class="fas fa-ellipsis-h"></i></button>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                                <button ngbDropdownItem (click)="openXl(content,customer)">Edit</button>
                                                <button ngbDropdownItem (click)="openXl(orders,customer)">View Orders</button>
                                                <button ngbDropdownItem>View Notes</button>
                                                <button ngbDropdownItem>Login</button>
                                            </div>
                                        </div>
                                    </td>
                                    <ng-template #content let-modal>
                                        <div class="modal-header">
                                            <ul ngbNav #nav="ngbNav" class="nav-tabs customer">
                                                <li ngbNavItem>
                                                    <a ngbNavLink>Customer Details</a>
                                                    <ng-template ngbNavContent>
                                                        <div class="container-fluid">
                                                            <p>Fill the form below to specify the account details</p>
                                                            <h3>Customer Details</h3>
                                                            <form class="form">
                                                                <div class="form-group">
                                                                    <label>First Name</label>
                                                                    <input type="text" class="form-control"
                                                                        name="firstName" [(ngModel)]="first_name" readonly>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label>Last Name</label>
                                                                    <input type="text" class="form-control"
                                                                        name="lastName" [(ngModel)]="last_name" readonly>
                                                                </div>
                                                                <!-- <div class="form-group">
                                                                    <label>Company Name <span
                                                                            class="mr-auto">(optional)</span></label>
                                                                    <input type="text" class="form-control">
                                                                </div> -->
                                                                <div class="form-group">
                                                                    <label>Email address</label>
                                                                    <input type="email" class="form-control"
                                                                        name="email" [(ngModel)]="customer.email" readonly>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label>Customer Group <span
                                                                            class="mr-auto">(optional)</span></label>
                                                                    <select class="form-control">
                                                                        <option>1</option>
                                                                        <option>2</option>
                                                                        <option>3</option>
                                                                        <option>4</option>
                                                                        <option>5</option>
                                                                    </select>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label>Phone Number(optional)</label>
                                                                    <input type="text" class="form-control" name="phone" readonly
                                                                        [(ngModel)]="customer.phone">
                                                                </div>
                                                                <div class="form-group">
                                                                    <label>Store Credit</label>
                                                                    <div class="input-group">
                                                                        <div class="input-group-prepend">
                                                                            <div class="input-group-text"></div>
                                                                        </div>
                                                                        <input type="number" class="form-control">
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label>Receive ACS/Review Emails</label>
                                                                    <select class="form-control">
                                                                        <option>Yes</option>
                                                                        <option>No</option>
                                                                    </select>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label>Force Password Reset to Next Origin</label>
                                                                    <select class="form-control">
                                                                        <option>Yes</option>
                                                                        <option>No</option>
                                                                    </select>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label>Tax Exempt Code <span
                                                                            class="mr-auto">(optional)</span></label>
                                                                    <input type="text" class="form-control">
                                                                </div>
                                                            </form>

                                                            <!-- <h3 class="py-4">Customer Password</h3>
                                                            <form class="form">
                                                                <div class="form-group">
                                                                    <label>Password</label>
                                                                    <input type="password" class="form-control">
                                                                </div>
                                                                <div class="form-group">
                                                                    <label>Confirm Password</label>
                                                                    <input type="password" class="form-control">
                                                                </div>
                                                            </form> -->
                                                        </div>
                                                    </ng-template>
                                                </li>
                                                <li ngbNavItem>
                                                    <a ngbNavLink>Customer Address Book</a>
                                                    <ng-template ngbNavContent>
                                                        <div class="container-fluid">
                                                            <form class="form my-3" *ngFor="let address of customer.address; index as i">
                                                                <h4>Address {{i+1}}</h4>
                                                                <div class="form-row">
                                                                    <div class="form-group col-md-3">
                                                                        <label>Country</label>
                                                                        <input class="form-control"
                                                                            name="addresscountry"
                                                                            [(ngModel)]="address.country" readonly>
                                                                    </div>
                                                                    <div class="form-group col-md-3">
                                                                        <label for="inputZip">Code</label>
                                                                        <input class="form-control" name="countrycode"
                                                                            [(ngModel)]="address.countrycode" readonly>
                                                                    </div>
                                                                    <div class="form-group col-md-3">
                                                                        <label>State</label>
                                                                        <input type="text" class="form-control" readonly
                                                                            name="State" [(ngModel)]="address.state">
                                                                    </div>
                                                                    <div class="form-group col-md-3">
                                                                        <label>City</label>
                                                                        <input type="text" class="form-control" readonly
                                                                            name="city" [(ngModel)]="address.city">
                                                                    </div>
                                                                </div>
                                                                <div class="form-row">
                                                                    <div class="form-group col-md-3">
                                                                        <label>Landmark</label>
                                                                        <input type="text" class="form-control" readonly
                                                                            name="landmark" [(ngModel)]="address.landmark">
                                                                    </div>
                                                                    <div class="form-group col-md-3">
                                                                        <label>Street</label>
                                                                        <input type="text" class="form-control" readonly
                                                                            name="street" [(ngModel)]="address.street">
                                                                    </div>
                                                                    <div class="form-group col-md-3">
                                                                        <label>House Number</label>
                                                                        <input type="text" class="form-control" readonly
                                                                            name="house_number" [(ngModel)]="address.house_number">
                                                                    </div>
                                                                    <div class="form-group col-md-3">
                                                                        <label for="inputZip">Zip</label>
                                                                        <input type="text" class="form-control" name="zip_code" 
                                                                        [(ngModel)]="address.zip_code" readonly>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </ng-template>
                                                </li>
                                            </ul>
                                            <button type="button" class="close" aria-label="Close"
                                                (click)="modal.dismiss('Cross click')">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <div [ngbNavOutlet]="nav"></div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-outline-dark"
                                                (click)="modal.close('Save click')">Cancel</button>
                                            <button type="button" class="btn btn-outline-dark"
                                                (click)="modal.close('Save click')">Save & Add Another</button>
                                            <button type="button" class="btn btn-outline-dark"
                                                (click)="modal.close('Save click')">Save & Exit</button>
                                        </div>
                                    </ng-template>
                                    <ng-template #orders let-modal>
                                        <div class="modal-header border-0">
                                            <button type="button" class="close" aria-label="Close"
                                                (click)="modal.dismiss('Cross click')">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <div class="container-fluid">
                                                <h3>Order Details</h3>
                                                <form>
                                                    <table class="table border form">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col-3">Oder Ref</th>
                                                                <th scope="col-1">Delivay Address</th>
                                                                <th scope="col-1">Status</th>
                                                                <th scope="col-1">Ordered</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let order of ordersPerCustomer">
                                                                <td>{{order.order_id}}</td>
                                                                <td>
                                                                    <Address>
                                                                        {{order.address.address_1}}, {{order.address.address_2}}, {{order.address.location}}, 
                                                                        <p class="m-auto">City: {{order.address.city}}</p>
                                                                        <p class="m-auto">Zip: {{order.address.pin}}</p>
                                                                        <p class="m-auto">State: {{order.address.state}}</p>
                                                                        <p class="m-auto">Country: {{order.address.country}}</p>
                                                                    </Address>
                                                                </td>
                                                                <td>
                                                                    <div ngbDropdown class="d-inline-block">
                                                                        <button class="btn p-0" ngbDropdownToggle>
                                                                            <div class="btn btn-sm rounded-circle mr-2" [style.background-color]="order.bgColor"></div>{{order.status}}
                                                                        </button>
                                                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                                                            <button ngbDropdownItem (click)="statusUpdate(order,'confirm')">
                                                                                <div class="btn btn-sm rounded-circle mr-2" style="background-color: green;"></div>Confirm
                                                                            </button>
                                                                            <button ngbDropdownItem (click)="statusUpdate(order,'pending')">
                                                                                <div class="btn btn-sm rounded-circle mr-2" style="background-color: blue;"></div>
                                                                                Pending</button>
                                                                            <button ngbDropdownItem (click)="statusUpdate(order,'delivered')">
                                                                                <div class="btn btn-sm rounded-circle mr-2" style="background-color: yellow;"></div>Delivered
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>{{order.createdAt | date: 'short'}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </form>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-outline-dark"
                                                (click)="modal.close('Save click')">Cancel</button>
                                            <button type="button" class="btn btn-outline-dark"
                                                (click)="modal.close('Save click')">Save & Add Another</button>
                                            <button type="button" class="btn btn-outline-dark"
                                                (click)="modal.close('Save click')">Save & Exit</button>
                                        </div>
                                    </ng-template>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>Custom Views</a>
                <ng-template ngbNavContent>Second content</ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>

        <p class="text-right font-weight-bold text-secondary">Total: {{customers.length}}</p>
        <div class="d-flex justify-content-between">
            <button class="btn btn-success" *ngIf="customerGroupList.length && id2 !== ' '" (click)="open(add_group)">Add Customer</button>
            <ng-template #add_group let-modal>
                <div class="modal-header border-0">
                    <button type="button" class="close" aria-label="Close"
                        (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <table *ngIf="customerGroupList.length" class="table table-striped table-hover table-bordered my-4">
                            <thead>
                                <tr>
                                    <th scope="col">Sl.no</th>
                                    <th>
                                        <input type="checkbox" [name]="customerGroup.customer_group_name">
                                    </th>
                                    <th scope="col">Customer Group Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let customerGroup of customerGroupList; index as i">
                                    <th scope="row">{{i+1}}</th>
                                    <th scope="col-1">
                                        <input type="checkbox" [name]="customerGroup.customer_group_name" [(ngModel)]="customerGroup.status">
                                    </th>
                                    <td>{{customerGroup.customer_group_name}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <ngb-alert *ngIf="response.status" [type]="response.status" (closed)="close()">{{ response.message }}</ngb-alert>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-dark"
                        (click)="_handleCustomerFormGroupAdd()">Save</button>
                    <button type="button" class="btn btn-outline-dark"
                        (click)="modal.close('Save click')">Cancel</button>
                </div>
            </ng-template>
            <button *ngIf="id3 !== '0'" class="btn btn-danger" (click)="_handleCustomerFormGroupDelete()">Delete Customer</button>
        </div>
    </div>