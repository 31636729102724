import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageDesignService {

  private activeTab = new Subject<number>();

  activeTab$ = this.activeTab.asObservable();

  activeTabShare(active:number) {
    this.activeTab.next(active);
  }

  private activeTabParent = new Subject<number>();

  activeTabParent$ = this.activeTab.asObservable();

  activeTabParentShare(active:number) {
    this.activeTabParent.next(active);
  }

  url='http://localhost:5000/v1.0/api/'

  get(endPoint:string){
    return this.http.get(this.url+endPoint);
  }

  post(endPoint:string,user){
    // let body = {"select_country": user.country, "catagory_name": user.catagoryName, "gst": user.gstTax}
    return this.http.post<any>(this.url+endPoint,user);
  }
  put(endPoint:string,user){
    return this.http.put<any>(this.url+endPoint,user);
  }
  delete(endPoint:string,user){
    return this.http.delete<any>(this.url+endPoint,user);
  }

  constructor(private http:HttpClient) { }
}
