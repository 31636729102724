<div class="container-fluid">
    <div class="d-flex">
        <label class="switch">
            <input type="checkbox" name="gst_avaliable" [(ngModel)]="gst_avaliable">
            <span class="slider"></span>
        </label>
        <div class="mx-2">GST/Tax avaliable or not</div>
    </div>
    <form *ngIf="gst_avaliable">
        <div class="form-group">
            <label>Country</label>
            <select class="form-control" name="country_id" [(ngModel)]="gst.country_id"
                (ngModelChange)="selectCountry()">
                <option [value]="country._id" *ngFor="let country of countries">{{country.selected_country_name}}
                </option>
            </select>
        </div>
        <div class="form-row">
            <div class="form-group col">
                <label>HSN Code</label>
                <input type="text" class="form-control" name="store_hsn_code" [(ngModel)]="gst.store_hsn_code">
            </div>
            <div class="form-group col">
                <label>CGST Rate (%)</label>
                <input type="text" class="form-control" name="store_cgst_rate" [(ngModel)]="gst.store_cgst_rate"
                    (ngModelChange)="store_igst_rate()">
            </div>
            <div class="form-group col">
                <label>SGST / UTGST Rate (%)</label>
                <input type="text" class="form-control" name="store_sgst_or_utgst_rate"
                    [(ngModel)]="gst.store_sgst_or_utgst_rate" (ngModelChange)="store_igst_rate()">
            </div>
            <div class="form-group col">
                <label>IGST Rate (%)</label>
                <input type="text" class="form-control" name="store_igst_rate" [(ngModel)]="gst.store_igst_rate"
                    readonly>
            </div>
        </div>
        <div class="form-group">
            <label>Description of Goods</label>
            <input type="text" class="form-control" name="store_description_of_goods"
                [(ngModel)]="gst.store_description_of_goods">
        </div>
        <div class="row form-group">
            <!-- price inculed gst -->
            <div class="col-auto">
                <label class="custom mb-0">Price Inculed with gst
                    <input type="radio" name="price_included_gst" value="true"
                        [(ngModel)]="gst.store_included_gst">
                    <span class="checkmark"></span>
                </label>
            </div>

            <div class="col-auto">
                <label class="custom mb-0">Price Excluded with gst
                    <input type="radio" name="store_included_gst" value="false"
                        [(ngModel)]="gst.store_included_gst">
                    <span class="checkmark"></span>
                </label>
            </div>

            <div class="col">
                <div class="form-group productInfo">
                    <label class="title">*Text To Shown For Gst</label>
                    <input type="text" class="form-control" [(ngModel)]="gst.store_gst_text"
                        name="store_gst_text">
                </div>
            </div>
        </div>
        <div class="text-center">
            <button type="submit" class="btn btn-success" (click)="_handelSubmit()">Submit</button>
        </div>
    </form>
</div>

<section class="my-3 bg-white">
    <table class="table table-bordered">
        <thead>
            <tr>
                <th scope="col">Sl no</th>
                <th scope="col">Country Name</th>
                <th scope="col">Store hsn Code</th>
                <th scope="col">Store cgst rate</th>
                <th scope="col">Store sgst or utgst rate</th>
                <th scope="col">Store igst rate</th>
                <th scope="col">Price Inculed Gst</th>
                <th scope="col">Gst Text</th>
                <th scope="col">Store Description of Goods</th>
                <th colspan="2">Action</th>
                <th scope="col">Status</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let gstper of gstList; index as i">
                <th scope="row">{{i+1}}</th>
                <td>{{gstper.country_name}}</td>
                <td>{{gstper.store_hsn_code}}</td>
                <td>{{gstper.store_cgst_rate}}</td>
                <td>{{gstper.store_sgst_or_utgst_rate}}</td>
                <td>{{gstper.store_igst_rate}}</td>
                <td>{{gstper.store_included_gst}}</td>
                <td>{{gstper.store_gst_text}}</td>
                <td>{{gstper.store_description_of_goods}}</td>
                <td>
                    <button class="btn p-0 text-success" (click)="edit(gstper,i)">
                        <i class="fas fa-edit"></i>
                    </button>
                </td>
                <td>
                    <button class="btn text-danger" (click)="delete(gstper)">
                        <i class="fas fa-trash-alt"></i>
                    </button>
                </td>
                <td>
                    <button class="btn p-0" [class.text-success]="gstper.status" [class.text-secondary]="!gstper.status" (click)="view(gstper)">
                        <i *ngIf="gstper.status" class="fas fa-eye"></i>
                        <i *ngIf="!gstper.status" class="fas fa-eye-slash"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</section>