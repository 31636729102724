export class Gst {
    constructor(
        public _id:string,
        public country_id: string,
        public country_code: string,
        public country_name: string,
        public store_hsn_code: string,
        public store_description_of_goods: string,
        public store_cgst_rate: any,
        public store_sgst_or_utgst_rate: any,
        public store_igst_rate: any,
        public store_included_gst: any,
        public store_gst_text: string
    ){}
}
