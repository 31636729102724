import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import {SocialType} from './social-type';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.css']
})
export class SocialComponent implements OnInit {

    socialList = []
  
    sociaInfo = new SocialType('','','','');
    faIcon = "fa-facebook-official";
    
    countries = [];

    edit = false;

    icons;

    edit_id;
  
    delete(value){
      // this.socialList.splice(this.socialList.indexOf(value),1);
      let body = {
        "_id": value._id
      }
      
      console.log(JSON.stringify(body))
      this.api.post(`delete_social_media`,body).subscribe(
        data=> {
          console.log('!success',data);
          this.edit = false;
          this.sociaInfo.img='';
          this.api.get(`get_all_social_media_list`).subscribe(
            data =>{
              this.socialList = Object.values(data)[2]
              console.log(this.socialList)
            }, 
            error => console.log('Error!', error)
            )
          },
          error=> console.log(error)
      )
      
    }


    chooseIcon(val){
      this.sociaInfo.img = val.icon;
    }
    
    onUpdate(form:NgForm){
      let country = form.value.country.split('-');
      let body = {
        "_id" : this.edit_id,
        "social_media_name": form.value.socialName,
        "country_id": country[0],
        "social_media_country_name": country[1],
        "social_media_icon":this.sociaInfo.img,
        "social_media_link": form.value.link,
      }
      
      this.api.put(`update_social_media`,body).subscribe(
        data=> {
          console.log('!success',data);
          this.edit = false;
          this.sociaInfo.img='';
          this.api.get(`get_all_social_media_list`).subscribe(
            data =>{
              this.socialList = Object.values(data)[2]
              console.log(this.socialList)
            }, 
            error => console.log('Error!', error)
            )
          },
          error=> console.log(error)
      )
      //   console.log(form.value);
        form.reset();
    }
    update(value){
      this.edit = true;
      this.sociaInfo.img = value.social_media_icon;
      this.edit_id = value._id;
      this.sociaInfo.country = `${value.country_id}-${value.social_media_country_name}`;
      this.sociaInfo.link = value.social_media_link;
      this.sociaInfo.socialName = value.social_media_name;
      console.log(this.sociaInfo)
      this.socialList.splice(this.socialList.indexOf(value),1);
    }
  
    
      // bannerMenu;
    
      // getData(){
      //   this.api.bannerSource$.subscribe(
      //     message => {
      //       // console.log(message)
      //       this.bannerMenu = message;
      //     }
      //   )
      // }
    
      onSubmit(form:NgForm){
        let country = form.value.country.split('-');
        let body = {
          "social_media_name": form.value.socialName,
          "country_id": country[0],
          "social_media_country_name": country[1],
          "social_media_icon":this.sociaInfo.img,
          "social_media_link": form.value.link,
        }
        
          this.api.post(`add_social_media`,body).subscribe(
          data=> {
            console.log('!success',data);
            this.api.get(`get_all_social_media_list`).subscribe(
              data =>{
                this.socialList = Object.values(data)[2]
                console.log(this.socialList)
                this.sociaInfo.img = '';
              }, 
              error => console.log('Error!', error)
            )
          },
          error=> console.log(error)
        )
        //   console.log(form.value);
          this.socialList.push(form.value)
          form.reset();
      }
      
      openVerticallyCentered(content) {
        this.modalService.open(content, { centered: true });
      }
    
    
      constructor(private api:ApiService,private modalService: NgbModal) { 
      }
      ngOnInit(): void {
        this.api.get(`selected_country_list`).subscribe(
          data =>{
            let country = Object.values(data);
            this.countries = Object.values(country[2]);
            // this.api.countryShare(this.countries);
            console.log(this.countries)
          }, 
          error => console.log('Error!', error)
        )
        this.api.get(`get_all_social_media_list`).subscribe(
          data =>{
            this.socialList = Object.values(data)[2]
            console.log(this.socialList)
          }, 
          error => console.log('Error!', error)
        )
        this.api.get(`all_json_social_media_icon_list`).subscribe(
          data =>{
            this.icons = Object.values(data)[2]
            console.log(this.icons)
          }, 
          error => console.log('Error!', error)
        )
      }

      // http://34.121.17.227/v1.0/api/get_all_social_media_list
      //   this.api.countrySource$.subscribe(
      //     message => {
      //       this.countries = message;
      //     }
      //   )

}
