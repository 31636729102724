import { Component, OnInit } from '@angular/core';
import { Inventory } from './inventory';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit {

  inventory = new Inventory("Don't do anything","Don't do anything","When an order is successfully placed",
  false,false,"Don't show stock level","Don't do anything",false,'','')

  constructor() { }

  ngOnInit(): void {
  }

}
