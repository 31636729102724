import { Component, Input, NgZone, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ApiService } from 'src/app/api.service';
import { YoutubeChannelSettings } from './youtube-channel-settings';

@Component({
  selector: 'app-youtube-channel-settings',
  templateUrl: './youtube-channel-settings.component.html',
  styleUrls: ['./youtube-channel-settings.component.css']
})
export class YoutubeChannelSettingsComponent implements OnInit {

  youtubeChannelSettings = new YoutubeChannelSettings('','','','')

  imgType = true;
  safeSrc: SafeResourceUrl = "https://www.youtube.com/embed/WJ7GqRF9N90";

  channelMenu= [
    {img:'../../../../assets/img/icons/asset-1.png', channelName: 'Parama Fashion India',
    youtubeLink : 'https://moz.com/blog/how-to-build-links-to-your-blog-a-case-study', country:'India', 
    AddVideo:false, videoList: [
      {img:this.safeSrc, caption:'Caption', delete:false},
      {img:this.safeSrc, caption:'Caption', delete:false},
      {img:this.safeSrc, caption:'Caption', delete:false}
    ]},
    {img:'../../../../assets/img/icons/asset-1.png', channelName: 'Parama Fashion India', 
    youtubeLink : 'https://moz.com/blog/how-to-build-links-to-your-blog-a-case-study', country:'India', 
    AddVideo:false,videoList: [
      {img:this.safeSrc, caption:'Caption', delete:false},
      {img:this.safeSrc, caption:'Caption', delete:false},
      {img:this.safeSrc, caption:'Caption', delete:false}
    ]},
    {img:'../../../../assets/img/icons/asset-1.png', channelName: 'Parama Fashion India', 
    youtubeLink : 'https://moz.com/blog/how-to-build-links-to-your-blog-a-case-study',  country:'India', 
    AddVideo:false,videoList: [
      {img:this.safeSrc, caption:'Caption', delete:false},
      {img:this.safeSrc, caption:'Caption', delete:false},
      {img:this.safeSrc, caption:'Caption', delete:false}
    ]}
  ]
  countries: any;

  onSubmit(form:NgForm,img){
    if (img.value.includes(".png") || img.value.includes(".jpg")) {
      console.log(form.value);
      this.channelMenu.push(form.value);
      form.reset();
    }
  }

  submit(form:NgForm,i){
    console.log(form.value);
    this.channelMenu[i].videoList.push(form.value);
  }

  @Input()
  responses: Array<any>;

  private hasBaseDropZoneOver: boolean = false;
  private title: string;

  // upload(event){
    // if (event.value.includes(".png") || event.value.includes(".jpg")) {
    //   this.imgType = true;
    // } else {
    //   this.imgType = false;
    // }
    // let value = event.target.files[0];
    // const fd = new FormData();
    // fd.append('image',value,value.name)
    // console.log(event.target.files)
    // this.imgUpload.postImages(value).subscribe(
      // response => console.log(response),
      // error => console.log(error)
    // )
  // }


  AddVideo(value){
    this.channelMenu[value].AddVideo = true;
  }

  close(value){
    this.channelMenu[value].AddVideo = false;
  }

  constructor(private zone: NgZone,private sanitizer: DomSanitizer, private api:ApiService ) {
      this.responses = [];
    this.title = '';
      this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/6IRazDAS0QI?rel=0&enablejsapi=1")
   }

  ngOnInit(): void {
    this.api.get(`selected_country_list`).subscribe(
      data =>{
        this.countries = Object.values(data)[2];
        console.log(this.countries)
      }, 
      error => console.log('Error!', error)
    )
  }

}
