<div>
    <div class="container-fluid">
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeIdForParent" class="nav-tabs">
            <li [ngbNavItem]="1">
                <a ngbNavLink>DESKTOP</a>
                <ng-template ngbNavContent>
                    <div class="laptop">
                        <div class="content">
                            <!-- <iframe src="/w3css/tryw3css_templates_band.htm" style="width:100%;border:none;height:100%" /> -->
                        </div>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="2">
                <a ngbNavLink>MOBLIE OR APP</a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="position-absolute d-flex flex-column" style="z-index: 110;">
                            <div ngbDropdown class="d-inline-block">
                                <button class="btn d-flex justify-content-between" type="button" ngbDropdownToggle>
                                    <span>{{country_for_page}}</span>
                                </button>
                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="width: auto;">
                                    <div class="modal-body pt-0" *ngIf="countries.length > 0">
                                        <div class="input-group p-2 w-100">
                                            <div class="input-group-prepend" style="width: 15%;">
                                                <span
                                                    class="input-group-text border-dark rounded-0 bg-transparent border-right-0">
                                                    <i class="fas fa-search"></i></span>
                                            </div>
                                            <input type="text" class="" ngModel name="searchCatagory" #searchCatagory>
                                        </div>
                                        <div class="">
                                            <ul class="list-group list-group-flush">
                                                <li class="list-group-item border-0" *ngFor="let country of countries">
                                                    <div class="d-flex">
                                                        <label class="custom mb-0">{{country.selected_country_name}}
                                                            <input type="radio" [value]="country.selected_country_name"
                                                                [(ngModel)]="country_for_page"
                                                                class="form-check-input m-2" name="country">
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div ngbDropdown class="d-inline-block page" #myDrop="ngbDropdown">
                                <button class="btn d-flex justify-content-between" type="button" ngbDropdownToggle>
                                    <span>{{pageDesign.page_name}}</span>
                                    <span><i class="fas fa-angle-double-right mx-2"></i></span>
                                </button>
                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                    style="width: 400px; height: 550px; overflow-y: scroll;" class=" bg-secondary">
                                    <ul class="list-group rounded-0">
                                        <li class="list-group-item border-0 bg-secondary">
                                            <div class="d-flex justify-content-between">
                                                <button class="btn font-weight-bold"
                                                    (click)="page_name('Home')">Home</button>
                                                <button class="btn p-0"
                                                    (click)="$event.stopPropagation(); myDrop.close();"><i
                                                        class="fas fa-angle-double-left"></i></button>
                                            </div>
                                            <div class="d-flex justify-content-between">
                                                <button class="btn font-weight-bold"
                                                    (click)="page_name('Shop by Catagory')">Shop by Catagory</button>
                                            </div>
                                            <ul class="list-group rounded-0">
                                                <li class="list-group-item border-0 bg-secondary"
                                                    *ngFor="let catagory of catagories; i as index">
                                                    <div class="d-flex">
                                                        <button class="btn font-weight-bold p-0"
                                                            (click)="page_name(catagory)">
                                                            {{catagory.category_name}}
                                                        </button>
                                                    </div>
                                                    <ul class="list-group rounded-0">
                                                        <li class="list-group-item bg-secondary pr-0 border-0"
                                                            *ngFor="let subCatagory of catagory.main_sub_category">
                                                            <div class="d-flex">
                                                                <button class="btn font-weight-bold p-0"
                                                                    (click)="page_name(subCatagory)">
                                                                    {{subCatagory.category_name}}
                                                                </button>
                                                            </div>
                                                            <ul>
                                                                <li class="list-group-item bg-secondary pr-0 border-0"
                                                                    *ngFor="let subCatagory1 of subCatagory.sub_category">
                                                                    <div class="d-flex">
                                                                        <button class="btn font-weight-bold p-0"
                                                                            (click)="page_name(subCatagory1)">
                                                                            {{subCatagory1.category_name}}
                                                                        </button>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="position-relative" id="notifications-list" style="margin-top: 150px;">
                                <div class="smartphone Flipped" (scroll)="scrollHandler($event)">
                                    <div class="iphone"></div>
                                    <div class="smartphone-container example-list" cdkDropList
                                        (cdkDropListDropped)="drop($event,this.bannerMenu)">
                                        <header>
                                            <div>
                                                <button type="button" class="btn p-0"><i
                                                        class="fas fa-bars"></i></button>
                                                <img [src]="iconLogoList.ilc_logo" alt="logo" width="40px"
                                                    style="margin-left: 20px;">
                                            </div>
                                        </header>
                                        <div cdkDrag *ngFor="let item of bannerMenu; index as i" class="d-flex">
                                            <div class="d-flex justify-content-around width-180">
                                                <div class="example-handle" cdkDragHandle>
                                                    <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                                                        <path
                                                            d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                                                        </path>
                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                    </svg>
                                                </div>
                                                <span *ngIf="item.data.length > 0">{{item.data.length}}</span>
                                                <span
                                                    *ngIf="item.product_data.length > 0">{{item.product_data.length}}</span>
                                                <span>{{item.design_type}}</span>
                                                <i class="fas fa-arrow-right mt-1"></i>
                                            </div>
                                            <div class="content">
                                                <carousel [autoplay]="item.carosel_animation"
                                                    [style.margin-top]="item.margin.margin_top"
                                                    [style.margin-bottom]="item.margin.margin_bottom"
                                                    [style.padding-left]="item.margin.margin_left"
                                                    [style.padding-right]="item.margin.margin_right"
                                                    [autoplayInterval]="item.carosel_animation_time"
                                                    [pauseOnHover]='true'
                                                    [cellsToShow]="(item.grid_type === 'list_view') ? 3 : 1"
                                                    [loop]='item.carosel_animation' [dots]=" !item.carosel_arrows"
                                                    [height]="item.grid_height" [arrows]="item.carosel_arrows">
                                                    <div class="carousel-cell h-100 m-0" *ngFor="let value of item.data"
                                                        style.border-radius={{item.grid_border_radius}}px
                                                        [style.margin-left]="item.margin.margin_left"
                                                        [style.margin-right]="item.margin.margin_right"
                                                        [style.border-style]="item.grid_border_width? 'solid' : 'hidden' "
                                                        [style.border-width]="item.grid_border_width"
                                                        [style.border-color]="item.grid_border_color">
                                                        <img [src]="value.img">
                                                    </div>
                                                </carousel>
                                            </div>
                                            <div class="flex-fill d-flex justify-content-around pl-4 border-danger"
                                                style="width:100px; border-width: 4px !important;"
                                                [class.border-top]="item.edit" [class.border-bottom]="item.edit">
                                                <button class="btn p-0 view my-auto"
                                                    (click)="view({'_id': item._id, 'status': !item.status})"
                                                    [style.color]="item.status? 'green': 'rgb(190, 187, 187)'">
                                                    <i class="fas fa-eye" *ngIf="item.status"></i>
                                                    <i class="fas fa-eye-slash" *ngIf="!item.status"></i>
                                                </button>
                                                <button class="btn p-0 view my-auto" (click)="edit(item,$event)">
                                                    <i class="fas fa-pen"></i>
                                                </button>
                                                <button class="btn p-0 view my-auto" (click)="delete(item)">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form class="col" style="list-style-type: none;" (ngSubmit)="submit(bannerForm)"
                            #bannerForm="ngForm">
                            <ul ngbNav #nav="ngbNav" [(activeId)]="activeId" class="nav-tabs">
                                <li [ngbNavItem]="1">
                                    <a ngbNavLink>Grid Setting</a>
                                    <ng-template ngbNavContent>
                                        <div class="form-group row">
                                            <div class="col-sm-1 mt-2">
                                                <div class="bullet"></div>
                                            </div>
                                            <div class="col-3">
                                                <button type="button" class="btn btn-success" (click)="openXl(content)">
                                                    Select Design
                                                </button>
                                                <ng-template #content let-modal>
                                                    <div class="modal-header border-0">
                                                        <button type="button" class="btn close" aria-label="Close"
                                                            (click)="modal.dismiss('Cross click')">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <ul class="d-flex flex-wrap">
                                                            <li class="list-group-item border-0"
                                                                *ngFor="let grid of grid_types">
                                                                <div class="d-flex">
                                                                    <label class="custom mb-0">
                                                                        <h5>{{grid.name}}</h5>
                                                                        <img [src]="grid.img" height="150px" alt="">
                                                                        <input type="radio" [value]="grid.value"
                                                                            [disabled]="edit_id"
                                                                            [(ngModel)]="pageDesign.design_type"
                                                                            (ngModelChange)="selectGrid(grid.img)"
                                                                            class="form-check-input m-2"
                                                                            name="design_type">
                                                                        <span class="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-outline-dark"
                                                            (click)="modal.close('Save click')">close</button>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-1 mt-2">
                                                <div class="bullet"></div>
                                            </div>
                                            <div class="col-sm-11 ml-auto">
                                                <div class="row form-group">
                                                    <label class="col-form-label">Design Type(required)</label>
                                                    <div class="col-3">
                                                        <div class="form-control" style="overflow: hidden;">
                                                            {{design_type}}</div>
                                                    </div>
                                                    <div class="col"
                                                        *ngIf="pageDesign.design_type !== 'productgridView' && pageDesign.design_type !== 'productlistView' && pageDesign.design_type !== 'Horizontal-product-view'">
                                                        <div class="row">
                                                            <label class="col-form-label">Select Total Grid</label>
                                                            <div class="col-sm-4">
                                                                <input type="number" class="form-control"
                                                                    name="total_number_of_grid"
                                                                    [(ngModel)]="pageDesign.total_number_of_grid"
                                                                    [readonly]="pageDesign.design_type === 'DesignGridView3Item'"
                                                                    (change)="totalGrid(pageDesign.total_number_of_grid,this.pageDesign.data)"
                                                                    min="0">
                                                            </div>
                                                            <button type="button" class="btn btn-danger btn-sm">use
                                                                save
                                                                grid</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row"
                                                    *ngIf="pageDesign.design_type === 'Carosuel'">
                                                    <span class="col-2 form-check">AutoPlay :</span>
                                                    <div class="col-2 form-check">
                                                        <label class="form-check-label text-small">Yes</label>
                                                        <input type="radio" name="animation" value=true
                                                            class="form-check-input mx-2"
                                                            [(ngModel)]="pageDesign.animation">
                                                    </div>
                                                    <div class="col input-group"
                                                        *ngIf="pageDesign.animation === 'true'">
                                                        <input type="text" class="form-control"
                                                            name="carosel_animation_time" (keyup)="valueChange($event)"
                                                            [(ngModel)]="pageDesign.carosel_animation_time">
                                                        <div class="input-group-append">
                                                            <span class="input-group-text">milisecond</span>
                                                        </div>
                                                    </div>
                                                    <div class="col form-check">
                                                        <label class="form-check-label text-small"> No</label>
                                                        <input type="radio" name="animation" value=false
                                                            class="form-check-input mx-2"
                                                            [(ngModel)]="pageDesign.animation">
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="row">
                                                            <label class="col-sm-3 col-form-label">Arrows show</label>
                                                            <div class="form-check form-check-inline"><input
                                                                    type="radio" name="arrows" class="form-check-input"
                                                                    value="true" [(ngModel)]="pageDesign.arrows">
                                                                <label class="form-check-label">Yes</label>
                                                            </div>
                                                            <div class="form-check form-check-inline"><input
                                                                    type="radio" name="arrows" class="form-check-input"
                                                                    value="false" [(ngModel)]="pageDesign.arrows">
                                                                <label class="form-check-label">No</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-sm-1 my-auto">
                                                <div class="bullet"></div>
                                            </div>
                                            <div class="col-sm-11">
                                                <input type="text" class="form-control" name="grid_name"
                                                    placeholder="Write Grid Name" [(ngModel)]="pageDesign.grid_name">
                                            </div>
                                        </div>
                                        <div class="form-group row"
                                            *ngIf="pageDesign.showTitleBar && pageDesign.design_type !== 'Carosuel'">
                                            <div class="col-sm-1 my-auto">
                                                <div class="bullet"></div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label>Container Title</label>
                                                <input type="text" class="form-control" name="container_title_text"
                                                    placeholder="Write Grid Title"
                                                    [(ngModel)]="pageDesign.container_title_design.container_title_text">
                                            </div>
                                            <div class="col">
                                                <label>Title Font Size</label>
                                                <input type="text" class="form-control" name="container_title_fontSize"
                                                    [(ngModel)]="pageDesign.container_title_design.container_title_fontSize">
                                            </div>
                                            <div class="col">
                                                <label class="w-100">Title Color</label>
                                                <input type="color" class="h-50 border-0 bg-white"
                                                    name="container_title_color"
                                                    [(ngModel)]="pageDesign.container_title_design.container_title_color">
                                            </div>
                                        </div>
                                        <div class="form-group row"
                                            *ngIf="pageDesign.showTitleBar && pageDesign.design_type !== 'Carosuel'">
                                            <div class="col-sm-1 my-auto">
                                                <div class="bullet"></div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label>Container Subtitle</label>
                                                <input type="text" class="form-control" name="container_subtitle_text"
                                                    placeholder="Write Grid Subtitle"
                                                    [(ngModel)]="pageDesign.container_subtitle_design.container_subtitle_text">
                                            </div>
                                            <div class="col">
                                                <label>subtitle Font Size</label>
                                                <input type="text" class="form-control"
                                                    name="container_subtitle_fontSize"
                                                    [(ngModel)]="pageDesign.container_subtitle_design.container_subtitle_fontSize">
                                            </div>
                                            <div class="col">
                                                <label class="w-100">subtitle Color</label>
                                                <input type="color" class="h-50 border-0 bg-white"
                                                    name="container_subtitle_color"
                                                    [(ngModel)]="pageDesign.container_subtitle_design.container_subtitle_color">
                                            </div>
                                        </div>
                                        <div
                                            *ngIf="pageDesign.design_type !== 'productgridView' && pageDesign.design_type !== 'productlistView' && pageDesign.design_type !== 'Horizontal-product-view'">
                                            <div class="form-group row"
                                                *ngIf="pageDesign.design_type !== 'Carosuel' && pageDesign.design_type !== 'Design 4' && pageDesign.design_type !== 'textView'">
                                                <div class="col-sm-1">
                                                    <div class="bullet"></div>
                                                </div>
                                                <div class="col-11">
                                                    <div class="row form-group">
                                                        <div class="form-check form-check-inline">Background Type</div>
                                                        <div class="form-check form-check-inline">
                                                            <input type="radio" name="grid_background"
                                                                class="form-check-input" value="bg_color"
                                                                [(ngModel)]="pageDesign.grid_background"><label
                                                                class="form-check-label">Background Color</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">Or</div>
                                                        <div class="form-check form-check-inline">
                                                            <input type="radio" name="grid_background"
                                                                class="form-check-input" value="bg_image"
                                                                [(ngModel)]="pageDesign.grid_background"><label
                                                                class="form-check-label">Background Image</label>
                                                        </div>
                                                    </div>
                                                    <div class="row form-group"
                                                        *ngIf="pageDesign.grid_background === 'bg_color'">
                                                        <label class="col-5">BackGround Color of Container</label>
                                                        <div class="col-sm">
                                                            <input type="color"
                                                                class="bg-white border-0 rounded-0 ng-untouched ng-pristine ng-valid"
                                                                name="grid_background_color"
                                                                placeholder="Write Grid Title"
                                                                [(ngModel)]="pageDesign.grid_background_color">
                                                        </div>
                                                    </div>
                                                    <div class="row form-group"
                                                        *ngIf="pageDesign.grid_background === 'bg_image'">
                                                        <label class="col-5">BackGround Image of Container</label>
                                                        <div class="col-sm">
                                                            <div class="upload-image d-flex flex-column w-100">
                                                                <img *ngIf="pageDesign.grid_background_image !== ''"
                                                                    [src]="pageDesign.grid_background_image" alt="image"
                                                                    height="100%" width="100%">
                                                                <div
                                                                    class="flex-grow-1 d-flex justify-content-center align-items-center">
                                                                    Upload Image<br>
                                                                    234px X 400 PX
                                                                </div>
                                                                <label class="btn btn-success btn-block m-0"
                                                                    style="position: absolute; bottom: 0;">
                                                                    Upload Images <input type="file"
                                                                        (change)="imgUpload($event,pageDesign)"
                                                                        accept="image/x-png,image/jpeg"
                                                                        style="display: none;">
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-1">
                                                    <div class="bullet"></div>
                                                </div>
                                                <div class="col-4">Grid Timing
                                                </div>
                                                <div class="col-7">
                                                    <div class="row form-group">
                                                        <div class="form-check form-check-inline">
                                                            <input type="radio" name="is_valid_date"
                                                                class="form-check-input" value="true"
                                                                [(ngModel)]="pageDesign.is_valid_date"><label
                                                                class="form-check-label">limited</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">Or
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input type="radio" name="is_valid_date"
                                                                class="form-check-input" value="false"
                                                                [(ngModel)]="pageDesign.is_valid_date"><label
                                                                class="form-check-label">Unlimited</label>
                                                        </div>
                                                    </div>
                                                    <div class="row form-group "
                                                        *ngIf="this.pageDesign.is_valid_date === 'true' ">
                                                        <div class="col-6">
                                                            <label>Valid Form</label>
                                                            <input type="date" name="is_valid_from" class="form-control"
                                                                [(ngModel)]="pageDesign.is_valid_from">
                                                        </div>
                                                        <div class="col-6">
                                                            <label>Valid Upto</label>
                                                            <input type="date" name="is_valid_upto" class="form-control"
                                                                [(ngModel)]="pageDesign.is_valid_upto">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row"
                                                *ngIf="pageDesign.design_type !== 'Carosuel' && pageDesign.design_type !== 'textView'">
                                                <div class="col-sm-1">
                                                    <div class="bullet"></div>
                                                </div>
                                                <div class="col-6 d-flex form-group">
                                                    <label style="width: 90%;">Gap Between Items</label>
                                                    <div class="w-50">
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="pageDesign.item_gap" name="item_gap"
                                                            (keyup)="valueChange($event)">
                                                    </div>
                                                    <span style="margin-left: 10px;">px</span>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-sm-1">
                                                            <div class="bullet"></div>
                                                        </div>
                                                        <div class="col-3 form-group">Images Radius</div>
                                                        <div class="col-8 form-group">
                                                            <div class="row">
                                                                <div class="col">
                                                                    <div class="row">
                                                                        <div class="col-12">Top-Left</div>
                                                                        <div class="col-8 pr-1">
                                                                            <input type="text" class="form-control"
                                                                                name="image_border_top_left"
                                                                                (keyup)="valueChange($event)"
                                                                                [(ngModel)]="pageDesign.image_border_radius.image_border_top_left">
                                                                        </div>
                                                                        <div class="col-3 pl-1">px</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="row">
                                                                        <div class="col-12">Top-Right</div>
                                                                        <div class="col-8 pr-1">
                                                                            <input type="text" class="form-control"
                                                                                name="image_border_top_right"
                                                                                (keyup)="valueChange($event)"
                                                                                [(ngModel)]="pageDesign.image_border_radius.image_border_top_right">
                                                                        </div>
                                                                        <div class="col-3 pl-1">px</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="row">
                                                                        <div class="col-12 text-center">bottom-left
                                                                        </div>
                                                                        <div class="col-8 pr-1">
                                                                            <input type="text" class="form-control"
                                                                                name="image_border_bottom_left"
                                                                                (keyup)="valueChange($event)"
                                                                                [(ngModel)]="pageDesign.image_border_radius.image_border_bottom_left">
                                                                        </div>
                                                                        <div class="col-3 pl-1">px</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="row">
                                                                        <div class="col-13 text-center">bottom-Right
                                                                        </div>
                                                                        <div class="col-8 pr-1">
                                                                            <input type="text" class="form-control"
                                                                                name="image_border_bottom_right"
                                                                                (keyup)="valueChange($event)"
                                                                                [(ngModel)]="pageDesign.image_border_radius.image_border_bottom_right">
                                                                        </div>
                                                                        <div class="col-3 pl-1">px</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row"
                                                *ngIf="this.pageDesign.design_type !== 'textView'">
                                                <div class="col-sm-1 mt-2">
                                                    <div class="bullet"></div>
                                                </div>
                                                <div class="col-sm-11">
                                                    <div class="row col-form-label">
                                                        <div class="col-5">
                                                            <div class="form-group row">
                                                                <label
                                                                    class="col-sm-5 col-form-label col-form-label-sm">Grids
                                                                    Height : <span
                                                                        *ngIf="this.pageDesign.design_type === 'Carosuel'">required</span></label>
                                                                <div class="col-6 input-group">
                                                                    <input type="text" class="form-control rounded-0"
                                                                        name="grid_height"
                                                                        [(ngModel)]="pageDesign.grid_height"
                                                                        (keyup)="valueChange($event)">
                                                                    <div class="input-group-append">
                                                                        <span
                                                                            class="input-group-text border-0 bg-white">px</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-5" *ngIf="design_type !== 'grid_view'">
                                                            <div class="form-group row">
                                                                <label
                                                                    class="col-sm-5 col-form-label col-form-label-sm">Grids
                                                                    Width :</label>
                                                                <div class="col-6 input-group">
                                                                    <input type="text" class="form-control rounded-0"
                                                                        name="grid_width"
                                                                        [readonly]="this.pageDesign.design_type === 'Carosuel'"
                                                                        [(ngModel)]="pageDesign.grid_width"
                                                                        (keyup)="valueChange($event)">
                                                                    <div class="input-group-append">
                                                                        <span
                                                                            *ngIf="this.pageDesign.design_type === 'Carosuel' && this.pageDesign.design_type === 'Banner'"
                                                                            class="input-group-text border-0 bg-white">%</span>
                                                                        <span
                                                                            *ngIf="this.pageDesign.design_type !== 'Carosuel' && this.pageDesign.design_type !== 'Banner'"
                                                                            class="input-group-text border-0 bg-white">px</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col" *ngIf="design_type === 'grid_view'">
                                                            <div class="form-group row">
                                                                <label class="col col-form-label col-form-label-sm">
                                                                    Grid count to show on a single Line
                                                                </label>
                                                                <div class="col-4 input-group">
                                                                    <select class="form-control rounded-0"
                                                                        name="cross_axis_count"
                                                                        [(ngModel)]="pageDesign.cross_axis_count">
                                                                        <option [value]="col"
                                                                            *ngFor="let col of colcount">{{col}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 text-small"
                                                            *ngIf="pageDesign.is_height_fixed === 'true' && pageDesign.design_type !== 'Carosuel'">
                                                            If No - Grid Height Depend on image Height
                                                        </div>
                                                        <div class="col-12 text-small"
                                                            *ngIf="pageDesign.is_height_fixed === 'false'">
                                                            Grid Height Depend on image Height
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-1 mt-2">
                                                    <div class="bullet"></div>
                                                </div>
                                                <div class="col-sm-11">
                                                    <div class="row">
                                                        <div class="col-3 form-group">Outside Grid Margin:</div>
                                                        <div class="col-9 form-group">
                                                            <div class="row">
                                                                <div class="col">
                                                                    <div class="row">
                                                                        <div class="col-8 text-center">Top</div>
                                                                        <div class="col-8 pr-1">
                                                                            <input type="text" class="form-control"
                                                                                name="margin_top"
                                                                                (keyup)="valueChange($event)"
                                                                                [(ngModel)]="pageDesign.margin.margin_top">
                                                                        </div>
                                                                        <div class="col-3 pl-1">px</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="row">
                                                                        <div class="col-8 text-center">Bottom</div>
                                                                        <div class="col-8 pr-1">
                                                                            <input type="text" class="form-control"
                                                                                name="margin_bottom"
                                                                                (keyup)="valueChange($event)"
                                                                                [(ngModel)]="pageDesign.margin.margin_bottom">
                                                                        </div>
                                                                        <div class="col-3 pl-1">px</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="row">
                                                                        <div class="col-8 text-center">Left</div>
                                                                        <div class="col-8 pr-1">
                                                                            <input type="text" class="form-control"
                                                                                name="margin_left"
                                                                                (keyup)="valueChange($event)"
                                                                                [(ngModel)]="pageDesign.margin.margin_left">
                                                                        </div>
                                                                        <div class="col-3 pl-1">px</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="row">
                                                                        <div class="col-8 text-center">Right</div>
                                                                        <div class="col-8 pr-1">
                                                                            <input type="text" class="form-control"
                                                                                name="margin_right"
                                                                                (keyup)="valueChange($event)"
                                                                                [(ngModel)]="pageDesign.margin.margin_right">
                                                                        </div>
                                                                        <div class="col-3 pl-1">px</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="pageDesign.design_type !== 'Carosuel'">
                                                <div class="col-sm-1 mt-2">
                                                    <div class="bullet"></div>
                                                </div>
                                                <div class="col-sm-11">
                                                    <div class="row">
                                                        <div class="col-3 form-group">Padding:</div>
                                                        <div class="col-9 form-group">
                                                            <div class="row">
                                                                <div class="col">
                                                                    <div class="row">
                                                                        <div class="col-8 text-center">Top</div>
                                                                        <div class="col-8 pr-1">
                                                                            <input type="text" class="form-control"
                                                                                name="padding_top"
                                                                                (keyup)="valueChange($event)"
                                                                                [(ngModel)]="pageDesign.padding.padding_top">
                                                                        </div>
                                                                        <div class="col-3 pl-1">px</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="row">
                                                                        <div class="col-8 text-center">Bottom</div>
                                                                        <div class="col-8 pr-1">
                                                                            <input type="text" class="form-control"
                                                                                name="padding_bottom"
                                                                                (keyup)="valueChange($event)"
                                                                                [(ngModel)]="pageDesign.padding.padding_bottom">
                                                                        </div>
                                                                        <div class="col-3 pl-1">px</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="row">
                                                                        <div class="col-8 text-center">Left</div>
                                                                        <div class="col-8 pr-1">
                                                                            <input type="text" class="form-control"
                                                                                name="padding_left"
                                                                                (keyup)="valueChange($event)"
                                                                                [(ngModel)]="pageDesign.padding.padding_left">
                                                                        </div>
                                                                        <div class="col-3 pl-1">px</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="row">
                                                                        <div class="col-8 text-center">Right</div>
                                                                        <div class="col-8 pr-1">
                                                                            <input type="text" class="form-control"
                                                                                name="padding_right"
                                                                                (keyup)="valueChange($event)"
                                                                                [(ngModel)]="pageDesign.padding.padding_right">
                                                                        </div>
                                                                        <div class="col-3 pl-1">px</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row col-form-label"
                                                *ngIf="pageDesign.design_type !== 'Carosuel'">
                                                <div class="col-sm-1 mt-1">
                                                    <div class="bullet"></div>
                                                </div>
                                                <div class="col">
                                                    <label for="customControlAutosizing">Grids have any text
                                                        area:</label>
                                                    <input type="checkbox" name="is_grid_have_text_area"
                                                        [disabled]="this.pageDesign.design_type === 'textView'"
                                                        [(ngModel)]="pageDesign.is_grid_have_text_area" class="m-2">
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="pageDesign.is_grid_have_text_area">
                                                <div class="col-sm-1 mt-2">
                                                </div>
                                                <div class="col-sm-11">
                                                    <div class="row col-form-label">
                                                        <div class="col-4">Grid Text Area Location</div>
                                                        <div class="col">
                                                            <div class="row">
                                                                <div class="form-check form-check-inline">
                                                                    <input type="radio" name="grid_text_area_location"
                                                                        value='top' class="form-check-input"
                                                                        (keyup)="valueChange($event)"
                                                                        [(ngModel)]="pageDesign.grid_text_area_location">
                                                                    <label class="form-check-label">Top</label>
                                                                </div>
                                                                <div class="form-check form-check-inline">
                                                                    <input type="radio" name="grid_text_area_location"
                                                                        value='bottom' class="form-check-input"
                                                                        (keyup)="valueChange($event)"
                                                                        [(ngModel)]="pageDesign.grid_text_area_location">
                                                                    <label class="form-check-label">Bottom</label>
                                                                </div>
                                                                <div class="form-check form-check-inline">
                                                                    <input type="radio" name="grid_text_area_location"
                                                                        value='overlay' class="form-check-input"
                                                                        [(ngModel)]="pageDesign.grid_text_area_location">
                                                                    <label class="form-check-label">gird text overlay on
                                                                        image</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-6 input-group form-group">
                                                            <label
                                                                class="col-form-label col-form-label-sm mr-2">Background
                                                                Color</label>
                                                            <input type="text" class="form-control" name="bg_color"
                                                                [(ngModel)]="pageDesign.bg_color">
                                                            <input type="color" class="bg-white border-0 rounded-0"
                                                                name="bg_color" [(ngModel)]="pageDesign.bg_color">
                                                        </div>
                                                        <div class="col-4 input-group form-group">
                                                            <label class="col-form-label col-form-label-sm mr-2">Text
                                                                Align</label>
                                                            <select class="form-control rounded-0" name="text_align"
                                                                [(ngModel)]="pageDesign.text_align">
                                                                <option value="left">left</option>
                                                                <option value="right">right</option>
                                                                <option value="center">center</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-4 input-group form-group"
                                                            *ngIf="pageDesign.grid_text_area_location === 'overlay' ">
                                                            <label
                                                                class="col-form-label col-form-label-sm mr-2">Opacity</label>
                                                            <select type="text" class="form-control rounded-0"
                                                                name="opacity" [(ngModel)]="pageDesign.opacity">
                                                                <option value="0">0</option>
                                                                <option value="0.1">0.1</option>
                                                                <option value="0.2">0.2</option>
                                                                <option value="0.3">0.3</option>
                                                                <option value="0.4">0.4</option>
                                                                <option value="0.5">0.5</option>
                                                                <option value="0.6">0.6</option>
                                                                <option value="0.7">0.7</option>
                                                                <option value="0.8">0.8</option>
                                                                <option value="0.9">0.9</option>
                                                                <option value="1">1</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-sm-1">
                                                            <div class="bullet"></div>
                                                        </div>
                                                        <div class="col-3 form-group">Textarea Radius</div>
                                                        <div class="col-8 form-group">
                                                            <div class="row">
                                                                <div class="col">
                                                                    <div class="row">
                                                                        <div class="col-12">Top-Left</div>
                                                                        <div class="col-8 pr-1">
                                                                            <input type="text" class="form-control"
                                                                                name="text_area_border_top_left"
                                                                                (keyup)="valueChange($event)"
                                                                                [(ngModel)]="pageDesign.text_area_border_radius.text_area_border_top_left">
                                                                        </div>
                                                                        <div class="col-3 pl-1">px</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="row">
                                                                        <div class="col-12">Top-Right</div>
                                                                        <div class="col-8 pr-1">
                                                                            <input type="text" class="form-control"
                                                                                name="text_area_border_top_right"
                                                                                (keyup)="valueChange($event)"
                                                                                [(ngModel)]="pageDesign.text_area_border_radius.text_area_border_top_right">
                                                                        </div>
                                                                        <div class="col-3 pl-1">px</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="row">
                                                                        <div class="col-12 text-center">bottom-left
                                                                        </div>
                                                                        <div class="col-8 pr-1">
                                                                            <input type="text" class="form-control"
                                                                                name="text_area_border_bottom_left"
                                                                                (keyup)="valueChange($event)"
                                                                                [(ngModel)]="pageDesign.text_area_border_radius.text_area_border_bottom_left">
                                                                        </div>
                                                                        <div class="col-3 pl-1">px</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="row">
                                                                        <div class="col-13 text-center">bottom-Right
                                                                        </div>
                                                                        <div class="col-8 pr-1">
                                                                            <input type="text" class="form-control"
                                                                                name="text_area_border_bottom_right"
                                                                                (keyup)="valueChange($event)"
                                                                                [(ngModel)]="pageDesign.text_area_border_radius.text_area_border_bottom_right">
                                                                        </div>
                                                                        <div class="col-3 pl-1">px</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-1">
                                                    <div class="bullet"></div>
                                                </div>
                                                <div class="col-6 d-flex form-group">
                                                    <label style="width: 90%;">Border Radius of Grid</label>
                                                    <div class="w-50">
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="pageDesign.grid_border_radius"
                                                            name="grid_border_radius" (keyup)="valueChange($event)">
                                                    </div>
                                                    <span style="margin: 0 10px;">px</span>
                                                </div>
                                                <div class="col-12 ml-auto">
                                                    <div class="row">
                                                        <div class="col-sm-1 my-auto">
                                                            <div class="bullet"></div>
                                                        </div>
                                                        <div class="col-4">
                                                            <label>Grid have any border:</label>
                                                            <input type="checkbox" name="is_grid_border" class="m-2"
                                                                name="is_grid_border"
                                                                [(ngModel)]="pageDesign.is_grid_border">
                                                        </div>
                                                        <div class="col d-flex" *ngIf="pageDesign.is_grid_border">
                                                            <label style="width: 90%;">Border Width</label>
                                                            <div class="w-50">
                                                                <input type="text" class="form-control"
                                                                    [(ngModel)]="pageDesign.grid_border_width"
                                                                    name="grid_border_width"
                                                                    (keyup)="valueChange($event)">
                                                            </div>
                                                            <span style="margin: 0 10px;">px</span>
                                                        </div>
                                                        <div class="col-3" *ngIf="pageDesign.is_grid_border">
                                                            <div class="row">
                                                                <label class="col-9">Border Color</label>
                                                                <input type="color" name="grid_border_color"
                                                                    style="width: 36px;"
                                                                    class="bg-white border-0 rounded-0 h-auto"
                                                                    (change)="valueChange($event)"
                                                                    [(ngModel)]="pageDesign.grid_border_color">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row"
                                                *ngIf="pageDesign.design_type !== 'Carosuel' && pageDesign.design_type !=='textView'">
                                                <div class="col-sm-1">
                                                    <div class="bullet"></div>
                                                </div>
                                                <div class="col-auto">
                                                    Making Of <span class="text-danger">View All</span> Button:
                                                </div>
                                                <div class="col-3">
                                                    <label>Show Or Hide</label>
                                                    <input type="checkbox" name="showOptionButton" class="m-2"
                                                        name="showOptionButton"
                                                        [(ngModel)]="pageDesign.showOptionButton">
                                                </div>
                                                <div class="col d-flex form-group" *ngIf="pageDesign.showOptionButton">
                                                    <label style="width: 20%;">Title</label>
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="pageDesign.option_button.title" name="title"
                                                        (keyup)="valueChange($event)">
                                                </div>
                                                <div class="col-11 ml-auto" *ngIf="pageDesign.showOptionButton">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="row form-group">
                                                                <label class="col-2">See All Link</label>
                                                                <div ngbDropdown class="d-inline-block col">
                                                                    <button
                                                                        class="btn-outline-green form-control w-100 d-flex justify-content-between"
                                                                        type="button" ngbDropdownToggle>
                                                                        <span>{{pageDesign.option_button.applied_to}}</span>
                                                                    </button>
                                                                    <div ngbDropdownMenu
                                                                        aria-labelledby="dropdownBasic1"
                                                                        style="width: 100%;">
                                                                        <ul class="list-group list-group-flush">
                                                                            <li class="list-group-item border-0">
                                                                                <button class="btn p-0" type="button"
                                                                                    (click)="open(feturecontent)">Feture</button>
                                                                                <ng-template #feturecontent let-modal>
                                                                                    <div class="modal-header border-0">
                                                                                        <div class="bg-success p-2 text-center w-100"
                                                                                            *ngIf="catagories.length > 0">
                                                                                            <a routerLink="/feture-product-setting"
                                                                                                class="text-white"
                                                                                                routerLinkActive="active-link"
                                                                                                target="_blank"> Create
                                                                                                new
                                                                                                Feture</a>
                                                                                        </div>
                                                                                        <button type="button"
                                                                                            class="close"
                                                                                            aria-label="Close"
                                                                                            (click)="modal.dismiss('Cross click')">
                                                                                            <span
                                                                                                aria-hidden="true">&times;</span>
                                                                                        </button>
                                                                                    </div>
                                                                                    <div class="modal-body pt-0"
                                                                                        *ngIf="fetures.length > 0 || fetureMenu.length > 0">
                                                                                        <div
                                                                                            class="input-group p-2 w-100">
                                                                                            <div class="input-group-prepend"
                                                                                                style="width: 9%;">
                                                                                                <span
                                                                                                    class="input-group-text border-dark rounded-0 bg-transparent border-right-0">
                                                                                                    <i
                                                                                                        class="fas fa-search"></i></span>
                                                                                            </div>
                                                                                            <input type="text" class=""
                                                                                                ngModel
                                                                                                name="searchCatagory"
                                                                                                #searchCatagory>
                                                                                        </div>
                                                                                        <div class="">
                                                                                            <ul
                                                                                                class="list-group list-group-flush">
                                                                                                <li class="list-group-item border-0"
                                                                                                    *ngFor="let feture of fetures">
                                                                                                    <div class="d-flex">
                                                                                                        <label
                                                                                                            class="custom mb-0">
                                                                                                            Home <i
                                                                                                                class="fas fa-angle-double-right mx-2"></i>
                                                                                                            {{feture.featured_product_tag}}
                                                                                                            <input
                                                                                                                type="radio"
                                                                                                                [value]="feture._id"
                                                                                                                [(ngModel)]="pageDesign.option_button.applied_to_id"
                                                                                                                (click)="applied_to(pageDesign.option_button,'feture',feture.featured_product_tag)"
                                                                                                                class="form-check-input m-2"
                                                                                                                name="pageDesign.option_button.applied_to_id">
                                                                                                            <span
                                                                                                                class="checkmark"></span>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li class="list-group-item border-0"
                                                                                                    *ngFor="let feture of fetureMenu">
                                                                                                    <div class="d-flex">
                                                                                                        <label
                                                                                                            class="custom mb-0">
                                                                                                            {{feture.featured_product_category_category_name}}
                                                                                                            <i
                                                                                                                class="fas fa-angle-double-right mx-2"></i>
                                                                                                            {{feture.featured_product_category_tag}}
                                                                                                            <input
                                                                                                                type="radio"
                                                                                                                [value]="feture._id"
                                                                                                                [(ngModel)]="pageDesign.option_button.applied_to_id"
                                                                                                                (click)="applied_to(pageDesign.option_button,'feture',feture.featured_product_category_tag)"
                                                                                                                class="form-check-input m-2"
                                                                                                                name="pageDesign.option_button.applied_to_id">
                                                                                                            <span
                                                                                                                class="checkmark"></span>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </ng-template>
                                                                            </li>
                                                                            <li class="list-group-item border-0">
                                                                                <button class="btn p-0" type="button"
                                                                                    (click)="open(content)">catagory</button>
                                                                                <ng-template #content let-modal>
                                                                                    <div class="modal-header border-0">
                                                                                        <div class="p-2 w-100"
                                                                                            *ngIf="0 >= catagories.length">
                                                                                            first select a country and
                                                                                            then select catagory
                                                                                        </div>
                                                                                        <div class="bg-success p-2 text-center w-100"
                                                                                            *ngIf="catagories.length > 0">
                                                                                            <a routerLink="/create-new-catagory"
                                                                                                class="text-white"
                                                                                                routerLinkActive="active-link"
                                                                                                target="_blank">
                                                                                                Create new
                                                                                                catagory</a>
                                                                                        </div>
                                                                                        <button type="button"
                                                                                            class="close"
                                                                                            aria-label="Close"
                                                                                            (click)="modal.dismiss('Cross click')">
                                                                                            <span
                                                                                                aria-hidden="true">&times;</span>
                                                                                        </button>
                                                                                    </div>
                                                                                    <div class="modal-body pt-0"
                                                                                        *ngIf="catagories.length > 0">
                                                                                        <div class="">
                                                                                            <ul
                                                                                                class="list-group list-group-flush">
                                                                                                <li class="list-group-item border-0"
                                                                                                    *ngFor="let main_cat of catagories; index as i">
                                                                                                    <div class="d-flex">
                                                                                                        <label
                                                                                                            class="custom mb-0">{{main_cat.category_name}}
                                                                                                            <input
                                                                                                                type="radio"
                                                                                                                [value]="main_cat._id"
                                                                                                                (click)="applied_to(pageDesign.option_button,'category',main_cat.category_name)"
                                                                                                                [(ngModel)]="pageDesign.option_button.applied_to_id"
                                                                                                                class="form-check-input m-2"
                                                                                                                name="applied_to_id">
                                                                                                            <span
                                                                                                                class="checkmark"></span>
                                                                                                        </label>
                                                                                                        <button
                                                                                                            class="btn p-0 ml-4"
                                                                                                            type="button"
                                                                                                            *ngIf="main_cat.main_sub_category"
                                                                                                            (click)="Showsubcat(main_cat)">
                                                                                                            <i
                                                                                                                [class]="!main_cat.click? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                    <ul class="list-group list-group-flush ml-4"
                                                                                                        *ngIf="main_cat.click">
                                                                                                        <li class="list-group-item border-0"
                                                                                                            *ngFor="let val of main_cat.main_sub_category; index as j">
                                                                                                            <div
                                                                                                                class="d-flex">
                                                                                                                <label
                                                                                                                    class="custom mb-0">{{val.category_name}}
                                                                                                                    <input
                                                                                                                        type="radio"
                                                                                                                        [value]="val._id"
                                                                                                                        (click)="applied_to(pageDesign.option_button,'category',val.category_name)"
                                                                                                                        [(ngModel)]="pageDesign.option_button.applied_to_id"
                                                                                                                        class="form-check-input m-2"
                                                                                                                        name="applied_to_id">
                                                                                                                    <span
                                                                                                                        class="checkmark"></span>
                                                                                                                </label>
                                                                                                                <button
                                                                                                                    class="btn p-0 ml-4"
                                                                                                                    type="button"
                                                                                                                    *ngIf="val.sub_category.length > 0"
                                                                                                                    (click)="Showsubcat(val)">
                                                                                                                    <i
                                                                                                                        [class]="!val.click? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                                                                </button>
                                                                                                            </div>
                                                                                                            <ul class="list-group list-group-flush ml-4"
                                                                                                                *ngIf="val.click">
                                                                                                                <li class="list-group-item border-0"
                                                                                                                    *ngFor="let v of val.sub_category; index as m">
                                                                                                                    <div
                                                                                                                        class="d-flex">
                                                                                                                        <label
                                                                                                                            class="custom mb-0">{{v.category_name}}
                                                                                                                            <input
                                                                                                                                type="radio"
                                                                                                                                [value]="v._id"
                                                                                                                                (click)="applied_to(pageDesign.option_button,'category',v.category_name)"
                                                                                                                                [(ngModel)]="pageDesign.option_button.applied_to_id"
                                                                                                                                class="form-check-input m-2"
                                                                                                                                name="applied_to_id">
                                                                                                                            <span
                                                                                                                                class="checkmark"></span>
                                                                                                                        </label>
                                                                                                                        <button
                                                                                                                            class="btn p-0 ml-4"
                                                                                                                            type="button"
                                                                                                                            *ngIf="v.main_sub_category">
                                                                                                                            <i
                                                                                                                                [class]="v.main_sub_category? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </li>
                                                                                                            </ul>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </ng-template>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="row">
                                                                <label class="col-5">Font Size</label>
                                                                <div class="col">
                                                                    <input type="text" class="form-control"
                                                                        [(ngModel)]="pageDesign.option_button.fontSize"
                                                                        name="fontSize" (keyup)="valueChange($event)">
                                                                </div>
                                                                <span class="col-2 pl-0">px</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-5">
                                                            <div class="row">
                                                                <label class="col-10">Background Color of Button</label>
                                                                <input type="color" name="backgroundColor"
                                                                    style="width: 36px;"
                                                                    class="bg-white border-0 rounded-0 h-auto"
                                                                    (change)="valueChange($event)"
                                                                    [(ngModel)]="pageDesign.option_button.backgroundColor">
                                                            </div>
                                                        </div>
                                                        <div class="col-2">
                                                            <div class="row">
                                                                <label class="col-6">Color</label>
                                                                <input type="color" name="titleColor"
                                                                    style="width: 36px;"
                                                                    class="bg-white border-0 rounded-0 h-auto"
                                                                    (change)="valueChange($event)"
                                                                    [(ngModel)]="pageDesign.option_button.titleColor">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group text-right">
                                            <button type="button" class="btn btn-success"
                                                (click)="activeId = 2 ">next</button>
                                        </div>
                                    </ng-template>
                                </li>
                                <li [ngbNavItem]="2">
                                    <a ngbNavLink>Manage Grid</a>
                                    <ng-template ngbNavContent>
                                        <div *ngFor="let item of pageDesign.data; index as i">
                                            <div class="form-row form-group">
                                                <div class="col-4 m-auto text-center">
                                                    Grid {{i+1}} of {{pageDesign.data.length}}
                                                </div>
                                                <div class="col" *ngIf="item.img !== 'none'">
                                                    <div class="upload-image d-flex flex-column">
                                                        <img *ngIf="item.img !== ''" [src]="item.img" alt="image"
                                                            height="100%" width="100%">
                                                        <div
                                                            class="flex-grow-1 d-flex justify-content-center align-items-center">
                                                            <div>
                                                                Upload Image<br>
                                                                {{pageDesign.grid_height}} X <span
                                                                    *ngIf="pageDesign.design_type !== 'Carosuel'">{{pageDesign.grid_width}}</span>
                                                                <span
                                                                    *ngIf="pageDesign.design_type === 'Carosuel'">414</span>
                                                                px
                                                            </div>
                                                        </div>
                                                        <label class="btn btn-success btn-block m-0"
                                                            style="position: absolute; bottom: 0;">
                                                            Upload Images <input type="file"
                                                                (change)="imgUpload($event,item)"
                                                                accept="image/x-png,image/jpeg,image/webp"
                                                                style="display: none;">
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="pageDesign.is_grid_have_text_area">
                                                <div class="form-row form-group"
                                                    *ngFor="let text of item.texts; index as t">
                                                    <div class="col">
                                                        <input type="text" class="form-control"
                                                            name="text_value{{t}}{{i}}" placeholder="Write Text Here"
                                                            [(ngModel)]="text.text_value">
                                                    </div>
                                                    <div class="col-1">
                                                        <input type="color" class="p-0 border-0 bg-white"
                                                            name="text_color{{t}}{{i}}" [(ngModel)]="text.text_color">
                                                    </div>
                                                    <div class="col-2">
                                                        <input type="number" class="form-control"
                                                            name="text_font_size{{t}}{{i}}" placeholder="Font-size"
                                                            [(ngModel)]="text.text_font_size">
                                                    </div>
                                                    <div class="col-2">
                                                        <input type="text" class="form-control"
                                                            name="text_font_family{{t}}{{i}}" placeholder="Font-Family"
                                                            [(ngModel)]="text.text_font_family">
                                                    </div>
                                                    <div class="col-1" *ngIf="item.img !== 'none'">
                                                        <button class="btn py-0" *ngIf="!text.delete"
                                                            (click)="addMoreText(item)">
                                                            <i class="fas fa-plus my-2"></i>
                                                        </button>
                                                        <button class="btn py-0" *ngIf="text.delete"
                                                            (click)="deleteText(item.texts,t)">
                                                            <i class="fas fa-trash-alt"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div ngbDropdown class="form-group d-inline-block w-100">
                                                <button
                                                    class="btn-outline-green form-control w-100 d-flex justify-content-between"
                                                    type="button" ngbDropdownToggle>
                                                    <span>{{item.applied_to}}</span>
                                                </button>
                                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                                    style="width: 100%;">
                                                    <ul class="list-group list-group-flush">
                                                        <li class="list-group-item border-0">
                                                            <button class="btn p-0" type="button"
                                                                (click)="open(feturecontent)">Feture</button>
                                                            <ng-template #feturecontent let-modal>
                                                                <div class="modal-header border-0">
                                                                    <div class="bg-success p-2 text-center w-100"
                                                                        *ngIf="catagories.length > 0">
                                                                        <a routerLink="/feture-product-setting"
                                                                            class="text-white"
                                                                            routerLinkActive="active-link"
                                                                            target="_blank"> Create new
                                                                            Feture</a>
                                                                    </div>
                                                                    <button type="button" class="close"
                                                                        aria-label="Close"
                                                                        (click)="modal.dismiss('Cross click')">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div class="modal-body pt-0"
                                                                    *ngIf="fetures.length > 0 || fetureMenu.length > 0">
                                                                    <div class="input-group p-2 w-100">
                                                                        <div class="input-group-prepend"
                                                                            style="width: 9%;">
                                                                            <span
                                                                                class="input-group-text border-dark rounded-0 bg-transparent border-right-0">
                                                                                <i class="fas fa-search"></i></span>
                                                                        </div>
                                                                        <input type="text" class="" ngModel
                                                                            name="searchCatagory" #searchCatagory>
                                                                    </div>
                                                                    <div class="">
                                                                        <ul class="list-group list-group-flush">
                                                                            <li class="list-group-item border-0"
                                                                                *ngFor="let feture of fetures">
                                                                                <div class="d-flex">
                                                                                    <label class="custom mb-0">
                                                                                        Home <i
                                                                                            class="fas fa-angle-double-right mx-2"></i>
                                                                                        {{feture.featured_product_tag}}
                                                                                        <input type="radio"
                                                                                            [value]="feture._id"
                                                                                            [(ngModel)]="item.applied_to_id"
                                                                                            (click)="applied_to(item,'feture',feture.featured_product_tag)"
                                                                                            class="form-check-input m-2"
                                                                                            name="applied_to_id">
                                                                                        <span class="checkmark"></span>
                                                                                    </label>
                                                                                </div>
                                                                            </li>
                                                                            <li class="list-group-item border-0"
                                                                                *ngFor="let feture of fetureMenu">
                                                                                <div class="d-flex">
                                                                                    <label class="custom mb-0">
                                                                                        {{feture.featured_product_category_category_name}}
                                                                                        <i
                                                                                            class="fas fa-angle-double-right mx-2"></i>
                                                                                        {{feture.featured_product_category_tag}}
                                                                                        <input type="radio"
                                                                                            [value]="feture._id"
                                                                                            [(ngModel)]="item.applied_to_id"
                                                                                            (click)="applied_to(item,'feture',feture.featured_product_category_category_name)"
                                                                                            class="form-check-input m-2"
                                                                                            name="applied_to_id">
                                                                                        <span class="checkmark"></span>
                                                                                    </label>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                        </li>
                                                        <li class="list-group-item border-0">
                                                            <button class="btn p-0" type="button"
                                                                (click)="open(content)">catagory</button>
                                                            <ng-template #content let-modal>
                                                                <div class="modal-header border-0">
                                                                    <div class="p-2 w-100"
                                                                        *ngIf="0 >= catagories.length">
                                                                        No Catagory Is Avaliable
                                                                    </div>
                                                                    <div class="bg-success p-2 text-center w-100"
                                                                        *ngIf="catagories.length > 0">
                                                                        <a routerLink="/create-new-catagory"
                                                                            class="text-white"
                                                                            routerLinkActive="active-link"
                                                                            target="_blank">
                                                                            Create new
                                                                            catagory</a>
                                                                    </div>
                                                                    <button type="button" class="close"
                                                                        aria-label="Close"
                                                                        (click)="modal.dismiss('Cross click')">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div class="modal-body pt-0"
                                                                    *ngIf="catagories.length > 0">
                                                                    <div class="">
                                                                        <ul class="list-group list-group-flush">
                                                                            <li class="list-group-item border-0"
                                                                                *ngFor="let main_cat of catagories; index as i">
                                                                                <div class="d-flex">
                                                                                    <label
                                                                                        class="custom mb-0">{{main_cat.category_name}}
                                                                                        <input type="radio"
                                                                                            [value]="main_cat._id"
                                                                                            (click)="applied_to(item,'category1',main_cat.category_name)"
                                                                                            [(ngModel)]="item.applied_to_id"
                                                                                            class="form-check-input m-2"
                                                                                            name="applied_to_id">
                                                                                        <span class="checkmark"></span>
                                                                                    </label>
                                                                                    <button class="btn p-0 ml-4"
                                                                                        type="button"
                                                                                        *ngIf="main_cat.main_sub_category"
                                                                                        (click)="Showsubcat(main_cat)">
                                                                                        <i
                                                                                            [class]="!main_cat.click? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                                    </button>
                                                                                </div>
                                                                                <ul class="list-group list-group-flush ml-4"
                                                                                    *ngIf="main_cat.click">
                                                                                    <li class="list-group-item border-0"
                                                                                        *ngFor="let val of main_cat.main_sub_category; index as j">
                                                                                        <div class="d-flex">
                                                                                            <label
                                                                                                class="custom mb-0">{{val.category_name}}
                                                                                                <input type="radio"
                                                                                                    [value]="val._id"
                                                                                                    (click)="applied_to(item,'category2',val.category_name)"
                                                                                                    [(ngModel)]="item.applied_to_id"
                                                                                                    class="form-check-input m-2"
                                                                                                    name="applied_to_id">
                                                                                                <span
                                                                                                    class="checkmark"></span>
                                                                                            </label>
                                                                                            <button class="btn p-0 ml-4"
                                                                                                type="button"
                                                                                                *ngIf="val.sub_category.length > 0"
                                                                                                (click)="Showsubcat(val)">
                                                                                                <i
                                                                                                    [class]="!val.click? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                                            </button>
                                                                                        </div>
                                                                                        <ul class="list-group list-group-flush ml-4"
                                                                                            *ngIf="val.click">
                                                                                            <li class="list-group-item border-0"
                                                                                                *ngFor="let v of val.sub_category; index as m">
                                                                                                <div class="d-flex">
                                                                                                    <label
                                                                                                        class="custom mb-0">{{v.category_name}}
                                                                                                        <input
                                                                                                            type="radio"
                                                                                                            [value]="v._id"
                                                                                                            (click)="applied_to(item,'category3',v.category_name)"
                                                                                                            [(ngModel)]="item.applied_to_id"
                                                                                                            class="form-check-input m-2"
                                                                                                            name="applied_to_id">
                                                                                                        <span
                                                                                                            class="checkmark"></span>
                                                                                                    </label>
                                                                                                    <button
                                                                                                        class="btn p-0 ml-4"
                                                                                                        type="button"
                                                                                                        *ngIf="v.main_sub_category">
                                                                                                        <i
                                                                                                            [class]="v.main_sub_category? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                                                    </button>
                                                                                                </div>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </li>
                                                                                </ul>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row"
                                            *ngIf="pageDesign.design_type === 'productgridView' || pageDesign.design_type === 'productlistView' || pageDesign.design_type ==='Horizontal-product-view'">
                                            <div class="col">
                                                <img [src]="image" width="100%" alt="">
                                            </div>
                                            <div class="col m-auto">
                                                <button class="btn btn-success " type="button"
                                                    (click)="openXl(productcontent)">Add Products</button>
                                            </div>
                                            <ng-template #productcontent let-modal>
                                                <div class="modal-header border-0">
                                                    <h4>Products</h4>
                                                    <button type="button" class="close" aria-label="Close"
                                                        (click)="modal.dismiss('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body pt-0">
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col-1">All</th>
                                                                <th scope="col-3">Product Details</th>
                                                                <th scope="col-1">Price</th>
                                                                <th scope="col-1">Discount</th>
                                                                <th scope="col-1">Stock</th>
                                                                <th scope="col-1">SKU</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let product of products;index as p">
                                                                <th scope="row">
                                                                    <label class="custom mb-0">
                                                                        <input type="checkbox"
                                                                            [(ngModel)]="product.status"
                                                                            (ngModelChange)="addProducts(product)"
                                                                            class="form-check-input m-2"
                                                                            name="product.name">
                                                                        <span class="checkmark"></span>
                                                                    </label>
                                                                </th>
                                                                <td>
                                                                    <div class="row">
                                                                        <div class="col-1">
                                                                            <img [src]="product.cover_image"
                                                                                class="manage-product-image" alt="img">
                                                                        </div>
                                                                        <div class="col">
                                                                            {{product.product_name}}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>{{product.price_from_buyer}}</td>
                                                                <td>{{product.price_discount_from_buyer}}%
                                                                </td>
                                                                <td>{{product.quantity_in_stock}}</td>
                                                                <td>{{product.sku}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </ng-template>
                                        </div>
                                        <div class="form-group text-center" *ngIf="!edit_id">
                                            <button class="btn btn-success" [disabled]="!pageDesign.design_type"
                                                type="submit">Save</button>
                                        </div>
                                    </ng-template>
                                </li>
                            </ul>
                            <div [ngbNavOutlet]="nav" class="border p-3"></div>
                            <div class="col-12 text-center my-3" *ngIf="edit_id">
                                <button class="btn btn-success" type="submit">Update</button>
                            </div>
                        </form>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </div>
</div>
