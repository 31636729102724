<section>
    <div class="upper">
        <div class="container-fluid">
            <div class="border border-success bg-white py-4">
                <div class="position-absolute title" style="right: 30px;">
                    <button class="btn p-0 rounded-0">
                        <img src="../../../assets/img/icons/Icon material-cancel.png" alt="" class="w-100">
                    </button>
                </div>
                <div>
                    <h4 class="text-capitalize  text-center">Roll On Top</h4>
                    <div class="container">
                        <form (ngSubmit)="onSubmit(rollOnTop,rollOnList)" class="d-flex justify-content-center"
                            #rollOnTop="ngForm">
                            <div class="col-8">
                                <div class="form-group productInfo">
                                    <label class="title">*Country</label>
                                    <select name="country" class="form-control" [(ngModel)]="rollOnForm.country"
                                        required>
                                        <option value="{{country.name}}" *ngFor="let country of countries">
                                            {{country.name}}</option>
                                    </select>
                                </div>
                                <div class="form-group productInfo">
                                    <label class="title">*Roll On Top</label>
                                    <input type="text" class="form-control productInfo" name="rollOnTop"
                                        [(ngModel)]="rollOnForm.rollOnTop" required>
                                    <!-- <ejs-richtexteditor #fromRTE [toolbarSettings]='tools' #rollTop='ngModel'
                                        [(value)]='rollOnForm.rollOnTop' required name="rollOnTop"
                                        [(ngModel)]="rollOnForm.rollOnTop" (created)="rteCreated()">
                                    </ejs-richtexteditor> -->

                                </div>
                                <div ngbDropdown class="form-group w-100 d-inline-block">
                                    <label class="title">Apply To</label>
                                    <button
                                        class="btn btn-outline-green form-control w-100 d-flex justify-content-between"
                                        type="button" id="dropdownBasic1" ngbDropdownToggle>
                                        Apply On</button>
                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="width: 100%;">
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item border-0">
                                                New Page
                                            </li>
                                            <li class="list-group-item border-0">
                                                Product Tag
                                            </li>
                                            <li class="list-group-item border-0">
                                                <button class="btn p-0" (click)="open(content,'md')">catagory
                                                    <span class="material-icons">
                                                        arrow_drop_down
                                                    </span></button>
                                            </li>
                                            <ng-template #content let-modal>
                                                <div class="modal-header border-0">
                                                    <input type="text" class="form-control" name="search">
                                                    <button type="button" class="close" aria-label="Close"
                                                        (click)="modal.dismiss('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="">
                                                        <ul class="list-group list-group-flush">
                                                            <li class="list-group-item border-0"
                                                                *ngFor="let item of catagories; index as i">
                                                                <div class="d-flex">
                                                                    <label class="contain" *ngIf="item.button">
                                                                        <input type="radio" class="shorter">
                                                                        <span class="checkmark"></span>
                                                                    </label>
                                                                    <button class="btn p-0 ml-4" *ngIf="item.button"
                                                                        type="button" (click)="Showcat(i)">
                                                                        <img [src]="item.button? '../../../assets/img/icons/Icon awesome-plus-square.png':'../../../assets/img/icons/Icon feather-minus-square.png'"
                                                                            height="15px" alt="">
                                                                    </button>
                                                                    <label class="form-check-label mx-2"
                                                                        for="">{{item.catName}}</label>
                                                                </div>
                                                                <ul class="list-group list-group-flush ml-4"
                                                                    *ngIf="item.click">
                                                                    <li class="list-group-item border-0"
                                                                        *ngFor="let val of item.subcatagory; index as j">
                                                                        <div class="d-flex">
                                                                            <label class="contain">
                                                                                <input type="radio" class="shorter">
                                                                                <span class="checkmark"></span>
                                                                            </label>
                                                                            <button class="btn p-0 ml-4" type="button"
                                                                                (click)="Showsubcat(i,j)">
                                                                                <img [src]="val.button? '../../../assets/img/icons/Icon awesome-plus-square.png':'../../../assets/img/icons/Icon feather-minus-square.png'"
                                                                                    height="15px" alt="">
                                                                            </button>
                                                                            <label class="form-check-label mx-2"
                                                                                for="">{{val.catName}}</label>
                                                                        </div>
                                                                        <ul class="list-group list-group-flush ml-4"
                                                                            *ngIf="val.click">
                                                                            <li class="list-group-item border-0"
                                                                                *ngFor="let v of val.subcatagory; index as m">
                                                                                <div class="d-flex">
                                                                                    <label class="contain"
                                                                                        *ngIf="item.button">
                                                                                        <input type="radio"
                                                                                            class="shorter">
                                                                                        <span class="checkmark"></span>
                                                                                    </label>
                                                                                    <button class="btn p-0 ml-4"
                                                                                        type="button">
                                                                                        <img [src]="v.button? '../../../assets/img/icons/Icon awesome-plus-square.png':'../../../assets/img/icons/Icon feather-minus-square.png'"
                                                                                            height="15px" alt="">
                                                                                    </button>
                                                                                    <label class="form-check-label mx-2"
                                                                                        for="">{{v.name}}</label>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <li class="list-group-item border-0">
                                                <button class="btn p-0" (click)="open(brand,'xl')">Brand
                                                    <span class="material-icons">
                                                        arrow_drop_down
                                                    </span></button>
                                            </li>
                                            <ng-template #brand let-modal>
                                                <div class="modal-header border-0">
                                                    <h4 class="text-capitalize text-center">Offer List</h4>
                                                    <button type="button" class="close" aria-label="Close"
                                                        (click)="modal.dismiss('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="">
                                                        <ul class="list-group p-0 rounded-0">
                                                            <li class="col-12 font-weight-bold">
                                                                <div class="row">
                                                                    <div class="col-7">
                                                                        <div class="row h-100">
                                                                            <div class="col-1"></div>
                                                                            <div class="col-1 py-3 px-0 border">
                                                                                Sl.No</div>
                                                                            <div
                                                                                class="col-2 py-3 border-right  border-top border-bottom">
                                                                                Description</div>
                                                                            <div
                                                                                class="col-4 py-3 border-right  border-top border-bottom">
                                                                                Offer Tag</div>
                                                                            <div
                                                                                class="col-1 px-0 py-3 text-center border-right  border-top border-bottom">
                                                                                Value</div>
                                                                            <div
                                                                                class="col-1 py-3 px-0 text-center border-right border-bottom  border-top">
                                                                                Count</div>
                                                                            <div
                                                                                class="col-2 py-3 border-right border-bottom  border-top">
                                                                                Applied On</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-5  border-top">
                                                                        <div class="row h-100">
                                                                            <div
                                                                                class="col-2 py-3 border-right border-bottom">
                                                                                coupon</div>
                                                                            <div
                                                                                class="col-3 py-3 border-right border-bottom">
                                                                                valid From</div>
                                                                            <div
                                                                                class="col-3 py-3 border-right border-bottom">
                                                                                valid Upto
                                                                            </div>
                                                                            <div class="col-4">
                                                                                <div class="row h-100">
                                                                                    <div
                                                                                        class="col-8 py-3 border-right border-bottom">
                                                                                        Action
                                                                                    </div>
                                                                                    <div
                                                                                        class="col-4 py-3 px-0 text-center border-right border-bottom">
                                                                                        Status
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li class="col-12"
                                                                *ngFor="let item of offerList; index as i">
                                                                <div class="row">
                                                                    <div class="col-7">
                                                                        <div class="row h-100">
                                                                            <div class="col-1 py-3 border-right">
                                                                                <label class="contain">
                                                                                    <input type="radio" class="shorter">
                                                                                    <span class="checkmark"></span>
                                                                                </label>
                                                                            </div>
                                                                            <div
                                                                                class="col-1 py-3 border-right border-bottom">
                                                                                {{i+1}}</div>
                                                                            <div
                                                                                class="col-2 py-3 border-right border-bottom">
                                                                                {{item.description}}</div>
                                                                            <div
                                                                                class="col-4 py-3 border-right border-bottom">
                                                                                {{item.couponOfferTag}}</div>
                                                                            <div class="col-1 py-3 border-right border-bottom"
                                                                                *ngIf="item.cuponOfferValue">
                                                                                {{item.cuponOfferValue}}</div>
                                                                            <div class="col-1 py-3 border-right border-bottom"
                                                                                *ngIf="item.cuponOfferValueFlat">
                                                                                {{item.cuponOfferValueFlat}}</div>
                                                                            <div
                                                                                class="col-1 py-3 border-right border-bottom">
                                                                                {{item.OfferUseTime}}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-5">
                                                                        <div class="row h-100">
                                                                            <div
                                                                                class="col-2 py-3 border-right border-bottom">
                                                                                {{item.couponOfferCode}}</div>
                                                                            <div
                                                                                class="col-3 py-3 border-right border-bottom">
                                                                                {{item.ValidFrom| date}}</div>
                                                                            <div
                                                                                class="col-3 py-3 border-right border-bottom">
                                                                                {{item.ValidUpto| date}}</div>
                                                                            <div class="col-4">
                                                                                <div class="row h-100">
                                                                                    <div
                                                                                        class="col-4 py-3 border-right border-bottom">
                                                                                        <button class="btn p-0"
                                                                                            (click)="updateCoupon(item)">
                                                                                            <img src="../../../../assets/img/icons/Icon awesome-edit-1.png"
                                                                                                height="25px" alt="">
                                                                                        </button>
                                                                                    </div>
                                                                                    <div
                                                                                        class="col-4 py-3 border-right border-bottom">
                                                                                        <button class="btn p-0"
                                                                                            (click)="delete(item,offerList)">
                                                                                            <img src="../../../../assets/img/icons/Icon material-delete-red-forever.png"
                                                                                                height="25px" alt="">
                                                                                        </button>
                                                                                    </div>
                                                                                    <div
                                                                                        class="col-4 py-3 border-right border-bottom">
                                                                                        <button class="btn p-0">
                                                                                            <img src="../../../../assets/img/icons/Icon awesome-eye.png"
                                                                                                height="25px" alt="">
                                                                                        </button>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ul>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <button type="submit" class="btn btn-success rounded-0"
                                        [disabled]="rollOnTop.form.invalid">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid my-4">
        <h4 class="text-capitalize text-center">Roll On Top List</h4>
        <ul class="list-group p-0 border-top rounded-0">
            <li class="col-12 border-left font-weight-bold">
                <div class="row">
                    <div class="col-3">
                        <div class="row h-100">
                            <div class="col-3 py-3 border-right border-bottom">Sl.No</div>
                            <div class="col-9 py-3 border-right border-bottom">Country</div>
                        </div>
                    </div>
                    <div class="col-7 py-3 border-right border-bottom">Offer Tag</div>
                    <div class="col-2">
                        <div class="row h-100">
                            <div class="col-8 py-3 border-right border-bottom">
                                Action
                            </div>
                            <div class="col-4 py-3 border-right border-bottom">
                                Status
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="col-12 border-left" *ngFor="let item of rollOnList; index as i">
                <div class="row">
                    <div class="col-3">
                        <div class="row h-100">
                            <div class="col-3 py-3 border-right border-bottom">{{i+1}}</div>
                            <div class="col-9 py-3 border-right border-bottom">{{item.country}}</div>
                        </div>
                    </div>
                    <div class="col-7 py-3 border-right border-bottom">{{item.rollOnTop}}</div>
                    <div class="col-2">
                        <div class="row h-100">
                            <div class="col-4 py-3 border-right border-bottom">
                                <button class="btn p-0" (click)="updaterollOn(item)">
                                    <img src="../../../../assets/img/icons/Icon awesome-edit-1.png" height="25px"
                                        alt="">
                                </button>
                            </div>
                            <div class="col-4  py-3 border-right border-bottom">
                                <button class="btn p-0" (click)="delete(item,rollOnList)">
                                    <img src="../../../../assets/img/icons/Icon material-delete-red-forever.png"
                                        height="25px" alt="">
                                </button>
                            </div>
                            <div class="col-4 py-3 border-right border-bottom">
                                <button class="btn p-0">
                                    <img src="../../../../assets/img/icons/Icon awesome-eye.png" height="25px" alt="">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</section>