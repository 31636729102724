import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-store-settings',
  templateUrl: './store-settings.component.html',
  styleUrls: ['./store-settings.component.css']
})
export class StoreSettingsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
