import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { convertToObject } from 'typescript';
import { AddVendor } from './add-vendor';

@Component({
  selector: 'app-add-vendor',
  templateUrl: './add-vendor.component.html',
  styleUrls: ['./add-vendor.component.css']
})
export class AddVendorComponent implements OnInit {

  mobNumberPattern = "^[0-9]{10}$";
  active = 1;

  addVendor = new AddVendor('Salutation', '', '', '', '', '', '', '', '', '', '', '', '')
  vendorList: any= [];

  submit(form: NgForm) {
    let body = {
      primary_contact: `${this.addVendor.salutation} ${this.addVendor.fname} ${this.addVendor.lname}`,
      company_name: this.addVendor.company_name,
      vendor_display_name: this.addVendor.vendor_display_name,
      vendor_email: this.addVendor.vendor_email,
      vendor_work_phone: this.addVendor.vendor_work_phone,
      vendor_mobile_phone: this.addVendor.vendor_mobile_phone,
      vendor_skype_number_or_name: this.addVendor.vendor_skype_number_or_name,
      vendor_whatsapp: this.addVendor.vendor_whatsapp,
      vendor_designation: this.addVendor.vendor_designation,
      vendor_department: this.addVendor.vendor_department,
      vendor_website: this.addVendor.vendor_website
    }
    this.api.post(`add_source_vendor`, body).subscribe(
      data => {
        console.log('Success!', data);
        form.reset()
      },
      error => console.log('Error!', error)
    )

  }

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.api.get(`source_vendor_list`).subscribe(
      data => {
        this.vendorList = Object.values(data)[2];
        console.log(this.vendorList)
      },
      error => console.log('Error!', error)
    )
  }

}
