import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-customer-group',
  templateUrl: './customer-group.component.html',
  styleUrls: ['./customer-group.component.css']
})
export class CustomerGroupComponent implements OnInit {

  customerGroupList = []

  loader = true
  customers = []

  customer_group_customers = []

  openXl(content) {
    this.modalService.open(content, { scrollable: true, size: 'xl' })}

  customer_group_name = ''

  _handelSubmit(){
    this.api.post(`add_customer_group`, {"customer_group_name": this.customer_group_name}).subscribe(
      data => {
        console.log(data)
        if (data.status==='success') {
          window.location.reload()
        }
      },
      error => console.log(error)
    ) 
  }

  _handelAddCustomer(customerGroup){
    console.log(customerGroup)
    // this.customers.map((res) => {
    //   if (res.status = true) {
    //     this.customer_group_customers.push(res._id)
    //   }
    // })
  }

  viewCustomer(_id,routerlink,val){
    this.router.navigateByUrl(`manage-customer/${routerlink}/${_id}/${val}`)
  }

  constructor(private modalService: NgbModal,private api:ApiService, public router: Router) { }

  ngOnInit(): void {
    this.api.get(`customer_group_list`).subscribe(
      data =>{
        console.log(data)
        this.customerGroupList = Object.values(data)[2];
        this.loader = false
      }, 
      error => console.log('Error!', error)
    )

    this.api.post(`customer_list`, '').subscribe(
      data => {
          this.customers = data.response
          console.log(this.customers)
          this.customers.map((res) => res.status = false)
      }
    )
  }

}
