export class ManageProduct {
    constructor(
        public country: string,
        public cat_id: Array<String>,
        public cat_name: Array<String>,
        public allSelect : boolean,
        public feture: Array<String>,
        public updateMessage: string,
        public search: string,
        public serchByCatagory:string,
        public searchByGst: string,
    ){}
}
