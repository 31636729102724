export class SkuWeight {
    constructor(
        public _id: string,
        public store_generate_auto: boolean,
        public store_sku_prefix: string,
        public store_sku_manual_start_from: string,
        public store_weight_mesurements: string,
        public store_length_mesurements: string,
        public store_decimal_token: string,
        public store_thousand_token: string,
        public store_decimal_places: string
    ) { }
}
