import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-admincomponent',
  templateUrl: './admincomponent.component.html',
  styleUrls: ['./admincomponent.component.css']
})
export class AdmincomponentComponent implements OnInit {

  isCollapsed = false;
  // product_id= '/';
  toggle(name){
    this.isCollapsed = !this.isCollapsed
    if (name === 'Manage Products') {
      this.api.get(`product_list?page=1&limit=10`).subscribe(
          data => {
            // this.productList = Object.values(data)[2]
            this.api.productListShare(Object.values(data)[2])
            console.log(Object.values(data)[2])
          },
          error => console.log('Error!', error)
        )
    }
  }



  details= [
    // {name:"orders", link:'banner-setting', childs:[
      // {name:'manage order',link:'banner-setting',},
      // {name:'draft oder',link:'banner-setting'},
      // {name:'manual order',link:'banner-setting'},
      // {name:'abandoned and checkout',link:'banner-setting'}
    // ]},
    {name: "product",childs:[
      {name:"Add Products", link:'product-add'},
      {name:"Manage Products", link: 'product-manage/product_list/ /1'},
      {name:"Product Settings", link: 'product-setting'},
      {name:'inventory',link:'inventory'},
    ]},
    // {name:'Marketing',link:'banner-setting'},
    {name:"Fetured Settings", link: 'feture-product-setting'},
    {name: 'customer Group',link : 'customer-group'},
    {name:"Manage Customers", link:'manage-customer/customer_list/ /0'},
    {name:"Manage Orders", link:"order-list/all/1"},
    // {name:"Settings", link:'store-settings',
    //  childs:[
      //  {name:'Buy button Setting', link: 'store-settings#child-1', active:false},
      //  {name:'Store Setting', link: 'store-settings#child-2',  active:false},
      //  {name:'Morbi leo risus', link: 'store-settings#child-3',  active:false},
      //  {name:'Porta ac consectetur ac', link: 'store-settings#child-4' , active:false}]},
    {name:"Banner Settings", link: 'banner-setting'},
    {name:"Add Banner", link: 'index-page-design'},
    {name: "Add Vendor", link: 'add-vendor'},
    {name:"Youtube Channel settings", link:'youtube-channel-setting'},
    { name:'Icon settings', link:'icon-logo-caption-setting',},
    { name:'Country & Currency Settings', link:'country-currency-setting'},
    { name:'Store Settings', link:'store-settings/1'},
    {name: 'Discount & Coupon Settings', link: 'offers',},
    { name:'Social Settings', link:'social_add'},
    { name:'Pages Info Add', link:'pages_info_add'},
    { name:'Brands Settings', link:'brands'},
    {name: 'Top Navbar settings', link:'top-navbar'},
    {name:'roll On top', link:'roll-on-top'},
    {name:'Write Terms And Condition', link:'terms-condition'},
    {name:'Authentic tag', link: 'authentic-product'},
    {name:"Extra things Added on Home", link:'what-are-you-looking-for'},
    // {name:"Calculator Settings", link:'banner-setting'},
    // {name:"Affiliates Settings", link: 'banner-setting'},
    // {name:"Coin Settings", link:'banner-setting'},
    {name:"Catagory Settings", link:'create-new-catagory'},
    // {name:"Sub Admin", link:'banner-setting'},
  ]
  linkActive:boolean;
  linkNotActive: boolean;
  
  constructor(private api: ApiService) { }

  ngOnInit(): void {
  }

}
