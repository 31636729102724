import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-delivary-garrunty',
  template: `
  <h4>Delivary Garrunty</h4>
  <div class="form-group">
      <label class="mr-sm-2">Shipping List</label>
        <div ngbDropdown class="">
            <button class="btn form-control h-auto border d-flex justify-content-between" id="dropdownBasic1"
                ngbDropdownToggle style="min-height: 35px;">
              <div class="d-flex flex-wrap">
              <ngb-alert class= "mx-2" type="secondary" (click)="close(country,c)"
                  *ngFor="let country of countryZone; index as c">{{country.shipping_name}}</ngb-alert>
              </div>
            </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="height: 350px; overflow-y: scroll;">
            <button ngbDropdownItem *ngFor="let country of selectedCountries"
              (click)="_handleZone(country,selectedCountries,countryZone)">{{country.shipping_name}}</button>
        </div>
    </div>
  </div>
    <div class="form-group">
      <label>Delivary Garrunty</label>
      <angular-editor [placeholder]="'Enter text here...'" [(ngModel)]="shipping_delivery_guarantee"></angular-editor>
    </div>
    <div class="form-group text-center">
      <button class="btn btn-success" (click)="update()">Submit</button>
    </div>
    <section class="my-3">
        <table class="table">
            <tbody class="border">
                <tr *ngFor="let ship of shippingList; index as i">
                    <th scope="row">{{i+1}}</th>
                    <td>{{ship.shipping_name}}</td>
                    <td [innerHTML]="ship.shipping_delivery_guarantee"></td>
                    <td>
                      <button class="btn p-0 text-success" (click)="edit(ship)">
                        <i class="fas fa-edit"></i>
                      </button>
                    </td>
                    <td>
                      <button class="btn text-danger" (click)="delete(ship)">
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>
    <ngb-alert *ngIf="response.status" [type]="response.status" class="submit"
                        (closed)="response.status = ''">
                        {{response.message}}
    </ngb-alert>
  `,
  styleUrls: [`../terms-condition/terms-condition.component.css`]
})
export class DelivaryGarruntyComponent implements OnInit {

  shipping_delivery_guarantee = ''

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '200px',
    minHeight: '200px',
    maxHeight: '200px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'no',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };
  shippingList = []
  selectedCountries: any = []
  countryZone: any = []
  response: any = { status: '', message: '', type: '' }

  _handleZone(country, array, zone) {
    console.log(country)
    zone.push(country)
    array.map((res, index) => {
      if (res.shipping_name === country.shipping_name) {
        array.splice(index, 1)
      }
    })
  }

  close(country,index) {
    this.countryZone.splice(index,1)
    this.selectedCountries.push(country)
  }

  edit(ship){
    this.shippingList.map((res,index) => {
      if (res.shipping_name === ship.shipping_name) {
        this.countryZone.push(res)
        this.shippingList.splice(index)
      }
    })
    this.shipping_delivery_guarantee = ship.shipping_delivery_guarantee
  }

  update() {
    this.countryZone.map((res) => {
      res.shipping_delivery_guarantee = this.shipping_delivery_guarantee
      this.api.put(`update_shipping`, res).subscribe(
        data => {
          console.log(data)
          this.response = data
          if (this.response.status === 'success') {
            this.response.message = 'Shipping Delivary Garruenty Added Successfully'
            window.location.reload()
          }
        },
        error => console.log(error)
      )
    })
  }

  delete(ship){
    console.log(ship)
    ship.shipping_delivery_guarantee = ''
    this.api.put(`update_shipping`, ship).subscribe(
      data => {
        this.response = data
        this.response.message = 'Shipping Delivary Garruenty Deleted Successfully'
        if (this.response.status === 'success') {
          window.location.reload()
        }
      },
      error => console.log(error)
    )
  }

  constructor(private api:ApiService) { }

  ngOnInit(): void {

    this.api.get('shipping_list').subscribe(
      data => {
        let country = Object.values(data);
        country[2].map((result) => {
          if (result.shipping_delivery_guarantee){
            this.shippingList.push(result)
          }else{
            this.selectedCountries.push(result)
          }
        })
        console.log(this.selectedCountries)
      },
      error => console.log('Error!', error)
    )
  }

}
