export class ProductAdd {
    constructor(
        public loader : boolean,
        public coverImg : any,
        public productType : boolean,
        public physical_product_condition: string,
        public country_origin_name: string,
        public product_main_category :string,
        public product_main_category_id: string,
        public product_sub_main_category : string,
        public product_sub_main_category_id: string,
        public product_sub_category : string,
        public catagory_id : string,
        public brandId : string,
        public productName: string,
        public price : string,
        public discount :string,
        public productCostPrice : string,
        public mrp : string,
        public margin : string,
        public profit : string,
        public Description : string,
        public quantity : Number,
        public quantity_in_stock_unlimited : boolean,
        public AvaliableToShopUpTo: string,
        public AddVideoURL : string,
        public PreOrder: boolean,
        public expectedLaunchDate: string,
        public typeOfProduct :string,
        public type_of_product_addons: Array<Object>,
        public wash_care: string,
        public productAttribute: Array<Object>,
        public attributeList: Array<Object>,
        public gst_id: string,
        public barcode : string,
        public sku : string,
        public SourceVendor : string,
        public RefferenceSKU : string,
        public weight : string,
        public freeShipping: boolean,
        public giftWrap:boolean,
        public gift_wrap_price: string,
        public variableList : Array<Object>,
        public bulkPricelist : Array<Object>,
        public varientsforSingleProductCustomforMake: Array<any>,
        public custom : Array<Object>,
        public sku_valid: object)
        {}
}
