export class Form {
        constructor(
            public img:File,
            public country: string,
            public name: string,
            public applyPage: string,
            public text: string
        ){}
    
}
