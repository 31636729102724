<div>
    <section>
        <h4 class="heading text-center text-uppercase">IcON , LOGO & CAPTION SETTING</h4>
                <div class="container-fluid">
                    <form  #logoIconForm="ngForm">
                        <div class="row">
                            <div class="col-4">
                                    <div class="form-group">
                                        <label class="title">Country</label>
                                        <select name="country" class="form-control" [(ngModel)]="logoForm.country" required>
                                            <option value="{{country._id}}-{{country.selected_country_name}}" *ngFor="let country of countries">{{country.selected_country_name}}</option>
                                        </select>
                                    </div>
                            </div>
                            <div  class="col-7">
                                <div class="row">
                                    <div class="col-4 text-center mb-3">
                                        <div class="border border-success selectIcon d-flex justify-content-center align-items-center">
                                            <img class="" [src]="logoForm.icon" 
                                            alt="" width="100px" height="100px">
                                        </div>
                                        <label class="btn btn-success rounded-0 m-2 font-weight-bold">Select Icon
                                            <input type="file" size="60" class="d-none" name="icon" required
                                                ngModel #icon="ngModel" (change)="onIconUpload($event,logoForm.icon)"></label>
                                        <small class="text-danger" *ngIf="!imgType">img should be .jpg or .png</small>
                                    </div>
                                    <div class="col-8 text-center mb-3">
                                        <div class="border border-success position-relative selectLogo">
                                            <img [src]="logoForm.logo" alt="SelectLogo" width="100%" height="100%">
                                        </div>
                                        <label class="btn btn-success rounded-0 m-2 font-weight-bold">Select Logo
                                            <input type="file" size="60" class="d-none" name="logo" required
                                                ngModel #logo="ngModel" (change)="onLogoUpload($event,logoForm.logo)"></label>
                                        <small class="text-danger" *ngIf="!imgType">img should be .jpg or .png</small>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group ">
                                            <label class="title">Write Caption</label>
                                            <input type="text" class="form-control" name="writeCaption"
                                                [(ngModel)]="logoForm.caption" required>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-control py-0">
                                            <label class="title" style="margin: -10px 5px">Caption Color</label>
                                            <input type="text" class="text-color" name="captionColor"
                                            [(ngModel)]="logoForm.color" required>
                                            <input type="color" class="colorPicker" name="color"
                                            [(ngModel)]="logoForm.color" required>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <div class="form-control py-0">
                                            <label class="title" style="margin: -10px;">Size</label>
                                            <input type="text" class="text-color w-100" name="fontSize" placeholder="pixels"
                                            [(ngModel)]="logoForm.size" required>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-12 text-center">
                                <button type="submit" class="btn btn-success rounded-0" *ngIf="!edit"
                                    [disabled]="logoIconForm.form.invalid || icon.invalid" (click)="onSubmit(logoIconForm)">Save</button>
                                <button type="submit" class="btn btn-success rounded-0" *ngIf="edit"
                                (click)="onUpdate(logoIconForm)">Update</button>
                            </div>
                        </div>
                    </form>
                </div>
    </section>
    <section class="container">
        <div>
            <ul class="list-group p-0 example-list">
                <li class="example-box" *ngFor="let item of iconLogoList; index as i">
                    <div class="d-flex w-100">
                        <div class="list-group-item w-100">
                            <div class="row">
                                <div class="col-2 my-auto">
                                    <h4>Country : </h4>
                                    <h5>{{item.country_name}}</h5>
                                </div>
                                <div class="col-2">
                                    <div style="height:140px">
                                        <img [src]="item.ilc_icon" alt="img" height="100%" width="100%">
                                    </div>
                                </div>
                                <div class="col-4 text-center my-auto">
                                    <div style="height:80px">
                                        <img [src]="item.ilc_logo" alt="img" height="100%" width="100%">
                                    </div>
                                    <p class="m-auto" [style.color]="item.ilc_caption_color" [style.font-size]="item.ilc_caption_size">
                                        {{item. ilc_caption}}</p>
                                </div>
                                <div class="col-2 m-auto d-flex justify-content-around">
                                    <button class="btn p-0 text-success" (click)="update(item)">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                    <button class="btn p-0 text-danger" (click)="delete(item)">
                                        <i class="fas fa-trash-alt"></i>                                    
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </section>
</div>
