<div class="loaderBackground" *ngIf="loader">
    <div class="loaderparent"></div>
    <div class="loader">
        <div class="spinner-grow text-success mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success  mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success  mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>
<header class="heading text-center">
    <h4 class="text-capitalize">Create New Catagory</h4>
</header>

<section class="container-fluid upper  position-relative">
    <div class="container border border-success bg-white w-50 py-4">
        <div class="position-absolute" style="right: 30px;">
            <button class="btn p-0 rounded-0 text-success">
                <h4>
                    <i class="fas fa-times-circle"></i>
                </h4>
            </button>
        </div>
        <div class="container">
            <form #CatForm="ngForm" novalidate>
                <div class="">
                    <div class="">
                        <!-- <div class="form-group">
                            <label class="title">Select Country</label>
                            <select name="country" class="form-control" [(ngModel)]="catagoryForm.country" required>
                                <option value="{{country._id}}-{{country.selected_country_name}}"
                                    *ngFor="let country of countries">{{country.selected_country_name}}</option>
                            </select>
                        </div> -->
                        <div class="form-group">
                            <label class="title">Catagory Name</label>
                            <input type="text" class="form-control" name="catagoryName"
                                [(ngModel)]="catagoryForm.category_name" required>
                        </div>
                        <div class="form-group">
                            <label class="title">Category Type</label>
                            <select class="form-control" [disabled]="0 >= catagories.length" [disabled]="catagoryForm._id"
                                name="catagoryType" (ngModelChange)="subcatagory()"
                                [(ngModel)]="catagoryForm.catagory_set_primary" required>
                                <option value=''>Main catagory</option>
                                <option value=' '>Sub catagory</option>
                            </select>
                        </div>
                        <div class="form-group " *ngIf="catagoryForm.catagory_set_primary">
                            <label class="title" style="z-index: 2;">Catagory</label>
                            <div ngbDropdown class="d-inline-block w-100" #myDrop="ngbDropdown">
                                <button class="btn py-2 px-4 btn-block form-control d-flex justify-content-between"
                                    type="button" ngbDropdownToggle style="height: 40px;" [disabled]="catagoryForm._id">
                                    <span class="list-group-flush d-flex">
                                        <span class="list-group-item border-0 p-0 d-flex"
                                            *ngFor="let item of catagories; index as i">
                                            <span class="d-flex" *ngIf="item._id === catagoryForm.main_category_id">{{item.category_name}}</span>
                                            <span class="list-group-flush d-flex">
                                                <span class="list-group-item border-0 p-0 d-flex"
                                                    *ngFor="let val of item.main_sub_category; index as j">
                                                    <span class="d-flex" *ngIf="val._id === catagoryForm.main_sub_category_id">
                                                        <i class="fas fa-angle-double-right m-1"></i>
                                                        {{val.category_name}}
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </button>
                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="height: 300px; overflow-y: scroll;">
                                    <!-- <div class="input-group p-2">
                                            <div class="input-group-prepend">
                                              <span class="input-group-text border-black rounded-0 bg-transparent border-right-0">
                                                  <i class="fas fa-search"></i></span>
                                            </div>
                                            <input type="text" class="search" ngModel name="searchCatagory" #searchCatagory>
                                        </div> -->
                                    <div class="">
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item border-0"
                                                *ngFor="let item of catagories; index as i">
                                                <div class="d-flex">
                                                    <label class="custom mb-0">{{item.category_name}}
                                                        <input type="radio" [value]="item._id" (ngModelChange)="onCat(item,myDrop)"
                                                            [(ngModel)]="catagoryForm.main_category_id" class="form-check-input m-2" name="main_category_id">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                    <button class="btn p-0 ml-4" type="button" *ngIf="item.main_sub_category.length > 0"
                                                        (click)="Showsubcat(item)">
                                                        <i
                                                            [class]="!item.click? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                    </button>
                                                </div>
                                                <ul class="list-group list-group-flush ml-4" *ngIf="item.click">
                                                    <li class="list-group-item border-0"
                                                        *ngFor="let val of item.main_sub_category; index as j">
                                                        <div class="d-flex">
                                                            <label class="custom mb-0">{{val.category_name}}
                                                                <input type="radio" [value]="val._id"
                                                                    (ngModelChange)="onCat(val,myDrop)" [(ngModel)]="catagoryForm.main_sub_category_id"
                                                                    class="form-check-input m-2" name="main_sub_category_id">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="form-group">
                            <label class="title">Add GST/Tax classification</label>
                            <select class="form-control" name="gstTax" ngModel required>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div> -->
                        <div class="form-group position-relative" style="height: 250px;">
                            <div class="border border-dark h-100 text-center">
                                <div class="title">Category image</div>
                                <div class="mx-auto my-3 border" style="width:150px; height: 150px; overflow: hidden;">
                                    <img *ngIf="catagoryForm.category_image" [src]="catagoryForm.category_image"
                                        class="h-100 w-100">
                                    <div class="text-danger text-center">Drop an image(.png or .jpg) file here 150
                                        x 150 px and Size will be less than 500kb
                                    </div>
                                </div>
                                <label class=" btn btn-success rounded-0 d-flex-inline my-2">Upload an image
                                    <input type="file" size="60" style="display: none;" name="img" required ngModel
                                        (change)="onFileChanged($event)"></label>
                            </div>
                        </div>
                        <div class="text-center" *ngIf="!catagoryForm._id">
                            <button type="submit" class="btn btn-danger rounded-0 w-25"
                                (click)="onSubmit(contentWarning)">Save</button>
                        </div>
                        <div class="text-center" *ngIf="catagoryForm._id">
                            <button type="submit" class="btn btn-success rounded-0 w-25"
                                (click)="update()">Update</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <ng-template #contentWarning let-modal>
        <div class="modal-header">
            <h4 class="modal-title">Warning</h4>
            <button type="button" class="close" aria-label="Close"
                (click)="modal.close('Save click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>{{response}}</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark"
                (click)="modal.close('Save click')">Close</button>
        </div>
    </ng-template>
</section>

<section class="bg-white">
    <div class="py-4">
        <h4 class="py-3 pl-5">Country : Universal</h4>
        <ul class="list-group rounded-0 border-bottom pb-2">
            <li class="list-group-item" *ngFor="let catagory of catagories; i as index" [class.bg-danger]="catagory._id === catagoryForm._id">
                <div class=" d-flex justify-content-between">
                    <div class="position-absolute"
                        style="width: 1px; background-color: rgba(0,0,0,.125); height: 100%; z-index: 2;"></div>
                    <div class="d-flex">
                        <div class="font-weight-bold my-auto mx-3">
                            {{catagory.category_name}}
                        </div>
                        <div class="border border-dark catImg mx-2" *ngIf="catagory.category_image">
                            <img [src]="catagory.category_image" alt="img" height="100%" width="100%">
                        </div>
                    </div>
                    <div class="d-flex flex-wrap">
                        <div class="my-auto mx-3">
                            <button class="btn btn-success py-1 rounded-0 GST">Add GST/ Tax classification</button>
                        </div>
                        <div class="text-center mx-3">
                            <button class="btn button-bg btn-success">
                                <i class="far fa-copy"></i>
                            </button>
                            <p class="font-weight-bold m-auto text-small">Copy Link</p>
                        </div>
                        <div class="text-center mx-3">
                            <button class="btn button-bg btn-success">
                                <i class="fas fa-share-alt"></i>
                            </button>
                            <p class="font-weight-bold m-auto text-small">Share</p>
                        </div>
                        <div class="text-center mx-3" (click)="view(catagory)">
                            <button class="btn button-bg" [class.btn-success]="catagory.status"
                                [class.btn-secondary]="!catagory.status">
                                <i class="fas fa-eye" *ngIf="catagory.status"></i>
                                <i class="fas fa-eye-slash" *ngIf="!catagory.status"></i>
                            </button>
                            <p class="font-weight-bold m-auto text-small">View</p>
                        </div>
                        <div class="text-center mx-3">
                            <button class="btn button-bg btn-success"
                                (click)="edit(catagory,catagories,catagories,'main-cat')">
                                <i class="fas fa-edit"></i>
                            </button>
                            <p class="font-weight-bold m-auto text-small">Edit</p>
                        </div>
                        <div class="text-center mx-3" (click)="open(parentcontent,catagory)">
                            <button class="btn button-bg btn-danger">
                                <i class="far fa-trash-alt"></i>
                            </button>
                            <p class="font-weight-bold m-auto text-small">Delete</p>
                        </div>
                    </div>
                </div>
                <ng-template #parentcontent let-modal>
                    <div class="modal-header">
                        <h4 class="modal-title">Warning</h4>
                        <button type="button" class="close" aria-label="Close" (click)="modal.close('Save click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>{{response}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger"
                            *ngIf="response === 'Are You Surely Want To Delete this Catagory'"
                            (click)="delete(catagory)">Delete</button>
                        <button type="button" class="btn btn-outline-dark"
                            (click)="modal.close('Save click')">Close</button>
                    </div>
                </ng-template>
                <ul class="list-group rounded-0">
                    <li class="list-group-item pr-0 border-right-0 border-left-0" [class.bg-danger]="subCatagory._id === catagoryForm._id"
                        *ngFor="let subCatagory of catagory.main_sub_category">
                        <div class=" d-flex justify-content-between">
                            <div class="position-absolute"
                                style="width: 1px; background-color: rgba(0,0,0,.125); height: 100%; z-index: 2;"></div>
                            <div class="position-absolute"
                                style="margin-top:20px; width: 15px; background-color: rgba(0,0,0,.125); height: 1px; z-index: 2;">
                            </div>
                            <div class="d-flex">
                                <div class="font-weight-bold my-auto mx-3">
                                    {{subCatagory.category_name}}
                                </div>
                                <div class="border border-dark catImg mx-2" *ngIf="subCatagory.category_image">
                                    <img [src]="subCatagory.category_image" alt="img" height="100%" width="100%">
                                </div>
                            </div>
                            <div class="d-flex flex-wrap">
                                <div class="my-auto mx-3">
                                    <button class="btn btn-success py-1 rounded-0 GST">Add GST/ Tax
                                        classification</button>
                                </div>
                                <div class="text-center mx-3">
                                    <button class="btn button-bg btn-success">
                                        <i class="far fa-copy"></i>
                                    </button>
                                    <p class="font-weight-bold m-auto text-small">Copy Link</p>
                                </div>
                                <div class="text-center mx-3">
                                    <button class="btn button-bg btn-success">
                                        <i class="fas fa-share-alt"></i>
                                    </button>
                                    <p class="font-weight-bold m-auto text-small">Share</p>
                                </div>
                                <div class="text-center mx-3" (click)="view(subCatagory)">
                                    <button class="btn button-bg" [class.btn-success]="subCatagory.status"
                                        [class.btn-secondary]="!subCatagory.status">
                                        <i class="fas fa-eye" *ngIf="subCatagory.status"></i>
                                        <i class="fas fa-eye-slash" *ngIf="!subCatagory.status"></i>
                                    </button>
                                    <p class="font-weight-bold m-auto text-small">View</p>
                                </div>
                                <div class="text-center mx-3">
                                    <button class="btn button-bg btn-success"
                                        (click)="edit(subCatagory,catagory.main_sub_category,catagories,'subcat')">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                    <p class="font-weight-bold m-auto text-small">Edit</p>
                                </div>
                                <div class="text-center mx-3">
                                    <button class="btn button-bg btn-danger" (click)="open(subcontent,subCatagory)">
                                        <i class="far fa-trash-alt"></i>
                                    </button>
                                    <p class="font-weight-bold m-auto text-small">Delete</p>
                                </div>
                            </div>
                        </div>
                        <ng-template #subcontent let-modal>
                            <div class="modal-header">
                                <h4 class="modal-title">Warning</h4>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.close('Save click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p>{{response}}</p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-danger"
                                    *ngIf="response === 'Are You Surely Want To Delete this Catagory'"
                                    (click)="delete(subCatagory)">Delete</button>
                                <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Close</button>
                            </div>
                        </ng-template>
                        <ul>
                            <li class="list-group-item pr-0 border-right-0 border-left-0" [class.bg-danger]="subCatagory1._id === catagoryForm._id"
                                *ngFor="let subCatagory1 of subCatagory.sub_category">
                                <div class=" d-flex justify-content-between">
                                    <div class="position-absolute"
                                        style="width: 1px; background-color: rgba(0,0,0,.125); height: 100%; z-index: 2;">
                                    </div>
                                    <div class="position-absolute"
                                        style="margin-top:20px; width: 15px; background-color: rgba(0,0,0,.125); height: 1px; z-index: 2;">
                                    </div>
                                    <div class="d-flex">
                                        <div class="font-weight-bold my-auto mx-3">
                                            {{subCatagory1.category_name}}
                                        </div>
                                        <div class="border border-dark catImg mx-2"
                                            *ngIf="subCatagory1.category_image">
                                            <img [src]="subCatagory1.category_image" alt="img" height="100%" width="100%">
                                        </div>
                                    </div>
                                    <div class="d-flex flex-wrap">
                                        <div class="my-auto mx-3">
                                            <button class="btn btn-success py-1 rounded-0 GST">Add GST/ Tax
                                                classification</button>
                                        </div>
                                        <div class="text-center mx-3">
                                            <button class="btn button-bg btn-success">
                                                <i class="far fa-copy"></i>
                                            </button>
                                            <p class="font-weight-bold m-auto text-small">Copy Link</p>
                                        </div>
                                        <div class="text-center mx-3">
                                            <button class="btn button-bg btn-success">
                                                <i class="fas fa-share-alt"></i>
                                            </button>
                                            <p class="font-weight-bold m-auto text-small">Share</p>
                                        </div>
                                        <div class="text-center mx-3" (click)="view(subCatagory1)">
                                            <button class="btn button-bg" [class.btn-success]="subCatagory1.status"
                                                [class.btn-secondary]="!subCatagory1.status">
                                                <i class="fas fa-eye" *ngIf="subCatagory1.status"></i>
                                                <i class="fas fa-eye-slash" *ngIf="!subCatagory1.status"></i>
                                            </button>
                                            <p class="font-weight-bold m-auto text-small">View</p>
                                        </div>
                                        <div class="text-center mx-3">
                                            <button class="btn button-bg btn-success"
                                                (click)="edit(subCatagory1,subCatagory.sub_category,catagories,'subcat_child')">
                                                <i class="fas fa-edit"></i>
                                            </button>
                                            <p class="font-weight-bold m-auto text-small">Edit</p>
                                        </div>
                                        <div class="text-center mx-3">
                                            <button class="btn btn-danger button-bg"
                                                (click)="open(content,subCatagory1)">
                                                <i class="far fa-trash-alt"></i>
                                            </button>
                                            <p class="font-weight-bold m-auto text-small">Delete</p>
                                        </div>
                                    </div>
                                </div>
                                <ng-template #content let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title">Warning</h4>
                                        <button type="button" class="close" aria-label="Close"
                                            (click)="modal.close('Save click')">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <p>{{response}}</p>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-danger"
                                            *ngIf="response === 'Are You Surely Want To Delete this Catagory'"
                                            (click)="delete(subCatagory1)">Delete</button>
                                        <button type="button" class="btn btn-outline-dark"
                                            (click)="modal.close('Save click')">Close</button>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</section>