// import { AngularFirestoreModule } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Signup } from './signup';
import { Subject } from 'rxjs';
// import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private countrySource = new Subject<object>();
  private brandSource = new Subject<object>();
  private catagorySource = new Subject<object>();
  private productListSource = new Subject<object>();
  private vendorListSource = new Subject<object>();


  countrySource$ = this.countrySource.asObservable();
  brandSource$ = this.brandSource.asObservable();
  catagorySource$ = this.brandSource.asObservable();
  productListSource$ = this.productListSource.asObservable();
  vendorListSource$ = this.vendorListSource.asObservable();

  productListShare(mission: Array<object>) {
    this.productListSource.next(mission);
  }

  countryShare(mission: Array<object>) {
    this.countrySource.next(mission);
  }

  brandShare(astronaut: Array<object>) {
    this.brandSource.next(astronaut);
  }

  catagoryShare(value:Array<object>){
    this.catagorySource.next(value);
  }

  vendorList(value:Array<Object>){
    this.vendorListSource.next(value)
  }

  constructor(private http : HttpClient) { }

  signUp(url: any, user: Signup){
    let body = {"first_name": user.firstName, "last_name": user.lastName, "email": user.email, "password": user.pwd}
    console.log(url);
    console.log(body);
    return this.http.post<any>(url, body);
  }

  login(url, user){
    return this.http.post<any>(url, user);
  }

  get windowRef() {
    return window
  }

  url='https://paramafashion-backend-g5whtncwfa-uc.a.run.app/v1.0/api/'

  getcountries(endPoint:string){
    return this.http.get(endPoint)
  }

  get(endPoint:string){
    return this.http.get(this.url+endPoint);
  }

  post(endPoint:string,user){
    // let body = {"select_country": user.country, "catagory_name": user.catagoryName, "gst": user.gstTax}
    return this.http.post<any>(this.url+endPoint,user);
  }
  put(endPoint:string,user){
    return this.http.put<any>(this.url+endPoint,user);
  }
  delete(endPoint:string,user){
    return this.http.delete<any>(this.url+endPoint,user);
  }
  
}
