<header class="heading text-center">
    <h4 class="text-capitalize">Create external link</h4>
</header>
<section class="container-fluid upper">
    <div class="position-absolute" style="right: 30px;">
        <button class="btn p-0 rounded-0">
            <img src="../../../assets/img/icons/Icon material-cancel.png" alt="" class="w-100">
        </button>
    </div>
    <div class="container">
        <div class="d-flex justify-content-center">
            <div class="border border-success mx-3" style="height: 150px; width: 150px;">
                <img src="../../../../assets/img/icons/asset-1.png" height="100%" width="100%" alt="img" class="">
            </div>
            <div class="w-25">
                <p class="mx-3">Fantastic zoya silk saree with running blouse pcs …….</p>
            </div>
            <div>
                <ul class="list-group">
                    <li class="d-flex justify-content-between" *ngFor="let item of detailsInfo">
                        <p class="mx-2 my-0" style="width: 50px;">{{item.size}}</p>
                        <p class="mx-2 my-0" style="width:60px">{{item.color}}</p>
                        <p class="mx-2 my-0" style="width: 70px;">{{item.quantity}}</p>
                        <p class="mx-2 my-0" style="width:100px">{{item.price}}</p>
                        <p class="mx-2 my-0" style="width: 90px;">{{item.sku}}</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>

<section class="container-fluid">
    <div class="row py-3">
        <div class="col-11">
            <div class="border border-success container-fluid">
                <form class="container" (ngSubmit)="submit(externalLinkForm)" #externalLinkForm="ngForm">
                    <div class="row">
                        <div class="col-6">
                            <div class="row">
                                <div class="col-5">
                                    <div class="my-3">
                                        <p class="title">Text</p>
                                            <div class="form-control">
                                                <p class="m-auto">Order By</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="my-3">
                                        <p class="title">Image</p>
                                            <div class="form-control d-flex justify-content-between">
                                                <div>
                                                    <img src="" alt="flipkart">
                                                </div>
                                                <div>
                                                    <label class="btn btn-success btn-sm rounded-0">
                                                        Add
                                                    <input type="file" size="60" style="display: none;">
                                                    </label>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                <div class="col-1 m-auto p-0 d-flex flex-column text-center">
                                    <input class="m-auto" type="checkbox" name="defalt" ngModel required>
                                    <label for="defalt" class="small m-auto">Defalt</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="my-3">
                                <label class="title left-0">Image</label>
                                <input type="text" name="youtubeLink" ngModel required
                                    class="form-control" name="youtubeLink" required>
                            </div>
                        </div>
                        <div class="col-1 my-auto">
                            <a href="" class="btn btn-success btn-sm rounded-0">
                                Go to
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-1 my-auto">
            <button class="btn p-0">
                <img src="../../../assets/img/icons/Icon material-add-circle.png" alt="">
            </button>
        </div>
    </div>
    <div class="row py-3">
        <div class="col-11">
            <div class="border border-success container-fluid">
                <form class="container" (ngSubmit)="submit(externalLinkForm)" #externalLinkForm="ngForm">
                    <div class="row">
                        <div class="col-6">
                            <div class="row">
                                <div class="col-5">
                                    <div class="my-3">
                                        <label class="title left-0">Caption</label>
                                        <input type="text" name="caption" ngModel required
                                            class="form-control">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="my-3">
                                        <p class="title">Image</p>
                                            <div class="form-control d-flex justify-content-between">
                                                <div>
                                                    <img src="" alt="flipkart">
                                                </div>
                                                <div>
                                                    <label class="btn btn-success btn-sm rounded-0">
                                                        Add
                                                    <input type="file" size="60" style="display: none;">
                                                    </label>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                <div class="col-1 m-auto p-0 d-flex flex-column text-center">
                                    <input class="m-auto" type="checkbox" name="defalt" ngModel required>
                                    <label for="defalt" class="small m-auto">Defalt</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="my-3">
                                <label class="title left-0">Image</label>
                                <input type="text" name="youtubeLink" ngModel required
                                    class="form-control" name="youtubeLink" required>
                            </div>
                        </div>
                        <div class="col-1 my-auto">
                            <a href="" class="btn btn-success btn-sm rounded-0">
                                Go to
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-1 my-auto">
            <button class="btn p-0">
                <img src="../../../assets/img/icons/Icon material-add-circle.png" alt="">
            </button>
        </div>
    </div>
    <div class="text-center">
        <button type="submit" class="btn btn-success rounded-0">Save</button>
    </div>
</section>