<div class="loaderBackground" *ngIf="loader">
    <div class="loaderparent"></div>
    <div class="loader">
        <div class="spinner-grow text-success mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success  mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success  mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>

<div class="content-wrapper container-fulid">
    <!-- Content Header (Page header) -->
    <section class="container-fluid">
        <div class="manage-product-nav">
            <div class="manage-header">
                <div class="catagory col-12 bg-white p-2 text-center">
                    <h4 class="m-0">Manage Product</h4>
                </div>
                <div style="background-color: rgba(238,236,236,1);">
                    <ul class="product-manage">
                        <li class="nav-item">
                            <form class="d-inline-flex w-100">
                                <button class="btn border border-right-0" type="submit"
                                    style="background:white; border-top-right-radius: 0; border-bottom-right-radius: 0;">
                                    <img src="../../../../assets/img/icons/Icon awesome-search@2x.png" alt=""
                                        height="16px">
                                </button>
                                <input class="form-control" type="text" placeholder="Search.." name="search"
                                    style="border-left: 0 !important; border-top-left-radius: 0; border-bottom-left-radius: 0;">
                            </form>
                        </li>
                        <li class="nav-item">
                            <select name="country" class="form-control" [(ngModel)]="manageProduct.country" required
                                (change)="getCatagory(manageProduct.country)">
                                <option value="country">All Country</option>
                                <option value="{{country._id}}-{{country.selected_country_name}}"
                                    *ngFor="let country of countries">{{country.selected_country_name}}</option>
                            </select>
                        </li>
                        <li ngbDropdown class="d-inline-block w-100 nav-item" #CatDrop="ngbDropdown">
                            <button class="btn-block d-flex justify-content-between h-100  form-control o-scroll"
                                type="button" ngbDropdownToggle>
                                <span>{{manageProduct.serchByCatagory}}</span>
                            </button>
                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="min-width: 400px; height: 600px; overflow-y: scroll;">
                                <!-- <div class="bg-success p-2 text-center ">
                                    <a routerLink="/create-new-catagory" class="text-white"
                                        routerLinkActive="active-link" target="_blank"> Create new
                                        catagory</a>
                                </div> -->
                                <div class="p-2 text-danger" *ngIf="catagories.length === 0">
                                    No Catagory Is Avaliable
                                </div>
                                <div *ngIf="catagories.length > 0">
                                    <div class="input-group p-2">
                                        <div class="input-group-prepend border border-dark border-right-0"
                                            style="width: 30%;">
                                            <span class="input-group-text rounded-0 bg-transparent border-right-0">
                                                <i class="fas fa-search"></i></span>
                                        </div>
                                        <input type="text" class="search border-left-0" ngModel name="searchCatagory"
                                            #searchCatagory>
                                    </div>
                                    <div class="w-100">
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item border-0"
                                                *ngFor="let item of catagories; index as i">
                                                <div class="d-flex">
                                                    <label class="contain mb-0">{{item.category_name}}
                                                        <input type="radio" [value]="item._id"
                                                            [(ngModel)]="manageProduct.search"
                                                            (ngModelChange)="searchCat(item.category_name,'product_by_cat_id')"
                                                            class="form-check-input m-2" name="catagory">
                                                        <span class="checkmark border border-dark"></span>
                                                    </label>
                                                    <button class="btn p-0 ml-4" type="button" *ngIf="item.main_sub_category"
                                                        (click)="Showsubcat(item)">
                                                        <i
                                                            [class]="!item.click? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                    </button>
                                                </div>
                                                <ul class="list-group list-group-flush ml-4" *ngIf="item.click">
                                                    <li class="list-group-item border-0"
                                                        *ngFor="let val of item.main_sub_category; index as j">
                                                        <div class="d-flex">
                                                            <label class="contain mb-0">{{val.category_name}}
                                                                <input type="radio" [value]="val._id"
                                                                    [(ngModel)]="manageProduct.search"
                                                                    (ngModelChange)="searchCat(val.category_name,'product_by_cat_id')"
                                                                    class="form-check-input m-2" name="catagory">
                                                                <span class="checkmark border border-dark"></span>
                                                            </label>
                                                            <button class="btn p-0 ml-4" type="button"
                                                                *ngIf="val.sub_category.length > 0"
                                                                (click)="Showsubcat(val)">
                                                                <i
                                                                    [class]="!val.click? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                            </button>
                                                        </div>
                                                        <ul class="list-group list-group-flush ml-4" *ngIf="val.click">
                                                            <li class="list-group-item border-0"
                                                                *ngFor="let v of val.sub_category; index as m">
                                                                <div class="d-flex">
                                                                    <label class="contain mb-0">{{v.category_name}}
                                                                        <input type="radio" [value]="v._id"
                                                                            [(ngModel)]="manageProduct.search"
                                                                            (ngModelChange)="searchCat(v.category_name,'product_by_cat_id')"
                                                                            class="form-check-input m-2" name="catagory"
                                                                            *ngIf="!v.main_sub_category">
                                                                        <span class="checkmark border border-dark"
                                                                            *ngIf="!v.main_sub_category"></span>
                                                                    </label>
                                                                    <button class="btn p-0 ml-4" type="button"
                                                                        *ngIf="v.main_sub_category">
                                                                        <i
                                                                            [class]="v.main_sub_category? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                    </button>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item">
                            <select class="form-control" id="">
                                <option>Category Clasifiacation </option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </li>
                        <li ngbDropdown class="d-inline-block w-100 nav-item">
                            <button class="btn-block d-flex justify-content-between h-100 form-control o-scroll"
                                type="button" ngbDropdownToggle>
                                {{manageProduct.searchByGst}}
                            </button>
                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="w-auto">
                                <div class="input-group p-2">
                                    <div class="input-group-prepend border border-dark border-right-0"
                                        style="width: 30%;">
                                        <span class="input-group-text rounded-0 bg-transparent border-right-0">
                                            <i class="fas fa-search"></i></span>
                                    </div>
                                    <input type="text" class="search border-left-0" ngModel name="searchCatagory"
                                        #searchCatagory>
                                </div>
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item border-0" *ngFor="let item of gst_list; index as i">
                                        <div class="d-flex">
                                            <label class="contain mb-0">{{item.store_hsn_code}}
                                                <input type="radio" [(ngModel)]="manageProduct.search"
                                                    class="form-check-input m-2" name="search" [value]="item._id"
                                                    (ngModelChange)="searchProduct(item.store_hsn_code,'product_by_gst_id')">
                                                <span class="checkmark border border-dark"></span>
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="nav-item">
                            <select class="form-control" id="">
                                <option>Attribute</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </li>
                        <li class="nav-item">
                            <select class="form-control" id="">
                                <option>Stock</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </li>
                        <li class="nav-item">
                            <select class="form-control" [(ngModel)]="manageProduct.search">
                                <option value="All">All Product</option>
                                <option>Pre order</option>
                                <option>Avaliable Product</option>
                            </select>
                        </li>
                        <li class="nav-item">
                            <button class="btn btn-secondary h-auto w-100"
                                (click)="openAddFeture(feturecontent)">Feture</button>
                            <ng-template #feturecontent let-modal>
                                <button type="button" class="close" aria-label="Close" (click)="closeModel()">
                                    <i class="fas fa-times-circle"></i>
                                </button>
                                <div class="modal-body">
                                    <div class="pt-3 row">
                                        <div class="col">
                                            <h4>Index page Feture Product</h4>
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col"></th>
                                                        <th scope="col">Image</th>
                                                        <th scope="col">Country Name</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Tag</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let feture of fetureList">
                                                        <th scope="row">
                                                            <label class="contain">
                                                                <input type="checkbox" class="shorter"
                                                                    name="selectfeture" [(ngModel)]="feture.status"
                                                                    (click)="selectFeture(feture)">
                                                                <span class="checkmark border"></span>
                                                            </label>
                                                        </th>
                                                        <td><img [src]="feture.featured_product_image" alt="img"
                                                                class="border border-success" height="50px"
                                                                width="50px"></td>
                                                        <td>{{feture.country_name}}</td>
                                                        <td>{{feture.featured_product_name}}</td>
                                                        <td>{{feture.featured_product_tag}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col" *ngIf="feturePerCat.length > 0">
                                            <h4>Catagory Wise Feture Product</h4>
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col"></th>
                                                        <th scope="col">Image</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Tag</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let feture of feturePerCat">
                                                        <th scope="row">
                                                            <label class="contain">
                                                                <input type="checkbox" class="shorter" name="feture"
                                                                    [(ngModel)]="feture.status"
                                                                    (click)="selectFetureForCatagory(feture)">
                                                                <span class="checkmark border"></span>
                                                            </label>
                                                        </th>
                                                        <td><img [src]="feture.featured_product_category_image"
                                                                alt="img" class="border border-success" height="50px"
                                                                width="50px"></td>
                                                        <td>{{feture.featured_product_category_name}}</td>
                                                        <td>{{feture.featured_product_category_tag}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <ul class="stock-information">
                        <li class="nav-item">
                            <p class="text-center form-control border-0" style="margin:10px 0">Products in stock</p>
                        </li>
                        <li class="nav-item">
                            <select class="form-control" id="">
                                <option>Active up to</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </li>
                        <li class="nav-item">
                            <select class="form-control" id="">
                                <option>Expired</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </li>
                        <li class="nav-item">
                            <select class="form-control" id="">
                                <option>Trend</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </li>
                        <li class="nav-item" style="background: rgb(41, 170, 41); color:white;">
                            <a routerLink="/product-add" class="text-white" routerLinkActive="active-link"
                                target="_blank">
                                <div class="text-center px-1 py-2" style="display:inline-flex;">
                                    <i class="fa fa-plus-circle" style="margin:auto 1rem"></i>
                                    <p class="" style="margin:10px 0">Add Product</p>
                                </div>
                            </a>
                        </li>
                        <li class="nav-item other-options">
                            <div style="display:inline-flex">
                                <div class="products" style="background-color: rgb(219, 249, 250);">
                                    <p class="m-auto">SET</p>
                                </div>
                            </div>
                            <div style="display:inline-flex">
                                <div class="products" style="background-color: rgb(232, 202, 240);">
                                    <p class="m-auto">SET & SINGLE</p>
                                </div>

                            </div>
                            <div style="display:inline-flex">
                                <div class="products" style="background-color: rgb(255, 217, 223);">
                                    <p class="m-auto">SINGLES</p>
                                </div>
                            </div>
                            <div style="display:inline-flex">
                                <div class="products" style="background-color: rgb(207, 248, 212);">
                                    <p class="m-auto">BULK</p>
                                </div>
                            </div>
                            <div style="display:inline-flex">
                                <div class="products" style="background-color: rgb(244, 248, 207);">
                                    <p class="m-auto">COMBO</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <ul class="list-group list-group-flush manage-product-list p-0">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col-1"><input type="checkbox" name="select" [(ngModel)]="manageProduct.allSelect"
                                    #select (ngModelChange)="all()"></th>
                            <th scope="col-3">Product Details</th>
                            <th scope="col-1">Price</th>
                            <th scope="col-1">Discount</th>
                            <th scope="col-1">Stock</th>
                            <th scope="col-1">Type</th>
                            <th scope="col-1">SKU</th>
                            <th scope="col-1">Product Type</th>
                            <th scope="col-1"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let product of products;index as p" [style.background-color]="product.bg_color">
                            <th scope="row">
                                <input type="checkbox" [(ngModel)]="product.status" (ngModelChange)="selectOne()">
                            </th>
                            <td>
                                <div class="row">
                                    <div class="col-2">
                                        <div class="manage-product-image">
                                            <img [src]="product.cover_image" class="w-100 h-100" alt="img">
                                            <button class="btn addImg" (click)="openXl(imgContent,'')">View All</button>
                                        </div>
                                        <ng-template #imgContent let-modal>
                                            <div class="modal-header">
                                                <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
                                                <button type="button" class="close" aria-label="Close"
                                                    (click)="modal.dismiss('Cross click')">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="d-flex flex-wrap">
                                                    <div class="text-center bg-light m-1 dropProducts">
                                                        <div>
                                                            <p class="m-2">1/20</p>
                                                            <p class="m-2">Drops Products Image Here</p>
                                                            <p class="m-2">(.PNG or .JPG)</p>
                                                            <label
                                                                style="padding: 10px; background: rgb(41, 170, 41); display:inline-flex;color: #fff;">Upload
                                                                Images
                                                                <input type="file" style="display: none;" multiple
                                                                    accept="image/x-png,image/jpeg"
                                                                    (change)="onFileUpload($event,product.image)"></label>
                                                        </div>
                                                    </div>
                                                    <div class="text-center m-1"
                                                        *ngFor="let img of product.image;index as i">
                                                        <div class="p-2 border position-relative">
                                                            <img [src]="img.image_link" alt="" width="250px"
                                                                height="300px">
                                                            <i class="fas fa-times-circle text-success product-clean"
                                                                (click)="deleteImg(img.image_link,product)"></i>
                                                        </div>
                                                        <div>
                                                            <div class="form-check form-check-inline">
                                                                <input type="radio" name="coverimage{{i}}"
                                                                    [value]="img.image_link"
                                                                    class="form-check-input mx-1"
                                                                    [(ngModel)]="product.cover_image">
                                                                <label for="cover"
                                                                    class="form-check-label mx-1">Cover</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-success"
                                                    (click)="productEdit(product._id, {'cover_image': product.cover_image,'image': product.image})">Save</button>
                                                <button type="button" class="btn btn-danger"
                                                    (click)="modal.close('Save click')">Cancel</button>
                                            </div>
                                        </ng-template>
                                    </div>
                                    <div class="col">
                                        {{product.product_name}}
                                    </div>
                                    <!-- <div class="col-3 text-danger font-weight-bold">
                                        <ng-template #tipContent>
                                            <div *ngFor="let feture of fetureList">
                                                <span *ngIf="feture.status">{{feture.featured_product_name}}, </span>
                                            </div>
                                            <div *ngFor="let feture of fetureCatList">
                                                <span *ngIf="feture.status">{{feture.featured_product_category_name}},
                                                </span>
                                            </div>
                                        </ng-template>
                                        <button type="button" class="btn p-0 text-danger font-weight-bold"
                                            *ngIf="product.featured_product" [ngbTooltip]="tipContent"
                                            (mouseover)="showFetures(product)" (mouseout)="hideFetures()">
                                            <button class="btn btn-danger p-1"></button>
                                            Feture
                                        </button>
                                    </div> -->
                                </div>
                            </td>
                            <td>{{product.price_from_buyer}}</td>
                            <td>{{product.price_discount_from_buyer}}%</td>
                            <td>{{product.quantity_in_stock}}</td>
                            <td>{{product.product_type}}</td>
                            <td>{{product.sku}}</td>
                            <td>{{product.type_of_product}}</td>
                            <td>
                                <button class="btn p-0" (click)="openXl(largecontent,product)">
                                    <i class="fas fa-ellipsis-v"></i>
                                </button>
                            </td>
                            <ng-template #largecontent let-modal>
                                <button type="button" class="close" aria-label="Close" (click)="closeModel()">
                                    <i class="fas fa-times-circle"></i>
                                </button>
                                <div class="modal-body">
                                    <div class="">
                                        <ngb-tabset type="pills" justify="justified">
                                            <ngb-tab>
                                                <ng-template ngbTabTitle class="button-bg">
                                                    <button class="btn button-bg">
                                                        <p class="font-weight-bold m-auto">CAT</p>
                                                    </button>
                                                    <p class="font-weight-bold m-auto">CAT</p>
                                                </ng-template>
                                                <ng-template ngbTabContent>
                                                    <form class="row" #catForm="ngForm">
                                                        <div class="col-12 border border-white">
                                                            <div class="p-3">
                                                                <!-- <div class="form-group">
                                                                    <label class="title">Country</label>
                                                                    <div class="form-control border-dark rounded-0">
                                                                        India
                                                                    </div>
                                                                </div> -->
                                                                <ul class="list-group rounded-0 pb-2">
                                                                    <li class="list-group-item border-0"
                                                                        *ngFor="let catagory of catagories">
                                                                        <div class=" d-flex justify-content-between">
                                                                            <div class="position-absolute"
                                                                                style="width: 1px; background-color: rgba(0,0,0,.125); height: 100%; z-index: 2;">
                                                                            </div>
                                                                            <div class="position-absolute"
                                                                                style="margin-top:15px; width: 15px; background-color: rgba(0,0,0,.125); height: 1px; z-index: 2;">
                                                                            </div>
                                                                            <div class="d-flex">
                                                                                <div
                                                                                    class="font-weight-bold my-auto mx-3">
                                                                                    {{catagory.category_name}}
                                                                                </div>
                                                                                <label class="contain">
                                                                                    <input type="radio"
                                                                                        [value]="catagory._id"
                                                                                        (click)="catagoryEdit(catagory,'','',product,smallcontent)"
                                                                                        class="form-check-input m-2"
                                                                                        name="product_main_category_id"
                                                                                        [(ngModel)]="product.product_main_category_id">
                                                                                    <span
                                                                                        class="checkmark border"></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <ul class="list-group rounded-0">
                                                                            <li class="list-group-item pr-0 border-0"
                                                                                *ngFor="let subCatagory of catagory.main_sub_category">
                                                                                <div
                                                                                    class=" d-flex justify-content-between">
                                                                                    <div class="position-absolute"
                                                                                        style="width: 1px; background-color: rgba(0,0,0,.125); height: 100%; z-index: 2;">
                                                                                    </div>
                                                                                    <div class="position-absolute"
                                                                                        style="margin-top:15px; width: 15px; background-color: rgba(0,0,0,.125); height: 1px; z-index: 2;">
                                                                                    </div>
                                                                                    <div class="d-flex">
                                                                                        <div
                                                                                            class="font-weight-bold my-auto mx-3">
                                                                                            {{subCatagory.category_name}}
                                                                                        </div>
                                                                                        <label class="contain">
                                                                                            <input type="radio"
                                                                                                [value]="subCatagory._id"
                                                                                                (click)="catagoryEdit(catagory,subCatagory,'',product,smallcontent)"
                                                                                                [(ngModel)]="product.product_sub_main_category_id"
                                                                                                class="form-check-input m-2"
                                                                                                name="product_sub_main_category_id">
                                                                                            <span
                                                                                                class="checkmark border"></span>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <ul>
                                                                                    <li class="list-group-item pr-0 border-0"
                                                                                        *ngFor="let subCatagory1 of subCatagory.sub_category">
                                                                                        <div
                                                                                            class=" d-flex justify-content-between">
                                                                                            <div class="position-absolute"
                                                                                                style="width: 1px; background-color: rgba(0,0,0,.125); height: 100%; z-index: 2;">
                                                                                            </div>
                                                                                            <div class="position-absolute"
                                                                                                style="margin-top:15px; width: 15px; background-color: rgba(0,0,0,.125); height: 1px; z-index: 2;">
                                                                                            </div>
                                                                                            <div class="d-flex">
                                                                                                <div
                                                                                                    class="font-weight-bold my-auto mx-3">
                                                                                                    {{subCatagory1.category_name}}
                                                                                                </div>
                                                                                                <label
                                                                                                    class="contain mb-0">
                                                                                                    <input type="radio"
                                                                                                        [value]="subCatagory1._id"
                                                                                                        (click)="catagoryEdit(catagory,subCatagory,subCatagory1,product,smallcontent)"
                                                                                                        class="form-check-input m-2"
                                                                                                        [(ngModel)]="product.category_id"
                                                                                                        name="category_id">
                                                                                                    <span
                                                                                                        class="checkmark border"></span>
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <!-- <div class="text-center m-2">
                                                        <button type="button"
                                                            (click)="catagoryEdit(catForm,product._id,item)"
                                                            class="btn btn-success">Submit</button>
                                                    </div> -->
                                                </ng-template>
                                            </ngb-tab>
                                            <ngb-tab>
                                                <ng-template ngbTabTitle>
                                                    <button class="btn button-bg">
                                                        <p class="font-weight-bold m-auto">Brand</p>
                                                    </button>
                                                    <p class="font-weight-bold m-auto">Brand</p>
                                                </ng-template>
                                                <ng-template ngbTabContent>
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col"></th>
                                                                <th scope="col">Image</th>
                                                                <th scope="col">Country Name</th>
                                                                <th scope="col">Brand Name</th>
                                                                <th scope="col">Company Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of brand">
                                                                <th scope="row">
                                                                    <label class="contain">
                                                                        <input type="radio" [value]="item.brand_name"
                                                                            [(ngModel)]="product.brand_name"
                                                                            class="shorter" name="brand"
                                                                            (click)="productEdit(product._id ,{'brand_name':item.brand_name,'brand_id':item.brand_id})">
                                                                        <span class="checkmark border"></span>
                                                                    </label>
                                                                </th>
                                                                <td><img [src]="item.brand_image" alt="img"
                                                                        class="border border-success" height="50px"
                                                                        width="50px"></td>
                                                                <td>{{item.brand_country_name}}</td>
                                                                <td>{{item.brand_name}}</td>
                                                                <td>{{item.company_name}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </ng-template>
                                            </ngb-tab>
                                            <ngb-tab>
                                                <ng-template ngbTabTitle class="button-bg">
                                                    <button class="btn button-bg">
                                                        <i class="fas fa-eye"></i>
                                                    </button>
                                                    <p class="font-weight-bold m-auto">View</p>
                                                </ng-template>
                                            </ngb-tab>
                                            <ngb-tab>
                                                <ng-template ngbTabTitle class="button-bg">
                                                    <button class="btn button-bg">
                                                        <p class="font-weight-bold m-auto">Feture</p>
                                                    </button>
                                                    <p class="font-weight-bold m-auto">Feture</p>
                                                </ng-template>
                                                <ng-template ngbTabContent>
                                                    <div class="row">
                                                        <div class="col">
                                                            <h4>Index Page Feture Product</h4>
                                                            <table class="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col"></th>
                                                                        <th scope="col">Image</th>
                                                                        <th scope="col">Name</th>
                                                                        <th scope="col">Tag</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor="let feture of fetureList">
                                                                        <th scope="row">
                                                                            <label class="contain">
                                                                                <input type="checkbox" class="shorter"
                                                                                    name="feture"
                                                                                    [(ngModel)]="feture.status"
                                                                                    (ngModelChange)="addFeture(product,feture,$event)">
                                                                                <span class="checkmark border"></span>
                                                                            </label>
                                                                        </th>
                                                                        <td><img [src]="feture.featured_product_image"
                                                                                alt="img" class="border border-success"
                                                                                height="50px" width="50px"></td>
                                                                        <td>{{feture.featured_product_name}}</td>
                                                                        <td>{{feture.featured_product_tag}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div class="col" *ngIf="feturePerCatForProduct.length > 0">
                                                            <h4>Catagory Wise Feture Product</h4>
                                                            <table class="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col"></th>
                                                                        <th scope="col">Image</th>
                                                                        <th scope="col">Name</th>
                                                                        <th scope="col">Tag</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor="let feture of feturePerCatForProduct">
                                                                        <th scope="row">
                                                                            <label class="contain">
                                                                                <input type="checkbox" class="shorter"
                                                                                    name="feture"
                                                                                    [(ngModel)]="feture.status"
                                                                                    (ngModelChange)="addCatFeture(product,feture,$event)">
                                                                                <span class="checkmark border"></span>
                                                                            </label>
                                                                        </th>
                                                                        <td><img [src]="feture.featured_product_category_image"
                                                                                alt="img" class="border border-success"
                                                                                height="50px" width="50px"></td>
                                                                        <td>{{feture.featured_product_category_name}}
                                                                        </td>
                                                                        <td>{{feture.featured_product_category_tag}}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </ngb-tab>
                                            <ngb-tab>
                                                <ng-template ngbTabTitle class="button-bg">
                                                    <button class="btn button-bg">
                                                        <i class="fas fa-link"></i>
                                                    </button>
                                                    <p class="font-weight-bold m-auto">Ext. link</p>
                                                </ng-template>
                                            </ngb-tab>
                                            <ngb-tab>
                                                <ng-template ngbTabTitle class="button-bg">
                                                    <button class="btn button-bg">
                                                        <i class="far fa-copy"></i>
                                                    </button>
                                                    <p class="font-weight-bold m-auto">Copy Link</p>
                                                </ng-template>
                                            </ngb-tab>
                                            <ngb-tab>
                                                <ng-template ngbTabTitle class="button-bg">
                                                    <button class="btn button-bg">
                                                        <i class="fas fa-share-alt"></i>
                                                    </button>
                                                    <p class="font-weight-bold m-auto">Share</p>
                                                </ng-template>
                                            </ngb-tab>
                                            <ngb-tab>
                                                <ng-template ngbTabTitle class="button-bg">
                                                    <button class="btn button-bg">
                                                        <i class="fas fa-edit"></i>
                                                    </button>
                                                    <p class="font-weight-bold m-auto">Edit</p>
                                                </ng-template>
                                                <ng-template ngbTabContent>
                                                    <div>
                                                        <!-- image upload list -->
                                                        <div
                                                            class="imageUpload border border-success my-2 px-2 d-flex flex-row">
                                                            <div class="text-center bg-light px-3">
                                                                <p class="m-2">1/20</p>
                                                                <p class="m-2">Drops Products Image Here</p>
                                                                <p class="m-2">(.PNG or .JPG)</p>
                                                                <label
                                                                    style="padding: 10px; background: rgb(41, 170, 41); display:inline-flex;color: #fff;">Upload
                                                                    Images
                                                                    <input type="file" style="display: none;" multiple
                                                                        accept="image/x-png,image/jpeg"
                                                                        (change)="onFileUpload($event,product.image)"></label>
                                                            </div>

                                                            <div class="d-flex flex-row images">
                                                                <div class="text-center "
                                                                    *ngFor="let img of product.image">
                                                                    <div
                                                                        class="p-2 border d-inline-flex position-relative">
                                                                        <img [src]="img.image_link" alt="">
                                                                        <i class="fas fa-times-circle text-success product-clean"
                                                                            (click)="deleteImg(img.image_link,product)"></i>
                                                                    </div>
                                                                    <div>
                                                                        <div class="form-check form-check-inline">
                                                                            <input type="radio" name="cover_image"
                                                                                [value]="img.image_link"
                                                                                class="form-check-input mx-1"
                                                                                [(ngModel)]="product.cover_image">
                                                                            <label for="cover"
                                                                                class="form-check-label mx-1">Cover</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <!-- product information -->
                                                        <form #singleProdyctUploadForm="ngForm" novalidate>
                                                            <div class="d-flex">
                                                                <div>
                                                                    <label class="custom mb-0">This is a Physical
                                                                        Product
                                                                        <input type="checkbox" name="product_type"
                                                                            [(ngModel)]="product.product_type">
                                                                        <span class="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                                <div class="mx-2" *ngIf="product.product_type">
                                                                    <div class="d-lg-inline-flex">
                                                                        <div class="form-check form-check-inline">
                                                                            <input class="form-check-input" type="radio"
                                                                                name="physical_product_condition" #new
                                                                                [(ngModel)]="product.physical_product_condition"
                                                                                value="new">
                                                                            <label class="form-check-label"
                                                                                for="inlineRadio1">New</label>
                                                                        </div>
                                                                        <div class="form-check form-check-inline">
                                                                            <input class="form-check-input" type="radio"
                                                                                name="physical_product_condition"
                                                                                value="refurbished"
                                                                                [(ngModel)]="product.physical_product_condition"
                                                                                #refurbished>
                                                                            <label class="form-check-label"
                                                                                for="inlineRadio2">refurbished</label>
                                                                        </div>
                                                                        <div class="form-check form-check-inline">
                                                                            <input class="form-check-input" type="radio"
                                                                                name="physical_product_condition"
                                                                                value="used"
                                                                                [(ngModel)]="product.physical_product_condition"
                                                                                #used>
                                                                            <label class="form-check-label"
                                                                                for="inlineRadio2">used</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="my-4 row">
                                                                <!-- catagory -->
                                                                <div class="col-lg-2 col-6">
                                                                    <div class="form-group productInfo">
                                                                        <label class="title"
                                                                            style="z-index: 2;">Catagory</label>
                                                                        <div ngbDropdown class="d-inline-block w-100"
                                                                            #CatDrop="ngbDropdown">
                                                                            <button
                                                                                style="overflow-x: scroll; overflow-y: hidden;"
                                                                                class="btn py-2 px-4 btn-block d-flex justify-content-between form-control"
                                                                                type="button" ngbDropdownToggle>
                                                                                <div>
                                                                                    <span
                                                                                        *ngIf="product.product_main_category">
                                                                                        {{product.product_main_category}}</span>
                                                                                    <span
                                                                                        *ngIf="product.product_sub_main_category"><i
                                                                                            class="fas fa-angle-double-right m-1"></i>
                                                                                        {{product.product_sub_main_category}}</span>
                                                                                    <span
                                                                                        *ngIf="product.product_sub_category"><i
                                                                                            class="fas fa-angle-double-right m-1"></i>{{product.product_sub_category}}</span>
                                                                                </div>
                                                                            </button>
                                                                            <div ngbDropdownMenu
                                                                                aria-labelledby="dropdownBasic1"
                                                                                style="height: 500px; overflow-y: scroll; width: 400px;">
                                                                                <div
                                                                                    class="bg-success p-2 text-center ">
                                                                                    <a routerLink="/create-new-catagory"
                                                                                        class="text-white"
                                                                                        routerLinkActive="active-link"
                                                                                        target="_blank"> Create new
                                                                                        catagory</a>
                                                                                </div>
                                                                                <div class="input-group p-2">
                                                                                    <div class="input-group-prepend"
                                                                                        style="width: 15%;">
                                                                                        <span
                                                                                            class="input-group-text border-black rounded-0 bg-transparent border-right-0">
                                                                                            <i
                                                                                                class="fas fa-search"></i></span>
                                                                                    </div>
                                                                                    <input type="text" class="search"
                                                                                        ngModel name="searchCatagory"
                                                                                        #searchCatagory>
                                                                                </div>
                                                                                <ul class="list-group list-group-flush">
                                                                                    <li class="list-group-item border-0"
                                                                                        *ngFor="let item of catagories; index as i">
                                                                                        <div class="d-flex">
                                                                                            <label
                                                                                                class="contain mb-0">{{item.category_name}}
                                                                                                <input type="radio"
                                                                                                    [value]="item._id"
                                                                                                    (click)="selectCat(item,'','',CatDrop,product)"
                                                                                                    class="form-check-input m-2"
                                                                                                    name="product_main_category_id"
                                                                                                    [(ngModel)]="product.product_main_category_id">
                                                                                                <span
                                                                                                    class="checkmark border"></span>
                                                                                            </label>
                                                                                            <button class="btn p-0 ml-4"
                                                                                                type="button"
                                                                                                *ngIf="item.main_sub_category"
                                                                                                (click)="Showsubcat(item)">
                                                                                                <i
                                                                                                    [class]="!item.click? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                                            </button>
                                                                                        </div>
                                                                                        <ul class="list-group list-group-flush ml-4"
                                                                                            *ngIf="item.click">
                                                                                            <li class="list-group-item border-0"
                                                                                                *ngFor="let val of item.main_sub_category; index as j">
                                                                                                <div class="d-flex">
                                                                                                    <label
                                                                                                        class="contain mb-0">{{val.category_name}}
                                                                                                        <input
                                                                                                            type="radio"
                                                                                                            [value]="val._id"
                                                                                                            (click)="selectCat(item,val,'',CatDrop,product)"
                                                                                                            [(ngModel)]="product.product_sub_main_category_id"
                                                                                                            class="form-check-input m-2"
                                                                                                            name="product_sub_main_category_id">
                                                                                                        <span
                                                                                                            class="checkmark border"></span>
                                                                                                    </label>
                                                                                                    <button
                                                                                                        class="btn p-0 ml-4"
                                                                                                        type="button"
                                                                                                        *ngIf="val.sub_category.length > 0"
                                                                                                        (click)="Showsubcat(val)">
                                                                                                        <i
                                                                                                            [class]="!val.click? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                                                    </button>
                                                                                                </div>
                                                                                                <ul class="list-group list-group-flush ml-4"
                                                                                                    *ngIf="val.click">
                                                                                                    <li class="list-group-item border-0"
                                                                                                        *ngFor="let v of val.sub_category; index as m">
                                                                                                        <div
                                                                                                            class="d-flex">
                                                                                                            <label
                                                                                                                class="contain mb-0">{{v.category_name}}
                                                                                                                <input
                                                                                                                    type="radio"
                                                                                                                    [value]="v._id"
                                                                                                                    (click)="selectCat(item,val,v,CatDrop,product)"
                                                                                                                    class="form-check-input m-2"
                                                                                                                    [(ngModel)]="product.category_id"
                                                                                                                    name="category_id">
                                                                                                                <span
                                                                                                                    class="checkmark border"></span>
                                                                                                            </label>
                                                                                                            <button
                                                                                                                class="btn p-0 ml-4"
                                                                                                                type="button"
                                                                                                                *ngIf="v.sub_category">
                                                                                                                <i
                                                                                                                    [class]="v.sub_category? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!-- type of product -->
                                                                <div class="col-lg-2 col-6">
                                                                    <div class="form-group productInfo">
                                                                        <label class="title" style="z-index: 2;">Type of
                                                                            product</label>
                                                                        <div ngbDropdown class="d-inline-block w-100">
                                                                            <button
                                                                                class="btn d-flex justify-content-between form-control bg-white border"
                                                                                type="button" ngbDropdownToggle>
                                                                                <span>{{product.type_of_product}}</span>
                                                                            </button>
                                                                            <div ngbDropdownMenu
                                                                                aria-labelledby="dropdownBasic1">
                                                                                <div
                                                                                    class="bg-success p-2 text-center ">
                                                                                    <a routerLink="/brands"
                                                                                        class="text-white"
                                                                                        routerLinkActive="active-link">
                                                                                        Create new Brand</a>
                                                                                </div>
                                                                                <div class="input-group p-2">
                                                                                    <div class="input-group-prepend"
                                                                                        style="width: 12%;">
                                                                                        <span
                                                                                            class="input-group-text border-black rounded-0 bg-transparent border-right-0">
                                                                                            <i
                                                                                                class="fas fa-search"></i></span>
                                                                                    </div>
                                                                                    <input type="text" class="search"
                                                                                        ngModel name="searchCatagory"
                                                                                        #searchCatagory>
                                                                                </div>
                                                                                <ul class="list-group list-group-flush">
                                                                                    <li class="list-group-item border-0"
                                                                                        *ngFor="let item of type_of_productList; index as i">
                                                                                        <div class="d-flex">
                                                                                            <div class="d-flex">
                                                                                                <label
                                                                                                    class="contain">{{item.type_of_product_sub}}
                                                                                                    <input type="radio"
                                                                                                        [value]="item.type_of_product_sub"
                                                                                                        [(ngModel)]="product.type_of_product"
                                                                                                        (click)="onType(item,product)"
                                                                                                        class="shorter"
                                                                                                        name="type_of_product">
                                                                                                    <span
                                                                                                        class="checkmark border"></span>
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!-- Brand -->
                                                                <div class="col-lg-2 col-6">
                                                                    <div class="form-group productInfo">
                                                                        <label class="title"
                                                                            style="z-index: 2;">Brand</label>
                                                                        <div ngbDropdown class="d-inline-block w-100"
                                                                            #brandDrop="ngbDropdown">
                                                                            <button
                                                                                class="btn d-flex justify-content-between form-control bg-white border"
                                                                                type="button" ngbDropdownToggle>
                                                                                <span>{{product.brand_name}}</span>
                                                                            </button>
                                                                            <div ngbDropdownMenu
                                                                                aria-labelledby="dropdownBasic1">
                                                                                <div
                                                                                    class="bg-success p-2 text-center ">
                                                                                    <a routerLink="/brands"
                                                                                        class="text-white"
                                                                                        routerLinkActive="active-link"
                                                                                        target="_blank"> Create new
                                                                                        Brand</a>
                                                                                </div>
                                                                                <div class="input-group"
                                                                                    *ngIf="brand.length > 0">
                                                                                    <div class="input-group p-2">
                                                                                        <div class="input-group-prepend"
                                                                                            style="width: 15%;">
                                                                                            <span
                                                                                                class="input-group-text border-black rounded-0 bg-transparent border-right-0">
                                                                                                <i
                                                                                                    class="fas fa-search"></i></span>
                                                                                        </div>
                                                                                        <input type="text"
                                                                                            class="search" ngModel
                                                                                            name="searchCatagory"
                                                                                            #searchCatagory>
                                                                                    </div>
                                                                                </div>
                                                                                <ul class="list-group list-group-flush">
                                                                                    <li class="list-group-item border-0"
                                                                                        *ngFor="let item of brand">
                                                                                        <div class="d-flex">
                                                                                            <label
                                                                                                class="contain">{{item.brand_name}}
                                                                                                <input type="radio"
                                                                                                    [value]="item._id"
                                                                                                    (ngModelChange)="product.brand_name = item.brand_name"
                                                                                                    [(ngModel)]="product.brand"
                                                                                                    class="shorter"
                                                                                                    name="brand">
                                                                                                <span
                                                                                                    class="checkmark border"></span>
                                                                                            </label>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!-- gst -->
                                                                <div class="col-lg-2">
                                                                    <label class="title">HsnCode</label>
                                                                    <select class="form-control" name="gst"
                                                                        [(ngModel)]="product.gst">
                                                                        <option [value]="gst._id"
                                                                            *ngFor="let gst of gst_list">
                                                                            {{gst.store_hsn_code}}({{gst.store_description_of_goods}})
                                                                        </option>
                                                                    </select>
                                                                </div>

                                                                <!-- product name  -->
                                                                <div class="col-lg-4">
                                                                    <div class="form-group productInfo">
                                                                        <label class="title">*Product Name</label>
                                                                        <input type="text" class="form-control"
                                                                            [(ngModel)]="product.product_name"
                                                                            name="product_name">
                                                                    </div>
                                                                </div>

                                                                <div class="col-12">
                                                                    <div class="row">
                                                                        <!-- price you Want form buyer -->
                                                                        <div class="col">
                                                                            <div class="form-group productInfo">
                                                                                <label class="title">*Price</label>
                                                                                <div class="">
                                                                                    <input type="text"
                                                                                        class="form-control"
                                                                                        (keyup)="price(product)"
                                                                                        [(ngModel)]="product.price_from_buyer"
                                                                                        name="price_from_buyer"
                                                                                        #PriceUWantFromBuyerSingle>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <!-- discount shown to buyer  -->
                                                                        <div class="col">
                                                                            <div class="form-group productInfo">
                                                                                <label class="title">*Discount</label>
                                                                                <div>
                                                                                    <input type="text"
                                                                                        class="form-control"
                                                                                        name="price_discount_from_buyer"
                                                                                        [(ngModel)]="product.price_discount_from_buyer"
                                                                                        #DiscountToShownToBuyerSingle
                                                                                        (keyup)="discount(product)">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <!-- MRP price -->
                                                                        <div class="col">
                                                                            <div class="form-group productInfo">
                                                                                <label class="title">*MRP</label>
                                                                                <div>
                                                                                    <input type="text"
                                                                                        class="form-control"
                                                                                        name="totalPrice"
                                                                                        [(ngModel)]="product.mrp"
                                                                                        #totalPrice
                                                                                        (keyup)="totalprice(product)">
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <!-- product cost price  -->
                                                                        <div class="col">
                                                                            <div class="form-group productInfo">
                                                                                <label class="title">Product Cost
                                                                                    Price</label>
                                                                                <div>
                                                                                    <input type="text"
                                                                                        class="form-control"
                                                                                        name="product_cost_price"
                                                                                        #product_cost_price
                                                                                        [(ngModel)]="product.product_cost_price"
                                                                                        (keyup)="Pofit(product)">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <!-- margin  -->
                                                                        <div class="col">
                                                                            <div class="form-group productInfo">
                                                                                <label class="title">Margin</label>
                                                                                <div class="form-control">
                                                                                    {{product.margin}}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <!-- profit  -->
                                                                        <div class="col">
                                                                            <div class="form-group productInfo">
                                                                                <label class="title">Profit</label>
                                                                                <div class="form-control">
                                                                                    {{product.profit}}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!-- price shown to buyer  -->
                                                                <div class="col-lg-6">
                                                                    <div class="form-group productInfo border border-dark d-flex justify-content-between p-1"
                                                                        style="height: 45px;">
                                                                        <p class="font-weight-bold my-auto text-size">
                                                                            Price Shown To Buyer</p>
                                                                        <p class="m-auto text-size"
                                                                            style="text-decoration:line-through;"
                                                                            *ngIf="product.mrp">
                                                                            Rs.{{product.mrp}}</p>
                                                                        <p class="m-auto text-size"
                                                                            *ngIf="product.price_from_buyer">
                                                                            Rs.{{product.price_from_buyer}}<span
                                                                                class="text-danger"
                                                                                *ngIf="product.price_discount_from_buyer">
                                                                                ({{product.price_discount_from_buyer}}%
                                                                                off)</span></p>
                                                                        <button
                                                                            class="btn btn-sm btn-success text-size mx-2">Rs.99(10%)</button>
                                                                        <button
                                                                            class="btn btn-success text-size mx-2">Rs.109(10%)</button>
                                                                        <button
                                                                            class="btn btn-success text-size mx-2">Rs.110(10%)</button>
                                                                    </div>
                                                                </div>

                                                                <!-- quantity in stock  -->
                                                                <div class="col-lg-2 col-6">
                                                                    <div class="form-group productInfo">
                                                                        <label class="title">*Quantity in Stock</label>
                                                                        <div
                                                                            class="form-control d-flex justify-content-between">
                                                                            <div class="w-50">
                                                                                <input type="number"
                                                                                    class="w-100 border-0 p-0"
                                                                                    *ngIf="!product.quantity_in_stock_unlimited"
                                                                                    min="0"
                                                                                    [(ngModel)]="product.quantity_in_stock"
                                                                                    name="quantity_in_stock"
                                                                                    #quantity_in_stock
                                                                                    (keyup)="quantityInStock(product)">
                                                                            </div>
                                                                            <div class="w-50">
                                                                                <input type="checkbox" class="mx-2"
                                                                                    [(ngModel)]="product.quantity_in_stock_unlimited"
                                                                                    name="quantity_in_stock_unlimited"
                                                                                    #unlimitedSingle>
                                                                                <label>Unlimited</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!-- avaliable shop upto  -->
                                                                <div class="col-lg-2 col-6">
                                                                    <div class="productInfo">
                                                                        <label class="title">Avaliable to shop up
                                                                            to</label>
                                                                        <input class="form-control w-100" type="date"
                                                                            [min]="today | date:'yy-MM-dd'"
                                                                            name="available_to_shop_upto"
                                                                            [(ngModel)]="product.available_to_shop_upto"
                                                                            #available_to_shop_upto>
                                                                    </div>
                                                                </div>

                                                                <!-- pre Order -->
                                                                <div class="col-lg-2 col-6">
                                                                    <div class="form-group productInfo">
                                                                        <label class="title">Pre Order</label>
                                                                        <div class="form-control">
                                                                            <div class="form-check ">
                                                                                <label class="mr-4 my-1">Pre
                                                                                    Order</label>
                                                                                <input type="checkbox" name="pre_order"
                                                                                    #pre_order
                                                                                    [(ngModel)]="product.pre_order">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!-- description  -->
                                                                <div class="col-lg-6">
                                                                    <div class="form-group ">
                                                                        <label
                                                                            class="title">Description(Optional)</label>
                                                                        <textarea class="form-control" rows="4"
                                                                            [(ngModel)]="product.product_description"
                                                                            name="product_description"></textarea>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="row">

                                                                        <!-- expected launch date -->
                                                                        <div class="col-lg-4 col-6">
                                                                            <div class="productInfo">
                                                                                <label class="title">Expected Launch
                                                                                    Date</label>
                                                                                <input class="form-control w-100"
                                                                                    type="date"
                                                                                    [min]="today | date:'yy-MM-dd'"
                                                                                    [disabled]="!product.pre_order"
                                                                                    [(ngModel)]="product.pre_order_launch_date"
                                                                                    name="pre_order_launch_date">
                                                                            </div>
                                                                        </div>

                                                                        <!-- Sku -->
                                                                        <div class="col-lg-4"
                                                                            *ngIf="!sku_weight_mesurement.store_generate_auto">
                                                                            <div class="form-group productInfo">
                                                                                <label class="title">*SKU</label>
                                                                                <div class="input-group"
                                                                                    *ngIf="sku_weight_mesurement.store_sku_prefix">
                                                                                    <div class="input-group-prepend">
                                                                                        <div
                                                                                            class="input-group-text rounded-0">
                                                                                            {{sku_weight_mesurement.store_sku_prefix}}
                                                                                        </div>
                                                                                    </div>
                                                                                    <input type="text"
                                                                                        class="form-control"
                                                                                        [(ngModel)]="product.sku"
                                                                                        name="SKUSingle"
                                                                                        #SKUSingle="ngModel"
                                                                                        (keyup)="sku(product)">
                                                                                </div>
                                                                                <input type="text"
                                                                                    *ngIf="!sku_weight_mesurement.store_sku_prefix"
                                                                                    class="form-control"
                                                                                    [(ngModel)]="product.sku"
                                                                                    name="SKUSingle"
                                                                                    #SKUSingle="ngModel"
                                                                                    (keyup)="sku(product)">
                                                                            </div>
                                                                            <div *ngIf="product.sku_valid.status"
                                                                                class="p-2 btn" style="position: absolute;
                                                                                 z-index: 250;
                                                                                 width: 100%;
                                                                                 bottom: -10px;
                                                                                 left: 0;"
                                                                                [class.btn-success]="product.sku_valid.status === 'success'"
                                                                                [class.btn-danger]="product.sku_valid.status === 'fail'">
                                                                                <small>{{product.sku_valid.message}}</small>
                                                                            </div>
                                                                        </div>

                                                                        <!-- barcode no -->
                                                                        <div class="col-lg-4 col-2">
                                                                            <div class="form-group productInfo">
                                                                                <label class="title">Barcode No</label>
                                                                                <div>
                                                                                    <input type="text"
                                                                                        class="form-control"
                                                                                        [(ngModel)]="product.barcode_number"
                                                                                        name="barcode_number"
                                                                                        #barcode_number
                                                                                        (keyup)="barcodeNo(product)">
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <!-- source Vendor -->
                                                                        <div class="col-lg-4 col-6">
                                                                            <div class="form-group productInfo">
                                                                                <label class="title"
                                                                                    style="z-index: 2;">Source
                                                                                    Vendor</label>
                                                                                <div ngbDropdown
                                                                                    class="d-inline-block w-100">
                                                                                    <button
                                                                                        class="btn d-flex justify-content-between form-control bg-white border"
                                                                                        type="button" ngbDropdownToggle>
                                                                                        <span>{{product.source_vendor}}</span>
                                                                                    </button>
                                                                                    <div ngbDropdownMenu
                                                                                        aria-labelledby="dropdownBasic1">
                                                                                        <div
                                                                                            class="bg-success p-2 text-center ">
                                                                                            <a routerLink="/add-vendor"
                                                                                                class="text-white"
                                                                                                routerLinkActive="active-link">
                                                                                                Add Vendor</a>
                                                                                        </div>
                                                                                        <div class="input-group p-2">
                                                                                            <div class="input-group-prepend"
                                                                                                style="width: 12%;">
                                                                                                <span
                                                                                                    class="input-group-text border-black rounded-0 bg-transparent border-right-0">
                                                                                                    <i
                                                                                                        class="fas fa-search"></i></span>
                                                                                            </div>
                                                                                            <input type="text"
                                                                                                class="search" ngModel
                                                                                                name="searchCatagory"
                                                                                                #searchCatagory>
                                                                                        </div>
                                                                                        <div class="">
                                                                                            <ul
                                                                                                class="list-group list-group-flush">
                                                                                                <li class="list-group-item border-0"
                                                                                                    *ngFor="let item of vendorList; index as i">
                                                                                                    <div class="d-flex">
                                                                                                        <div
                                                                                                            class="d-flex">
                                                                                                            <label
                                                                                                                class="contain">{{item.primary_contact}}
                                                                                                                <input
                                                                                                                    type="radio"
                                                                                                                    [value]="item.primary_contact"
                                                                                                                    [(ngModel)]="product.source_vendor"
                                                                                                                    class="shorter"
                                                                                                                    name="primary_contact">
                                                                                                                <span
                                                                                                                    class="checkmark border"></span>
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <!-- refference Sku -->
                                                                        <div class="col-lg-4">
                                                                            <div class="form-group productInfo">
                                                                                <label class="title">Refference
                                                                                    SKU</label>
                                                                                <input type="text" class="form-control"
                                                                                    [(ngModel)]="product.ref_sku"
                                                                                    name="ref_sku">
                                                                            </div>
                                                                        </div>

                                                                        <!-- country origin  -->
                                                                        <div class="col-lg-4">
                                                                            <div class="form-group productInfo">
                                                                                <label class="title">*Country origin
                                                                                    name</label>
                                                                                <input type="text" class="form-control"
                                                                                    [(ngModel)]="product.country_origin_name"
                                                                                    name="country_origin"
                                                                                    #countryOrigin>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!-- add video URL -->
                                                                <div class="col-lg-4">
                                                                    <div class="form-group productInfo">
                                                                        <label class="title">Add Video URL</label>
                                                                        <input type="text" class="form-control"
                                                                            name="product_video_link"
                                                                            [(ngModel)]="product.product_video_link">
                                                                    </div>
                                                                </div>

                                                                <div class="col-2">
                                                                    <div class="productInfo">
                                                                        <button
                                                                            class="btn form-control d-flex justify-content-between border"
                                                                            (click)="modalService.open(attributecontent)">
                                                                            <span>Specifications</span>
                                                                            <span class="ml-4"
                                                                                style="font-size: 24px;"><i
                                                                                    class="fas fa-expand"></i></span>
                                                                        </button>
                                                                        <ng-template #attributecontent let-modal>
                                                                            <div class="modal-header">
                                                                                <h4 class="modal-title"
                                                                                    id="modal-basic-title">Specification
                                                                                    List</h4>
                                                                                <button type="button" class="close"
                                                                                    aria-label="Close"
                                                                                    (click)="modal.dismiss('Cross click')">
                                                                                    <span
                                                                                        aria-hidden="true">&times;</span>
                                                                                </button>
                                                                            </div>
                                                                            <div class="modal-body">
                                                                                <div class="d-flex">
                                                                                    <ul ngbNav #nav="ngbNav"
                                                                                        class="nav-pills"
                                                                                        orientation="vertical">
                                                                                        <li ngbNavItem
                                                                                            *ngFor="let attribute of product.attributeList; index as i">
                                                                                            <a
                                                                                                ngbNavLink>{{attribute.type_of_product_attribute_name}}</a>
                                                                                            <ng-template ngbNavContent>
                                                                                                <div class="">
                                                                                                    <ul
                                                                                                        class="list-group list-group-flush">
                                                                                                        <li class="list-group-item border-0"
                                                                                                            *ngFor="let attr of attribute.type_of_product_attribute_values; index as a">
                                                                                                            <div
                                                                                                                class="d-flex">
                                                                                                                <label
                                                                                                                    class="custom">{{attr.type_of_product_value}}
                                                                                                                    <input
                                                                                                                        type="checkbox"
                                                                                                                        (click)="productAttribute(attribute,attr,product,$event)"
                                                                                                                        ngModel
                                                                                                                        class="form-check-input m-2"
                                                                                                                        name="attribute{{a}}">
                                                                                                                    <span
                                                                                                                        class="checkmark"></span>
                                                                                                                </label>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </ng-template>
                                                                                        </li>
                                                                                    </ul>
                                                                                    <div [ngbNavOutlet]="nav"
                                                                                        class="ml-4"></div>
                                                                                </div>
                                                                            </div>
                                                                        </ng-template>
                                                                    </div>
                                                                </div>

                                                                <!-- gift wrap -->
                                                                <div class="col-4">
                                                                    <div class="row">
                                                                        <div class="col-8">
                                                                            <div class="productInfo">
                                                                                <div
                                                                                    class="form-control d-flex justify-content-between">
                                                                                    <div class="my-auto ">Gift Wrap
                                                                                    </div>
                                                                                    <div
                                                                                        class="form-check form-check-inline mr-0">
                                                                                        <label
                                                                                            class="form-check-label mx-1">Yes
                                                                                        </label>
                                                                                        <input
                                                                                            class="form-check-input mx-1"
                                                                                            type="radio"
                                                                                            name="gift_wrap" value=true
                                                                                            [(ngModel)]="product.gift_wrap">
                                                                                    </div>
                                                                                    <div
                                                                                        class="form-check form-check-inline mr-0">
                                                                                        <label
                                                                                            class="form-check-label mx-1">No</label>
                                                                                        <input
                                                                                            class="form-check-input mx-1"
                                                                                            type="radio"
                                                                                            name="gift_wrap" value=false
                                                                                            [(ngModel)]="product.gift_wrap">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-4 col-4">
                                                                            <div class="form-group productInfo">
                                                                                <label class="title mx-0">Wrapping
                                                                                    Price</label>
                                                                                <input type="text" class="form-control"
                                                                                    name="gift_wrap_price"
                                                                                    [readonly]="!product.gift_wrap || product.gift_wrap ==='false'"
                                                                                    #gift_wrap
                                                                                    [(ngModel)]="product.gift_wrap_price">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!-- weight -->
                                                                <div class="col-lg-2 col-3">
                                                                    <div class="form-group productInfo ">
                                                                        <label class="title">Product Weight</label>
                                                                        <div class="input-group">
                                                                            <input type="number" class="form-control"
                                                                                [(ngModel)]="product.product_weight"
                                                                                name="product_weight">
                                                                            <div class="input-group-append">
                                                                                <div class="input-group-text rounded-0">
                                                                                    {{sku_weight_mesurement.store_weight_mesurements}}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!-- free shipping -->
                                                                <!-- <div class="col-lg-2 col-6">
                                                                    <div class="form-group productInfo">
                                                                        <label class="title">Free Shipping</label>
                                                                        <div class="form-control">
                                                                            <div class="form-check ">
                                                                                <label class="mr-4 my-1">Free Shipping</label>
                                                                                <input type="checkbox" name="freeShipping" #freeShipping
                                                                                    [(ngModel)]="product.freeShipping">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> -->


                                                                <div class="col-6"
                                                                    *ngIf="product.product_attribute.length > 0">
                                                                    <div class="productInfo row form-group"
                                                                        *ngFor="let item of product.product_attribute;index as i">
                                                                        <!-- product attribute -->
                                                                        <div class="col-lg-5 col-6">
                                                                            <label class="title"
                                                                                style="z-index: 2;">Product
                                                                                Attribute</label>
                                                                            <div class="form-control"
                                                                                style="height: 45px;">
                                                                                <span>{{item.attribute_name}}</span>
                                                                            </div>
                                                                        </div>

                                                                        <!-- product attributeValue -->
                                                                        <div class="col-lg-6 col-6">
                                                                            <label class="title"
                                                                                style="z-index: 2;">Attribute
                                                                                Value</label>
                                                                            <div class="form-control"
                                                                                style="height: 45px;">
                                                                                <span>{{item.attribute_description}}</span>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-1 m-auto">
                                                                            <button class="btn bg-white w-100 p-0"
                                                                                type="button"
                                                                                (click)="deleteVar(product.product_attribute,item)">
                                                                                <i class="fas fa-trash-alt"></i>
                                                                            </button>
                                                                            <!-- <button class="btn bg-white w-100 p-0 addDelete" *ngIf="!item.deleteButton"
                                                                                    type="button" (click)="addAttribute(product)">
                                                                                    <i class="fas fa-plus-circle"></i>
                                                                                </button> -->
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!-- variable list -->
                                                                <div class="col-6">
                                                                    <div class="productInfo row  form-group"
                                                                        *ngFor="let item of product.product_var;index as i">
                                                                        <div class="col-4">
                                                                            <label class="title">Variable Name</label>
                                                                            <input type="text" class="form-control"
                                                                                name="variable_name{{i}}"
                                                                                [(ngModel)]="item.variable_name">
                                                                        </div>
                                                                        <div class="col-6">
                                                                            <label class="title">Variable
                                                                                Description</label>
                                                                            <input type="text" class="form-control"
                                                                                name="variable_description{{i}}"
                                                                                [(ngModel)]="item.variable_description">
                                                                        </div>
                                                                        <div class="col-2 m-auto d-flex">
                                                                            <button type="button"
                                                                                class="btn bg-white w-100 p-0 addDelete"
                                                                                *ngIf="product.product_var.length > 1"
                                                                                (click)="deleteVar(product.product_var,i)">
                                                                                <i class="fas fa-trash-alt"></i>
                                                                            </button>
                                                                            <button type="button"
                                                                                class="btn bg-white w-100 p-0 addDelete"
                                                                                *ngIf="i === product.product_var.length-1"
                                                                                (click)="addVar(product.product_var,{variable_name: '', variable_description: ''})">
                                                                                <i class="fas fa-plus-circle"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- bulk price -->
                                                                <div class="col-6">
                                                                    <div class="row productInfo">
                                                                        <div class="col-2 text-center p-2">Bulk Price
                                                                        </div>
                                                                        <div class="col-10">
                                                                            <div class="row form-group"
                                                                                *ngFor="let item of product.product_bulk_price;index as i">
                                                                                <div class="col">
                                                                                    <label class="title mx-2">Min
                                                                                        Quality</label>
                                                                                    <input type="text"
                                                                                        class="form-control"
                                                                                        [(ngModel)]="item.min_quantity"
                                                                                        name="min_quantity{{i}}">
                                                                                </div>
                                                                                <div class="col">
                                                                                    <label class="title mx-2">Max
                                                                                        Quality</label>
                                                                                    <input type="text"
                                                                                        class="form-control"
                                                                                        [(ngModel)]="item.max_quantity"
                                                                                        name="max_quantity{{i}}">
                                                                                </div>
                                                                                <div class="col">
                                                                                    <label
                                                                                        class="title mx-2">Price</label>
                                                                                    <input type="text"
                                                                                        class="form-control" ngModel
                                                                                        [(ngModel)]="item.price"
                                                                                        name="price{{i}}">
                                                                                </div>
                                                                                <div class="col-2 m-auto d-flex">
                                                                                    <button type="button"
                                                                                        class="btn bg-white w-100 p-0 addDelete"
                                                                                        *ngIf="product.product_bulk_price.length > 1"
                                                                                        (click)="deleteVar(product.product_bulk_price,i)">
                                                                                        <i class="fas fa-trash-alt"></i>
                                                                                    </button>
                                                                                    <button type="button"
                                                                                        class="btn bg-white w-100 p-0 addDelete"
                                                                                        *ngIf="i === product.product_bulk_price.length-1"
                                                                                        (click)="addVar(product.product_bulk_price,{min_quantity: '', max_quantity: '', price:''})">
                                                                                        <i
                                                                                            class="fas fa-plus-circle"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!-- add varients -->
                                                                <div class="col-12">
                                                                    <ngb-tabset type="pills" class="add-varients">
                                                                        <ngb-tab title="Add Varients (optional)"
                                                                            [disabled]="true">
                                                                        </ngb-tab>
                                                                        <ngb-tab
                                                                            *ngFor="let varient of product.varientsforSingleProductCustomforMake;index as i">
                                                                            <ng-template ngbTabTitle>
                                                                                <div class="border text-center d-flex flex-column"
                                                                                    style="width:100px;height: 60px;">
                                                                                    <img [src]="varient.image"
                                                                                        class="mx-auto"
                                                                                        class="mx-auto my-1"
                                                                                        height="30px" width="30px"
                                                                                        alt="">
                                                                                    <small
                                                                                        class="text-center">{{varient.name}}</small>
                                                                                </div>
                                                                            </ng-template>
                                                                            <ng-template ngbTabContent>
                                                                                <div
                                                                                    *ngFor="let a of varient.everything; index as m">
                                                                                    <div class="row" *ngIf="a.sameShow">
                                                                                        <div
                                                                                            class="col-lg-9 col-12 form-group my-3">
                                                                                            <div class="title float-left"
                                                                                                style="top: 0px; left: -15px;">
                                                                                                {{m+1}}
                                                                                            </div>
                                                                                            <div class="productInfo border border-dark"
                                                                                                style="height: 70px;">
                                                                                                <div
                                                                                                    class="form-group d-flex w-75 title">
                                                                                                    <div class="flex-fill px-2"
                                                                                                        *ngFor="let v of a.details;index as r">
                                                                                                        <label
                                                                                                            class="title text-capitalize">{{v.name}}
                                                                                                            name</label>
                                                                                                        <select
                                                                                                            class="form-control"
                                                                                                            name={{v.name}}{{m}}
                                                                                                            [(ngModel)]="product.custom[i].variable_description[m].value"
                                                                                                            (change)="optionDisable(product.custom[i].variable_description,v.list)">
                                                                                                            <option
                                                                                                                *ngFor="let lt of v.list"
                                                                                                                [value]=[lt.varient_name]
                                                                                                                [disabled]="!lt.status">
                                                                                                                {{lt.varient_name}}
                                                                                                            </option>
                                                                                                        </select>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="title float-right"
                                                                                                style="top:0px; right: -25px;"
                                                                                                *ngIf="varient.everything.length > 1">
                                                                                                <button
                                                                                                    class="btn btn-outline-success p-1 rounded-0 addDelete"
                                                                                                    (click)="deleteVarients(varient,m,product.custom[i].variable_description)">
                                                                                                    <i
                                                                                                        class="fas fa-times-circle"></i>
                                                                                                </button>
                                                                                            </div>

                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 col-12 form-group my-auto">
                                                                                            <button
                                                                                                class="btn productInfo bg-dark text-white p-2 my-lg-4 rounded-0"
                                                                                                (click)="a.sameShow = !a.sameShow">
                                                                                                Use Different price &
                                                                                                quality
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="row"
                                                                                        *ngIf="!a.sameShow">
                                                                                        <div
                                                                                            class="col-lg-9 col-12 form-group my-3">
                                                                                            <div class="title float-left"
                                                                                                style="top: 0px; left: -15px;">
                                                                                                {{m+1}}
                                                                                            </div>
                                                                                            <div class=" productInfo  d-flex  flex-column border border-dark"
                                                                                                style="min-height: 100px;">
                                                                                                <div
                                                                                                    class="form-group d-flex w-75 title">
                                                                                                    <div class="flex-fill px-2"
                                                                                                        *ngFor="let v of a.details;index as r">
                                                                                                        <label
                                                                                                            class="title text-capitalize">{{v.name}}
                                                                                                            name</label>
                                                                                                        <select
                                                                                                            class="form-control"
                                                                                                            name={{v.name}}{{m}}
                                                                                                            [(ngModel)]="product.custom[i].variable_description[m].value">
                                                                                                            <option
                                                                                                                *ngFor="let lt of v.list"
                                                                                                                [value]=[lt.varient_name]>
                                                                                                                {{lt.varient_name}}
                                                                                                            </option>
                                                                                                        </select>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="row mt-5 px-4">

                                                                                                    <!-- price -->
                                                                                                    <div class="col-4 ">
                                                                                                        <div
                                                                                                            class="form-group productInfo">
                                                                                                            <label
                                                                                                                class="title">Price
                                                                                                                You
                                                                                                                Want
                                                                                                                Form
                                                                                                                Buyer</label>
                                                                                                            <div
                                                                                                                class="d-flex justify-content-between">
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    class="form-control "
                                                                                                                    [(ngModel)]="product.custom[i].variable_description[m].price"
                                                                                                                    name="priceWantFromBuyercustom{{m}}"
                                                                                                                    (keyup)="price(product.custom[i].variable_description[m])">
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>

                                                                                                    <!-- discount -->
                                                                                                    <div class="col-4 ">
                                                                                                        <div
                                                                                                            class="form-group productInfo">
                                                                                                            <label
                                                                                                                for="inputEmail4"
                                                                                                                class="title mx-4">Discount
                                                                                                                Percentage
                                                                                                                to show
                                                                                                                Buyer</label>
                                                                                                            <div
                                                                                                                class="d-flex justify-content-between">
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    class="form-control"
                                                                                                                    name="discountFromBuyercustom{{m}}"
                                                                                                                    [(ngModel)]="product.custom[i].variable_description[m].discount"
                                                                                                                    (keyup)="discount(product.custom[i].variable_description[m])">
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <!-- mrp rupees -->
                                                                                                    <div class="col-4 ">
                                                                                                        <div
                                                                                                            class="form-group productInfo">
                                                                                                            <label
                                                                                                                for="inputEmail4"
                                                                                                                class="title">MRP</label>
                                                                                                            <div
                                                                                                                class="form-control d-flex justify-content-between">
                                                                                                                <div
                                                                                                                    class="w-50 py-2">
                                                                                                                    <input
                                                                                                                        type="text"
                                                                                                                        class="w-100 border-0"
                                                                                                                        name="mrp{{m}}"
                                                                                                                        (keyup)="totalprice(product.custom[i].variable_description[m])"
                                                                                                                        [(ngModel)]="product.custom[i].variable_description[m].mrp">
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <!-- quantity in stock -->
                                                                                                    <div class="col-4 ">
                                                                                                        <div
                                                                                                            class="form-group productInfo">
                                                                                                            <label
                                                                                                                for="inputEmail4"
                                                                                                                class="title">Quantity
                                                                                                                in
                                                                                                                Stock</label>
                                                                                                            <div
                                                                                                                class="form-control d-flex justify-content-between">
                                                                                                                <div
                                                                                                                    class="w-50 py-2">
                                                                                                                    <input
                                                                                                                        type="text"
                                                                                                                        class="w-100 border-0"
                                                                                                                        name="quantitycustom{{m}}"
                                                                                                                        [(ngModel)]="product.custom[i].variable_description[m].quantity">
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <!-- barcode no -->
                                                                                                    <div
                                                                                                        class="col-lg-4 col-6">
                                                                                                        <div
                                                                                                            class="form-group productInfo">
                                                                                                            <label
                                                                                                                class="title">Barcode
                                                                                                                No</label>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    class="form-control"
                                                                                                                    name="BarcodeNoSinglecustom{{m}}"
                                                                                                                    [(ngModel)]="product.custom[i].variable_description[m].barcode"
                                                                                                                    #BarcodeNoSingle>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!-- Sku -->
                                                                                                    <div
                                                                                                        class="col-lg-4">
                                                                                                        <div
                                                                                                            class="form-group productInfo">
                                                                                                            <label
                                                                                                                class="title">SKU</label>
                                                                                                            <input
                                                                                                                type="text"
                                                                                                                class="form-control"
                                                                                                                [(ngModel)]="product.custom[i].variable_description[m].sku"
                                                                                                                name="SKUSinglecustom{{m}}"
                                                                                                                #SKUSingle{{m}}>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="col-12">
                                                                                                        <div class="form-group productInfo border border-dark d-flex justify-content-between p-1"
                                                                                                            style="height: 45px;">
                                                                                                            <p
                                                                                                                class="font-weight-bold m-auto text-size">
                                                                                                                Price
                                                                                                                Shown To
                                                                                                                Buyer
                                                                                                            </p>
                                                                                                            <p
                                                                                                                class="m-auto text-size previousOne">
                                                                                                                Rs.
                                                                                                                {{product.custom[i].variable_description[m].price}}
                                                                                                            </p>
                                                                                                            <p class="m-auto text-size"
                                                                                                                *ngIf="product.custom[i].variable_description[m].discount">
                                                                                                                Rs.
                                                                                                                {{product.custom[i].variable_description[m].mrp}}
                                                                                                                <span
                                                                                                                    class="text-danger">({{product.custom[i].variable_description[m].discount}}%
                                                                                                                    off)</span>
                                                                                                            </p>
                                                                                                            <button
                                                                                                                class="btn btn-sm btn-success text-size mx-2">Rs.99(10%)</button>
                                                                                                            <button
                                                                                                                class="btn btn-success text-size mx-2">Rs.109(10%)</button>
                                                                                                            <button
                                                                                                                class="btn btn-success text-size mx-2">Rs.110(10%)</button>
                                                                                                            <button
                                                                                                                class="btn btn-primary text-size rounded-0 mx-2">GST
                                                                                                                Applied</button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="title float-right"
                                                                                                    *ngIf="varient.everything.length > 1"
                                                                                                    style="top:0px; right: -25px;">
                                                                                                    <button
                                                                                                        class="btn btn-outline-success p-1 rounded-0 addDelete"
                                                                                                        (click)="deleteVarients(varient,m,product.custom[i].variable_description)">
                                                                                                        <i
                                                                                                            class="fas fa-times-circle"></i>
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 col-12 form-group m-auto">
                                                                                            <button
                                                                                                class="btn rounded-0 productInfo bg-dark text-white p-2 my-lg-5"
                                                                                                (click)="a.sameShow = !a.sameShow">
                                                                                                Use Same price & quality
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="d-flex col-3">
                                                                                    <p class="my-auto mx-3">Add
                                                                                        More(Optional)</p>
                                                                                    <button
                                                                                        class="btn p-0 border text-center d-flex flex-column"
                                                                                        style="width:100px;height: 60px;"
                                                                                        [disabled]="varient.disable"
                                                                                        (click)="addVarient(varient,product.custom[i].variable_description,product)">
                                                                                        <img [src]="varient.image"
                                                                                            class="mx-auto"
                                                                                            height="40px" alt="">
                                                                                        <small
                                                                                            class="m-auto">{{varient.name}}</small>
                                                                                    </button>
                                                                                </div>
                                                                            </ng-template>
                                                                        </ngb-tab>
                                                                    </ngb-tabset>
                                                                </div>
                                                                <div class="col-lg-3 col-12 my-3 text-center">
                                                                    <button class="btn btn-danger rounded-0"
                                                                        type="button"
                                                                        (click)="open(editToalProduct,product)">
                                                                        Save Product</button>
                                                                </div>
                                                                <ng-template #editToalProduct let-modal>
                                                                    <div class="modal-header">
                                                                        <button type="button" class="close"
                                                                            aria-label="Close"
                                                                            (click)="modal.dismiss('Cross click')">
                                                                            <span aria-hidden="true">&times;</span>
                                                                        </button>
                                                                    </div>
                                                                    <div class="modal-body">
                                                                        <p class="text-danger">
                                                                            {{manageProduct.updateMessage}}</p>
                                                                    </div>
                                                                    <div
                                                                        class="modal-footer border-0 d-flex justify-content-between">
                                                                        <button type="button" class="btn btn-danger"
                                                                            (click)="_handelEdit(product)">Update</button>
                                                                        <button type="button" class="btn btn-success"
                                                                            (click)="modal.close('Close click')">Close</button>
                                                                    </div>
                                                                </ng-template>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </ng-template>
                                            </ngb-tab>
                                            <ngb-tab>
                                                <ng-template ngbTabTitle class="button-bg">
                                                    <button class="btn button-bg">
                                                        <p class="font-weight-bold m-auto">COD</p>
                                                    </button>
                                                    <p class="font-weight-bold m-auto">COD</p>
                                                </ng-template>
                                            </ngb-tab>
                                            <ngb-tab>
                                                <ng-template ngbTabTitle class="button-bg">
                                                    <button class="btn button-bg">
                                                        <i class="fas fa-cut"></i>
                                                    </button>
                                                    <p class="font-weight-bold m-auto">Stich</p>
                                                </ng-template>
                                            </ngb-tab>
                                            <ngb-tab>
                                                <ng-template ngbTabTitle class="button-bg">
                                                    <button class="btn button-bg">
                                                        <i class="fa-external-link-alt fas"></i>
                                                    </button>
                                                    <p class="font-weight-bold m-auto">Price</p>
                                                </ng-template>
                                            </ngb-tab>
                                            <ngb-tab>
                                                <ng-template ngbTabTitle class="button-bg">
                                                    <button class="btn button-bg bg-danger"
                                                        (click)="modalService.open(smallcontent, { centered: true })">
                                                        <i class="far fa-trash-alt"></i>
                                                    </button>
                                                    <p class="font-weight-bold m-auto">Delete</p>
                                                </ng-template>
                                            </ngb-tab>
                                        </ngb-tabset>
                                        <ng-template #smallcontent let-modal>
                                            <div class="modal-header">
                                                <button type="button" class="close" aria-label="Close"
                                                    (click)="modal.dismiss('Cross click')">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <p>Are You surely want to delete this product</p>
                                            </div>
                                            <div class="modal-footer border-0 d-flex justify-content-between">
                                                <button type="button" class="btn btn-danger"
                                                    (click)="delete(product)">Yes</button>
                                                <button type="button" class="btn btn-success"
                                                    (click)="modal.close('Close click')">Close</button>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </ng-template>
                        </tr>
                    </tbody>
                </table>
            </ul>

            <div *ngIf="products.length === 0" style="margin: 10em; text-align: center;">
                <h1>No Products Found</h1>
            </div>
        </div>
        <div class="text-right" *ngIf="products.length !== 0 && products.length === 10">
            <a routerLink="/product-manage/{{pageLink}}/{{manageProduct.search}}/{{addMore}}"
                class="btn btn-success">Next</a>
        </div>
    </section>
</div>