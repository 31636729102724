<div class="upper">
    <div>
        <app-child1  id="child-1"></app-child1>
    </div>
    <div>
        <app-child2  id="child-2"></app-child2>
    </div>
    <div>
        <app-child3   id="child-3"></app-child3>
    </div>
    <div>
        <app-child4  id="child-4"></app-child4>
    </div>
</div>