import { NgForm } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { CompanyInfo } from './company-info';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { SkuWeight } from './sku-weight';
import { StoreTime } from './store-time';
@Component({
  selector: 'app-add-country',
  templateUrl: './add-country.component.html',
  styleUrls: ['./add-country.component.css']
})
export class AddCountryComponent implements OnInit {

  loader = true

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '200px',
    minHeight: '200px',
    maxHeight: '200px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'no',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

  activeId = 1

  company_info = new CompanyInfo('', '', [{
    unikid: Math.random().toString(36).substr(2, 5), address_type: '', address: '', buttons: [
      { name: "Home Office", active: false },
      { name: "Commercial Office", active: false },
      { name: "Retail", active: false },
      { name: "Warehouse", active: false }
    ]
  }], [{ email: '' }], [{ phone: '' }])
  store_information_list: any = [];

  skuWeight = new SkuWeight('', false, '', '', '', '', '', '', '')
  storeTime = new StoreTime({
    store_week_day_name: 'Monday', store_week_day_opening_type: '24 hours', store_time: [{ store_week_day_starts_from: '', store_week_day_ends_at: '' }]
  }, {
    store_week_day_name: 'Tuesday', store_week_day_opening_type: '24 hours', store_time: [{ store_week_day_starts_from: '', store_week_day_ends_at: '' }]
  }, {
    store_week_day_name: 'Wednesday', store_week_day_opening_type: '24 hours', store_time: [{ store_week_day_starts_from: '', store_week_day_ends_at: '' }]
  }, {
    store_week_day_name: 'Thursday', store_week_day_opening_type: '24 hours', store_time: [{ store_week_day_starts_from: '', store_week_day_ends_at: '' }]
  }, {
    store_week_day_name: 'Friday', store_week_day_opening_type: '24 hours', store_time: [{ store_week_day_starts_from: '', store_week_day_ends_at: '' }]
  }, {
    store_week_day_name: 'Saturday', store_week_day_opening_type: '24 hours', store_time: [{ store_week_day_starts_from: '', store_week_day_ends_at: '' }]
  }, {
    store_week_day_name: 'Sunday', store_week_day_opening_type: '24 hours', store_time: [{ store_week_day_starts_from: '', store_week_day_ends_at: '' }]
  })
  store_sku_and_weight_list: any = []
  store_pre_order_and_oos:any = {response: [{oos_product_display_in_shop: 'false', pre_order_product_display_in_shop: 'false', gift_wrap_available_in_shop: 'false'}]}
  shippingList: unknown[];


  addressType(button, value) {
    value.buttons.map(res => {
      if (res.name === button.name) {
        res.active = true
      } else {
        res.active = false
      }
    })
    value.address_type = button.name
  }

  addMoreAddess() {
    this.company_info.store_address.push({
      unikid: Math.random().toString(36).substr(2, 5), address_type: '', address: '', buttons: [
        { name: "Home Office", active: false },
        { name: "Commercial Office", active: false },
        { name: "Retail", active: false },
        { name: "Warehouse", active: false }
      ]
    })
  }


  delete(array, index) {
    array.splice(index, 1)
  }

  _handleNext() {
    this.activeId = this.activeId + 1
  }

  _handlePrevious() {
    this.activeId = this.activeId - 1
  }

  timeList = [
    { from: 1, to: 12 },
  ];


  _handleEdit(store_info, index) {
    this.store_information_list.splice(index, 1)
    this.company_info = store_info
    let result
    this.company_info.store_address.map((res) => {
      result = res
      result.buttons = [
        { name: "Home Office", active: false },
        { name: "Commercial Office", active: false },
        { name: "Retail", active: false },
        { name: "Warehouse", active: false }
      ]
      result.buttons.find((data) => {
        if (result.address_type === data.name) {
          data.active = true
        }
      })
    })
  }

  _edit(store_info, index, array) {
    this.skuWeight = store_info
    array.splice(index, 1)
  }

  _addMore(array){
    array.push({store_week_day_starts_from: "", store_week_day_ends_at: ""})
  }

  storeClose(e,day){
    if (!e.target.checked) {
      day.store_week_day_opening_type = 'closed'
    }else{
      this.api.get('store_timing_list').subscribe(
        data => {
          let storeTiming = Object.values(data)[2][0]
          for (const key in storeTiming) {
            if (storeTiming[key].store_week_day_name === day.store_week_day_name) {
              console.log(storeTiming[key].store_week_day_opening_type)
              day.store_week_day_opening_type = storeTiming[key].store_week_day_opening_type
            }
          }
        },
        error => console.log('Error!', error)
      )
    }
  }

  onSubmit(postEndpint, putEndpoint, body) {
    console.log(body)
    if (body._id) {
      this.api.put(putEndpoint, body).subscribe(
        data => {
          console.log('!success', data);
          window.location.reload()
        },
        error => console.log(error)
      )
    } else {
      this.api.post(postEndpint, body).subscribe(
        data => {
          console.log('!success', data);
          window.location.reload()
        },
        error => console.log(error)
      )
    }
  }

  onWeightSku(postEndpint, putEndpoint, body) {
    body= {...body, 
      store_sku_prefix : body.store_generate_auto ? '': body.store_sku_prefix,
      store_sku_manual_start_from : body.store_generate_auto ? '': body.store_sku_manual_start_from
    }
    console.log(body)

    if (body._id) {
      this.api.put(putEndpoint, body).subscribe(
        data => {
          console.log('!success', data);
          window.location.reload()
        },
        error => console.log(error)
      )
    } else {
      this.api.post(postEndpint, body).subscribe(
        data => {
          console.log('!success', data);
          window.location.reload()
        },
        error => console.log(error)
      )
    }
  }

  _handleDelete(store_info, endpoint) {
    this.api.post(endpoint, { "_id": store_info._id }).subscribe(
      data => {
        console.log('!success', data);
        if (data.status === 'success') {
          window.location.reload()
        }
      },
      error => console.log(error)
    )
  }

  _preOrderOof(){
    for (const key in this.store_pre_order_and_oos.response[0]) {
        if (this.store_pre_order_and_oos.response[0][key] === 'true') {
          this.store_pre_order_and_oos.response[0][key] = true
        }else if (this.store_pre_order_and_oos.response[0][key] === 'false') {
          this.store_pre_order_and_oos.response[0][key] = false
        }else{
          this.store_pre_order_and_oos.response[0][key] = this.store_pre_order_and_oos.response[0][key]
        }
    }
    this.api.put(`update_store_pre_order_and_oos`, this.store_pre_order_and_oos.response[0]).subscribe(
      data => {
        console.log('!success', data);
        if (data.status === 'success') {
          window.location.reload()
        }
      },
      error => console.log(error)
    )
  }


  constructor(private api: ApiService, public router: Router, private route: ActivatedRoute,) {
  }
  days;
  countries;
  times;
  ngOnInit(): void {
    this.loader = true
    this.api.getcountries(`../../../../assets/days.json`).subscribe(
      data => {
        this.days = Object.values(data);
        console.log(this.days)
      },
      error => console.log('Error!', error)
    )
    this.api.getcountries(`../../../../assets/time.json`).subscribe(
      data => {
        this.times = Object.values(data);
        console.log(this.times)
      },
      error => console.log('Error!', error)
    )
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = params.get('param')
      this.activeId = parseInt(id)
      //  this.api.get(`get_all_order/${id}`).subscribe(
      //    data => {
      //      this.orders = data
      //      let select = "select"
      //      this.orders.response.map((res) => {
      //        res[select] = false
      //      })
      //      console.log(this.orders.response)
      //    },
      //    error => console.log('Error!', error)
      //  )
    })
    this.api.get('store_information_list').subscribe(
      data => {
        this.store_information_list = Object.values(data)[2];
        if (this.store_information_list.length > 0) {
          this.company_info = this.store_information_list[0]
          console.log(this.company_info)
          let result
          this.company_info.store_address.map((res) => {
            result = res
            result.buttons = [
              { name: "Home Office", active: false },
              { name: "Commercial Office", active: false },
              { name: "Retail", active: false },
              { name: "Warehouse", active: false }
            ]
            result.buttons.find((data) => {
              if (result.address_type === data.name) {
                data.active = true
              }
            })
          })
        }
      },
      error => console.log('Error!', error)
    )
    this.api.get('store_sku_and_weight_list').subscribe(
      data => {
        this.store_sku_and_weight_list = Object.values(data)[2];
        if (Object.values(data)[2].length > 0) {
          this.skuWeight = this.store_sku_and_weight_list[0]
        }
      },
      error => console.log('Error!', error)
    )
    this.api.get('store_timing_list').subscribe(
      data => {
        if (Object.values(data)[2].length > 0) {
          this.storeTime = Object.values(data)[2][0]
        }
      },
      error => console.log('Error!', error)
    )
    this.api.get('store_pre_order_and_oos_list').subscribe(
      data => {
        this.store_pre_order_and_oos = data
        this.store_pre_order_and_oos.response[0].oos_product_display_in_shop = this.store_pre_order_and_oos.response[0].oos_product_display_in_shop.toString()
        this.store_pre_order_and_oos.response[0].gift_wrap_available_in_shop = this.store_pre_order_and_oos.response[0].gift_wrap_available_in_shop.toString()
        this.store_pre_order_and_oos.response[0].pre_order_product_display_in_shop = this.store_pre_order_and_oos.response[0].pre_order_product_display_in_shop.toString()
        console.log(this.store_pre_order_and_oos.response[0])
        // if (Object.values(data)[2].length > 0) {
        //   this.storeTime = Object.values(data)[2][0]
        // }
      },
      error => console.log('Error!', error)
    )
    this.api.get('shipping_list').subscribe(
      data => {
        console.log(data)
        let country = Object.values(data);
        this.shippingList = Object.values(country[2]);
        console.log(this.shippingList)
      },
      error => console.log('Error!', error)
    )
    setTimeout(() => {
      this.loader = false
    }, 3000);
  }
  //   this.api.countrySource$.subscribe(
  //     message => {
  //       this.countries = message;
  //     }
  //   )



}
