<div class="main">
    <section class="upper">
        <div>
            <div class="heading text-center">
                <h4 class="text-capitalize">Banner setting</h4>
            </div>
        </div>
        <div class="container-fluid">
            <div class="border border-success bg-white  py-4">
                <div class="position-absolute title" style="right: 30px;">
                    <button class="btn p-0 rounded-0 clean">
                        <i class="fas fa-times-circle"></i>
                    </button>
                </div>
                <div class="container">
                    <form (ngSubmit)="onSubmit(bannerForm)" #bannerForm="ngForm">
                        <div class="row">
                            <!-- <div class="col-8"> -->
                                <!-- <div class="row"> -->
                                    <div class="form-group productInfo col-3">
                                        <label class="title">Country</label>
                                        <select name="country" class="form-control" [(ngModel)]="bannerPage.country">
                                            <option value="{{country._id}}-{{country.selected_country_name}}"
                                                *ngFor="let country of countries">{{country.selected_country_name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group productInfo col-4">
                                        <label class="title">Banner Name</label>
                                        <input type="text" class="form-control" name="bannerName"
                                            [(ngModel)]="bannerPage.name" required>
                                    </div>
                                    <div class="form-group productInfo col-2">
                                        <input type="checkbox" name="animation" [(ngModel)]="bannerPage.animation"
                                            #animation>
                                        <label class="pl-2">Animation</label>
                                    </div>
                                    <!-- <div class="form-group col-2" *ngIf="animation.checked">
                                        <label class="title">Animation Time</label>
                                        <div class="input-group">
                                            <input type="number" class="form-control" name="animationTime"
                                                [(ngModel)]="bannerPage.animationtime">
                                            <div class="input-group-append">
                                                <span class="input-group-text rounded-0">ms</span>
                                            </div>
                                        </div>

                                    </div> -->
                                    <div class="form-group productInfo col-2">
                                        <label class="title"> Show Image</label>
                                        <input type="number" class="form-control" name="showImageNumber"
                                            [(ngModel)]="bannerPage.showImageNumber" required min="1" [max]="bannerPage.bannerImages">
                                    </div>
                                    <div class="col-12 form-group">
                                        <div class="border border-success p-2 d-flex flex-row">
                                            <div class="text-center bg-light p-3">
                                                <!-- <p class="m-2">1/20</p> -->
                                                <p class="m-2">Drops Carusal Image Here</p>
                                                <p class="m-2">(.PNG or .JPG)</p>
                                                <label
                                                    style="padding: 10px; background: rgb(41, 170, 41); display:inline-flex;color: #fff;">Upload
                                                    Images
                                                    <input type="file" style="display: none;" multiple accept="image/x-png,image/jpeg"
                                                        (change)="onUpload($event)"></label>
                                            </div>
                    
                                            <div class="d-flex flex-row h-auto">
                                                <div class="h-100 position-relative" *ngFor="let img of bannerPage.bannerImages;index as i" style="width: 150px;">
                                                    <div class="p-2 border d-inline-flex h-100">
                                                        <img [src]="img.image" alt="" width="100%" height="100%">
                                                        <button type="button" class="btn p-0 product-clean" (click)="deleteImg(img,bannerPage.bannerImages)">
                                                            <i class="fas fa-times-circle"></i>
                                                        </button>
                                                    </div>
                                                    <!-- <div>
                                                        <div class="form-check form-check-inline">
                                                            <input type="radio" name="coverimage{{i}}" [value]="img.coverImg"
                                                                class="form-check-input mx-1" [(ngModel)]="img.coverImg">
                                                            <label for="cover" class="form-check-label mx-1">Cover</label>
                                                        </div>
                                                    </div> -->
                                                </div>
                                            </div>
                    
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                    <!-- <div class="row"> -->
                                    <!-- <div class="form-group productInfo col-3">
                                        <label class="title">Banner Style</label>
                                        <select name="bannerStyle" class="form-control"
                                            [(ngModel)]="bannerPage.bannerStyle" required>
                                            <option value="rectangle">rectangle</option>
                                            <option value="circle">Circle</option>
                                        </select>
                                    </div> -->
                                <!-- </div> -->
                                <!-- <div ngbDropdown class="form-group w-100 d-inline-block">
                                    <label class="title">Apply On</label>
                                    <button
                                        class="btn btn-outline-green form-control w-100 d-flex justify-content-between"
                                        type="button" id="dropdownBasic1" ngbDropdownToggle>
                                        {{bannerPage.applyPage}}</button>
                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="width: 100%;">
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item border-0">
                                                <button class="btn p-0" type="button">Home Page</button>
                                            </li>
                                            <li class="list-group-item border-0">
                                                <button class="btn p-0" type="button"
                                                    (click)="open(content,'md')">catagory
                                                    <span class="material-icons">
                                                        arrow_drop_down
                                                    </span></button>
                                            </li>
                                            <ng-template #content let-modal>
                                                <div class="modal-header border-0">
                                                    <input type="text" class="form-control" name="search">
                                                    <button type="button" class="close" aria-label="Close"
                                                        (click)="modal.dismiss('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="">
                                                        <ul class="list-group list-group-flush">
                                                            <li class="list-group-item border-0"
                                                                *ngFor="let item of catagories; index as i">
                                                                <div class="d-flex">
                                                                    <label class="contain" *ngIf="item.button">
                                                                        <input type="radio" class="shorter">
                                                                        <span class="checkmark"></span>
                                                                    </label>
                                                                    <button class="btn p-0 ml-4" *ngIf="item.button"
                                                                        type="button" (click)="Showcat(i)">
                                                                        <img [src]="item.button? '../../../assets/img/icons/Icon awesome-plus-square.png':'../../../assets/img/icons/Icon feather-minus-square.png'"
                                                                            height="15px" alt="">
                                                                    </button>
                                                                    <label class="form-check-label mx-2"
                                                                        for="">{{item.catName}}</label>
                                                                </div>
                                                                <ul class="list-group list-group-flush ml-4"
                                                                    *ngIf="item.click">
                                                                    <li class="list-group-item border-0"
                                                                        *ngFor="let val of item.subcatagory; index as j">
                                                                        <div class="d-flex">
                                                                            <label class="contain">
                                                                                <input type="radio" class="shorter">
                                                                                <span class="checkmark"></span>
                                                                            </label>
                                                                            <button class="btn p-0 ml-4" type="button"
                                                                                (click)="Showsubcat(i,j)">
                                                                                <img [src]="val.button? '../../../assets/img/icons/Icon awesome-plus-square.png':'../../../assets/img/icons/Icon feather-minus-square.png'"
                                                                                    height="15px" alt="">
                                                                            </button>
                                                                            <label class="form-check-label mx-2"
                                                                                for="">{{val.catName}}</label>
                                                                        </div>
                                                                        <ul class="list-group list-group-flush ml-4"
                                                                            *ngIf="val.click">
                                                                            <li class="list-group-item border-0"
                                                                                *ngFor="let v of val.subcatagory; index as m">
                                                                                <div class="d-flex">
                                                                                    <label class="contain"
                                                                                        *ngIf="item.button">
                                                                                        <input type="radio"
                                                                                            class="shorter">
                                                                                        <span class="checkmark"></span>
                                                                                    </label>
                                                                                    <button class="btn p-0 ml-4"
                                                                                        type="button">
                                                                                        <img [src]="v.button? '../../../assets/img/icons/Icon awesome-plus-square.png':'../../../assets/img/icons/Icon feather-minus-square.png'"
                                                                                            height="15px" alt="">
                                                                                    </button>
                                                                                    <label class="form-check-label mx-2"
                                                                                        for="">{{v.name}}</label>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ul>
                                    </div>
                                </div> -->

                            <!-- </div> -->
                            

                            <div class="col-12 text-center">
                                <button type="submit" class="btn btn-success rounded-0">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <section class="container-fluid">
        <div>
            <ul class="list-group p-0">
                <li *ngFor="let item of bannerMenu; index as i">
                    <div class="d-flex">
                        <div class="my-auto d-flex justify-content-between" style="width:12%;">
                            <p class="m-auto text-capitalize">Image Banner</p>
                            <p class="m-auto"> {{i+1}}</p>
                        </div>
                        <div class="list-group-item m-4 w-100">
                            <div class="d-flex bd-highlight">
                                <h4 class="flex-grow-1">{{item.carosel_name}}</h4>
                                <button class="btn p-0 edit"><i class="fas fa-edit"></i></button>
                                <button class="btn p-0 delete" (click)="deletebanner(item)"><i class="fas fa-trash-alt"></i></button>
                                <button class="btn p-0 view" (click)="view(item)" [style.color]="item.status? 'green': 'rgb(190, 187, 187)' ">
                                    <i class="fas fa-eye" *ngIf="item.status"></i>
                                    <i class="fas fa-eye-slash" *ngIf="!item.status"></i>
                                </button>                          
                            </div>
                            <div class="row d-flex flex-wrap">
                                <div class="m-2" *ngFor="let img of item.carosel_image" style="width: 250px;">
                                    <div class="border border-success" style="height: 150px;">
                                        <img [src]="img.image" alt="img" class="h-100 w-100">
                                    </div>
                                    <button class="btn px-0 delete">
                                        <i class="fas fa-trash-alt"></i>
                                    </button>
                                </div>
                                <!-- <div class="col-3 m-auto d-flex justify-content-around">
                                    <button class="btn p-0">
                                        <img src="../../../../assets/img/icons/Icon awesome-edit-1.png" height="25px"
                                            alt="">
                                    </button>
                                    <button class="btn p-0">
                                        <img src="../../../../assets/img/icons/Icon material-delete-red-forever.png"
                                            height="25px" alt="">
                                    </button>
                                    <button class="btn p-0">
                                        <img src="../../../../assets/img/icons/Icon awesome-eye.png" height="25px"
                                            alt="">
                                    </button>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </section>

</div>