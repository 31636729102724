export class IconLogo {
    constructor(
        public country: String,
        public icon: any,
        public logo: any,
        public caption: String,
        public color: String,
        public size : String
    ){}
}
