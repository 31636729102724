import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {

  select = false

  action = false

  All = 0;

  pending_paymemnt = 0;

  processing = 0;

  completed = 0;

  cancelled = 0;

  orders: any = { response: '' }

  filterOptions = [{ name: 'All', value: ' ', active: false }, { name: 'Pending Payment', value: 'pending', active: false }, 
  { name: 'Processing', value: 'processing', active: false }, { name: 'Confirm', value: 'Confirm', active: false }, 
  { name: 'Cancel', value: 'cancel', active: false },{ name: 'Completed', value: 'completed', active: false }, 
  { name: 'Refund', value: 'refund', active: false }, { name: 'Failed', value: 'failed', active: false }]

  chooseAction = 'Bulk Actions'

  statuses = ['Bulk Actions', 'Pending Payment','Confirm', 'On hold','Delivered', 'Processing', 'Way To Delivered', 'Cancel']
  result: any = { response: '' }
  loader: boolean = true
  id1: string;
  id2: string;

  open(content) {
    this.modalService.open(content, { size: 'xl' })
  }

  statusChange() {
    if (this.chooseAction !== 'Bulk Actions') {
      this.orders.response.map((res) => {
        if (res.select === true) {
          this.api.put(`order_update`, { "_id": res._id, "status": this.chooseAction.toLowerCase()}).subscribe(
            data => {
              this.loader = true
              if (data.status === "success") {
                if (this.chooseAction === 'Confirm') {
                  res.order_history.map(result => {
                    this.api.post(`product_by_id`, { "product_id": result.product_id }).subscribe(
                      d => {
                        console.log(d)
                        if (!d.response.quantity_in_stock_unlimited) {
                          let body = { "product_id": result.product_id, 'quantity_in_stock': d.response.quantity_in_stock - result.product_quantity }
                          console.log(body)
                          this.api.put(`update_all_product`, { "_id": result.product_id, 'quantity_in_stock': d.response.quantity_in_stock - result.product_quantity }).subscribe(
                            data => {
                              console.log(data)
                              if (data.status === 'success') {
                                window.location.reload()
                              }
                            },
                            error => console.log('Error!', error)
                          )
                        }else{
                          window.location.reload()
                        }
                      },
                      error => console.log('Error!', error)
                    )
                  })
                }else{
                  window.location.reload()
                }
              }
            },
            error => console.log(error)
          )
        }
      })
    }
  }

  all() {
    if (this.orders.response.some((res) => res.select === true)) {
      this.action = false
    } else {
      this.action = true
    }
    if (this.select === true) {
      this.orders.response.map((res) => res.select = true)
    } else {
      this.orders.response.map((res) => res.select = false)
    }
  }

  selectOne() {
    if (this.orders.response.some((res) => res.select === true)) {
      this.action = true
    } else {
      this.action = false
    }
    if (this.orders.response.every((res) => res.select === true)) {
      this.select = true
    } else {
      this.select = false
    }
  }

  getOrder() {
    this.api.get(`get_all_order?page=${this.id2}&limit=10`).subscribe(
      data => {
        console.log(data)
        this.orders = data
        this.All = this.orders.response.length
        let select = "select"
        this.orders.response.map((res) => {
          res[select] = false
          switch (res.status) {
            case "Pending Payment":
              this.pending_paymemnt = this.pending_paymemnt + 1
              break;
            case "pending":
              this.processing = this.processing + 1
              break;
            case "Completed":
              this.completed = this.completed + 1
              break;

            default:
              break;
          }
        })
      },
      error => console.log('Error!', error)
    )
  }

  filter(param) {
    this.router.navigateByUrl(`order-list/${param.toLowerCase()}/1`);
  }

  edit(value) {
    console.log(value)
    this.api.put(`order_update`, value).subscribe(
      data => {
        console.log(data)
        this.chooseAction = 'Bulk Actions'
        this.getOrder()
        this.modalService.dismissAll()
      },
      error => console.log(error)
    )
  }

  pagecount = 0
  pagecountback = 0


  constructor(private api: ApiService, public router: Router, private route: ActivatedRoute, private modalService: NgbModal,) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id1 = params.get('param1')
      console.log(this.id1)
      this.id2 = params.get('param2')
      console.log(this.id2)
      this.pagecount = parseInt(this.id2)+1
      this.pagecountback = parseInt(this.id2)-1
      this.filterOptions.map(res => res.name.toLowerCase() === this.id1 ? res.active = true : res.active = false)
      if (this.id1 === 'all') {
        this.api.get(`get_all_order?page=${this.id2}&limit=10`).subscribe(
          data => {
            this.orders = data
            let select = "select"
            this.orders.response.map((res) => {
              res[select] = false
            })
            this.loader = false
            console.log(this.orders)
            console.log(Math.floor(this.orders.response.length/10))
          },
          error => console.log('Error!', error)
        )
      } else {
        this.api.get(`get_all_order/${this.id1}?page=${this.id2}&limit=10`).subscribe(
          data => {
            this.orders = data
            let select = "select"
            this.orders.response.map((res) => {
              res[select] = false
            })
            this.loader = false
            console.log(this.orders)
          },
          error => console.log('Error!', error)
        )
      }
    })
  }
}
