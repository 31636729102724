import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProductAddComponent } from './pages/admincomponent/product-add/product-add.component';
// import { AnotherpagesModule } from './anotherpages/anotherpages.module';
import { BrowserModule } from '@angular/platform-browser';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import {HttpClientModule} from '@angular/common/http'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { FormsModule } from '@angular/forms';
import { AdmincomponentComponent } from './pages/admincomponent/admincomponent.component';
import { ManageProductComponent } from './pages/admincomponent/manage-product/manage-product.component';
import { FetureProductSettingComponent } from './pages/admincomponent/feture-product-setting/feture-product-setting.component';
import { BannerSettingComponent } from './pages/admincomponent/banner-setting/banner-setting.component';
import { YoutubeChannelSettingsComponent } from './pages/admincomponent/youtube-channel-settings/youtube-channel-settings.component';
import { ExternalLinkComponent } from './pages/admincomponent/external-link/external-link.component';
import { NewCatagoryComponent } from './pages/admincomponent/new-catagory/new-catagory.component';
import { CookieService } from 'ngx-cookie-service';
import { DragDropModule} from '@angular/cdk/drag-drop';
import { StoreSettingsComponent } from './pages/admincomponent/store-settings/store-settings.component';
import { Child1Component } from './pages/admincomponent/store-settings/storeSettings/child1/child1.component';
import { Child2Component } from './pages/admincomponent/store-settings/storeSettings/child2/child2.component';
import { Child3Component } from './pages/admincomponent/store-settings/storeSettings/child3/child3.component';
import { Child4Component } from './pages/admincomponent/store-settings/storeSettings/child4/child4.component';
import { IconLogoCaptionComponent } from './pages/admincomponent/icon-logo-caption/icon-logo-caption.component';
import { CountryCurrencyComponent } from './pages/admincomponent/country-currency/country-currency.component';
import { PagesInfoComponent } from './pages/admincomponent/pages-info/pages-info.component';
import { SocialComponent } from './pages/admincomponent/social/social.component';
import { OffersComponent } from './pages/admincomponent/offers/offers.component';
import { AddCountryComponent } from './pages/admincomponent/add-country/add-country.component';
import { BrandsComponent } from './pages/admincomponent/brands/brands.component';
import { TopNavComponent } from './pages/admincomponent/top-nav/top-nav.component';
import { RollOnTopComponent } from './pages/admincomponent/roll-on-top/roll-on-top.component';
import { AuthenticProductComponent } from './pages/admincomponent/authentic-product/authentic-product.component';
import {NgxPhotoEditorModule} from "ngx-photo-editor";
import { ProductSettingComponent } from './pages/admincomponent/product-setting/product-setting.component';
import { ExtraListComponent } from './pages/admincomponent/extra-list/extra-list.component';
import { AddVendorComponent } from './pages/admincomponent/add-vendor/add-vendor.component';
import { InventoryComponent } from './pages/admincomponent/inventory/inventory.component';
import { CustomerComponent } from './pages/admincomponent/customer/customer.component';
import { CustomerGroupComponent } from './pages/admincomponent/customer-group/customer-group.component';
import { IndexPageDesignComponent } from './pages/admincomponent/index-page-design/index-page-design.component';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { OrdersComponent } from './pages/admincomponent/orders/orders.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TermsConditionComponent } from './pages/admincomponent/terms-condition/terms-condition.component';
import { ShippingComponent } from './pages/admincomponent/shipping/shipping.component';
import { CurrencyPipe } from './pages/admincomponent/pipe';
import { DelivaryGarruentyComponent } from './pages/admincomponent/delivary-garruenty/delivary-garruenty.component';
import { ReturnPolicyComponent } from './pages/admincomponent/return-policy/return-policy.component';
import { DelivaryGarruntyComponent } from './pages/admincomponent/delivary-garrunty/delivary-garrunty.component';
import { CashOnDelivaryComponent } from './pages/admincomponent/cash-on-delivary/cash-on-delivary.component';
import { GstComponent } from './pages/admincomponent/gst/gst.component';
import { DatepipePipe } from './pages/admincomponent/datepipe.pipe';
import { ShippingMethodComponent } from './pages/admincomponent/shipping-method/shipping-method.component';
import { ImageCropperComponent } from './pages/admincomponent/image-cropper/image-cropper.component';

// import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
// import { AngularFireModule } from '@angular/fire';
// import { AngularFirestoreModule } from '@angular/fire/firestore';
// import { AngularFireAuthModule } from '@angular/fire/auth';
// import { environment } from '../environments/environment';

// var firebaseConfig = {
//   apiKey: "AIzaSyAVQPcBDPbiTwMOp6nZ5tVAqQVZ88uE0q0",
//   authDomain: "parama-fashion.firebaseapp.com",
//   databaseURL: "https://parama-fashion.firebaseio.com",
//   projectId: "parama-fashion",
//   storageBucket: "parama-fashion.appspot.com",
//   messagingSenderId: "690905698735",
//   appId: "1:690905698735:web:d114c88c8226930de91f5a",
//   measurementId: "G-C4W9JWFH2X"
// };

@NgModule({
  declarations: [
    AppComponent,
    PagenotfoundComponent,
    AdmincomponentComponent,
    ManageProductComponent,
    FetureProductSettingComponent,
    BannerSettingComponent,
    YoutubeChannelSettingsComponent,
    ExternalLinkComponent,
    NewCatagoryComponent,
    StoreSettingsComponent,
    Child1Component,
    Child2Component,
    Child3Component,
    Child4Component,
    IconLogoCaptionComponent,
    CountryCurrencyComponent,
    ProductAddComponent,
    PagesInfoComponent,
    SocialComponent,
    OffersComponent,
    AddCountryComponent,
    BrandsComponent,
    TopNavComponent,
    RollOnTopComponent,
    AuthenticProductComponent,
    ExtraListComponent,
    ProductSettingComponent,
    AddVendorComponent,
    InventoryComponent,
    CustomerComponent,
    CustomerGroupComponent,
    IndexPageDesignComponent,
    OrdersComponent,
    TermsConditionComponent,
    ShippingComponent,
    CurrencyPipe,
    DelivaryGarruentyComponent,
    ReturnPolicyComponent,
    DelivaryGarruntyComponent,
    CashOnDelivaryComponent,
    GstComponent,
    DatepipePipe,
    ShippingMethodComponent,
    ImageCropperComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    DragDropModule,
    NgbModule,
    NgxPhotoEditorModule,
    IvyCarouselModule,
    AngularEditorModule,
    // AnotherpagesModule
    // AngularFireModule.initializeApp(firebaseConfig),
    // AngularFirestoreModule,
    // AngularFireAuthModule,
  ],
  providers: [CookieService,{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(){
    console.log('App module loaded')
  }
 }
