<section style="padding-top: 5rem;">
    <div>
        <div class="heading text-center">
            <h4 class="">Featured Products setting1</h4>
        </div>
    </div>
    <div class="container-fluid">
        <div class="border border-success bg-white  py-4">
            <div class="position-absolute title" style="right: 30px;">
                <button class="btn p-0 rounded-0">
                    <img src="../../../assets/img/icons/Icon material-cancel.png" alt="" class="w-100">
                </button>
            </div>
            <div class="container">
                <form (ngSubmit)="onSubmit(featuredForm,img)" #featuredForm="ngForm">
                    <div class="row">
                        <div class="col-9">
                            <div class="d-flex justify-content-between w-100">
                                <div class="form-group productInfo">
                                    <label class="title">Country</label>
                                    <input type="text" class="form-control" name="country"
                                        ngModel required>
                                </div>
                                <div class="form-group productInfo">
                                    <label class="title">Featured Name</label>
                                    <input type="text" class="form-control" name="featuredName"
                                        ngModel required>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between w-100">
                                <div class="form-group productInfo">
                                    <label class="title">Featured tag</label>
                                    <input type="text" class="form-control" name="featuredTag"
                                        ngModel required>
                                </div>
                                <div class="form-group productInfo">
                                    <label class="title">Feature ID</label>
                                    <input type="text" class="form-control" name="featureID"
                                        ngModel required>
                                </div>
                            </div>

                            <div class="w-100">
                                <div class="form-group productInfo w-100">
                                    <label class="title">Link</label>
                                    <input type="text" class="form-control w-100" name="link"
                                        ngModel required>
                                </div>
                            </div>
                        </div>
                        <div class="col-1"></div>
                        <div class="col-2">
                            <div class="border border-success h-100 position-relative">
                                <img [src]="img.value" alt="productname">
                                <label class="position-absolute btn btn-success px-1 py-0 m-2"
                                    style="right: 0; font-size: 12px; display:inline-flex;">Add Images
                                    <input type="file" size="60" style="display: none;" name="img" required
                                        ngModel #img="ngModel" (change)="upload(img)"></label>
                            </div>
                            <small class="text-danger" *ngIf="!imgType">img should be .jpg or .png</small>
                        </div>
                        <div class="col-12 text-center">
                            <button type="submit" class="btn btn-success rounded-0"
                                [disabled]="featuredForm.form.invalid || img.invalid">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
