<div class="modal-header border-0">
    <h4 class="modal-title" id="modal-basic-title">{{ship.shipping_name}}</h4>
    <button type="button" class="close" aria-label="Close">
        <span aria-hidden="true" (click)="close()">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="form-group d-flex">
        <div class="form-check mx-2">
            <input type="radio" class="form-check-input" value="Free Shipping"
                name="shipping_method" [(ngModel)]="ship.shipping_method">
            <label class="form-check-label">Free Shipping</label>
        </div>
        <div class="form-check mx-2">
            <input type="radio" class="form-check-input" value="Flat Rate"
                name="shipping_method" [(ngModel)]="ship.shipping_method">
            <label class="form-check-label" for="customRadioInline2">Flat Rate</label>
        </div>
        <div class="form-check mx-2">
            <input type="radio" class="form-check-input" value="Ship by" name="shipping_method"
                [(ngModel)]="ship.shipping_method">
            <label class="form-check-label" for="customRadioInline1">Ship by</label>
        </div>
        <div class="form-check mx-2">
            <input type="radio" class="form-check-input" value="Pickup In Store"
                name="shipping_method" [(ngModel)]="ship.shipping_method">
            <label class="form-check-label" for="customRadioInline2">Pickup In Store</label>
        </div>
    </div>
    <form *ngIf="ship.shipping_method === 'Free Shipping'">
        <div class="form-group">
            You are able to limit free shipping to be active only when a certain order total
            amount
            is exceeded. Products with fixed price shipping can be made ineligible for free
            shipping, but the product price will still count toward the minimum order threshold.
        </div>
        <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" name="limit_to_order_over"
                [(ngModel)]="ship.shipping_free_shipping.limit_to_order_over">
            <label class="form-check-label">Limit to order over</label>
        </div>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <span class="input-group-text"
                    [innerHTML]="baseCountry.selected_currency_symbol | currencyPipe"></span>
            </div>
            <input type="text" class="form-control" name="limit_to_order_over_price"
                [readonly]="!ship.shipping_free_shipping.limit_to_order_over"
                [(ngModel)]="ship.shipping_free_shipping.limit_to_order_over_price">
        </div>
        <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" name="use_discounted_order_subtotal"
                [disabled]="!ship.shipping_free_shipping.limit_to_order_over"
                [(ngModel)]="ship.shipping_free_shipping.use_discounted_order_subtotal">
            <label class="form-check-label">Use discounted order subtotal (Recommended)<button
                    type="button" class="btn" placement="right"
                    ngbTooltip="Use the discounted order subtotal to determine elibility for free shipping">
                    <i class="fas fa-info-circle"></i>
                </button></label>
        </div>
        <div class="form-group form-check">
            <input type="checkbox" class="form-check-input"
                name="make_product_with_fixed_shipping"
                [(ngModel)]="ship.shipping_free_shipping.make_product_with_fixed_shipping">
            <label class="form-check-label">Make products with a Fixed Shipping Cost ineligible
                for
                Free Shipping</label>
        </div>
    </form>
    <form *ngIf="ship.shipping_method === 'Flat Rate'">
        <div class="form-group">
            <label>Display name</label>
            <input type="text" class="form-control" name="display_name"
                [(ngModel)]="ship.shipping_flat_rate_option.display_name">
        </div>
        <div class="row">
            <div class="col">
                <label>Shipping rate</label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text"
                            [innerHTML]="baseCountry.selected_currency_symbol | currencyPipe"></span>
                    </div>
                    <input type="text" class="form-control" name="shipping_rate"
                        [(ngModel)]="ship.shipping_flat_rate_option.shipping_rate">
                </div>
            </div>
            <div class="col">
                <label>Type</label>
                <select class="custom-select mr-sm-2" name="type"
                    [(ngModel)]="ship.shipping_flat_rate_option.type">
                    <option value="Per Order">Per Order</option>
                    <option value="Per Item">Per Item</option>
                </select>
            </div>
        </div>
    </form>
    <form *ngIf="ship.shipping_method === 'Ship by'">
        <div class="form-group">
            <label>Display name</label>
            <input type="text" class="form-control" name="display_name_by_ship"
                [(ngModel)]="ship.shipping_by_weight_or_order_total.display_name">
        </div>
        <div class="form-group">
            <label>Charge shipping</label>
            <select class="custom-select mr-sm-2" name="charge_shipping"
                [(ngModel)]="ship.shipping_by_weight_or_order_total.charge_shipping">
                <option value="Weight">By Weight</option>
                <option value="Total Order">By total Order</option>
            </select>
        </div>
        <div class="row form-group">
            <div class="col">
                <label>Default shipping cost (Optional)</label>
                <input type="text" class="form-control" name="default_shipping_cost"
                    [(ngModel)]="ship.shipping_by_weight_or_order_total.default_shipping_cost">
            </div>
            <div class="col">
                <label>Type</label>
                <select class="custom-select mr-sm-2"
                    name="shipping_by_weight_or_order_total_type"
                    [(ngModel)]="ship.shipping_by_weight_or_order_total.type">
                    <option value="flat_rupees"
                        [innerHTML]="baseCountry.selected_currency_symbol | currencyPipe">
                    </option>
                    <option value="% of order Total">% of order Total</option>
                </select>
            </div>
        </div>
        <div class="">
            <h4>Ranges</h4>
            <div class="row"
                *ngFor="let value of ship.shipping_by_weight_or_order_total.ranges;index as i">
                <div class="col">
                    <label>Form</label>
                    <input type="text" class="form-control" name=shippingForm{{value.unikid}}
                        [(ngModel)]="value.from">
                </div>
                <div class="col">
                    <label>Upto</label>
                    <input type="text" class="form-control" name=shippingUpto{{value.unikid}}
                        [(ngModel)]="value.up_to">
                </div>
                <div class="col">
                    <label>Cost</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"
                                [innerHTML]="baseCountry.selected_currency_symbol | currencyPipe"></span>
                        </div>
                        <input type="text" class="form-control"
                            name=shippingCost{{value.unikid}} [(ngModel)]="value.cost">
                    </div>
                </div>
                <div class="col-2 mt-auto">
                    <button class="btn" *ngIf="ship.shipping_by_weight_or_order_total.ranges.length > 1" style="font-size: 25px;"
                        (click)="delete(ship.shipping_by_weight_or_order_total.ranges,i)">
                        <i class="fas fa-trash-alt"></i> </button>
                    <button class="btn" *ngIf="i === ship.shipping_by_weight_or_order_total.ranges.length - 1" style="font-size: 25px;"
                        (click)="addMore(ship.shipping_by_weight_or_order_total.ranges)">
                        <i class="fas fa-plus-circle"></i>
                    </button>
                </div>
            </div>
        </div>
    </form>
    <form class="" *ngIf="ship.shipping_method === 'Pickup In Store'">
        <div class="form-group">
            <label>Display name</label>
            <input type="text" class="form-control"
                name="shipping_pick_up_in_store_display_name"
                [(ngModel)]="ship.shipping_pick_up_in_store.display_name">
        </div>
    </form>
</div>
<div class="modal-footer border-0">
    <button type="button" class="btn btn-success" (click)="update(ship)">Submit</button>
    <button type="button" class="btn btn-outline-dark" (click)="close()">Close</button>
</div>