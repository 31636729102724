export class Offer {

    constructor(
        public country: string,
        public description: string,
        public cuponOfferValue: string,
        public cuponOfferValueFlat: string,
        public OfferUseTime : any,
        public unlimited: boolean,
        public couponType: string,
        public couponOfferTag: string,
        public couponOfferCode : string,
        public ValidFrom: String,
        public ValidUpto: String,
        public Terms_Conditions: string,
        public apply_to: Array<Object>,
        public apply_to_name: String,
        public apply_on: String,
        public apply_to_id: String,
        public cart_value: Number,
    ){}
}
