<div class="loaderBackground" *ngIf="loader">
    <div class="loaderparent"></div>
    <div class="loader">
        <div class="spinner-grow text-success mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success  mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success  mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="p-4">
        <h3 class="my-4">Customer Group</h3>
        <button type="button" class="btn btn-outline-success" (click)="openXl(content)">Create a Customer Group</button>
        <div *ngIf="!customerGroupList.length" class="bg-light my-4 d-flex justify-content-center align-items-center" style="height: 200px;">
            <div class="text-center text-secondary" style="font-size: 25px;">
                <i class="fas fa-ban"></i>
                <p>no groups</p>
            </div>
        </div>
        <table *ngIf="customerGroupList.length" class="table table-striped table-hover table-bordered my-4">
            <thead>
                <tr>
                    <th scope="col">Sl.no</th>
                    <th scope="col">Customer Group Name</th>
                    <th scope="col">Add</th>
                    <th scope="col">View</th>
                    <th scope="col">Delete</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let customerGroup of customerGroupList; index as i">
                    <th scope="row">{{i+1}}</th>
                    <td>{{customerGroup.customer_group_name}}</td>
                    <td>
                        <button (click)="viewCustomer(customerGroup._id,'customer_list','0')"
                         class="btn btn-success">Add Customer</button>
                    </td>
                    <td>
                        <h5 class="text-danger" *ngIf="!customerGroup.customer_group_customers.length">No Customer Avaliable</h5>
                        <button (click)="viewCustomer(customerGroup._id,'customer_group_list_with_users','0')"
                         class="btn btn-success" *ngIf="customerGroup.customer_group_customers.length">{{customerGroup.customer_group_customers.length}} Customer Avaliable
                        </button>
                    </td>
                    <td><button *ngIf="customerGroup.customer_group_customers.length" (click)="viewCustomer(customerGroup._id,'customer_group_list_with_users','1')"
                        class="btn btn-success">Delete Customer</button></td>
                </tr>
            </tbody>
        </table>
        <ng-template #content let-modal class="container-fluid">
            <div class="modal-header border-0">
                <h4 class="modal-title" id="modal-basic-title">Create A Customer Gruop</h4>
                <button type="button" class="close" aria-label="Close"
                    (click)="modal.dismiss('Cross click');customer_group_name = ''">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form my-4">
                    <h4 class="py-2">Gruop Details</h4>
                    <div class="form-group">
                        <label>Group Name</label>
                        <input type="text" class="form-control" name="customer_group_name"
                            [(ngModel)]="customer_group_name">
                        <small>Such as "Retail" or "Wholesale". Group name is not visiable to customers in your
                            store</small>
                    </div>
                    <!-- <div class="form-group">
                        <label>Group Access</label>
                        <div class="form-group form-check">
                            <input type="checkbox" class="form-check-input">
                            <label class="form-check-label checkbox-level">Customer in this group can see products in all catagories
                                accross all channels
                            </label>
                        </div>
                    </div> -->
                </div>
                <!-- <div class="form p-3 my-4 border-left border-primary">
                    <p class="m-auto">We Have updated this page to make it easier for you to manage Customer Group Discount</p>
                    <p class="m-auto"><span class="font-weight-bold"> Looking for Price Lists?</span>Once you have Created the Customer 
                        Group, you can assign a Price List at <span class="text-primary"> Products> Price Lists</span></p>
                </div> -->
                <form class="form my-4">
                    <h4 class="py-2">Catagory Level Discounts</h4>
                    <span>No Catagory level discount is created On this group.
                        <a routerLink="/offers" target="_blank">Create One Now</a>
                    </span>
                </form>
                <form class="form my-4">
                    <h4 class="py-2">Product Level Discounts</h4>
                    <span>No product level discounts have been created for this group.
                        <a routerLink="/offers" target="_blank">Create One Now</a>
                    </span>
                </form>
                <!-- <form class="form my-4">
                    <h4 class="py-2">StoreWide Discount</h4>
                        <div class="form-row">
                          <div class="col">
                            <input type="text" class="form-control" placeholder="First name">
                          </div>
                          <div class="col">
                            <input type="text" class="form-control" placeholder="Last name">
                          </div>
                        </div>
                    <div class="form-group">
                        <label>Group Name</label>
                        <input type="text" class="form-control">
                        <small>Such as "Retail" or "Wholesale". Group name is not visiable to customers in your store</small>
                    </div>
                    <div class="form-group">
                        <label>Group Access</label>
                        <div class="form-group form-check">
                            <input type="checkbox" class="form-check-input">
                            <label class="form-check-label checkbox-level">Customer in this group can see products in all catagories
                                accross all channels
                            </label>
                        </div>
                    </div>
                </form> -->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="_handelSubmit()">Save</button>
                <button type="button" class="btn btn-outline-dark"
                    (click)="modal.close('Save click');customer_group_name = ''">Cancel</button>
            </div>
        </ng-template>
    </div>
</div>