import { Component, Input, OnInit,OnDestroy } from '@angular/core';
import { Shipping } from '../shipping/shipping';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-shipping-method',
  templateUrl: './shipping-method.component.html',
  styleUrls: ['./shipping-method.component.css']
})
export class ShippingMethodComponent implements OnInit {

  @Input() ship = new Shipping('', 'Selection of Countries', '', '', '', [], '', '', {
    limit_to_order_over: false, limit_to_order_over_price: '0.00', use_discounted_order_subtotal: false,
    make_product_with_fixed_shipping: false,
  }, { display_name: 'Flat Rate', shipping_rate: 0.00, type: 'Per Order' }, {
    display_name: 'Ship By', charge_shipping: 'Weight',
    use_discounted_total_subtotal: false, default_shipping_cost: '0.00', type: 'flat_rupees',
    ranges: [
      {
        unikid: Math.random().toString(36).substr(2, 5),
        from: '0.00',
        up_to: '20.00',
        cost: '8.00',
        add: true, delete: false
      }
    ]
  }, { display_name: 'Pickup In Store' },
  )

  @Input() baseCountry:Object

  response: any = { status: '', message: '', type: '' }

  update(ship) {
    ship.status = true
    ship.shipping_free_shipping.limit_to_order_over_price = parseFloat(ship.shipping_free_shipping.limit_to_order_over_price)
    ship.shipping_by_weight_or_order_total.default_shipping_cost = parseFloat(ship.shipping_free_shipping.limit_to_order_over_price)
    let result
    ship.shipping_by_weight_or_order_total.ranges.map((res) => {
      result = res
      result.from = parseFloat(result.from),
        result.up_to = parseFloat(result.up_to),
        result.cost = parseFloat(result.cost)
    })
    console.log(ship)
    this.api.put(`update_shipping`, ship).subscribe(
      data => {
        console.log(data)
        this.response = data
        if (this.response.status === 'success') {
          this.api.get('shipping_list').subscribe(
            data => {
              console.log(data)
              let country = Object.values(data);
              window.location.reload()
            },
            error => console.log('Error!', error)
          )
        }
      },
      error => console.log(error)
    )
  }

  addMore(array) {
    array.map((res) => {
      res.add = false,
        res.delete = true
    })
    array.push({
      unikid: Math.random().toString(36).substr(2, 5),
      from: (parseFloat(array[array.length - 1].up_to) + parseFloat('0.01')).toFixed(2),
      up_to: ((parseFloat(array[array.length - 1].up_to) + parseFloat('0.01'))*2).toFixed(2), 
      cost: (parseFloat(array[array.length - 1].cost) * 2).toFixed(2), add: true, delete: true
    })
  }
  delete(array, index) {
    array.splice(index, 1)
    array[array.length - 1].add = true
    if (array.length <= 1) {
      array[array.length - 1].delete = false
    }
  }

  close(){
    window.location.reload()
  }

  constructor(private modelService: NgbModal,private api: ApiService) { }

  ngOnInit(): void {
    console.log(this.ship)
  }

  ngOnDestroy(): void{
    window.location.reload()
  }
}
