<div class="container-fluid">
    <div class="form-group d-flex">
        <div class="form-check mx-2">
            <input type="radio" class="form-check-input" value="Shipping Method Wise" name="delivary_type"
                [(ngModel)]="delivary_type">
            <label class="form-check-label">Shipping Method Wise</label>
        </div>
        <div class="form-check mx-2">
            <input type="radio" class="form-check-input" value="Shipping Method Wise & Product Wise"
                name="delivary_type" [(ngModel)]="delivary_type">
            <label class="form-check-label" for="customRadioInline1">Shipping Method Wise & Product Wise</label>
        </div>
    </div>

    <form>
        <div class="form-group">
            <label class="mr-sm-2">Country</label>
            <div ngbDropdown class="">
                <button class="btn form-control h-auto border d-flex justify-content-between" id="dropdownBasic1"
                    ngbDropdownToggle style="min-height: 35px;">
                    <div class="d-flex flex-wrap">
                        <ngb-alert class="value" type="secondary" (click)="close(country,i)"
                            *ngFor="let country of countryZone;index as i">{{country.shipping_name}}</ngb-alert>
                    </div>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="height: 350px; overflow-y: scroll;">
                    <button ngbDropdownItem *ngFor="let country of selectedCountries"
                        (click)="_handleZone(country,selectedCountries,countryZone)">{{country.shipping_name}}</button>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label>Product Will be Delivared within</label>
            <input type="text" class="form-control" name="shipping_delivery_day" [(ngModel)]="shipping_delivery_day">
        </div>
        <div class="form-group">
            <label>Delivary Terms & Conditions</label>
            <angular-editor name="delivery_terms_conditions" [placeholder]="'Enter text here...'"
                [(ngModel)]="delivery_terms_conditions"></angular-editor>
        </div>
        <div class="shipping_options p-3 my-4 border-left border-success" style="background-color: #e2fedb;"
            *ngIf="delivary_type === 'Shipping Method Wise'">
            Note:This method is only applicable for country wise delivary e.g: every product delivacy time will be show
            this
            country or countries delivary time. If you want your product delivacy different different days then choose
            another method e.g <span class="custom_zone"
                (click)="delivary_type = 'Shipping Method Wise & Product Wise'">Shipping Method Wise & Product
                Wise</span>
        </div>
        <div class="shipping_options p-3 my-4 border-left border-success" style="background-color: #e2fedb;">
            Note:This method is only applicable for product wise delivary e.g: product delivacy time will be show
            every country same time . If you want your product delivacy different different days then choose
            delivary time from <a class="text-danger font-weight-bold" routerLink="/product-add">Add Product</a>
        </div>
        <div class="shipping_options p-3 my-4 border-left border-success" style="background-color: #e2fedb;"
            *ngIf="delivary_type === 'Shipping Method Wise & Product Wise'">
            <span class="text-danger font-weight-bold">
                Note: If you apply this method then your product delivary time will be country delivary time + product
                delivary time
                e.g: if your delivary time in India is 5 days and in usa is 10 days and any product delivary time 2 days
                then in india your product will be delivared within (5+2) = 7 days , same prouct in usa will be
                delivared within (10+2) = 12
                days
            </span>
            e.g <span class="custom_zone" (click)="delivary_type = 'Shipping Method Wise'">Shipping Method Wise</span>
        </div>
        <div class="text-center">
            <button type="submit" class="btn btn-success" (click)="update()">Submit</button>
        </div>
    </form>
    <section class="my-3">
        <table class="table">
            <tbody class="border">
                <tr *ngFor="let ship of shippingList; index as i">
                    <th scope="row">{{i+1}}</th>
                    <td>{{ship.shipping_name}}</td>
                    <td>Shipping delivary within {{ship.shipping_delivery_day}} days</td>
                    <td><span class="font-weight-bold" *ngIf="ship.delivery_terms_conditions">Delivery Terms Conditions:
                        </span>{{ship.delivery_terms_conditions}}</td>
                    <td>
                        <button class="btn p-0 text-success" (click)="edit(ship)">
                            <i class="fas fa-edit"></i>
                        </button>
                    </td>
                    <td>
                        <button class="btn text-danger" (click)="delete(ship)">
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>
    <ngb-alert *ngIf="response.status" [type]="response.status" class="submit" (closed)="response.status = ''">
        {{response.message}}
    </ngb-alert>
</div>