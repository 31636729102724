<div>
    <section class="upper">
        <div>
            <div class="heading text-center">
                <h4 class="text-capitalize">Additional Feture with Images and Link</h4>
            </div>
        </div>
        <div class="container-fluid">
            <div class="border border-success bg-white  py-4">
                <div class="position-absolute title" style="right: 30px;">
                    <button class="btn p-0 rounded-0">
                        <img src="../../../assets/img/icons/Icon material-cancel.png" alt="" class="w-100">
                    </button>
                </div>
                <div class="container">
                    <p *ngIf="!bannerpositionRight">
                        <ngb-alert (close)="bannerpositionRight=true">
                            <strong>Warning!</strong> Better check yourself, you're not looking too good.
                        </ngb-alert>
                    </p>
                    <form (ngSubmit)="onSubmit(bannerForm)" #bannerForm="ngForm">
                        <div class="row">
                            <div class="col-10">
                                <div class="row">
                                    <div class="form-group productInfo col-6">
                                        <label class="title">Country</label>
                                        <select name="country" class="form-control" [(ngModel)]="bannerPage.country">
                                            <option value="{{country.phone}}" *ngFor="let country of countries">
                                                {{country.name}}</option>
                                        </select>
                                    </div>
                                    <div class="form-group productInfo col-6">
                                        <label class="title">Name</label>
                                        <input type="text" class="form-control" name="Name" [(ngModel)]="bannerPage.name" required>
                                    </div>
                                </div>
                                
                                <div class="w-100">
                                    <div class="form-group productInfo w-100">
                                        <label class="title">HeaderText</label>
                                        <input type="text" class="form-control w-100" name="link" 
                                        [(ngModel)]="bannerPage.text" required>
                                    </div>
                                </div>
                                
                                <div ngbDropdown class="form-group  w-100 d-inline-block">
                                    <label class="title">Apply On</label>
                                    <div class="btn btn-outline-green productInfo form-control w-100">
                                                <button class="btn btn-block p-0 d-flex justify-content-between" type="button" 
                                                (click)="open(content,'md')">
                                                   <span>{{bannerPage.applyPage}}</span>
                                                    <span class="material-icons">
                                                        arrow_drop_down
                                                    </span></button>
                                            <ng-template #content let-modal>
                                                <div class="modal-header border-0">
                                                    <input type="text" class="form-control" name="search">
                                                    <button type="button" class="close" aria-label="Close"
                                                        (click)="modal.dismiss('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="">
                                                        <ul class="list-group list-group-flush">
                                                            <li class="list-group-item border-0"
                                                                *ngFor="let item of catagories; index as i">
                                                                <div class="d-flex">
                                                                    <label class="contain" *ngIf="item.button">
                                                                        <input type="radio" class="shorter">
                                                                        <span class="checkmark"></span>
                                                                    </label>
                                                                    <button class="btn p-0 ml-4" *ngIf="item.button"
                                                                        type="button" (click)="Showcat(i)">
                                                                        <img [src]="item.button? '../../../assets/img/icons/Icon awesome-plus-square.png':'../../../assets/img/icons/Icon feather-minus-square.png'"
                                                                            height="15px" alt="">
                                                                    </button>
                                                                    <label class="form-check-label mx-2"
                                                                        for="">{{item.catName}}</label>
                                                                </div>
                                                                <ul class="list-group list-group-flush ml-4"
                                                                    *ngIf="item.click">
                                                                    <li class="list-group-item border-0"
                                                                        *ngFor="let val of item.subcatagory; index as j">
                                                                        <div class="d-flex">
                                                                            <label class="contain">
                                                                                <input type="radio" class="shorter">
                                                                                <span class="checkmark"></span>
                                                                            </label>
                                                                            <button class="btn p-0 ml-4" type="button"
                                                                                (click)="Showsubcat(i,j)">
                                                                                <img [src]="val.button? '../../../assets/img/icons/Icon awesome-plus-square.png':'../../../assets/img/icons/Icon feather-minus-square.png'"
                                                                                    height="15px" alt="">
                                                                            </button>
                                                                            <label class="form-check-label mx-2"
                                                                                for="">{{val.catName}}</label>
                                                                        </div>
                                                                        <ul class="list-group list-group-flush ml-4"
                                                                            *ngIf="val.click">
                                                                            <li class="list-group-item border-0"
                                                                                *ngFor="let v of val.subcatagory; index as m">
                                                                                <div class="d-flex">
                                                                                    <label class="contain"
                                                                                        *ngIf="item.button">
                                                                                        <input type="radio"
                                                                                            class="shorter">
                                                                                        <span class="checkmark"></span>
                                                                                    </label>
                                                                                    <button class="btn p-0 ml-4"
                                                                                        type="button">
                                                                                        <img [src]="v.button? '../../../assets/img/icons/Icon awesome-plus-square.png':'../../../assets/img/icons/Icon feather-minus-square.png'"
                                                                                            height="15px" alt="">
                                                                                    </button>
                                                                                    <label class="form-check-label mx-2"
                                                                                        for="">{{v.name}}</label>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </ng-template>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="border border-success h-100 position-relative">
                                <img [src]="img.value" alt="productname">
                                <label class="position-absolute btn btn-success px-1 py-0 m-2"
                                    style="right: 0; font-size: 12px; display:inline-flex;">Add Image
                                    <input type="file" size="60" style="display: none;" name="img" required 
                                       [(ngModel)]="bannerPage.img" #img="ngModel" (change)="upload($event)">
                                    </label>
                                </div>
                                <small class="text-danger" *ngIf="!imgType">img should be .jpg or .png</small>
                            </div>

                            <div class="col-12 text-center">
                                <button type="submit" class="btn btn-success rounded-0"
                                    >Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <section class="container">
        <div>
            <ul class="list-group p-0">
                <li *ngFor="let item of bannerMenu; index as i">
                    <div class="d-flex">
                        <div class="my-auto d-flex justify-content-between" style="width:12%;">
                            <p class="m-auto text-capitalize">Image Banner</p>
                            <p class="m-auto"> {{i+1}}</p>
                        </div>
                        <div class="list-group-item m-4 w-100">
                            <div class="row">
                                <div class="col-5 text-center my-auto">
                                    <p class="m-auto">{{item.main_banner_position_id}}</p>
                                    <p class="m-auto">{{item.main_banner_name}}</p>
                                    <p class="m-auto">{{item.featuredTag}}</p>
                                    <p class="m-auto">{{item.main_banner_link}}</p>
                                </div>
                                <div class="col-4">
                                    <div class="border border-success h-100">
                                        <img src="../../../../assets/img/icons/asset-1.png" alt="img" height="150px"
                                            width="100%">
                                    </div>
                                </div>
                                <div class="col-3 m-auto d-flex justify-content-around">
                                    <button class="btn p-0">
                                        <img src="../../../../assets/img/icons/Icon awesome-edit-1.png" height="25px"
                                            alt="">
                                    </button>
                                    <button class="btn p-0">
                                        <img src="../../../../assets/img/icons/Icon material-delete-red-forever.png"
                                            height="25px" alt="">
                                    </button>
                                    <button class="btn p-0">
                                        <img src="../../../../assets/img/icons/Icon awesome-eye.png" height="25px"
                                            alt="">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </section>

</div>