export class Shipping {
    constructor(
        public shipping_name: string,
        public type: string,
        public shipping_country_name: string,
        public shipping_country_id: string,
        public shipping_country_code: string,
        public shipping_state_name: Array<Object>,
        public country_post_code: string,
        public shipping_method: string,
        public shipping_free_shipping: {
            limit_to_order_over: boolean,
            limit_to_order_over_price: any,
            use_discounted_order_subtotal: boolean,
            make_product_with_fixed_shipping: boolean,
        },
        public shipping_flat_rate_option: {
            display_name: string,
            shipping_rate: any,
            type: string
        },
        public shipping_by_weight_or_order_total: {
            display_name: string,
            charge_shipping: string,
            use_discounted_total_subtotal: boolean,
            default_shipping_cost: any,
            type: string,
            ranges: Array<Object>
        },
        public shipping_pick_up_in_store: {
            display_name: string
        },
    ) { }
}
