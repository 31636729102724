import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { Gst } from './gst';

@Component({
  selector: 'app-gst',
  templateUrl: './gst.component.html',
  styleUrls: ['./gst.component.css']
})
export class GstComponent implements OnInit {

  gst_avaliable = true

  countries = [];

  gst = new Gst('','','','India','','','0','0','0',false,'')
  gstList = []

  selectCountry(){
    this.countries.find(res => {
      if (res._id === this.gst.country_id) {
        this.gst.country_name = res.selected_country_name
        this.gst.country_code = res.selected_country_code
      }
    })
  }

  store_igst_rate(){
    if (!this.gst.store_sgst_or_utgst_rate && this.gst.store_cgst_rate) {
      this.gst.store_igst_rate = parseFloat(this.gst.store_cgst_rate)
    }else if (!this.gst.store_cgst_rate && this.gst.store_sgst_or_utgst_rate) {
      this.gst.store_igst_rate = parseFloat(this.gst.store_sgst_or_utgst_rate)
    }else if (!this.gst.store_cgst_rate && !this.gst.store_sgst_or_utgst_rate) {
      this.gst.store_igst_rate = 0
    }else{
      this.gst.store_igst_rate = parseFloat( this.gst.store_sgst_or_utgst_rate) +parseFloat( this.gst.store_cgst_rate)
    }
  }

  _handelSubmit(){
    console.log(this.gst)
    this.gst.store_sgst_or_utgst_rate = this.gst.store_sgst_or_utgst_rate ? parseFloat( this.gst.store_sgst_or_utgst_rate) : 0
    this.gst.store_cgst_rate = this.gst.store_cgst_rate ? parseFloat( this.gst.store_cgst_rate): 0
    this.gst.store_igst_rate = this.gst.store_igst_rate ? parseFloat(this.gst.store_igst_rate): 0
    this.gst.store_included_gst === 'true' ? this.gst.store_included_gst = true : false
    if (this.gst._id) {
      this.api.put(`update_gst`, this.gst).subscribe(
        data => {
          console.log(data)
          if (data.status === 'success') {
            window.location.reload()
          }
        },
        error => console.log(error)
      ) 
    } else {
      this.api.post(`add_store_gst`, this.gst).subscribe(
        data => {
          console.log(data)
          if (data.status === 'success') {
            window.location.reload()
          }        },
        error => console.log(error)
      )  
    }
  }

  edit(gstper,index){
    gstper.store_included_gst = gstper.store_included_gst.toString()
    this.gst = gstper
    this.gstList.splice(index,1)
  }

  _handelgst(){
    this.api.put(`update_store_gst_status`, {"country_id": this.gst.country_id, "status": this.gst_avaliable}).subscribe(
      data => {
        console.log(data)
        window.location.reload()
      },
      error => console.log(error)
    )
  }

  delete(gstper){
    console.log({"_id": gstper._id})
    this.api.post(`delete_gst`, {"_id": gstper._id}).subscribe(
      data => {
        console.log(data)
        if (data.status === 'success') {
          window.location.reload()
        }
      },
      error => console.log(error)
    )
  }

  view(gstper){
    console.log({"id":gstper._id, "status": !gstper.status})
    this.api.put(`update_gst`, {"_id":gstper._id, "status": !gstper.status}).subscribe(
      data => {
        console.log(data)
        if (data.status==='success') {
          window.location.reload()
        }
      },
      error => console.log(error)
    ) 
  }

  constructor(private api:ApiService) { }

  ngOnInit(): void {
    this.api.get(`selected_country_list`).subscribe(
      data =>{
        this.countries = Object.values(data)[2];
        this.gst.country_id = this.countries[0]._id
        this.gst.country_name = this.countries[0].selected_country_name
        this.gst.country_code = this.countries[0].selected_country_code
        // this.api.countryShare(this.countries);
        console.log(this.countries)
      }, 
      error => console.log('Error!', error)
    )
      
    this.api.get(`store_gst_list`).subscribe(
      data =>{
        console.log(data)
        this.gstList = Object.values(data)[2];
      }, 
      error => console.log('Error!', error)
    )
  }

}
