<div class="loaderBackground" *ngIf="loader">
    <div class="loaderparent"></div>
    <div class="loader">
        <div class="spinner-grow text-success mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success  mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success  mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>
<div class="container-fluid">
    <h2>Manage Orders</h2>
    <div class="d-flex mb-2 border-bottom">
        <div class="mr-2" *ngFor="let f of filterOptions">
            <button type="button" class="btn btn-filter" [class.btn-filter-active]="f.active"
                (click)="filter(f.name)">{{f.name}} ({{All}})</button>|
        </div>
    </div>
    <div class="form-row form-group">
        <div class="col-2">
            <select class="form-control" [disabled]="!action" [(ngModel)]="chooseAction" name="chooseAction">
                <option [value]="status" *ngFor="let status of statuses">{{status}}</option>
            </select>
        </div>
        <div class="col">
            <button class="btn btn-success" [disabled]="chooseAction === 'Bulk Actions'"
                (click)="statusChange()">Apply</button>
        </div>
    </div>
    <table class="table border">
        <thead>
            <tr>
                <th scope="col-1"><input type="checkbox" name="all" [(ngModel)]="select" (ngModelChange)="all()"></th>
                <th></th>
                <th scope="col-3">Order</th>
                <th scope="col-1">Purchased</th>
                <th scope="col-1">Total</th>
                <th scope="col-1">Shipped To</th>
                <th scope="col-1">Status</th>
                <!-- <th scope="col-1">Review</th> -->
                <th scope="col-1">Date</th>
                <th scope="col-1">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let order of orders.response">
                <th scope="row"><input type="checkbox" name="order" [(ngModel)]="order.select"
                        (ngModelChange)="selectOne()"></th>
                <td *ngIf="order.order_by_device === 'mobileweb'"><i class="fas fa-mobile-alt"></i></td>
                <td *ngIf="order.order_by_device === 'ios'"><i class="fab fa-apple"></i></td>
                <td>
                    <div class="d-flex">
                        <div class="pr-2">
                            <img [src]="order.order_history[0].product_main_image" height="35px" width="35px" alt="">
                        </div>
                        <div>
                            <div>{{order.user_full_name}}</div>
                            <div>{{order.order_id}}</div>
                        </div>
                    </div>
                </td>
                <td *ngIf="order.order_history.length === 1">{{order.order_history[0].product_quantity}} pics</td>
                <td *ngIf="order.order_history.length > 1">{{order.order_history.length}} items</td>
                <td>{{order.currency_type}} {{order.total_price}} by {{order.transaction_type}}</td>
                <td>
                    <span placement="bottom"
                        ngbTooltip="{{order.address.address_1}}, {{order.address.address_2}}, {{order.address.city}}, {{order.address.country}}, {{order.address.location}}, {{order.address.state}}, {{order.address.pin}}">
                        {{order.address.state}},{{order.address.pin}}
                    </span>
                </td>
                <!-- <td>{{order.is_reviwed}}</td> -->
                <td>{{order.status}}</td>
                <td>{{order.createdAt | date: 'short'}}</td>
                <td>
                    <button class="btn p-0" (click)="open(orders)">
                        <i class="fas fa-ellipsis-h"></i></button>
                </td>
                <ng-template #orders let-modal>
                    <div class="modal-header border-0">
                        <h3>Order Details</h3>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p class="font-weight-bold" style="font-size: 20px;">Order #{{order.order_id}} details</p>
                        <p class="text-secondary" style="font-size: 18px;">Payment via {{order.transaction_type}}</p>
                        <div class="row">
                            <div class="col">
                                <h4>General Details</h4>
                                <p class=""><span class="font-weight-bold">Customer:</span>
                                    {{order.user_full_name}}({{order.user_id}})</p>
                                <p>Email</p>
                                <p class="font-weight-bold">Phone : <span
                                        class="font-weight-normal">{{order.user_phone}}</span></p>
                                <div>
                                    <p class="font-weight-bold" style="font-size: 18px;">Shipping Address Details: </p>
                                    <div>{{order.address.address_1}}</div>
                                    <div>{{order.address.address_2}}</div>
                                    <div>{{order.address.city}}</div>
                                    <div>{{order.address.country}}</div>
                                    <div>{{order.address.location}}</div>
                                    <div>{{order.address.state}}</div>
                                    <div>{{order.address.pin}}</div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="mb-3">
                                    <p class="font-weight-bold" style="font-size: 18px;">Order Notes</p>
                                    <textarea class="form-control form-group" rows="3" name="notes"
                                        [(ngModel)]="order.order_notes"></textarea>
                                    <button type="submit" class="btn btn-success"
                                        (click)='edit({"_id":order._id,"order_notes":order.order_notes})'>Add
                                        Note</button>
                                </div>
                            </div>
                        </div>
                        <p class="font-weight-bold" style="font-size: 18px;">Item Details: </p>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">sl No.</th>
                                    <th scope="col">Item</th>
                                    <th scope="col">Item Name</th>
                                    <th scope="col">Item Price</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of order.order_history;index as i">
                                    <th scope="row">{{i+1}}</th>
                                    <td><img [src]="item.product_main_image" height="35px" width="35px" alt=""></td>
                                    <td>{{item.product_name}}</td>
                                    <td>{{item.product_unit_price}}</td>
                                    <td>{{item.product_quantity}}</td>
                                    <td>
                                        <div class="input-group">
                                            <input type="text" class="form-control" name="product_inquiry"
                                                [(ngModel)]="item.product_inquiry">
                                            <button class="btn btn-success" type="button"
                                                (click)='edit({"_id":order._id,
                                                "order_history":order.order_history})'>Enquery
                                                Now</button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </ng-template>
            </tr>
        </tbody>
    </table>
    <div class="d-flex {{pagecountback ? 'justify-content-between' : 'justify-content-end'}}">
        <a *ngIf="pagecountback" routerLink="/order-list/{{id1}}/{{pagecountback}}" class="btn btn-success">Back</a>
        <a *ngIf="orders.response.length >= 10" routerLink="/order-list/{{id1}}/{{pagecount}}"
            class="btn btn-success">Next</a>
    </div>
</div>