import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/api.service';
import { Shipping } from './shipping';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import * as uuid from 'uuid';

@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.css']
})

export class ShippingComponent implements OnInit {
  // [{ unikid: Math.random().toString(36).substr(2, 5), from: 0.00, upto: 20.00, cost: 8.00, add: true, delete: false }]
  shipping = new Shipping('', 'Selection of Countries', '', '', '', [], '', '', {
    limit_to_order_over: false, limit_to_order_over_price: '0.00', use_discounted_order_subtotal: false,
    make_product_with_fixed_shipping: false,
  }, { display_name: 'Flat Rate', shipping_rate: 0.00, type: 'Per Order' }, {
    display_name: 'Ship By', charge_shipping: 'Weight',
    use_discounted_total_subtotal: false, default_shipping_cost: '0.00', type: 'flat_rupees',
    ranges: [
      {
        unikid: Math.random().toString(36).substr(2, 5),
        from: '0.00',
        up_to: '20.00',
        cost: '8.00',
        add: true, delete: false
      }
    ]
  }, { display_name: 'Pickup In Store' },
  )

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '200px',
    minHeight: '200px',
    maxHeight: '200px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'no',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

  countryZone = []

  stateZone: any[]

  countries: any[];
  shippingList = [];

  response: any = { status: '', message: '', type: '' }
  totalWorld: boolean;
  baseCountry: any
  selectedCountries: any = [];
  selectedCountryForState = [];
  selectedCountryForPin = [];

  open(content) {
    this.modelService.open(content, { size: 'lg' })
  }


  _handleZone(country, array, zone) {
    console.log(country)
    zone.push(country)
    array.map((res, index) => {
      if (res.name === country.name) {
        array.splice(index, 1)
      }
    })
  }

  _getState(country) {
    this.shipping.shipping_state_name = []
    this.countries.map((res) => {
      if (res.name === country) {
        this.api.get(`get_shipping_countries/${res.id}`).subscribe(
          data => {
            let country = Object.values(data);
            this.stateZone = Object.values(country[1]);
            this.shippingList.map((result) => {
              if (result.shipping_country_code === res.code) {
                let selectedState = result
                this.stateZone.map((state, index) => {
                  if (selectedState.shipping_state_name.find((value) => value.name === state.name)) {
                    this.stateZone.splice(index, 1)
                  }
                })
              }
            }
            )
          },
          error => console.log('Error!', error)
        )
      }
    })
  }

  close(value, array, index, selectList) {
    array.splice(index, 1)
    selectList.unshift(value)
  }

  _handleCreateZone(country) {
    this.shipping.shipping_name = country.name
    this.shipping.shipping_country_id = country.id
  }

  _handleSubmit(shippingZone) {
    this.selectedCountries.map((res) => {
      if (res.name === shippingZone) {
        this.shipping.shipping_name = res.name
        this.shipping.shipping_country_id = res.id
        this.shipping.shipping_country_code = res.code
      }
    })
    this.api.post(`add_shipping`, { ...this.shipping, shipping_country_pin_code: [] }).subscribe(
      data => {
        console.log(data)
        this.response = data
        this.api.get('shipping_list').subscribe(
          data => {
            console.log(data)
            let country = Object.values(data);
            this.shippingList = Object.values(country[2]);
            setTimeout(() => {
              window.location.reload()
            }, 1000);
          },
          error => console.log('Error!', error)
        )
      },
      error => console.log(error)
    )
  }

  _handleManySubmit() {
    this.response = { status: '', message: '', type: '' }
    switch (this.shipping.type) {
      case 'Selection of Countries':
        let body = []
        this.countryZone.map((res) => {
          body.push({
            shipping_name: this.shipping.shipping_name,
            shipping_country_name: res.name,
            shipping_country_id: res.id,
            shipping_country_code: res.code,
            shipping_country_pin_code: []
          })
        })
        this.api.post(`add_many_shipping`, { shippingArray: body }).subscribe(
          data => {
            this.api.get('shipping_list').subscribe(
              data => {
                this.response = data
                let country = Object.values(data);
                this.shippingList = Object.values(country[2]);
                setTimeout(() => {
                  window.location.reload()
                }, 1000);
              },
              error => console.log('Error!', error)
            )
          },
          error => console.log(error)
        )
        break;

      case 'Selection of States or Provinces':
        this.countries.map((res) => {
          if (res.name === this.shipping.shipping_country_name) {
            this.shipping.shipping_country_id = res.id
            this.shipping.shipping_country_code = res.code
          }
        })
        console.log(this.shipping)
        this.api.post(`add_shipping`, { ...this.shipping, shipping_country_pin_code: [] }).subscribe(
          data => {
            this.response = data
            this.api.get('shipping_list').subscribe(
              data => {
                    let country = Object.values(data);
                this.shippingList = Object.values(country[2]);
                setTimeout(() => {
                  window.location.reload()
                }, 1000);
              },
              error => console.log('Error!', error)
            )
          },
          error => console.log(error)
        )
        break

      default:
        let users: any = {
          shipping_country_pin_code: []
        }
        this.countries.map((res) => {
          if (res.name === this.shipping.shipping_country_name) {
            users.shipping_name = this.shipping.shipping_name
            users.shipping_country_name = res.name,
              users.shipping_country_id = res.id
            users.shipping_country_code = res.code
          }
        })
        let country_post_code = this.shipping.country_post_code.split(',')
        country_post_code.map((res) => {
          users.shipping_country_pin_code.push({
            pin_code: res
          })
        })
        this.shippingList.map((result) => {
          if (result.shipping_country_code === users.shipping_country_code) {
            let selectedState = result
            selectedState.shipping_country_pin_code.map((value) => {
              if (users.shipping_country_pin_code.find((pin) => pin.pin_code === value.pin_code)) {
                this.response = { status: 'danger', message: 'Pin Code Already Exists', type: 'danger' }
              }
            })
          }
        }
        )
        console.log(users)
        if (!this.response.status) {
          this.api.post(`add_shipping`, users).subscribe(
            data => {
              console.log(data)
              this.response = data
              if (this.response.status === 'success') {
                this.api.get('shipping_list').subscribe(
                  data => {
                    console.log(data)
                    let country = Object.values(data);
                    this.shippingList = Object.values(country[2]);
                    setTimeout(() => {
                      window.location.reload()
                    }, 1000);
                  },
                  error => console.log('Error!', error)
                )
              }
            },
            error => console.log(error)
          )
        }
        break;
    }
  }

  _restOfWorld() {
    this.api.post(`add_shipping`, {
      shipping_name: 'Rest of the World',
      shipping_country_name: 'All',
      shipping_country_id: 'All',
      shipping_country_code: 'All'
    }).subscribe(
      data => {
        console.log(data)
        this.response = data
        if (data.status === 'success') {
          setTimeout(() => {
            window.location.reload()
          }, 1000);
        }
        setTimeout(() => {
          this.response.status = ''
        }, 1000);
      },
      error => console.log(error)
    )
  }

  _handleDelete(item) {
    this.api.post(`delete_shipping`, { "_id": item._id }).subscribe(
      data => {
        console.log(data)
        this.response = data
        if (this.response.status === 'success') {
          setTimeout(() => {
            window.location.reload()
          }, 1000);
        }
      },
      error => console.log(error)
    )
  }

  edit(content) {
    // this.shipping["_id"] = ship._id;
    // this.shipping["status"] = true;
    // this.shipping.shipping_name = ship.shipping_name,
    //   this.shipping.shipping_country_name = ship.shipping_country_name,
    //   this.shipping.shipping_country_id = ship.shipping_country_id,
    //   this.shipping.shipping_country_code = ship.shipping_country_code,
    //   this.shipping.country_post_code = ship.country_post_code,
    //   this.shipping.shipping_state_name = ship.shipping_state_name
    // this.shipping.shipping_method = ship.shipping_method
    // switch (this.shipping.shipping_method) {
    //   case 'Free Shipping':
    //     this.shipping.shipping_free_shipping = ship.shipping_free_shipping
    //     this.shipping.shipping_flat_rate_option = this.shipping.shipping_flat_rate_option
    //     this.shipping.shipping_pick_up_in_store = this.shipping.shipping_pick_up_in_store
    //     this.shipping.shipping_by_weight_or_order_total = this.shipping.shipping_by_weight_or_order_total
    //     break;
    //   case 'Flat Rate':
    //     this.shipping.shipping_free_shipping = this.shipping.shipping_free_shipping
    //     this.shipping.shipping_flat_rate_option = ship.shipping_flat_rate_option
    //     this.shipping.shipping_pick_up_in_store = this.shipping.shipping_pick_up_in_store
    //     this.shipping.shipping_by_weight_or_order_total = this.shipping.shipping_by_weight_or_order_total
    //     break;
    //   case 'Ship by':
    //     this.shipping.shipping_free_shipping = this.shipping.shipping_free_shipping
    //     this.shipping.shipping_flat_rate_option = this.shipping.shipping_flat_rate_option
    //     this.shipping.shipping_pick_up_in_store = this.shipping.shipping_pick_up_in_store
    //     this.shipping.shipping_by_weight_or_order_total = ship.shipping_by_weight_or_order_total
    //     break;
    //   case 'Pickup In Store':
    //     this.shipping.shipping_free_shipping = this.shipping.shipping_free_shipping
    //     this.shipping.shipping_flat_rate_option = this.shipping.shipping_flat_rate_option
    //     this.shipping.shipping_pick_up_in_store = this.shipping.shipping_pick_up_in_store
    //     this.shipping.shipping_by_weight_or_order_total = ship.shipping_by_weight_or_order_total
    //     break;

    //   default:
    //     break;
    // }
    this.modelService.open(content, { size: 'lg' })
  }

  cod(body, content) {
    this.modelService.open(content)
    console.log(body)
  }

  _handelchange(codForm, body) {
    body.shipping_cash_on_delivery_available = codForm.value.cod
    body.cod_terms_conditions = codForm.value.cod_terms_conditions
    this.api.put(`update_shipping`, body).subscribe(
      data => {
        console.log(data)
        this.response = data
        if (this.response.status === 'success') {
          window.location.reload()
        }
      },
      error => console.log(error)
    )
  }

  modelClose(ship) {
    ship.shipping_cash_on_delivery_available = !ship.shipping_cash_on_delivery_available
    this.modelService.dismissAll()
  }

  update(ship) {
    this.api.put(`update_shipping`, ship).subscribe(
      data => {
        console.log(data)
        this.response = data
        if (this.response.status === 'success') {
          this.api.get('shipping_list').subscribe(
            data => {
              console.log(data)
              let country = Object.values(data);
              this.shippingList = Object.values(country[2]);
              window.location.reload()
            },
            error => console.log('Error!', error)
          )
        }
      },
      error => console.log(error)
    )
  }

  constructor(private modelService: NgbModal, private api: ApiService) { }
  // countryCode = "20B9"
  ngOnInit(): void {
    this.api.get('selected_country_list').subscribe(
      data => {
        let country = Object.values(data);
        this.baseCountry = country[2].find(res => res.selected_country_type_base)
      },
      error => console.log('Error!', error)
    )
    this.api.get('get_shipping_countries').subscribe(
      data => {
        let country = Object.values(data);
        console.log(country)
        this.countries = Object.values(country[1]);
        this.selectedCountryForState = this.countries
      },
      error => console.log('Error!', error)
    )
    this.api.get('shipping_list').subscribe(
      data => {
        console.log(data)
        let country = Object.values(data);
        this.shippingList = Object.values(country[2]);
        this.countries.map((result) => {
          if (!this.shippingList.find((res) => res.shipping_country_code === result.code)) {
            this.selectedCountries.push(result)
          }
        })
        this.countries.map((result, index) => {
          if (this.shippingList.find((res) => res.shipping_state_name.length === 0)) {
            this.countries.splice(index, 1)
          }
        })
        console.log(this.countries)
        console.log(this.shippingList)
      },
      error => console.log('Error!', error)
    )
  }

}
