<div class="upper">
    <section>
        <h4 class="heading text-center text-uppercase">Country & Currency Setting</h4>
        <div class="container-fluid">
            <div class="border border-success bg-white  py-4">
                <div class="position-absolute title" style="right: 30px;">
                    <button class="btn p-0 rounded-0">
                        <i class="fas fa-times-circle"></i>
                    </button>
                </div>
                <div class="container">
                    <form (ngSubmit)="onSubmit(chooseContry)" #chooseContry="ngForm">
                        <div class="row">
                            <div class="col-4">
                                <div ngbDropdown class="d-inline-block w-100">
                                    <label class="title">Select Country</label>
                                    <button class="btn btn-outline-green form-control w-100" type="button"
                                        id="dropdownBasic1" ngbDropdownToggle>
                                        {{selectCountry}}</button>
                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                        style="height:250px; overflow-y: scroll; width: 100%;">
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item border-0" *ngFor="let country of countries">
                                                <label class="custom mb-0">{{country.name}}
                                                    <input type="checkbox" #chooseCountry ngModel [name]="country.name"
                                                        [readonly]="alerttag"
                                                        (ngModelChange)="checkSelectedCountry(country,chooseCountry)">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4 text-center">
                                <button type="submit" class="btn btn-success rounded-0"
                                    [disabled]="chooseContry.form.invalid">Submit</button>
                            </div>
                            <ngb-alert type="danger" *ngIf="alerttag" (click)="alerttag=false">
                                These countries are already selected
                            </ngb-alert>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <section class="my-4" *ngIf="countrySelection.length > 0">
        <div class="container">
            <ul class="list-group p-0 example-list border rounded-0 border-success ">
                <li class="list-group-item border-0">
                    <div class="d-flex w-100">
                        <div class="list-group-item w-100 border-0">
                            <div class="row">
                                <div class="col">
                                    <h5>Base Currency</h5>
                                </div>
                                <div class="col">
                                    <h5 class="text-uppercase">Country</h5>
                                </div>
                                <div class="col text-center my-auto">
                                    <h5 class="text-uppercase">Flag</h5>
                                </div>
                                <div class="col">
                                    <h5 class="text-uppercase">Currency Code</h5>
                                </div>
                                <div class="col">
                                    <h5 class="text-uppercase">Currency Symbol</h5>
                                </div>
                                <div class="col">
                                    <h5 class="text-uppercase">Country Code</h5>
                                </div>
                                <div class="col-1"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="list-group-item border-0" *ngFor="let item of countrySelection; index as i">
                    <div class="d-flex w-100">
                        <div class="list-group-item w-100 border-0">
                            <div class="row">
                                <div class="col text-center">
                                    <label class="custom">
                                        <input type="checkbox" [disabled]="!baseCurrencyChange"
                                            (click)="updateStatus(item)" name="baseCurrency"
                                            [(ngModel)]="item.selected_country_type_base">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="col">
                                    <h5 class="text-uppercase">{{item.selected_country_name}}</h5>
                                </div>
                                <div class="col text-center my-auto">
                                    <i class="em em-flag-{{item.selected_country_code | lowercase }}"></i>
                                </div>
                                <div class="col">
                                    <h5 class="text-uppercase">{{item.selected_country_currency}}</h5>
                                </div>
                                <div class="col">＄
                                    <!-- <p [innerHTML]="item.selected_currency_symbol | currencyPipe"></p> -->
                                </div>
                                <div class="col">
                                    <h5 class="text-uppercase">{{item.selected_country_phone}}</h5>
                                </div>
                                <div class="col-1">
                                    <button class="btn delete" *ngIf="!item.selected_country_type_base"
                                        (click)="deleteCountry(item._id)">
                                        <i class="fas fa-trash-alt"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="list-group-item border-0">
                    If you want to change your base country settings first click here to reset all country and then
                    choose from
                    existing country
                    <button type="button" class="btn btn-success btn-sm px-1 py-0 m-2" (click)="ShowHideDiv(content)">
                        Reset </button>
                </li>
            </ul>
        </div>
        <ng-template #content let-modal>
            <div class="modal-header">
                <h4 class="modal-title">Modal title</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Do You surely want to change your base country??
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" (click)="basecountryChangeYes(content)">Yes</button>
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">No</button>
            </div>
        </ng-template>
    </section>
    <section class="my-4" *ngIf="basecurrencySet">
        <div class="container">
            <ul class="list-group p-0 example-list border rounded-0 border-success ">
                <li class="list-group-item border-0">
                    <div class="row">
                        <div class="col-3 text-center">
                            <h5>Country</h5>
                        </div>
                        <div class="col text-center">
                            <h5 class="text-uppercase">Code</h5>
                        </div>
                        <div class="col text-center">
                            <h5 class="text-uppercase">Value</h5>
                        </div>
                        <div class="col-3 text-center">
                            <h5 class="text-uppercase">Country</h5>
                        </div>
                        <div class="col text-center">
                            <h5 class="text-uppercase">Convert Figure</h5>
                        </div>
                        <div class="col text-center">
                            <h5 class="text-uppercase">Convert Value</h5>
                        </div>
                        <div class="col-1 text-center">
                            <h5 class="text-uppercase">Reset</h5>
                        </div>
                    </div>
                </li>
                <li class="list-group-item w-100 border-0">
                    <div class="row my-3" *ngFor="let country of baseCountryList">
                        <div class="col-3">
                            <div type="text" class="form-control">
                                {{basecountrySelection.selected_country_name}}</div>
                        </div>
                        <div class="col">
                            <div type="text" class="form-control">
                                {{basecountrySelection.selected_country_currency}}</div>
                        </div>
                        <div class="col">
                            <div type="text" class="form-control">1</div>
                        </div>
                        <div class="col-3">
                            <div type="text" class="form-control">
                                {{country.selected_country_name}}</div>
                        </div>
                        <div class="col">
                            <div type="text" class="form-control">
                                {{country.selected_country_currency}}</div>
                        </div>
                        <div class="col">
                            <input type="number" class="form-control" [name]="country.selected_country_name"
                                [(ngModel)]="country.selected_country_currency_convert_value"
                                (change)="currency(country,$event)">
                        </div>
                        <div class="col-1">
                            <button type="button" class="btn btn-success btn-sm px-1 py-0 m-2"> Reset </button>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </section>
</div>