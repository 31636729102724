<div class="loaderBackground" *ngIf="loader">
    <div class="loaderparent"></div>
    <div class="loader">
        <div class="spinner-grow text-success mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success  mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success  mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>
<div>
    <section class="upper">
        <div>
            <div class="heading text-center">
                <h4 class="text-capitalize">Store Settings</h4>
            </div>
        </div>
        <div class="container-fluid ">
            <div class="row">
                <div class="col-9">
                    <div class="border border-success bg-white py-4">
                        <div class="position-absolute title" style="right: 30px;">
                            <button class="btn p-0 rounded-0 text-success"><i class="fas fa-times-circle"></i></button>
                        </div>
                        <div class="container-fluid" style="z-index: 999;" [ngbNavOutlet]="nav"></div>
                        <div class="row">
                            <div class="col d-flex justify-content-start">
                                <button class="btn btn-lg" *ngIf="activeId > 1" (click)="_handlePrevious()">
                                    <i class="fas fa-arrow-alt-circle-left"></i>
                                </button>
                            </div>
                            <div class="col d-flex justify-content-end">
                                <button class="btn btn-lg" *ngIf="10 > activeId" (click)="_handleNext()">
                                    <i class="fas fa-arrow-alt-circle-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <ul ngbNav #nav="ngbNav" class="col-3 nav-pills sideNavBar h-auto" [(activeId)]="activeId"
                    orientation="vertical">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink routerLink='/store-settings/1'>1 Store Information</a>
                        <ng-template ngbNavContent>
                            <form #createAddess="ngForm">
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">Store Name</label>
                                    <div class="col-sm-5">
                                        <input type="text" class="form-control" name="store_name"
                                            [(ngModel)]="company_info.store_name">
                                    </div>
                                </div>
                                <div class="row" *ngFor="let value of company_info.store_address; index as s">
                                    <div class="col-2 form-group"></div>
                                    <div class="col-sm-6 form-group ">
                                        <button type="button" class="btn m-1" *ngFor="let button of value.buttons"
                                            [class.btn-danger]="button.active" [class.btn-light]="!button.active"
                                            (click)="addressType(button,value)">{{button.name}}</button>
                                    </div>
                                    <div class="col-2">
                                        <button type="button" class="btn" style="font-size: 25px;"
                                            *ngIf="company_info.store_address.length > 1"
                                            (click)="delete(company_info.store_address,s)">
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                        <button type="button" class="btn" style="font-size: 25px;"
                                            *ngIf="s === company_info.store_address.length-1" (click)="addMoreAddess()">
                                            <i class="fas fa-plus-circle"></i>
                                        </button>
                                    </div>
                                    <div class="col-12 form-group ">
                                        <div class="row">
                                            <label class="col-sm-2 col-form-label">Store Address {{s+1}}</label>
                                            <div class="col-sm-5">
                                                <angular-editor name=value{{value.unikid}} [(ngModel)]="value.address">
                                                </angular-editor>
                                                <!-- <textarea class="form-control" cols="30" rows="5"
                                                    name=value{{value.unikid}} [(ngModel)]="value.address"></textarea> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row"
                                    *ngFor="let store_email of company_info.store_email; index as e">
                                    <label class="col-sm-2 col-form-label">Email</label>
                                    <div class="col-sm-5">
                                        <input type="text" class="form-control" name="store_email{{e}}"
                                            [(ngModel)]="store_email.email" #email="ngModel"
                                            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                                            [class.is-invalid]="email.invalid && email.touched">
                                    </div>
                                    <div class="col-2">
                                        <button type="button" class="btn" style="font-size: 25px;"
                                            *ngIf="company_info.store_email.length > 1"
                                            (click)="delete(company_info.store_email,e)">
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                        <button type="button" class="btn" style="font-size: 25px;"
                                            *ngIf="e === company_info.store_email.length-1"
                                            (click)="company_info.store_email.push({email: ''})">
                                            <i class="fas fa-plus-circle"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="form-group row"
                                    *ngFor="let store_phone of company_info.store_phone; index as p">
                                    <label class="col-sm-2 col-form-label">Phone</label>
                                    <div class="col-sm-5">
                                        <input type="text" class="form-control" name="store_phone{{p}}"
                                            [(ngModel)]="store_phone.phone"
                                            pattern="/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/"
                                            #store_phone="ngModel"
                                            [class.is-invalid]="store_phone.invalid && store_phone.touched">
                                        <small>Stores with contact numbers get higher conversion rates</small>
                                    </div>
                                    <div class="col-2">
                                        <button type="button" class="btn" style="font-size: 25px;"
                                            *ngIf="company_info.store_phone.length > 1"
                                            (click)="delete(company_info.store_phone,p)">
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                        <button type="button" class="btn" style="font-size: 25px;"
                                            *ngIf="p === company_info.store_phone.length-1"
                                            (click)="company_info.store_phone.push('')">
                                            <i class="fas fa-plus-circle"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="form-group text-center">
                                    <button type="submit" class="btn btn-success"
                                        (click)="onSubmit('add_store_information','update_store_information',company_info)">submit</button>
                                </div>
                            </form>
                            <section>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Address</th>
                                            <th scope="col">Email Address</th>
                                            <th scope="col">Phone Number</th>
                                            <th colspan="2">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let store_info of store_information_list;index as s">
                                            <th>{{store_info.store_name}}</th>
                                            <td>
                                                <div *ngFor="let address of store_info.store_address"
                                                    [innerHTML]="address.address"></div>
                                            </td>
                                            <td>
                                                <div *ngFor="let store_email of store_info.store_email">
                                                    {{store_email.email}} <span
                                                        *ngIf="store_info.store_email.length > 1">,</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div *ngFor="let store_phone of store_info.store_phone">
                                                    {{store_phone.phone}} <span
                                                        *ngIf="store_info.store_phone.length > 1">,</span>
                                                </div>
                                            </td>
                                            <td>
                                                <button class="btn p-0 text-success"
                                                    (click)="_handleEdit(store_info,s)">
                                                    <i class="fas fa-edit"></i>
                                                </button>
                                            </td>
                                            <td>
                                                <button class="btn p-0 text-danger"
                                                    (click)="_handleDelete(store_info, 'delete_store_information')">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </section>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink routerLink='/store-settings/2'>2 Icon Logo</a>
                        <ng-template ngbNavContent>
                            <app-icon-logo-caption></app-icon-logo-caption>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3">
                        <a ngbNavLink routerLink='/store-settings/3'>3 Customer Care & Store time</a>
                        <ng-template ngbNavContent>
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label">Customer Care No</label>
                                <div class="col-sm-10">
                                    <div class="row">
                                        <div class="col-4 form-group">
                                            <input type="text" class="form-control">
                                        </div>
                                        <div class="form-group col-12">
                                            <label class="col-3">Available from</label>
                                            <ul class="list-group">
                                                <li class="list-group-item border-0">
                                                    <div class="d-flex w-100">
                                                        <div class="w-100 border-0">
                                                            <div class="row">
                                                                <div class="col-1">
                                                                    <label class="custom">
                                                                        <input type="checkbox" (change)="storeClose($event,storeTime.store_week_day_monday)"  
                                                                        [checked]="storeTime.store_week_day_monday.store_week_day_opening_type !=='closed'">
                                                                        <span class="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                                <div class="col-2">
                                                                    <div class="days">
                                                                        {{storeTime.store_week_day_monday.store_week_day_name}}
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <select name="country"
                                                                        class="form-control btn-light"
                                                                        [(ngModel)]="storeTime.store_week_day_monday.store_week_day_opening_type">
                                                                        <option value="24 hours">24 hours</option>
                                                                        <option value="choose">choose beteen yourself
                                                                        </option>
                                                                        <option value="closed">closed</option>
                                                                    </select>
                                                                </div>
                                                                <div class="col"
                                                                    *ngIf="storeTime.store_week_day_monday.store_week_day_opening_type ==='choose'">
                                                                    <div class="row form-group"
                                                                        *ngFor="let timelist of storeTime.store_week_day_monday.store_time; index as t">
                                                                        <div class="col">
                                                                            <select name="country"
                                                                                class="form-control btn-secondary"
                                                                                [(ngModel)]="timelist.store_week_day_starts_from">
                                                                                <option [value]="time"
                                                                                    *ngFor="let time of times">
                                                                                    {{time}}</option>
                                                                            </select>
                                                                        </div>
                                                                        <div
                                                                            class="col-1 d-flex justify-content-center align-items-center">
                                                                            <div class="gap"></div>
                                                                        </div>
                                                                        <div class="col">
                                                                            <select name="country"
                                                                                class="form-control btn-secondary"
                                                                                [(ngModel)]="timelist.store_week_day_ends_at">
                                                                                <option [value]="time"
                                                                                    *ngFor="let time of times">
                                                                                    {{time}}</option>
                                                                            </select>
                                                                        </div>
                                                                        <div class="col-2 form-group d-flex">
                                                                            <button type="button" class="btn w-100 p-0" *ngIf="storeTime.store_week_day_monday.store_time.length > 1"
                                                                            (click)="delete(storeTime.store_week_day_monday.store_time,t)">
                                                                                <i class="far fa-trash-alt"></i>
                                                                            </button>
                                                                            <button type="button" class="btn w-100 p-0" *ngIf="t === storeTime.store_week_day_monday.store_time.length - 1"
                                                                                (click)="_addMore(storeTime.store_week_day_monday.store_time)">
                                                                                <i class="fas fa-plus"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="list-group-item border-0">
                                                    <div class="d-flex w-100">
                                                        <div class="w-100 border-0">
                                                            <div class="row">
                                                                <div class="col-1">
                                                                    <label class="custom">
                                                                        <input type="checkbox" (change)="storeClose($event,storeTime.store_week_day_tuesday)" 
                                                                          [checked]="storeTime.store_week_day_tuesday.store_week_day_opening_type !=='closed'">
                                                                        <span class="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                                <div class="col-2">
                                                                    <div class="days">
                                                                        {{storeTime.store_week_day_tuesday.store_week_day_name}}
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <select name="country"
                                                                        class="form-control btn-light"
                                                                        [(ngModel)]="storeTime.store_week_day_tuesday.store_week_day_opening_type">
                                                                        <option value="24 hours">24 hours</option>
                                                                        <option value="choose">choose beteen yourself
                                                                        </option>
                                                                        <option value="closed">closed</option>
                                                                    </select>
                                                                </div>
                                                                <div class="col"
                                                                    *ngIf="storeTime.store_week_day_tuesday.store_week_day_opening_type ==='choose'">
                                                                    <div class="row form-group"
                                                                        *ngFor="let timelist of storeTime.store_week_day_tuesday.store_time; index as t">
                                                                        <div class="col">
                                                                            <select name="country"
                                                                                class="form-control btn-secondary"
                                                                                [(ngModel)]="timelist.store_week_day_starts_from">
                                                                                <option [value]="time"
                                                                                    *ngFor="let time of times">
                                                                                    {{time}}</option>
                                                                            </select>
                                                                        </div>
                                                                        <div
                                                                            class="col-1 d-flex justify-content-center align-items-center">
                                                                            <div class="gap"></div>
                                                                        </div>
                                                                        <div class="col">
                                                                            <select name="country"
                                                                                class="form-control btn-secondary"
                                                                                [(ngModel)]="timelist.store_week_day_ends_at">
                                                                                <option [value]="time"
                                                                                    *ngFor="let time of times">
                                                                                    {{time}}</option>
                                                                            </select>
                                                                        </div>
                                                                        <div class="col-2 form-group d-flex">
                                                                            <button type="button" class="btn w-100 p-0" *ngIf="storeTime.store_week_day_tuesday.store_time.length > 1"
                                                                            (click)="delete(storeTime.store_week_day_tuesday.store_time,t)">
                                                                                <i class="far fa-trash-alt"></i>
                                                                            </button>
                                                                            <button type="button" class="btn w-100 p-0" *ngIf="t === storeTime.store_week_day_tuesday.store_time.length - 1"
                                                                                (click)="_addMore(storeTime.store_week_day_tuesday.store_time)">
                                                                                <i class="fas fa-plus"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="list-group-item border-0">
                                                    <div class="d-flex w-100">
                                                        <div class="w-100 border-0">
                                                            <div class="row">
                                                                <div class="col-1">
                                                                    <label class="custom">
                                                                        <input type="checkbox"  (change)="storeClose($event,storeTime.store_week_day_wednesday)"
                                                                        [checked]="storeTime.store_week_day_wednesday.store_week_day_opening_type !=='closed'">
                                                                        <span class="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                                <div class="col-2">
                                                                    <div class="days">
                                                                        {{storeTime.store_week_day_wednesday.store_week_day_name}}
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <select name="country"
                                                                        class="form-control btn-light"
                                                                        [(ngModel)]="storeTime.store_week_day_wednesday.store_week_day_opening_type">
                                                                        <option value="24 hours">24 hours</option>
                                                                        <option value="choose">choose beteen yourself
                                                                        </option>
                                                                        <option value="closed">closed</option>
                                                                    </select>
                                                                </div>
                                                                <div class="col"
                                                                    *ngIf="storeTime.store_week_day_wednesday.store_week_day_opening_type ==='choose'">
                                                                    <div class="row form-group"
                                                                        *ngFor="let timelist of storeTime.store_week_day_wednesday.store_time; index as t">
                                                                        <div class="col">
                                                                            <select name="country"
                                                                                class="form-control btn-secondary"
                                                                                [(ngModel)]="timelist.store_week_day_starts_from">
                                                                                <option [value]="time"
                                                                                    *ngFor="let time of times">
                                                                                    {{time}}</option>
                                                                            </select>
                                                                        </div>
                                                                        <div
                                                                            class="col-1 d-flex justify-content-center align-items-center">
                                                                            <div class="gap"></div>
                                                                        </div>
                                                                        <div class="col">
                                                                            <select name="country"
                                                                                class="form-control btn-secondary"
                                                                                [(ngModel)]="timelist.store_week_day_ends_at">
                                                                                <option [value]="time"
                                                                                    *ngFor="let time of times">
                                                                                    {{time}}</option>
                                                                            </select>
                                                                        </div>
                                                                        <div class="col-2 form-group d-flex">
                                                                            <button type="button" class="btn w-100 p-0" *ngIf="storeTime.store_week_day_wednesday.store_time.length > 1"
                                                                            (click)="delete(storeTime.store_week_day_wednesday.store_time,t)">
                                                                                <i class="far fa-trash-alt"></i>
                                                                            </button>
                                                                            <button type="button" class="btn w-100 p-0" *ngIf="t === storeTime.store_week_day_wednesday.store_time.length - 1"
                                                                                (click)="_addMore(storeTime.store_week_day_wednesday.store_time)">
                                                                                <i class="fas fa-plus"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="list-group-item border-0">
                                                    <div class="d-flex w-100">
                                                        <div class="w-100 border-0">
                                                            <div class="row">
                                                                <div class="col-1">
                                                                    <label class="custom">
                                                                        <input type="checkbox"  (change)="storeClose($event,storeTime.store_week_day_thursday)"
                                                                        [checked]="storeTime.store_week_day_thursday.store_week_day_opening_type !=='closed'">
                                                                        <span class="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                                <div class="col-2">
                                                                    <div class="days">
                                                                        {{storeTime.store_week_day_thursday.store_week_day_name}}
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <select name="country"
                                                                        class="form-control btn-light"
                                                                        [(ngModel)]="storeTime.store_week_day_thursday.store_week_day_opening_type">
                                                                        <option value="24 hours">24 hours</option>
                                                                        <option value="choose">choose beteen yourself
                                                                        </option>
                                                                        <option value="closed">closed</option>
                                                                    </select>
                                                                </div>
                                                                <div class="col"
                                                                    *ngIf="storeTime.store_week_day_thursday.store_week_day_opening_type ==='choose'">
                                                                    <div class="row form-group"
                                                                        *ngFor="let timelist of storeTime.store_week_day_thursday.store_time; index as t">
                                                                        <div class="col">
                                                                            <select name="country"
                                                                                class="form-control btn-secondary"
                                                                                [(ngModel)]="timelist.store_week_day_starts_from">
                                                                                <option [value]="time"
                                                                                    *ngFor="let time of times">
                                                                                    {{time}}</option>
                                                                            </select>
                                                                        </div>
                                                                        <div
                                                                            class="col-1 d-flex justify-content-center align-items-center">
                                                                            <div class="gap"></div>
                                                                        </div>
                                                                        <div class="col">
                                                                            <select name="country"
                                                                                class="form-control btn-secondary"
                                                                                [(ngModel)]="timelist.store_week_day_ends_at">
                                                                                <option [value]="time"
                                                                                    *ngFor="let time of times">
                                                                                    {{time}}</option>
                                                                            </select>
                                                                        </div>
                                                                        <div class="col-2 form-group d-flex">
                                                                            <button type="button" class="btn w-100 p-0" *ngIf="storeTime.store_week_day_thursday.store_time.length > 1"
                                                                            (click)="delete(storeTime.store_week_day_thursday.store_time,t)">
                                                                                <i class="far fa-trash-alt"></i>
                                                                            </button>
                                                                            <button type="button" class="btn w-100 p-0" *ngIf="t === storeTime.store_week_day_thursday.store_time.length - 1"
                                                                                (click)="_addMore(storeTime.store_week_day_thursday.store_time)">
                                                                                <i class="fas fa-plus"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="list-group-item border-0">
                                                    <div class="d-flex w-100">
                                                        <div class="w-100 border-0">
                                                            <div class="row">
                                                                <div class="col-1">
                                                                    <label class="custom">
                                                                        <input type="checkbox"  (change)="storeClose($event,storeTime.store_week_day_fryday)"
                                                                         [checked]="storeTime.store_week_day_fryday.store_week_day_opening_type !=='closed'">
                                                                        <span class="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                                <div class="col-2">
                                                                    <div class="days">
                                                                        {{storeTime.store_week_day_fryday.store_week_day_name}}
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <select name="country"
                                                                        class="form-control btn-light"
                                                                        [(ngModel)]="storeTime.store_week_day_fryday.store_week_day_opening_type">
                                                                        <option value="24 hours">24 hours</option>
                                                                        <option value="choose">choose beteen yourself
                                                                        </option>
                                                                        <option value="closed">closed</option>
                                                                    </select>
                                                                </div>
                                                                <div class="col"
                                                                    *ngIf="storeTime.store_week_day_fryday.store_week_day_opening_type ==='choose'">
                                                                    <div class="row form-group"
                                                                        *ngFor="let timelist of storeTime.store_week_day_fryday.store_time; index as t">
                                                                        <div class="col">
                                                                            <select name="country"
                                                                                class="form-control btn-secondary"
                                                                                [(ngModel)]="timelist.store_week_day_starts_from">
                                                                                <option [value]="time"
                                                                                    *ngFor="let time of times">
                                                                                    {{time}}</option>
                                                                            </select>
                                                                        </div>
                                                                        <div
                                                                            class="col-1 d-flex justify-content-center align-items-center">
                                                                            <div class="gap"></div>
                                                                        </div>
                                                                        <div class="col">
                                                                            <select name="country"
                                                                                class="form-control btn-secondary"
                                                                                [(ngModel)]="timelist.store_week_day_ends_at">
                                                                                <option [value]="time"
                                                                                    *ngFor="let time of times">
                                                                                    {{time}}</option>
                                                                            </select>
                                                                        </div>
                                                                        <div class="col-2 form-group d-flex">
                                                                            <button type="button" class="btn w-100 p-0" *ngIf="storeTime.store_week_day_fryday.store_time.length > 1"
                                                                            (click)="delete(storeTime.store_week_day_fryday.store_time,t)">
                                                                                <i class="far fa-trash-alt"></i>
                                                                            </button>
                                                                            <button type="button" class="btn w-100 p-0" *ngIf="t === storeTime.store_week_day_fryday.store_time.length - 1"
                                                                                (click)="_addMore(storeTime.store_week_day_fryday.store_time)">
                                                                                <i class="fas fa-plus"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="list-group-item border-0">
                                                    <div class="d-flex w-100">
                                                        <div class="w-100 border-0">
                                                            <div class="row">
                                                                <div class="col-1">
                                                                    <label class="custom">
                                                                        <input type="checkbox" (change)="storeClose($event,storeTime.store_week_day_saturday)"
                                                                         [checked]="storeTime.store_week_day_saturday.store_week_day_opening_type !=='closed'">
                                                                        <span class="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                                <div class="col-2">
                                                                    <div class="days">
                                                                        {{storeTime.store_week_day_saturday.store_week_day_name}}
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <select name="country"
                                                                        class="form-control btn-light"
                                                                        [(ngModel)]="storeTime.store_week_day_saturday.store_week_day_opening_type">
                                                                        <option value="24 hours">24 hours</option>
                                                                        <option value="choose">choose beteen yourself
                                                                        </option>
                                                                        <option value="closed">closed</option>
                                                                    </select>
                                                                </div>
                                                                <div class="col"
                                                                    *ngIf="storeTime.store_week_day_saturday.store_week_day_opening_type ==='choose'">
                                                                    <div class="row form-group"
                                                                        *ngFor="let timelist of storeTime.store_week_day_saturday.store_time; index as t">
                                                                        <div class="col">
                                                                            <select name="country"
                                                                                class="form-control btn-secondary"
                                                                                [(ngModel)]="timelist.store_week_day_starts_from">
                                                                                <option [value]="time"
                                                                                    *ngFor="let time of times">
                                                                                    {{time}}</option>
                                                                            </select>
                                                                        </div>
                                                                        <div
                                                                            class="col-1 d-flex justify-content-center align-items-center">
                                                                            <div class="gap"></div>
                                                                        </div>
                                                                        <div class="col">
                                                                            <select name="country"
                                                                                class="form-control btn-secondary"
                                                                                [(ngModel)]="timelist.store_week_day_ends_at">
                                                                                <option [value]="time"
                                                                                    *ngFor="let time of times">
                                                                                    {{time}}</option>
                                                                            </select>
                                                                        </div>
                                                                        <div class="col-2 form-group d-flex">
                                                                            <button type="button" class="btn w-100 p-0" *ngIf="storeTime.store_week_day_saturday.store_time.length > 1"
                                                                            (click)="delete(storeTime.store_week_day_saturday.store_time,t)">
                                                                                <i class="far fa-trash-alt"></i>
                                                                            </button>
                                                                            <button type="button" class="btn w-100 p-0" *ngIf="t === storeTime.store_week_day_saturday.store_time.length - 1"
                                                                                (click)="_addMore(storeTime.store_week_day_saturday.store_time)">
                                                                                <i class="fas fa-plus"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="list-group-item border-0">
                                                    <div class="d-flex w-100">
                                                        <div class="w-100 border-0">
                                                            <div class="row">
                                                                <div class="col-1">
                                                                    <label class="custom">
                                                                        <input type="checkbox"  (change)="storeClose($event,storeTime.store_week_day_sunday)"
                                                                         [checked]="storeTime.store_week_day_sunday.store_week_day_opening_type !=='closed'">
                                                                        <span class="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                                <div class="col-2">
                                                                    <div class="days">
                                                                        {{storeTime.store_week_day_sunday.store_week_day_name}}
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <select name="country"
                                                                        class="form-control btn-light"
                                                                        [(ngModel)]="storeTime.store_week_day_sunday.store_week_day_opening_type">
                                                                        <option value="24 hours">24 hours</option>
                                                                        <option value="choose">choose beteen yourself
                                                                        </option>
                                                                        <option value="closed">closed</option>
                                                                    </select>
                                                                </div>
                                                                <div class="col"
                                                                    *ngIf="storeTime.store_week_day_sunday.store_week_day_opening_type ==='choose'">
                                                                    <div class="row form-group"
                                                                        *ngFor="let timelist of storeTime.store_week_day_sunday.store_time; index as t">
                                                                        <div class="col">
                                                                            <select name="country"
                                                                                class="form-control btn-secondary"
                                                                                [(ngModel)]="timelist.store_week_day_starts_from">
                                                                                <option [value]="time"
                                                                                    *ngFor="let time of times">
                                                                                    {{time}}</option>
                                                                            </select>
                                                                        </div>
                                                                        <div
                                                                            class="col-1 d-flex justify-content-center align-items-center">
                                                                            <div class="gap"></div>
                                                                        </div>
                                                                        <div class="col">
                                                                            <select name="country"
                                                                                class="form-control btn-secondary"
                                                                                [(ngModel)]="timelist.store_week_day_ends_at">
                                                                                <option [value]="time"
                                                                                    *ngFor="let time of times">
                                                                                    {{time}}</option>
                                                                            </select>
                                                                        </div>
                                                                        <div class="col-2 form-group d-flex">
                                                                            <button type="button" class="btn w-100 p-0" *ngIf="storeTime.store_week_day_sunday.store_time.length > 1"
                                                                            (click)="delete(storeTime.store_week_day_sunday.store_time,t)">
                                                                                <i class="far fa-trash-alt"></i>
                                                                            </button>
                                                                            <button type="button" class="btn w-100 p-0" *ngIf="t === storeTime.store_week_day_sunday.store_time.length - 1"
                                                                                (click)="_addMore(storeTime.store_week_day_sunday.store_time)">
                                                                                <i class="fas fa-plus"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group text-center">
                                <button class="btn btn-success"
                                    (click)="onSubmit('add_store_timing','update_store_timing',storeTime)">submit</button>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="4">
                        <a ngbNavLink routerLink='/store-settings/4'>4 SKU & Weight setting</a>
                        <ng-template ngbNavContent>
                            <div class="form-group">
                                <h3>SKU & Dimension Setting</h3>
                                <small>Configure what currencies customers will see and be charged in on your
                                    store</small>
                            </div>
                            <form class="col-6 mx-auto">
                                <h4>SKU Setting</h4>
                                <div class="row form-group">
                                    <span class="col-3">SKU Generate</span>
                                    <small class="col-2">Manual</small>
                                    <label class="switch">
                                        <input type="checkbox"  [checked]="storeTime.store_week_day_saturday.store_week_day_opening_type !=='closed'" class="custom-control-input" name="store_generate_auto"
                                        [(ngModel)]="skuWeight.store_generate_auto">
                                        <span class="slider"></span>
                                    </label>
                                    <small class="col-2">Automatic</small>
                                </div>
                                <div class="form-group row" *ngIf="!skuWeight.store_generate_auto">
                                    <label class="col-sm-4 col-form-label">SKU prefix</label>
                                    <div class="col">
                                        <input type="text" class="form-control" name="store_sku_prefix"
                                            [(ngModel)]="skuWeight.store_sku_prefix">
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="!skuWeight.store_generate_auto">
                                    <label class="col-sm-4 col-form-label">Manual Start from</label>
                                    <div class="col">
                                        <input type="text" class="form-control" name="store_sku_manual_start_from"
                                            [(ngModel)]="skuWeight.store_sku_manual_start_from">
                                    </div>
                                </div>
                                <h4>Physical Dimension Settings</h4>
                                <div class="form-group row">
                                    <label class="col-sm-5 col-form-label">Weight Measurement</label>
                                    <div class="col">
                                        <input type="text" class="form-control" name="store_weight_mesurements"
                                            [(ngModel)]="skuWeight.store_weight_mesurements">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-5 col-form-label">Length Measurement</label>
                                    <div class="col">
                                        <input type="text" class="form-control" name="store_length_mesurements"
                                            [(ngModel)]="skuWeight.store_length_mesurements">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-5 col-form-label">Decimal Token</label>
                                    <div class="col">
                                        <input type="text" class="form-control" name="store_decimal_token"
                                            [(ngModel)]="skuWeight.store_decimal_token">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-5 col-form-label">Thousands Token</label>
                                    <div class="col">
                                        <input type="text" class="form-control" name="store_thousand_token"
                                            [(ngModel)]="skuWeight.store_thousand_token">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-5 col-form-label">Decimal Places</label>
                                    <div class="col">
                                        <input type="text" class="form-control" name="store_decimal_places"
                                            [(ngModel)]="skuWeight.store_decimal_places">
                                    </div>
                                </div>
                                <div class="col-12 text-center">
                                    <button class="btn btn-success"
                                        (click)="onWeightSku('add_store_sku_and_weight','update_sku_and_weight',skuWeight)">Submit</button>
                                </div>
                            </form>
                            <section>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">SKU Generate</th>
                                            <th scope="col">SKU prefix</th>
                                            <th scope="col">Manual Start from</th>
                                            <th scope="col">Weight Measurement</th>
                                            <th scope="col">Length Measurement</th>
                                            <th scope="col">Decimal Token</th>
                                            <th scope="col">Thousands Token</th>
                                            <th scope="col">Decimal Places</th>
                                            <th colspan="2">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let store_info of store_sku_and_weight_list;index as s">
                                            <td>{{store_info.store_generate_auto}}</td>
                                            <td>{{store_info.store_sku_prefix}}</td>
                                            <td>{{store_info.store_sku_manual_start_from}}</td>
                                            <td>{{store_info.store_weight_mesurements}}</td>
                                            <td>{{store_info.store_length_mesurements}}</td>
                                            <td>{{store_info.store_decimal_token}}</td>
                                            <td>{{store_info.store_thousand_token}}</td>
                                            <td>{{store_info.store_decimal_places}}</td>
                                            <td>
                                                <button class="btn p-0 text-success"
                                                    (click)="_edit(store_info,s,store_sku_and_weight_list)">
                                                    <i class="fas fa-edit"></i>
                                                </button>
                                            </td>
                                            <td>
                                                <button class="btn p-0 text-danger"
                                                    (click)="_handleDelete(store_info, 'delete_sku_and_weight')">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </section>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="5">
                        <a ngbNavLink routerLink='/store-settings/5'>5 Gst Settings</a>
                        <ng-template ngbNavContent>
                            <app-gst></app-gst>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="6">
                        <a ngbNavLink routerLink='/store-settings/6'>6 Pre Order & Out Of Stock Settings</a>
                        <ng-template ngbNavContent>
                            <div class="form-group">
                                <h3>Out Of Stock</h3>
                            </div>
                            <form class="col-6 mx-auto">
                                <h4>Out of Stock Setting</h4>
                                <div class="form-group row">
                                    <label class="col-auto col-form-label">Out of stock products display in shop</label>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="oos_product_display_in_shop"
                                            value="true" [(ngModel)]="store_pre_order_and_oos.response[0].oos_product_display_in_shop">
                                        <label class="form-check-label">Yes</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="oos_product_display_in_shop"
                                            value="false" [(ngModel)]="store_pre_order_and_oos.response[0].oos_product_display_in_shop">
                                        <label class="form-check-label">No</label>
                                    </div>
                                </div>
                                <h4>Pre Order Setting</h4>
                                <div class="form-group row">
                                    <label class="col-auto col-form-label">Pre order products display in shop</label>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="pre_order_product_display_in_shop"
                                            value="true" [(ngModel)]="store_pre_order_and_oos.response[0].pre_order_product_display_in_shop">
                                        <label class="form-check-label">Yes</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="pre_order_product_display_in_shop"
                                            value="false" [(ngModel)]="store_pre_order_and_oos.response[0].pre_order_product_display_in_shop">
                                        <label class="form-check-label">No</label>
                                    </div>
                                </div>
                                <h4>Gift Wrap Settings</h4>
                                <div class="form-group row">
                                    <label class="col-auto col-form-label">Gift Wrap Avaliable in shop</label>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="gift_wrap_available_in_shop"
                                            value="true" [(ngModel)]="store_pre_order_and_oos.response[0].gift_wrap_available_in_shop">
                                        <label class="form-check-label">Yes</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="gift_wrap_available_in_shop"
                                            value="false" [(ngModel)]="store_pre_order_and_oos.response[0].gift_wrap_available_in_shop">
                                        <label class="form-check-label">No</label>
                                    </div>
                                </div>
                                <div class="row" class="text-center">
                                    <button class="btn btn-success" (click)="_preOrderOof()">Submit</button>
                                </div>
                            </form>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="7">
                        <a ngbNavLink routerLink='/store-settings/7'>7 Shipping</a>
                        <ng-template ngbNavContent>
                            <app-shipping></app-shipping>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="8">
                        <a ngbNavLink routerLink='/store-settings/8'>8 Delivary</a>
                        <ng-template ngbNavContent>
                            <app-delivary-garruenty ></app-delivary-garruenty>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="9">
                        <a ngbNavLink routerLink='/store-settings/9'>9 Delivary Gurrenty</a>
                        <ng-template ngbNavContent>
                            <app-delivary-garrunty></app-delivary-garrunty>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="10">
                        <a ngbNavLink routerLink='/store-settings/10'>10 Return Policy</a>
                        <ng-template ngbNavContent>
                            <app-return-policy></app-return-policy>
                        </ng-template>
                    </li>
                </ul>
            </div>
        </div>
    </section>
    <section class="upper my-4">
        <div class="container">
            <ul class="list-group p-0 example-list border rounded-0 border-success ">
                <li class="list-group-item border-0">
                    <div class="d-flex w-100">
                        <div class="list-group-item w-100 border-0">
                            <div class="row">
                                <div class="col-3">
                                    <div type="text" class="form-control">name</div>
                                </div>
                                <div class="col">
                                    <div type="text" class="form-control">currency</div>
                                </div>
                                <div class="col ">
                                    <div type="text" class="form-control">1</div>
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" name="" ngModel required>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </section>
</div>