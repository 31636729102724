import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FetureClass } from "../feture-product-setting/feture-class";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ApiService } from 'src/app/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
@Component({
  selector: 'app-feture-product-setting',
  templateUrl: './feture-product-setting.component.html',
  styleUrls: ['./feture-product-setting.component.css']
})
export class FetureProductSettingComponent implements OnInit {
  imgType = true;

  active = 1

  fetureMenu = []
  countries: unknown;

  fetureForm = new FetureClass('', '', '', '', '', '','');
  catagories: any = []
  fetureCatMenu: any;

  // getCatagory(country) {
  //   this.api.post(`get_footer_cat_subcat`, { "category_country_id": country.split('-')[0], "category_country_name": country.split('-')[1] }).subscribe(
  //     data => {
  //       this.catagories = Object.values(data)[2]
  //       this.catagories.map((data) => {
  //         data.status = false
  //         if (data.child_cat && data.child_cat.length > 0) {
  //           data.child_cat.map((subcat) => {
  //             subcat.status = false
  //             if (subcat.child_cat && subcat.child_cat.length > 0) {
  //               subcat.child_cat.map((ultimateSerch) => {
  //                 ultimateSerch.status = false
  //               })
  //             }
  //           })
  //         }
  //       })
  //     },
  //     error => console.log('Error!', error)
  //   )
  // }

  home() {
    console.log('home')
    this.fetureForm.apply_to = 'home'
  }

  Showsubcat(val) {
    val.click = !val.click
  }

  onCat(item) {
    this.fetureForm.apply_to = item.category_name
  }

  open(content) {
    this.modalService.open(content, { centered: true });
  }

  productList: any = []

  manageProduct(api, id) {
    this.router.navigateByUrl(`/product-manage/${api}/${id}/1`);
  }

  edit(item,form) {
    console.log(this.active)
    console.log(item)
    if (this.active === 1) {
      let country = []
      country.push(item.country_id)
      country.push(item.featured_product_country_name)
      this.fetureForm.country = country.join('-')
      this.fetureMenu.splice(this.fetureMenu.indexOf(item),1)
      this.fetureForm.img = item.featured_product_image
      this.fetureForm.featuredTag = item.featured_product_tag
      this.fetureForm.featuredName = item.featured_product_name
      this.fetureForm.apply_to ='home'
      this.fetureForm._id = item._id
    }else{
      this.fetureCatMenu.splice(this.fetureMenu.indexOf(item),1)
      let country = []
      country.push(item.country_id)
      country.push(item.featured_product_category_country_name)
      this.fetureForm.country = country.join('-')
      this.fetureForm._id = item._id
      this.fetureForm.img = item.featured_product_category_image
      this.fetureForm.featuredTag = item.featured_product_category_tag
      this.fetureForm.featuredName = item.featured_product_category_name
      this.fetureForm.apply_to = item.featured_product_category_category_name
      this.fetureForm.apply_id = item.featured_product_category_category_id
    }
  }

  onSubmit(form: NgForm) {
    let country = form.value.country.split('-');
    if (this.fetureForm.apply_to === 'home') {
      let body:any = {
        "country_id": country[0],
        "featured_product_country_name": country[1],
        "featured_product_image": this.fetureForm.img,
        "featured_product_tag": this.fetureForm.featuredTag,
        "featured_product_name": this.fetureForm.featuredName
      }
      if (this.fetureForm._id) {
        body._id = this.fetureForm._id
        this.api.put(`update_featured_product`, body).subscribe(
          data => {
            console.log('!success', data)
            window.location.reload()
          },
          error => console.log(error)
        )
      } else {
        this.api.post(`add_featured_product`, body).subscribe(
          data => {
            console.log('!success', data)
            form.reset();
            this.fetureForm.img = '';
            window.location.reload()
          },
          error => console.log(error)
        )
      }
    } else {
      let body:any = {
        "country_id": country[0],
        "featured_product_category_country_name": country[1],
        "featured_product_category_image": this.fetureForm.img,
        "featured_product_category_tag": this.fetureForm.featuredTag,
        // "featured_product_category_position": "formData.featured_product_category_position",
        "featured_product_category_name": this.fetureForm.featuredName,
        "featured_product_category_category_name": this.fetureForm.apply_to,
        "featured_product_category_category_id": this.fetureForm.apply_id
      }
      if (this.fetureForm._id) {
        body._id = this.fetureForm._id
        this.api.put(`update_featured_product_category`, body).subscribe(
          data => {
            console.log('!success', data)
            window.location.reload()
          },
          error => console.log(error)
        )
      }else{
        this.api.post(`add_featured_product_category`, body).subscribe(
          data => {
            console.log('!success', data)
            form.reset();
            this.fetureForm.img = '';
            window.location.reload()
          },
          error => console.log(error)
        )
      }
    }
  }

  _handelDelete(api, id) {
    this.api.post(api, id).subscribe(
      data => {
        console.log(data)
        if (data.status === 'success') {
          window.location.reload()
        }
      },
      error => console.log(error)
    )
  }

  _handleView(api, id) {
    this.api.put(api, id).subscribe(
      data => {
        console.log(data)
        if (data.status === 'success') {
          window.location.reload()
        }
      },
      error => console.log(error)
    )
  }

  upload(event) {
    // if(this.fetureForm.img !== '' && this.fetureForm.img !==null){
    //   let public_id = this.fetureForm.img.split("/")[8].split(".")[0];
    //   this.api.post(`remove_image`, {"image_id":`brands/${public_id}`}).subscribe(
    //     data => {
    //       console.log(data)
    //     },
    //     error => console.log(error)
    //   )
    // }
    const file: File = event.target.files[0];
    const formData = new FormData();
    formData.append('featured_product_image', file)
    console.log(file.name)
    this.api.post(`add_featured_product_image`, formData).subscribe(
      data => {
        let val = Object.values(data)[2];
        this.fetureForm.img = val
        console.log(this.fetureForm.img)
      },
      error => console.log(error)
    )
  }



  drop(event: CdkDragDrop<string[]>, list) {
    moveItemInArray(list, event.previousIndex, event.currentIndex);
  }

  constructor(private api: ApiService, private modalService: NgbModal, private router: Router) { }

  ngOnInit(): void {
    this.api.get(`selected_country_list`).subscribe(
      data => {
        this.countries = Object.values(data)[2];
        console.log(this.countries)
      },
      error => console.log('Error!', error)
    )
    this.api.get(`featured_product_list`).subscribe(
      data => {
        this.fetureMenu = Object.values(data)[2];
        console.log(this.fetureMenu)
      },
      error => console.log('Error!', error)
    )
    this.api.get(`featured_product_category_list`).subscribe(
      data => {
        this.fetureCatMenu = Object.values(data)[2].result;
        console.log(this.fetureCatMenu)
      },
      error => console.log('Error!', error)
    )
    this.api.get(`populate_all_category`).subscribe(
      data => {
        this.catagories = Object.values(data)[2]
      },
      error => console.log('Error!', error)
    )
  }

}
