import { Component, OnInit,ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/api.service';
import {RollOnTop} from './roll-on-top'
@Component({
  selector: 'app-roll-on-top',
  templateUrl: './roll-on-top.component.html',
  styleUrls: ['./roll-on-top.component.css'],
})
export class RollOnTopComponent implements OnInit {


  rollOnForm = new RollOnTop('','')

  rollOnList = [
    {rollOnTop: 'About1233 Shop', country:'India',},
    {rollOnTop: 'About1111 Shop', country:'India',},
    {rollOnTop: 'About222 Shop', country:'India',},
    {rollOnTop: 'About22 Shop', country:'India',},
    {rollOnTop: 'About123566 Shop', country:'India',},
  ]


  offerList = [
    {cuponOfferValue:'5',couponOfferTag: 'About Shop',description:'1234', country:'India', couponOfferCode: 'new30',
   ValidFrom: new Date(), ValidUpto: new Date(), OfferUseTime:0},
    {cuponOfferValue:'5', couponOfferTag: 'About Shop',description:'1234', country:'India', couponOfferCode: 'new30',
   ValidFrom: new Date(), ValidUpto: new Date(), OfferUseTime:0},
    {cuponOfferValue:'5', couponOfferTag: 'About Shop',description:'1234', country:'India', couponOfferCode: 'new30',
   ValidFrom: new Date(), ValidUpto: new Date(), OfferUseTime:0},
    {cuponOfferValue:'5', couponOfferTag: 'About Shop',description:'1234', country:'India', couponOfferCode: 'new30',
   ValidFrom: new Date(), ValidUpto: new Date(), OfferUseTime:0},
    {cuponOfferValue:'15', couponOfferTag: 'About Shop',description:'1234', country:'India', couponOfferCode: 'new30',
   ValidFrom: new Date(), ValidUpto: new Date(), OfferUseTime:0},
  ]


  catagories = [
    {catName: 'Women Ware',button:true, click:false, value:"", subcatagory: [
      {catName: 'Saree',button:true, click:false, subcatagory: [{
        name: 'cotton Saree', button: false,value:'',
      }]},
      {catName: 'Kurti',button:true, click:false, value:''},
      {catName: 'Dress',button:true, click:false, value:''},
    ]},
    {catName: 'men Ware',button:true, click:false, value:"", subcatagory: 
      [
        {catName: 'Shirt',button:true, click:false,subcatagory: [{
          name: 'cotton Shirt', button: false, value:'',
        }]},
        {catName: 'Jeans',button:true, click:false, value:''},
      ]
    },
    {catName: 'Child Ware',button:true, click:false, value:"", subcatagory: 
      [
        {catName: 'Top',button:true, click:false,subcatagory: [{
          name: 'cotton Top', button: false, value:''
        }]},
        {catName: 'skirt',button:true, click:false, value:''},
      ]
    },
  ]

  Showcat(val){
    this.catagories[val].click = !this.catagories[val].click;
  }
  Showsubcat(val,val2){
    this.catagories[val].subcatagory[val2].click = !this.catagories[val].subcatagory[val2].click;
  }

  delete(value,list){
    list.splice(list.indexOf(value),1);
  }

  updaterollOn(value){
    this.rollOnForm = value;
    this.rollOnList.splice(this.rollOnList.indexOf(value),1);
  }

  onSubmit(form: NgForm, list) {
      console.log(form.value);
      list.push(form.value);
      form.reset();
  }

  upload(event) {
 
  }


  open(content,size) {
    this.modalService.open(content, { size: size,scrollable: true  });
  }

  constructor(private api:ApiService,private modalService: NgbModal) { }

  countries;
    ngOnInit(): void {
      this.api.get(`get_countries`).subscribe(
        data =>{
          this.countries = Object.values(data);
          this.api.countryShare(this.countries);
          console.log(this.countries)
        }, 
        error => console.log('Error!', error)
      )
    }

}
