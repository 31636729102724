export class TypeOfProduct {
    constructor(
        public product_type: String,
        public main_id: string,
        public type_of_product_gst : Array<Object>,
        public attribute_name: String,
        public attribute_values : Array<Object>,
        public add_on_details: Array<Object>,
        public varient_name: string,
        public varient_description: string,
        public varImg : any,
        public mesurementImg : any,
        public custom_varients: string,
    ){}
}
