import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { from } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { IconLogo } from './icon-logo';

@Component({
  selector: 'app-icon-logo-caption',
  templateUrl: './icon-logo-caption.component.html',
  styleUrls: ['./icon-logo-caption.component.css']
})
export class IconLogoCaptionComponent implements OnInit {

  imgType = true;
  countries ;

  iconLogoList= [
    {img:'', country: 'India', logo:'parama fashion',  writeCaption:'Looking Beautiful Everyday !', color: 'red', fontSize:'15px'},
    {img:'', country: 'USA',  logo:'parama fashion',  writeCaption:'Looking Beautiful Everyday !', color: 'red', fontSize:'15px'},
    {img:'', country: 'UK',  logo:'parama fashion',  writeCaption:'Looking Beautiful Everyday !',  color: 'red', fontSize:'15px'}
  ]

  logoForm = new IconLogo('selct_icon','','','','','')
  edit
  edit_id
  onIconUpload(event,value) {
    if(value !== '' && value !==null){
      let public_id = value.split("/")[8].split(".")[0];
      this.api.post(`remove_image`, {"image_id":`icon/${public_id}`}).subscribe(
        data => {
          console.log(data)
        },
        error => console.log(error)
      )

    }
    const file:File = event.target.files[0];
    const formData = new FormData();
    formData.append(`icon_image`, file)
      this.api.post(`add_icon_image`,formData).subscribe(
        data=> {
          this.logoForm.icon = Object.values(data)[2];
          console.log(this.logoForm.icon)
        },
        error=> console.log(error)
      )
  }
  onLogoUpload(event,value) {
    console.log(value)
    if(value !== '' && value !==null){
      let public_id = value.split("/")[8].split(".")[0];
      this.api.post(`remove_image`, {"image_id":`logo/${public_id}`}).subscribe(
        data => {
          console.log(data)
        },
        error => console.log(error)
      )

    }
    const file:File = event.target.files[0];
    const formData = new FormData();
    formData.append(`logo_image`, file)
      this.api.post(`add_logo_image`,formData).subscribe(
        data=> {
          this.logoForm.logo = Object.values(data)[2];
          console.log(this.logoForm.icon)
        },
        error=> console.log(error)
      )
  }

  onUpdate(form:NgForm){
    console.log(form.value)
    let country = form.value.country.split('-');
    let body = {
      "_id" : this.edit_id,
      "country_id": country[0],
      // "social_media_country_name": country[1],
      // "social_media_icon":this.sociaInfo.img,
      // "social_media_link": form.value.link,
      "country_name": country[1],
      "ilc_logo": this.logoForm.logo,
      "ilc_icon": this.logoForm.icon,
      "ilc_caption": this.logoForm.caption,
      "ilc_caption_color": this.logoForm.color,
      "ilc_caption_size": this.logoForm.size
    }
    
    this.api.put(`update_icon_logo_caption`,body).subscribe(
      data=> {
        console.log('!success',data);
        this.edit = false;
        this.logoForm.icon='';
        this.logoForm.logo='';
        this.api.get(`icon_logo_caption_list`).subscribe(
          data =>{
            console.log(data)
            this.iconLogoList = Object.values(data)[2]
            console.log(this.iconLogoList)
          }, 
          error => console.log('Error!', error)
          )
        },
        error=> console.log(error)
    )
    //   console.log(form.value);
      form.reset();
  }

  update(value){
    this.edit = true;
    this.logoForm.icon = value.ilc_icon;
    this.edit_id = value._id;
    this.logoForm.country = `${value.country_id}-${value.country_name}`;
    this.logoForm.logo = value.ilc_logo;
    this.logoForm.icon = value.ilc_icon;
    this.logoForm.caption = value.ilc_caption;
    this.logoForm.color = value.ilc_caption_color;
    this.logoForm.size = value.ilc_caption_size;
    console.log(value)
    this.iconLogoList.splice(this.iconLogoList.indexOf(value),1);
  }


  delete(value){
    let body = {
      "_id": value._id
    }
    this.api.post(`delete_icon_logo_caption`,body).subscribe(
      data=> {
        console.log('!success',data);
        this.edit = false;
        this.api.get(`icon_logo_caption_list`).subscribe(
          data =>{
            this.iconLogoList = Object.values(Object.values(data)[2]);
            console.log(this.iconLogoList)
          }, 
          error => console.log('Error!', error)
        )
      },
        error=> console.log(error)
    )
    
  }

  onSubmit(form:NgForm){
    let country = form.value.country.split('-');
    let body = {
      "country_id": country[0],
      "country_name": country[1],
      "ilc_logo": this.logoForm.logo,
      "ilc_icon": this.logoForm.icon,
      "ilc_caption": this.logoForm.caption,
      "ilc_caption_color": this.logoForm.color,
      "ilc_caption_size": this.logoForm.size
    }
      console.log(form.value);
      this.api.post(`add_icon_logo_caption`, body).subscribe(
        data => {
          console.log('!success', data)
          form.reset();
          this.logoForm.icon = '';
          this.logoForm.caption = '';
          this.api.get(`icon_logo_caption_list`).subscribe(
            data =>{
              this.iconLogoList = Object.values(Object.values(data)[2]);
              console.log(this.iconLogoList)
            }, 
            error => console.log('Error!', error)
          )
        },
        error => console.log(error)
      )
      // this.fetureMenu.push(form.value);
      // form.reset();
  }



  constructor(private api:ApiService) { }

  ngOnInit(): void {
    this.api.get(`selected_country_list`).subscribe(
      data =>{
        let country = Object.values(data);
        this.countries = Object.values(country[2]);
        // this.api.countryShare(this.countries);
        console.log(this.countries)
      }, 
      error => console.log('Error!', error)
    )
    this.api.get(`icon_logo_caption_list`).subscribe(
      data =>{
        this.iconLogoList = Object.values(Object.values(data)[2]);
        // this.api.countryShare(this.countries);
        console.log(this.iconLogoList)
      }, 
      error => console.log('Error!', error)
    )
  }

}