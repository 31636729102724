import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {

  customers = []

  first_name;
  last_name;
  countries: any = [];
  ordersPerCustomer = [];
  loader: boolean = true
  id3: string;

  customerGroup;
  customerGroup_id: any;
  customerGroupList = []

  response = {
    status: '',
    message: '',
  }
  id2: string;

  openXl(content, customer) {
    this.modalService.open(content, { scrollable: true, size: 'xl' })
    let full_name = customer.fullname.split(' ')
    this.first_name = full_name[0]
    let last_name = full_name.slice(1)
    this.last_name = last_name.join(' ')
  }

  open(content) {
    this.modalService.open(content)
  }

  customer_orders(customer) {
    this.api.post(`order_list_by_user`, { "user_id": customer._id }).subscribe(
      data => {
        data.response.map((res) => res.bgColor = '')
        data.response.map((res) => {
          if (res.status === "pending") {
            res.bgColor = 'blue'
          } else if (res.status === "confirm") {
            res.bgColor = 'green'
          } else if (res.status === "delivered") {
            res.bgColor = 'yellow'
          } else {
            res.bgColor = 'red'
          }
        })
        this.ordersPerCustomer = data.response
        console.log(data.response)
      }
    )
  }

  statusUpdate(order, status) {
    this.api.post(`order_update`, { "order_id": order._id, "status": status }).subscribe(
      data => {
        order.status = data.response.status
        if (data.response.status === "pending") {
          order.bgColor = 'blue'
        } else if (data.response.status === "confirm") {
          order.bgColor = 'green'
        } else if (data.response.status === "delivered") {
          order.bgColor = 'yellow'
        } else {
          order.bgColor = 'red'
        }
        // if (order.status === "pending") {
        //   bgColor = 
        // }
      }
    )
  }

  close() {
    this.response = {
      status: '',
      message: ''
    }
  }

  _handleCustomerFormGroupDelete() {
    this.customers.map((res) => {
      if (res.status) {
        this.api.post(`remove_user_to_customer_group`, { customer_group_id: this.customerGroup_id, user_id: res._id }).subscribe(
          data => {
            console.log(data)
          },
          error => console.log('Error!', error)
        )
      }
    })
  }

  _handleCustomerFormGroupAdd() {
    let user = []
    this.customers.map((res) => {
      if (res.status) {
        user.push(res._id)
      }
    })
    this.customerGroupList.map((result) => {
      if (result.status && user.length) {
        this.api.post(`add_user_to_customer_group`, { "customer_group_id": result._id, "user_id": user }).subscribe(
          data => {
            console.log(data)
            this.response = data
          },
          error => console.log('Error!', error)
        )
      }
    })

    if (this.customerGroupList.every((res) => res.status === false)) {
      this.response = {
        status: 'danger',
        message: 'No customer Group is selected'
      }
    } else {
      if (!user.length) {
        this.response = {
          status: 'danger',
          message: 'No customer is selected'
        }
      }
    }

  }

  constructor(private modalService: NgbModal, private api: ApiService, private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.loader = true
    this.route.paramMap.subscribe((params: ParamMap) => {
      let id1 = params.get('param1')
      this.id2 = params.get('param2')
      this.id3 = params.get('param3')
      if (id1 === 'customer_list') {
        this.api.post(id1, this.id2).subscribe(
          data => {
            console.log(data)
            if (this.id2 !== ' ') {
              this.api.post(`customer_group_list_with_users`, { "customer_group_id": this.id2 }).subscribe(
                d => {
                  console.log(d.response.customer_group_customers)
                  data.response.map((result) => {
                    if (!d.response.customer_group_customers.find((res) => res._id === result._id)) {
                      this.customers.push(result)
                      this.customerGroup = ''
                      this.customers.map(res => res.status = false)
                      this.loader = false
                    }
                  })
                },
                error => console.log('Error!', error)
              )
            } else {
              this.customers = data.response
              this.loader = false
              this.customerGroup = ''
              this.customers.map(res => res.status = false)
            }
          },
          error => console.log('Error!', error)
        )
      } else {
        this.api.post(id1, { "customer_group_id": this.id2 }).subscribe(
          data => {
            console.log(data)
            this.customerGroup_id = data.response._id
            this.customerGroup = data.response.customer_group_name
            this.customers = data.response.customer_group_customers
            if (this.id3 === '0') {
              this.customers.map(res => res.status = false)
            } else {
              this.customers.map(res => res.status = true)
            }
            this.loader = false
          },
          error => console.log('Error!', error)
        )
      }
    }
    )

    this.api.get(`customer_group_list`).subscribe(
      data => {
        console.log(data)
        this.customerGroupList = Object.values(data)[2];
        this.customerGroupList.map((res) => res.status = false)
        this.loader = false
      },
      error => console.log('Error!', error)
    )
  }

}
