<div class="loaderBackground" *ngIf="loader">
    <div class="loaderparent"></div>
    <div class="loader">
        <div class="spinner-grow text-success mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success  mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success  mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>
<div>
    <section class="upper">
        <div>
            <div class="heading text-center">
                <h4 class="text-capitalize pt-2">Add Products Settings</h4>
            </div>
        </div>
        <div class="container-fluid">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs product-settings">
                <li [ngbNavItem]="1">
                    <a ngbNavLink>Type Of Product</a>
                    <ng-template ngbNavContent>
                        <div class="border border-success bg-white py-4 d-flex">
                            <div class="position-absolute title" style="right: 30px;">
                                <button class="btn p-0 rounded-0">
                                    <i class="fas fa-times-circle"></i>
                                </button>
                            </div>
                            <div class="container">
                                <div class="">
                                    <form #gstForm="ngForm" class="row">
                                        <div class="form-group col-4">
                                            <label class="title">Catagory</label>
                                            <div class="form-control border-black w-100">
                                                <div ngbDropdown class="d-inline-block w-100" #CatDrop="ngbDropdown">
                                                    <button class="btn p-0 btn-block d-flex justify-content-between"
                                                        type="button" ngbDropdownToggle>
                                                        <span>{{type_of_product.product_type}}</span>
                                                    </button>
                                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                                        style="width: 350px; height: 300px; overflow-y: scroll;">
                                                        <div class="bg-success p-2 text-center ">
                                                            <a routerLink="/create-new-catagory" class="text-white"
                                                                routerLinkActive="active-link" target="_blank"> Create
                                                                new
                                                                catagory</a>
                                                        </div>
                                                        <div class="input-group p-2">
                                                            <div class="h-auto" style="width: 15%;">
                                                                <span
                                                                    class="input-group-text h-100 border-dark rounded-0 bg-transparent border-right-0">
                                                                    <i class="fas fa-search"></i></span>
                                                            </div>
                                                            <input type="text" class="search" ngModel
                                                                name="searchCatagory" #searchCatagory
                                                                style="width: auto;">
                                                        </div>
                                                        <ul class="list-group list-group-flush">
                                                            <li class="list-group-item border-0"
                                                                *ngFor="let item of catagories; index as i">
                                                                <div class="d-flex">
                                                                    <label class="custom mb-0">{{item.category_name}}
                                                                        <input type="radio" [value]="item._id"
                                                                            [(ngModel)]="type_of_product.main_id"
                                                                            (click)="onCat(item)"
                                                                            class="form-check-input m-2"
                                                                            name="catagory">
                                                                        <span class="checkmark"></span>
                                                                    </label>
                                                                    <button class="btn p-0 ml-4" type="button"
                                                                        *ngIf="item.main_sub_category"
                                                                        (click)="Showsubcat(item)">
                                                                        <i
                                                                            [class]="!item.click? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                    </button>
                                                                </div>
                                                                <ul class="list-group list-group-flush ml-4"
                                                                    *ngIf="item.click">
                                                                    <li class="list-group-item border-0"
                                                                        *ngFor="let val of item.main_sub_category; index as j">
                                                                        <div class="d-flex">
                                                                            <label
                                                                                class="custom mb-0">{{val.category_name}}
                                                                                <input type="radio" [value]="val._id"
                                                                                    [(ngModel)]="type_of_product.main_id"
                                                                                    (click)="onCat(val)"
                                                                                    class="form-check-input m-2"
                                                                                    name="catagory">
                                                                                <span class="checkmark"></span>
                                                                            </label>
                                                                            <button class="btn p-0 ml-4" type="button"
                                                                                *ngIf="val.sub_category.length > 0"
                                                                                (click)="Showsubcat(val)">
                                                                                <i
                                                                                    [class]="!val.click? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                            </button>
                                                                        </div>
                                                                        <ul class="list-group list-group-flush ml-4"
                                                                            *ngIf="val.click">
                                                                            <li class="list-group-item border-0"
                                                                                *ngFor="let v of val.sub_category; index as m">
                                                                                <div class="d-flex">
                                                                                    <label
                                                                                        class="custom mb-0">{{v.category_name}}
                                                                                        <input type="radio"
                                                                                            [value]="v._id"
                                                                                            [(ngModel)]="type_of_product.main_id"
                                                                                            class="form-check-input m-2"
                                                                                            (click)="onCat(v)"
                                                                                            name="catagory">
                                                                                        <span class="checkmark"></span>
                                                                                    </label>
                                                                                    <button class="btn p-0 ml-4"
                                                                                        type="button"
                                                                                        *ngIf="v.main_sub_category">
                                                                                        <i
                                                                                            [class]="v.main_sub_category? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-5">
                                            <div class="row"
                                                *ngFor="let value of type_of_product.type_of_product_gst;index as i">
                                                <div class="form-group col">
                                                    <label class="title">*Attribute Value</label>
                                                    <input type="text" class="form-control"
                                                        name="sub_type_of_product{{i}}"
                                                        [readonly]='!type_of_product.product_type'
                                                        [(ngModel)]="value.type_of_product_value" required>
                                                </div>
                                                <div class="col-1" *ngIf="!edit_value">
                                                    <button *ngIf="!value.delete" type="button"
                                                        class="btn bg-white p-0 addDelete"
                                                        (click)="addVarientType(type_of_product.type_of_product_gst)">
                                                        <i class="fas fa-plus-circle"></i>
                                                    </button>
                                                    <button *ngIf="value.delete" type="button"
                                                        class="btn bg-white p-0 addDelete"
                                                        (click)="deleteVar(type_of_product.type_of_product_gst,i)">
                                                        <i class="fas fas fa-trash-alt"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="col-12 text-center">
                                        <button type="submit" class="btn btn-success rounded-0" *ngIf="!edit_value"
                                            [disabled]="gstForm.invalid"
                                            (click)="product_type(type_of_product,gstForm)">Submit</button>
                                        <button type="submit" class="btn btn-success rounded-0" *ngIf="edit_value"
                                            [disabled]="gstForm.invalid"
                                            (click)="onUpdate(type_of_product,gstForm)">Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a ngbNavLink>Add Custom Varients</a>
                    <ng-template ngbNavContent>
                        <div class="border border-success bg-white py-4 d-flex">
                            <div class="position-absolute title" style="right: 30px;">
                                <button class="btn p-0 rounded-0">
                                    <i class="fas fa-times-circle"></i>
                                </button>
                            </div>
                            <div class="m-auto">
                                <div class="w-100">
                                    <form #socialForm="ngForm">
                                        <div ngbDropdown class="form-group d-inline-block w-100">
                                            <label class="title">Product typeList(required)</label>
                                            <button [disabled]="edit_id"
                                                class="btn btn-outline-green form-control w-100 d-flex justify-content-between"
                                                type="button" ngbDropdownToggle>
                                                <div><span *ngFor="let types of type_of_products">{{types.type}},</span>
                                                </div>
                                            </button>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="width: 100%;"
                                                style="width: 100%; height: 400px; overflow-y: scroll;">
                                                <div class="border-0">
                                                    <div class="bg-success p-2 text-center w-100">
                                                        <a routerLink="/create-new-catagory" class="text-white"
                                                            routerLinkActive="active-link" target="_blank"> Create new
                                                            catagory</a>
                                                    </div>
                                                </div>
                                                <div class="" *ngIf="types.length > 0">
                                                    <div class="input-group">
                                                        <div class="input-group p-2">
                                                            <div class="input-group-prepend" style="width: 15%;">
                                                                <span
                                                                    class="input-group-text border-dark rounded-0 bg-transparent border-right-0 w-100">
                                                                    <i class="fas fa-search"></i></span>
                                                            </div>
                                                            <input type="text" class="search" ngModel
                                                                name="searchCatagory" #searchCatagory>
                                                        </div>
                                                    </div>
                                                    <ul class="list-group list-group-flush ml-4">
                                                        <li class="list-group-item border-0"
                                                            *ngFor="let type of types; index as j">
                                                            <div class="d-flex">
                                                                <input type="checkbox" [(ngModel)]="type.status"
                                                                    (ngModelChange)="onType(type)"
                                                                    class="form-check-input" name="type">
                                                                <label class="custom mb-0">{{type.type_of_product_sub}}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="title">*Varient Name(required)</label>
                                            <input type="text" class="form-control" name="name"
                                                placeholder="e.g Size,Storage,"
                                                [readonly]="0 >= type_of_products.length"
                                                [(ngModel)]="type_of_product.varient_name" required>
                                        </div>
                                        <div class="form-group">
                                            <label class="title">*Varient description</label>
                                            <input type="text" class="form-control" name="description"
                                                placeholder="Description related on Varients"
                                                [readonly]="0 >= type_of_products.length"
                                                [(ngModel)]="type_of_product.varient_description">
                                        </div>
                                        <div class="form-group">
                                            <label class="title">*Type or Varients(required)</label>
                                            <input type="text" class="form-control" name="var"
                                                placeholder="e.g S,M,L,Xl, Or 64Gb,128Gb"
                                                [readonly]="0 >= type_of_products.length"
                                                [(ngModel)]="type_of_product.custom_varients" required>
                                        </div>
                                        <div class="row">
                                            <div class="col-4 form-group " style="height: 150px;width: 150px;">
                                                <div class="border border-success position-relative h-100 w-100">
                                                    <img *ngIf="type_of_product.varImg" [src]="type_of_product.varImg"
                                                        alt="productname" class="w-100 h-100">
                                                    <div *ngIf="type_of_product.varImg === null"
                                                        class="text-danger text-center mt-50">Type or Varients Size
                                                        Chart
                                                        Image
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2 my-auto">
                                                <label class="btn btn-success m-auto btn-sm"
                                                    style=" display:inline-flex;"
                                                    [disabled]="0 >= type_of_products.length">Size Chart Images
                                                    <input type="file" size="60" style="display: none;" name="img1"
                                                        [disabled]="0 >= type_of_products.length" ngModel
                                                        (change)="onFileChanged($event)"></label>
                                            </div>
                                            <div class="col-4 form-group" style="height: 150px;width: 150px;">
                                                <div class="border border-success position-relative h-100 w-100">
                                                    <img *ngIf="type_of_product.mesurementImg"
                                                        [src]="type_of_product.mesurementImg" alt="productname"
                                                        class="w-100 h-100">
                                                    <div *ngIf="type_of_product.mesurementImg === null"
                                                        class="text-danger text-center mt-50">Mesure Image
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2 my-auto">
                                                <label class="btn btn-success m-auto btn-sm"
                                                    [disabled]="0 >= type_of_products.length"
                                                    style=" display:inline-flex;">Mesurement Image
                                                    <input type="file" size="60" style="display: none;" name="img2"
                                                        [disabled]="0 >= type_of_products.length" ngModel
                                                        (change)="changed($event)"></label>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="text-center">
                                        <button type="submit" class="btn btn-success rounded-0" *ngIf="!edit"
                                            [disabled]="socialForm.invalid || 0 >= type_of_products.length"
                                            (click)="varSubmit(socialForm)">Submit</button>
                                        <!-- <button type="submit" class="btn btn-success rounded-0" *ngIf="edit"
                                        [disabled]="socialForm.form.invalid" (click)="onUpdate(socialForm)">Update</button> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3">
                    <a ngbNavLink>Product Attribute</a>
                    <ng-template ngbNavContent>
                        <div class="border border-success bg-white py-4 d-flex">
                            <div class="position-absolute title" style="right: 30px;">
                                <button class="btn p-0 rounded-0">
                                    <i class="fas fa-times-circle"></i>
                                </button>
                            </div>
                            <div class="container-fluid">
                                <div class="row">
                                    <form #attributeForm="ngForm" class="col-6 m-auto">
                                        <div ngbDropdown class="form-group d-inline-block w-100">
                                            <label class="title">Product typeList(required)</label>
                                            <button [disabled]="edit_id"
                                                class="btn btn-outline-green form-control w-100 d-flex justify-content-between"
                                                type="button" ngbDropdownToggle>
                                                <div><span *ngFor="let types of type_of_products">{{types.type}},</span>
                                                </div>
                                            </button>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="width: 100%;"
                                                style="width: 100%; height: 400px; overflow-y: scroll;">
                                                <div class="border-0">
                                                    <div class="bg-success p-2 text-center w-100">
                                                        <a routerLink="/create-new-catagory" class="text-white"
                                                            routerLinkActive="active-link" target="_blank"> Create new
                                                            catagory</a>
                                                    </div>
                                                </div>
                                                <div class="" *ngIf="types.length > 0">
                                                    <div class="input-group">
                                                        <div class="input-group p-2">
                                                            <div class="input-group-prepend" style="width: 15%;">
                                                                <span
                                                                    class="input-group-text border-dark rounded-0 bg-transparent border-right-0 w-100">
                                                                    <i class="fas fa-search"></i></span>
                                                            </div>
                                                            <input type="text" class="search" ngModel
                                                                name="searchCatagory" #searchCatagory>
                                                        </div>
                                                    </div>
                                                    <ul class="list-group list-group-flush ml-4">
                                                        <li class="list-group-item border-0"
                                                            *ngFor="let type of types; index as j">
                                                            <div class="d-flex">
                                                                <input type="checkbox" [(ngModel)]="type.status"
                                                                    (ngModelChange)="onType(type)"
                                                                    class="form-check-input" name="type">
                                                                <label class="custom mb-0">{{type.type_of_product_sub}}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="title">*Attribute Name(required)</label>
                                            <input type="text" class="form-control" name="attribute_name"
                                                [readonly]="0 >= type_of_products.length"
                                                [(ngModel)]="type_of_product.attribute_name" required>
                                        </div>
                                        <div *ngFor="let attribute of type_of_product.attribute_values;index as i">
                                            <div class="row">
                                                <div class="form-group col-11">
                                                    <label class="title">*Attribute Value(required)</label>
                                                    <input type="text" class="form-control" name="attribute{{i}}"
                                                        [readonly]="0 >= type_of_products.length"
                                                        [(ngModel)]="attribute.type_of_product_value" required>
                                                </div>
                                                <div class="col-1">
                                                    <button *ngIf="i === type_of_product.attribute_values.length-1"
                                                        type="button" class="btn bg-white p-0 addDelete"
                                                        (click)="addAttibute(type_of_product.attribute_values)">
                                                        <i class="fas fa-plus-circle"></i>
                                                    </button>
                                                    <button *ngIf="i !== type_of_product.attribute_values.length-1"
                                                        type="button" class="btn bg-white p-0 addDelete"
                                                        (click)="deleteVar(type_of_product.attribute_values,i)">
                                                        <i class="fas fas fa-trash-alt"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="form-group"
                                                *ngIf="type_of_product.attribute_name === 'material'">
                                                <label class="title">Wash Care</label>
                                                <textarea class="form-control" name="wash_care{{i}}"
                                                    [(ngModel)]="attribute.wash_care" required></textarea>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="text-center">
                                    <button type="submit" class="btn btn-success rounded-0" *ngIf="!edit"
                                        [disabled]=" type_of_products.length === 0 && type_of_product.attribute_name === ''"
                                        (click)="postAttibute(attributeForm,type_of_product)">Submit</button>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="4">
                    <a ngbNavLink>Addon's</a>
                    <ng-template ngbNavContent>
                        <div class="border border-success bg-white py-4 d-flex">
                            <div class="position-absolute title" style="right: 30px;">
                                <button class="btn p-0 rounded-0">
                                    <i class="fas fa-times-circle"></i>
                                </button>
                            </div>
                            <div class="container-fluid">
                                <div class="row">
                                    <form #typeOfProductForm="ngForm" class="col-6 m-auto">
                                        <div ngbDropdown class="form-group d-inline-block w-100">
                                            <label class="title">*Attibute Type</label>
                                            <button
                                                class="btn btn-outline-green form-control w-100 d-flex justify-content-between"
                                                type="button" ngbDropdownToggle>
                                                <div><span *ngFor="let types of type_of_products">{{types.type}},</span>
                                                </div>
                                            </button>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="width: 100%;">
                                                <div class="border-0">
                                                    <div class="bg-success p-2 text-center w-100">
                                                        <a routerLink="/create-new-catagory" class="text-white"
                                                            routerLinkActive="active-link" target="_blank"> Create new
                                                            catagory</a>
                                                    </div>
                                                </div>
                                                <div class="" *ngIf="types.length > 0">
                                                    <div class="input-group">
                                                        <div class="input-group p-2">
                                                            <div class="input-group-prepend" style="width: 15%;">
                                                                <span
                                                                    class="input-group-text border-dark rounded-0 bg-transparent border-right-0 w-100">
                                                                    <i class="fas fa-search"></i></span>
                                                            </div>
                                                            <input type="text" class="search" ngModel
                                                                name="searchCatagory" #searchCatagory>
                                                        </div>
                                                    </div>
                                                    <div class="">
                                                        <ul class="list-group list-group-flush ml-4">
                                                            <li class="list-group-item border-0"
                                                                *ngFor="let type of types; index as j">
                                                                <div class="d-flex">
                                                                    <input type="checkbox" (click)="onType(type,$event)"
                                                                        class="form-check-input" name="val">
                                                                    <label
                                                                        class="custom mb-0">{{type.type_of_product_sub}}
                                                                    </label>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngFor="let item of type_of_product.add_on_details;index as i">
                                            <div class="row">
                                                <div class="form-group col-11">
                                                    <label class="title">*Heading</label>
                                                    <input type="text" class="form-control" name={{item.heading}}{{i}}
                                                        [(ngModel)]="item.heading" required>
                                                    <ul class="list-group">
                                                        <li class="list-group-item border-0"
                                                            *ngFor="let element of item.child;index as r">
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <input type="text" class="form-control"
                                                                        name=name{{r}}{{i}} [(ngModel)]="element.name"
                                                                        required>
                                                                </div>
                                                                <div class="col addonImg d-flex">
                                                                    <img *ngIf="element.image" [src]="element.image"
                                                                        height="50px">
                                                                    <label class="btn btn-success btn-sm "
                                                                        style=" display:inline-flex;">Upload Image
                                                                        <input type="file" style="display: none;"
                                                                            name=name{{r}}{{i}}
                                                                            (change)="upload($event,element)">
                                                                    </label>
                                                                </div>
                                                                <div class="col">
                                                                    <input type="text" class="form-control"
                                                                        name=price{{r}}{{i}} [(ngModel)]="element.price"
                                                                        required>
                                                                </div>
                                                                <div class="col-1">
                                                                    <button *ngIf="r === item.child.length-1"
                                                                        type="button" class="btn bg-white p-0 addDelete"
                                                                        (click)="addAddonDetails(item.child)">
                                                                        <i class="fas fa-plus-circle"></i>
                                                                    </button>
                                                                    <button *ngIf="r !== item.child.length-1"
                                                                        type="button" class="btn bg-white p-0 addDelete"
                                                                        (click)="deleteVar(item.child,r)">
                                                                        <i class="fas fas fa-trash-alt"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="col-1">
                                                    <button *ngIf="!item.delete" type="button"
                                                        class="btn bg-white p-0 addDelete"
                                                        (click)="addAddons(type_of_product.add_on_details)">
                                                        <i class="fas fa-plus-circle"></i>
                                                    </button>
                                                    <button *ngIf="item.delete" type="button"
                                                        class="btn bg-white p-0 addDelete"
                                                        (click)="deleteVar(type_of_product.add_on_details,i)">
                                                        <i class="fas fas fa-trash-alt"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="text-center">
                                    <button type="submit" class="btn btn-success rounded-0" *ngIf="!edit"
                                        (click)="typeSubmit(typeOfProductForm)">Submit</button>
                                    <!-- <button type="submit" class="btn btn-success rounded-0" *ngIf="edit"
                                        [disabled]="socialForm.form.invalid" (click)="onUpdate(socialForm)">Update</button> -->
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav"></div>
        </div>
    </section>
    <section class="container-fluid my-3">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th scope="col">Sl.no</th>
                    <th scope="col">Main</th>
                    <th scope="col">Sub</th>
                    <th scope="col" *ngIf="active === 2">
                        <div class="row form-group">
                            <div class="col">Varients</div>
                            <div class="col"></div>
                            <div class="col">Action</div>
                        </div>
                    </th>
                    <th scope="col" *ngIf="active === 3">
                        <div class="row form-group">
                            <div class="col">Attributes</div>
                            <div class="col">Action</div>
                        </div>
                    </th>
                    <th scope="col" *ngIf="active === 4">
                        <div class="row form-group">
                            <div class="col-8">Addons</div>
                            <div class="col">Delete</div>
                        </div>
                    </th>
                    <th colspan="2" *ngIf="active === 1 || active === 4">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let type of types; index as i">
                    <th>{{i+1}}</th>
                    <td>{{type.type_of_product_main}}</td>
                    <td>{{type.type_of_product_sub}}</td>
                    <td *ngIf="active === 2">
                        <div class="row form-group"
                            *ngFor="let varient of type.type_of_product_custom_varient;index as c">
                            <div class="d-flex col-3">
                                <div class="">{{varient.custom_varient_product_type}} -</div>
                                <div class="">
                                    <div *ngFor="let value of varient.custom_varient_value">
                                        {{value.varient_name}},
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto">
                                <img *ngIf="varient.custom_varient_img1" [src]="varient.custom_varient_img1" alt=""
                                    height="50px">
                            </div>
                            <div class="col-auto">
                                <img *ngIf="varient.custom_varient_img2" [src]="varient.custom_varient_img2" alt=""
                                    height="50px">
                            </div>
                            <div class="ml-auto col-2">
                                <button class="btn text-success">
                                    <i class="fas fa-edit"
                                        (click)="update(type.type_of_product_custom_varient,type,varient,c)"></i>
                                </button>
                            </div>
                            <div class="col"><button class="btn text-danger" (click)="open(customContent)"><i
                                        class="fas fa-trash-alt"></i></button></div>
                            <ng-template #customContent let-modal>
                                <div class="modal-header">
                                    <h4 class="modal-title" id="modal-basic-title">Warning</h4>
                                    <button type="button" class="close" aria-label="Close"
                                        (click)="modal.dismiss('Cross click')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    Do You Surely Want to delete this Varient
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" (click)="delete({
                                            '_id': type._id,
                                            'type_of_product_custom_varient_id': varient._id
                                          },'pull_type_of_product_custom_varient_from_product_type')">Delete</button>
                                    <button type="button" class="btn btn-outline-dark"
                                        (click)="modal.close('Save click')">Save</button>
                                </div>
                            </ng-template>
                        </div>
                    </td>
                    <td *ngIf="active === 3">
                        <div class="row" *ngFor="let attributes of type.type_of_product_attribute;index as a">
                            <div class="d-flex col-6 border-bottom py-2">
                                <div class="">{{attributes.type_of_product_attribute_name}} -</div>
                                <div class="">
                                    <div *ngFor="let value of attributes.type_of_product_attribute_values">
                                        {{value.type_of_product_value}},
                                        <b *ngIf="value.wash_care">Wash Care - </b>{{value.wash_care}}
                                    </div>
                                </div>
                            </div>
                            <div class="col border-bottom py-2">
                                <button class="btn text-success">
                                    <i class="fas fa-edit"
                                        (click)="update(type.type_of_product_attribute,type,attributes,a)"></i>
                                </button>
                            </div>
                            <div class="col border-bottom py-2"><button class="btn text-danger"
                                    (click)="open(attibutecontent)"><i class="fas fa-trash-alt"></i></button></div>
                            <ng-template #attibutecontent let-modal>
                                <div class="modal-header">
                                    <h4 class="modal-title" id="modal-basic-title">Warning</h4>
                                    <button type="button" class="close" aria-label="Close"
                                        (click)="modal.dismiss('Cross click')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    Do You Surely Want to delete this Attribute
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" (click)="delete({'_id': type._id,
                                            'type_of_product_attribute_id': attributes._id
                                          },'pull_type_of_product_attribute_from_product_type')">Delete</button>
                                    <button type="button" class="btn btn-outline-dark"
                                        (click)="modal.close('Save click')">Save</button>
                                </div>
                            </ng-template>
                        </div>
                    </td>
                    <td *ngIf="active === 4">
                        <div class="row" *ngFor="let addon of type.type_of_product_addons;index as a">
                            <div class="d-flex border-bottom py-2 col-8">
                                <div class="w-25">{{addon.heading}} -</div>
                                <div class="w-auto">
                                    <div class="row" *ngFor="let value of addon.child">
                                        <div class="col-auto">
                                            {{value.name}},
                                            <b *ngIf="value.price">price - </b>{{value.price}}
                                        </div>
                                        <img class="col-auto" *ngIf="value.image" [src]="value.image" alt=""
                                            height="50px">
                                    </div>
                                </div>
                            </div>
                            <div class="col border-bottom py-2"><button class="btn text-danger" (click)="delete({
                                '_id': type._id,
                                'type_of_product_addons_id': addon._id
                              },'pull_type_of_product_addons_from_product_type')"><i
                                        class="fas fa-trash-alt"></i></button></div>
                        </div>
                    </td>
                    <td *ngIf="active === 1"><button class="btn text-success"
                            (click)="update(typeList,type,'nothing',i)"><i class="fas fa-edit"></i></button>
                    </td>
                    <td *ngIf="active === 4">
                        <button class="btn text-success">
                            <i class="fas fa-edit" (click)="update(typeList,type,'',i)"></i>
                        </button>
                    </td>
                    <td *ngIf="active === 1"><button class="btn text-danger" (click)="open(content)">
                            <i class="fas fa-trash-alt"></i></button>
                    </td>
                    <ng-template #content let-modal>
                        <div class="modal-header">
                            <h4 class="modal-title" id="modal-basic-title">Warning</h4>
                            <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            Do You Surely Want to delete this Type Of Product
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger"
                                (click)="delete({'product_type_id': type._id},'delete_product_type_by_id')">Delete</button>
                            <button type="button" class="btn btn-outline-dark"
                                (click)="modal.close('Save click')">Cancel</button>
                        </div>
                    </ng-template>
                </tr>
            </tbody>
        </table>
    </section>
</div>