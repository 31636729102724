<div class="container-fluid">
    <div class="upper">
        <h4 class="text-center">Country Currency Settings</h4>
        <div class="bg-white p-4 border border-success">
            <div ngbDropdown class="d-inline-block mb-4 w-100">
                <button class="btn btn-block border d-flex justify-content-between" type="button" ngbDropdownToggle>
                    <div>
                        <span *ngIf="select_countries.length === 0">Country</span>
                        <span *ngFor="let country of select_countries">{{country.country_name}}, </span>
                    </div>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <div class="modal-body pt-0" *ngIf="countries.length > 0">
                        <div class="input-group p-2 w-100">
                            <div class="input-group-prepend" style="width: 15%;">
                                <span class="input-group-text border-dark rounded-0 bg-transparent border-right-0">
                                    <i class="fas fa-search"></i></span>
                            </div>
                            <input type="text" class="" ngModel name="searchCatagory" #searchCatagory>
                        </div>
                        <div class="">
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item border-0" *ngFor="let country of countries">
                                    <div class="d-flex">
                                        <label class="custom mb-0">{{country.selected_country_name}}
                                            <input type="checkbox" [(ngModel)]="country.status"
                                                class="form-check-input m-2" name="country"
                                                (ngModelChange)="addCountry(country)">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-4">
                <label class="labeltitle">Terms And Conditions</label>
                <angular-editor [placeholder]="'Enter text here...'" [(ngModel)]="terms_and_conditions"></angular-editor>
            </div>
            <div class="mb-4">
                <label class="labeltitle">Privacy Policy</label>
                <angular-editor [placeholder]="'Enter text here...'" [(ngModel)]="privacy_and_policy"></angular-editor>
            </div>
            <div class="text-center">
                <button class="btn btn-success" type="submit" (click)="submit()">Submit</button>
            </div>
        </div>
    </div>
</div>
<section class="container-fluid py-4">
    <table class="table table-bordered">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Country</th>
                <th scope="col">Terms And Condition</th>
                <th scope="col">Privacy & Policy</th>
                <th colspan="2">Action</th>
                <th>Status</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let terms_cond of terms_condition.response; index as i">
                <th scope="row">{{i+1}}</th>
                <td>{{terms_cond.country_name}}</td>
                <td [innerHTML]="terms_cond.terms_and_conditions"></td>
                <td [innerHTML]="terms_cond.privacy_and_policy"></td>
                <td><button class="btn text-success" (click)="edit(terms_cond)"><i class="fas fa-edit"></i></button>
                </td>
                <td><button class="btn text-danger" (click)="delete(terms_cond)"><i
                            class="fas fa-trash-alt"></i></button></td>
                <td><button class="btn" [class.text-success]="terms_cond.status"
                        [class.text-secondary]="!terms_cond.status" (click)="view(terms_cond._id, terms_cond.status)"><i
                            class="fas" [class.fa-eye]="terms_cond.status" [class.fa-eye-slash]="!terms_cond.status"></i></button></td>
            </tr>
        </tbody>
    </table>
</section>
<ngb-alert *ngIf="response.status" [type]="response.type" class="submit" (closed)="response.status = ''">
    {{response.message}}
</ngb-alert>