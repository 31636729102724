import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/api.service';
import {AuthenticForm} from './authentic-form'
@Component({
  selector: 'app-authentic-product',
  templateUrl: './authentic-product.component.html',
  styleUrls: ['./authentic-product.component.css']
})
export class AuthenticProductComponent implements OnInit {

    offerList = [
      {cuponOfferValue:'5',couponOfferTag: 'About Shop',description:'1234', country:'India', couponOfferCode: 'new30',
     ValidFrom: new Date(), ValidUpto: new Date(), OfferUseTime:0},
      {cuponOfferValue:'5', couponOfferTag: 'About Shop',description:'1234', country:'India', couponOfferCode: 'new30',
     ValidFrom: new Date(), ValidUpto: new Date(), OfferUseTime:0},
      {cuponOfferValue:'5', couponOfferTag: 'About Shop',description:'1234', country:'India', couponOfferCode: 'new30',
     ValidFrom: new Date(), ValidUpto: new Date(), OfferUseTime:0},
      {cuponOfferValue:'5', couponOfferTag: 'About Shop',description:'1234', country:'India', couponOfferCode: 'new30',
     ValidFrom: new Date(), ValidUpto: new Date(), OfferUseTime:0},
      {cuponOfferValue:'15', couponOfferTag: 'About Shop',description:'1234', country:'India', couponOfferCode: 'new30',
     ValidFrom: new Date(), ValidUpto: new Date(), OfferUseTime:0},
    ]
  
  
    catagories = [
      {catName: 'Women Ware',button:true, click:false, value:"", subcatagory: [
        {catName: 'Saree',button:true, click:false, subcatagory: [{
          name: 'cotton Saree', button: false,value:'',
        }]},
        {catName: 'Kurti',button:true, click:false, value:''},
        {catName: 'Dress',button:true, click:false, value:''},
      ]},
      {catName: 'men Ware',button:true, click:false, value:"", subcatagory: 
        [
          {catName: 'Shirt',button:true, click:false,subcatagory: [{
            name: 'cotton Shirt', button: false, value:'',
          }]},
          {catName: 'Jeans',button:true, click:false, value:''},
        ]
      },
      {catName: 'Child Ware',button:true, click:false, value:"", subcatagory: 
        [
          {catName: 'Top',button:true, click:false,subcatagory: [{
            name: 'cotton Top', button: false, value:''
          }]},
          {catName: 'skirt',button:true, click:false, value:''},
        ]
      },
    ]
  
    Showcat(val){
      this.catagories[val].click = !this.catagories[val].click;
    }
    Showsubcat(val,val2){
      this.catagories[val].subcatagory[val2].click = !this.catagories[val].subcatagory[val2].click;
    }
    customOptions = {
      loop: true,
      autoplay: true,
      center: true,
      dots: false,
      autoHeight: true,
      autoWidth: true,
      responsive: {
        0: {
          items: 2,
        },
        600: {
          items: 1,
        },
        1000: {
          items: 1,
        }
      }
    }
  
    authenticPage = new AuthenticForm('','','','women')
  
    images = [
      {img: "../../../assets/img/home/hero-slider/01.jpg", myStyles: {"background-color": "rgb(58, 175, 210)"}},
      {img: "../../../assets/img/home/hero-slider/02.jpg", myStyles: {"background-color": "#f5b1b0"}},
      {img: "../../../assets/img/home/hero-slider/03.jpg", myStyles: {"background-color": "#eba170"}}
    ]
  
    imgType = true;
  
    bannerMenu;
  
    // getData(){
    //   this.api.bannerSource$.subscribe(
    //     message => {
    //       // console.log(message)
    //       this.bannerMenu = message;
    //     }
    //   )
    // }
    bannerpositionRight = true;
    bannerPositionChecked(value){
      let item = value.toString();
        for (let i = 0; i < this.bannerMenu.length; i++) {
          if (this.bannerMenu[i].main_banner_position_id === item) {
            this.bannerpositionRight = false;
          }else{
            this.bannerpositionRight = true
          }
        }
    }
    selectedFile:File;
  
    onSubmit(form:NgForm){
      // if (img.value.includes(".png") || img.value.includes(".jpg")) {
        console.log(form.value);
        const fd = new FormData();
        fd.append('image',this.selectedFile,this.selectedFile.name);
        console.log(fd)
        let body = {
          "main_banner_name": this.authenticPage.name,
          "main_banner_country_id": '1',
          "main_banner_pic":fd,
        }
        this.api.post(`post_main_banner`,body).subscribe(
        data=> {
          console.log('!success',data);
          this.api.get(`get_main_banner`).subscribe(
            data =>{
              this.bannerMenu = data;
              console.log('Success!',data);
            },
            error => console.log('Error!', error)
          )
        },
        error=> console.log(error)
      )
        form.reset();
      // } 
    }
    upload(event){
      // if (event.value.includes(".png") || event.value.includes(".jpg")) {
      //   this.imgType = true;
      // } else {
      //   this.imgType = false;
      // }
      this.selectedFile = <File>event.target.files[0];
      console.log(this.selectedFile)
    }
  
    constructor(private api:ApiService,private modalService: NgbModal) { 
    }
    countries;
  
    open(content,size) {
      this.modalService.open(content, { size: size,scrollable: true  });
    }
    ngOnInit(): void {
      this.api.post(`banner_list`,[]).subscribe(
        data =>{
          this.bannerMenu = data;
          console.log('Success!',data);
          // this.api.BannerShare(this.bannerMenu);
        },
        error => console.log('Error!', error)
      )
      this.api.get(`get_countries`).subscribe(
        data =>{
          this.countries = Object.values(data);
          this.api.countryShare(this.countries);
          console.log(this.countries)
        }, 
        error => console.log('Error!', error)
      )
    }
    //   this.api.countrySource$.subscribe(
    //     message => {
    //       this.countries = message;
    //     }
    //   )
}
