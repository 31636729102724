<div>
    <section class="upper">
        <div>
            <div class="heading text-center">
                <h4 class="">Featured Products setting</h4>
            </div>
        </div>
        <div class="container-fluid">
            <div class="border border-success bg-white  py-4">
                <div class="position-absolute title" style="right: 30px;">
                    <button class="btn p-0 rounded-0 clean">
                        <i class="fas fa-times-circle"></i>
                    </button>
                </div>
                <div class="container">
                    <form #featuredForm="ngForm">
                        <div class="row">
                            <div class="col-9">
                                <div class="d-flex justify-content-between w-100">
                                    <div class="form-group productInfo">
                                        <label class="title">Country</label>
                                        <select name="country" class="form-control" [(ngModel)]="fetureForm.country"
                                            required>
                                            <option value="{{country._id}}-{{country.selected_country_name}}"
                                                *ngFor="let country of countries">{{country.selected_country_name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group productInfo">
                                        <label class="title">Featured Name</label>
                                        <input type="text" class="form-control" name="featuredName"
                                            [(ngModel)]="fetureForm.featuredName" required>
                                    </div>
                                </div>
                                <div class="w-100">
                                    <div class="form-group w-100">
                                        <label class="title">Featured tag</label>
                                        <input type="text" class="form-control" name="featuredTag"
                                            [(ngModel)]="fetureForm.featuredTag" required>
                                    </div>
                                </div>
                                <div ngbDropdown class="form-group d-inline-block w-100">
                                    <label class="title">Apply To</label>
                                    <button
                                        class="btn btn-outline-green form-control w-100 d-flex justify-content-between"
                                        type="button" ngbDropdownToggle>
                                        <span>{{fetureForm.apply_to}}</span>
                                    </button>
                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="width: 100%;">
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item border-0">
                                                <button class="btn p-0" (click)="home()">Home</button>
                                            </li>
                                            <li class="list-group-item border-0">
                                                <button class="btn p-0" (click)="open(content)">catagory</button>
                                            </li>
                                            <ng-template #content let-modal>
                                                <div class="modal-header border-0">
                                                    <div class="p-2 w-100" *ngIf="0 >= catagories.length">
                                                        first select a country and then select catagory
                                                    </div>
                                                    <div class="bg-success p-2 text-center w-100"
                                                        *ngIf="catagories.length > 0">
                                                        <a routerLink="/create-new-catagory" class="text-white"
                                                            routerLinkActive="active-link" target="_blank"> Create new
                                                            catagory</a>
                                                    </div>
                                                    <button type="button" class="close" aria-label="Close"
                                                        (click)="modal.dismiss('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body pt-0" *ngIf="catagories.length > 0">
                                                    <div>
                                                        <div class="input-group">
                                                            <div class="input-group p-2">
                                                                <div class="input-group-prepend" style="width: 15%;">
                                                                    <span
                                                                        class="input-group-text border-dark rounded-0 bg-transparent border-right-0 w-100">
                                                                        <i class="fas fa-search"></i></span>
                                                                </div>
                                                                <input type="text" class="search" ngModel
                                                                    name="searchCatagory" #searchCatagory>
                                                            </div>
                                                        </div>
                                                        <div class="">
                                                            <ul class="list-group list-group-flush">
                                                                <li class="list-group-item border-0"
                                                                    *ngFor="let item of catagories; index as i">
                                                                    <div class="d-flex">
                                                                        <label class="custom mb-0">{{item.category_name}}
                                                                            <input type="radio" [value]="item._id"
                                                                                (click)="onCat(item)"
                                                                                [(ngModel)]="fetureForm.apply_id"
                                                                                class="form-check-input m-2"
                                                                                name="catagory">
                                                                            <span class="checkmark"></span>
                                                                        </label>
                                                                        <button class="btn p-0 ml-4" type="button"
                                                                            *ngIf="item.main_sub_category"
                                                                            (click)="Showsubcat(item)">
                                                                            <i
                                                                                [class]="!item.click? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                        </button>
                                                                    </div>
                                                                    <ul class="list-group list-group-flush ml-4"
                                                                        *ngIf="item.click">
                                                                        <li class="list-group-item border-0"
                                                                            *ngFor="let val of item.main_sub_category; index as j">
                                                                            <div class="d-flex">
                                                                                <label
                                                                                    class="custom mb-0">{{val.category_name}}
                                                                                    <input type="radio"
                                                                                        [value]="val._id"
                                                                                        (click)="onCat(val,CatDrop,singleProductForm)"
                                                                                        [(ngModel)]="fetureForm.apply_id"
                                                                                        class="form-check-input m-2"
                                                                                        name="catagory">
                                                                                    <span class="checkmark"></span>
                                                                                </label>
                                                                                <button class="btn p-0 ml-4"
                                                                                    type="button"
                                                                                    *ngIf="val.sub_category.length > 0"
                                                                                    (click)="Showsubcat(val)">
                                                                                    <i
                                                                                        [class]="!val.click? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                                </button>
                                                                            </div>
                                                                            <ul class="list-group list-group-flush ml-4"
                                                                                *ngIf="val.click">
                                                                                <li class="list-group-item border-0"
                                                                                    *ngFor="let v of val.sub_category; index as m">
                                                                                    <div class="d-flex">
                                                                                        <label
                                                                                            class="custom mb-0">{{v.category_name}}
                                                                                            <input type="radio"
                                                                                                [value]="v._id"
                                                                                                (click)="onCat(v,CatDrop,singleProductForm)"
                                                                                                [(ngModel)]="fetureForm.apply_id"
                                                                                                class="form-check-input m-2"
                                                                                                name="catagory">
                                                                                            <span
                                                                                                class="checkmark"></span>
                                                                                        </label>
                                                                                        <button class="btn p-0 ml-4"
                                                                                            type="button"
                                                                                            *ngIf="v.sub_category">
                                                                                            <i
                                                                                                [class]="v.sub_category? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-1"></div>
                            <div class="col-2 h-auto">
                                <div class="border border-success h-100 position-relative">
                                    <img *ngIf="fetureForm.img" [src]="fetureForm.img" alt="productname" class="w-100 h-auto">
                                    <label class="position-absolute btn btn-success px-1 py-0 m-2"
                                        style="right: 0; font-size: 12px; display:inline-flex;">Add Images
                                        <input type="file" size="60" style="display: none;" name="img" ngModel
                                            #img="ngModel" (change)="upload($event)"></label>
                                </div>
                                <small class="text-danger" *ngIf="!imgType">img should be .jpg or .png</small>
                            </div>

                            <div class="col-12 text-center" *ngIf="!edit">
                                <button type="submit" class="btn btn-success rounded-0" (click)="onSubmit(featuredForm)"
                                    [disabled]="featuredForm.form.invalid || img.invalid">Save</button>
                            </div>
                            <div class="col-12 text-center" *ngIf="edit">
                                <button type="submit" class="btn btn-success rounded-0"
                                    (click)="onSubmit(featuredForm,img)">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <section class="container">
        <div class="my-3">
            <ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="active">
                <li ngbNavItem [ngbNavItem]="1">
                    <a ngbNavLink>First</a>
                    <ng-template ngbNavContent>
                        <ul cdkDropList class="list-group p-0 example-list"
                            (cdkDropListDropped)="drop($event,this.fetureMenu)">
                            <h4>India</h4>
                            <li class="example-box" *ngFor="let item of fetureMenu; index as i" cdkDrag>
                                <div class="d-flex w-100">
                                    <div class="my-auto d-flex justify-content-between" style="width:10%;">
                                        <div class="example-handle" cdkDragHandle>
                                            <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                                                <path
                                                    d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                                                </path>
                                                <path d="M0 0h24v24H0z" fill="none"></path>
                                            </svg>
                                        </div>
                                        <p class="m-auto">Featured</p>
                                        <p class="m-auto"> {{i+1}}</p>
                                    </div>
                                    <div class="list-group-item w-100">
                                        <div class="row">
                                            <div class="col-2 m-auto">
                                                <button class="btn p-0"
                                                    (click)="manageProduct('populate_featured_product_by_fetured_product_id',item._id)">
                                                    <span class="badge badge-success"
                                                        *ngIf="item.featured_product_product_id.length > 0">
                                                        {{item.featured_product_product_id.length}} products
                                                        Avaliable</span>
                                                </button>
                                            </div>
                                            <div class="col-5 text-center my-auto">
                                                <p class="m-auto">{{item.featured_product_name}}</p>
                                                <h4 class="m-auto">{{item.featured_product_tag}}</h4>
                                            </div>
                                            <div class="col-2">
                                                <div class="border border-success h-100">
                                                    <img [src]="item.featured_product_image" alt="img" height="200px"
                                                        width="100%">
                                                </div>
                                            </div>
                                            <div class="col-3 m-auto d-flex justify-content-around">
                                                <button class="btn p-0 edit" (click)="edit(item,featuredForm)">
                                                    <i class="fas fa-edit"></i>
                                                </button>
                                                <button class="btn p-0" style="font-size: 25px;" [class.text-success]="item.status" [class.text-secondary]="!item.status"
                                                (click)="_handleView('update_featured_product',{'_id':item._id,'status': !item.status})">
                                                    <i class="fas fa-eye" *ngIf="item.status"></i>
                                                    <i class="fas fa-eye-slash" *ngIf="!item.status"></i>                                                
                                                </button>
                                                <button class="btn p-0 delete" (click)="open(productContent)">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ng-template #productContent let-modal>
                                    <div class="modal-body">
                                        <h3 class="text-danger">Are You Surely Want To Delete This Feture</h3>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-danger"
                                            (click)="_handelDelete('delete_featured_product_by_id',{'featured_product_id': item._id})">Delete</button>
                                        <button type="button" class="btn btn-outline-dark"
                                            (click)="modal.close('Save click')">Cancel</button>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                    </ng-template>
                </li>
                <li ngbNavItem [ngbNavItem]="2">
                    <a ngbNavLink>Second</a>
                    <ng-template ngbNavContent>
                        <ul cdkDropList class="list-group p-0 example-list"
                            (cdkDropListDropped)="drop($event,this.fetureCatMenu)">
                            <h4>India</h4>
                            <li class="example-box" *ngFor="let item of fetureCatMenu; index as i" cdkDrag>
                                <div class="d-flex w-100">
                                    <div class="my-auto d-flex justify-content-between" style="width:10%;">
                                        <div class="example-handle" cdkDragHandle>
                                            <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                                                <path
                                                    d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                                                </path>
                                                <path d="M0 0h24v24H0z" fill="none"></path>
                                            </svg>
                                        </div>
                                        <p class="m-auto">Featured</p>
                                        <p class="m-auto"> {{i+1}}</p>
                                    </div>
                                    <div class="list-group-item w-100">
                                        <div class="row">
                                            <div class="col-2 m-auto">
                                                <button class="btn p-0"
                                                    (click)="manageProduct('populate_featured_product_category_by_id',item._id)">
                                                    <span class="badge badge-success"
                                                        *ngIf="item.featured_product_category_product_id.length > 0">{{item.featured_product_category_product_id.length}}
                                                        products Avaliable</span>
                                                </button>
                                            </div>
                                            <div class="col-5 text-center my-auto">
                                                <p class="m-auto">{{item.featured_product_category_name}}</p>
                                                <h4 class="m-auto">{{item.featured_product_category_tag}}</h4>
                                                <p class="m-auto">{{item.featured_product_category_category_name}}</p>
                                            </div>
                                            <div class="col-2">
                                                <div class="border border-success h-100">
                                                    <img [src]="item.featured_product_category_image" alt="img"
                                                        height="200px" width="100%">
                                                </div>
                                            </div>
                                            <div class="col-3 m-auto d-flex justify-content-around">
                                                <button class="btn p-0 edit" (click)="edit(item,featuredForm)">
                                                    <i class="fas fa-edit"></i>
                                                </button>
                                                <button class="btn p-0" style="font-size: 25px;" [class.text-success]="item.status" [class.text-secondary]="!item.status"
                                                (click)="_handleView('update_featured_product_category',{'_id':item._id,'status': !item.status})">
                                                    <i class="fas fa-eye" *ngIf="item.status"></i>
                                                    <i class="fas fa-eye-slash" *ngIf="!item.status"></i>                                                
                                                </button>
                                                <button class="btn p-0 delete" (click)="open(productContent)">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ng-template #productContent let-modal>
                                    <div class="modal-body">
                                        <h3 class="text-danger">Are You Surely Want To Delete This Feture</h3>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-danger"
                                            (click)="_handelDelete('delete_featured_product_category_by_id',{'featured_product_category_id': item._id})">Delete</button>
                                        <button type="button" class="btn btn-outline-dark"
                                            (click)="modal.close('Save click')">Cancel</button>
                                    </div>
                                </ng-template>

                            </li>
                        </ul>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav"></div>
        </div>
    </section>
</div>