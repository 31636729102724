import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-external-link',
  templateUrl: './external-link.component.html',
  styleUrls: ['./external-link.component.css']
})
export class ExternalLinkComponent implements OnInit {

  detailsInfo = [
    {size:'S', color:'blue', quantity: '5pcs', price:'Rs. 450', sku: '63782345'},
    {size:'M', color:'Red', quantity: '5pcs', price:'Rs. 450', sku: '63782345'},
    {size:'L', color:'Green', quantity: '5pcs', price:'Rs. 450', sku: '63782345'},
    {size:'XL', color:'Orange', quantity: '5pcs', price:'Rs. 450', sku: '63782345'}
  ]

  submit(form:NgForm){
    form.reset();
    console.log(form.value)
  }

  constructor() { }

  ngOnInit(): void {
  }

}
