import { ApiService } from './../../../api.service';
import { Component, OnInit, Input, NgZone } from '@angular/core';
import { ProductAdd } from './product-add';
import { Customcatform } from './../../customcatform';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { from } from 'rxjs';
import * as uuid from 'uuid';

@Component({
  selector: 'app-product-add',
  templateUrl: './product-add.component.html',
  styleUrls: ['./product-add.component.css']
})

export class ProductAddComponent implements OnInit {

  today = new Date()

  myId = uuid.v4();
  page = 1;
  pageSize = 1;
  imgLinks = [];

  sku_varidation = { status: '', message: '' }

  arr

  setImageLinks = [];

  bulkImageForms = [];

  vendorList;

  type_of_productList = []

  loading = false

  singleProductForm = new ProductAdd(false, this.imgLinks[0], true, 'new', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 0, false, '', '', false, '',
    '', [], '', [], [], '', '', '', '', '', '', true, false, '', [{ name: '', des: '', deleteButton: false }],
    [{ minQuality: '', maxQuality: '', price: '' }], [], [], { status: '', message: '' })


  varientsforSingleProductCustom = [];
  gstList: any = []
  unit: any;
  loader = true
  sku_weight_mesurement: any = {
    store_decimal_places: "",
    store_decimal_token: "",
    store_generate_auto: false,
    store_length_mesurements: "",
    store_sku_manual_start_from: "",
    store_sku_prefix: "",
    store_thousand_token: "",
    store_weight_mesurements: "",
  }

  price(obj) {
    if (0 > obj.price) {
      obj.price = ''
      obj.mrp = ''
    } else {
      //for India and Bangladesh only
      obj.price = Math.round(obj.price)
      if (obj.price > 0) {
        if (obj.price >= obj.mrp) {
          obj.mrp = Math.round(obj.price)
          obj.discount = 0
        } else {
          let new_dis = parseInt(obj.mrp) - parseInt(obj.price) 
          obj.discount = Math.round(new_dis / 100) 
        }
      }else{
        obj.mrp = 0
        obj.price = 0
        obj.discount = 0
      }
      // if (obj.discount) {
      //   obj.discount = parseFloat(obj.discount)
      //   let new_dis = obj.discount / 100
      //   //for India and Bangladesh only
      //   obj.mrp = Math.round((obj.price / (1 - new_dis)))
      //   //for another countries
      //   // obj.mrp = (obj.price / (1 - new_dis)).toFixed(2)
      // } else {
      //   obj.mrp = obj.price ? parseFloat(obj.price) : ''
      // }
      if (isNaN(obj.mrp)) {
        obj.price = ''
        obj.mrp = ''
      }
      if (obj.custom) {
        obj.custom.map((res) => res.variable_description.map((res) => res.price = obj.price))
      }
      if (obj.price && obj.productCostPrice) {
        obj.margin = (parseFloat(obj.price) - parseFloat(obj.productCostPrice)).toFixed(2)
        obj.margin > 0 ? obj.profit = Math.round((obj.margin / parseFloat(obj.price)) * 100) : obj.profit = 0
      }
    }
  }

  // discount(obj) {
  //   if (obj.discount > 0) {
  //     obj.discount = parseInt(obj.discount)
  //   }else{
  //     obj.discount = ""
  //   }
  // }

  discount(obj) {
    if (obj.discount > 0) {
      if (obj.discount === Math.round(obj.discount)) {
        obj.discount = parseInt(obj.discount)
      } else {
        obj.discount = Math.floor(parseFloat(obj.discount))
      }
      if (obj.mrp) {
        obj.mrp = parseFloat(obj.mrp);
        obj.discount = parseFloat(obj.discount)
        let discount_amount = (obj.discount * obj.mrp) / 100;
        //for India and Bangladesh only
        obj.price = Math.round(obj.mrp - discount_amount)
        //for another countries
        //obj.price = (obj.mrp - discount_amount).toFixed(2)
      } else {
        if (obj.price) {
          obj.price = parseFloat(obj.price);
          let new_dis = obj.discount / 100
          //for India and Bangladesh only
          obj.mrp = Math.round((obj.price / (1 - new_dis)))
          //for another countries
          // obj.mrp = (obj.price / (1 - new_dis)).toFixed(2)
          if (obj.custom) {
            obj.custom.map((res) => res.variable_description.map((res) => res.mrp = obj.mrp))
            obj.custom.map((res) => res.variable_description.map((res) => res.price = obj.price))
          }
        }
      }
      if (obj.custom) {
        obj.custom.map((res) => res.variable_description.map((res) => res.discount = obj.discount))
      }

    } else {
      obj.discount = ""
      if (obj.mrp > 0) {
        if (isNaN(obj.discount)) {
          obj.discount = ''
        }
        obj.price = obj.mrp
      } else if (obj.price > 0) {
        if (isNaN(obj.discount)) {
          obj.discount = ''
        }
        obj.mrp = obj.price
      } else {
        if (isNaN(obj.discount)) {
          obj.discount = ''
        }
        obj.price = obj.price
        obj.mrp = obj.mrp
      }
    }
  }

  totalprice(obj) {
    if (0 > obj.mrp) {
      obj.mrp = ''
    } else {
      if (obj.discount) {
        obj.discount = parseFloat(obj.discount)
        obj.mrp = parseFloat(obj.mrp);
        //for India and Bangladesh only
        obj.mrp = Math.round(obj.mrp)
        //
        let discount_amount = (obj.discount * obj.mrp) / 100;
        //for India and Bangladesh only
        obj.price = Math.round(obj.mrp - discount_amount)
        //for another countries
        //obj.price = (obj.mrp - discount_amount).toFixed(2)
      } else {
        obj.price = obj.mrp ? parseFloat(obj.mrp) : ''
        //for India and Bangladesh only
        obj.mrp = Math.round(obj.mrp)
      }
      if (isNaN(obj.mrp)) {
        obj.price = ''
        obj.mrp = ''
      }
      if (obj.custom) {
        obj.custom.map((res) => res.variable_description.map((res) => res.mrp = obj.mrp))
      }
    }
  }

  Pofit(obj) {
    if (obj.price && obj.productCostPrice) {
      //for India and Bangladesh only
      obj.margin = (obj.price - obj.productCostPrice)
      // obj.margin = (obj.price - obj.productCostPrice).toFixed(2)
      obj.margin > 0 ? obj.profit = Math.round((obj.margin / parseInt(obj.price)) * 100) : obj.margin = 0
    } else {
      obj.margin = 0;
      obj.profit = 0;
    }
  }

  quantityInStock(obj) {
    if (obj.quantity) {
      obj.quantity = Math.round(parseInt(obj.quantity));
    }
    obj.custom.map((res) => res.variable_description.map((res) => res.quantity = obj.quantity))
  }

  barcodeNo(obj) {
    if (obj.barcode) {
      obj.barcode = obj.barcode;
    }
    obj.custom.map((res) => res.variable_description.map((res) => res.barcode = obj.barcode))
  }

  sku(obj, array) {
    obj.sku_valid.status = ''
    if (obj.sku) {
      obj.sku = obj.sku;
      // array.map((res,index) => {
      //   if (index !== array.indexOf(obj)) {
      //       if (obj.sku === res.sku) {
      //         obj.sku_valid.status = 'fail'
      //         obj.sku_valid.message = `Sku is not Unique`
      //       }
      //   }
      // })
      if (this.sku_weight_mesurement.store_sku_manual_start_from) {
        if (!obj.sku.startsWith(this.sku_weight_mesurement.store_sku_manual_start_from)) {
          obj.sku_valid.status = 'fail'
          obj.sku_valid.message = `Sku is not Starts form ${this.sku_weight_mesurement.store_sku_manual_start_from}`
        } else {
          this.api.post(`check_sku`, { "sku": obj.sku }).subscribe(
            data => {
              obj.sku_valid = data
              if (obj.sku_valid.status === 'success') {
                setTimeout(() => {
                  obj.sku_valid.status = ''
                }, 1000);
              } else {
                obj.sku_valid.status = 'fail'
              }
            },
            error => console.log(error)
          )
        }
      } else {
        this.api.post(`check_sku`, { "sku": obj.sku }).subscribe(
          data => {
            obj.sku_valid = data
            if (obj.sku_valid.status === 'success') {
              setTimeout(() => {
                obj.sku_valid.status = ''
              }, 1000);
            }
          },
          error => console.log(error)
        )
      }
    }
    // obj.custom.map((res) => res.variable_description.map((res) => res.sku = obj.Math.random().toString(36).substr(2, 10)))
    obj.custom.map((res) => res.variable_description.map((res) => res.sku = obj.sku))
  }


  varientsforSingleProductCustomforMake = [];


  onChoose(val, e) {
    e.size = val
  }

  sameShowing(index, arr) {
    arr[index].differentShow = true;
    arr[index].sameShow = false;
  }

  differentShowing(index, arr) {
    arr[index].differentShow = false;
    arr[index].sameShow = true;
  }

  optionDisable(val, arr) {
    arr.map((result) => {
      val.map((res) => {
        if (result.varient_name === res.variable_description) {
          result.status = false
        }
      })
    })
  }

  addVarient(a, vardes, form) {
    if (a.everything.length <= (a.everything[0].details[0].list.length - 2)) {
      console.log(false)
      a.disable = false
    } else {
      console.log(true)
      a.disable = true
    }
    a.everything.push(a.everything[0]);
    vardes.push({
      "value": "",
      "quantity": form.quantity,
      "discount": form.discount,
      "price": form.price,
      "sku": this.sku_weight_mesurement.store_generate_auto ? form.sku : Math.random().toString(36).toUpperCase().substr(2, 10),
      "barcode": form.barcode,
      "mrp": form.mrp
    })
  };


  openScrollableContent(longContent) {
    this.modalService.open(longContent, { scrollable: true });
  }

  customform = new Customcatform('', '');

  onSubmit(form: NgForm, arr) {
    console.log(form.value);
    let list = form.value.varients.split(',');
    let custom_varient_value = []
    list.map((res) => custom_varient_value.push({ "varient_name": res }))
    let body = {
      "custom_varient_name": form.value.name,
      "custom_varient_icon": '123',
      "custom_varient_value": custom_varient_value
    }

    this.api.post(`add_custom_varient`, body).subscribe(
      data => {
        console.log('!success', data)
        this.api.get(`varient_list`).subscribe(
          data => {
            this.arr = Object.values(Object.values(data)[2])[1];
            console.log(this.arr)
            let val = []
            this.arr.map((res) =>
              this.varientsforSingleProductCustomforMake.push(
                {
                  name: res.custom_varient_name, image: '../../../../assets/icon/settings.png',
                  everything: [{
                    details: [{ name: res.custom_varient_name, list: res.custom_varient_value }],
                    sameShow: true, differentShow: false,
                  }]
                })
            )
            this.varientsforSingleProductCustom = this.varientsforSingleProductCustomforMake
            this.varientsforSingleProductCustomforMake = []
            form.reset();
          },
          // .map((data)=> val.push({"varient_name" : data.varient_name}))
          error => console.log(error)
        )
      },
      error => console.log(error)
    )
  }

  isCopy = false

  copy(arrayOfProducts) {
    for (let index = 1; index < arrayOfProducts.length; index++) {
      arrayOfProducts[index] = { ...JSON.parse(JSON.stringify(arrayOfProducts[0])), coverImg: arrayOfProducts[index].coverImg }
      // for (const key in arrayOfProducts[index]) {
      //   // console.log(key)
      //   // console.log(arrayOfProducts[0][key])
      //   if (key !== 'coverImg' && key !== 'sku' && key !== 'varientsforSingleProductCustomforMake' && key !== 'sku_valid') {
      //     if (key === 'custom') {
      //       // let Array = []
      //       // if (arrayOfProducts[index].custom.length === 0) {
      //       //   arrayOfProducts[index].varientsforSingleProductCustomforMake.map((res) => {
      //       //     Array.push({
      //       //       "variable_name": res.name,
      //       //       "custom_varient_img1": res.custom_varient_img1,
      //       //       "custom_varient_img2": res.custom_varient_img2,
      //       //       "variable_description": res.everything.map(val =>
      //       //       ({
      //       //         "value": '',
      //       //         "quantity": '',
      //       //         "discount": arrayOfProducts[index].discount,
      //       //         "price": arrayOfProducts[index].price,
      //       //         "sku": this.sku_weight_mesurement.store_generate_auto ? Math.random().toString(36).toUpperCase().substr(2, 10) : arrayOfProducts[index].sku,
      //       //         "barcode": '',
      //       //         "mrp": arrayOfProducts[index].mrp
      //       //       }),
      //       //       )
      //       //     })
      //       //   })
      //       //   arrayOfProducts[index].custom = Array
      //       //   console.log(arrayOfProducts[index].custom)
      //       // }
      //     } else {
      //       if (key === 'typeOfProduct') {
      //         console.log(this.type_of_productList.find(type => type.type_of_product_sub === arrayOfProducts[0].typeOfProduct))
      //         this.onType(this.type_of_productList.find(type => type.type_of_product_sub === arrayOfProducts[0].typeOfProduct), arrayOfProducts[index])
      //       } else {
      //         arrayOfProducts[index][key] = arrayOfProducts[0][key]
      //       }
      //     }
      //     // if (key === 'varientsforSingleProductCustomforMake') {
      //     //   console.log(arrayOfProducts[0].type_of_product_sub)
      //     //   console.log(this.type_of_productList.find(type => type.type_of_product_sub === arrayOfProducts[0].typeOfProduct))
      //     //   // this.onType(this.type_of_productList.find(type => type.type_of_product_sub === arrayOfProducts[index].type_of_product_sub), arrayOfProducts[index])
      //     // }
      //   }
      // }
    }
  }

  catagories = []

  catagory;

  onCat(main, submain, sub, dropDown, obj) {
    obj.product_main_category = main.category_name,
      obj.product_main_category_id = main._id,
      obj.product_sub_main_category = submain.category_name,
      obj.product_sub_main_category_id = submain._id,
      obj.product_sub_category = sub.category_name,
      obj.catagory_id = sub._id
    obj.loader = true
    this.api.get(`product_type_list/${obj.product_main_category_id}`).subscribe(
      data => {
        console.log(data)
        this.type_of_productList = Object.values(data)[2]
        console.log(this.type_of_productList)
        if (!submain) {
          obj.loader = false
        }
      },
      error => console.log('Error!', error)
    )
    this.api.get(`product_type_list/${obj.product_sub_main_category_id}`).subscribe(
      data => {
        console.log(Object.values(data)[2])
        this.type_of_productList = this.type_of_productList.concat(Object.values(data)[2])
        // Object.values(data)[2].map((res) => this.type_of_productList.push(res))
        console.log(this.type_of_productList)
        if (!sub) {
          obj.loader = false
        }
      },
      error => console.log('Error!', error)
    )
    this.api.get(`product_type_list/${obj.catagory_id}`).subscribe(
      data => {
        console.log(Object.values(data)[2])
        this.type_of_productList = this.type_of_productList.concat(Object.values(data)[2])
        console.log(this.type_of_productList)
        obj.loader = false
      },
      error => console.log('Error!', error)
    )
    dropDown.close()
  }

  Showsubcat(val) {
    val.click = !val.click
  }

  brands: any = [];
  onBrand(v, dropDown, obj) {
    obj.brandName = v.brand_name
    obj.brandId = v._id
    dropDown.close()
  }


  Types = [];

  onType(v, obj) {
    obj.typeOfProduct = v.type_of_product_sub
    obj.type_of_product_addons = v.type_of_product_addons
    obj.attributeList = v.type_of_product_attribute
    v.type_of_product_gst_percentage ? obj.product_gst_percentage = v.type_of_product_gst_percentage : 0
    let arr = []
    v.type_of_product_custom_varient.map((res) =>
      arr.push(
        {
          name: res.custom_varient_product_type, image: '../../../../assets/icon/settings.png', disable: false,
          everything: [{
            details: [{ name: res.custom_varient_product_type, list: res.custom_varient_value }],
            sameShow: true, differentShow: false,
          }],
          custom_varient_img1: res.custom_varient_img1,
          custom_varient_img2: res.custom_varient_img2
        }
      )
    )
    obj.varientsforSingleProductCustomforMake = arr
    let Array = []
    obj.varientsforSingleProductCustomforMake.map((res) => {
      Array.push({
        "variable_name": res.name,
        "custom_varient_img1": res.custom_varient_img1,
        "custom_varient_img2": res.custom_varient_img2,
        "variable_description": [
          {
            "value": '',
            "quantity": '',
            "discount": obj.discount,
            "price": obj.price,
            "sku": this.sku_weight_mesurement.store_generate_auto ? Math.random().toString(36).toUpperCase().substr(2, 10) : obj.sku,
            "barcode": '',
            "mrp": obj.mrp
          },
        ]
      })
    })
    obj.custom = Array
  }

  open(content) {
    // if (this.singleProductForm.attributeList.length > 0) {
    this.modalService.open(content, { scrollable: true });
    // }
    // try {

    // } catch (Error) {
    //   console.log(Error);
    // }
  }

  productAttribute(attrparent, attr, form, e) {
    if (e.target.checked) {
      let hasparent = form.productAttribute.some((res) => res.attribute_name === attrparent.type_of_product_attribute_name)
      if (hasparent) {
        form.productAttribute.map((res) => {
          if (res.attribute_name === attrparent.type_of_product_attribute_name) {
            res.attribute_description = res.attribute_description.concat(' & ', attr.type_of_product_value)
          }
        })
      } else {
        form.productAttribute.unshift({ "attribute_name": attrparent.type_of_product_attribute_name, "attribute_description": attr.type_of_product_value, deleteButton: true })
      }
      if (attrparent.type_of_product_attribute_name === 'material') {
        if (form.wash_care) {
          if (form.wash_care.includes(attr.wash_care.toLowerCase()) === false) {
            form.wash_care = form.wash_care.concat(', ', attr.wash_care)
          }
        } else {
          form.wash_care = attr.wash_care
        }
        console.log(form.wash_care)
      }
    } else {
      form.productAttribute.map((res, i) => {
        if (res.attribute_name === attrparent.type_of_product_attribute_name) {
          let arr = res.attribute_description.split(' & ')
          let array = []
          arr.map((r) => {
            if (r !== attr.type_of_product_value) {
              array.push(r)
            }
          })
          res.attribute_description = array.join(' & ')
        }
      })
    }
  }

  SourceVendor(v, obj) {
    obj.SourceVendor = v.primary_contact
  }


  attributeList = [
    {
      attribute_name: '',
      attribute_description: '',
      deleteButton: false
    }
  ]

  attributeValue(v, dropdown, val) {
    // this.singleProductForm.attributeValue = v.attribute_name
    val.attribute_description = v.attribute_name
    dropdown.close()
  }

  addAttribute(form) {
    form.productAttribute.map((res) => res.deleteButton = true)
    form.productAttribute.push({
      attribute_name: '',
      attribute_description: '',
      deleteButton: false
    })
  }


  deleteVar(list, item) {
    let array = list
    array.splice(list.indexOf(item), 1);
    console.log(list)
  }


  deleteVarients(list, index, custom_array) {
    let array = list.everything
    if (array.length > 1) {
      array.splice(index, 1);
      list.disable = false
      let custom = custom_array
      custom.splice(index, 1);
    }
  }

  addMore(i, list) {
    list.map((res) => res.deleteButton = true)
    list.push({ name: '', des: '', deleteButton: false })
    // console.log(this.variableList)
  }

  addMore2(i, list) {
    list.map((res) => res.deleteButton = true)
    list.push({ minQuality: '', maxQuality: '', price: '', deleteButton: false })
  }

  priceAndDes = 'samepriceAndDes';
  priceAndDes2 = 'samepriceAndDes2';
  setComboAvalable = 'combo';

  select_size = 'true';

  cropImage = null

  getcropImage(image) {
    this.cropImage = image
  }
  uploadImage = null

  onFileUpload(event, contant) {
    let reader = new FileReader();
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.uploadImage = reader.result;
        this.modalService.open(contant)
      }
    }
  }

  uploadToSever() {
    this.loading = true
    this.api.post(`add_product_image`, this.cropImage).subscribe(
      data => {
        this.loading = false
        let imagelink = Object.values(data)[2];
        this.imgLinks.push({ 'image_link': imagelink })
        this.singleProductForm.coverImg = this.imgLinks[0].image_link
        this.closeModel()
      },
      error => {
        this.loading = false
        console.log(error)
      }
    )
  }

  closeModel() {
    this.uploadImage = null
    this.modalService.dismissAll()
  }

  onUpload(formlist) {
    this.loading = true
    this.api.post(`add_product_image`, this.cropImage).subscribe(
      data => {
        console.log(data)
        this.loading = false
        let imagelink = Object.values(data)[2];
        formlist.push(new ProductAdd(false, imagelink, true, 'new', '', '', '', '', '', '', '', '', '', '', '', '', '0', '0', '0', '', 0, false, '', '',
          false, '', '', [], '', [], [],
          '', '', '', '', '', '', true, false, '', [{ name: '', des: '', deleteButton: false }],
          [{ minQuality: '', maxQuality: '', price: '' }], [], [], { status: '', message: '' }))
        this.closeModel()
      },
      error => {
        this.loading = false
        console.log(error)
      }
    )
  }


  // onFileUpload(event) {
  //   const file: File = event.target.files;
  //   console.log(file)
  //   var filesAmount = event.target.files.length;
  //   if (filesAmount <= 20 && this.imgLinks.length < 20) {
  //     for (let i = 0; i < filesAmount; i++) {
  //       const formData = new FormData();
  //       formData.append('product_image', file[i])
  //       this.api.post(`add_product_image`, formData).subscribe(
  //         data => {
  //           let imagelink = Object.values(data)[2];
  //           this.imgLinks.push({ 'image_link': imagelink })
  //           this.singleProductForm.coverImg = this.imgLinks[0].image_link
  //           console.log(this.singleProductForm.coverImg)
  //         },
  //         error => console.log(error)
  //       )
  //     }
  //   }
  // }

  // onUpload(event, formlist) {
  //   const file: File = event.target.files;
  //   console.log(file)
  //   var filesAmount = event.target.files.length;
  //   if (filesAmount <= 20 && formlist.length < 20) {
  //     for (let i = 0; i < filesAmount; i++) {
  //       const formData = new FormData();
  //       formData.append('product_image', file[i])
  //       this.api.post(`add_product_image`, formData).subscribe(
  //         data => {
  //           console.log(data)
  //           let imagelink = Object.values(data)[2];
  //           formlist.push(new ProductAdd(false, imagelink, true, 'new', '', '', '', '', '', '', '', '', '', '', '', '', '0', '0', '0', '', 0, false, '', '',
  //             false, '', '', [], '', [], [],
  //             '', '', '', '', '', '', true, false, '', [{ name: '', des: '', deleteButton: false }],
  //             [{ minQuality: '', maxQuality: '', price: '' }], [], [], { status: '', message: '' }))
  //         },
  //         error => console.log(error)
  //       )
  //     }
  //   }
  // }



  deleteImg(value, list, obj) {
    let array = list
    let public_id = value.split("/")[8].split(".")[0];
    this.api.post(`remove_image`, { "image_id": `products/${public_id}` }).subscribe(
      data => {
        list.map((res) => {
          if (res.image_link === value) {
            array.splice(list.indexOf(res), 1)
          }
        })
        if (obj) {
          if (value === obj.coverImg) {
            obj.coverImg = array[0].image_link
          }
        }
        // console.log(array)
      },
      error => console.log(error)
    )
  }

  deleteProduct(value, list) {
    let array = list
    let public_id = value.coverImg.split("/")[8].split(".")[0];
    this.api.post(`remove_image`, { "image_id": `products/${public_id}` }).subscribe(
      data => {
        console.log(data)
        list.map((res) => {
          if (res.coverImg === value.coverImg) {
            array.splice(list.indexOf(value), 1)
          }
        })
      },
      error => console.log(error)
    )
  }



  alert: any = false
  type
  message

  close() {
    console.log(this.alert)
  }

  setSingle = { "single_product_type": false, "set_product_type": false }

  multipleProductsave(product_upload, list) {
    this.alert = false
    let productArray = []

    list.map((obj) => {
      if (obj.sku_valid.status) {
        this.alert = true
        this.type = 'danger'
        this.message = obj.sku_valid.message
      }
    })

    list.map((obj) => {
      if (obj.weight < 0) {
        this.alert = true
        this.type = 'danger'
        this.message = `Weight Can't be Negetive`
      }
    })

    if (!this.alert) {
      list.map((obj) => {
        let body =
        {
          "product_upload_type": product_upload,

          "physical_product_condition": obj.physical_product_condition,

          "product_type": obj.productType ? 'Physical product' : 'Logical product',

          "combo_product": product_upload === 'set_product_upload' ? (this.setComboAvalable === 'combo' ? true : false) : false,

          "combo_product_id": product_upload === 'set_product_upload' ? (this.setComboAvalable === 'combo' ? this.myId : '') : '',

          "set_product_id": product_upload === 'set_product_upload' ? (this.setComboAvalable === 'set' ? this.myId : '') : '',

          "set_product_count": product_upload === 'set_product_upload' ? (this.setComboAvalable === 'set' ? this.setImageLinks.length : '0') : '0',

          "product_main_category": obj.product_main_category,

          "product_main_category_id": obj.product_main_category_id,

          "product_sub_main_category": obj.product_sub_main_category,

          "product_sub_main_category_id": obj.product_sub_main_category_id,

          "product_sub_category": obj.product_sub_category,

          "category_id": obj.catagory_id,

          "brand_id": obj.brandId ? obj.brandId : null,

          "product_name": obj.productName,

          "price_from_buyer": obj.price,

          "price_discount_from_buyer": obj.discount ? obj.discount : 0,

          "product_description": obj.Description,

          "product_cost_price": obj.productCostPrice,

          "quantity_in_stock": obj.quantity_in_stock_unlimited ? 10000000 : obj.quantity,

          "quantity_in_stock_unlimited": obj.quantity_in_stock_unlimited,

          "available_to_shop_upto": obj.AvaliableToShopUpTo,

          "pre_order": obj.PreOrder,

          "mrp": obj.mrp,

          "pre_order_launch_date": obj.expectedLaunchDate,

          "product_video_link": obj.AddVideoURL,

          "type_of_product": obj.typeOfProduct,

          "gst_id": obj.gst_id ? obj.gst_id : null,

          "type_of_product_addons": obj.type_of_product_addons,

          "wash_care": obj.wash_care,

          "product_attribute": [],

          "barcode_number": obj.barcode,

          "sku": this.sku_weight_mesurement.store_generate_auto ? Math.random().toString(36).toUpperCase().substr(2, 10) :
            (this.sku_weight_mesurement.store_sku_manual_start_from ?
              `${this.sku_weight_mesurement.store_sku_manual_start_from} - ${obj.sku}` : obj.sku),

          "source_vendor": obj.SourceVendor,

          "ref_sku": obj.RefferenceSKU,

          "product_weight": obj.weight,

          "product_weight_unit": this.unit,

          "gift_wrap": obj.giftWrap,

          "custom_varient": [],

          "cover_image": obj.coverImg,

          "image": product_upload === 'single product upload' ? this.imgLinks : [{ 'image_link': obj.coverImg }],

          "product_var": [],

          "product_bulk_price": [],

          "set_single_product": this.setSingle,

          "country_origin_name": obj.country_origin_name,

          "featured_product": false
        };

        obj.variableList.map((res) => {
          if (res.name && res.des) {
            body.product_var.push({ "variable_name": res.name, "variable_description": res.des })
          }
        }
        )
        obj.bulkPricelist.map((res) => {
          if (res.minQuality && res.maxQuality) {
            body.product_bulk_price.push({ "min_quantity": res.minQuality, "max_quantity": res.maxQuality, "price": res.price })
          }
        }
        )

        obj.productAttribute.map((res) => {
          if (res.attribute_name && res.attribute_description) {
            body.product_attribute.push({ "attribute_name": res.attribute_name, "attribute_description": res.attribute_description })
          }
        }
        )
        let array = []
        obj.custom.map((res) => {
          res.variable_description.map((result, i) => {
            if (result.value === '') {
              result.discount = result.discount ? result.discount : 0
              res.variable_description.splice(i, 1)
            } else {
              array.push(parseFloat(result.price))
            }
          })
        })
        obj.custom.map((res) => {
          if (res.variable_description.length > 0) {
            body.custom_varient.push(res)
          }
        })
        if (obj.custom.length > 0) {
          obj.custom.map((res) => {
            res.variable_description.find((result) => {
              if (parseFloat(result.price) === Math.min(...array)) {
                console.log(result)
                body.price_from_buyer = parseFloat(result.price),
                  body.price_discount_from_buyer = parseFloat(result.discount),
                  body.mrp = parseFloat(result.mrp)
                body.barcode_number = result.barcode
                body.sku = result.sku
              }
            })
          })
        }
        productArray.push(body)
      })

      console.log(productArray)
      this.api.post(`add_many_product`, { "productArray": productArray }).subscribe(
        data => {
          console.log(Object.values(data))
          this.alert = true
          this.type = Object.values(data)[0]
          if (this.type === 'success') {
            this.message = product_upload === 'single product upload' ? 'Product uploaded successfully' : 'products uploaded successfully'
            window.location.reload()
          } else {
            this.message = Object.values(data)[1]
          }
        },
        error => console.log(error)
      )
    }
  }

  getValues(endpoint, declairValues) {
    this.api.get(endpoint).subscribe(
      data => {
        declairValues = Object.values(data)[2];
        // this.api.countryShare(this.countries);
        console.log(declairValues)
      },
      error => console.log('Error!', error)
    )
  }

  constructor(private modalService: NgbModal, private api: ApiService,) { }


  ngOnInit(): void {
    this.api.get(`source_vendor_list`).subscribe(
      data => {
        this.vendorList = Object.values(data)[2];
      },
      error => console.log('Error!', error)
    )
    this.api.get(`brand_list`).subscribe(
      data => {
        this.brands = Object.values(data)[2];
        console.log(this.brands)
      },
      error => console.log('Error!', error)
    )
    this.api.get(`populate_all_category`).subscribe(
      data => {
        this.loader = false
        this.catagories = Object.values(data)[2]
        console.log(this.catagories)
      },
      error => console.log('Error!', error)
    )
    this.api.get(`store_gst_list`).subscribe(
      data => {
        console.log(data)
        this.gstList = Object.values(data)[2];
      },
      error => console.log('Error!', error)
    )
    this.api.get(`product_type_list/0`).subscribe(
      data => {
        let status = Object.values(data)[0]
        if (status === 'success') {
          this.type_of_productList = Object.values(data)[2]
        }
      },
      error => console.log(error)
    )
    this.api.get('store_sku_and_weight_list').subscribe(
      data => {
        this.unit = Object.values(data)[2][0].store_weight_mesurements;
        this.sku_weight_mesurement = Object.values(data)[2][0]
        console.log(this.sku_weight_mesurement)
      },
      error => console.log('Error!', error)
    )
  };

}