<div class="loaderBackground" *ngIf="loader">
    <div class="loaderparent"></div>
    <div class="loader">
        <div class="spinner-grow text-success mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success  mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success  mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>
<div>
    <section class="upper">
        <div>
            <div class="heading text-center">
                <h4 class="text-capitalize">Pages Info</h4>
            </div>
        </div>
        <div class="container-fluid ">
            <div class="border border-success bg-white py-4 d-flex justify-content-center">
                <div class="position-absolute title" style="right: 30px;">
                    <button class="btn p-0 rounded-0 text-success">
                        <i class="fas fa-times-circle"></i>
                    </button>
                </div>
                <div class="w-50">
                    <form (ngSubmit)="onSubmit(pageInfo)" #pageInfo="ngForm">
                        <div class="form-group productInfo">
                            <label class="title">*Country</label>
                            <select name="country" class="form-control" [(ngModel)]="pagesInfo.country" required>
                                <option value="{{country._id}}-{{country.selected_country_name}}"
                                    *ngFor="let country of countries">{{country.selected_country_name}}</option>
                            </select>
                        </div>
                        <div class="form-group productInfo">
                            <label class="title">*Page Name</label>
                            <select name="pageName" class="form-control" [(ngModel)]="pagesInfo.pageName" required>
                                <option value="About Us">About Us</option>
                                <option value="Terms and Conditions">Terms and Conditions</option>
                                <option value="Privacy Policy">Privacy Policy</option>
                                <option value="Refunds/Cancellations">Refunds/Cancellations</option>
                                <option value="Pricing Policy">Pricing Policy</option>
                                <option value="Contact">Contact</option>
                            </select>
                        </div>
                        <div class="form-group position-relative">
                            <label class="title">*Description</label>
                            <angular-editor name="description" [placeholder]="'Enter text here...'" [(ngModel)]="pagesInfo.description"></angular-editor>
                        </div>
                        <div class="col-12 text-center">
                            <button type="submit" class="btn btn-success rounded-0"
                                [disabled]="pageInfo.form.invalid">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <section class="container-fluid">
        <ul class="list-group p-0 my-4  border-top rounded-0">
            <li class="col-12 border-left font-weight-bold">
                <div class="row">
                    <div class="col-3">
                        <div class="row h-100">
                            <div class="col-2 py-3 px-0 text-center border-right border-bottom">Sl.No</div>
                            <div class="col-4 py-3 px-0 text-center border-right border-bottom">PAGE NAME</div>
                            <div class="col-6 py-3 border-right border-bottom">Country</div>
                        </div>
                    </div>
                    <!-- <div class="col-1 py-3 border-right border-bottom">Sl.No.</div>
                    <div class="col-2 py-3 border-right border-bottom">PAGE NAME</div> -->
                    <div class="col-7 py-3 border-right border-bottom">Description</div>
                    <div class="col-2">
                        <div class="row h-100">
                            <div class="col-8 py-3 border-right border-bottom">
                                Action
                            </div>
                            <div class="col-4 py-3 border-right border-bottom">
                                Status
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="col-12 border-left" *ngFor="let item of pages; index as i">
                <div class="row">
                    <div class="col-3">
                        <div class="row h-100">
                            <div class="col-2 py-3 border-right border-bottom d-flex flex-wrap">{{i+1}}</div>
                            <div class="col-4 py-3 border-right border-bottom flex-wrap">{{item.page_name}}</div>
                            <div class="col-6 py-3 border-right border-bottom flex-wrap">{{item.page_country_name}}</div>
                        </div>
                    </div>
                    <!-- <div class="col-7 py-3 border-right border-bottom" >{{item.page_description}}</div> -->
                    <div class="col-7 py-3 border-right border-bottom" [innerHTML]="item.page_description"></div>
                    <div class="col-2">
                        <div class="row h-100">
                            <div class="col-4 py-3 border-right border-bottom">
                                <button class="btn p-0 text-success" (click)="update(item)">
                                    <i class="fas fa-edit"></i>
                                </button>
                            </div>
                            <div class="col-4  py-3 border-right border-bottom">
                                <button class="btn p-0 text-danger" (click)="delete(item)">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                            </div>
                            <div class="col-4 py-3 border-right border-bottom">
                                <button class="btn p-0" [class.text-success]="item.status" [class.text-secondary]="!item.status" (click)="view(item)">
                                    <i class="fas"  [class.fa-eye]="item.status" [class.fa-eye-slash]="!item.status"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </section>
</div>