<div class="loaderBackground" *ngIf="loader">
    <div class="loaderparent"></div>
    <div class="loader">
        <div class="spinner-grow text-success mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success  mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success  mx-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>
<div>
    <section class="upper">
        <h4 class="text-capitalize text-center">Add Brands</h4>

        <div class="container-fluid ">
            <div class="border border-success bg-white py-4 d-flex justify-content-center">
                <div class="position-absolute title" style="right: 30px;">
                    <button class="btn p-0 rounded-0 clean">
                        <i class="fas fa-times-circle"></i>
                    </button>
                </div>
                <div class="col-6">
                    <form (ngSubmit)="onSubmit(logoIconForm)" #logoIconForm="ngForm">
                        <div class="row">
                            <div class="col-8">
                                <div class="form-group">
                                    <label class="title">*Name</label>
                                    <input type="text" class="form-control" name="brandName"
                                        [(ngModel)]="brandInfo.brandName" required>
                                </div>
                                <div class="form-group">
                                    <label class="title">*Company Name</label>
                                    <input type="text" class="form-control" name="comapnyName"
                                        [(ngModel)]="brandInfo.comapnyName" required>
                                </div>
                            </div>
                            <div class="col-1"></div>
                            <div class="col-3">
                                <div class="border border-success position-relative" style="height: 90%;">
                                    <img [src]="brandInfo.img" alt="productname" class="w-100 h-100">
                                    <label class="position-absolute btn btn-success px-1 py-0 m-2"
                                        style="right: 0; font-size: 12px; display:inline-flex;">Add Images
                                        <input type="file" size="60" style="display: none;" name="img"
                                            ngModel #img (change)="onFileChanged($event)"
                                            required></label>
                                </div>
                                 <!-- <small class="text-danger" *ngIf="!imgType">img should be .jpg or .png</small> -->

                            </div>
                            <div class="col-12 text-center">
                                <button type="submit" class="btn btn-success rounded-0" *ngIf="!edit">Save</button>
                                <button type="click" class="btn btn-success rounded-0"  *ngIf="edit" (click)="onUpdate(logoIconForm)">
                                    Update</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <section class="container-fluid">
        <ul class="list-group p-0 my-4  border-top rounded-0">
            <li class="col-12 border-left font-weight-bold">
                <div class="row">
                    <div class="col-1 py-3 border-right border-bottom">Sl.No.</div>
                    <div class="col-3">
                        <div class="row">
                            <div class="col-6 py-3 border-right border-bottom">Country</div>
                            <div class="col-6 py-3 border-right border-bottom">Company Name</div>
                        </div>
                    </div>
                    <div class="col-2 py-3 border-right border-bottom">Brand NAME</div>
                    <div class="col-1 py-3 border-right border-bottom">Icon</div>
                    <div class="col-3 py-3 border-right border-bottom">Link</div>
                    <div class="col-2">
                        <div class="row h-100">
                            <div class="col-8 py-3 border-right border-bottom">
                                Action
                            </div>
                            <div class="col-4 py-3 border-right border-bottom">
                                Status
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="col-12 border-left" *ngFor="let item of brandList; index as i">
                <div class="row">
                    <div class="col-1 py-3 border-right border-bottom">{{i+1}}</div>
                    <div class="col-3 ">
                        <div class="row h-100">
                            <div class="col-6 py-3 border-right border-bottom">Universal</div>
                            <div class="col-6 py-3 border-right border-bottom">{{item.company_name}}</div>
                        </div>
                    </div>
                    <div class="col-2 py-3 border-right border-bottom">{{item.brand_name}}</div>
                    <div class="col-1 py-3 border-right border-bottom">
                        <img [src]="item.brand_image" alt="icon" class="w-100" style="height: 50px;">
                    </div>
                    <div class="col-3 py-3 border-right border-bottom">
                        <a [href]="item.link">{{item.link}}</a>
                    </div>
                    <div class="col-2">
                        <div class="row h-100">
                            <div class="col-4 py-3 border-right border-bottom">
                                <button class="btn p-0 edit" (click)="update(item)">
                                    <i class="fas fa-edit"></i>
                                </button>
                            </div>
                            <div class="col-4  py-3 border-right border-bottom">
                                <button class="btn p-0 delete" (click)="delete(item)">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                            </div>
                            <div class="col-4 py-3 border-right border-bottom" *ngIf="item.status">
                                <button class="btn p-0 view"(click)="off(item)">
                                    <i class="fas fa-eye"></i>
                                </button>
                            </div>
                            <div class="col-4 py-3 border-right border-bottom" *ngIf="!item.status">
                                <button class="btn p-0 viewOff"(click)="on(item)">
                                    <i class="fas fa-eye-slash" *ngIf="!item.status"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </section>

</div>