export class PageDesign {
    carosel_animation: string;
    constructor(
        public page_id : string,
        public page_name: String,
        public grid_name: String,
        public showTitleBar: boolean,
        public container_title_design: {
            container_title_text: string,
            container_title_fontSize: string,
            container_title_color: string,
        },
        public container_subtitle_design: {
            container_subtitle_text: string,
            container_subtitle_fontSize: string,
            container_subtitle_color: string,
        },
        public total_number_of_grid: string,
        public grid_background: string,
        public grid_background_color: string,
        public grid_background_image: string,
        public is_valid_date: string,
        public is_valid_from: string,
        public is_valid_upto: String,
        public design_type: string,
        public direction: string,
        public data: Array<Object>,
        public animation: string,
        public carosel_animation_time: string,
        public position: Number,
        public arrows: string,
        public grid_height: string,
        public grid_width: string,
        public cross_axis_count: string,
        public margin: { margin_top: string, margin_right: string, margin_bottom: string, margin_left: string },
        public padding: { padding_top: string, padding_right: string, padding_bottom: string, padding_left: string },
        public is_grid_have_text_area: Boolean,
        public grid_text_area_location: String,
        public grid_text_area_height: string,
        public grid_image_height: Number,
        public bg_color: String,
        public opacity: string,
        public text_align: string,
        public is_grid_border: boolean,
        public grid_border_radius: string,
        public item_gap: string,
        public image_border_radius: { image_border_top_left: string, image_border_top_right: string, image_border_bottom_left: string, image_border_bottom_right: string },
        public text_area_border_radius: { text_area_border_top_left: string, text_area_border_top_right: string, text_area_border_bottom_left: string, text_area_border_bottom_right: string },
        public grid_border_color: string,
        public grid_border_width: string,
        public showOptionButton: boolean,
        public option_button: { title: string, titleColor: string, fontSize: string, backgroundColor: string, applied_to: string,applied_to_id: string, check_page_type: string },
        public product_data : Array<String>
    ) { }
}
