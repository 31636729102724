<div class="container-fluid bg-light py-4">
    <div class="container-fluid">
        <p class="heading">Inventory Settings</p>
        <h3>General Settings</h3>
        <form class="bg-white border rounded p-4">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right">When a product is out of
                    stock</label>
                <div class="col-sm-5">
                    <select class="form-control" name="product_out_of_stock" [(ngModel)]="inventory.product_out_of_stock">
                        <option value="Hide Product Completely">Hide Product Completely</option>
                        <option value="Hide Product But leave product page accessible">Hide Product But leave product
                            page accessible</option>
                        <option value="Redirect to catagory page">Redirect to catagory page</option>
                        <option value="Don't do anything">Don't do anything</option>
                    </select>
                </div>
                <!-- <span class="col-1">
                <span class="badge badge-pill badge-secondary">?</span>
              </span> -->
            </div>
            <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right">When an option is out of
                    stock</label>
                <div class="col-sm-5">
                    <select class="form-control" name="option_out_of_stock" [(ngModel)]="inventory.option_out_of_stock">
                        <option value="Hide the option">Hide the option</option>
                        <option value="Mark the option as out of stock">Mark the option as out of stock</option>
                        <option value="Don't do anything">Don't do anything</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right">update stock levels</label>
                <div class="col-sm-5">
                    <select class="form-control" name="update_stock_level" [(ngModel)]="inventory.update_stock_level">
                        <option value="When an order is successfully placed">When an order is successfully placed</option>
                        <option value="When order status is changed to Completed or Shipped">When order status is changed to Completed or Shipped</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 col-form-label"></label>
                <div class="col-sm-5">
                    <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" name="Adjust_stock_levels_when_editing_an_order" 
                        [(ngModel)]="inventory.Adjust_stock_levels_when_editing_an_order">
                        <label class="form-check-label checkbox-level">Autometically Adjust stock levels when editing an
                            order</label>
                    </div>
                    <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" name="Adjust_stock_levels_when_I_refound_or_cancel_an_order" 
                        [(ngModel)]="inventory.Adjust_stock_levels_when_I_refound_or_cancel_an_order">
                        <label class="form-check-label checkbox-level">Autometically Adjust stock levels when I refound
                            or cancel an order</label>
                    </div>
                </div>
            </div>
        </form>

    </div>
    <div class="container-fluid">
        <h3>Stock Level</h3>
        <form class="bg-white border rounded p-4">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right">Stock Level Display</label>
                <div class="col-sm-5">
                    <select class="form-control" name="Stock_Level_Display" [(ngModel)]="inventory.Stock_Level_Display">
                        <option value="Show stock levels">Show stock levels</option>
                        <option value="Only show stock levels when stock is low">Only show stock levels when stock is
                            low</option>
                        <option value="Don't show stock level">Don't show stock level</option>
                    </select>
                </div>
                <!-- <span class="col-1">
                <span class="badge badge-pill badge-secondary">?</span>
              </span> -->
            </div>
            <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right">Out of Stock Message</label>
                <div class="col-sm-5">
                    <select class="form-control" name="Out_of_Stock_Message" [(ngModel)]="inventory.Out_of_Stock_Message">
                        <option value="Hide the option">Hide the option</option>
                        <option value="Mark the option as out of stock">Mark the option as out of stock</option>
                        <option value="Don't do anything">Don't do anything</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 col-form-label"></label>
                <div class="col-sm-5">
                    <div class="form-group form-check" >
                        <input type="checkbox" class="form-check-input" name="Show_Out_of_stock_message_on_product_listing_pages" 
                        [(ngModel)]="inventory.Show_Out_of_stock_message_on_product_listing_pages">
                        <label class="form-check-label checkbox-level">Show Out of stock message on product listing
                            pages</label>
                    </div>
                </div>
            </div>
        </form>

    </div>
    <div class="container-fluid">
        <h3>Email Notifications</h3>
        <form class="bg-white border rounded p-4">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right">Low stock level reached</label>
                <div class="col-sm-5">
                    <input type="email" class="form-control" name="Low_stock_level_reached" [(ngModel)]="inventory.Low_stock_level_reached"
                        placeholder="Enter an email address to enable this notification">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 col-form-label text-right">Out of Stock reached</label>
                <div class="col-sm-5">
                    <input type="email" class="form-control" id="exampleInputEmail1" name="Out_of_Stock_reached" [(ngModel)]="inventory.Out_of_Stock_reached"
                        placeholder="Enter an email address to enable this notification">
                </div>
            </div>
        </form>

    </div>
</div>