import { BrandType } from './brand-type';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.css']
})
export class BrandsComponent implements OnInit {

  brandList = []
  brandInfo = new BrandType('','','','');
  loader = true
  
  countries = [];
  cd: any;


  edit; 
  edit_id;

  update(value){
    this.edit = true;
    this.brandInfo.img = value.brand_image;
    this.edit_id = value._id;
    this.brandInfo.brandName = value.brand_name;
    this.brandInfo.comapnyName = value.company_name;
    console.log(value)
    this.brandList.splice(this.brandList.indexOf(value),1);
  }

  onUpdate(form:NgForm){
    console.log(form.value)
    let body = {
      "_id" : this.edit_id,
      "brand_name": this.brandInfo.brandName,
      "brand_image": this.brandInfo.img,
      "company_name": this.brandInfo.comapnyName,
    }
    
    this.api.put(`update_brand`,body).subscribe(
      data=> {
        console.log('!success',data);
        this.edit = false;
        this.brandInfo.img = ''
        this.api.get(`brand_list`).subscribe(
          data =>{
            this.brandList = Object.values(data)[2];
            // this.api.countryShare(this.countries);
            console.log(this.brandList)
          }, 
          error => console.log('Error!', error)
        )
        },
        error=> console.log(error)
    )
    //   console.log(form.value);
      form.reset();
  }

  off(item){
    this.api.put(`update_brand`,{"_id": item._id, "status": false}).subscribe(
      data=> {
        console.log('!success',data);
        this.edit = false;
        this.brandInfo.img = ''
        this.api.get(`brand_list`).subscribe(
          data =>{
            this.brandList = Object.values(data)[2];
            console.log(this.brandList)
          }, 
          error => console.log('Error!', error)
        )
        },
        error=> console.log(error)
    )
  }

  on(item){
    this.api.put(`update_brand`,{"_id": item._id, "status": true}).subscribe(
      data=> {
        console.log('!success',data);
        this.edit = false;
        this.brandInfo.img = ''
        this.api.get(`brand_list`).subscribe(
          data =>{
            this.brandList = Object.values(data)[2];
            console.log(this.brandList)
          }, 
          error => console.log('Error!', error)
        )
        },
        error=> console.log(error)
    )
  }

  onFileChanged(event) {
    if(this.brandInfo.img !== '' && this.brandInfo.img !==null){
      let public_id = this.brandInfo.img.split("/")[8].split(".")[0];
      this.api.post(`remove_image`, {"image_id":`brands/${public_id}`}).subscribe(
        data => {
          console.log(data)
        },
        error => console.log(error)
      )
    }
    const file:File = event.target.files[0];
    const formData = new FormData();
    formData.append('brand_image', file)
    console.log(file.name)
      this.api.post(`add_brand_image`,formData).subscribe(
        data=> {
          let val = Object.values(data)[2];
          this.brandInfo.img = val
          console.log(this.brandInfo.img)
        },
        error=> console.log(error)
      )

    
  }

  delete(value){
    let body = {
      "_id": value._id
    }
    this.api.post(`delete_brand`,body).subscribe(
      data=> {
        console.log('!success',data);
        this.edit = false;
        let public_id = value.brand_image.split("/")[8].split(".")[0];
        this.api.post(`remove_image`, {"image_id":`brands/${public_id}`}).subscribe(
          data => {
          console.log(data)
          },
        error => console.log(error)
      )
        this.api.get(`brand_list`).subscribe(
          data =>{
            this.brandList = Object.values(data)[2];
            console.log(this.brandList)
          }, 
          error => console.log('Error!', error)
        )
      },
        error=> console.log(error)
    )
    
  }
  
    onSubmit(form:NgForm){
      let body = {
      "brand_name": this.brandInfo.brandName,
      "brand_image": this.brandInfo.img,
      "company_name": this.brandInfo.comapnyName,
      }
        this.api.post(`add_brand`,body).subscribe(
        data=> {
          console.log('!success',data);
          this.api.get(`brand_list`).subscribe(
            data =>{
              this.brandList = Object.values(data)[2];
              form.reset();
              this.brandInfo.img = ''
              console.log(data)
            },
            error => console.log('Error!', error)
          )
        },
        error=> console.log(error)
      )
    }
  
  
    constructor(private api:ApiService) { 
    }
    ngOnInit(): void {
      this.api.get(`selected_country_list`).subscribe(
        data =>{
          this.countries = Object.values(data)[2];
          // this.api.countryShare(this.countries);
          console.log(this.countries)
        }, 
        error => console.log('Error!', error)
      )
      this.api.get(`brand_list`).subscribe(
        data =>{
          this.brandList = Object.values(data)[2];
          this.loader  = false
          // this.api.countryShare(this.countries);
          console.log(this.brandList)
        }, 
        error => console.log('Error!', error)
      )
    }
    //   this.api.countrySource$.subscribe(
    //     message => {
    //       this.countries = message;
    //     }
    //   )

}

