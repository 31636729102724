import { ApiService } from './../../../api.service';
import { Component, OnInit } from '@angular/core';
import { Form, NgForm } from '@angular/forms';
import { Catagory } from './catagory';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-new-catagory',
  templateUrl: './new-catagory.component.html',
  styleUrls: ['./new-catagory.component.css']
})
export class NewCatagoryComponent implements OnInit {

  countries = []

  catagoryForm = new Catagory('', '', '', '', '', '')

  catagories: any = []

  response = 'If You Delete This Catagory the Subcatagories under it are also deleted'
  catagoryList = []

  loader = false;


  onCat(category,dropdown) {
    if (category.main_category_id) {
      this.catagoryForm.main_category_id = category.main_category_id
      this.catagoryForm.main_sub_category_id = category._id
    } else {
      this.catagoryForm.main_category_id = category._id
      this.catagoryForm.main_sub_category_id = ''
    }
    dropdown.close()
  }

  subcatagory(){
    if (this.catagoryForm.catagory_set_primary) {
      this.catagoryForm.main_category_id = ''
      this.catagoryForm.main_sub_category_id = ''
    }
  }

  Showsubcat(val) {
    val.click = !val.click
  }

  obj: Object

  onFileChanged(event) {
    if (this.catagoryForm.category_image) {
      let public_id = this.catagoryForm.category_image.split("/")[8].split(".")[0];
      this.api.post(`remove_image`, { "image_id": `category_image/${public_id}` }).subscribe(
        data => {
          console.log(data)
        },
        error => console.log(error)
      )
    }
    const file: File = event.target.files[0];
    const formData = new FormData();
    formData.append('category_image', file)
    console.log(file)
    if (file.size < 500000) {
      this.api.post(`add_category_image`, formData).subscribe(
        data => {
          let val = Object.values(data)[2];
          this.catagoryForm.category_image = val
          console.log(this.catagoryForm.category_image)
        },
        error => console.log(error)
      )
    } else {
      console.log(file.size)
    }
  }
  wantEdit = false;
  edit_id;

  view(value) {
    // this.loader = true
    // console.log({'_id': value.cat_id, 'status': !value.status})
    this.api.put(`update_main_category`, { '_id': value._id, 'status': !value.status }).subscribe(
      data => {
        if (value.main_sub_category.length > 0) {
          value.main_sub_category.map((res) => {
            this.api.put(`update_main_sub_category`, { '_id': res._id, 'status': !value.status }).subscribe(
              data => {
                if (res.sub_category.length > 0) {
                  res.sub_category.map((result) => {
                    this.api.put(`update_sub_category`, { '_id': result._id, 'status': !value.status }).subscribe(
                      data => {
                        if (data.status === 'success') {
                          window.location.reload()
                        }
                      }
                    )
                  })
                } else {
                  if (data.status === 'success') {
                    window.location.reload()
                  }
                }
              }
            )
          })
        } else {
          if (data.status === 'success') {
            window.location.reload()
          }
        }
      },
      error => console.log('Error!', error)
    )
    // if (value.child_cat) {
    //   value.child_cat.map((res) => {
    //     if (res.child_cat) {
    //       res.child_cat.map((result) => {
    //         console.log(result.cat_name)
    //       })
    //       console.log(res.cat_name)
    //       console.log(value.cat_name)
    //     } else {
    //       console.log(res.cat_name)
    //       console.log(value.cat_name)
    //     }
    //   }
    //   )
    // } else {
    //   console.log(value.cat_name)
    // }
    // {'category_id': value.cat_id,'changes': {'status': !value.status}}
    // this.api.post(`edit_catagory`, value).subscribe(
    // data => {

    // this.countries.map((res) => {
    //   this.api.post(`get_footer_cat_subcat`, { "category_country_id": res._id, "category_country_name": res.selected_country_name }).subscribe(
    //     data => {
    //       this.catagories = Object.values(data)[2]
    //       if (this.catagories.length > 0) {
    //         this.catagoryList.push({
    //           "category_country_name": res.selected_country_name, "category_country_id": res._id,
    //           "catagories": this.catagories
    //         })
    //       }
    //     },
    //     error => console.log('Error!', error)
    //   )
    // },
    //   console.log(this.catagoryList)
    // )
    // },
    // error => console.log('Error!', error)
    // )
  }

  parent_id;

  edit(value, array, obj, catType) {
    this.catagoryForm = value
    value.main_sub_category_id || value.main_category_id ? this.catagoryForm.catagory_set_primary = ' ' : this.catagoryForm.catagory_set_primary = ''
    // array.splice(array.indexOf(value), 1);
  }

  update() {
    let endPoint;
    if (!this.catagoryForm.catagory_set_primary) {
      endPoint = 'update_main_category'
    } else {
      if (this.catagoryForm.main_sub_category_id && this.catagoryForm.main_category_id) {
        endPoint = 'update_sub_category'
      } else if (!this.catagoryForm.main_sub_category_id && this.catagoryForm.main_category_id) {
        endPoint = 'update_main_sub_category'
      } else {
        endPoint = 'update_main_category'
      }
    }
    this.api.put(endPoint, this.catagoryForm).subscribe(
      data => {
        console.log('Success!', data);
        if (data.status === 'success') {
         window.location.reload() 
        }
      },
      error => console.log('Error!', error)
    )
  }

  
  onSubmit(content) {
    let endPoint;
    if (!this.catagoryForm.catagory_set_primary) {
      endPoint = 'add_main_category'
    } else {
      if (this.catagoryForm.main_sub_category_id && this.catagoryForm.main_category_id) {
        endPoint = 'add_sub_category'
      } else if (!this.catagoryForm.main_sub_category_id && this.catagoryForm.main_category_id) {
        endPoint = 'add_main_sub_category'
      }else{
        this.modalService.open(content)
        this.response = 'Select Main Catagory'
      }
    }
    this.api.post(endPoint, this.catagoryForm).subscribe(
      data => {
        console.log('Success!', data);
        if (data.status === 'success') {
         window.location.reload() 
        }
      },
      error => console.log('Error!', error)
    )
  }

  delete(value) {
    let endPoint
    if (value.main_sub_category_id && value.main_category_id) {
      endPoint = 'delete_sub_category'
    } else if(!value.main_sub_category_id && value.main_category_id){
      endPoint = 'delete_main_sub_category'
    }else{
      endPoint = 'delete_main_category'
    }
    this.api.post(endPoint, { '_id': value._id, }).subscribe(
      data => {
        console.log(data)
        if (data.status === 'success') {
          if (this.catagoryForm.category_image) {
            let public_id = this.catagoryForm.category_image.split("/")[8].split(".")[0];
            this.api.post(`remove_image`, { "image_id": `category_image/${public_id}` }).subscribe(
              data => {
                window.location.reload()
              },
              error => console.log(error)
            )
          }
        } else {
          this.response = data.message
        }
      },
      error => console.log('Error!', error)
    )
  }

  open(content) {
    this.response = 'Are You Surely Want To Delete this Catagory'
    this.modalService.open(content)
  }


  constructor(private api: ApiService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.loader = true
    this.api.get(`selected_country_list`).subscribe(
      data => {
        this.countries = Object.values(data)[2];
        // Object.values(data)[2].map((res) => {
        //   this.api.post(`get_footer_cat_subcat`, { "category_country_id": res._id, "category_country_name": res.selected_country_name }).subscribe(
        //     data => {
        //       this.catagories = Object.values(data)[2]
        //       // this.loader = false
        //       if (this.catagories.length > 0) {
        //         this.catagoryList.push({
        //           "category_country_name": res.selected_country_name, "category_country_id": res._id,
        //           "child_cat": this.catagories
        //         })
        //       }
        //     },
        //     error => console.log('Error!', error)
        //   )
        // },
        //   console.log(this.catagoryList),
        //   setTimeout(() => {
        //     this.loader = false
        //   }, 5000)
        // )
      },
      error => console.log('Error!', error)
    );
    this.api.get(`populate_all_category`).subscribe(
      data => {
        this.loader = false
        this.catagories = Object.values(data)[2]
        // this.subCatagories = this.catagories
        console.log(this.catagories)
      },
      error => console.log('Error!', error)
    )
  }

}
