import { TopNavComponent } from './pages/admincomponent/top-nav/top-nav.component';
import { BrandsComponent } from './pages/admincomponent/brands/brands.component';
import { AddCountryComponent } from './pages/admincomponent/add-country/add-country.component';
import { OffersComponent } from './pages/admincomponent/offers/offers.component';
import { SocialComponent } from './pages/admincomponent/social/social.component';
import { PagesInfoComponent } from './pages/admincomponent/pages-info/pages-info.component';
import { CountryCurrencyComponent } from './pages/admincomponent/country-currency/country-currency.component';
import { IconLogoCaptionComponent } from './pages/admincomponent/icon-logo-caption/icon-logo-caption.component';
import { StoreSettingsComponent } from './pages/admincomponent/store-settings/store-settings.component';
import { NewCatagoryComponent } from './pages/admincomponent/new-catagory/new-catagory.component';
import { ExternalLinkComponent } from './pages/admincomponent/external-link/external-link.component';
import { BannerSettingComponent } from './pages/admincomponent/banner-setting/banner-setting.component';
import { ManageProductComponent } from './pages/admincomponent/manage-product/manage-product.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { AdmincomponentComponent } from './pages/admincomponent/admincomponent.component';
import { FetureProductSettingComponent } from './pages/admincomponent/feture-product-setting/feture-product-setting.component';
import { YoutubeChannelSettingsComponent } from './pages/admincomponent/youtube-channel-settings/youtube-channel-settings.component';
import { Child2Component } from './pages/admincomponent/store-settings/storeSettings/child2/child2.component';
import { Child3Component } from './pages/admincomponent/store-settings/storeSettings/child3/child3.component';
import { Child4Component } from './pages/admincomponent/store-settings/storeSettings/child4/child4.component';
import { Child1Component } from './pages/admincomponent/store-settings/storeSettings/child1/child1.component';
import { ProductAddComponent } from './pages/admincomponent/product-add/product-add.component';
import { RollOnTopComponent } from './pages/admincomponent/roll-on-top/roll-on-top.component';
import { AuthenticProductComponent } from './pages/admincomponent/authentic-product/authentic-product.component';
import { ProductSettingComponent } from './pages/admincomponent/product-setting/product-setting.component';
import { ExtraListComponent } from './pages/admincomponent/extra-list/extra-list.component';
import { AddVendorComponent } from './pages/admincomponent/add-vendor/add-vendor.component';
import { InventoryComponent } from './pages/admincomponent/inventory/inventory.component';
import { CustomerComponent } from './pages/admincomponent/customer/customer.component';
import { CustomerGroupComponent } from './pages/admincomponent/customer-group/customer-group.component';
import { IndexPageDesignComponent } from './pages/admincomponent/index-page-design/index-page-design.component';
import { OrdersComponent } from './pages/admincomponent/orders/orders.component';
import { TermsConditionComponent } from './pages/admincomponent/terms-condition/terms-condition.component';

const routes: Routes = [
  {path:'', component:AdmincomponentComponent,children:[
    // {path:'product' , loadChildren: ()=>import('./anotherpages/anotherpages.module').then(m => m.AnotherpagesModule)},
    {path:'product-add' , component: ProductAddComponent},
    // {path:'product' , loadChildren: ()=>import('./put-address/put-address.module').then(m => m.PutAddressModule)},
    {path:'inventory',component:InventoryComponent},
    {path:'product-manage/:param1/:param2/:param3' , component:ManageProductComponent},
    {path:'manage-customer/:param1/:param2/:param3', component:CustomerComponent},
    {path: 'customer-group', component: CustomerGroupComponent},
    // {path: 'order-list',component : OrdersComponent},
    {path: 'order-list/:param1/:param2',component : OrdersComponent},
    {path:'feture-product-setting' , component:FetureProductSettingComponent},
    {path:'banner-setting' , component:BannerSettingComponent},
    {path:'youtube-channel-setting', component:YoutubeChannelSettingsComponent,},
    {path:'create-external-link', component: ExternalLinkComponent},
    {path:'create-new-catagory', component: NewCatagoryComponent},
    {path:'add-vendor', component: AddVendorComponent},
    {path:'store-settings/:param', component: AddCountryComponent},
    {path:'pages_info_add', component: PagesInfoComponent},
    {path: 'offers', component: OffersComponent},
    {path:'child', component:StoreSettingsComponent,children:[
      {path:'child-1', component:Child1Component},
      {path:'child-2', component:Child2Component},
      {path:'child-3', component:Child3Component},
      {path:'child-4', component:Child4Component}
    ]},
    {path: 'index-page-design', component: IndexPageDesignComponent},
    {path:'roll-on-top',component:RollOnTopComponent},
    {path: 'brands', component:BrandsComponent},
    {path:'top-navbar', component:TopNavComponent},
    {path:'social_add', component: SocialComponent},
    {path:'icon-logo-caption-setting', component:IconLogoCaptionComponent},
    {path:'country-currency-setting', component:CountryCurrencyComponent},
    {path:'product-setting', component:ProductSettingComponent},
    {path:'authentic-product', component:AuthenticProductComponent},
    {path:'what-are-you-looking-for', component:ExtraListComponent},
    {path: 'terms-condition',component: TermsConditionComponent},
    { path: '', redirectTo: 'banner-setting', pathMatch: 'full' },
  ]},
  {path:'**', component:PagenotfoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
