<div>
    <section class="upper">
        <div>
            <div class="heading text-center">
                <h4 class="text-capitalize">Add Social</h4>
            </div>
        </div>
        <div class="container-fluid ">
            <div class="border border-success bg-white py-4 d-flex justify-content-center">
                <div class="position-absolute title" style="right: 30px;">
                    <button class="btn p-0 rounded-0">
                        <img src="../../../assets/img/icons/Icon material-cancel.png" alt="" class="w-100">
                    </button>
                </div>
                <div class="col-6">
                    <form #socialForm="ngForm">
                        <div class="row">
                            <div class="col-8">
                                <div class="form-group">
                                    <label class="title">*Name</label>
                                    <input type="text" class="form-control" name="socialName" [(ngModel)]="sociaInfo.socialName"
                                     required>
                                </div>
                                <div class="form-group">
                                        <label class="title">*Country</label>
                                        <select name="country" class="form-control" [(ngModel)]="sociaInfo.country" required>
                                            <option value="{{country._id}}-{{country.selected_country_name}}" *ngFor="let country of countries">{{country.selected_country_name}}</option>
                                        </select>
                                </div>
                                <div class="form-group">
                                    <label class="title">*Link</label>
                                    <input type="text" class="form-control" name="link" [(ngModel)]="sociaInfo.link"
                                     required>
                                </div>
                            </div>
                            <div class="col-1"></div>
                            <div class="col-3">
                                <div class="border border-success position-relative text-center" style="height: 90%;">
                                    <i class="showIcon" [class]="sociaInfo.img" aria-hidden="true"></i>
                                    <button class="btn btn-success btn-sm px-1 py-0 m-2"
                                    (click)="openVerticallyCentered(content)">
                                    Add Icons
                                    </button>
                                </div>
                                <!-- <small class="text-danger" *ngIf="!imgType">img should be .jpg or .png</small> -->
                                <ng-template #content let-modal>
                                    <div class="modal-header border-0">
                                      <h4 class="modal-title" id="modal-basic-title">Choose Icons From Here</h4>
                                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                        <img src="../../../assets/img/icons/Icon material-cancel.png" alt="" class="w-100">
                                      </button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="d-flex flex-wrap">
                                            <button class="btn m-2" *ngFor="let icon of icons" (click)="chooseIcon(icon)">
                                                <i [class]="icon.icon" aria-hidden="true"></i>
                                            </button>
                                          </div>
                                    </div>
                                    
                                  </ng-template>                                  
                        </div>
                        <div class="col-12 text-center">
                            <button type="submit" class="btn btn-success rounded-0" *ngIf="!edit"
                                [disabled]="socialForm.form.invalid" (click)="onSubmit(socialForm)">Submit</button>
                            <button type="submit" class="btn btn-success rounded-0" *ngIf="edit"
                                [disabled]="socialForm.form.invalid" (click)="onUpdate(socialForm)">Update</button>
                        </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <section class="container-fluid">
        <ul class="list-group p-0 my-4  border-top rounded-0">
            <li class="col-12 border-left font-weight-bold">
                <div class="row">
                    <div class="col-1 py-3 border-right border-bottom">Sl.No.</div>
                    <div class="col-2 py-3 border-right border-bottom">Country</div>
                    <div class="col-2 py-3 border-right border-bottom">NAME</div>
                    <div class="col-1 py-3 border-right border-bottom">Icon</div>
                    <div class="col-4 py-3 border-right border-bottom">Link</div>
                    <div class="col-2">
                        <div class="row h-100">
                            <div class="col-8 py-3 border-right border-bottom">
                                Action
                            </div>
                            <div class="col-4 py-3 border-right border-bottom">
                                Status
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="col-12 border-left" *ngFor="let item of socialList; index as i">
                <div class="row">
                    <div class="col-1 py-3 border-right border-bottom">{{i+1}}</div>
                    <div class="col-2 py-3 border-right border-bottom">{{item.social_media_country_name}}</div>
                    <div class="col-2 py-3 border-right border-bottom">{{item.social_media_name}}</div>
                    <div class="col-1 py-1 border-right border-bottom text-center">
                        <i class="listIcon" [class]="item.social_media_icon" aria-hidden="true"></i>
                    </div>
                    <div class="col-4 py-3 border-right border-bottom">
                        <a [href]="item.link">{{item.social_media_link}}</a>
                    </div>
                    <div class="col-2">
                        <div class="row h-100">
                            <div class="col-4 py-3 border-right border-bottom">
                                <button class="btn p-0" (click)="update(item)">
                                    <img src="../../../../assets/img/icons/Icon awesome-edit-1.png" height="25px"
                                        alt="">
                                </button>
                            </div>
                            <div class="col-4  py-3 border-right border-bottom">
                                <button class="btn p-0" (click)="delete(item)">
                                    <img src="../../../../assets/img/icons/Icon material-delete-red-forever.png"
                                        height="25px" alt="">
                                </button>
                            </div>
                            <div class="col-4 py-3 border-right border-bottom">
                                <button class="btn p-0">
                                    <img src="../../../../assets/img/icons/Icon awesome-eye.png" height="25px" alt="">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </section>

</div>
