import { Component, OnInit, ViewChild, Input, ElementRef, EventEmitter, Output } from '@angular/core';
import Cropper from "cropperjs";
@Component({
    selector: 'app-image-cropper',
    templateUrl: './image-cropper.component.html',
    styleUrls: ['./image-cropper.component.css']
})
export class ImageCropperComponent implements OnInit {

    @Output() greetEvent = new EventEmitter()

    @ViewChild("image", { static: false })
    public imageElement: ElementRef;

    @Input("src")
    public imageSource: string;

    public imageDestination: string;
    private cropper: Cropper;

    public constructor() {
        this.imageDestination = "";
    }

    cropImage(value){
        this.greetEvent.emit(value)
    }

    public ngAfterViewInit() {
        this.cropper = new Cropper(this.imageElement.nativeElement, {
            zoomable: false,
            scalable: false,
            aspectRatio: 0.67,
            minCropBoxWidth: 352,
            minCropBoxHeight: 528,
            crop: () => {
                const canvas = this.cropper.getCroppedCanvas();
                const formData = new FormData();
                canvas.toBlob(function (blob) {
                    formData.append('product_image', blob)
                })
                this.cropImage(formData)
            },
        });
    }

    public ngOnInit() { }

}
