import { Component } from '@angular/core';
import { ApiService } from './api.service';
import { PageDesignService } from './page-design.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'paramafashion';
  catagories: any;
  catagoryList = [];
  countries;
  bannerMenu;
  vendorList;
  productList;
  activeId = 2;

  constructor(private api:ApiService,private apiforpage:PageDesignService) { }
  ngOnInit(): void {
    this.apiforpage.activeTabParent$.subscribe(
      data => {
        this.activeId = data
        this.apiforpage.activeTabShare(this.activeId)
      }
    )
    this.api.get(`source_vendor_list`).subscribe(
      data => {
        this.vendorList = Object.values(data)[2]
        this.api.vendorList(this.vendorList)
      },
      error => console.log('Error!', error)
    )
    this.api.get(`product_list`).subscribe(
      data => {
        // this.productList = Object.values(data)[2]
        this.api.productListShare(Object.values(data)[2])
        // console.log(Object.values(data)[2])
      },
      error => console.log('Error!', error)
    )
    this.api.get(`brand_list`).subscribe(
      data =>{
        this.api.brandShare(Object.values(data)[2]);
      },
      error => console.log('Error!', error)
    )
    this.api.get(`selected_country_list`).subscribe(
      data =>{
        this.countries = Object.values(data)[2];
        this.api.countryShare(this.countries);
        Object.values(data)[2].map((res) => {
          this.api.post(`get_footer_cat_subcat`, { "category_country_id": res._id, "category_country_name": res.selected_country_name }).subscribe(
            data => {
              this.catagories = Object.values(data)[2]
              if (this.catagories.length > 0) {
                this.catagoryList.push({ "category_country_name": res.selected_country_name, "catagory": "", "catagories": this.catagories })
              }
            },
            error => console.log('Error!', error)
          )
        },
          // console.log(this.catagoryList),
          this.api.catagoryShare(this.catagoryList)
        )
      }, 
      error => console.log('Error!', error)
    )
  }
}
