import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import {TopNav} from './top-nav'
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.css'],
})

export class TopNavComponent implements OnInit {



  imgType = true;


  offerList = [
    {cuponOfferValue:'5',couponOfferTag: 'About Shop',description:'1234', country:'India', couponOfferCode: 'new30',
   ValidFrom: new Date(), ValidUpto: new Date(), OfferUseTime:0},
    {cuponOfferValue:'5', couponOfferTag: 'About Shop',description:'1234', country:'India', couponOfferCode: 'new30',
   ValidFrom: new Date(), ValidUpto: new Date(), OfferUseTime:0},
    {cuponOfferValue:'5', couponOfferTag: 'About Shop',description:'1234', country:'India', couponOfferCode: 'new30',
   ValidFrom: new Date(), ValidUpto: new Date(), OfferUseTime:0},
    {cuponOfferValue:'5', couponOfferTag: 'About Shop',description:'1234', country:'India', couponOfferCode: 'new30',
   ValidFrom: new Date(), ValidUpto: new Date(), OfferUseTime:0},
    {cuponOfferValue:'15', couponOfferTag: 'About Shop',description:'1234', country:'India', couponOfferCode: 'new30',
   ValidFrom: new Date(), ValidUpto: new Date(), OfferUseTime:0},
  ]


  catagories = [
    {catName: 'Women Ware',button:true, click:false, value:"", subcatagory: [
      {catName: 'Saree',button:true, click:false, subcatagory: [{
        name: 'cotton Saree', button: false,value:'',
      }]},
      {catName: 'Kurti',button:true, click:false, value:''},
      {catName: 'Dress',button:true, click:false, value:''},
    ]},
    {catName: 'men Ware',button:true, click:false, value:"", subcatagory: 
      [
        {catName: 'Shirt',button:true, click:false,subcatagory: [{
          name: 'cotton Shirt', button: false, value:'',
        }]},
        {catName: 'Jeans',button:true, click:false, value:''},
      ]
    },
    {catName: 'Child Ware',button:true, click:false, value:"", subcatagory: 
      [
        {catName: 'Top',button:true, click:false,subcatagory: [{
          name: 'cotton Top', button: false, value:''
        }]},
        {catName: 'skirt',button:true, click:false, value:''},
      ]
    },
  ]

  Showcat(val){
    this.catagories[val].click = !this.catagories[val].click;
  }
  Showsubcat(val,val2){
    this.catagories[val].subcatagory[val2].click = !this.catagories[val].subcatagory[val2].click;
  }



  NavbarList = [
    { img: '', navText: 'Women', link: 'https://youtube.com', country: 'India' },
    { img: '', navText: 'Men', link: 'https://youtube.com', country: 'India' },
    { img: '', navText: 'Sunslink', link: 'https://youtube.com', country: 'India' }
  ]


  delete(value,list){
    list.splice(list.indexOf(value),1);
  }

  onSubmit(form: NgForm, list) {
      console.log(form.value);
      list.push(form.value);
      form.reset();
  }

  upload(event) {
    if (event.value.includes(".png") || event.value.includes(".jpg")) {
      this.imgType = true;
    } else {
      this.imgType = false;
    }
  }


  topNavForm = new TopNav('', '', '');

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.NavbarList, event.previousIndex, event.currentIndex);
  }

  open(content,size) {
    this.modalService.open(content, { size: size,scrollable: true  });
  }

  constructor(private api:ApiService,private modalService: NgbModal) { }

  countries;
    ngOnInit(): void {
      this.api.get(`get_countries`).subscribe(
        data =>{
          this.countries = Object.values(data);
          this.api.countryShare(this.countries);
          console.log(this.countries)
        }, 
        error => console.log('Error!', error)
      )
    }

}