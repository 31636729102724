<section>
    <div class="container-fluid">
        <ul ngbNav #nav="ngbNav" class="nav-tabs offer">
            <li ngbNavItem>
                <a ngbNavLink>Offers</a>
                <ng-template ngbNavContent>
                    <h4 class="text-capitalize text-center">Offers</h4>
                    <div class="border border-success bg-white py-4 d-flex justify-content-center">
                        <div class="position-absolute title" style="right: 30px;">
                            <button class="btn p-0 rounded-0 edit">
                                <i class="fas fa-times-circle"></i>
                            </button>
                        </div>
                        <div class="w-50">
                            <form #offerForm="ngForm">
                                <div class="form-group productInfo">
                                    <label class="title">*Country</label>
                                    <select name="country" class="form-control" [(ngModel)]="offerInfo.country" required
                                        (change)="getCatagory(offerInfo.country)">
                                        <option value="{{country._id}}-{{country.selected_country_name}}"
                                            *ngFor="let country of countries">{{country.selected_country_name}}</option>
                                    </select>
                                </div>
                                <div class="form-group position-relative productInfo">
                                    <label class="title">*Offer & Coupon description</label>
                                    <input type="text" name="description" rows="3" class="form-control"
                                        [(ngModel)]="offerInfo.description" required>
                                </div>
                                <div class="form-group position-relative productInfo">
                                    <label class="title">*Offer & Coupon Tag</label>
                                    <input type="text" name="couponOfferTag" rows="3" class="form-control"
                                        [(ngModel)]="offerInfo.couponOfferTag" required>
                                </div>
                                <div class="form-row">
                                    <div class="form-group productInfo col-6">
                                        <label class="title">*Offer & Coupon Type</label>
                                        <select name="type" #type="ngModel" class="form-control"
                                            [(ngModel)]="offerInfo.couponType" required
                                            (change)="onSelect(offerInfo.couponType)">
                                            <option value="%off">% off</option>
                                            <option value="flatOff">Flat Rate Off</option>
                                        </select>
                                    </div>
                                    <div class="form-group productInfo col-6" *ngIf="!flat">
                                        <label class="title">*Offer or Coupon Value</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text border-danger rounded-0 bg-white">%</span>
                                            </div>
                                            <input type="text" class="form-control" name="cuponOfferValue"
                                                [(ngModel)]="offerInfo.cuponOfferValue">
    
                                        </div>
                                    </div>
                                    <div class="form-group productInfo col-6" *ngIf="flat">
                                        <label class="title">*Offer or Coupon Value</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text border-danger rounded-0 bg-white">Rs.</span>
                                            </div>
                                            <input type="text" class="form-control " name="cuponOfferValue"
                                                [(ngModel)]="offerInfo.cuponOfferValueFlat">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group position-relative productInfo col-6">
                                        <label class="title">*coupon & Offer Code</label>
                                        <input type="text" name="couponOfferCode" rows="3" class="form-control productInfo"
                                            [(ngModel)]="offerInfo.couponOfferCode" required>
                                    </div>
                                    <div class="form-group col-6">
                                        <label class="title">Offer And coupon uses Time</label>
                                        <div class="form-control d-flex justify-content-between usersTime">
                                            <div class="w-50">
                                                <input type="number" [(ngModel)]="offerInfo.OfferUseTime"
                                                    name="OfferUseTime" class="w-100 border-0 py-1"
                                                    *ngIf="!unlimited.checked">
                                            </div>
                                            <div class="w-50 py-1">
                                                <input type="checkbox" name="unlimited" #unlimited
                                                    [(ngModel)]="offerInfo.unlimited" class="mx-2">
                                                <label>Unlimited</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group position-relative col-md-6 productInfo">
                                        <label class="title">*Valid From</label>
                                        <input type="date" name="ValidFrom" class="form-control"
                                            [(ngModel)]="offerInfo.ValidFrom">
                                    </div>
                                    <div class="form-group position-relative col-md-6 productInfo">
                                        <label class="title">*Valid Upto</label>
                                        <input type="date" name="ValidUpto" class="form-control productInfo"
                                            [(ngModel)]="offerInfo.ValidUpto">
                                    </div>
                                </div>
                                <div ngbDropdown class="form-group d-inline-block w-100">
                                    <label class="title">Apply To</label>
                                    <button class="btn btn-outline-green form-control w-100 d-flex justify-content-between"
                                        type="button" ngbDropdownToggle>
                                        <!-- <div class="d-flex" *ngIf="offerInfo.apply_to.length > 0">
                                        <span *ngFor="let apply_on of offerInfo.apply_to">{{apply_on.apply_to_name}},</span>
                                    </div> -->
                                        <div class="d-flex">
                                            <span>{{offerInfo.apply_to_name}}</span>
                                            <span *ngIf="offerInfo.cart_value > 0">Cart Value More Than
                                                {{offerInfo.cart_value}}</span>
                                        </div>
                                    </button>
                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="width: 100%;">
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item border-0">
                                                customer
                                            </li>
                                            <li class="list-group-item border-0">
                                                <div class="d-inline-block w-100">
                                                    <button class="btn p-0">Cart
                                                        Value</button>
                                                    <div class="w-100 p-2 border"> Cart Value More Than
                                                        <input type="number" class="border-0" name="cart_value"
                                                            [(ngModel)]="offerInfo.cart_value"
                                                            (keyup)="catValue(offerInfo.cart_value)">
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="list-group-item border-0">
                                                <button class="btn p-0" (click)="open(content)">catagory</button>
                                            </li>
                                            <li class="list-group-item border-0">
                                                <button class="btn p-0" (click)="open(brandcontent)">brand</button>
                                            </li>
                                            <ng-template #content let-modal>
                                                <div class="modal-header border-0">
                                                    <div class="p-2 w-100" *ngIf="0 >= catagories.length">
                                                        first select a country and then select catagory
                                                    </div>
                                                    <div class="bg-success p-2 text-center w-100"
                                                        *ngIf="catagories.length > 0">
                                                        <a routerLink="/create-new-catagory" class="text-white"
                                                            routerLinkActive="active-link" target="_blank"> Create new
                                                            catagory</a>
                                                    </div>
                                                    <button type="button" class="close" aria-label="Close"
                                                        (click)="modal.dismiss('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body pt-0" *ngIf="catagories.length > 0">
                                                    <div>
                                                        <div class="input-group">
                                                            <div class="input-group p-2">
                                                                <div class="input-group-prepend" style="width: 15%;">
                                                                    <span
                                                                        class="input-group-text border-dark rounded-0 bg-transparent border-right-0 w-100">
                                                                        <i class="fas fa-search"></i></span>
                                                                </div>
                                                                <input type="text" class="search" ngModel
                                                                    name="searchCatagory" #searchCatagory>
                                                            </div>
                                                        </div>
                                                        <div class="">
                                                            <ul class="list-group list-group-flush">
                                                                <li class="list-group-item border-0"
                                                                    *ngFor="let item of catagories; index as i">
                                                                    <div class="d-flex">
                                                                        <label class="custom mb-0">{{item.cat_name}}
                                                                            <input type="radio" [value]="item.cat_id"
                                                                                (click)="onCat(item)" ngModel
                                                                                class="form-check-input m-2"
                                                                                name="catagory">
                                                                            <span class="checkmark"></span>
                                                                        </label>
                                                                        <button class="btn p-0 ml-4" type="button"
                                                                            *ngIf="item.child_cat"
                                                                            (click)="Showsubcat(item)">
                                                                            <i
                                                                                [class]="!item.click? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                        </button>
                                                                    </div>
                                                                    <ul class="list-group list-group-flush ml-4"
                                                                        *ngIf="item.click">
                                                                        <li class="list-group-item border-0"
                                                                            *ngFor="let val of item.child_cat; index as j">
                                                                            <div class="d-flex">
                                                                                <label class="custom mb-0">{{val.cat_name}}
                                                                                    <input type="radio" [value]="val.cat_id"
                                                                                        (click)="onCat(val,CatDrop,singleProductForm)"
                                                                                        ngModel class="form-check-input m-2"
                                                                                        name="catagory">
                                                                                    <span class="checkmark"></span>
                                                                                </label>
                                                                                <button class="btn p-0 ml-4" type="button"
                                                                                    *ngIf="val.child_cat.length > 0"
                                                                                    (click)="Showsubcat(val)">
                                                                                    <i
                                                                                        [class]="!val.click? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                                </button>
                                                                            </div>
                                                                            <ul class="list-group list-group-flush ml-4"
                                                                                *ngIf="val.click">
                                                                                <li class="list-group-item border-0"
                                                                                    *ngFor="let v of val.child_cat; index as m">
                                                                                    <div class="d-flex">
                                                                                        <label
                                                                                            class="custom mb-0">{{v.cat_name}}
                                                                                            <input type="radio"
                                                                                                [value]="v.cat_id"
                                                                                                (click)="onCat(v,CatDrop,singleProductForm)"
                                                                                                ngModel
                                                                                                class="form-check-input m-2"
                                                                                                name="catagory">
                                                                                            <span class="checkmark"></span>
                                                                                        </label>
                                                                                        <button class="btn p-0 ml-4"
                                                                                            type="button"
                                                                                            *ngIf="v.child_cat">
                                                                                            <i
                                                                                                [class]="v.child_cat? 'far fa-plus-square':'far fa-minus-square'"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template #brandcontent let-modal>
                                                <div class="modal-header border-0">
                                                    <div class="bg-success p-2 text-center w-100">
                                                        <a routerLink="/brands" class="text-white"
                                                            routerLinkActive="active-link" target="_blank"> Create new
                                                            Brand</a>
                                                    </div>
                                                    <button type="button" class="close" aria-label="Close"
                                                        (click)="modal.dismiss('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body pt-0">
                                                    <div>
                                                        <div class="input-group" *ngIf="brands.length > 0">
                                                            <div class="input-group p-2">
                                                                <div class="input-group-prepend" style="width: 15%;">
                                                                    <span
                                                                        class="input-group-text border-dark rounded-0 bg-transparent border-right-0 w-100">
                                                                        <i class="fas fa-search"></i></span>
                                                                </div>
                                                                <input type="text" class="search" ngModel
                                                                    name="searchCatagory" #searchCatagory>
                                                            </div>
                                                        </div>
                                                        <div class="">
                                                            <ul class="list-group list-group-flush">
                                                                <li class="list-group-item border-0"
                                                                    *ngFor="let brand of brands">
                                                                    <div class="d-flex">
                                                                        <label class="custom mb-0">{{brand.brand_name}}
                                                                            <input type="radio" [value]="brand._id"
                                                                                (click)="onBrand(brand)" ngModel
                                                                                class="form-check-input m-2" name="brand">
                                                                            <span class="checkmark"></span>
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ul>
                                    </div>
                                </div>
                                <div class="form-group productInfo">
                                    <label class="title">Terms And Conditions</label>
                                    <textarea name="Terms_Conditions" rows="3" class="form-control"
                                        [(ngModel)]="offerInfo.Terms_Conditions" required></textarea>
                                </div>
                                <div class="col-12 text-center">
                                    <button type="submit" (click)="onSubmit(offerForm)" class="btn btn-success rounded-0"
                                        [disabled]="offerForm.form.invalid">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>Coupon</a>
                <ng-template ngbNavContent>
                    <h4 class="text-capitalize text-center">Coupon</h4>
                <div class="border border-success bg-white py-4 d-flex justify-content-center">
                    <div class="position-absolute title" style="right: 30px;">
                        <button class="btn p-0 rounded-0 edit">
                            <i class="fas fa-times-circle"></i>
                        </button>
                    </div>
                    <div class="w-50">
                        <form #offerForm="ngForm">
                            <div class="form-group productInfo">
                                <label class="title">*Country</label>
                                <select name="country" class="form-control" [(ngModel)]="offerInfo.country" required
                                    (change)="getCatagory(offerInfo.country)">
                                    <option value="{{country._id}}-{{country.selected_country_name}}"
                                        *ngFor="let country of countries">{{country.selected_country_name}}</option>
                                </select>
                            </div>
                            <div class="form-group position-relative productInfo">
                                <label class="title">*Offer description</label>
                                <input type="text" name="description" rows="3" class="form-control"
                                    [(ngModel)]="offerInfo.description" required>
                            </div>
                            <div class="form-group position-relative productInfo">
                                <label class="title">*Offer Tag</label>
                                <input type="text" name="couponOfferTag" rows="3" class="form-control"
                                    [(ngModel)]="offerInfo.couponOfferTag" required>
                            </div>
                            <div class="form-row">
                                <div class="form-group productInfo col-6">
                                    <label class="title">*Offer Type</label>
                                    <select name="type" #type="ngModel" class="form-control"
                                        [(ngModel)]="offerInfo.couponType" required
                                        (change)="onSelect(offerInfo.couponType)">
                                        <option value="%off">% off</option>
                                        <option value="flatOff">Flat Rate Off</option>
                                    </select>
                                </div>
                                <div class="form-group productInfo col-6" *ngIf="!flat">
                                    <label class="title">*Offer Value</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text border-danger rounded-0 bg-white">%</span>
                                        </div>
                                        <input type="text" class="form-control" name="cuponOfferValue"
                                            [(ngModel)]="offerInfo.cuponOfferValue">
    
                                    </div>
                                </div>
                                <div class="form-group productInfo col-6" *ngIf="flat">
                                    <label class="title">*Offer or Coupon Value</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text border-danger rounded-0 bg-white">Rs.</span>
                                        </div>
                                        <input type="text" class="form-control " name="cuponOfferValue"
                                            [(ngModel)]="offerInfo.cuponOfferValueFlat">
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group position-relative productInfo col-6">
                                    <label class="title">*coupon & Offer Code</label>
                                    <input type="text" name="couponOfferCode" rows="3" class="form-control productInfo"
                                        [(ngModel)]="offerInfo.couponOfferCode" required>
                                </div>
                                <div class="form-group col-6">
                                    <label class="title">Offer And coupon uses Time</label>
                                    <div class="form-control d-flex justify-content-between usersTime">
                                        <div class="w-50">
                                            <input type="number" [(ngModel)]="offerInfo.OfferUseTime" name="OfferUseTime"
                                                class="w-100 border-0 py-1" *ngIf="!unlimited.checked">
                                        </div>
                                        <div class="w-50 py-1">
                                            <input type="checkbox" name="unlimited" #unlimited
                                                [(ngModel)]="offerInfo.unlimited" class="mx-2">
                                            <label>Unlimited</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group position-relative col-md-6 productInfo">
                                    <label class="title">*Valid From</label>
                                    <input type="date" name="ValidFrom" class="form-control"
                                        [(ngModel)]="offerInfo.ValidFrom">
                                </div>
                                <div class="form-group position-relative col-md-6 productInfo">
                                    <label class="title">*Valid Upto</label>
                                    <input type="date" name="ValidUpto" class="form-control productInfo"
                                        [(ngModel)]="offerInfo.ValidUpto">
                                </div>
                            </div>
                            <div class="form-group productInfo border border-dark px-4"> Cart Value More Than
                                <input type="number" class="border-0" name="cart_value"
                                    [(ngModel)]="offerInfo.cart_value">
                            </div>
                            <div class="form-group productInfo border border-dark px-4"> Cart Value More Than
                                <input type="number" class="border-0" name="cart_value"
                                    [(ngModel)]="offerInfo.cart_value"
                                    (keyup)="catValue(offerInfo.cart_value)">
                            </div>
                            <div class="form-group productInfo">
                                <label class="title">Terms And Conditions</label>
                                <textarea name="Terms_Conditions" rows="3" class="form-control"
                                    [(ngModel)]="offerInfo.Terms_Conditions" required></textarea>
                            </div>
                            <div class="col-12 text-center">
                                <button type="submit" (click)="onSubmit(offerForm)" class="btn btn-success rounded-0"
                                    [disabled]="offerForm.form.invalid">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
                </ng-template>
            </li>
        </ul>
    </div>
    <div class="upper">
        <div class="container-fluid">
            <div [ngbNavOutlet]="nav"></div>
        </div>
    </div>
    <div class="container-fluid  my-4">
        <h4 class="text-capitalize text-center">Offer List</h4>
        <ul class="list-group p-0 border-top rounded-0">
            <li class="col-12 border-left font-weight-bold">
                <div class="row">
                    <div class="col-8">
                        <div class="row h-100">
                            <div class="col-6  border-bottom">
                                <div class="row h-100">
                                    <div class="col-1 py-3 border-right px-0 text-center">Sl.No</div>
                                    <div class="col-5 py-3 border-right">Description</div>
                                    <div class="col-4 py-3 border-right">Offer Tag</div>
                                    <div class="col-2 py-3 border-right">Value</div>
                                </div>
                            </div>
                            <div class="col-6  border-bottom">
                                <div class="row h-100">
                                    <div class="col-3 py-3 border-right">UseTime</div>
                                    <div class="col-4 py-3 border-right ">Applied On</div>
                                    <div class="col-5 py-3 border-right">Tems & Conditions</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 border-bottom">
                        <div class="row h-100">
                            <div class="col-2 py-3 border-right">Coupon Code</div>
                            <div class="col-3 py-3 border-right">Valid Form</div>
                            <div class="col-3 py-3 border-right">Valid Upto</div>
                            <div class="col-4">
                                <div class="row h-100">
                                    <div class="col-8 py-3 border-right">
                                        Action
                                    </div>
                                    <div class="col-4 px-0 py-3 border-right text-center">
                                        Status
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="col-12 border-left" *ngFor="let item of offerList; index as i">
                <div class="row">
                    <div class="col-8">
                        <div class="row h-100">
                            <div class="col-6  border-bottom">
                                <div class="row h-100">
                                    <div class="col-1 py-3 border-right">{{i+1}}</div>
                                    <div class="col-5 py-3 border-right">{{item.cart_offer_description}}
                                    </div>
                                    <div class="col-4 py-3 border-right">{{item.cart_offer_tag}}</div>
                                    <div class="col-2 py-3 border-right">{{item.cart_offer_value}}</div>
                                </div>
                            </div>
                            <div class="col-6  border-bottom">
                                <div class="row h-100">
                                    <div class="col-3 py-3 border-right">
                                        {{item.cart_offer_uses_time}}</div>
                                    <div class="col-4 py-3 border-right d-flex flex-wrap">
                                        <span *ngIf="item.cart_value === '0' ">{{item.apply_to_name}}</span>
                                        <span *ngIf="item.cart_value !== '0' ">Cart Value More Than
                                            {{item.cart_value}}</span>
                                    </div>
                                    <div class="col-5 py-3 border-right">{{item.cart_offer_terms_and_conditions}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4  border-bottom">
                        <div class="row h-100">
                            <div class="col-2 py-3 border-right">{{item.cart_offer_code}}</div>
                            <div class="col-3 py-3 border-right">{{item.cart_offer_valid_from}}</div>
                            <div class="col-3 py-3 border-right">{{item.cart_offer_valid_upto}}</div>
                            <div class="col-4">
                                <div class="row h-100">
                                    <div class="col-4 py-3 border-right">
                                        <button class="btn p-0 edit" (click)="updateCoupon(item)">
                                            <i class="fas fa-edit"></i>
                                        </button>
                                    </div>
                                    <div class="col-4 py-3 border-right">
                                        <button class="btn p-0 delete" (click)="delete(item)">
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                    </div>
                                    <div class="col-4 py-3 border-right">
                                        <button class="btn p-0 view">
                                            <i class="fas fa-eye"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</section>