export class FetureClass {
    constructor(
        public country : string,
        public featuredName : string,
        public featuredTag : string,
        public img : any,
        public apply_to : String,
        public apply_id :String,
        public _id: String,
    ){}
}
