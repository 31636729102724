import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ApiService } from 'src/app/api.service';
import { PagesInfo } from './pages-info'
// import {CustomPipe} from '../../admincomponent/pipe'
@Component({
  selector: 'app-pages-info',
  templateUrl: './pages-info.component.html',
  styleUrls: ['./pages-info.component.css'],
})
export class PagesInfoComponent implements OnInit {

  @ViewChild("userHtml", { static: false }) userHtml;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '200px',
    minHeight: '200px',
    maxHeight: '200px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'no',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

  pages = [];

  pagesInfo = new PagesInfo('','', '', '');
  loader: boolean = true

  delete(value) {
    this.api.post(`delete_page`, {"_id":value._id}).subscribe(
      data => {
        if (data.status === 'success') {
          window.location.reload()
        }
      },
      error => console.log(error)
    )
  }

  update(value) {
    let array = []
    array.push(value.country_id)
    array.push(value.page_country_name)
    this.pagesInfo._id = value._id
    this.pagesInfo.country = array.join('-')
    this.pagesInfo.pageName = value.page_name
    this.pagesInfo.description = value.page_description
    this.pages.splice(this.pages.indexOf(value), 1);
  }


  // bannerMenu;

  // getData(){
  //   this.api.bannerSource$.subscribe(
  //     message => {
  //       // console.log(message)
  //       this.bannerMenu = message;
  //     }
  //   )
  // }

  onSubmit(form: NgForm) {
    let country = form.value.country.split('-');
    let body = {
      "_id": this.pagesInfo._id,
      "country_id": country[0],
      "page_country_name": country[1],
      "page_name": form.value.pageName,
      "page_description": form.value.description,
    }
    if (this.pagesInfo._id) {
      this.api.put(`update_page`, body).subscribe(
        data => {
          console.log(data)
          if (data.status === 'success') {
            window.location.reload()
          }
        },
        error => console.log(error)
      )
    } else {
      this.api.post(`add_page`, body).subscribe(
        data => {
          if (data.status === 'success') {
            window.location.reload()
          }
        },
        error => console.log(error)
      )
    }
  }

  view(body){
    console.log({_id: body._id,status: !body.status})
    this.api.put(`update_page`, {_id: body._id,status: !body.status}).subscribe(
      data => {
        console.log(data)
        if (data.status === 'success') {
          window.location.reload()
        }
      },
      error => console.log(error)
    )
  }


  constructor(private api: ApiService) {
  }
  countries;
  ngOnInit(): void {
    this.api.get(`selected_country_list`).subscribe(
      data => {
        this.countries = Object.values(data)[2]
        // this.api.countryShare(this.countries);
      },
      error => console.log('Error!', error)
    )
    this.api.get(`get_page_list`).subscribe(
      data => {
        console.log('Success!', data);
        this.loader =  false
        this.pages = Object.values(data)[2];
      },
      error => console.log('Error!', error)
    )
  }
  //   this.api.countrySource$.subscribe(
  //     message => {
  //       this.countries = message;
  //     }
  //   )
}
