<section>
    <div class="upper">
        <h4 class=" text-center">Top Navbar Setting</h4>
        <div class="container-fluid">
            <div class="border border-success bg-white  py-4">
                <div class="position-absolute title" style="right: 30px;">
                    <button class="btn p-0 rounded-0">
                        <img src="../../../assets/img/icons/Icon material-cancel.png" alt="" class="w-100">
                    </button>
                </div>
                <div class="container">
                    <form (ngSubmit)="onSubmit(NavbarForm,NavbarList)" #NavbarForm="ngForm"
                        class="d-flex justify-content-center">
                        <div class="col-8">
                            <div class="form-group productInfo">
                                <label class="title">*Country</label>
                                <select name="country" class="form-control" [(ngModel)]="topNavForm.country" required>
                                    <option value="{{country.name}}" *ngFor="let country of countries">
                                        {{country.name}}</option>
                                </select>
                            </div>
                            <div class="form-group productInfo">
                                <label class="position-absolute title">NavBar Image And Text</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" name="navText"
                                        [(ngModel)]="topNavForm.navText">
                                    <div class="input-group-append">
                                        <span class="input-group-text border-dark rounded-0 bg-white">
                                            <label class="mb-0 px-2 bg-success d-inline-flex text-white">Upload
                                                Images
                                                <input type="file" size="60" style="display: none;"></label>
                                        </span>
                                        <span class="input-group-text border-dark rounded-0 bg-white">
                                            <img src="../../../../assets/img/icons/Icon awesome-edit-1.png"
                                                height="24px" alt="">
                                        </span>
                                    </div>
                                    <!-- <ejs-richtexteditor #fromRTE [toolbarSettings]='tools' #navText='ngModel'
                                        [(value)]='topNavForm.navText' required name="navText"
                                        [(ngModel)]="topNavForm.navText" (created)="rteCreated()">
                                    </ejs-richtexteditor> -->
                                </div>
                            </div>
                            <div ngbDropdown class="form-group w-100 d-inline-block">
                                <label class="title">Apply To</label>
                                <button class="btn btn-outline-green form-control w-100 d-flex justify-content-between"
                                    type="button" id="dropdownBasic1" ngbDropdownToggle>
                                    Apply On</button>
                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="width: 100%;">
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item border-0">
                                            New Page
                                        </li>
                                        <li class="list-group-item border-0">
                                            Product Tag
                                        </li>
                                        <li class="list-group-item border-0">
                                            <button class="btn p-0" (click)="open(content,'md')">catagory
                                                <span class="material-icons">
                                                    arrow_drop_down
                                                </span></button>
                                        </li>
                                        <ng-template #content let-modal>
                                            <div class="modal-header border-0">
                                                <input type="text" class="form-control" name="search">
                                                <button type="button" class="close" aria-label="Close"
                                                    (click)="modal.dismiss('Cross click')">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="">
                                                    <ul class="list-group list-group-flush">
                                                        <li class="list-group-item border-0"
                                                            *ngFor="let item of catagories; index as i">
                                                            <div class="d-flex">
                                                                <label class="contain" *ngIf="item.button">
                                                                    <input type="radio" class="shorter">
                                                                    <span class="checkmark"></span>
                                                                </label>
                                                                <button class="btn p-0 ml-4" *ngIf="item.button"
                                                                    type="button" (click)="Showcat(i)">
                                                                    <img [src]="item.button? '../../../assets/img/icons/Icon awesome-plus-square.png':'../../../assets/img/icons/Icon feather-minus-square.png'"
                                                                        height="15px" alt="">
                                                                </button>
                                                                <label class="form-check-label mx-2"
                                                                    for="">{{item.catName}}</label>
                                                            </div>
                                                            <ul class="list-group list-group-flush ml-4"
                                                                *ngIf="item.click">
                                                                <li class="list-group-item border-0"
                                                                    *ngFor="let val of item.subcatagory; index as j">
                                                                    <div class="d-flex">
                                                                        <label class="contain">
                                                                            <input type="radio" class="shorter">
                                                                            <span class="checkmark"></span>
                                                                        </label>
                                                                        <button class="btn p-0 ml-4" type="button"
                                                                            (click)="Showsubcat(i,j)">
                                                                            <img [src]="val.button? '../../../assets/img/icons/Icon awesome-plus-square.png':'../../../assets/img/icons/Icon feather-minus-square.png'"
                                                                                height="15px" alt="">
                                                                        </button>
                                                                        <label class="form-check-label mx-2"
                                                                            for="">{{val.catName}}</label>
                                                                    </div>
                                                                    <ul class="list-group list-group-flush ml-4"
                                                                        *ngIf="val.click">
                                                                        <li class="list-group-item border-0"
                                                                            *ngFor="let v of val.subcatagory; index as m">
                                                                            <div class="d-flex">
                                                                                <label class="contain"
                                                                                    *ngIf="item.button">
                                                                                    <input type="radio" class="shorter">
                                                                                    <span class="checkmark"></span>
                                                                                </label>
                                                                                <button class="btn p-0 ml-4"
                                                                                    type="button">
                                                                                    <img [src]="v.button? '../../../assets/img/icons/Icon awesome-plus-square.png':'../../../assets/img/icons/Icon feather-minus-square.png'"
                                                                                        height="15px" alt="">
                                                                                </button>
                                                                                <label class="form-check-label mx-2"
                                                                                    for="">{{v.name}}</label>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <li class="list-group-item border-0">
                                            <button class="btn p-0" (click)="open(brand,'xl')">Brand
                                                <span class="material-icons">
                                                    arrow_drop_down
                                                </span></button>
                                        </li>
                                        <ng-template #brand let-modal>
                                            <div class="modal-header border-0">
                                                <h4 class="text-capitalize text-center">Offer List</h4>
                                                <button type="button" class="close" aria-label="Close"
                                                    (click)="modal.dismiss('Cross click')">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="">
                                                    <ul class="list-group p-0 rounded-0">
                                                        <li class="col-12 font-weight-bold">
                                                            <div class="row">
                                                                <div class="col-7">
                                                                    <div class="row h-100">
                                                                        <div class="col-1"></div>
                                                                        <div class="col-1 py-3 px-0 border">
                                                                            Sl.No</div>
                                                                        <div
                                                                            class="col-2 py-3 border-right  border-top border-bottom">
                                                                            Description</div>
                                                                        <div
                                                                            class="col-4 py-3 border-right  border-top border-bottom">
                                                                            Offer Tag</div>
                                                                        <div
                                                                            class="col-1 px-0 py-3 text-center border-right  border-top border-bottom">
                                                                            Value</div>
                                                                        <div
                                                                            class="col-1 py-3 px-0 text-center border-right border-bottom  border-top">
                                                                            Count</div>
                                                                        <div
                                                                            class="col-2 py-3 border-right border-bottom  border-top">
                                                                            Applied On</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-5  border-top">
                                                                    <div class="row h-100">
                                                                        <div
                                                                            class="col-2 py-3 border-right border-bottom">
                                                                            coupon</div>
                                                                        <div
                                                                            class="col-3 py-3 border-right border-bottom">
                                                                            valid From</div>
                                                                        <div
                                                                            class="col-3 py-3 border-right border-bottom">
                                                                            valid Upto
                                                                        </div>
                                                                        <div class="col-4">
                                                                            <div class="row h-100">
                                                                                <div
                                                                                    class="col-8 py-3 border-right border-bottom">
                                                                                    Action
                                                                                </div>
                                                                                <div
                                                                                    class="col-4 py-3 px-0 text-center border-right border-bottom">
                                                                                    Status
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li class="col-12" *ngFor="let item of offerList; index as i">
                                                            <div class="row">
                                                                <div class="col-7">
                                                                    <div class="row h-100">
                                                                        <div class="col-1 py-3 border-right">
                                                                            <label class="contain">
                                                                                <input type="radio" class="shorter">
                                                                                <span class="checkmark"></span>
                                                                            </label>
                                                                        </div>
                                                                        <div
                                                                            class="col-1 py-3 border-right border-bottom">
                                                                            {{i+1}}</div>
                                                                        <div
                                                                            class="col-2 py-3 border-right border-bottom">
                                                                            {{item.description}}</div>
                                                                        <div
                                                                            class="col-4 py-3 border-right border-bottom">
                                                                            {{item.couponOfferTag}}</div>
                                                                        <div class="col-1 py-3 border-right border-bottom"
                                                                            *ngIf="item.cuponOfferValue">
                                                                            {{item.cuponOfferValue}}</div>
                                                                        <div class="col-1 py-3 border-right border-bottom"
                                                                            *ngIf="item.cuponOfferValueFlat">
                                                                            {{item.cuponOfferValueFlat}}</div>
                                                                        <div
                                                                            class="col-1 py-3 border-right border-bottom">
                                                                            {{item.OfferUseTime}}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-5">
                                                                    <div class="row h-100">
                                                                        <div
                                                                            class="col-2 py-3 border-right border-bottom">
                                                                            {{item.couponOfferCode}}</div>
                                                                        <div
                                                                            class="col-3 py-3 border-right border-bottom">
                                                                            {{item.ValidFrom| date}}</div>
                                                                        <div
                                                                            class="col-3 py-3 border-right border-bottom">
                                                                            {{item.ValidUpto| date}}</div>
                                                                        <div class="col-4">
                                                                            <div class="row h-100">
                                                                                <div
                                                                                    class="col-4 py-3 border-right border-bottom">
                                                                                    <button class="btn p-0"
                                                                                        (click)="updateCoupon(item)">
                                                                                        <img src="../../../../assets/img/icons/Icon awesome-edit-1.png"
                                                                                            height="25px" alt="">
                                                                                    </button>
                                                                                </div>
                                                                                <div
                                                                                    class="col-4 py-3 border-right border-bottom">
                                                                                    <button class="btn p-0"
                                                                                        (click)="delete(item,offerList)">
                                                                                        <img src="../../../../assets/img/icons/Icon material-delete-red-forever.png"
                                                                                            height="25px" alt="">
                                                                                    </button>
                                                                                </div>
                                                                                <div
                                                                                    class="col-4 py-3 border-right border-bottom">
                                                                                    <button class="btn p-0">
                                                                                        <img src="../../../../assets/img/icons/Icon awesome-eye.png"
                                                                                            height="25px" alt="">
                                                                                    </button>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ul>
                                </div>
                            </div>
                            <div class="text-center">
                                <button type="submit" class="btn btn-success rounded-0"
                                    [disabled]="NavbarForm.form.invalid">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div>
            <ul cdkDropList class="list-group p-0 example-list" (cdkDropListDropped)="drop($event)">
                <li class="example-box" *ngFor="let item of NavbarList; index as i" cdkDrag>
                    <div class="d-flex w-100">
                        <svg width="24px" class="example-handle" fill="currentColor" viewBox="0 0 24 24" cdkDragHandle>
                            <path
                                d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                            </path>
                            <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>
                        <div class="my-auto d-flex justify-content-between" style="width:10%;">
                            <p class="m-auto">Nav Item</p>
                            <p class="m-auto"> {{i+1}}</p>
                        </div>
                        <div class="list-group-item w-100">
                            <div class="row">
                                <div class="col-1 m-auto d-flex justify-content-around">
                                    <button class="btn p-0">
                                        <img src="../../../../assets/img/icons/Icon awesome-edit-1.png" height="25px"
                                            alt="">
                                    </button>
                                </div>
                                <div class="col-3 my-auto">
                                    <p class="m-auto">{{item.navText}}</p>
                                </div>
                                <div class="col-3 my-auto">
                                    <p class="m-auto">{{item.link}}</p>
                                </div>
                                <div class="col-3 m-auto d-flex justify-content-around">
                                    <button class="btn p-0">
                                        <img src="../../../../assets/img/icons/Icon awesome-edit-1.png" height="25px"
                                            alt="">
                                    </button>
                                    <button class="btn p-0">
                                        <img src="../../../../assets/img/icons/Icon material-delete-red-forever.png"
                                            height="25px" alt="">
                                    </button>
                                    <button class="btn p-0">
                                        <img src="../../../../assets/img/icons/Icon awesome-eye.png" height="25px"
                                            alt="">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</section>