export class Inventory {
    constructor(
        public product_out_of_stock: String,
        public option_out_of_stock: String,
        public update_stock_level: String,
        public Adjust_stock_levels_when_editing_an_order: boolean,
        public Adjust_stock_levels_when_I_refound_or_cancel_an_order : boolean,
        public Stock_Level_Display : String,
        public Out_of_Stock_Message: String,
        public Show_Out_of_stock_message_on_product_listing_pages :boolean,
        public Low_stock_level_reached : String,
        public Out_of_Stock_reached : String     
    ){}
}
