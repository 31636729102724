export class Catagory {
    constructor(
        public _id : string,
        public category_name: string,
        public catagory_set_primary : string,
        public category_image : any,
        public main_category_id : string,
        public main_sub_category_id : string
    ){}
}
