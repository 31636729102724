<section class="upper">
    <div>
        <div class="heading text-center">
            <h4 class="text-capitalize">YouTube channel setting</h4>
        </div>
    </div>
    <div class="container-fluid">
        <div class="border border-success bg-white  py-4">
            <div class="position-absolute title" style="right: 30px;">
                <button class="btn clean p-0 rounded-0">
                    <i class="fas fa-times-circle"></i>
                </button>
            </div>
            <div class="container">
                <form (ngSubmit)="onSubmit(channelForm,img)" #channelForm="ngForm">
                    <div class="row">
                        <div class="col-8">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group ">
                                        <label class="title">Country</label>
                                        <!-- <input type="text" class="form-control" name="country" ngModel required> -->
                                        <select name="country" class="form-control" [(ngModel)]="youtubeChannelSettings.country"
                                            required>
                                            <option value="{{country._id}}-{{country.selected_country_name}}"
                                                *ngFor="let country of countries">{{country.selected_country_name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group ">
                                        <label class="title">Channel Name</label>
                                        <input type="text" class="form-control" name="channelName" 
                                        [(ngModel)]="youtubeChannelSettings.channelName" required>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group productInfo w-100">
                                        <label class="title"> YouTube Channel Link</label>
                                        <input type="text" class="form-control w-100" name="youtubeLink" [(ngModel)]="youtubeChannelSettings.youtubeLink"
                                            required>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="border border-success h-100 position-relative">
                                <img [src]="img.value" alt="productname">
                                <label class="position-absolute btn btn-success px-1 py-0 m-2"
                                    style="right: 0; font-size: 12px; display:inline-flex;">Add Banner
                                    <input type="file" size="60" style="display: none;" name="img" required ngModel
                                        #img="ngModel"></label>
                            </div>
                            <small class="text-danger" *ngIf="!imgType">img should be .jpg or .png</small>
                        </div>
                        <div class="col-12 text-center">
                            <button type="submit" class="btn btn-success rounded-0"
                                [disabled]="channelForm.form.invalid || img.invalid">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<section class="container-fluid">
    <div>
        <ul class="list-group p-0">
            <li *ngFor="let item of channelMenu; index as i">
                <div class="d-flex">
                    <div class="list-group-item m-4 w-100">
                        <div class="row">
                            <div class="col-7 text-center my-auto">
                                <p class="m-auto">{{item.channelName}}</p>
                                <p class="m-auto">{{item.youtubeLink}}</p>
                                <button type="button" class="btn btn-success my-2 rounded-0" (click)="AddVideo(i)">
                                    ADD VIDEO
                                </button>
                                <div class="youtube-video-selection border container-fluid" *ngIf="item.AddVideo">
                                    <div class="title m-0 mt-1" style="right: 0">
                                        <button class="btn p-0 rounded-0" (click)="close(i)">
                                            <img src="../../../assets/img/icons/Icon material-cancel.png" alt="" class="w-100">
                                        </button>
                                    </div>
                                    <form (ngSubmit)="submit(youtubeVideoForm,i)" #youtubeVideoForm="ngForm">
                                        <div class="row mt-4 mb-3">
                                            <div class="col-12">
                                                <div class="my-3">
                                                    <label class="title left-0">Caption</label>
                                                    <input type="text" name="caption" ngModel required
                                                        class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="my-3">
                                                    <label class="title left-0"> YouTube Channel Link</label>
                                                    <input type="text" name="youtubeLink" ngModel required
                                                        class="form-control" name="youtubeLink" required>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <button type="submit" class="btn btn-success rounded-0"
                                                    [disabled]="youtubeVideoForm.form.invalid">
                                                    SAVE
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="border border-success h-100">
                                    <img src="../../../../assets/img/icons/asset-1.png" alt="img" height="150px"
                                        width="100%">
                                </div>
                            </div>
                            <div class="col-2 m-auto d-flex justify-content-around">
                                <button class="btn p-0 edit">
                                    <i class="fas fa-edit"></i>
                                </button>
                                <button class="btn p-0 view">
                                    <i class="fas fa-eye"></i>
                                </button>
                                <button class="btn p-0 delete">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                            </div>
                            <div class="col-12 d-flex flex-wrap">
                                <div *ngFor="let val of item.videoList">
                                    <div class="card m-2 border-0" style="width: 10rem;">
                                        <iframe class="card-img-top" src="https://www.youtube.com/embed/bHcQx4hCF_0"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen></iframe>
                                        <div class="card-body p-0 d-flex">
                                            <button class="btn p-0 delete">
                                                <i class="fas fa-trash-alt"></i>
                                            </button>
                                            <div class="flex-fill my-auto">
                                                <p class="card-text text-center">{{val.caption}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</section>