<h4>Shipping Manager</h4>

<div class="shipping_options">
    <header class="d-flex justify-content-between form-group">
        <h5>Default Shipping Rules</h5>
        <div ngbDropdown class="d-inline-block">
            <button class="btn btn-outline-success" ngbDropdownToggle>Add Shipping Zone</button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem class="border-bottom" (click)="open(country)" *ngIf='!totalWorld'>Add a Country
                    Zone</button>
                <button ngbDropdownItem class="border-bottom" (click)="open(custom)" *ngIf='!totalWorld'>Add a Custom
                    Zone</button>
                <!-- <button ngbDropdownItem (click)="_restOfWorld()">Add the rest of the
                    World</button> -->
            </div>
            <ng-template #country let-modal>
                <div class="modal-header border-0">
                    <h4 class="modal-title" id="modal-basic-title">Add a Country Zone</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label class="mr-sm-2">Country</label>
                        <select class="custom-select mr-sm-2" [(ngModel)]="shipping.shipping_country_name"
                            name="shipping_country_name">
                            <option [value]="country.name" *ngFor="let country of selectedCountries">
                                {{country.name}}</option>
                        </select>
                    </div>
                    <div class="shipping_options p-3 my-4 border-left border-success"
                        style="background-color: #e2fedb;">
                        This will add a single country, as a valid shipping destination. For more complex zones
                        like multiple countries, states or postcodes set up a <span class="custom_zone"
                            (click)="open(custom); modal.close('Save click')">custom
                            zone.</span>
                    </div>
                    <ngb-alert *ngIf="response.status" [type]="response.status" class="submit"
                        (closed)="response.status = ''">
                        {{response.message}}
                    </ngb-alert>
                </div>
                <div class="modal-footer border-0">
                    <button type="button" class="btn btn-outline-dark"
                        (click)="modal.close('Save click')">Cancel</button>
                    <button type="button" class="btn btn-outline-dark"
                        (click)="_handleSubmit(shipping.shipping_country_name)">Submit</button>
                </div>
            </ng-template>
            <ng-template class="custom_selection" #custom let-modal>
                <div class="modal-header border-0">
                    <h4 class="modal-title" id="modal-basic-title">Add a Custom Zone</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <nav ngbNav #nav="ngbNav" class="nav-tabs">
                        <ng-container ngbNavItem>
                            <a ngbNavLink>Zone</a>
                            <ng-template ngbNavContent>
                                <form>
                                    <div class="form-group">
                                        <label>Name</label>
                                        <input type="text" class="form-control" [(ngModel)]="shipping.shipping_name"
                                            name="shipping_name">
                                    </div>
                                    <div class="form-group">
                                        <label class="mr-sm-2">Type</label>
                                        <select class="custom-select mr-sm-2" [(ngModel)]="shipping.type" name="type">
                                            <option value="Selection of Countries">Selection of Countries</option>
                                            <option value="Selection of States or Provinces">Selection of States or
                                                Provinces</option>
                                            <option value="Advance Selection">Advance Selection</option>
                                        </select>
                                    </div>
                                    <div class="form-group" *ngIf="shipping.type === 'Selection of Countries'">
                                        <label class="mr-sm-2">Country</label>
                                        <div ngbDropdown class="">
                                            <button
                                                class="btn form-control h-auto border d-flex justify-content-between"
                                                id="dropdownBasic1" ngbDropdownToggle style="min-height: 35px;">
                                                <div class="d-flex flex-wrap">
                                                    <ngb-alert class="value" type="secondary"
                                                        (click)="close(country,countryZone,i,selectedCountries)"
                                                        *ngFor="let country of countryZone; index as i">{{country.name}}
                                                    </ngb-alert>
                                                </div>
                                            </button>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                                style="height: 350px; overflow-y: scroll;">
                                                <button ngbDropdownItem *ngFor="let country of selectedCountries"
                                                    (click)="_handleZone(country,selectedCountries,countryZone)">{{country.name}}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group" *ngIf="shipping.type !== 'Selection of Countries'">
                                        <label class="mr-sm-2">Country</label>
                                        <select class="custom-select mr-sm-2"
                                            [(ngModel)]="shipping.shipping_country_name" name="shipping_country_name"
                                            (ngModelChange)="_getState(shipping.shipping_country_name)">
                                            <option [value]="country.name" *ngFor="let country of countries">
                                                {{country.name}}</option>
                                        </select>
                                    </div>
                                    <div class="form-group"
                                        *ngIf="shipping.type === 'Selection of States or Provinces'">
                                        <label class="mr-sm-2">State</label>
                                        <div ngbDropdown class="">
                                            <button
                                                class="btn form-control h-auto border d-flex justify-content-between"
                                                ngbDropdownToggle style="min-height: 35px;">
                                                <div class="d-flex flex-wrap">
                                                    <ngb-alert class="value" type="secondary"
                                                        (click)="close(state,shipping.shipping_state_name,s,stateZone)"
                                                        *ngFor="let state of shipping.shipping_state_name;index as s">
                                                        {{state.name}}</ngb-alert>
                                                </div>
                                            </button>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                                style="height: 350px; overflow-y: scroll;">
                                                <button ngbDropdownItem *ngFor="let state of stateZone"
                                                    (click)="_handleZone(state,stateZone,shipping.shipping_state_name)">{{state.name}}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group" *ngIf="shipping.type === 'Advance Selection'">
                                        <label>Multiple postcodes</label>
                                        <textarea class="form-control" rows="3" name="country_post_code"
                                            [(ngModel)]="shipping.country_post_code"></textarea>
                                    </div>
                                </form>
                            </ng-template>
                        </ng-container>
                        <ng-container ngbNavItem>
                            <a ngbNavLink>Handling fee</a>
                            <ng-template ngbNavContent>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="handling_fee"
                                        value="Do not apply a handling fee to this zone" checked>
                                    <label class="form-check-label">Do not apply a handling fee to this zone</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="handling_fee"
                                        value="Multiple states/provinces across countries">
                                    <label class="form-check-label">Multiple states/provinces across countries</label>
                                </div>
                                <div class="form-group row">
                                    <div class="col">
                                        <label class="mr-sm-2">Handling Fee Type</label>
                                        <select class="custom-select mr-sm-2">
                                            <option value="Fixed">Fixed</option>
                                            <option value="Percentage">Percentage</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <label class="mr-sm-2">Handling Fee<span class="fees_info" placement="right"
                                                ngbTooltip="Percentage of the shipping total part - application of the cart lavel discounts + coupon codes">
                                                <i class="fas fa-info-circle"></i>
                                            </span></label>
                                        <div class="input-group">
                                            <input type="text" class="form-control">
                                            <div class="input-group-append">
                                                <span class="input-group-text rounded-0">%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <label class="mr-sm-2">Handling Fee<span class="fees_info" placement="right"
                                                ngbTooltip="A flat fee of will be charged as your handeling fee in addition to your total shipping cost">
                                                <i class="fas fa-info-circle"></i>
                                            </span></label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text rounded-0">INR:</span>
                                            </div>
                                            <input type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="handling_fee"
                                        value="Multiple Postcodes">
                                    <label class="form-check-label">Multiple Postcodes</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox">
                                    <label class="form-check-label"> Show handling fee separately in checkout</label>
                                </div>
                            </ng-template>
                        </ng-container>
                    </nav>

                    <div [ngbNavOutlet]="nav" class="mt-2"></div>

                    <ngb-alert *ngIf="response.status" [type]="response.type" class="submit"
                        (closed)="response.status = ''">
                        {{response.message}}
                    </ngb-alert>

                </div>
                <div class="modal-footer border-0">
                    <button type="button" class="btn btn-outline-dark"
                        (click)="modal.close('Save click')">Cancel</button>
                    <button type="button" class="btn btn-outline-dark" (click)="_handleManySubmit()">Submit</button>
                </div>
            </ng-template>
        </div>
    </header>
    <table class="table">
        <tbody class="border">
            <!-- <tr>
                <th scope="row">
                    <i class="em em-flag-in"></i>
                </th>
                <td>India</td>
                <td>Free Shipping</td>
                <td class="d-flex justify-content-between">
                    <div class="custom-control custom-switch">
                        <label class="custom-control-label"></label>
                        <input type="checkbox" class="custom-control-input" name="SKU_generate" checked>
                    </div>
                    <button class="btn btn-outline-success">Edit</button>
                </td>
            </tr> -->
            <tr *ngFor="let ship of shippingList; index as i">
                <th scope="row">{{i+1}}</th>
                <td>
                    <div>
                        {{ship.shipping_name}}
                    </div>
                    <small class="d-flex flex-wrap">
                        <b>Country:</b>
                        {{ship.shipping_country_name}}
                        <span class="font-weight-bold" *ngIf="ship.shipping_state_name.length > 0">(State:
                            <span class="font-weight-normal" *ngFor="let state of ship.shipping_state_name">{{state.name}},</span>)</span>
                        <span class="font-weight-bold" *ngIf="ship.shipping_country_pin_code.length > 0">(PinCode:
                            <span class="font-weight-normal" *ngFor="let pin of ship.shipping_country_pin_code">{{pin.pin_code}},</span>)</span>
                    </small>
                </td>
                <!-- <td>{{ship.shipping_method}} <span
                        *ngIf="ship.shipping_method === 'Ship by'">{{ship.shipping_by_weight_or_order_total.charge_shipping}}</span>
                </td> -->
                <td class="d-flex justify-content-between">
                    <button class="btn btn-outline-success" (click)="edit(editContent)">Configure</button>
                    <ng-template #editContent let-modal>
                        <app-shipping-method [ship]="ship" [baseCountry]="baseCountry"></app-shipping-method>
                    </ng-template>
                    <label class="switch">
                        <input type="checkbox" name="status" [(ngModel)]="ship.status"
                            (ngModelChange)="update({ _id: ship._id, status: ship.status})">
                        <span class="slider"></span>
                    </label>
                </td>
                <td>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox"
                            [(ngModel)]="ship.shipping_cash_on_delivery_available"
                            name="shipping_cash_on_delivery_available" (click)="cod(ship,content)">
                        <label class="form-check-label">Cod Avaliable</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox"
                            [(ngModel)]="ship.shipping_is_enquiry_available"
                            name="shipping_is_enquiry_available" (ngModelChange)="update(ship)">
                        <label class="form-check-label">Shipping is enquiry available</label>
                    </div>
                    <ng-template #content let-modal>
                        <div class="modal-header">
                            <h4 class="modal-title" id="modal-basic-title">Cash On Delivary</h4>
                            <button type="button" class="close" aria-label="Close" (click)="modelClose(ship)">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form #codForm="ngForm">
                            <div class="modal-body">
                                <label class="switch">
                                    <input type="checkbox" name="cod"
                                        [(ngModel)]="!ship.shipping_cash_on_delivery_available">
                                    <span class="slider"></span>
                                </label>
                                <div class="form-group">
                                    <label>Cash On Delivary Terms Conditions</label>
                                    <angular-editor [placeholder]="'Enter text here...'" name="cod_terms_conditions"
                                        [(ngModel)]="ship.cod_terms_conditions"></angular-editor>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-outline-dark" (click)="modelClose(ship)">Close</button>
                                <button type="button" class="btn btn-success" (click)="_handelchange(codForm,ship)">Save</button>
                            </div>
                        </form>
                    </ng-template>
                </td>
                <td class="text-center">
                    <button class="btn p-0 delete" (click)="_handleDelete(ship)">
                        <i class="fas fa-trash-alt"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<ngb-alert *ngIf="response.status" [type]="response.status" class="restOfWorld" (closed)="response.status = ''">
    {{response.message}}
</ngb-alert>