<div class="container">
    <h1>New Vendor</h1>
    <form #vendorForm="ngForm">
        <div class="form-group row">
            <label for="staticEmail" class="col-sm-2 col-form-label">Primary Contact</label>
            <div class="col-sm-10">
                <div class="form-row">
                    <div class="col-md-4">
                        <select class="form-control" name="salutation" [(ngModel)]="addVendor.salutation">
                            <option>Salutation</option>
                            <option>Mr.</option>
                            <option>Mrs.</option>
                            <option>Ms.</option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <input type="text" class="form-control" placeholder="First Name" name="fname" [(ngModel)]="addVendor.fname">
                    </div>
                    <div class="col-md-4">
                        <input type="text" class="form-control" placeholder="Last Name" name="lname" [(ngModel)]="addVendor.lname">
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Company Name</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" name="company_name" [(ngModel)]="addVendor.company_name">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Vendor Dispaly Name</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" name="vendor_display_name" [(ngModel)]="addVendor.vendor_display_name">
            </div>
        </div>
        <div class="form-group row">
            <label for="staticEmail" class="col-sm-2 col-form-label">Email</label>
            <div class="col-sm-8">
                <input type="email" class="form-control" placeholder="email@example.com" name="vendor_email"
                    [(ngModel)]="addVendor.vendor_email">
            </div>
        </div>
        <div class="form-group row">
            <label for="inputPassword" class="col-sm-2 col-form-label">Vendor Phone</label>
            <div class="col-sm-8">
                <div class="form-row">
                    <div class="col-md-6">
                        <input type="tel" class="form-control" placeholder="Work Phone" name="vendor_work_phone"
                            [(ngModel)]="addVendor.vendor_work_phone" [pattern]="mobNumberPattern">
                    </div>
                    <div class="col-md-6">
                        <input type="tel" class="form-control" placeholder="Mobile" name="vendor_mobile_phone"
                            [(ngModel)]="addVendor.vendor_mobile_phone" [pattern]="mobNumberPattern">
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="staticEmail" class="col-sm-2 col-form-label">Skype & Whatsapp</label>
            <div class="col-sm-8">
                <div class="form-row">
                    <div class="col-md-6">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text" style="font-size: 24px; color:#00aff0;">
                                    <i class="fab fa-skype"></i>
                                </div>
                            </div>
                            <input type="text" class="form-control" name="vendor_skype_number_or_name"
                                [(ngModel)]="addVendor.vendor_skype_number_or_name">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text" style="font-size: 24px; color: #4FCE5D;">
                                    <i class="fab fa-whatsapp"></i>
                                </div>
                            </div>
                            <input type="text" class="form-control" name="vendor_whatsapp"
                                [(ngModel)]="addVendor.vendor_whatsapp">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="staticEmail" class="col-sm-2 col-form-label">Designation</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" name="vendor_designation"
                    [(ngModel)]="addVendor.vendor_designation">
            </div>
        </div>
        <div class="form-group row">
            <label for="staticEmail" class="col-sm-2 col-form-label">Department</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" name="vendor_department"
                    [(ngModel)]="addVendor.vendor_department">
            </div>
        </div>
        <div class="form-group row">
            <label for="staticEmail" class="col-sm-2 col-form-label">Website</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" name="vendor_website" [(ngModel)]="addVendor.vendor_website">
            </div>
        </div>
    </form>
    <button class="btn btn-success" (click)="submit(vendorForm)">Submit</button>
    <!-- <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs add-vendor">
        <li [ngbNavItem]="1">
            <a ngbNavLink>First</a>
            <ng-template ngbNavContent>First content</ng-template>
        </li>
        <li [ngbNavItem]="2">
            <a ngbNavLink>Second</a>
            <ng-template ngbNavContent>Second content</ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div> -->

</div>

<section class="container-fluid my-3">
    <table class="table table-bordered">
        <thead>
          <tr>
            <th >Sl<br>No.</th>
                <th>Primary Contact</th>
                <th>Vendor Department</th>
                <th>Vendor designation</th>
                <th>Vendor display Name</th>
                <th>Vendor email</th>
                <th>Mobile phone</th>
                <th>Skype number or name</th>
                <th>Website</th>
                <th>Whatsapp</th>
                <th>Work Phone</th>
                <th>Action</th>
                <th>Status</th>
          </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of vendorList; index as i">
                <th scope="row">{{i+1}}</th>
                <th class="font-weight-normal">{{item.primary_contact}}</th>
                <th class="font-weight-normal">{{item.vendor_department}}</th>
                <th class="font-weight-normal">{{item.vendor_designation}}</th>
                <th class="font-weight-normal">{{item.vendor_display_name}}</th>
                <th class="font-weight-normal">{{item.vendor_email}}</th>
                <th class="font-weight-normal">{{item.vendor_mobile_phone}}</th>
                <th class="font-weight-normal">{{item.vendor_skype_number_or_name}}</th>
                <th class="font-weight-normal">{{item.vendor_website}}</th>
                <th class="font-weight-normal">{{item.vendor_whatsapp}}</th>
                <th class="font-weight-normal">{{item.vendor_work_phone}}</th>
                <th>
                    <div class="row h-100">
                        <div class="col-6 py-3">
                            <button class="btn p-0 text-success" (click)="update(item)">
                                <i class="fas fa-edit"></i>
                            </button>
                        </div>
                        <div class="col-6 py-3">
                            <button class="btn p-0 text-danger" (click)="delete(item)">
                                <i class="fas fa-trash-alt"></i>
                            </button>
                        </div>
                    </div>
                </th>
            </tr>
        </tbody>
      </table>
</section>