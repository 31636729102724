export class AddVendor {
    constructor(
    public salutation: String,
    public fname: String,
    public lname: String,
    public company_name:String,
    public vendor_display_name:String,
    public vendor_email:String,
    public vendor_work_phone: String,
    public vendor_mobile_phone: String,
    public vendor_skype_number_or_name: String,
    public vendor_whatsapp: String,
    public vendor_designation: String,
    public vendor_department: String,
    public vendor_website: String
    ){}
}
