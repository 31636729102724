import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/api.service';
import { ProductSettings } from './product-settings';
import { TypeOfProduct } from './type-of-product';

@Component({
  selector: 'app-product-setting',
  templateUrl: './product-setting.component.html',
  styleUrls: ['./product-setting.component.css']
})
export class ProductSettingComponent implements OnInit {

  active = 1;

  socialList: any

  type_of_product = new TypeOfProduct('', '', [{ type_of_product_value: '', gst: true, hsn: '', percentage: 0, delete: false }], ''
    , [{ type_of_product_value: '', wash_care: '', delete: false }],
    [
      {
        heading: 'Addon Details', child: [
          { name: 'Customized Inskart', price: 10, image: '' },
          { name: 'Fall & Ending Work', price: 10, image: '' },
          { name: ' Readymade Stich', price: 10, image: '' },
          { name: 'Cups', price: 10, image: '' }], delete: true
      },
      {
        heading: 'Addon', child: [
          { name: 'Ready Size', price: 10, image: '' },
          { name: 'Pattern dress stiching', price: 10, image: '' },
          { name: 'Unstich Dress Material', price: 10, image: '' },
        ], delete: false
      },
    ], '', '', '', '', '')

  faIcon = "fa-facebook-official";

  countries = [];

  edit = false;

  icons;

  edit_id;
  types: any = [];
  catagories: any;
  edit_value: any;
  loader: boolean = true
  typeList = [];

  onCat(v) {
    this.type_of_product.main_id = v._id
    this.type_of_product.product_type = v.category_name
  }

  Showsubcat(val) {
    val.click = !val.click
  }

  deleteVar(array, i) {
    array.splice(i, 1)
  }

  addAddonDetails(array) {
    console.log(array)
    array.map((res) => res.delete = true)
    array.push({ name: '', price: '' })
  }

  addAttibute(obj) {
    obj.map((res) => res.delete = true)
    this.type_of_product.attribute_values.push({ type_of_product_value: '', wash_care: '', delete: false })
  }

  addVarientType(type_of_varient) {
    console.log(type_of_varient)
    type_of_varient.map((res) => res.delete = true)
    this.type_of_product.type_of_product_gst.push({ type_of_product_value: '', gst: true, hsn: '', percentage: 0, delete: false })
  }

  addAddons(array) {
    let arr = array
    array.map((res) => res.delete = true)
    arr.push({ heading: "", child: [{ name: '', price: '', image: '' }] })
    this.type_of_product.add_on_details = [...arr]
    console.log(this.type_of_product.add_on_details)
  }


  onFileChanged(event) {
    if (this.type_of_product.varImg !== '' && this.type_of_product.varImg !== null) {
      let public_id = this.type_of_product.varImg.split("/")[8].split(".")[0];
      this.api.post(`remove_image`, { "image_id": `custom_varient_img/${public_id}` }).subscribe(
        data => {
          console.log(data)
        },
        error => console.log(error)
      )
    }
    const file: File = event.target.files[0];
    const formData = new FormData();
    formData.append('custom_varient_img', file)
    console.log(file.name)
    this.api.post(`add_custom_image_for_product`, formData).subscribe(
      data => {
        this.type_of_product.varImg = Object.values(data)[2];
        // console.log(this.catagoryForm.img)
      },
      error => console.log(error)
    )
  }

  changed(event) {
    if (this.type_of_product.mesurementImg !== '' && this.type_of_product.mesurementImg !== null) {
      let public_id = this.type_of_product.mesurementImg.split("/")[8].split(".")[0];
      this.api.post(`remove_image`, { "image_id": `custom_varient_img/${public_id}` }).subscribe(
        data => {
          console.log(data)
        },
        error => console.log(error)
      )
    }
    const file: File = event.target.files[0];
    const formData = new FormData();
    formData.append('custom_varient_img', file)
    console.log(file.name)
    this.api.post(`add_custom_image_for_product`, formData).subscribe(
      data => {
        this.type_of_product.mesurementImg = Object.values(data)[2];
        // console.log(this.catagoryForm.img)
      },
      error => console.log(error)
    )
  }

  upload(event,element) {
    if (element.image !== '' && element.image !== null) {
      let public_id = element.image.split("/")[8].split(".")[0];
      this.api.post(`remove_image`, { "image_id": `custom_varient_img/${public_id}` }).subscribe(
        data => {
          console.log(data)
        },
        error => console.log(error)
      )
    }
    const file: File = event.target.files[0];
    const formData = new FormData();
    formData.append('custom_varient_img', file)
    console.log(file.name)
    this.api.post(`add_custom_image_for_product`, formData).subscribe(
      data => {
        element.image = Object.values(data)[2];
        console.log(element.image)
      },
      error => console.log(error)
    )
  }

  varientList = []

  update(types, value, additionalValue, i) {
    this.edit_value = value
    switch (this.active) {
      case 1:
        types.splice(i, 1)
        this.type_of_product.product_type = value.type_of_product_main
        this.type_of_product.main_id = value.type_of_product_main_id
        this.type_of_product.type_of_product_gst = [{
          type_of_product_value: value.type_of_product_sub,
          gst: value.type_of_product_gst_applicable, hsn: '', percentage: value.type_of_product_gst_percentage,
        }]
        this.edit_value.type_of_product_main = this.type_of_product.product_type
        this.edit_value.type_of_product_gst = this.type_of_product.type_of_product_gst
        break;
      case 2:
        this.edit_id = additionalValue._id
        let array = []
        this.type_of_products.push({ type: value.type_of_product_sub, edit_id: value._id })
        this.type_of_product.varient_name = additionalValue.custom_varient_product_type
        this.type_of_product.varient_description = additionalValue.custom_varient_product_type_description
        additionalValue.custom_varient_value.map((res) => array.push(res.varient_name))
        this.type_of_product.custom_varients = array.toString();
        this.type_of_product.varImg = additionalValue.custom_varient_img1
        this.type_of_product.mesurementImg = additionalValue.custom_varient_img2
        types.splice(i, 1)
        break;
      case 3:
        this.edit_id = additionalValue._id
        this.type_of_products.push({ type: this.edit_value.type_of_product_sub, edit_id: this.edit_value._id })
        this.type_of_product.attribute_name = additionalValue.type_of_product_attribute_name
        this.type_of_product.attribute_values = additionalValue.type_of_product_attribute_values
        types.splice(i, 1)
        break;
      case 4:
        this.type_of_products.push({ type: this.edit_value.type_of_product_sub, edit_id: this.edit_value._id })
        this.type_of_product.add_on_details = this.edit_value.type_of_product_addons
        types.splice(i, 1)
        // console.log(this.type_of_product.add_on_details)
        // console.log(value.type_of_product_addons)
        break;

      default:
        break;
    }
    console.log(this.edit_value)
  }

  onUpdate(obj,form: NgForm) {
    let result
    obj.type_of_product_gst.map((res) => {
      result = res
      let body = {
        "_id": this.edit_value._id,
        'type_of_product_main': this.type_of_product.product_type,
        'type_of_product_main_id': this.type_of_product.main_id,
        'type_of_product_gst_applicable': result.gst,
        'type_of_product_gst_percentage': result.percentage,
        'type_of_product_sub': result.type_of_product_value,
      }
      this.api.put(`update_product_type`, body).subscribe(
        data => {
          console.log('!success', data);
          form.reset()
          window.location.reload()
        },
        error => console.log(error)
      )
    })
  }

  product_type(obj, form: NgForm) {
    this.loader = true
    obj.type_of_product_gst.map((res) => {
      let body = {
        'type_of_product_main': obj.product_type,
        'type_of_product_main_id': obj.main_id,
        'type_of_product_gst_applicable': res.gst,
        'type_of_product_gst_percentage': res.percentage,
        'type_of_product_sub': res.type_of_product_value,
      }
      console.log(body)
      this.api.post(`add_product_type`, body).subscribe(
        data => {
          console.log('!success', data);
          if (data.status === 'success') {
            window.location.reload()
          }
        },
        error => console.log(error)
      )
    })
    this.api.get(`product_type_list`).subscribe(
      data => {
        form.reset()
        this.types = Object.values(Object.values(data)[2])
        this.types.map(res => res.status = false)
        this.loader = false
        console.log(this.types)
      },
      error => console.log('Error!', error)
    )
  }

  varSubmit(form: NgForm) {
    this.loader = true
    this.type_of_products.map((res) => {
      let custom_varient_value = []
      this.type_of_product.custom_varients.split(',').map((res) => custom_varient_value.push({ varient_name: res }))
      let body = {
        "_id": res.edit_id,
        "type_of_product_custom_varient": [
          {
            "custom_varient_product_type": this.type_of_product.varient_name,
            "custom_varient_product_type_description": this.type_of_product.varient_description,
            "custom_varient_value": custom_varient_value,
            "custom_varient_img1": this.type_of_product.varImg,
            "custom_varient_img2": this.type_of_product.mesurementImg
          }
        ]
      }
      if (this.edit_value) {
        this.delete({ '_id': this.edit_value._id, 'type_of_product_custom_varient_id': this.edit_id},'pull_type_of_product_custom_varient_from_product_type')
      }
        this.api.post(`push_type_of_product_custom_varient_to_product_type`, body).subscribe(
          data => {
            console.log('!success', data);
            this.type_of_products = []
            this.type_of_product.mesurementImg = ''
            this.type_of_product.varImg = ''
            this.api.get(`product_type_list`).subscribe(
              data => {
                this.types = Object.values(Object.values(data)[2])
                this.types.map(res => res.status = false)
                this.loader = false
                this.edit_id = ''
                this.edit_value;
                console.log(this.types)
              },
              error => console.log('Error!', error)
            )
            form.reset()

          },
          error => console.log(error)
        )
    })
  }

  type_of_products = []

  onType(product_type) {
    if (product_type.status) {
      this.type_of_products.push({ type: product_type.type_of_product_sub, edit_id: product_type._id })
    } else {
      this.type_of_products.splice(this.type_of_products.findIndex(res => res.edit_id === product_type._id), 1)
    }
  }

  postAttibute(form: NgForm, array) {
    this.loader = true
    if (this.type_of_products.length > 0 && this.type_of_product.attribute_name) {
      this.type_of_products.map((res) => {
        let body = {
          "_id": res.edit_id,
          "type_of_product_attribute": [{
            type_of_product_attribute_name: this.type_of_product.attribute_name,
            type_of_product_attribute_values: array.attribute_values
          }
          ]
        }
        if (this.edit_value) {
          this.delete({ '_id': this.edit_value._id, 'type_of_product_attribute_id': this.edit_id},'pull_type_of_product_attribute_from_product_type')
        }
        this.api.post(`push_type_of_product_attribute_to_product_type`, body).subscribe(
          data => {
            this.loader = false
            console.log('!success', data);
            this.type_of_products = []
            this.api.get(`product_type_list`).subscribe(
              data => {
                this.types = Object.values(Object.values(data)[2])
                this.types.map(res => res.status = false)
                this.type_of_product.attribute_name = ''
                console.log(this.types)
                this.edit_value;
                this.edit_id = ''
              },
              error => console.log('Error!', error)
            )
            form.reset()
          },
          error => console.log(error)
        )
      })
    }
  }


  typeSubmit(form: NgForm) {
    this.loader = true
    if (this.edit_value) {
      let result
        this.type_of_product.add_on_details.map(res => {
          result = res
          this.delete({ '_id': this.edit_value._id, 'type_of_product_addons_id': result._id},'pull_type_of_product_addons_from_product_type')
        })
    }
    this.type_of_products.map((res) => {
      let body = {
        "_id": res.edit_id,
        "type_of_product_addons": this.type_of_product.add_on_details,
      }
      this.api.post(`push_type_of_product_addons_to_product_type`, body).subscribe(
        data => {
          this.loader = false
          console.log('!success', data);
          this.type_of_products = []
          this.api.get(`product_type_list`).subscribe(
            data => {
              this.types = Object.values(Object.values(data)[2])
              this.types.map(res => res.status = false)
              this.types.map(res => res.status = false)
              console.log(this.types)
              this.edit_id = ''
              this.edit_value;
            },
            error => console.log('Error!', error)
          )
          form.reset()
        },
        error => console.log(error)
      )
    }
    )
  }

  delete(value, endpoint) {
    console.log(value)
    this.api.post(endpoint, value).subscribe(
      data => {
        console.log('!success', data);
        this.api.get(`product_type_list`).subscribe(
          data => {
            this.edit_id = ''
            this.modalService.dismissAll()
            this.types = Object.values(Object.values(data)[2])
            this.types.map(res => res.status = false)
            console.log(this.types)
          },
          error => console.log('Error!', error)
        )
      },
      error => console.log(error)
    )
  }

  deleteAdditionals(value, varient) {
    let body = {
      "product_type_id": value._id,
      "type_of_product_custom_varient_id": varient._id
    }
    console.log(body)
    this.api.post(`pull_type_of_product_addons_from_product_type`, body).subscribe(
      data => {
        console.log('!success', data);
        this.api.get(`product_type_list`).subscribe(
          data => {
            this.types = Object.values(Object.values(data)[2])
            this.types.map(res => res.status = false)
            console.log(this.types)
          },
          error => console.log('Error!', error)
        )
      },
      error => console.log(error)
    )
  }

  open(content){
    this.modalService.open(content)
  }

  constructor(private api: ApiService, private modalService: NgbModal) {
  }
  ngOnInit(): void {
    this.api.get(`selected_country_list`).subscribe(
      data => {
        let country = Object.values(data);
        this.countries = Object.values(country[2]);
        // this.api.countryShare(this.countries);
        console.log(this.countries)
      },
      error => console.log('Error!', error)
    )

    this.api.get(`product_type_list`).subscribe(
      data => {
        this.typeList = Object.values(Object.values(data)[2])
        this.types = Object.values(Object.values(data)[2])
        this.types.map(res => res.status = false)
        this.loader = false
        console.log(this.types)
      },
      error => console.log('Error!', error)
    )
    this.api.get(`populate_all_category`).subscribe(
      data => {
        this.catagories = Object.values(data)[2]
        console.log(this.catagories)
      },
      error => console.log('Error!', error)
    )
  }

  // http://34.121.17.227/v1.0/api/get_all_social_media_list
  //   this.api.countrySource$.subscribe(
  //     message => {
  //       this.countries = message;
  //     }
  //   )



}
