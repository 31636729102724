export class StoreTime {

    constructor(
        public store_week_day_monday: {
            store_week_day_name: string,
            store_week_day_opening_type: string,
            store_time: Array<object>
            },
            
        public store_week_day_tuesday: {
            store_week_day_name: string,
            store_week_day_opening_type: string,
            store_time: Array<object>
            },
            
        public store_week_day_wednesday: {
                store_week_day_name: string,
                store_week_day_opening_type: string,
                store_time: Array<object>
            },
            
        public store_week_day_thursday: {
                store_week_day_name: string,
                store_week_day_opening_type: string,
                store_time: Array<object>
                },
            
        public store_week_day_fryday: {
                store_week_day_name: string,
                store_week_day_opening_type: string,
                store_time: Array<object>
                },
            
        public store_week_day_saturday: {
                store_week_day_name: string,
                store_week_day_opening_type: string,
                store_time: Array<object>
                },
            
        public store_week_day_sunday: {
                store_week_day_name: string,
                store_week_day_opening_type: string,
                store_time: Array<object>
                },
    ){}

}

