import { Component, OnInit, } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/api.service';
@Component({
  selector: 'app-country-currency',
  templateUrl: './country-currency.component.html',
  styleUrls: ['./country-currency.component.css']
})
export class CountryCurrencyComponent implements OnInit {
  imgType = true;
  countries;
  success: boolean;
  selectCountry = "Choose country";

  countrySelection: any = [];

  basecurrencySet = false;
  ultimateList = [];

  basecountrySelection = {};

  baseCountryList = [];
  alerttag = false;

  selectedCountry: any = [];
  baseCurrencyChange = true;
  getselectedCountry() {
    this.api.get(`selected_country_list`).subscribe(
      data => {
        console.log(data)
        let selectcountry = Object.values(data);
        this.countrySelection = Object.values(selectcountry[2]);
        console.log(this.countrySelection);
        this.countrySelection.map((res) => {
          if (res.selected_country_type_base === true) {
            this.baseCurrencyChange = false;
            this.basecurrencySet = true;
            this.basecountrySelection = res;
            this.baseCountryList = Object.values(selectcountry[2]);
            this.baseCountryList.splice(this.baseCountryList.indexOf(res), 1)
          }
        }
        )
      },
      error => console.log('Error!', error)
    )
  }

  checkSelectedCountry(value, chooseCountry) {
    let body = {
      "selected_country_name": value.name,
      "selected_country_native": value.native,
      "selected_country_phone": value.phone,
      "selected_country_continent": value.continent,
      "selected_country_capital": value.capital,
      "selected_country_currency": value.currency,
      "selected_country_code": value.code,
      "selected_country_currency_convert_value": value.currency_symbol,
      "selected_country_symbol": value.currency_symbol,
    }
    if (chooseCountry.checked) {
      this.api.post(`check_slected_countries`, { "selected_country_name": value.name }).subscribe(
        data => {
          if (Object.values(data)[0] === 'Success') {
            this.alerttag = false;
            this.selectedCountry.push(body)
          }
          else {
            this.alerttag = true;
          }
        },
        error => console.log(error)
      )
    } else {
      this.selectedCountry.map((res, index) => {
        if (res.selected_country_name === value.name) {
          this.selectedCountry.splice(index, 1)
        }
      })
    }
  }

  deleteCountry(id) {
    this.api.post(`delete_selected_country`, { '_id': id }).subscribe(
      data => {
        this.getselectedCountry();
      },
      error => console.log(error)
    )
    this.selectedCountry = [];
  }


  onSubmit(form: NgForm) {
    let body = { 'selectedCountryList': this.selectedCountry }
    this.api.post(`add_selected_countries`, body).subscribe(
      data => {
        console.log('!success', data);
        this.getselectedCountry();
      },
      error => console.log(error)
    )
    this.selectedCountry = [];
    form.reset();
  }

  updateStatus(item) {
    this.baseCurrencyChange = false;
    let body = {
      "id": item._id,
      "selected_country_type_base": true,
      "selected_country_currency_convert_value": '1',
    }
    this.api.put(`select_base_country`, body).subscribe(
      data => {
        console.log('!success', data);
        this.getselectedCountry()
      },
      error => console.log(error)
    )
  }

  basecountryChangeYes(content) {
    this.countrySelection.map((res) => {
      if (res.selected_country_type_base === true) {
        this.api.put(`select_base_country`,
          { "id": res._id, "selected_country_type_base": false, 'selected_country_currency_convert_value': '0' }).subscribe(
            data => {
              console.log('!success', data);
              this.getselectedCountry();
              this.basecurrencySet = false;
              this.baseCurrencyChange = true;
              this.modalService.dismissAll();
            },
            error => console.log(error)
          )
      }
    }
    )
  }

  ShowHideDiv(content) {
    this.modalService.open(content, { centered: true });
  }

  currency(addcourrency, e) {
    let body = {
      "id": addcourrency._id,
      "selected_country_currency_convert_value": e.target.value,
    }
    this.api.put(`select_base_country`, body).subscribe(
      data => {
        console.log('!success', data);
        this.getselectedCountry()
      },
      error => console.log(error)
    )
  }


  constructor(private api: ApiService, private modalService: NgbModal) { }

  ngOnInit(): void {
    console.log(new Date());
    this.api.get('/all_json_country_list').subscribe(
      data => {
        let country = Object.values(data);
        this.countries = Object.values(country[2]);
        // this.api.countryShare(this.countries);
        console.log(this.countries)
      },
      error => console.log('Error!', error)
    )
    this.getselectedCountry();
  }

}
