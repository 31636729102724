import { Component, HostListener, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/api.service';
import { PageDesignService } from 'src/app/page-design.service';
import { PageDesign } from './page-design';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-index-page-design',
  templateUrl: './index-page-design.component.html',
  styleUrls: ['./index-page-design.component.css']
})
export class IndexPageDesignComponent implements OnInit {
  bannerMenu;
  iconLogoList: any = {};
  countries: any = [];
  country_for_page;
  catagories: any = []
  catagoryList: any = []
  catagoryshow = false
  fetures: any = [];
  fetureMenu: any = []
  edit_id: any;
  pageCount = 1
  activeId = 1;
  activeIdForParent = 2
  res;
  pageDesign = new PageDesign('12345', 'Home', '', true, {
    container_title_text: '',
    container_title_fontSize: '18',
    container_title_color: '#000000',
  }, {
    container_subtitle_text: '',
    container_subtitle_fontSize: '15',
    container_subtitle_color: '#636e72',
  }, '1', 'bg_color', '#ffffff', '', 'true', '', '', '', '', [{
    img: '', texts: [{ text_value: '', text_color: '#ffffff', text_font_size: 16, text_font_family: 'sans-serif', delete: false }],
    applied_to: 'Select Link', applied_to_id: '', check_page_type: '',
  }], 'true', '1000', 2, 'true', '200', '100', '12',
    { margin_top: '0', margin_right: '0', margin_bottom: '0', margin_left: '0' },
    { padding_top: '0', padding_right: '0', padding_bottom: '0', padding_left: '0' }, true, 'bottom', '0', 100, 'transparent', '1', 'left',
    true, '0', '5', { image_border_top_left: '0', image_border_top_right: '0', image_border_bottom_left: '0', image_border_bottom_right: '0' },
    { text_area_border_top_left: '0', text_area_border_top_right: '0', text_area_border_bottom_left: '0', text_area_border_bottom_right: '0' },
    '#ffffff', '0', true, { title: 'View All', titleColor: '#ffffff', fontSize: '12', backgroundColor: "#e91e63", applied_to_id: '', applied_to: '', check_page_type: '' }, [])

  grid_types = [{ name: 'Carousel', value: 'Carosuel', img: '../../../../assets/ezgif.com-gif-maker (1).gif' },
  { name: 'Banner', value: 'Banner', img: '../../../../assets/design_1.png' },
  { name: 'Horizontal ListView With Title', value: 'ListView', img: '../../../../assets/design_3 (2).png' },
  { name: 'Horizontal ListView Without Title', value: 'listView', img: '../../../../assets/design_2.png' },
  { name: 'Vertical ListView With Title', value: 'Design 3_2', img: '../../../../assets/design_5.png' },
  { name: 'GridView With Title', value: 'DesignGridView3Item', img: '../../../../assets/design_6.png' },
  { name: 'Grid view', value: 'grid_view', img: '../../../../assets/horizontal_grid.png' },
  { name: 'TextView With Title', value: 'textView', img: '../../../../assets/design_textview.png' },
  { name: 'View Products in Grid', value: 'productgridView', img: '../../../../assets/sareeeeee.png' },
  { name: 'View Products in list', value: 'productlistView', img: '../../../../assets/productView.png' },
  { name: 'Horizontal_List_product_view', value: 'Horizontal-product-view', img: '../../../../assets/horizontal_product_view.jpg' }]

  design_type: string;
  products: any;
  image: string;
  colcount = [];
  currentPage = 1
  totalPages = 0


  countrySelect(country) {
    console.log(country.selected_country_name)
  }

  applied_to(item, type, applied_to_name) {
    item.applied_to = applied_to_name
    item.check_page_type = type
  }


  selectGrid(design_image) {
    if (this.pageDesign.design_type === 'Carosuel') {
      this.design_type = 'Carosuel'
      this.pageDesign.showTitleBar = false
      this.pageDesign.is_grid_have_text_area = false
      this.pageDesign.total_number_of_grid = '2'
      this.pageDesign.showOptionButton = false
      this.pageDesign.container_title_design = {
        container_title_text: '',
        container_title_fontSize: '',
        container_title_color: '',
      }
      this.pageDesign.grid_width = '100';
      this.pageDesign.is_grid_have_text_area = false;
      this.pageDesign.data = []
      this.pageDesign.grid_background = ''
      let number = parseInt(this.pageDesign.total_number_of_grid)
      for (let index = 0; index < number; index++) {
        this.pageDesign.data.push({
          img: '', texts: [{
            text_value: '', text_color: '#ffffff',
            text_font_size: 16, delete: false,
            text_font_family: 'sans-serif'
          }], applied_to: '', applied_to_id: '', check_page_type: '',
        })
      }
    } else if (this.pageDesign.design_type === 'ListView') {
      this.design_type = 'listView'
      this.pageDesign.showTitleBar = true
      this.pageDesign.direction = 'horizontal'
      this.pageDesign.total_number_of_grid = '2'
      this.pageDesign.data = []
      let number = parseInt(this.pageDesign.total_number_of_grid)
      for (let index = 0; index < number; index++) {
        this.pageDesign.data.push({
          img: '', texts: [{
            text_value: '', text_color: '#ffffff',
            text_font_size: 16, delete: false,
            text_font_family: 'sans-serif'
          }], applied_to: '', applied_to_id: ''
        })
      }
      this.pageDesign.animation = 'false'
      this.pageDesign.arrows = 'false'
      this.pageDesign.is_grid_have_text_area = true
      this.pageDesign.showOptionButton = true
    } else if (this.pageDesign.design_type === 'DesignGridView3Item') {
      this.design_type = 'DesignGridView3Item'
      this.pageDesign.total_number_of_grid = '3'
      this.pageDesign.data = []
      let number = parseInt(this.pageDesign.total_number_of_grid)
      for (let index = 0; index < number; index++) {
        this.pageDesign.data.push({
          img: '', texts: [{
            text_value: '', text_color: '#ffffff',
            text_font_size: 16, delete: false,
            text_font_family: 'sans-serif'
          }], applied_to: '', applied_to_id: ''
        })
      }
      this.pageDesign.animation = 'false'
      this.pageDesign.arrows = 'false'
      this.pageDesign.is_grid_have_text_area = true
      this.pageDesign.showOptionButton = true
    } else if (this.pageDesign.design_type === 'Design 3_2') {
      this.design_type = 'listView'
      this.pageDesign.direction = 'vertical'
      this.pageDesign.total_number_of_grid = '2'
      this.pageDesign.data = []
      let number = parseInt(this.pageDesign.total_number_of_grid)
      for (let index = 0; index < number; index++) {
        this.pageDesign.data.push({
          img: '', texts: [{
            text_value: '', text_color: '#ffffff',
            text_font_size: 16, delete: false,
            text_font_family: 'sans-serif'
          }], applied_to: '', applied_to_id: ''
        })
      }
      this.pageDesign.animation = 'false'
      this.pageDesign.arrows = 'false'
      this.pageDesign.is_grid_have_text_area = true
      this.pageDesign.showOptionButton = true
    } else if (this.pageDesign.design_type === 'listView') {
      this.design_type = 'listView'
      this.pageDesign.direction = 'horizontal'
      this.pageDesign.showTitleBar = false
      this.pageDesign.total_number_of_grid = '2'
      this.pageDesign.data = []
      let number = parseInt(this.pageDesign.total_number_of_grid)
      for (let index = 0; index < number; index++) {
        this.pageDesign.data.push({
          img: '', texts: [{
            text_value: '', text_color: '#ffffff',
            text_font_size: 16, delete: false,
            text_font_family: 'sans-serif'
          }], applied_to: '', applied_to_id: ''
        })
      }
      this.pageDesign.animation = 'false'
      this.pageDesign.arrows = 'false'
      this.pageDesign.is_grid_have_text_area = true
    } else if (this.pageDesign.design_type === 'Banner') {
      this.pageDesign.direction = 'vertical'
      this.design_type = 'listView'
      this.pageDesign.total_number_of_grid = '1'
      this.pageDesign.data = []
      let number = parseInt(this.pageDesign.total_number_of_grid)
      for (let index = 0; index < number; index++) {
        this.pageDesign.data.push({
          img: '', texts: [{
            text_value: '', text_color: '#ffffff',
            text_font_size: 16, delete: false,
            text_font_family: 'sans-serif'
          }], applied_to: '', applied_to_id: ''
        })
      }
      this.pageDesign.animation = 'false'
      this.pageDesign.arrows = 'false'
      this.pageDesign.is_grid_have_text_area = true
    } else if (this.pageDesign.design_type === 'textView') {
      this.design_type = 'textView'
      this.pageDesign.total_number_of_grid = '2'
      this.pageDesign.data = []
      let number = parseInt(this.pageDesign.total_number_of_grid)
      for (let index = 0; index < number; index++) {
        this.pageDesign.data.push({
          img: 'none', texts: [{
            text_value: '', text_color: '#ffffff',
            text_font_size: 16, delete: false,
            text_font_family: 'sans-serif'
          }], applied_to: '', applied_to_id: ''
        })
      }
      this.pageDesign.animation = 'false'
      this.pageDesign.arrows = 'false',
        this.pageDesign.showOptionButton = false
    } else if (this.pageDesign.design_type === 'productgridView') {
      this.image = design_image
      this.design_type = 'productgridView'
      this.pageDesign.data = []
      this.pageDesign.animation = 'false'
      this.pageDesign.arrows = 'false',
        this.pageDesign.showOptionButton = false
    } else if (this.pageDesign.design_type === 'productlistView') {
      this.image = design_image
      this.design_type = 'productlistView'
      this.pageDesign.data = []
      this.pageDesign.animation = 'false'
      this.pageDesign.arrows = 'false',
        this.pageDesign.showOptionButton = false
    } else if (this.pageDesign.design_type === 'grid_view') {
      this.design_type = 'grid_view'
      this.pageDesign.total_number_of_grid = '2'
      this.pageDesign.data = []
      this.colcount = []
      let number = parseInt(this.pageDesign.total_number_of_grid)
      for (let index = 0; index < number; index++) {
        this.pageDesign.data.push({
          img: '', texts: [{
            text_value: '', text_color: '#ffffff',
            text_font_size: 16, delete: false,
            text_font_family: 'sans-serif'
          }], applied_to: '', applied_to_id: ''
        })
        this.colcount.push(index + 1)
      }
      this.pageDesign.animation = 'false'
      this.pageDesign.arrows = 'false'
      this.pageDesign.cross_axis_count = '2'
      this.pageDesign.is_grid_have_text_area = true
      this.pageDesign.showOptionButton = true
    } else if (this.pageDesign.design_type === 'Horizontal-product-view') {
      this.image = design_image
      this.design_type = 'Horizontal_List_product_view'
      this.pageDesign.data = []
      this.pageDesign.animation = 'false'
      this.pageDesign.arrows = 'false'
      this.pageDesign.is_grid_have_text_area = true
      this.pageDesign.showOptionButton = true
    } else {

    }
    this.modalService.dismissAll()
  }

  open(content) {
    this.modalService.open(content, { backdropClass: 'light-blue-backdrop' });
  }

  addProducts(product) {
    if (product.status) {
      this.pageDesign.product_data.push(product._id)
    } else {
      this.pageDesign.product_data.splice(this.pageDesign.product_data.indexOf(product._id), 1)
    }
  }

  addMoreText(array) {
    array.texts.map((res) => res.delete = true)
    array.texts.push({
      text_value: '', text_color: '#ffffff', text_font_size: 16, text_font_family: 'sans-serif',
      delete: false
    })
    console.log(array.texts)
  }

  deleteText(array, index) {
    let list = array
    list.splice(index, 1);
    console.log(list)
  }

  gridSize(value) {
    if (value && this.pageDesign.grid_text_area_location !== 'overlay') {
      this.pageDesign.grid_image_height = 100 - parseFloat(value)
    } else {
      this.pageDesign.grid_image_height = 100
    }
  }

  valueChange(e) {
    console.log(e.target.value)
    let item = this.bannerMenu.find((res) => res._id === this.edit_id)
    // console.log(e.target.name)
    // if (e.target.name === 'margin-top') {
    //   item.outside_grid_margin.margin_left = e.target.valueelse
    // }
    for (const key in item) {
      if (key === e.target.name) {
        // e.target.value ? item[key] = e.target.value : item[key] = 0 
        item[key] = e.target.value
        console.log(item[key])
      }
    }
  }

  edit(item, e) {
    this.edit_id = item._id
    item.edit = true
    console.log(item)
    this.pageDesign.page_name = item.page_name,
      this.pageDesign.grid_name = item.grid_name,
      this.pageDesign.design_type = item.design_value,
      this.design_type = item.design_type,
      this.pageDesign.direction = item.direction,
      this.pageDesign.position = item.position,
      this.pageDesign.container_title_design = {
        container_title_text: item.container_title_design.container_title_text,
        container_title_fontSize: item.container_title_design.container_title_fontSize,
        container_title_color: item.container_title_design.container_title_color,
      },
      this.pageDesign.container_subtitle_design = {
        container_subtitle_text: item.container_subtitle_design.container_subtitle_text,
        container_subtitle_fontSize: item.container_subtitle_design.container_subtitle_fontSize,
        container_subtitle_color: item.container_subtitle_design.container_subtitle_color,
      },
      this.pageDesign.data = item.data,
      this.pageDesign.animation = item.carosel_animation ? 'true' : 'false',
      this.pageDesign.carosel_animation_time = item.carosel_animation ? item.carosel_animation_time : 0,
      this.pageDesign.arrows = item.carosel_arrows ? 'true' : 'false',
      this.pageDesign.grid_height = item.grid_height,
      this.pageDesign.grid_width = item.grid_width,
      this.pageDesign.cross_axis_count = item.cross_axis_count,
      this.pageDesign.total_number_of_grid = item.total_number_of_grid,
      this.pageDesign.margin = item.margin,
      this.pageDesign.padding = item.padding,
      this.pageDesign.is_grid_have_text_area = item.is_grid_have_text_area,
      this.pageDesign.grid_text_area_location = item.is_grid_have_text_area ? item.grid_text_area_location : '',
      this.pageDesign.grid_text_area_height = item.grid_text_area_height,
      this.pageDesign.grid_image_height = item.grid_image_height,
      this.pageDesign.bg_color = item.grid_text_area_bg_color,
      this.pageDesign.opacity = item.grid_text_area_opacity,
      this.pageDesign.is_grid_border = item.is_grid_border,
      this.pageDesign.grid_border_radius = item.grid_border_radius
    this.pageDesign.grid_border_color = item.grid_border_color,
      this.pageDesign.grid_border_width = item.grid_border_width,
      this.pageDesign.showTitleBar = item.showTitleBar
    this.pageDesign.showOptionButton = item.showOptionButton,
      this.pageDesign.option_button = item.option_button
    this.pageDesign.text_align = item.text_align
    this.pageDesign.item_gap = item.item_gap
    this.pageDesign.text_align = item.text_align
    this.pageDesign.image_border_radius = item.image_border_radius,
      this.pageDesign.text_area_border_radius = item.text_area_border_radius,
      this.pageDesign.grid_background = item.grid_background,
      this.pageDesign.grid_background_color = item.grid_background_color,
      this.pageDesign.grid_background_image = item.grid_background_image,
      this.pageDesign.is_valid_date = item.is_valid_date === true ? 'true' : 'false',
      this.pageDesign.is_valid_from = item.is_valid_from,
      this.pageDesign.is_valid_upto = item.is_valid_upto,
      this.pageDesign.product_data = item.product_data
    item.product_data.map((res) => {
      this.products.map((data) => {
        if (data.sku === res.sku) {
          res.status = true
        } else {
          data.status = false
        }
      })
    })
    item.data.map((res, index) => this.colcount.push(index + 1))
  }

  imgUpload(event, item) {
    const file: File = event.target.files[0];
    const formData = new FormData();
    formData.append('carosel_image', file)
    console.log(file.name)
    this.api.post(`add_carosel_image`, formData).subscribe(
      data => {
        'grid_background_image' in item ? item.grid_background_image = Object.values(data)[2] : item.img = Object.values(data)[2]
      },
      error => console.log(error)
    )
  }

  totalGrid(e, data) {
    let number = parseFloat(e)
    if (number < 0) {
      e = 0
    }
    this.pageDesign.data = []
    this.colcount = []
    switch (this.design_type) {
      case 'textView':
        for (let index = 0; index < number; index++) {
          this.pageDesign.data.push({
            img: 'none', texts: [{
              text_value: '', text_color: '#ffffff',
              text_font_size: 16, delete: false,
              text_font_family: 'sans-serif'
            }], applied_to: '', applied_to_id: ''
          })
        }
        break;
      case 'productgridView':
        this.pageDesign.data = []
        break;
      case 'productlistView':
        this.pageDesign.data = []
        break;
      default:
        for (let index = 0; index < number; index++) {
          this.pageDesign.data.push({
            img: '', texts: [{
              text_value: '', text_color: '#ffffff',
              text_font_size: 16, delete: false,
              text_font_family: 'sans-serif'
            }], applied_to: '', applied_to_id: ''
          })
          this.colcount.push(index + 1)
        }
        break;
    }
  }

  page_name(page) {
    if (page === 'Home') {
      this.pageDesign.page_name = page
      this.pageDesign.page_id = '12345'
      this.api.get(`featured_product_list`).subscribe(
        data => {
          this.fetures = Object.values(data)[2];
        },
        error => console.log('Error!', error)
      )
      this.api.get(`featured_product_category_list`).subscribe(
        data => {
          this.fetureMenu = Object.values(data)[2].result
        },
        error => console.log('Error!', error)
      )
    } else if (page === 'Shop by Catagory') {
      this.pageDesign.page_name = page
      this.pageDesign.page_id = 'cat123'
      this.api.get(`featured_product_list`).subscribe(
        data => {
          this.fetures = Object.values(data)[2];
        },
        error => console.log('Error!', error)
      )
      this.api.get(`featured_product_category_list`).subscribe(
        data => {
          this.fetureMenu = Object.values(data)[2].result
        },
        error => console.log('Error!', error)
      )
    } else {
      this.pageDesign.page_name = page.category_name
      this.pageDesign.page_id = page._id
      this.api.post(`populate_featured_product_category_by_category`, { featured_product_category_id: page._id }).subscribe(
        data => {
          console.log(data)
          if (data.status === 'success') {
            this.fetures = []
            this.fetureMenu = data.result
          } else {
            this.fetureMenu = []
            this.fetures = []
          }
          console.log(this.fetureMenu)
        },
        error => console.log('Error!', error)
      )
    }
    this.api.get(`/get_page_design/${this.pageDesign.page_id}?limit=10&page=1`).subscribe(
      data => {
        this.bannerMenu = Object.values(data)[0]
        this.totalPages = Object.values(data)[1]
        this.currentPage = Object.values(data)[2]
        let edit = 'edit';
        this.bannerMenu.map((res) => res[edit] = false)
        console.log(data)
      },
      error => console.log('Error!', error)
    )
  }


  submit(bannerform: NgForm) {
    let body = {
      page_name: this.pageDesign.page_name,
      grid_name: this.pageDesign.grid_name,
      page_id: this.pageDesign.page_id,
      design_type: this.design_type,
      design_value: this.pageDesign.design_type,
      direction: this.pageDesign.direction,
      showTitleBar: this.pageDesign.showTitleBar,
      container_title_design: {
        container_title_text: this.pageDesign.container_title_design.container_title_text,
        container_title_fontSize: parseFloat(this.pageDesign.container_title_design.container_title_fontSize),
        container_title_color: this.pageDesign.container_title_design.container_title_color,
      },
      container_subtitle_design: {
        container_subtitle_text: this.pageDesign.container_subtitle_design.container_subtitle_text,
        container_subtitle_fontSize: parseFloat(this.pageDesign.container_subtitle_design.container_subtitle_fontSize),
        container_subtitle_color: this.pageDesign.container_subtitle_design.container_subtitle_color,
      },
      position: this.pageDesign.position,
      data: [],
      carosel_animation: this.pageDesign.animation === 'true' ? true : false,
      carosel_animation_time: this.pageDesign.animation === 'true' ? parseFloat(this.pageDesign.carosel_animation_time) : 0,
      carosel_arrows: this.pageDesign.arrows === 'false' && this.pageDesign.design_type !== 'Banner' ? false : true,
      grid_height: parseFloat(this.pageDesign.grid_height),
      grid_width: parseFloat(this.pageDesign.grid_width),
      cross_axis_count: parseFloat(this.pageDesign.cross_axis_count),
      total_number_of_grid: parseFloat(this.pageDesign.total_number_of_grid),
      margin: {
        margin_top: parseFloat(this.pageDesign.margin.margin_top),
        margin_right: parseFloat(this.pageDesign.margin.margin_right),
        margin_bottom: parseFloat(this.pageDesign.margin.margin_bottom),
        margin_left: parseFloat(this.pageDesign.margin.margin_left)
      },
      padding: {
        padding_top: parseFloat(this.pageDesign.padding.padding_top),
        padding_right: parseFloat(this.pageDesign.padding.padding_right),
        padding_bottom: parseFloat(this.pageDesign.padding.padding_bottom),
        padding_left: parseFloat(this.pageDesign.padding.padding_left)
      },
      grid_background_color: this.pageDesign.grid_background_color,
      grid_background_image: this.pageDesign.grid_background_image,
      is_grid_have_text_area: this.pageDesign.is_grid_have_text_area,
      grid_text_area_location: this.pageDesign.is_grid_have_text_area ? this.pageDesign.grid_text_area_location : '',
      grid_text_area_height: this.pageDesign.is_grid_have_text_area ? parseFloat(this.pageDesign.grid_text_area_height) : 0,
      grid_image_height: this.pageDesign.grid_image_height,
      grid_text_area_bg_color: this.pageDesign.is_grid_have_text_area ? this.pageDesign.bg_color : '',
      grid_text_area_opacity: this.pageDesign.is_grid_have_text_area ? parseFloat(this.pageDesign.opacity) : 0,
      is_grid_border: this.pageDesign.is_grid_border,
      grid_border_radius: parseFloat(this.pageDesign.grid_border_radius),
      grid_border_color: this.pageDesign.grid_border_color,
      grid_border_width: this.pageDesign.grid_border_width,
      text_align: this.pageDesign.text_align,
      showOptionButton: this.pageDesign.showOptionButton,
      option_button: {
        title: this.pageDesign.option_button.title,
        titleColor: this.pageDesign.option_button.titleColor,
        fontSize: parseInt(this.pageDesign.option_button.fontSize),
        backgroundColor: this.pageDesign.option_button.backgroundColor,
        applied_to: this.pageDesign.option_button.applied_to,
        applied_to_id: this.pageDesign.option_button.applied_to_id,
        check_page_type: this.pageDesign.option_button.check_page_type
      },
      item_gap: this.pageDesign.item_gap,
      image_border_radius: {
        image_border_top_left: parseFloat(this.pageDesign.image_border_radius.image_border_top_left),
        image_border_top_right: parseFloat(this.pageDesign.image_border_radius.image_border_top_right),
        image_border_bottom_left: parseFloat(this.pageDesign.image_border_radius.image_border_bottom_left),
        image_border_bottom_right: parseFloat(this.pageDesign.image_border_radius.image_border_bottom_right)
      },

      text_area_border_radius: {
        text_area_border_top_left: parseFloat(this.pageDesign.text_area_border_radius.text_area_border_top_left),
        text_area_border_top_right: parseFloat(this.pageDesign.text_area_border_radius.text_area_border_top_right),
        text_area_border_bottom_left: parseFloat(this.pageDesign.text_area_border_radius.text_area_border_bottom_left),
        text_area_border_bottom_right: parseFloat(this.pageDesign.text_area_border_radius.text_area_border_bottom_right)
      },
      is_valid_date: this.pageDesign.is_valid_date === 'true' ? true : false,
      is_valid_from: this.pageDesign.is_valid_from,
      is_valid_upto: this.pageDesign.is_valid_upto,
      grid_background: this.pageDesign.grid_background,
      product_data: []
    }

    if (this.pageDesign.design_type === 'productgridView' || this.pageDesign.design_type === 'productlistView' || this.pageDesign.design_type === 'Horizontal-product-view') {
      body.product_data = this.pageDesign.product_data
      // this.pageDesign.data.map((result) => {
      //   this.res = result
      //   body.product_data.push(this.res.applied_to_id)
      // })
    } else {
      body.data = this.pageDesign.data
    }
    if (this.pageDesign.container_title_design.container_title_text || this.pageDesign.container_subtitle_design.container_subtitle_text || this.pageDesign.showOptionButton) {
      body.showTitleBar = true
    } else {
      body.showTitleBar = false
    }
    if (this.edit_id) {
      let _id = '_id'
      body[_id] = this.edit_id;
      this.api.put(`edit_carosel_or_banner`, body).subscribe(
        data => {
          console.log('Success', data)
          body.position = this.pageDesign.position;
          setTimeout(() => {
            window.location.reload()
          }, 1000);
          this.edit_id;
          bannerform.reset();
        },
        error => console.log('Error!', error)
      )
    } else {
      body.position = this.bannerMenu.length;
      // console.log(body)
      this.api.post(`add_banner_and_carosel`, body).subscribe(
        data => {
          console.log(data)
          bannerform.reset();
          setTimeout(() => {
            window.location.reload()
          }, 1000);
        },
        error => console.log('Error!', error)
      )
    }
    console.log(body)
  }

  Showsubcat(val) {
    val.click = !val.click
  }

  view(value) {
    this.api.put(`edit_carosel_or_banner`, value).subscribe(
      data => {
        console.log('Success', data)
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      },
      error => console.log('Error!', error)
    )
  }

  delete(value) {
    this.api.post(`delete_carosel_or_banner`, {
      "_id": value._id
    }).subscribe(
      data => {
        console.log('Success', data)
        if (data.status === 'success') {
          value.data.map((res) => {
            let public_id = res.img.split("/")[8].split(".")[0];
            this.api.post(`remove_image`, { "image_id": `carosel_image/${public_id}` }).subscribe(
              data => {
                console.log(data)
                window.location.reload()
              },
              error => console.log(error)
            )
          })
        }
      },
      error => console.log('Error!', error)
    )
  }

  openXl(content) {
    this.modalService.open(content, { size: 'xl', scrollable: true });
  }

  drop(event: CdkDragDrop<string[]>, list) {
    moveItemInArray(list, event.previousIndex, event.currentIndex);
    list.map(res => {
      res.position = list.indexOf(res)
      let body = { "_id": res._id, "position": res.position }
      console.log(body)
      this.api.put(`edit_carosel_or_banner`, { "_id": res._id, "position": res.position }).subscribe(
        data => {
          console.log(data)
        },
        error => console.log('Error!', error)
      )
    })
  }


  @HostListener('scroll', ['$event'])
  scrollHandler(event) {
    if (event.target.scrollTop >= event.target.clientHeight * (50 / 100)) {
      if (this.currentPage < this.totalPages && this.bannerMenu.length === this.currentPage * 10) {
        this.currentPage = this.currentPage + 1
        this.api.get(`/get_page_design/${this.pageDesign.page_id}?limit=10&page=${this.currentPage}`).subscribe(
          data => {
            console.log(data)
            let array = Object.values(data)[0]
            if (array.length > 0) {
              let edit = 'edit';
              array.map(res => {
                res[edit] = false
                this.bannerMenu.push(res)
              })
            }
          },
          error => console.log('Error!', error)
        )
      }
    }
  }

  constructor(private api: ApiService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.api.get(`/get_page_design/${this.pageDesign.page_id}?limit=10&page=1`).subscribe(
      data => {
        this.bannerMenu = Object.values(data)[0]
        this.totalPages = Object.values(data)[1]
        this.currentPage = Object.values(data)[2]
        console.log(this.bannerMenu)
        if (this.bannerMenu.length > 0) {
          let edit = 'edit';
          this.bannerMenu.map((res) => res[edit] = false)
        }
      },
      error => console.log('Error!', error)
    )
    this.api.get(`product_list`).subscribe(
      data => {
        this.products = Object.values(data)[2]
        this.api.productListShare(Object.values(data)[2])
        this.products.map((res) => res.status = false)
      },
      error => console.log('Error!', error)
    )
    this.api.get(`featured_product_list`).subscribe(
      data => {
        this.fetures = Object.values(data)[2];
        // console.log(this.fetures)
      },
      error => console.log('Error!', error)
    )
    this.api.get(`featured_product_category_list`).subscribe(
      data => {
        this.fetureMenu = Object.values(data)[2].result
        // console.log(Object.values(data)[2])
      },
      error => console.log('Error!', error)
    )
    this.api.get(`icon_logo_caption_list`).subscribe(
      data => {
        console.log(data)
        if (Object.values(data)[2].length > 0) {
          this.iconLogoList = Object.values(Object.values(data)[2])[0];
        }
        // this.api.countryShare(this.countries);
        // console.log(this.iconLogoList)
      },
      error => console.log('Error!', error)
    )
    this.api.get(`selected_country_list`).subscribe(
      data => {
        this.countries = Object.values(data)[2];
        let base_country = this.countries.find(res => res.selected_country_type_base)
        this.country_for_page = base_country.selected_country_name
        // console.log(this.countries)
      },
      error => console.log('Error!', error)
    );
    this.api.get(`populate_all_category`).subscribe(
      data => {
        this.catagories = Object.values(data)[2]
      },
      error => console.log('Error!', error)
    )
  }

}
