import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.css']
})

export class TermsConditionComponent implements OnInit {
  countries: any = [];
  select_countries = []
  terms_and_conditions = '';
  privacy_and_policy = '';
  terms_condition: any = { response: '' }
  response = { status: '', message: '', type: '' }
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '200px',
    minHeight: '200px',
    maxHeight: '200px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'no',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };
  edit_id: any;


  addCountry(country) {
    if (country.status) {
      this.select_countries.push({ country_id: country._id, country_name: country.selected_country_name,country_code: country.selected_country_code })
    } else {
      this.select_countries.find((res, index) => {
        if (res.country_id === country._id) {
          this.select_countries.splice(index, 1)
        }
      })
    }
    console.log(this.select_countries)
  }

  submit() {
    this.select_countries.map(res => {
      let body = {
        country_id: res.country_id,
        country_name: res.country_name,
        country_code: res.country_code,
        terms_and_conditions: this.terms_and_conditions,
        privacy_and_policy: this.privacy_and_policy
      }
      if (this.edit_id) {
        let _id = "_id"
        body[_id]= this.edit_id
        this.api.put(`update_terms_and_conditions_and_privacy_policy`, body).subscribe(
          data => {
            console.log(data)
            this.response = data
            if (data.status === 'success') {
              setTimeout(() => {
                window.location.reload()
              }, 1000);
            }
          },
          error => console.log('Error!', error)
        )
      } else {
        this.api.post(`add_terms_and_conditions_and_privacy_policy`, body).subscribe(
          data => {
            console.log(data)
            this.response = data
            if (data.status === 'success') {
              setTimeout(() => {
                window.location.reload()
              }, 1000);
            }
          },
          error => console.log('Error!', error)
        )
      }
    })
  }

  view(_id,status){
    this.api.put(`update_terms_and_conditions_and_privacy_policy`, {_id: _id, status: !status}).subscribe(
      data => {
        console.log(data)
        this.response = data
        if (data.status === 'success') {
          setTimeout(() => {
            window.location.reload()
          }, 1000);
        }
      },
      error => console.log('Error!', error)
    )
  }

  edit(value) {
    this.edit_id = value._id
    console.log(value)
    this.select_countries.push({ country_id: value.country_id, country_name: value.country_name,country_code: value.selected_country_code })
    this.countries.map((res) => {
      if (res._id === value.country_id) {
        res.status = true
      }
    })
    this.terms_condition.response.splice(this.terms_condition.response.indexOf(value), 1);
    this.terms_and_conditions = value.terms_and_conditions
    this.privacy_and_policy = value.privacy_and_policy
  }

  delete(value) {
    let body = {
      "_id": value._id
    }
    this.api.post(`delete_terms_and_conditions_and_privacy_policy`, body).subscribe(
      data => {
        console.log('!success', data);
        this.api.get(`get_all_terms_and_conditions_and_privacy_policy`).subscribe(
          data => {
            console.log(data)
            this.terms_condition = data
            // this.countries = Object.values(data)[2];
          },
          error => console.log('Error!', error)
        );
      },
      error => console.log(error)
    )

  }

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.api.get(`selected_country_list`).subscribe(
      data => {
        this.countries = Object.values(data)[2];
        this.countries.map(res => res.status = false)
      },
      error => console.log('Error!', error)
    );
    this.api.get(`get_all_terms_and_conditions_and_privacy_policy`).subscribe(
      data => {
        console.log(data)
        this.terms_condition = data
        // this.countries = Object.values(data)[2];
      },
      error => console.log('Error!', error)
    );
  }

}
